*, ::before, ::after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.dq-container {
  width: 100%;
}

@media (min-width: 640px) {
  .dq-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .dq-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .dq-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .dq-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .dq-container {
    max-width: 1536px;
  }
}

.dq-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.dq-not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.focus-within\:dq-sr-only:focus-within {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.focus-within\:dq-not-sr-only:focus-within {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.focus\:dq-sr-only:focus {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.focus\:dq-not-sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.dq-pointer-events-none {
  pointer-events: none;
}

.dq-pointer-events-auto {
  pointer-events: auto;
}

.dq-visible {
  visibility: visible;
}

.dq-invisible {
  visibility: hidden;
}

.dq-static {
  position: static;
}

.dq-fixed {
  position: fixed;
}

.dq-absolute {
  position: absolute;
}

.dq-relative {
  position: relative;
}

.dq-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.dq-inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.dq-inset-1 {
  top: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem;
}

.dq-inset-2 {
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
}

.dq-inset-3 {
  top: 0.75rem;
  right: 0.75rem;
  bottom: 0.75rem;
  left: 0.75rem;
}

.dq-inset-4 {
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
}

.dq-inset-5 {
  top: 1.25rem;
  right: 1.25rem;
  bottom: 1.25rem;
  left: 1.25rem;
}

.dq-inset-6 {
  top: 1.5rem;
  right: 1.5rem;
  bottom: 1.5rem;
  left: 1.5rem;
}

.dq-inset-7 {
  top: 1.75rem;
  right: 1.75rem;
  bottom: 1.75rem;
  left: 1.75rem;
}

.dq-inset-8 {
  top: 2rem;
  right: 2rem;
  bottom: 2rem;
  left: 2rem;
}

.dq-inset-9 {
  top: 2.25rem;
  right: 2.25rem;
  bottom: 2.25rem;
  left: 2.25rem;
}

.dq-inset-10 {
  top: 2.5rem;
  right: 2.5rem;
  bottom: 2.5rem;
  left: 2.5rem;
}

.dq-inset-11 {
  top: 2.75rem;
  right: 2.75rem;
  bottom: 2.75rem;
  left: 2.75rem;
}

.dq-inset-12 {
  top: 3rem;
  right: 3rem;
  bottom: 3rem;
  left: 3rem;
}

.dq-inset-14 {
  top: 3.5rem;
  right: 3.5rem;
  bottom: 3.5rem;
  left: 3.5rem;
}

.dq-inset-16 {
  top: 4rem;
  right: 4rem;
  bottom: 4rem;
  left: 4rem;
}

.dq-inset-20 {
  top: 5rem;
  right: 5rem;
  bottom: 5rem;
  left: 5rem;
}

.dq-inset-24 {
  top: 6rem;
  right: 6rem;
  bottom: 6rem;
  left: 6rem;
}

.dq-inset-28 {
  top: 7rem;
  right: 7rem;
  bottom: 7rem;
  left: 7rem;
}

.dq-inset-32 {
  top: 8rem;
  right: 8rem;
  bottom: 8rem;
  left: 8rem;
}

.dq-inset-36 {
  top: 9rem;
  right: 9rem;
  bottom: 9rem;
  left: 9rem;
}

.dq-inset-40 {
  top: 10rem;
  right: 10rem;
  bottom: 10rem;
  left: 10rem;
}

.dq-inset-44 {
  top: 11rem;
  right: 11rem;
  bottom: 11rem;
  left: 11rem;
}

.dq-inset-48 {
  top: 12rem;
  right: 12rem;
  bottom: 12rem;
  left: 12rem;
}

.dq-inset-52 {
  top: 13rem;
  right: 13rem;
  bottom: 13rem;
  left: 13rem;
}

.dq-inset-56 {
  top: 14rem;
  right: 14rem;
  bottom: 14rem;
  left: 14rem;
}

.dq-inset-60 {
  top: 15rem;
  right: 15rem;
  bottom: 15rem;
  left: 15rem;
}

.dq-inset-64 {
  top: 16rem;
  right: 16rem;
  bottom: 16rem;
  left: 16rem;
}

.dq-inset-72 {
  top: 18rem;
  right: 18rem;
  bottom: 18rem;
  left: 18rem;
}

.dq-inset-80 {
  top: 20rem;
  right: 20rem;
  bottom: 20rem;
  left: 20rem;
}

.dq-inset-96 {
  top: 24rem;
  right: 24rem;
  bottom: 24rem;
  left: 24rem;
}

.dq-inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.dq-inset-px {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
}

.dq-inset-0\.5 {
  top: 0.125rem;
  right: 0.125rem;
  bottom: 0.125rem;
  left: 0.125rem;
}

.dq-inset-1\.5 {
  top: 0.375rem;
  right: 0.375rem;
  bottom: 0.375rem;
  left: 0.375rem;
}

.dq-inset-2\.5 {
  top: 0.625rem;
  right: 0.625rem;
  bottom: 0.625rem;
  left: 0.625rem;
}

.dq-inset-3\.5 {
  top: 0.875rem;
  right: 0.875rem;
  bottom: 0.875rem;
  left: 0.875rem;
}

.dq--inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.dq--inset-1 {
  top: -0.25rem;
  right: -0.25rem;
  bottom: -0.25rem;
  left: -0.25rem;
}

.dq--inset-2 {
  top: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem;
}

.dq--inset-3 {
  top: -0.75rem;
  right: -0.75rem;
  bottom: -0.75rem;
  left: -0.75rem;
}

.dq--inset-4 {
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem;
}

.dq--inset-5 {
  top: -1.25rem;
  right: -1.25rem;
  bottom: -1.25rem;
  left: -1.25rem;
}

.dq--inset-6 {
  top: -1.5rem;
  right: -1.5rem;
  bottom: -1.5rem;
  left: -1.5rem;
}

.dq--inset-7 {
  top: -1.75rem;
  right: -1.75rem;
  bottom: -1.75rem;
  left: -1.75rem;
}

.dq--inset-8 {
  top: -2rem;
  right: -2rem;
  bottom: -2rem;
  left: -2rem;
}

.dq--inset-9 {
  top: -2.25rem;
  right: -2.25rem;
  bottom: -2.25rem;
  left: -2.25rem;
}

.dq--inset-10 {
  top: -2.5rem;
  right: -2.5rem;
  bottom: -2.5rem;
  left: -2.5rem;
}

.dq--inset-11 {
  top: -2.75rem;
  right: -2.75rem;
  bottom: -2.75rem;
  left: -2.75rem;
}

.dq--inset-12 {
  top: -3rem;
  right: -3rem;
  bottom: -3rem;
  left: -3rem;
}

.dq--inset-14 {
  top: -3.5rem;
  right: -3.5rem;
  bottom: -3.5rem;
  left: -3.5rem;
}

.dq--inset-16 {
  top: -4rem;
  right: -4rem;
  bottom: -4rem;
  left: -4rem;
}

.dq--inset-20 {
  top: -5rem;
  right: -5rem;
  bottom: -5rem;
  left: -5rem;
}

.dq--inset-24 {
  top: -6rem;
  right: -6rem;
  bottom: -6rem;
  left: -6rem;
}

.dq--inset-28 {
  top: -7rem;
  right: -7rem;
  bottom: -7rem;
  left: -7rem;
}

.dq--inset-32 {
  top: -8rem;
  right: -8rem;
  bottom: -8rem;
  left: -8rem;
}

.dq--inset-36 {
  top: -9rem;
  right: -9rem;
  bottom: -9rem;
  left: -9rem;
}

.dq--inset-40 {
  top: -10rem;
  right: -10rem;
  bottom: -10rem;
  left: -10rem;
}

.dq--inset-44 {
  top: -11rem;
  right: -11rem;
  bottom: -11rem;
  left: -11rem;
}

.dq--inset-48 {
  top: -12rem;
  right: -12rem;
  bottom: -12rem;
  left: -12rem;
}

.dq--inset-52 {
  top: -13rem;
  right: -13rem;
  bottom: -13rem;
  left: -13rem;
}

.dq--inset-56 {
  top: -14rem;
  right: -14rem;
  bottom: -14rem;
  left: -14rem;
}

.dq--inset-60 {
  top: -15rem;
  right: -15rem;
  bottom: -15rem;
  left: -15rem;
}

.dq--inset-64 {
  top: -16rem;
  right: -16rem;
  bottom: -16rem;
  left: -16rem;
}

.dq--inset-72 {
  top: -18rem;
  right: -18rem;
  bottom: -18rem;
  left: -18rem;
}

.dq--inset-80 {
  top: -20rem;
  right: -20rem;
  bottom: -20rem;
  left: -20rem;
}

.dq--inset-96 {
  top: -24rem;
  right: -24rem;
  bottom: -24rem;
  left: -24rem;
}

.dq--inset-px {
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
}

.dq--inset-0\.5 {
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
}

.dq--inset-1\.5 {
  top: -0.375rem;
  right: -0.375rem;
  bottom: -0.375rem;
  left: -0.375rem;
}

.dq--inset-2\.5 {
  top: -0.625rem;
  right: -0.625rem;
  bottom: -0.625rem;
  left: -0.625rem;
}

.dq--inset-3\.5 {
  top: -0.875rem;
  right: -0.875rem;
  bottom: -0.875rem;
  left: -0.875rem;
}

.dq-inset-1\/2 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
}

.dq-inset-1\/3 {
  top: 33.333333%;
  right: 33.333333%;
  bottom: 33.333333%;
  left: 33.333333%;
}

.dq-inset-2\/3 {
  top: 66.666667%;
  right: 66.666667%;
  bottom: 66.666667%;
  left: 66.666667%;
}

.dq-inset-1\/4 {
  top: 25%;
  right: 25%;
  bottom: 25%;
  left: 25%;
}

.dq-inset-2\/4 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
}

.dq-inset-3\/4 {
  top: 75%;
  right: 75%;
  bottom: 75%;
  left: 75%;
}

.dq-inset-full {
  top: 100%;
  right: 100%;
  bottom: 100%;
  left: 100%;
}

.dq--inset-1\/2 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
}

.dq--inset-1\/3 {
  top: -33.333333%;
  right: -33.333333%;
  bottom: -33.333333%;
  left: -33.333333%;
}

.dq--inset-2\/3 {
  top: -66.666667%;
  right: -66.666667%;
  bottom: -66.666667%;
  left: -66.666667%;
}

.dq--inset-1\/4 {
  top: -25%;
  right: -25%;
  bottom: -25%;
  left: -25%;
}

.dq--inset-2\/4 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
}

.dq--inset-3\/4 {
  top: -75%;
  right: -75%;
  bottom: -75%;
  left: -75%;
}

.dq--inset-full {
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%;
}

.dq-inset-x-0 {
  left: 0px;
  right: 0px;
}

.dq-inset-x-1 {
  left: 0.25rem;
  right: 0.25rem;
}

.dq-inset-x-2 {
  left: 0.5rem;
  right: 0.5rem;
}

.dq-inset-x-3 {
  left: 0.75rem;
  right: 0.75rem;
}

.dq-inset-x-4 {
  left: 1rem;
  right: 1rem;
}

.dq-inset-x-5 {
  left: 1.25rem;
  right: 1.25rem;
}

.dq-inset-x-6 {
  left: 1.5rem;
  right: 1.5rem;
}

.dq-inset-x-7 {
  left: 1.75rem;
  right: 1.75rem;
}

.dq-inset-x-8 {
  left: 2rem;
  right: 2rem;
}

.dq-inset-x-9 {
  left: 2.25rem;
  right: 2.25rem;
}

.dq-inset-x-10 {
  left: 2.5rem;
  right: 2.5rem;
}

.dq-inset-x-11 {
  left: 2.75rem;
  right: 2.75rem;
}

.dq-inset-x-12 {
  left: 3rem;
  right: 3rem;
}

.dq-inset-x-14 {
  left: 3.5rem;
  right: 3.5rem;
}

.dq-inset-x-16 {
  left: 4rem;
  right: 4rem;
}

.dq-inset-x-20 {
  left: 5rem;
  right: 5rem;
}

.dq-inset-x-24 {
  left: 6rem;
  right: 6rem;
}

.dq-inset-x-28 {
  left: 7rem;
  right: 7rem;
}

.dq-inset-x-32 {
  left: 8rem;
  right: 8rem;
}

.dq-inset-x-36 {
  left: 9rem;
  right: 9rem;
}

.dq-inset-x-40 {
  left: 10rem;
  right: 10rem;
}

.dq-inset-x-44 {
  left: 11rem;
  right: 11rem;
}

.dq-inset-x-48 {
  left: 12rem;
  right: 12rem;
}

.dq-inset-x-52 {
  left: 13rem;
  right: 13rem;
}

.dq-inset-x-56 {
  left: 14rem;
  right: 14rem;
}

.dq-inset-x-60 {
  left: 15rem;
  right: 15rem;
}

.dq-inset-x-64 {
  left: 16rem;
  right: 16rem;
}

.dq-inset-x-72 {
  left: 18rem;
  right: 18rem;
}

.dq-inset-x-80 {
  left: 20rem;
  right: 20rem;
}

.dq-inset-x-96 {
  left: 24rem;
  right: 24rem;
}

.dq-inset-x-auto {
  left: auto;
  right: auto;
}

.dq-inset-x-px {
  left: 1px;
  right: 1px;
}

.dq-inset-x-0\.5 {
  left: 0.125rem;
  right: 0.125rem;
}

.dq-inset-x-1\.5 {
  left: 0.375rem;
  right: 0.375rem;
}

.dq-inset-x-2\.5 {
  left: 0.625rem;
  right: 0.625rem;
}

.dq-inset-x-3\.5 {
  left: 0.875rem;
  right: 0.875rem;
}

.dq--inset-x-0 {
  left: 0px;
  right: 0px;
}

.dq--inset-x-1 {
  left: -0.25rem;
  right: -0.25rem;
}

.dq--inset-x-2 {
  left: -0.5rem;
  right: -0.5rem;
}

.dq--inset-x-3 {
  left: -0.75rem;
  right: -0.75rem;
}

.dq--inset-x-4 {
  left: -1rem;
  right: -1rem;
}

.dq--inset-x-5 {
  left: -1.25rem;
  right: -1.25rem;
}

.dq--inset-x-6 {
  left: -1.5rem;
  right: -1.5rem;
}

.dq--inset-x-7 {
  left: -1.75rem;
  right: -1.75rem;
}

.dq--inset-x-8 {
  left: -2rem;
  right: -2rem;
}

.dq--inset-x-9 {
  left: -2.25rem;
  right: -2.25rem;
}

.dq--inset-x-10 {
  left: -2.5rem;
  right: -2.5rem;
}

.dq--inset-x-11 {
  left: -2.75rem;
  right: -2.75rem;
}

.dq--inset-x-12 {
  left: -3rem;
  right: -3rem;
}

.dq--inset-x-14 {
  left: -3.5rem;
  right: -3.5rem;
}

.dq--inset-x-16 {
  left: -4rem;
  right: -4rem;
}

.dq--inset-x-20 {
  left: -5rem;
  right: -5rem;
}

.dq--inset-x-24 {
  left: -6rem;
  right: -6rem;
}

.dq--inset-x-28 {
  left: -7rem;
  right: -7rem;
}

.dq--inset-x-32 {
  left: -8rem;
  right: -8rem;
}

.dq--inset-x-36 {
  left: -9rem;
  right: -9rem;
}

.dq--inset-x-40 {
  left: -10rem;
  right: -10rem;
}

.dq--inset-x-44 {
  left: -11rem;
  right: -11rem;
}

.dq--inset-x-48 {
  left: -12rem;
  right: -12rem;
}

.dq--inset-x-52 {
  left: -13rem;
  right: -13rem;
}

.dq--inset-x-56 {
  left: -14rem;
  right: -14rem;
}

.dq--inset-x-60 {
  left: -15rem;
  right: -15rem;
}

.dq--inset-x-64 {
  left: -16rem;
  right: -16rem;
}

.dq--inset-x-72 {
  left: -18rem;
  right: -18rem;
}

.dq--inset-x-80 {
  left: -20rem;
  right: -20rem;
}

.dq--inset-x-96 {
  left: -24rem;
  right: -24rem;
}

.dq--inset-x-px {
  left: -1px;
  right: -1px;
}

.dq--inset-x-0\.5 {
  left: -0.125rem;
  right: -0.125rem;
}

.dq--inset-x-1\.5 {
  left: -0.375rem;
  right: -0.375rem;
}

.dq--inset-x-2\.5 {
  left: -0.625rem;
  right: -0.625rem;
}

.dq--inset-x-3\.5 {
  left: -0.875rem;
  right: -0.875rem;
}

.dq-inset-x-1\/2 {
  left: 50%;
  right: 50%;
}

.dq-inset-x-1\/3 {
  left: 33.333333%;
  right: 33.333333%;
}

.dq-inset-x-2\/3 {
  left: 66.666667%;
  right: 66.666667%;
}

.dq-inset-x-1\/4 {
  left: 25%;
  right: 25%;
}

.dq-inset-x-2\/4 {
  left: 50%;
  right: 50%;
}

.dq-inset-x-3\/4 {
  left: 75%;
  right: 75%;
}

.dq-inset-x-full {
  left: 100%;
  right: 100%;
}

.dq--inset-x-1\/2 {
  left: -50%;
  right: -50%;
}

.dq--inset-x-1\/3 {
  left: -33.333333%;
  right: -33.333333%;
}

.dq--inset-x-2\/3 {
  left: -66.666667%;
  right: -66.666667%;
}

.dq--inset-x-1\/4 {
  left: -25%;
  right: -25%;
}

.dq--inset-x-2\/4 {
  left: -50%;
  right: -50%;
}

.dq--inset-x-3\/4 {
  left: -75%;
  right: -75%;
}

.dq--inset-x-full {
  left: -100%;
  right: -100%;
}

.dq-inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.dq-inset-y-1 {
  top: 0.25rem;
  bottom: 0.25rem;
}

.dq-inset-y-2 {
  top: 0.5rem;
  bottom: 0.5rem;
}

.dq-inset-y-3 {
  top: 0.75rem;
  bottom: 0.75rem;
}

.dq-inset-y-4 {
  top: 1rem;
  bottom: 1rem;
}

.dq-inset-y-5 {
  top: 1.25rem;
  bottom: 1.25rem;
}

.dq-inset-y-6 {
  top: 1.5rem;
  bottom: 1.5rem;
}

.dq-inset-y-7 {
  top: 1.75rem;
  bottom: 1.75rem;
}

.dq-inset-y-8 {
  top: 2rem;
  bottom: 2rem;
}

.dq-inset-y-9 {
  top: 2.25rem;
  bottom: 2.25rem;
}

.dq-inset-y-10 {
  top: 2.5rem;
  bottom: 2.5rem;
}

.dq-inset-y-11 {
  top: 2.75rem;
  bottom: 2.75rem;
}

.dq-inset-y-12 {
  top: 3rem;
  bottom: 3rem;
}

.dq-inset-y-14 {
  top: 3.5rem;
  bottom: 3.5rem;
}

.dq-inset-y-16 {
  top: 4rem;
  bottom: 4rem;
}

.dq-inset-y-20 {
  top: 5rem;
  bottom: 5rem;
}

.dq-inset-y-24 {
  top: 6rem;
  bottom: 6rem;
}

.dq-inset-y-28 {
  top: 7rem;
  bottom: 7rem;
}

.dq-inset-y-32 {
  top: 8rem;
  bottom: 8rem;
}

.dq-inset-y-36 {
  top: 9rem;
  bottom: 9rem;
}

.dq-inset-y-40 {
  top: 10rem;
  bottom: 10rem;
}

.dq-inset-y-44 {
  top: 11rem;
  bottom: 11rem;
}

.dq-inset-y-48 {
  top: 12rem;
  bottom: 12rem;
}

.dq-inset-y-52 {
  top: 13rem;
  bottom: 13rem;
}

.dq-inset-y-56 {
  top: 14rem;
  bottom: 14rem;
}

.dq-inset-y-60 {
  top: 15rem;
  bottom: 15rem;
}

.dq-inset-y-64 {
  top: 16rem;
  bottom: 16rem;
}

.dq-inset-y-72 {
  top: 18rem;
  bottom: 18rem;
}

.dq-inset-y-80 {
  top: 20rem;
  bottom: 20rem;
}

.dq-inset-y-96 {
  top: 24rem;
  bottom: 24rem;
}

.dq-inset-y-auto {
  top: auto;
  bottom: auto;
}

.dq-inset-y-px {
  top: 1px;
  bottom: 1px;
}

.dq-inset-y-0\.5 {
  top: 0.125rem;
  bottom: 0.125rem;
}

.dq-inset-y-1\.5 {
  top: 0.375rem;
  bottom: 0.375rem;
}

.dq-inset-y-2\.5 {
  top: 0.625rem;
  bottom: 0.625rem;
}

.dq-inset-y-3\.5 {
  top: 0.875rem;
  bottom: 0.875rem;
}

.dq--inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.dq--inset-y-1 {
  top: -0.25rem;
  bottom: -0.25rem;
}

.dq--inset-y-2 {
  top: -0.5rem;
  bottom: -0.5rem;
}

.dq--inset-y-3 {
  top: -0.75rem;
  bottom: -0.75rem;
}

.dq--inset-y-4 {
  top: -1rem;
  bottom: -1rem;
}

.dq--inset-y-5 {
  top: -1.25rem;
  bottom: -1.25rem;
}

.dq--inset-y-6 {
  top: -1.5rem;
  bottom: -1.5rem;
}

.dq--inset-y-7 {
  top: -1.75rem;
  bottom: -1.75rem;
}

.dq--inset-y-8 {
  top: -2rem;
  bottom: -2rem;
}

.dq--inset-y-9 {
  top: -2.25rem;
  bottom: -2.25rem;
}

.dq--inset-y-10 {
  top: -2.5rem;
  bottom: -2.5rem;
}

.dq--inset-y-11 {
  top: -2.75rem;
  bottom: -2.75rem;
}

.dq--inset-y-12 {
  top: -3rem;
  bottom: -3rem;
}

.dq--inset-y-14 {
  top: -3.5rem;
  bottom: -3.5rem;
}

.dq--inset-y-16 {
  top: -4rem;
  bottom: -4rem;
}

.dq--inset-y-20 {
  top: -5rem;
  bottom: -5rem;
}

.dq--inset-y-24 {
  top: -6rem;
  bottom: -6rem;
}

.dq--inset-y-28 {
  top: -7rem;
  bottom: -7rem;
}

.dq--inset-y-32 {
  top: -8rem;
  bottom: -8rem;
}

.dq--inset-y-36 {
  top: -9rem;
  bottom: -9rem;
}

.dq--inset-y-40 {
  top: -10rem;
  bottom: -10rem;
}

.dq--inset-y-44 {
  top: -11rem;
  bottom: -11rem;
}

.dq--inset-y-48 {
  top: -12rem;
  bottom: -12rem;
}

.dq--inset-y-52 {
  top: -13rem;
  bottom: -13rem;
}

.dq--inset-y-56 {
  top: -14rem;
  bottom: -14rem;
}

.dq--inset-y-60 {
  top: -15rem;
  bottom: -15rem;
}

.dq--inset-y-64 {
  top: -16rem;
  bottom: -16rem;
}

.dq--inset-y-72 {
  top: -18rem;
  bottom: -18rem;
}

.dq--inset-y-80 {
  top: -20rem;
  bottom: -20rem;
}

.dq--inset-y-96 {
  top: -24rem;
  bottom: -24rem;
}

.dq--inset-y-px {
  top: -1px;
  bottom: -1px;
}

.dq--inset-y-0\.5 {
  top: -0.125rem;
  bottom: -0.125rem;
}

.dq--inset-y-1\.5 {
  top: -0.375rem;
  bottom: -0.375rem;
}

.dq--inset-y-2\.5 {
  top: -0.625rem;
  bottom: -0.625rem;
}

.dq--inset-y-3\.5 {
  top: -0.875rem;
  bottom: -0.875rem;
}

.dq-inset-y-1\/2 {
  top: 50%;
  bottom: 50%;
}

.dq-inset-y-1\/3 {
  top: 33.333333%;
  bottom: 33.333333%;
}

.dq-inset-y-2\/3 {
  top: 66.666667%;
  bottom: 66.666667%;
}

.dq-inset-y-1\/4 {
  top: 25%;
  bottom: 25%;
}

.dq-inset-y-2\/4 {
  top: 50%;
  bottom: 50%;
}

.dq-inset-y-3\/4 {
  top: 75%;
  bottom: 75%;
}

.dq-inset-y-full {
  top: 100%;
  bottom: 100%;
}

.dq--inset-y-1\/2 {
  top: -50%;
  bottom: -50%;
}

.dq--inset-y-1\/3 {
  top: -33.333333%;
  bottom: -33.333333%;
}

.dq--inset-y-2\/3 {
  top: -66.666667%;
  bottom: -66.666667%;
}

.dq--inset-y-1\/4 {
  top: -25%;
  bottom: -25%;
}

.dq--inset-y-2\/4 {
  top: -50%;
  bottom: -50%;
}

.dq--inset-y-3\/4 {
  top: -75%;
  bottom: -75%;
}

.dq--inset-y-full {
  top: -100%;
  bottom: -100%;
}

.dq-top-0 {
  top: 0px;
}

.dq-top-1 {
  top: 0.25rem;
}

.dq-top-2 {
  top: 0.5rem;
}

.dq-top-3 {
  top: 0.75rem;
}

.dq-top-4 {
  top: 1rem;
}

.dq-top-5 {
  top: 1.25rem;
}

.dq-top-6 {
  top: 1.5rem;
}

.dq-top-7 {
  top: 1.75rem;
}

.dq-top-8 {
  top: 2rem;
}

.dq-top-9 {
  top: 2.25rem;
}

.dq-top-10 {
  top: 2.5rem;
}

.dq-top-11 {
  top: 2.75rem;
}

.dq-top-12 {
  top: 3rem;
}

.dq-top-14 {
  top: 3.5rem;
}

.dq-top-16 {
  top: 4rem;
}

.dq-top-20 {
  top: 5rem;
}

.dq-top-24 {
  top: 6rem;
}

.dq-top-28 {
  top: 7rem;
}

.dq-top-32 {
  top: 8rem;
}

.dq-top-36 {
  top: 9rem;
}

.dq-top-40 {
  top: 10rem;
}

.dq-top-44 {
  top: 11rem;
}

.dq-top-48 {
  top: 12rem;
}

.dq-top-52 {
  top: 13rem;
}

.dq-top-56 {
  top: 14rem;
}

.dq-top-60 {
  top: 15rem;
}

.dq-top-64 {
  top: 16rem;
}

.dq-top-72 {
  top: 18rem;
}

.dq-top-80 {
  top: 20rem;
}

.dq-top-96 {
  top: 24rem;
}

.dq-top-auto {
  top: auto;
}

.dq-top-px {
  top: 1px;
}

.dq-top-0\.5 {
  top: 0.125rem;
}

.dq-top-1\.5 {
  top: 0.375rem;
}

.dq-top-2\.5 {
  top: 0.625rem;
}

.dq-top-3\.5 {
  top: 0.875rem;
}

.dq--top-0 {
  top: 0px;
}

.dq--top-1 {
  top: -0.25rem;
}

.dq--top-2 {
  top: -0.5rem;
}

.dq--top-3 {
  top: -0.75rem;
}

.dq--top-4 {
  top: -1rem;
}

.dq--top-5 {
  top: -1.25rem;
}

.dq--top-6 {
  top: -1.5rem;
}

.dq--top-7 {
  top: -1.75rem;
}

.dq--top-8 {
  top: -2rem;
}

.dq--top-9 {
  top: -2.25rem;
}

.dq--top-10 {
  top: -2.5rem;
}

.dq--top-11 {
  top: -2.75rem;
}

.dq--top-12 {
  top: -3rem;
}

.dq--top-14 {
  top: -3.5rem;
}

.dq--top-16 {
  top: -4rem;
}

.dq--top-20 {
  top: -5rem;
}

.dq--top-24 {
  top: -6rem;
}

.dq--top-28 {
  top: -7rem;
}

.dq--top-32 {
  top: -8rem;
}

.dq--top-36 {
  top: -9rem;
}

.dq--top-40 {
  top: -10rem;
}

.dq--top-44 {
  top: -11rem;
}

.dq--top-48 {
  top: -12rem;
}

.dq--top-52 {
  top: -13rem;
}

.dq--top-56 {
  top: -14rem;
}

.dq--top-60 {
  top: -15rem;
}

.dq--top-64 {
  top: -16rem;
}

.dq--top-72 {
  top: -18rem;
}

.dq--top-80 {
  top: -20rem;
}

.dq--top-96 {
  top: -24rem;
}

.dq--top-px {
  top: -1px;
}

.dq--top-0\.5 {
  top: -0.125rem;
}

.dq--top-1\.5 {
  top: -0.375rem;
}

.dq--top-2\.5 {
  top: -0.625rem;
}

.dq--top-3\.5 {
  top: -0.875rem;
}

.dq-top-1\/2 {
  top: 50%;
}

.dq-top-1\/3 {
  top: 33.333333%;
}

.dq-top-2\/3 {
  top: 66.666667%;
}

.dq-top-1\/4 {
  top: 25%;
}

.dq-top-2\/4 {
  top: 50%;
}

.dq-top-3\/4 {
  top: 75%;
}

.dq-top-full {
  top: 100%;
}

.dq--top-1\/2 {
  top: -50%;
}

.dq--top-1\/3 {
  top: -33.333333%;
}

.dq--top-2\/3 {
  top: -66.666667%;
}

.dq--top-1\/4 {
  top: -25%;
}

.dq--top-2\/4 {
  top: -50%;
}

.dq--top-3\/4 {
  top: -75%;
}

.dq--top-full {
  top: -100%;
}

.dq-right-0 {
  right: 0px;
}

.dq-right-1 {
  right: 0.25rem;
}

.dq-right-2 {
  right: 0.5rem;
}

.dq-right-3 {
  right: 0.75rem;
}

.dq-right-4 {
  right: 1rem;
}

.dq-right-5 {
  right: 1.25rem;
}

.dq-right-6 {
  right: 1.5rem;
}

.dq-right-7 {
  right: 1.75rem;
}

.dq-right-8 {
  right: 2rem;
}

.dq-right-9 {
  right: 2.25rem;
}

.dq-right-10 {
  right: 2.5rem;
}

.dq-right-11 {
  right: 2.75rem;
}

.dq-right-12 {
  right: 3rem;
}

.dq-right-14 {
  right: 3.5rem;
}

.dq-right-16 {
  right: 4rem;
}

.dq-right-20 {
  right: 5rem;
}

.dq-right-24 {
  right: 6rem;
}

.dq-right-28 {
  right: 7rem;
}

.dq-right-32 {
  right: 8rem;
}

.dq-right-36 {
  right: 9rem;
}

.dq-right-40 {
  right: 10rem;
}

.dq-right-44 {
  right: 11rem;
}

.dq-right-48 {
  right: 12rem;
}

.dq-right-52 {
  right: 13rem;
}

.dq-right-56 {
  right: 14rem;
}

.dq-right-60 {
  right: 15rem;
}

.dq-right-64 {
  right: 16rem;
}

.dq-right-72 {
  right: 18rem;
}

.dq-right-80 {
  right: 20rem;
}

.dq-right-96 {
  right: 24rem;
}

.dq-right-auto {
  right: auto;
}

.dq-right-px {
  right: 1px;
}

.dq-right-0\.5 {
  right: 0.125rem;
}

.dq-right-1\.5 {
  right: 0.375rem;
}

.dq-right-2\.5 {
  right: 0.625rem;
}

.dq-right-3\.5 {
  right: 0.875rem;
}

.dq--right-0 {
  right: 0px;
}

.dq--right-1 {
  right: -0.25rem;
}

.dq--right-2 {
  right: -0.5rem;
}

.dq--right-3 {
  right: -0.75rem;
}

.dq--right-4 {
  right: -1rem;
}

.dq--right-5 {
  right: -1.25rem;
}

.dq--right-6 {
  right: -1.5rem;
}

.dq--right-7 {
  right: -1.75rem;
}

.dq--right-8 {
  right: -2rem;
}

.dq--right-9 {
  right: -2.25rem;
}

.dq--right-10 {
  right: -2.5rem;
}

.dq--right-11 {
  right: -2.75rem;
}

.dq--right-12 {
  right: -3rem;
}

.dq--right-14 {
  right: -3.5rem;
}

.dq--right-16 {
  right: -4rem;
}

.dq--right-20 {
  right: -5rem;
}

.dq--right-24 {
  right: -6rem;
}

.dq--right-28 {
  right: -7rem;
}

.dq--right-32 {
  right: -8rem;
}

.dq--right-36 {
  right: -9rem;
}

.dq--right-40 {
  right: -10rem;
}

.dq--right-44 {
  right: -11rem;
}

.dq--right-48 {
  right: -12rem;
}

.dq--right-52 {
  right: -13rem;
}

.dq--right-56 {
  right: -14rem;
}

.dq--right-60 {
  right: -15rem;
}

.dq--right-64 {
  right: -16rem;
}

.dq--right-72 {
  right: -18rem;
}

.dq--right-80 {
  right: -20rem;
}

.dq--right-96 {
  right: -24rem;
}

.dq--right-px {
  right: -1px;
}

.dq--right-0\.5 {
  right: -0.125rem;
}

.dq--right-1\.5 {
  right: -0.375rem;
}

.dq--right-2\.5 {
  right: -0.625rem;
}

.dq--right-3\.5 {
  right: -0.875rem;
}

.dq-right-1\/2 {
  right: 50%;
}

.dq-right-1\/3 {
  right: 33.333333%;
}

.dq-right-2\/3 {
  right: 66.666667%;
}

.dq-right-1\/4 {
  right: 25%;
}

.dq-right-2\/4 {
  right: 50%;
}

.dq-right-3\/4 {
  right: 75%;
}

.dq-right-full {
  right: 100%;
}

.dq--right-1\/2 {
  right: -50%;
}

.dq--right-1\/3 {
  right: -33.333333%;
}

.dq--right-2\/3 {
  right: -66.666667%;
}

.dq--right-1\/4 {
  right: -25%;
}

.dq--right-2\/4 {
  right: -50%;
}

.dq--right-3\/4 {
  right: -75%;
}

.dq--right-full {
  right: -100%;
}

.dq-bottom-0 {
  bottom: 0px;
}

.dq-bottom-1 {
  bottom: 0.25rem;
}

.dq-bottom-2 {
  bottom: 0.5rem;
}

.dq-bottom-3 {
  bottom: 0.75rem;
}

.dq-bottom-4 {
  bottom: 1rem;
}

.dq-bottom-5 {
  bottom: 1.25rem;
}

.dq-bottom-6 {
  bottom: 1.5rem;
}

.dq-bottom-7 {
  bottom: 1.75rem;
}

.dq-bottom-8 {
  bottom: 2rem;
}

.dq-bottom-9 {
  bottom: 2.25rem;
}

.dq-bottom-10 {
  bottom: 2.5rem;
}

.dq-bottom-11 {
  bottom: 2.75rem;
}

.dq-bottom-12 {
  bottom: 3rem;
}

.dq-bottom-14 {
  bottom: 3.5rem;
}

.dq-bottom-16 {
  bottom: 4rem;
}

.dq-bottom-20 {
  bottom: 5rem;
}

.dq-bottom-24 {
  bottom: 6rem;
}

.dq-bottom-28 {
  bottom: 7rem;
}

.dq-bottom-32 {
  bottom: 8rem;
}

.dq-bottom-36 {
  bottom: 9rem;
}

.dq-bottom-40 {
  bottom: 10rem;
}

.dq-bottom-44 {
  bottom: 11rem;
}

.dq-bottom-48 {
  bottom: 12rem;
}

.dq-bottom-52 {
  bottom: 13rem;
}

.dq-bottom-56 {
  bottom: 14rem;
}

.dq-bottom-60 {
  bottom: 15rem;
}

.dq-bottom-64 {
  bottom: 16rem;
}

.dq-bottom-72 {
  bottom: 18rem;
}

.dq-bottom-80 {
  bottom: 20rem;
}

.dq-bottom-96 {
  bottom: 24rem;
}

.dq-bottom-auto {
  bottom: auto;
}

.dq-bottom-px {
  bottom: 1px;
}

.dq-bottom-0\.5 {
  bottom: 0.125rem;
}

.dq-bottom-1\.5 {
  bottom: 0.375rem;
}

.dq-bottom-2\.5 {
  bottom: 0.625rem;
}

.dq-bottom-3\.5 {
  bottom: 0.875rem;
}

.dq--bottom-0 {
  bottom: 0px;
}

.dq--bottom-1 {
  bottom: -0.25rem;
}

.dq--bottom-2 {
  bottom: -0.5rem;
}

.dq--bottom-3 {
  bottom: -0.75rem;
}

.dq--bottom-4 {
  bottom: -1rem;
}

.dq--bottom-5 {
  bottom: -1.25rem;
}

.dq--bottom-6 {
  bottom: -1.5rem;
}

.dq--bottom-7 {
  bottom: -1.75rem;
}

.dq--bottom-8 {
  bottom: -2rem;
}

.dq--bottom-9 {
  bottom: -2.25rem;
}

.dq--bottom-10 {
  bottom: -2.5rem;
}

.dq--bottom-11 {
  bottom: -2.75rem;
}

.dq--bottom-12 {
  bottom: -3rem;
}

.dq--bottom-14 {
  bottom: -3.5rem;
}

.dq--bottom-16 {
  bottom: -4rem;
}

.dq--bottom-20 {
  bottom: -5rem;
}

.dq--bottom-24 {
  bottom: -6rem;
}

.dq--bottom-28 {
  bottom: -7rem;
}

.dq--bottom-32 {
  bottom: -8rem;
}

.dq--bottom-36 {
  bottom: -9rem;
}

.dq--bottom-40 {
  bottom: -10rem;
}

.dq--bottom-44 {
  bottom: -11rem;
}

.dq--bottom-48 {
  bottom: -12rem;
}

.dq--bottom-52 {
  bottom: -13rem;
}

.dq--bottom-56 {
  bottom: -14rem;
}

.dq--bottom-60 {
  bottom: -15rem;
}

.dq--bottom-64 {
  bottom: -16rem;
}

.dq--bottom-72 {
  bottom: -18rem;
}

.dq--bottom-80 {
  bottom: -20rem;
}

.dq--bottom-96 {
  bottom: -24rem;
}

.dq--bottom-px {
  bottom: -1px;
}

.dq--bottom-0\.5 {
  bottom: -0.125rem;
}

.dq--bottom-1\.5 {
  bottom: -0.375rem;
}

.dq--bottom-2\.5 {
  bottom: -0.625rem;
}

.dq--bottom-3\.5 {
  bottom: -0.875rem;
}

.dq-bottom-1\/2 {
  bottom: 50%;
}

.dq-bottom-1\/3 {
  bottom: 33.333333%;
}

.dq-bottom-2\/3 {
  bottom: 66.666667%;
}

.dq-bottom-1\/4 {
  bottom: 25%;
}

.dq-bottom-2\/4 {
  bottom: 50%;
}

.dq-bottom-3\/4 {
  bottom: 75%;
}

.dq-bottom-full {
  bottom: 100%;
}

.dq--bottom-1\/2 {
  bottom: -50%;
}

.dq--bottom-1\/3 {
  bottom: -33.333333%;
}

.dq--bottom-2\/3 {
  bottom: -66.666667%;
}

.dq--bottom-1\/4 {
  bottom: -25%;
}

.dq--bottom-2\/4 {
  bottom: -50%;
}

.dq--bottom-3\/4 {
  bottom: -75%;
}

.dq--bottom-full {
  bottom: -100%;
}

.dq-left-0 {
  left: 0px;
}

.dq-left-1 {
  left: 0.25rem;
}

.dq-left-2 {
  left: 0.5rem;
}

.dq-left-3 {
  left: 0.75rem;
}

.dq-left-4 {
  left: 1rem;
}

.dq-left-5 {
  left: 1.25rem;
}

.dq-left-6 {
  left: 1.5rem;
}

.dq-left-7 {
  left: 1.75rem;
}

.dq-left-8 {
  left: 2rem;
}

.dq-left-9 {
  left: 2.25rem;
}

.dq-left-10 {
  left: 2.5rem;
}

.dq-left-11 {
  left: 2.75rem;
}

.dq-left-12 {
  left: 3rem;
}

.dq-left-14 {
  left: 3.5rem;
}

.dq-left-16 {
  left: 4rem;
}

.dq-left-20 {
  left: 5rem;
}

.dq-left-24 {
  left: 6rem;
}

.dq-left-28 {
  left: 7rem;
}

.dq-left-32 {
  left: 8rem;
}

.dq-left-36 {
  left: 9rem;
}

.dq-left-40 {
  left: 10rem;
}

.dq-left-44 {
  left: 11rem;
}

.dq-left-48 {
  left: 12rem;
}

.dq-left-52 {
  left: 13rem;
}

.dq-left-56 {
  left: 14rem;
}

.dq-left-60 {
  left: 15rem;
}

.dq-left-64 {
  left: 16rem;
}

.dq-left-72 {
  left: 18rem;
}

.dq-left-80 {
  left: 20rem;
}

.dq-left-96 {
  left: 24rem;
}

.dq-left-auto {
  left: auto;
}

.dq-left-px {
  left: 1px;
}

.dq-left-0\.5 {
  left: 0.125rem;
}

.dq-left-1\.5 {
  left: 0.375rem;
}

.dq-left-2\.5 {
  left: 0.625rem;
}

.dq-left-3\.5 {
  left: 0.875rem;
}

.dq--left-0 {
  left: 0px;
}

.dq--left-1 {
  left: -0.25rem;
}

.dq--left-2 {
  left: -0.5rem;
}

.dq--left-3 {
  left: -0.75rem;
}

.dq--left-4 {
  left: -1rem;
}

.dq--left-5 {
  left: -1.25rem;
}

.dq--left-6 {
  left: -1.5rem;
}

.dq--left-7 {
  left: -1.75rem;
}

.dq--left-8 {
  left: -2rem;
}

.dq--left-9 {
  left: -2.25rem;
}

.dq--left-10 {
  left: -2.5rem;
}

.dq--left-11 {
  left: -2.75rem;
}

.dq--left-12 {
  left: -3rem;
}

.dq--left-14 {
  left: -3.5rem;
}

.dq--left-16 {
  left: -4rem;
}

.dq--left-20 {
  left: -5rem;
}

.dq--left-24 {
  left: -6rem;
}

.dq--left-28 {
  left: -7rem;
}

.dq--left-32 {
  left: -8rem;
}

.dq--left-36 {
  left: -9rem;
}

.dq--left-40 {
  left: -10rem;
}

.dq--left-44 {
  left: -11rem;
}

.dq--left-48 {
  left: -12rem;
}

.dq--left-52 {
  left: -13rem;
}

.dq--left-56 {
  left: -14rem;
}

.dq--left-60 {
  left: -15rem;
}

.dq--left-64 {
  left: -16rem;
}

.dq--left-72 {
  left: -18rem;
}

.dq--left-80 {
  left: -20rem;
}

.dq--left-96 {
  left: -24rem;
}

.dq--left-px {
  left: -1px;
}

.dq--left-0\.5 {
  left: -0.125rem;
}

.dq--left-1\.5 {
  left: -0.375rem;
}

.dq--left-2\.5 {
  left: -0.625rem;
}

.dq--left-3\.5 {
  left: -0.875rem;
}

.dq-left-1\/2 {
  left: 50%;
}

.dq-left-1\/3 {
  left: 33.333333%;
}

.dq-left-2\/3 {
  left: 66.666667%;
}

.dq-left-1\/4 {
  left: 25%;
}

.dq-left-2\/4 {
  left: 50%;
}

.dq-left-3\/4 {
  left: 75%;
}

.dq-left-full {
  left: 100%;
}

.dq--left-1\/2 {
  left: -50%;
}

.dq--left-1\/3 {
  left: -33.333333%;
}

.dq--left-2\/3 {
  left: -66.666667%;
}

.dq--left-1\/4 {
  left: -25%;
}

.dq--left-2\/4 {
  left: -50%;
}

.dq--left-3\/4 {
  left: -75%;
}

.dq--left-full {
  left: -100%;
}

.dq-isolate {
  isolation: isolate;
}

.dq-isolation-auto {
  isolation: auto;
}

.dq-z-0 {
  z-index: 0;
}

.dq-z-10 {
  z-index: 10;
}

.dq-z-20 {
  z-index: 20;
}

.dq-z-30 {
  z-index: 30;
}

.dq-z-40 {
  z-index: 40;
}

.dq-z-50 {
  z-index: 50;
}

.dq-z-auto {
  z-index: auto;
}

.focus-within\:dq-z-0:focus-within {
  z-index: 0;
}

.focus-within\:dq-z-10:focus-within {
  z-index: 10;
}

.focus-within\:dq-z-20:focus-within {
  z-index: 20;
}

.focus-within\:dq-z-30:focus-within {
  z-index: 30;
}

.focus-within\:dq-z-40:focus-within {
  z-index: 40;
}

.focus-within\:dq-z-50:focus-within {
  z-index: 50;
}

.focus-within\:dq-z-auto:focus-within {
  z-index: auto;
}

.focus\:dq-z-0:focus {
  z-index: 0;
}

.focus\:dq-z-10:focus {
  z-index: 10;
}

.focus\:dq-z-20:focus {
  z-index: 20;
}

.focus\:dq-z-30:focus {
  z-index: 30;
}

.focus\:dq-z-40:focus {
  z-index: 40;
}

.focus\:dq-z-50:focus {
  z-index: 50;
}

.focus\:dq-z-auto:focus {
  z-index: auto;
}

.dq-order-1 {
  order: 1;
}

.dq-order-2 {
  order: 2;
}

.dq-order-3 {
  order: 3;
}

.dq-order-4 {
  order: 4;
}

.dq-order-5 {
  order: 5;
}

.dq-order-6 {
  order: 6;
}

.dq-order-7 {
  order: 7;
}

.dq-order-8 {
  order: 8;
}

.dq-order-9 {
  order: 9;
}

.dq-order-10 {
  order: 10;
}

.dq-order-11 {
  order: 11;
}

.dq-order-12 {
  order: 12;
}

.dq-order-first {
  order: -9999;
}

.dq-order-last {
  order: 9999;
}

.dq-order-none {
  order: 0;
}

.dq-col-auto {
  grid-column: auto;
}

.dq-col-span-1 {
  grid-column: span 1 / span 1;
}

.dq-col-span-2 {
  grid-column: span 2 / span 2;
}

.dq-col-span-3 {
  grid-column: span 3 / span 3;
}

.dq-col-span-4 {
  grid-column: span 4 / span 4;
}

.dq-col-span-5 {
  grid-column: span 5 / span 5;
}

.dq-col-span-6 {
  grid-column: span 6 / span 6;
}

.dq-col-span-7 {
  grid-column: span 7 / span 7;
}

.dq-col-span-8 {
  grid-column: span 8 / span 8;
}

.dq-col-span-9 {
  grid-column: span 9 / span 9;
}

.dq-col-span-10 {
  grid-column: span 10 / span 10;
}

.dq-col-span-11 {
  grid-column: span 11 / span 11;
}

.dq-col-span-12 {
  grid-column: span 12 / span 12;
}

.dq-col-span-full {
  grid-column: 1 / -1;
}

.dq-col-start-1 {
  grid-column-start: 1;
}

.dq-col-start-2 {
  grid-column-start: 2;
}

.dq-col-start-3 {
  grid-column-start: 3;
}

.dq-col-start-4 {
  grid-column-start: 4;
}

.dq-col-start-5 {
  grid-column-start: 5;
}

.dq-col-start-6 {
  grid-column-start: 6;
}

.dq-col-start-7 {
  grid-column-start: 7;
}

.dq-col-start-8 {
  grid-column-start: 8;
}

.dq-col-start-9 {
  grid-column-start: 9;
}

.dq-col-start-10 {
  grid-column-start: 10;
}

.dq-col-start-11 {
  grid-column-start: 11;
}

.dq-col-start-12 {
  grid-column-start: 12;
}

.dq-col-start-13 {
  grid-column-start: 13;
}

.dq-col-start-auto {
  grid-column-start: auto;
}

.dq-col-end-1 {
  grid-column-end: 1;
}

.dq-col-end-2 {
  grid-column-end: 2;
}

.dq-col-end-3 {
  grid-column-end: 3;
}

.dq-col-end-4 {
  grid-column-end: 4;
}

.dq-col-end-5 {
  grid-column-end: 5;
}

.dq-col-end-6 {
  grid-column-end: 6;
}

.dq-col-end-7 {
  grid-column-end: 7;
}

.dq-col-end-8 {
  grid-column-end: 8;
}

.dq-col-end-9 {
  grid-column-end: 9;
}

.dq-col-end-10 {
  grid-column-end: 10;
}

.dq-col-end-11 {
  grid-column-end: 11;
}

.dq-col-end-12 {
  grid-column-end: 12;
}

.dq-col-end-13 {
  grid-column-end: 13;
}

.dq-col-end-auto {
  grid-column-end: auto;
}

.dq-row-auto {
  grid-row: auto;
}

.dq-row-span-1 {
  grid-row: span 1 / span 1;
}

.dq-row-span-2 {
  grid-row: span 2 / span 2;
}

.dq-row-span-3 {
  grid-row: span 3 / span 3;
}

.dq-row-span-4 {
  grid-row: span 4 / span 4;
}

.dq-row-span-5 {
  grid-row: span 5 / span 5;
}

.dq-row-span-6 {
  grid-row: span 6 / span 6;
}

.dq-row-span-full {
  grid-row: 1 / -1;
}

.dq-row-start-1 {
  grid-row-start: 1;
}

.dq-row-start-2 {
  grid-row-start: 2;
}

.dq-row-start-3 {
  grid-row-start: 3;
}

.dq-row-start-4 {
  grid-row-start: 4;
}

.dq-row-start-5 {
  grid-row-start: 5;
}

.dq-row-start-6 {
  grid-row-start: 6;
}

.dq-row-start-7 {
  grid-row-start: 7;
}

.dq-row-start-auto {
  grid-row-start: auto;
}

.dq-row-end-1 {
  grid-row-end: 1;
}

.dq-row-end-2 {
  grid-row-end: 2;
}

.dq-row-end-3 {
  grid-row-end: 3;
}

.dq-row-end-4 {
  grid-row-end: 4;
}

.dq-row-end-5 {
  grid-row-end: 5;
}

.dq-row-end-6 {
  grid-row-end: 6;
}

.dq-row-end-7 {
  grid-row-end: 7;
}

.dq-row-end-auto {
  grid-row-end: auto;
}

.dq-float-right {
  float: right;
}

.dq-float-left {
  float: left;
}

.dq-float-none {
  float: none;
}

.dq-clear-left {
  clear: left;
}

.dq-clear-right {
  clear: right;
}

.dq-clear-both {
  clear: both;
}

.dq-clear-none {
  clear: none;
}

.dq-m-0 {
  margin: 0px;
}

.dq-m-1 {
  margin: 0.25rem;
}

.dq-m-2 {
  margin: 0.5rem;
}

.dq-m-3 {
  margin: 0.75rem;
}

.dq-m-4 {
  margin: 1rem;
}

.dq-m-5 {
  margin: 1.25rem;
}

.dq-m-6 {
  margin: 1.5rem;
}

.dq-m-7 {
  margin: 1.75rem;
}

.dq-m-8 {
  margin: 2rem;
}

.dq-m-9 {
  margin: 2.25rem;
}

.dq-m-10 {
  margin: 2.5rem;
}

.dq-m-11 {
  margin: 2.75rem;
}

.dq-m-12 {
  margin: 3rem;
}

.dq-m-14 {
  margin: 3.5rem;
}

.dq-m-16 {
  margin: 4rem;
}

.dq-m-20 {
  margin: 5rem;
}

.dq-m-24 {
  margin: 6rem;
}

.dq-m-28 {
  margin: 7rem;
}

.dq-m-32 {
  margin: 8rem;
}

.dq-m-36 {
  margin: 9rem;
}

.dq-m-40 {
  margin: 10rem;
}

.dq-m-44 {
  margin: 11rem;
}

.dq-m-48 {
  margin: 12rem;
}

.dq-m-52 {
  margin: 13rem;
}

.dq-m-56 {
  margin: 14rem;
}

.dq-m-60 {
  margin: 15rem;
}

.dq-m-64 {
  margin: 16rem;
}

.dq-m-72 {
  margin: 18rem;
}

.dq-m-80 {
  margin: 20rem;
}

.dq-m-96 {
  margin: 24rem;
}

.dq-m-auto {
  margin: auto;
}

.dq-m-px {
  margin: 1px;
}

.dq-m-0\.5 {
  margin: 0.125rem;
}

.dq-m-1\.5 {
  margin: 0.375rem;
}

.dq-m-2\.5 {
  margin: 0.625rem;
}

.dq-m-3\.5 {
  margin: 0.875rem;
}

.dq--m-0 {
  margin: 0px;
}

.dq--m-1 {
  margin: -0.25rem;
}

.dq--m-2 {
  margin: -0.5rem;
}

.dq--m-3 {
  margin: -0.75rem;
}

.dq--m-4 {
  margin: -1rem;
}

.dq--m-5 {
  margin: -1.25rem;
}

.dq--m-6 {
  margin: -1.5rem;
}

.dq--m-7 {
  margin: -1.75rem;
}

.dq--m-8 {
  margin: -2rem;
}

.dq--m-9 {
  margin: -2.25rem;
}

.dq--m-10 {
  margin: -2.5rem;
}

.dq--m-11 {
  margin: -2.75rem;
}

.dq--m-12 {
  margin: -3rem;
}

.dq--m-14 {
  margin: -3.5rem;
}

.dq--m-16 {
  margin: -4rem;
}

.dq--m-20 {
  margin: -5rem;
}

.dq--m-24 {
  margin: -6rem;
}

.dq--m-28 {
  margin: -7rem;
}

.dq--m-32 {
  margin: -8rem;
}

.dq--m-36 {
  margin: -9rem;
}

.dq--m-40 {
  margin: -10rem;
}

.dq--m-44 {
  margin: -11rem;
}

.dq--m-48 {
  margin: -12rem;
}

.dq--m-52 {
  margin: -13rem;
}

.dq--m-56 {
  margin: -14rem;
}

.dq--m-60 {
  margin: -15rem;
}

.dq--m-64 {
  margin: -16rem;
}

.dq--m-72 {
  margin: -18rem;
}

.dq--m-80 {
  margin: -20rem;
}

.dq--m-96 {
  margin: -24rem;
}

.dq--m-px {
  margin: -1px;
}

.dq--m-0\.5 {
  margin: -0.125rem;
}

.dq--m-1\.5 {
  margin: -0.375rem;
}

.dq--m-2\.5 {
  margin: -0.625rem;
}

.dq--m-3\.5 {
  margin: -0.875rem;
}

.dq-mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.dq-mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.dq-mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.dq-mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.dq-mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.dq-mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.dq-mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.dq-mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.dq-mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.dq-mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.dq-mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.dq-mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.dq-mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.dq-mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.dq-mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.dq-mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.dq-mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.dq-mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.dq-mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.dq-mx-36 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.dq-mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.dq-mx-44 {
  margin-left: 11rem;
  margin-right: 11rem;
}

.dq-mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}

.dq-mx-52 {
  margin-left: 13rem;
  margin-right: 13rem;
}

.dq-mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}

.dq-mx-60 {
  margin-left: 15rem;
  margin-right: 15rem;
}

.dq-mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.dq-mx-72 {
  margin-left: 18rem;
  margin-right: 18rem;
}

.dq-mx-80 {
  margin-left: 20rem;
  margin-right: 20rem;
}

.dq-mx-96 {
  margin-left: 24rem;
  margin-right: 24rem;
}

.dq-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.dq-mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.dq-mx-0\.5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.dq-mx-1\.5 {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}

.dq-mx-2\.5 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.dq-mx-3\.5 {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}

.dq--mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.dq--mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.dq--mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.dq--mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.dq--mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.dq--mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.dq--mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.dq--mx-7 {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}

.dq--mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.dq--mx-9 {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}

.dq--mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.dq--mx-11 {
  margin-left: -2.75rem;
  margin-right: -2.75rem;
}

.dq--mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.dq--mx-14 {
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}

.dq--mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.dq--mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.dq--mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.dq--mx-28 {
  margin-left: -7rem;
  margin-right: -7rem;
}

.dq--mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.dq--mx-36 {
  margin-left: -9rem;
  margin-right: -9rem;
}

.dq--mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}

.dq--mx-44 {
  margin-left: -11rem;
  margin-right: -11rem;
}

.dq--mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.dq--mx-52 {
  margin-left: -13rem;
  margin-right: -13rem;
}

.dq--mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}

.dq--mx-60 {
  margin-left: -15rem;
  margin-right: -15rem;
}

.dq--mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}

.dq--mx-72 {
  margin-left: -18rem;
  margin-right: -18rem;
}

.dq--mx-80 {
  margin-left: -20rem;
  margin-right: -20rem;
}

.dq--mx-96 {
  margin-left: -24rem;
  margin-right: -24rem;
}

.dq--mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.dq--mx-0\.5 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.dq--mx-1\.5 {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}

.dq--mx-2\.5 {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.dq--mx-3\.5 {
  margin-left: -0.875rem;
  margin-right: -0.875rem;
}

.dq-my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.dq-my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.dq-my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.dq-my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.dq-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.dq-my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.dq-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.dq-my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.dq-my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.dq-my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.dq-my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.dq-my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.dq-my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.dq-my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.dq-my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.dq-my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.dq-my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.dq-my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.dq-my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.dq-my-36 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.dq-my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.dq-my-44 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}

.dq-my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.dq-my-52 {
  margin-top: 13rem;
  margin-bottom: 13rem;
}

.dq-my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.dq-my-60 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.dq-my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.dq-my-72 {
  margin-top: 18rem;
  margin-bottom: 18rem;
}

.dq-my-80 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}

.dq-my-96 {
  margin-top: 24rem;
  margin-bottom: 24rem;
}

.dq-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.dq-my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.dq-my-0\.5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.dq-my-1\.5 {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.dq-my-2\.5 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.dq-my-3\.5 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

.dq--my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.dq--my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.dq--my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.dq--my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.dq--my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.dq--my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.dq--my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.dq--my-7 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}

.dq--my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.dq--my-9 {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}

.dq--my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.dq--my-11 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}

.dq--my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.dq--my-14 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.dq--my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.dq--my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.dq--my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.dq--my-28 {
  margin-top: -7rem;
  margin-bottom: -7rem;
}

.dq--my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.dq--my-36 {
  margin-top: -9rem;
  margin-bottom: -9rem;
}

.dq--my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.dq--my-44 {
  margin-top: -11rem;
  margin-bottom: -11rem;
}

.dq--my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.dq--my-52 {
  margin-top: -13rem;
  margin-bottom: -13rem;
}

.dq--my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.dq--my-60 {
  margin-top: -15rem;
  margin-bottom: -15rem;
}

.dq--my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.dq--my-72 {
  margin-top: -18rem;
  margin-bottom: -18rem;
}

.dq--my-80 {
  margin-top: -20rem;
  margin-bottom: -20rem;
}

.dq--my-96 {
  margin-top: -24rem;
  margin-bottom: -24rem;
}

.dq--my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.dq--my-0\.5 {
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
}

.dq--my-1\.5 {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}

.dq--my-2\.5 {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}

.dq--my-3\.5 {
  margin-top: -0.875rem;
  margin-bottom: -0.875rem;
}

.dq-mt-0 {
  margin-top: 0px;
}

.dq-mt-1 {
  margin-top: 0.25rem;
}

.dq-mt-2 {
  margin-top: 0.5rem;
}

.dq-mt-3 {
  margin-top: 0.75rem;
}

.dq-mt-4 {
  margin-top: 1rem;
}

.dq-mt-5 {
  margin-top: 1.25rem;
}

.dq-mt-6 {
  margin-top: 1.5rem;
}

.dq-mt-7 {
  margin-top: 1.75rem;
}

.dq-mt-8 {
  margin-top: 2rem;
}

.dq-mt-9 {
  margin-top: 2.25rem;
}

.dq-mt-10 {
  margin-top: 2.5rem;
}

.dq-mt-11 {
  margin-top: 2.75rem;
}

.dq-mt-12 {
  margin-top: 3rem;
}

.dq-mt-14 {
  margin-top: 3.5rem;
}

.dq-mt-16 {
  margin-top: 4rem;
}

.dq-mt-20 {
  margin-top: 5rem;
}

.dq-mt-24 {
  margin-top: 6rem;
}

.dq-mt-28 {
  margin-top: 7rem;
}

.dq-mt-32 {
  margin-top: 8rem;
}

.dq-mt-36 {
  margin-top: 9rem;
}

.dq-mt-40 {
  margin-top: 10rem;
}

.dq-mt-44 {
  margin-top: 11rem;
}

.dq-mt-48 {
  margin-top: 12rem;
}

.dq-mt-52 {
  margin-top: 13rem;
}

.dq-mt-56 {
  margin-top: 14rem;
}

.dq-mt-60 {
  margin-top: 15rem;
}

.dq-mt-64 {
  margin-top: 16rem;
}

.dq-mt-72 {
  margin-top: 18rem;
}

.dq-mt-80 {
  margin-top: 20rem;
}

.dq-mt-96 {
  margin-top: 24rem;
}

.dq-mt-auto {
  margin-top: auto;
}

.dq-mt-px {
  margin-top: 1px;
}

.dq-mt-0\.5 {
  margin-top: 0.125rem;
}

.dq-mt-1\.5 {
  margin-top: 0.375rem;
}

.dq-mt-2\.5 {
  margin-top: 0.625rem;
}

.dq-mt-3\.5 {
  margin-top: 0.875rem;
}

.dq--mt-0 {
  margin-top: 0px;
}

.dq--mt-1 {
  margin-top: -0.25rem;
}

.dq--mt-2 {
  margin-top: -0.5rem;
}

.dq--mt-3 {
  margin-top: -0.75rem;
}

.dq--mt-4 {
  margin-top: -1rem;
}

.dq--mt-5 {
  margin-top: -1.25rem;
}

.dq--mt-6 {
  margin-top: -1.5rem;
}

.dq--mt-7 {
  margin-top: -1.75rem;
}

.dq--mt-8 {
  margin-top: -2rem;
}

.dq--mt-9 {
  margin-top: -2.25rem;
}

.dq--mt-10 {
  margin-top: -2.5rem;
}

.dq--mt-11 {
  margin-top: -2.75rem;
}

.dq--mt-12 {
  margin-top: -3rem;
}

.dq--mt-14 {
  margin-top: -3.5rem;
}

.dq--mt-16 {
  margin-top: -4rem;
}

.dq--mt-20 {
  margin-top: -5rem;
}

.dq--mt-24 {
  margin-top: -6rem;
}

.dq--mt-28 {
  margin-top: -7rem;
}

.dq--mt-32 {
  margin-top: -8rem;
}

.dq--mt-36 {
  margin-top: -9rem;
}

.dq--mt-40 {
  margin-top: -10rem;
}

.dq--mt-44 {
  margin-top: -11rem;
}

.dq--mt-48 {
  margin-top: -12rem;
}

.dq--mt-52 {
  margin-top: -13rem;
}

.dq--mt-56 {
  margin-top: -14rem;
}

.dq--mt-60 {
  margin-top: -15rem;
}

.dq--mt-64 {
  margin-top: -16rem;
}

.dq--mt-72 {
  margin-top: -18rem;
}

.dq--mt-80 {
  margin-top: -20rem;
}

.dq--mt-96 {
  margin-top: -24rem;
}

.dq--mt-px {
  margin-top: -1px;
}

.dq--mt-0\.5 {
  margin-top: -0.125rem;
}

.dq--mt-1\.5 {
  margin-top: -0.375rem;
}

.dq--mt-2\.5 {
  margin-top: -0.625rem;
}

.dq--mt-3\.5 {
  margin-top: -0.875rem;
}

.dq-mr-0 {
  margin-right: 0px;
}

.dq-mr-1 {
  margin-right: 0.25rem;
}

.dq-mr-2 {
  margin-right: 0.5rem;
}

.dq-mr-3 {
  margin-right: 0.75rem;
}

.dq-mr-4 {
  margin-right: 1rem;
}

.dq-mr-5 {
  margin-right: 1.25rem;
}

.dq-mr-6 {
  margin-right: 1.5rem;
}

.dq-mr-7 {
  margin-right: 1.75rem;
}

.dq-mr-8 {
  margin-right: 2rem;
}

.dq-mr-9 {
  margin-right: 2.25rem;
}

.dq-mr-10 {
  margin-right: 2.5rem;
}

.dq-mr-11 {
  margin-right: 2.75rem;
}

.dq-mr-12 {
  margin-right: 3rem;
}

.dq-mr-14 {
  margin-right: 3.5rem;
}

.dq-mr-16 {
  margin-right: 4rem;
}

.dq-mr-20 {
  margin-right: 5rem;
}

.dq-mr-24 {
  margin-right: 6rem;
}

.dq-mr-28 {
  margin-right: 7rem;
}

.dq-mr-32 {
  margin-right: 8rem;
}

.dq-mr-36 {
  margin-right: 9rem;
}

.dq-mr-40 {
  margin-right: 10rem;
}

.dq-mr-44 {
  margin-right: 11rem;
}

.dq-mr-48 {
  margin-right: 12rem;
}

.dq-mr-52 {
  margin-right: 13rem;
}

.dq-mr-56 {
  margin-right: 14rem;
}

.dq-mr-60 {
  margin-right: 15rem;
}

.dq-mr-64 {
  margin-right: 16rem;
}

.dq-mr-72 {
  margin-right: 18rem;
}

.dq-mr-80 {
  margin-right: 20rem;
}

.dq-mr-96 {
  margin-right: 24rem;
}

.dq-mr-auto {
  margin-right: auto;
}

.dq-mr-px {
  margin-right: 1px;
}

.dq-mr-0\.5 {
  margin-right: 0.125rem;
}

.dq-mr-1\.5 {
  margin-right: 0.375rem;
}

.dq-mr-2\.5 {
  margin-right: 0.625rem;
}

.dq-mr-3\.5 {
  margin-right: 0.875rem;
}

.dq--mr-0 {
  margin-right: 0px;
}

.dq--mr-1 {
  margin-right: -0.25rem;
}

.dq--mr-2 {
  margin-right: -0.5rem;
}

.dq--mr-3 {
  margin-right: -0.75rem;
}

.dq--mr-4 {
  margin-right: -1rem;
}

.dq--mr-5 {
  margin-right: -1.25rem;
}

.dq--mr-6 {
  margin-right: -1.5rem;
}

.dq--mr-7 {
  margin-right: -1.75rem;
}

.dq--mr-8 {
  margin-right: -2rem;
}

.dq--mr-9 {
  margin-right: -2.25rem;
}

.dq--mr-10 {
  margin-right: -2.5rem;
}

.dq--mr-11 {
  margin-right: -2.75rem;
}

.dq--mr-12 {
  margin-right: -3rem;
}

.dq--mr-14 {
  margin-right: -3.5rem;
}

.dq--mr-16 {
  margin-right: -4rem;
}

.dq--mr-20 {
  margin-right: -5rem;
}

.dq--mr-24 {
  margin-right: -6rem;
}

.dq--mr-28 {
  margin-right: -7rem;
}

.dq--mr-32 {
  margin-right: -8rem;
}

.dq--mr-36 {
  margin-right: -9rem;
}

.dq--mr-40 {
  margin-right: -10rem;
}

.dq--mr-44 {
  margin-right: -11rem;
}

.dq--mr-48 {
  margin-right: -12rem;
}

.dq--mr-52 {
  margin-right: -13rem;
}

.dq--mr-56 {
  margin-right: -14rem;
}

.dq--mr-60 {
  margin-right: -15rem;
}

.dq--mr-64 {
  margin-right: -16rem;
}

.dq--mr-72 {
  margin-right: -18rem;
}

.dq--mr-80 {
  margin-right: -20rem;
}

.dq--mr-96 {
  margin-right: -24rem;
}

.dq--mr-px {
  margin-right: -1px;
}

.dq--mr-0\.5 {
  margin-right: -0.125rem;
}

.dq--mr-1\.5 {
  margin-right: -0.375rem;
}

.dq--mr-2\.5 {
  margin-right: -0.625rem;
}

.dq--mr-3\.5 {
  margin-right: -0.875rem;
}

.dq-mb-0 {
  margin-bottom: 0px;
}

.dq-mb-1 {
  margin-bottom: 0.25rem;
}

.dq-mb-2 {
  margin-bottom: 0.5rem;
}

.dq-mb-3 {
  margin-bottom: 0.75rem;
}

.dq-mb-4 {
  margin-bottom: 1rem;
}

.dq-mb-5 {
  margin-bottom: 1.25rem;
}

.dq-mb-6 {
  margin-bottom: 1.5rem;
}

.dq-mb-7 {
  margin-bottom: 1.75rem;
}

.dq-mb-8 {
  margin-bottom: 2rem;
}

.dq-mb-9 {
  margin-bottom: 2.25rem;
}

.dq-mb-10 {
  margin-bottom: 2.5rem;
}

.dq-mb-11 {
  margin-bottom: 2.75rem;
}

.dq-mb-12 {
  margin-bottom: 3rem;
}

.dq-mb-14 {
  margin-bottom: 3.5rem;
}

.dq-mb-16 {
  margin-bottom: 4rem;
}

.dq-mb-20 {
  margin-bottom: 5rem;
}

.dq-mb-24 {
  margin-bottom: 6rem;
}

.dq-mb-28 {
  margin-bottom: 7rem;
}

.dq-mb-32 {
  margin-bottom: 8rem;
}

.dq-mb-36 {
  margin-bottom: 9rem;
}

.dq-mb-40 {
  margin-bottom: 10rem;
}

.dq-mb-44 {
  margin-bottom: 11rem;
}

.dq-mb-48 {
  margin-bottom: 12rem;
}

.dq-mb-52 {
  margin-bottom: 13rem;
}

.dq-mb-56 {
  margin-bottom: 14rem;
}

.dq-mb-60 {
  margin-bottom: 15rem;
}

.dq-mb-64 {
  margin-bottom: 16rem;
}

.dq-mb-72 {
  margin-bottom: 18rem;
}

.dq-mb-80 {
  margin-bottom: 20rem;
}

.dq-mb-96 {
  margin-bottom: 24rem;
}

.dq-mb-auto {
  margin-bottom: auto;
}

.dq-mb-px {
  margin-bottom: 1px;
}

.dq-mb-0\.5 {
  margin-bottom: 0.125rem;
}

.dq-mb-1\.5 {
  margin-bottom: 0.375rem;
}

.dq-mb-2\.5 {
  margin-bottom: 0.625rem;
}

.dq-mb-3\.5 {
  margin-bottom: 0.875rem;
}

.dq--mb-0 {
  margin-bottom: 0px;
}

.dq--mb-1 {
  margin-bottom: -0.25rem;
}

.dq--mb-2 {
  margin-bottom: -0.5rem;
}

.dq--mb-3 {
  margin-bottom: -0.75rem;
}

.dq--mb-4 {
  margin-bottom: -1rem;
}

.dq--mb-5 {
  margin-bottom: -1.25rem;
}

.dq--mb-6 {
  margin-bottom: -1.5rem;
}

.dq--mb-7 {
  margin-bottom: -1.75rem;
}

.dq--mb-8 {
  margin-bottom: -2rem;
}

.dq--mb-9 {
  margin-bottom: -2.25rem;
}

.dq--mb-10 {
  margin-bottom: -2.5rem;
}

.dq--mb-11 {
  margin-bottom: -2.75rem;
}

.dq--mb-12 {
  margin-bottom: -3rem;
}

.dq--mb-14 {
  margin-bottom: -3.5rem;
}

.dq--mb-16 {
  margin-bottom: -4rem;
}

.dq--mb-20 {
  margin-bottom: -5rem;
}

.dq--mb-24 {
  margin-bottom: -6rem;
}

.dq--mb-28 {
  margin-bottom: -7rem;
}

.dq--mb-32 {
  margin-bottom: -8rem;
}

.dq--mb-36 {
  margin-bottom: -9rem;
}

.dq--mb-40 {
  margin-bottom: -10rem;
}

.dq--mb-44 {
  margin-bottom: -11rem;
}

.dq--mb-48 {
  margin-bottom: -12rem;
}

.dq--mb-52 {
  margin-bottom: -13rem;
}

.dq--mb-56 {
  margin-bottom: -14rem;
}

.dq--mb-60 {
  margin-bottom: -15rem;
}

.dq--mb-64 {
  margin-bottom: -16rem;
}

.dq--mb-72 {
  margin-bottom: -18rem;
}

.dq--mb-80 {
  margin-bottom: -20rem;
}

.dq--mb-96 {
  margin-bottom: -24rem;
}

.dq--mb-px {
  margin-bottom: -1px;
}

.dq--mb-0\.5 {
  margin-bottom: -0.125rem;
}

.dq--mb-1\.5 {
  margin-bottom: -0.375rem;
}

.dq--mb-2\.5 {
  margin-bottom: -0.625rem;
}

.dq--mb-3\.5 {
  margin-bottom: -0.875rem;
}

.dq-ml-0 {
  margin-left: 0px;
}

.dq-ml-1 {
  margin-left: 0.25rem;
}

.dq-ml-2 {
  margin-left: 0.5rem;
}

.dq-ml-3 {
  margin-left: 0.75rem;
}

.dq-ml-4 {
  margin-left: 1rem;
}

.dq-ml-5 {
  margin-left: 1.25rem;
}

.dq-ml-6 {
  margin-left: 1.5rem;
}

.dq-ml-7 {
  margin-left: 1.75rem;
}

.dq-ml-8 {
  margin-left: 2rem;
}

.dq-ml-9 {
  margin-left: 2.25rem;
}

.dq-ml-10 {
  margin-left: 2.5rem;
}

.dq-ml-11 {
  margin-left: 2.75rem;
}

.dq-ml-12 {
  margin-left: 3rem;
}

.dq-ml-14 {
  margin-left: 3.5rem;
}

.dq-ml-16 {
  margin-left: 4rem;
}

.dq-ml-20 {
  margin-left: 5rem;
}

.dq-ml-24 {
  margin-left: 6rem;
}

.dq-ml-28 {
  margin-left: 7rem;
}

.dq-ml-32 {
  margin-left: 8rem;
}

.dq-ml-36 {
  margin-left: 9rem;
}

.dq-ml-40 {
  margin-left: 10rem;
}

.dq-ml-44 {
  margin-left: 11rem;
}

.dq-ml-48 {
  margin-left: 12rem;
}

.dq-ml-52 {
  margin-left: 13rem;
}

.dq-ml-56 {
  margin-left: 14rem;
}

.dq-ml-60 {
  margin-left: 15rem;
}

.dq-ml-64 {
  margin-left: 16rem;
}

.dq-ml-72 {
  margin-left: 18rem;
}

.dq-ml-80 {
  margin-left: 20rem;
}

.dq-ml-96 {
  margin-left: 24rem;
}

.dq-ml-auto {
  margin-left: auto;
}

.dq-ml-px {
  margin-left: 1px;
}

.dq-ml-0\.5 {
  margin-left: 0.125rem;
}

.dq-ml-1\.5 {
  margin-left: 0.375rem;
}

.dq-ml-2\.5 {
  margin-left: 0.625rem;
}

.dq-ml-3\.5 {
  margin-left: 0.875rem;
}

.dq--ml-0 {
  margin-left: 0px;
}

.dq--ml-1 {
  margin-left: -0.25rem;
}

.dq--ml-2 {
  margin-left: -0.5rem;
}

.dq--ml-3 {
  margin-left: -0.75rem;
}

.dq--ml-4 {
  margin-left: -1rem;
}

.dq--ml-5 {
  margin-left: -1.25rem;
}

.dq--ml-6 {
  margin-left: -1.5rem;
}

.dq--ml-7 {
  margin-left: -1.75rem;
}

.dq--ml-8 {
  margin-left: -2rem;
}

.dq--ml-9 {
  margin-left: -2.25rem;
}

.dq--ml-10 {
  margin-left: -2.5rem;
}

.dq--ml-11 {
  margin-left: -2.75rem;
}

.dq--ml-12 {
  margin-left: -3rem;
}

.dq--ml-14 {
  margin-left: -3.5rem;
}

.dq--ml-16 {
  margin-left: -4rem;
}

.dq--ml-20 {
  margin-left: -5rem;
}

.dq--ml-24 {
  margin-left: -6rem;
}

.dq--ml-28 {
  margin-left: -7rem;
}

.dq--ml-32 {
  margin-left: -8rem;
}

.dq--ml-36 {
  margin-left: -9rem;
}

.dq--ml-40 {
  margin-left: -10rem;
}

.dq--ml-44 {
  margin-left: -11rem;
}

.dq--ml-48 {
  margin-left: -12rem;
}

.dq--ml-52 {
  margin-left: -13rem;
}

.dq--ml-56 {
  margin-left: -14rem;
}

.dq--ml-60 {
  margin-left: -15rem;
}

.dq--ml-64 {
  margin-left: -16rem;
}

.dq--ml-72 {
  margin-left: -18rem;
}

.dq--ml-80 {
  margin-left: -20rem;
}

.dq--ml-96 {
  margin-left: -24rem;
}

.dq--ml-px {
  margin-left: -1px;
}

.dq--ml-0\.5 {
  margin-left: -0.125rem;
}

.dq--ml-1\.5 {
  margin-left: -0.375rem;
}

.dq--ml-2\.5 {
  margin-left: -0.625rem;
}

.dq--ml-3\.5 {
  margin-left: -0.875rem;
}

.dq-box-border {
  box-sizing: border-box;
}

.dq-box-content {
  box-sizing: content-box;
}

.dq-block {
  display: block;
}

.dq-inline-block {
  display: inline-block;
}

.dq-inline {
  display: inline;
}

.dq-flex {
  display: flex;
}

.dq-inline-flex {
  display: inline-flex;
}

.dq-table {
  display: table;
}

.dq-inline-table {
  display: inline-table;
}

.dq-table-caption {
  display: table-caption;
}

.dq-table-cell {
  display: table-cell;
}

.dq-table-column {
  display: table-column;
}

.dq-table-column-group {
  display: table-column-group;
}

.dq-table-footer-group {
  display: table-footer-group;
}

.dq-table-header-group {
  display: table-header-group;
}

.dq-table-row-group {
  display: table-row-group;
}

.dq-table-row {
  display: table-row;
}

.dq-flow-root {
  display: flow-root;
}

.dq-grid {
  display: grid;
}

.dq-inline-grid {
  display: inline-grid;
}

.dq-contents {
  display: contents;
}

.dq-list-item {
  display: list-item;
}

.dq-hidden {
  display: none;
}

.dq-h-0 {
  height: 0px;
}

.dq-h-1 {
  height: 0.25rem;
}

.dq-h-2 {
  height: 0.5rem;
}

.dq-h-3 {
  height: 0.75rem;
}

.dq-h-4 {
  height: 1rem;
}

.dq-h-5 {
  height: 1.25rem;
}

.dq-h-6 {
  height: 1.5rem;
}

.dq-h-7 {
  height: 1.75rem;
}

.dq-h-8 {
  height: 2rem;
}

.dq-h-9 {
  height: 2.25rem;
}

.dq-h-10 {
  height: 2.5rem;
}

.dq-h-11 {
  height: 2.75rem;
}

.dq-h-12 {
  height: 3rem;
}

.dq-h-14 {
  height: 3.5rem;
}

.dq-h-16 {
  height: 4rem;
}

.dq-h-20 {
  height: 5rem;
}

.dq-h-24 {
  height: 6rem;
}

.dq-h-28 {
  height: 7rem;
}

.dq-h-32 {
  height: 8rem;
}

.dq-h-36 {
  height: 9rem;
}

.dq-h-40 {
  height: 10rem;
}

.dq-h-44 {
  height: 11rem;
}

.dq-h-48 {
  height: 12rem;
}

.dq-h-52 {
  height: 13rem;
}

.dq-h-56 {
  height: 14rem;
}

.dq-h-60 {
  height: 15rem;
}

.dq-h-64 {
  height: 16rem;
}

.dq-h-72 {
  height: 18rem;
}

.dq-h-80 {
  height: 20rem;
}

.dq-h-96 {
  height: 24rem;
}

.dq-h-auto {
  height: auto;
}

.dq-h-px {
  height: 1px;
}

.dq-h-0\.5 {
  height: 0.125rem;
}

.dq-h-1\.5 {
  height: 0.375rem;
}

.dq-h-2\.5 {
  height: 0.625rem;
}

.dq-h-3\.5 {
  height: 0.875rem;
}

.dq-h-1\/2 {
  height: 50%;
}

.dq-h-1\/3 {
  height: 33.333333%;
}

.dq-h-2\/3 {
  height: 66.666667%;
}

.dq-h-1\/4 {
  height: 25%;
}

.dq-h-2\/4 {
  height: 50%;
}

.dq-h-3\/4 {
  height: 75%;
}

.dq-h-1\/5 {
  height: 20%;
}

.dq-h-2\/5 {
  height: 40%;
}

.dq-h-3\/5 {
  height: 60%;
}

.dq-h-4\/5 {
  height: 80%;
}

.dq-h-1\/6 {
  height: 16.666667%;
}

.dq-h-2\/6 {
  height: 33.333333%;
}

.dq-h-3\/6 {
  height: 50%;
}

.dq-h-4\/6 {
  height: 66.666667%;
}

.dq-h-5\/6 {
  height: 83.333333%;
}

.dq-h-full {
  height: 100%;
}

.dq-h-screen {
  height: 100vh;
}

.dq-max-h-0 {
  max-height: 0px;
}

.dq-max-h-1 {
  max-height: 0.25rem;
}

.dq-max-h-2 {
  max-height: 0.5rem;
}

.dq-max-h-3 {
  max-height: 0.75rem;
}

.dq-max-h-4 {
  max-height: 1rem;
}

.dq-max-h-5 {
  max-height: 1.25rem;
}

.dq-max-h-6 {
  max-height: 1.5rem;
}

.dq-max-h-7 {
  max-height: 1.75rem;
}

.dq-max-h-8 {
  max-height: 2rem;
}

.dq-max-h-9 {
  max-height: 2.25rem;
}

.dq-max-h-10 {
  max-height: 2.5rem;
}

.dq-max-h-11 {
  max-height: 2.75rem;
}

.dq-max-h-12 {
  max-height: 3rem;
}

.dq-max-h-14 {
  max-height: 3.5rem;
}

.dq-max-h-16 {
  max-height: 4rem;
}

.dq-max-h-20 {
  max-height: 5rem;
}

.dq-max-h-24 {
  max-height: 6rem;
}

.dq-max-h-28 {
  max-height: 7rem;
}

.dq-max-h-32 {
  max-height: 8rem;
}

.dq-max-h-36 {
  max-height: 9rem;
}

.dq-max-h-40 {
  max-height: 10rem;
}

.dq-max-h-44 {
  max-height: 11rem;
}

.dq-max-h-48 {
  max-height: 12rem;
}

.dq-max-h-52 {
  max-height: 13rem;
}

.dq-max-h-56 {
  max-height: 14rem;
}

.dq-max-h-60 {
  max-height: 15rem;
}

.dq-max-h-64 {
  max-height: 16rem;
}

.dq-max-h-72 {
  max-height: 18rem;
}

.dq-max-h-80 {
  max-height: 20rem;
}

.dq-max-h-96 {
  max-height: 24rem;
}

.dq-max-h-px {
  max-height: 1px;
}

.dq-max-h-0\.5 {
  max-height: 0.125rem;
}

.dq-max-h-1\.5 {
  max-height: 0.375rem;
}

.dq-max-h-2\.5 {
  max-height: 0.625rem;
}

.dq-max-h-3\.5 {
  max-height: 0.875rem;
}

.dq-max-h-full {
  max-height: 100%;
}

.dq-max-h-screen {
  max-height: 100vh;
}

.dq-min-h-0 {
  min-height: 0px;
}

.dq-min-h-full {
  min-height: 100%;
}

.dq-min-h-screen {
  min-height: 100vh;
}

.dq-w-0 {
  width: 0px;
}

.dq-w-1 {
  width: 0.25rem;
}

.dq-w-2 {
  width: 0.5rem;
}

.dq-w-3 {
  width: 0.75rem;
}

.dq-w-4 {
  width: 1rem;
}

.dq-w-5 {
  width: 1.25rem;
}

.dq-w-6 {
  width: 1.5rem;
}

.dq-w-7 {
  width: 1.75rem;
}

.dq-w-8 {
  width: 2rem;
}

.dq-w-9 {
  width: 2.25rem;
}

.dq-w-10 {
  width: 2.5rem;
}

.dq-w-11 {
  width: 2.75rem;
}

.dq-w-12 {
  width: 3rem;
}

.dq-w-14 {
  width: 3.5rem;
}

.dq-w-16 {
  width: 4rem;
}

.dq-w-20 {
  width: 5rem;
}

.dq-w-24 {
  width: 6rem;
}

.dq-w-28 {
  width: 7rem;
}

.dq-w-32 {
  width: 8rem;
}

.dq-w-36 {
  width: 9rem;
}

.dq-w-40 {
  width: 10rem;
}

.dq-w-44 {
  width: 11rem;
}

.dq-w-48 {
  width: 12rem;
}

.dq-w-52 {
  width: 13rem;
}

.dq-w-56 {
  width: 14rem;
}

.dq-w-60 {
  width: 15rem;
}

.dq-w-64 {
  width: 16rem;
}

.dq-w-72 {
  width: 18rem;
}

.dq-w-80 {
  width: 20rem;
}

.dq-w-96 {
  width: 24rem;
}

.dq-w-auto {
  width: auto;
}

.dq-w-px {
  width: 1px;
}

.dq-w-0\.5 {
  width: 0.125rem;
}

.dq-w-1\.5 {
  width: 0.375rem;
}

.dq-w-2\.5 {
  width: 0.625rem;
}

.dq-w-3\.5 {
  width: 0.875rem;
}

.dq-w-1\/2 {
  width: 50%;
}

.dq-w-1\/3 {
  width: 33.333333%;
}

.dq-w-2\/3 {
  width: 66.666667%;
}

.dq-w-1\/4 {
  width: 25%;
}

.dq-w-2\/4 {
  width: 50%;
}

.dq-w-3\/4 {
  width: 75%;
}

.dq-w-1\/5 {
  width: 20%;
}

.dq-w-2\/5 {
  width: 40%;
}

.dq-w-3\/5 {
  width: 60%;
}

.dq-w-4\/5 {
  width: 80%;
}

.dq-w-1\/6 {
  width: 16.666667%;
}

.dq-w-2\/6 {
  width: 33.333333%;
}

.dq-w-3\/6 {
  width: 50%;
}

.dq-w-4\/6 {
  width: 66.666667%;
}

.dq-w-5\/6 {
  width: 83.333333%;
}

.dq-w-1\/12 {
  width: 8.333333%;
}

.dq-w-2\/12 {
  width: 16.666667%;
}

.dq-w-3\/12 {
  width: 25%;
}

.dq-w-4\/12 {
  width: 33.333333%;
}

.dq-w-5\/12 {
  width: 41.666667%;
}

.dq-w-6\/12 {
  width: 50%;
}

.dq-w-7\/12 {
  width: 58.333333%;
}

.dq-w-8\/12 {
  width: 66.666667%;
}

.dq-w-9\/12 {
  width: 75%;
}

.dq-w-10\/12 {
  width: 83.333333%;
}

.dq-w-11\/12 {
  width: 91.666667%;
}

.dq-w-full {
  width: 100%;
}

.dq-w-screen {
  width: 100vw;
}

.dq-w-min {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.dq-w-max {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.dq-min-w-0 {
  min-width: 0px;
}

.dq-min-w-full {
  min-width: 100%;
}

.dq-min-w-min {
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
}

.dq-min-w-max {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.dq-max-w-0 {
  max-width: 0rem;
}

.dq-max-w-none {
  max-width: none;
}

.dq-max-w-xs {
  max-width: 20rem;
}

.dq-max-w-sm {
  max-width: 24rem;
}

.dq-max-w-md {
  max-width: 28rem;
}

.dq-max-w-lg {
  max-width: 32rem;
}

.dq-max-w-xl {
  max-width: 36rem;
}

.dq-max-w-2xl {
  max-width: 42rem;
}

.dq-max-w-3xl {
  max-width: 48rem;
}

.dq-max-w-4xl {
  max-width: 56rem;
}

.dq-max-w-5xl {
  max-width: 64rem;
}

.dq-max-w-6xl {
  max-width: 72rem;
}

.dq-max-w-7xl {
  max-width: 80rem;
}

.dq-max-w-full {
  max-width: 100%;
}

.dq-max-w-min {
  max-width: -webkit-min-content;
  max-width: -moz-min-content;
  max-width: min-content;
}

.dq-max-w-max {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

.dq-max-w-prose {
  max-width: 65ch;
}

.dq-max-w-screen-sm {
  max-width: 640px;
}

.dq-max-w-screen-md {
  max-width: 768px;
}

.dq-max-w-screen-lg {
  max-width: 1024px;
}

.dq-max-w-screen-xl {
  max-width: 1280px;
}

.dq-max-w-screen-2xl {
  max-width: 1536px;
}

.dq-flex-1 {
  flex: 1 1 0%;
}

.dq-flex-auto {
  flex: 1 1 auto;
}

.dq-flex-initial {
  flex: 0 1 auto;
}

.dq-flex-none {
  flex: none;
}

.dq-flex-shrink-0 {
  flex-shrink: 0;
}

.dq-flex-shrink {
  flex-shrink: 1;
}

.dq-flex-grow-0 {
  flex-grow: 0;
}

.dq-flex-grow {
  flex-grow: 1;
}

.dq-table-auto {
  table-layout: auto;
}

.dq-table-fixed {
  table-layout: fixed;
}

.dq-border-collapse {
  border-collapse: collapse;
}

.dq-border-separate {
  border-collapse: separate;
}

.dq-origin-center {
  transform-origin: center;
}

.dq-origin-top {
  transform-origin: top;
}

.dq-origin-top-right {
  transform-origin: top right;
}

.dq-origin-right {
  transform-origin: right;
}

.dq-origin-bottom-right {
  transform-origin: bottom right;
}

.dq-origin-bottom {
  transform-origin: bottom;
}

.dq-origin-bottom-left {
  transform-origin: bottom left;
}

.dq-origin-left {
  transform-origin: left;
}

.dq-origin-top-left {
  transform-origin: top left;
}

.dq-transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.dq-transform-gpu {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.dq-transform-none {
  transform: none;
}

.dq-translate-x-0 {
  --tw-translate-x: 0px;
}

.dq-translate-x-1 {
  --tw-translate-x: 0.25rem;
}

.dq-translate-x-2 {
  --tw-translate-x: 0.5rem;
}

.dq-translate-x-3 {
  --tw-translate-x: 0.75rem;
}

.dq-translate-x-4 {
  --tw-translate-x: 1rem;
}

.dq-translate-x-5 {
  --tw-translate-x: 1.25rem;
}

.dq-translate-x-6 {
  --tw-translate-x: 1.5rem;
}

.dq-translate-x-7 {
  --tw-translate-x: 1.75rem;
}

.dq-translate-x-8 {
  --tw-translate-x: 2rem;
}

.dq-translate-x-9 {
  --tw-translate-x: 2.25rem;
}

.dq-translate-x-10 {
  --tw-translate-x: 2.5rem;
}

.dq-translate-x-11 {
  --tw-translate-x: 2.75rem;
}

.dq-translate-x-12 {
  --tw-translate-x: 3rem;
}

.dq-translate-x-14 {
  --tw-translate-x: 3.5rem;
}

.dq-translate-x-16 {
  --tw-translate-x: 4rem;
}

.dq-translate-x-20 {
  --tw-translate-x: 5rem;
}

.dq-translate-x-24 {
  --tw-translate-x: 6rem;
}

.dq-translate-x-28 {
  --tw-translate-x: 7rem;
}

.dq-translate-x-32 {
  --tw-translate-x: 8rem;
}

.dq-translate-x-36 {
  --tw-translate-x: 9rem;
}

.dq-translate-x-40 {
  --tw-translate-x: 10rem;
}

.dq-translate-x-44 {
  --tw-translate-x: 11rem;
}

.dq-translate-x-48 {
  --tw-translate-x: 12rem;
}

.dq-translate-x-52 {
  --tw-translate-x: 13rem;
}

.dq-translate-x-56 {
  --tw-translate-x: 14rem;
}

.dq-translate-x-60 {
  --tw-translate-x: 15rem;
}

.dq-translate-x-64 {
  --tw-translate-x: 16rem;
}

.dq-translate-x-72 {
  --tw-translate-x: 18rem;
}

.dq-translate-x-80 {
  --tw-translate-x: 20rem;
}

.dq-translate-x-96 {
  --tw-translate-x: 24rem;
}

.dq-translate-x-px {
  --tw-translate-x: 1px;
}

.dq-translate-x-0\.5 {
  --tw-translate-x: 0.125rem;
}

.dq-translate-x-1\.5 {
  --tw-translate-x: 0.375rem;
}

.dq-translate-x-2\.5 {
  --tw-translate-x: 0.625rem;
}

.dq-translate-x-3\.5 {
  --tw-translate-x: 0.875rem;
}

.dq--translate-x-0 {
  --tw-translate-x: 0px;
}

.dq--translate-x-1 {
  --tw-translate-x: -0.25rem;
}

.dq--translate-x-2 {
  --tw-translate-x: -0.5rem;
}

.dq--translate-x-3 {
  --tw-translate-x: -0.75rem;
}

.dq--translate-x-4 {
  --tw-translate-x: -1rem;
}

.dq--translate-x-5 {
  --tw-translate-x: -1.25rem;
}

.dq--translate-x-6 {
  --tw-translate-x: -1.5rem;
}

.dq--translate-x-7 {
  --tw-translate-x: -1.75rem;
}

.dq--translate-x-8 {
  --tw-translate-x: -2rem;
}

.dq--translate-x-9 {
  --tw-translate-x: -2.25rem;
}

.dq--translate-x-10 {
  --tw-translate-x: -2.5rem;
}

.dq--translate-x-11 {
  --tw-translate-x: -2.75rem;
}

.dq--translate-x-12 {
  --tw-translate-x: -3rem;
}

.dq--translate-x-14 {
  --tw-translate-x: -3.5rem;
}

.dq--translate-x-16 {
  --tw-translate-x: -4rem;
}

.dq--translate-x-20 {
  --tw-translate-x: -5rem;
}

.dq--translate-x-24 {
  --tw-translate-x: -6rem;
}

.dq--translate-x-28 {
  --tw-translate-x: -7rem;
}

.dq--translate-x-32 {
  --tw-translate-x: -8rem;
}

.dq--translate-x-36 {
  --tw-translate-x: -9rem;
}

.dq--translate-x-40 {
  --tw-translate-x: -10rem;
}

.dq--translate-x-44 {
  --tw-translate-x: -11rem;
}

.dq--translate-x-48 {
  --tw-translate-x: -12rem;
}

.dq--translate-x-52 {
  --tw-translate-x: -13rem;
}

.dq--translate-x-56 {
  --tw-translate-x: -14rem;
}

.dq--translate-x-60 {
  --tw-translate-x: -15rem;
}

.dq--translate-x-64 {
  --tw-translate-x: -16rem;
}

.dq--translate-x-72 {
  --tw-translate-x: -18rem;
}

.dq--translate-x-80 {
  --tw-translate-x: -20rem;
}

.dq--translate-x-96 {
  --tw-translate-x: -24rem;
}

.dq--translate-x-px {
  --tw-translate-x: -1px;
}

.dq--translate-x-0\.5 {
  --tw-translate-x: -0.125rem;
}

.dq--translate-x-1\.5 {
  --tw-translate-x: -0.375rem;
}

.dq--translate-x-2\.5 {
  --tw-translate-x: -0.625rem;
}

.dq--translate-x-3\.5 {
  --tw-translate-x: -0.875rem;
}

.dq-translate-x-1\/2 {
  --tw-translate-x: 50%;
}

.dq-translate-x-1\/3 {
  --tw-translate-x: 33.333333%;
}

.dq-translate-x-2\/3 {
  --tw-translate-x: 66.666667%;
}

.dq-translate-x-1\/4 {
  --tw-translate-x: 25%;
}

.dq-translate-x-2\/4 {
  --tw-translate-x: 50%;
}

.dq-translate-x-3\/4 {
  --tw-translate-x: 75%;
}

.dq-translate-x-full {
  --tw-translate-x: 100%;
}

.dq--translate-x-1\/2 {
  --tw-translate-x: -50%;
}

.dq--translate-x-1\/3 {
  --tw-translate-x: -33.333333%;
}

.dq--translate-x-2\/3 {
  --tw-translate-x: -66.666667%;
}

.dq--translate-x-1\/4 {
  --tw-translate-x: -25%;
}

.dq--translate-x-2\/4 {
  --tw-translate-x: -50%;
}

.dq--translate-x-3\/4 {
  --tw-translate-x: -75%;
}

.dq--translate-x-full {
  --tw-translate-x: -100%;
}

.dq-translate-y-0 {
  --tw-translate-y: 0px;
}

.dq-translate-y-1 {
  --tw-translate-y: 0.25rem;
}

.dq-translate-y-2 {
  --tw-translate-y: 0.5rem;
}

.dq-translate-y-3 {
  --tw-translate-y: 0.75rem;
}

.dq-translate-y-4 {
  --tw-translate-y: 1rem;
}

.dq-translate-y-5 {
  --tw-translate-y: 1.25rem;
}

.dq-translate-y-6 {
  --tw-translate-y: 1.5rem;
}

.dq-translate-y-7 {
  --tw-translate-y: 1.75rem;
}

.dq-translate-y-8 {
  --tw-translate-y: 2rem;
}

.dq-translate-y-9 {
  --tw-translate-y: 2.25rem;
}

.dq-translate-y-10 {
  --tw-translate-y: 2.5rem;
}

.dq-translate-y-11 {
  --tw-translate-y: 2.75rem;
}

.dq-translate-y-12 {
  --tw-translate-y: 3rem;
}

.dq-translate-y-14 {
  --tw-translate-y: 3.5rem;
}

.dq-translate-y-16 {
  --tw-translate-y: 4rem;
}

.dq-translate-y-20 {
  --tw-translate-y: 5rem;
}

.dq-translate-y-24 {
  --tw-translate-y: 6rem;
}

.dq-translate-y-28 {
  --tw-translate-y: 7rem;
}

.dq-translate-y-32 {
  --tw-translate-y: 8rem;
}

.dq-translate-y-36 {
  --tw-translate-y: 9rem;
}

.dq-translate-y-40 {
  --tw-translate-y: 10rem;
}

.dq-translate-y-44 {
  --tw-translate-y: 11rem;
}

.dq-translate-y-48 {
  --tw-translate-y: 12rem;
}

.dq-translate-y-52 {
  --tw-translate-y: 13rem;
}

.dq-translate-y-56 {
  --tw-translate-y: 14rem;
}

.dq-translate-y-60 {
  --tw-translate-y: 15rem;
}

.dq-translate-y-64 {
  --tw-translate-y: 16rem;
}

.dq-translate-y-72 {
  --tw-translate-y: 18rem;
}

.dq-translate-y-80 {
  --tw-translate-y: 20rem;
}

.dq-translate-y-96 {
  --tw-translate-y: 24rem;
}

.dq-translate-y-px {
  --tw-translate-y: 1px;
}

.dq-translate-y-0\.5 {
  --tw-translate-y: 0.125rem;
}

.dq-translate-y-1\.5 {
  --tw-translate-y: 0.375rem;
}

.dq-translate-y-2\.5 {
  --tw-translate-y: 0.625rem;
}

.dq-translate-y-3\.5 {
  --tw-translate-y: 0.875rem;
}

.dq--translate-y-0 {
  --tw-translate-y: 0px;
}

.dq--translate-y-1 {
  --tw-translate-y: -0.25rem;
}

.dq--translate-y-2 {
  --tw-translate-y: -0.5rem;
}

.dq--translate-y-3 {
  --tw-translate-y: -0.75rem;
}

.dq--translate-y-4 {
  --tw-translate-y: -1rem;
}

.dq--translate-y-5 {
  --tw-translate-y: -1.25rem;
}

.dq--translate-y-6 {
  --tw-translate-y: -1.5rem;
}

.dq--translate-y-7 {
  --tw-translate-y: -1.75rem;
}

.dq--translate-y-8 {
  --tw-translate-y: -2rem;
}

.dq--translate-y-9 {
  --tw-translate-y: -2.25rem;
}

.dq--translate-y-10 {
  --tw-translate-y: -2.5rem;
}

.dq--translate-y-11 {
  --tw-translate-y: -2.75rem;
}

.dq--translate-y-12 {
  --tw-translate-y: -3rem;
}

.dq--translate-y-14 {
  --tw-translate-y: -3.5rem;
}

.dq--translate-y-16 {
  --tw-translate-y: -4rem;
}

.dq--translate-y-20 {
  --tw-translate-y: -5rem;
}

.dq--translate-y-24 {
  --tw-translate-y: -6rem;
}

.dq--translate-y-28 {
  --tw-translate-y: -7rem;
}

.dq--translate-y-32 {
  --tw-translate-y: -8rem;
}

.dq--translate-y-36 {
  --tw-translate-y: -9rem;
}

.dq--translate-y-40 {
  --tw-translate-y: -10rem;
}

.dq--translate-y-44 {
  --tw-translate-y: -11rem;
}

.dq--translate-y-48 {
  --tw-translate-y: -12rem;
}

.dq--translate-y-52 {
  --tw-translate-y: -13rem;
}

.dq--translate-y-56 {
  --tw-translate-y: -14rem;
}

.dq--translate-y-60 {
  --tw-translate-y: -15rem;
}

.dq--translate-y-64 {
  --tw-translate-y: -16rem;
}

.dq--translate-y-72 {
  --tw-translate-y: -18rem;
}

.dq--translate-y-80 {
  --tw-translate-y: -20rem;
}

.dq--translate-y-96 {
  --tw-translate-y: -24rem;
}

.dq--translate-y-px {
  --tw-translate-y: -1px;
}

.dq--translate-y-0\.5 {
  --tw-translate-y: -0.125rem;
}

.dq--translate-y-1\.5 {
  --tw-translate-y: -0.375rem;
}

.dq--translate-y-2\.5 {
  --tw-translate-y: -0.625rem;
}

.dq--translate-y-3\.5 {
  --tw-translate-y: -0.875rem;
}

.dq-translate-y-1\/2 {
  --tw-translate-y: 50%;
}

.dq-translate-y-1\/3 {
  --tw-translate-y: 33.333333%;
}

.dq-translate-y-2\/3 {
  --tw-translate-y: 66.666667%;
}

.dq-translate-y-1\/4 {
  --tw-translate-y: 25%;
}

.dq-translate-y-2\/4 {
  --tw-translate-y: 50%;
}

.dq-translate-y-3\/4 {
  --tw-translate-y: 75%;
}

.dq-translate-y-full {
  --tw-translate-y: 100%;
}

.dq--translate-y-1\/2 {
  --tw-translate-y: -50%;
}

.dq--translate-y-1\/3 {
  --tw-translate-y: -33.333333%;
}

.dq--translate-y-2\/3 {
  --tw-translate-y: -66.666667%;
}

.dq--translate-y-1\/4 {
  --tw-translate-y: -25%;
}

.dq--translate-y-2\/4 {
  --tw-translate-y: -50%;
}

.dq--translate-y-3\/4 {
  --tw-translate-y: -75%;
}

.dq--translate-y-full {
  --tw-translate-y: -100%;
}

.hover\:dq-translate-x-0:hover {
  --tw-translate-x: 0px;
}

.hover\:dq-translate-x-1:hover {
  --tw-translate-x: 0.25rem;
}

.hover\:dq-translate-x-2:hover {
  --tw-translate-x: 0.5rem;
}

.hover\:dq-translate-x-3:hover {
  --tw-translate-x: 0.75rem;
}

.hover\:dq-translate-x-4:hover {
  --tw-translate-x: 1rem;
}

.hover\:dq-translate-x-5:hover {
  --tw-translate-x: 1.25rem;
}

.hover\:dq-translate-x-6:hover {
  --tw-translate-x: 1.5rem;
}

.hover\:dq-translate-x-7:hover {
  --tw-translate-x: 1.75rem;
}

.hover\:dq-translate-x-8:hover {
  --tw-translate-x: 2rem;
}

.hover\:dq-translate-x-9:hover {
  --tw-translate-x: 2.25rem;
}

.hover\:dq-translate-x-10:hover {
  --tw-translate-x: 2.5rem;
}

.hover\:dq-translate-x-11:hover {
  --tw-translate-x: 2.75rem;
}

.hover\:dq-translate-x-12:hover {
  --tw-translate-x: 3rem;
}

.hover\:dq-translate-x-14:hover {
  --tw-translate-x: 3.5rem;
}

.hover\:dq-translate-x-16:hover {
  --tw-translate-x: 4rem;
}

.hover\:dq-translate-x-20:hover {
  --tw-translate-x: 5rem;
}

.hover\:dq-translate-x-24:hover {
  --tw-translate-x: 6rem;
}

.hover\:dq-translate-x-28:hover {
  --tw-translate-x: 7rem;
}

.hover\:dq-translate-x-32:hover {
  --tw-translate-x: 8rem;
}

.hover\:dq-translate-x-36:hover {
  --tw-translate-x: 9rem;
}

.hover\:dq-translate-x-40:hover {
  --tw-translate-x: 10rem;
}

.hover\:dq-translate-x-44:hover {
  --tw-translate-x: 11rem;
}

.hover\:dq-translate-x-48:hover {
  --tw-translate-x: 12rem;
}

.hover\:dq-translate-x-52:hover {
  --tw-translate-x: 13rem;
}

.hover\:dq-translate-x-56:hover {
  --tw-translate-x: 14rem;
}

.hover\:dq-translate-x-60:hover {
  --tw-translate-x: 15rem;
}

.hover\:dq-translate-x-64:hover {
  --tw-translate-x: 16rem;
}

.hover\:dq-translate-x-72:hover {
  --tw-translate-x: 18rem;
}

.hover\:dq-translate-x-80:hover {
  --tw-translate-x: 20rem;
}

.hover\:dq-translate-x-96:hover {
  --tw-translate-x: 24rem;
}

.hover\:dq-translate-x-px:hover {
  --tw-translate-x: 1px;
}

.hover\:dq-translate-x-0\.5:hover {
  --tw-translate-x: 0.125rem;
}

.hover\:dq-translate-x-1\.5:hover {
  --tw-translate-x: 0.375rem;
}

.hover\:dq-translate-x-2\.5:hover {
  --tw-translate-x: 0.625rem;
}

.hover\:dq-translate-x-3\.5:hover {
  --tw-translate-x: 0.875rem;
}

.hover\:dq--translate-x-0:hover {
  --tw-translate-x: 0px;
}

.hover\:dq--translate-x-1:hover {
  --tw-translate-x: -0.25rem;
}

.hover\:dq--translate-x-2:hover {
  --tw-translate-x: -0.5rem;
}

.hover\:dq--translate-x-3:hover {
  --tw-translate-x: -0.75rem;
}

.hover\:dq--translate-x-4:hover {
  --tw-translate-x: -1rem;
}

.hover\:dq--translate-x-5:hover {
  --tw-translate-x: -1.25rem;
}

.hover\:dq--translate-x-6:hover {
  --tw-translate-x: -1.5rem;
}

.hover\:dq--translate-x-7:hover {
  --tw-translate-x: -1.75rem;
}

.hover\:dq--translate-x-8:hover {
  --tw-translate-x: -2rem;
}

.hover\:dq--translate-x-9:hover {
  --tw-translate-x: -2.25rem;
}

.hover\:dq--translate-x-10:hover {
  --tw-translate-x: -2.5rem;
}

.hover\:dq--translate-x-11:hover {
  --tw-translate-x: -2.75rem;
}

.hover\:dq--translate-x-12:hover {
  --tw-translate-x: -3rem;
}

.hover\:dq--translate-x-14:hover {
  --tw-translate-x: -3.5rem;
}

.hover\:dq--translate-x-16:hover {
  --tw-translate-x: -4rem;
}

.hover\:dq--translate-x-20:hover {
  --tw-translate-x: -5rem;
}

.hover\:dq--translate-x-24:hover {
  --tw-translate-x: -6rem;
}

.hover\:dq--translate-x-28:hover {
  --tw-translate-x: -7rem;
}

.hover\:dq--translate-x-32:hover {
  --tw-translate-x: -8rem;
}

.hover\:dq--translate-x-36:hover {
  --tw-translate-x: -9rem;
}

.hover\:dq--translate-x-40:hover {
  --tw-translate-x: -10rem;
}

.hover\:dq--translate-x-44:hover {
  --tw-translate-x: -11rem;
}

.hover\:dq--translate-x-48:hover {
  --tw-translate-x: -12rem;
}

.hover\:dq--translate-x-52:hover {
  --tw-translate-x: -13rem;
}

.hover\:dq--translate-x-56:hover {
  --tw-translate-x: -14rem;
}

.hover\:dq--translate-x-60:hover {
  --tw-translate-x: -15rem;
}

.hover\:dq--translate-x-64:hover {
  --tw-translate-x: -16rem;
}

.hover\:dq--translate-x-72:hover {
  --tw-translate-x: -18rem;
}

.hover\:dq--translate-x-80:hover {
  --tw-translate-x: -20rem;
}

.hover\:dq--translate-x-96:hover {
  --tw-translate-x: -24rem;
}

.hover\:dq--translate-x-px:hover {
  --tw-translate-x: -1px;
}

.hover\:dq--translate-x-0\.5:hover {
  --tw-translate-x: -0.125rem;
}

.hover\:dq--translate-x-1\.5:hover {
  --tw-translate-x: -0.375rem;
}

.hover\:dq--translate-x-2\.5:hover {
  --tw-translate-x: -0.625rem;
}

.hover\:dq--translate-x-3\.5:hover {
  --tw-translate-x: -0.875rem;
}

.hover\:dq-translate-x-1\/2:hover {
  --tw-translate-x: 50%;
}

.hover\:dq-translate-x-1\/3:hover {
  --tw-translate-x: 33.333333%;
}

.hover\:dq-translate-x-2\/3:hover {
  --tw-translate-x: 66.666667%;
}

.hover\:dq-translate-x-1\/4:hover {
  --tw-translate-x: 25%;
}

.hover\:dq-translate-x-2\/4:hover {
  --tw-translate-x: 50%;
}

.hover\:dq-translate-x-3\/4:hover {
  --tw-translate-x: 75%;
}

.hover\:dq-translate-x-full:hover {
  --tw-translate-x: 100%;
}

.hover\:dq--translate-x-1\/2:hover {
  --tw-translate-x: -50%;
}

.hover\:dq--translate-x-1\/3:hover {
  --tw-translate-x: -33.333333%;
}

.hover\:dq--translate-x-2\/3:hover {
  --tw-translate-x: -66.666667%;
}

.hover\:dq--translate-x-1\/4:hover {
  --tw-translate-x: -25%;
}

.hover\:dq--translate-x-2\/4:hover {
  --tw-translate-x: -50%;
}

.hover\:dq--translate-x-3\/4:hover {
  --tw-translate-x: -75%;
}

.hover\:dq--translate-x-full:hover {
  --tw-translate-x: -100%;
}

.hover\:dq-translate-y-0:hover {
  --tw-translate-y: 0px;
}

.hover\:dq-translate-y-1:hover {
  --tw-translate-y: 0.25rem;
}

.hover\:dq-translate-y-2:hover {
  --tw-translate-y: 0.5rem;
}

.hover\:dq-translate-y-3:hover {
  --tw-translate-y: 0.75rem;
}

.hover\:dq-translate-y-4:hover {
  --tw-translate-y: 1rem;
}

.hover\:dq-translate-y-5:hover {
  --tw-translate-y: 1.25rem;
}

.hover\:dq-translate-y-6:hover {
  --tw-translate-y: 1.5rem;
}

.hover\:dq-translate-y-7:hover {
  --tw-translate-y: 1.75rem;
}

.hover\:dq-translate-y-8:hover {
  --tw-translate-y: 2rem;
}

.hover\:dq-translate-y-9:hover {
  --tw-translate-y: 2.25rem;
}

.hover\:dq-translate-y-10:hover {
  --tw-translate-y: 2.5rem;
}

.hover\:dq-translate-y-11:hover {
  --tw-translate-y: 2.75rem;
}

.hover\:dq-translate-y-12:hover {
  --tw-translate-y: 3rem;
}

.hover\:dq-translate-y-14:hover {
  --tw-translate-y: 3.5rem;
}

.hover\:dq-translate-y-16:hover {
  --tw-translate-y: 4rem;
}

.hover\:dq-translate-y-20:hover {
  --tw-translate-y: 5rem;
}

.hover\:dq-translate-y-24:hover {
  --tw-translate-y: 6rem;
}

.hover\:dq-translate-y-28:hover {
  --tw-translate-y: 7rem;
}

.hover\:dq-translate-y-32:hover {
  --tw-translate-y: 8rem;
}

.hover\:dq-translate-y-36:hover {
  --tw-translate-y: 9rem;
}

.hover\:dq-translate-y-40:hover {
  --tw-translate-y: 10rem;
}

.hover\:dq-translate-y-44:hover {
  --tw-translate-y: 11rem;
}

.hover\:dq-translate-y-48:hover {
  --tw-translate-y: 12rem;
}

.hover\:dq-translate-y-52:hover {
  --tw-translate-y: 13rem;
}

.hover\:dq-translate-y-56:hover {
  --tw-translate-y: 14rem;
}

.hover\:dq-translate-y-60:hover {
  --tw-translate-y: 15rem;
}

.hover\:dq-translate-y-64:hover {
  --tw-translate-y: 16rem;
}

.hover\:dq-translate-y-72:hover {
  --tw-translate-y: 18rem;
}

.hover\:dq-translate-y-80:hover {
  --tw-translate-y: 20rem;
}

.hover\:dq-translate-y-96:hover {
  --tw-translate-y: 24rem;
}

.hover\:dq-translate-y-px:hover {
  --tw-translate-y: 1px;
}

.hover\:dq-translate-y-0\.5:hover {
  --tw-translate-y: 0.125rem;
}

.hover\:dq-translate-y-1\.5:hover {
  --tw-translate-y: 0.375rem;
}

.hover\:dq-translate-y-2\.5:hover {
  --tw-translate-y: 0.625rem;
}

.hover\:dq-translate-y-3\.5:hover {
  --tw-translate-y: 0.875rem;
}

.hover\:dq--translate-y-0:hover {
  --tw-translate-y: 0px;
}

.hover\:dq--translate-y-1:hover {
  --tw-translate-y: -0.25rem;
}

.hover\:dq--translate-y-2:hover {
  --tw-translate-y: -0.5rem;
}

.hover\:dq--translate-y-3:hover {
  --tw-translate-y: -0.75rem;
}

.hover\:dq--translate-y-4:hover {
  --tw-translate-y: -1rem;
}

.hover\:dq--translate-y-5:hover {
  --tw-translate-y: -1.25rem;
}

.hover\:dq--translate-y-6:hover {
  --tw-translate-y: -1.5rem;
}

.hover\:dq--translate-y-7:hover {
  --tw-translate-y: -1.75rem;
}

.hover\:dq--translate-y-8:hover {
  --tw-translate-y: -2rem;
}

.hover\:dq--translate-y-9:hover {
  --tw-translate-y: -2.25rem;
}

.hover\:dq--translate-y-10:hover {
  --tw-translate-y: -2.5rem;
}

.hover\:dq--translate-y-11:hover {
  --tw-translate-y: -2.75rem;
}

.hover\:dq--translate-y-12:hover {
  --tw-translate-y: -3rem;
}

.hover\:dq--translate-y-14:hover {
  --tw-translate-y: -3.5rem;
}

.hover\:dq--translate-y-16:hover {
  --tw-translate-y: -4rem;
}

.hover\:dq--translate-y-20:hover {
  --tw-translate-y: -5rem;
}

.hover\:dq--translate-y-24:hover {
  --tw-translate-y: -6rem;
}

.hover\:dq--translate-y-28:hover {
  --tw-translate-y: -7rem;
}

.hover\:dq--translate-y-32:hover {
  --tw-translate-y: -8rem;
}

.hover\:dq--translate-y-36:hover {
  --tw-translate-y: -9rem;
}

.hover\:dq--translate-y-40:hover {
  --tw-translate-y: -10rem;
}

.hover\:dq--translate-y-44:hover {
  --tw-translate-y: -11rem;
}

.hover\:dq--translate-y-48:hover {
  --tw-translate-y: -12rem;
}

.hover\:dq--translate-y-52:hover {
  --tw-translate-y: -13rem;
}

.hover\:dq--translate-y-56:hover {
  --tw-translate-y: -14rem;
}

.hover\:dq--translate-y-60:hover {
  --tw-translate-y: -15rem;
}

.hover\:dq--translate-y-64:hover {
  --tw-translate-y: -16rem;
}

.hover\:dq--translate-y-72:hover {
  --tw-translate-y: -18rem;
}

.hover\:dq--translate-y-80:hover {
  --tw-translate-y: -20rem;
}

.hover\:dq--translate-y-96:hover {
  --tw-translate-y: -24rem;
}

.hover\:dq--translate-y-px:hover {
  --tw-translate-y: -1px;
}

.hover\:dq--translate-y-0\.5:hover {
  --tw-translate-y: -0.125rem;
}

.hover\:dq--translate-y-1\.5:hover {
  --tw-translate-y: -0.375rem;
}

.hover\:dq--translate-y-2\.5:hover {
  --tw-translate-y: -0.625rem;
}

.hover\:dq--translate-y-3\.5:hover {
  --tw-translate-y: -0.875rem;
}

.hover\:dq-translate-y-1\/2:hover {
  --tw-translate-y: 50%;
}

.hover\:dq-translate-y-1\/3:hover {
  --tw-translate-y: 33.333333%;
}

.hover\:dq-translate-y-2\/3:hover {
  --tw-translate-y: 66.666667%;
}

.hover\:dq-translate-y-1\/4:hover {
  --tw-translate-y: 25%;
}

.hover\:dq-translate-y-2\/4:hover {
  --tw-translate-y: 50%;
}

.hover\:dq-translate-y-3\/4:hover {
  --tw-translate-y: 75%;
}

.hover\:dq-translate-y-full:hover {
  --tw-translate-y: 100%;
}

.hover\:dq--translate-y-1\/2:hover {
  --tw-translate-y: -50%;
}

.hover\:dq--translate-y-1\/3:hover {
  --tw-translate-y: -33.333333%;
}

.hover\:dq--translate-y-2\/3:hover {
  --tw-translate-y: -66.666667%;
}

.hover\:dq--translate-y-1\/4:hover {
  --tw-translate-y: -25%;
}

.hover\:dq--translate-y-2\/4:hover {
  --tw-translate-y: -50%;
}

.hover\:dq--translate-y-3\/4:hover {
  --tw-translate-y: -75%;
}

.hover\:dq--translate-y-full:hover {
  --tw-translate-y: -100%;
}

.focus\:dq-translate-x-0:focus {
  --tw-translate-x: 0px;
}

.focus\:dq-translate-x-1:focus {
  --tw-translate-x: 0.25rem;
}

.focus\:dq-translate-x-2:focus {
  --tw-translate-x: 0.5rem;
}

.focus\:dq-translate-x-3:focus {
  --tw-translate-x: 0.75rem;
}

.focus\:dq-translate-x-4:focus {
  --tw-translate-x: 1rem;
}

.focus\:dq-translate-x-5:focus {
  --tw-translate-x: 1.25rem;
}

.focus\:dq-translate-x-6:focus {
  --tw-translate-x: 1.5rem;
}

.focus\:dq-translate-x-7:focus {
  --tw-translate-x: 1.75rem;
}

.focus\:dq-translate-x-8:focus {
  --tw-translate-x: 2rem;
}

.focus\:dq-translate-x-9:focus {
  --tw-translate-x: 2.25rem;
}

.focus\:dq-translate-x-10:focus {
  --tw-translate-x: 2.5rem;
}

.focus\:dq-translate-x-11:focus {
  --tw-translate-x: 2.75rem;
}

.focus\:dq-translate-x-12:focus {
  --tw-translate-x: 3rem;
}

.focus\:dq-translate-x-14:focus {
  --tw-translate-x: 3.5rem;
}

.focus\:dq-translate-x-16:focus {
  --tw-translate-x: 4rem;
}

.focus\:dq-translate-x-20:focus {
  --tw-translate-x: 5rem;
}

.focus\:dq-translate-x-24:focus {
  --tw-translate-x: 6rem;
}

.focus\:dq-translate-x-28:focus {
  --tw-translate-x: 7rem;
}

.focus\:dq-translate-x-32:focus {
  --tw-translate-x: 8rem;
}

.focus\:dq-translate-x-36:focus {
  --tw-translate-x: 9rem;
}

.focus\:dq-translate-x-40:focus {
  --tw-translate-x: 10rem;
}

.focus\:dq-translate-x-44:focus {
  --tw-translate-x: 11rem;
}

.focus\:dq-translate-x-48:focus {
  --tw-translate-x: 12rem;
}

.focus\:dq-translate-x-52:focus {
  --tw-translate-x: 13rem;
}

.focus\:dq-translate-x-56:focus {
  --tw-translate-x: 14rem;
}

.focus\:dq-translate-x-60:focus {
  --tw-translate-x: 15rem;
}

.focus\:dq-translate-x-64:focus {
  --tw-translate-x: 16rem;
}

.focus\:dq-translate-x-72:focus {
  --tw-translate-x: 18rem;
}

.focus\:dq-translate-x-80:focus {
  --tw-translate-x: 20rem;
}

.focus\:dq-translate-x-96:focus {
  --tw-translate-x: 24rem;
}

.focus\:dq-translate-x-px:focus {
  --tw-translate-x: 1px;
}

.focus\:dq-translate-x-0\.5:focus {
  --tw-translate-x: 0.125rem;
}

.focus\:dq-translate-x-1\.5:focus {
  --tw-translate-x: 0.375rem;
}

.focus\:dq-translate-x-2\.5:focus {
  --tw-translate-x: 0.625rem;
}

.focus\:dq-translate-x-3\.5:focus {
  --tw-translate-x: 0.875rem;
}

.focus\:dq--translate-x-0:focus {
  --tw-translate-x: 0px;
}

.focus\:dq--translate-x-1:focus {
  --tw-translate-x: -0.25rem;
}

.focus\:dq--translate-x-2:focus {
  --tw-translate-x: -0.5rem;
}

.focus\:dq--translate-x-3:focus {
  --tw-translate-x: -0.75rem;
}

.focus\:dq--translate-x-4:focus {
  --tw-translate-x: -1rem;
}

.focus\:dq--translate-x-5:focus {
  --tw-translate-x: -1.25rem;
}

.focus\:dq--translate-x-6:focus {
  --tw-translate-x: -1.5rem;
}

.focus\:dq--translate-x-7:focus {
  --tw-translate-x: -1.75rem;
}

.focus\:dq--translate-x-8:focus {
  --tw-translate-x: -2rem;
}

.focus\:dq--translate-x-9:focus {
  --tw-translate-x: -2.25rem;
}

.focus\:dq--translate-x-10:focus {
  --tw-translate-x: -2.5rem;
}

.focus\:dq--translate-x-11:focus {
  --tw-translate-x: -2.75rem;
}

.focus\:dq--translate-x-12:focus {
  --tw-translate-x: -3rem;
}

.focus\:dq--translate-x-14:focus {
  --tw-translate-x: -3.5rem;
}

.focus\:dq--translate-x-16:focus {
  --tw-translate-x: -4rem;
}

.focus\:dq--translate-x-20:focus {
  --tw-translate-x: -5rem;
}

.focus\:dq--translate-x-24:focus {
  --tw-translate-x: -6rem;
}

.focus\:dq--translate-x-28:focus {
  --tw-translate-x: -7rem;
}

.focus\:dq--translate-x-32:focus {
  --tw-translate-x: -8rem;
}

.focus\:dq--translate-x-36:focus {
  --tw-translate-x: -9rem;
}

.focus\:dq--translate-x-40:focus {
  --tw-translate-x: -10rem;
}

.focus\:dq--translate-x-44:focus {
  --tw-translate-x: -11rem;
}

.focus\:dq--translate-x-48:focus {
  --tw-translate-x: -12rem;
}

.focus\:dq--translate-x-52:focus {
  --tw-translate-x: -13rem;
}

.focus\:dq--translate-x-56:focus {
  --tw-translate-x: -14rem;
}

.focus\:dq--translate-x-60:focus {
  --tw-translate-x: -15rem;
}

.focus\:dq--translate-x-64:focus {
  --tw-translate-x: -16rem;
}

.focus\:dq--translate-x-72:focus {
  --tw-translate-x: -18rem;
}

.focus\:dq--translate-x-80:focus {
  --tw-translate-x: -20rem;
}

.focus\:dq--translate-x-96:focus {
  --tw-translate-x: -24rem;
}

.focus\:dq--translate-x-px:focus {
  --tw-translate-x: -1px;
}

.focus\:dq--translate-x-0\.5:focus {
  --tw-translate-x: -0.125rem;
}

.focus\:dq--translate-x-1\.5:focus {
  --tw-translate-x: -0.375rem;
}

.focus\:dq--translate-x-2\.5:focus {
  --tw-translate-x: -0.625rem;
}

.focus\:dq--translate-x-3\.5:focus {
  --tw-translate-x: -0.875rem;
}

.focus\:dq-translate-x-1\/2:focus {
  --tw-translate-x: 50%;
}

.focus\:dq-translate-x-1\/3:focus {
  --tw-translate-x: 33.333333%;
}

.focus\:dq-translate-x-2\/3:focus {
  --tw-translate-x: 66.666667%;
}

.focus\:dq-translate-x-1\/4:focus {
  --tw-translate-x: 25%;
}

.focus\:dq-translate-x-2\/4:focus {
  --tw-translate-x: 50%;
}

.focus\:dq-translate-x-3\/4:focus {
  --tw-translate-x: 75%;
}

.focus\:dq-translate-x-full:focus {
  --tw-translate-x: 100%;
}

.focus\:dq--translate-x-1\/2:focus {
  --tw-translate-x: -50%;
}

.focus\:dq--translate-x-1\/3:focus {
  --tw-translate-x: -33.333333%;
}

.focus\:dq--translate-x-2\/3:focus {
  --tw-translate-x: -66.666667%;
}

.focus\:dq--translate-x-1\/4:focus {
  --tw-translate-x: -25%;
}

.focus\:dq--translate-x-2\/4:focus {
  --tw-translate-x: -50%;
}

.focus\:dq--translate-x-3\/4:focus {
  --tw-translate-x: -75%;
}

.focus\:dq--translate-x-full:focus {
  --tw-translate-x: -100%;
}

.focus\:dq-translate-y-0:focus {
  --tw-translate-y: 0px;
}

.focus\:dq-translate-y-1:focus {
  --tw-translate-y: 0.25rem;
}

.focus\:dq-translate-y-2:focus {
  --tw-translate-y: 0.5rem;
}

.focus\:dq-translate-y-3:focus {
  --tw-translate-y: 0.75rem;
}

.focus\:dq-translate-y-4:focus {
  --tw-translate-y: 1rem;
}

.focus\:dq-translate-y-5:focus {
  --tw-translate-y: 1.25rem;
}

.focus\:dq-translate-y-6:focus {
  --tw-translate-y: 1.5rem;
}

.focus\:dq-translate-y-7:focus {
  --tw-translate-y: 1.75rem;
}

.focus\:dq-translate-y-8:focus {
  --tw-translate-y: 2rem;
}

.focus\:dq-translate-y-9:focus {
  --tw-translate-y: 2.25rem;
}

.focus\:dq-translate-y-10:focus {
  --tw-translate-y: 2.5rem;
}

.focus\:dq-translate-y-11:focus {
  --tw-translate-y: 2.75rem;
}

.focus\:dq-translate-y-12:focus {
  --tw-translate-y: 3rem;
}

.focus\:dq-translate-y-14:focus {
  --tw-translate-y: 3.5rem;
}

.focus\:dq-translate-y-16:focus {
  --tw-translate-y: 4rem;
}

.focus\:dq-translate-y-20:focus {
  --tw-translate-y: 5rem;
}

.focus\:dq-translate-y-24:focus {
  --tw-translate-y: 6rem;
}

.focus\:dq-translate-y-28:focus {
  --tw-translate-y: 7rem;
}

.focus\:dq-translate-y-32:focus {
  --tw-translate-y: 8rem;
}

.focus\:dq-translate-y-36:focus {
  --tw-translate-y: 9rem;
}

.focus\:dq-translate-y-40:focus {
  --tw-translate-y: 10rem;
}

.focus\:dq-translate-y-44:focus {
  --tw-translate-y: 11rem;
}

.focus\:dq-translate-y-48:focus {
  --tw-translate-y: 12rem;
}

.focus\:dq-translate-y-52:focus {
  --tw-translate-y: 13rem;
}

.focus\:dq-translate-y-56:focus {
  --tw-translate-y: 14rem;
}

.focus\:dq-translate-y-60:focus {
  --tw-translate-y: 15rem;
}

.focus\:dq-translate-y-64:focus {
  --tw-translate-y: 16rem;
}

.focus\:dq-translate-y-72:focus {
  --tw-translate-y: 18rem;
}

.focus\:dq-translate-y-80:focus {
  --tw-translate-y: 20rem;
}

.focus\:dq-translate-y-96:focus {
  --tw-translate-y: 24rem;
}

.focus\:dq-translate-y-px:focus {
  --tw-translate-y: 1px;
}

.focus\:dq-translate-y-0\.5:focus {
  --tw-translate-y: 0.125rem;
}

.focus\:dq-translate-y-1\.5:focus {
  --tw-translate-y: 0.375rem;
}

.focus\:dq-translate-y-2\.5:focus {
  --tw-translate-y: 0.625rem;
}

.focus\:dq-translate-y-3\.5:focus {
  --tw-translate-y: 0.875rem;
}

.focus\:dq--translate-y-0:focus {
  --tw-translate-y: 0px;
}

.focus\:dq--translate-y-1:focus {
  --tw-translate-y: -0.25rem;
}

.focus\:dq--translate-y-2:focus {
  --tw-translate-y: -0.5rem;
}

.focus\:dq--translate-y-3:focus {
  --tw-translate-y: -0.75rem;
}

.focus\:dq--translate-y-4:focus {
  --tw-translate-y: -1rem;
}

.focus\:dq--translate-y-5:focus {
  --tw-translate-y: -1.25rem;
}

.focus\:dq--translate-y-6:focus {
  --tw-translate-y: -1.5rem;
}

.focus\:dq--translate-y-7:focus {
  --tw-translate-y: -1.75rem;
}

.focus\:dq--translate-y-8:focus {
  --tw-translate-y: -2rem;
}

.focus\:dq--translate-y-9:focus {
  --tw-translate-y: -2.25rem;
}

.focus\:dq--translate-y-10:focus {
  --tw-translate-y: -2.5rem;
}

.focus\:dq--translate-y-11:focus {
  --tw-translate-y: -2.75rem;
}

.focus\:dq--translate-y-12:focus {
  --tw-translate-y: -3rem;
}

.focus\:dq--translate-y-14:focus {
  --tw-translate-y: -3.5rem;
}

.focus\:dq--translate-y-16:focus {
  --tw-translate-y: -4rem;
}

.focus\:dq--translate-y-20:focus {
  --tw-translate-y: -5rem;
}

.focus\:dq--translate-y-24:focus {
  --tw-translate-y: -6rem;
}

.focus\:dq--translate-y-28:focus {
  --tw-translate-y: -7rem;
}

.focus\:dq--translate-y-32:focus {
  --tw-translate-y: -8rem;
}

.focus\:dq--translate-y-36:focus {
  --tw-translate-y: -9rem;
}

.focus\:dq--translate-y-40:focus {
  --tw-translate-y: -10rem;
}

.focus\:dq--translate-y-44:focus {
  --tw-translate-y: -11rem;
}

.focus\:dq--translate-y-48:focus {
  --tw-translate-y: -12rem;
}

.focus\:dq--translate-y-52:focus {
  --tw-translate-y: -13rem;
}

.focus\:dq--translate-y-56:focus {
  --tw-translate-y: -14rem;
}

.focus\:dq--translate-y-60:focus {
  --tw-translate-y: -15rem;
}

.focus\:dq--translate-y-64:focus {
  --tw-translate-y: -16rem;
}

.focus\:dq--translate-y-72:focus {
  --tw-translate-y: -18rem;
}

.focus\:dq--translate-y-80:focus {
  --tw-translate-y: -20rem;
}

.focus\:dq--translate-y-96:focus {
  --tw-translate-y: -24rem;
}

.focus\:dq--translate-y-px:focus {
  --tw-translate-y: -1px;
}

.focus\:dq--translate-y-0\.5:focus {
  --tw-translate-y: -0.125rem;
}

.focus\:dq--translate-y-1\.5:focus {
  --tw-translate-y: -0.375rem;
}

.focus\:dq--translate-y-2\.5:focus {
  --tw-translate-y: -0.625rem;
}

.focus\:dq--translate-y-3\.5:focus {
  --tw-translate-y: -0.875rem;
}

.focus\:dq-translate-y-1\/2:focus {
  --tw-translate-y: 50%;
}

.focus\:dq-translate-y-1\/3:focus {
  --tw-translate-y: 33.333333%;
}

.focus\:dq-translate-y-2\/3:focus {
  --tw-translate-y: 66.666667%;
}

.focus\:dq-translate-y-1\/4:focus {
  --tw-translate-y: 25%;
}

.focus\:dq-translate-y-2\/4:focus {
  --tw-translate-y: 50%;
}

.focus\:dq-translate-y-3\/4:focus {
  --tw-translate-y: 75%;
}

.focus\:dq-translate-y-full:focus {
  --tw-translate-y: 100%;
}

.focus\:dq--translate-y-1\/2:focus {
  --tw-translate-y: -50%;
}

.focus\:dq--translate-y-1\/3:focus {
  --tw-translate-y: -33.333333%;
}

.focus\:dq--translate-y-2\/3:focus {
  --tw-translate-y: -66.666667%;
}

.focus\:dq--translate-y-1\/4:focus {
  --tw-translate-y: -25%;
}

.focus\:dq--translate-y-2\/4:focus {
  --tw-translate-y: -50%;
}

.focus\:dq--translate-y-3\/4:focus {
  --tw-translate-y: -75%;
}

.focus\:dq--translate-y-full:focus {
  --tw-translate-y: -100%;
}

.dq-rotate-0 {
  --tw-rotate: 0deg;
}

.dq-rotate-1 {
  --tw-rotate: 1deg;
}

.dq-rotate-2 {
  --tw-rotate: 2deg;
}

.dq-rotate-3 {
  --tw-rotate: 3deg;
}

.dq-rotate-6 {
  --tw-rotate: 6deg;
}

.dq-rotate-12 {
  --tw-rotate: 12deg;
}

.dq-rotate-45 {
  --tw-rotate: 45deg;
}

.dq-rotate-90 {
  --tw-rotate: 90deg;
}

.dq-rotate-180 {
  --tw-rotate: 180deg;
}

.dq--rotate-180 {
  --tw-rotate: -180deg;
}

.dq--rotate-90 {
  --tw-rotate: -90deg;
}

.dq--rotate-45 {
  --tw-rotate: -45deg;
}

.dq--rotate-12 {
  --tw-rotate: -12deg;
}

.dq--rotate-6 {
  --tw-rotate: -6deg;
}

.dq--rotate-3 {
  --tw-rotate: -3deg;
}

.dq--rotate-2 {
  --tw-rotate: -2deg;
}

.dq--rotate-1 {
  --tw-rotate: -1deg;
}

.hover\:dq-rotate-0:hover {
  --tw-rotate: 0deg;
}

.hover\:dq-rotate-1:hover {
  --tw-rotate: 1deg;
}

.hover\:dq-rotate-2:hover {
  --tw-rotate: 2deg;
}

.hover\:dq-rotate-3:hover {
  --tw-rotate: 3deg;
}

.hover\:dq-rotate-6:hover {
  --tw-rotate: 6deg;
}

.hover\:dq-rotate-12:hover {
  --tw-rotate: 12deg;
}

.hover\:dq-rotate-45:hover {
  --tw-rotate: 45deg;
}

.hover\:dq-rotate-90:hover {
  --tw-rotate: 90deg;
}

.hover\:dq-rotate-180:hover {
  --tw-rotate: 180deg;
}

.hover\:dq--rotate-180:hover {
  --tw-rotate: -180deg;
}

.hover\:dq--rotate-90:hover {
  --tw-rotate: -90deg;
}

.hover\:dq--rotate-45:hover {
  --tw-rotate: -45deg;
}

.hover\:dq--rotate-12:hover {
  --tw-rotate: -12deg;
}

.hover\:dq--rotate-6:hover {
  --tw-rotate: -6deg;
}

.hover\:dq--rotate-3:hover {
  --tw-rotate: -3deg;
}

.hover\:dq--rotate-2:hover {
  --tw-rotate: -2deg;
}

.hover\:dq--rotate-1:hover {
  --tw-rotate: -1deg;
}

.focus\:dq-rotate-0:focus {
  --tw-rotate: 0deg;
}

.focus\:dq-rotate-1:focus {
  --tw-rotate: 1deg;
}

.focus\:dq-rotate-2:focus {
  --tw-rotate: 2deg;
}

.focus\:dq-rotate-3:focus {
  --tw-rotate: 3deg;
}

.focus\:dq-rotate-6:focus {
  --tw-rotate: 6deg;
}

.focus\:dq-rotate-12:focus {
  --tw-rotate: 12deg;
}

.focus\:dq-rotate-45:focus {
  --tw-rotate: 45deg;
}

.focus\:dq-rotate-90:focus {
  --tw-rotate: 90deg;
}

.focus\:dq-rotate-180:focus {
  --tw-rotate: 180deg;
}

.focus\:dq--rotate-180:focus {
  --tw-rotate: -180deg;
}

.focus\:dq--rotate-90:focus {
  --tw-rotate: -90deg;
}

.focus\:dq--rotate-45:focus {
  --tw-rotate: -45deg;
}

.focus\:dq--rotate-12:focus {
  --tw-rotate: -12deg;
}

.focus\:dq--rotate-6:focus {
  --tw-rotate: -6deg;
}

.focus\:dq--rotate-3:focus {
  --tw-rotate: -3deg;
}

.focus\:dq--rotate-2:focus {
  --tw-rotate: -2deg;
}

.focus\:dq--rotate-1:focus {
  --tw-rotate: -1deg;
}

.dq-skew-x-0 {
  --tw-skew-x: 0deg;
}

.dq-skew-x-1 {
  --tw-skew-x: 1deg;
}

.dq-skew-x-2 {
  --tw-skew-x: 2deg;
}

.dq-skew-x-3 {
  --tw-skew-x: 3deg;
}

.dq-skew-x-6 {
  --tw-skew-x: 6deg;
}

.dq-skew-x-12 {
  --tw-skew-x: 12deg;
}

.dq--skew-x-12 {
  --tw-skew-x: -12deg;
}

.dq--skew-x-6 {
  --tw-skew-x: -6deg;
}

.dq--skew-x-3 {
  --tw-skew-x: -3deg;
}

.dq--skew-x-2 {
  --tw-skew-x: -2deg;
}

.dq--skew-x-1 {
  --tw-skew-x: -1deg;
}

.dq-skew-y-0 {
  --tw-skew-y: 0deg;
}

.dq-skew-y-1 {
  --tw-skew-y: 1deg;
}

.dq-skew-y-2 {
  --tw-skew-y: 2deg;
}

.dq-skew-y-3 {
  --tw-skew-y: 3deg;
}

.dq-skew-y-6 {
  --tw-skew-y: 6deg;
}

.dq-skew-y-12 {
  --tw-skew-y: 12deg;
}

.dq--skew-y-12 {
  --tw-skew-y: -12deg;
}

.dq--skew-y-6 {
  --tw-skew-y: -6deg;
}

.dq--skew-y-3 {
  --tw-skew-y: -3deg;
}

.dq--skew-y-2 {
  --tw-skew-y: -2deg;
}

.dq--skew-y-1 {
  --tw-skew-y: -1deg;
}

.hover\:dq-skew-x-0:hover {
  --tw-skew-x: 0deg;
}

.hover\:dq-skew-x-1:hover {
  --tw-skew-x: 1deg;
}

.hover\:dq-skew-x-2:hover {
  --tw-skew-x: 2deg;
}

.hover\:dq-skew-x-3:hover {
  --tw-skew-x: 3deg;
}

.hover\:dq-skew-x-6:hover {
  --tw-skew-x: 6deg;
}

.hover\:dq-skew-x-12:hover {
  --tw-skew-x: 12deg;
}

.hover\:dq--skew-x-12:hover {
  --tw-skew-x: -12deg;
}

.hover\:dq--skew-x-6:hover {
  --tw-skew-x: -6deg;
}

.hover\:dq--skew-x-3:hover {
  --tw-skew-x: -3deg;
}

.hover\:dq--skew-x-2:hover {
  --tw-skew-x: -2deg;
}

.hover\:dq--skew-x-1:hover {
  --tw-skew-x: -1deg;
}

.hover\:dq-skew-y-0:hover {
  --tw-skew-y: 0deg;
}

.hover\:dq-skew-y-1:hover {
  --tw-skew-y: 1deg;
}

.hover\:dq-skew-y-2:hover {
  --tw-skew-y: 2deg;
}

.hover\:dq-skew-y-3:hover {
  --tw-skew-y: 3deg;
}

.hover\:dq-skew-y-6:hover {
  --tw-skew-y: 6deg;
}

.hover\:dq-skew-y-12:hover {
  --tw-skew-y: 12deg;
}

.hover\:dq--skew-y-12:hover {
  --tw-skew-y: -12deg;
}

.hover\:dq--skew-y-6:hover {
  --tw-skew-y: -6deg;
}

.hover\:dq--skew-y-3:hover {
  --tw-skew-y: -3deg;
}

.hover\:dq--skew-y-2:hover {
  --tw-skew-y: -2deg;
}

.hover\:dq--skew-y-1:hover {
  --tw-skew-y: -1deg;
}

.focus\:dq-skew-x-0:focus {
  --tw-skew-x: 0deg;
}

.focus\:dq-skew-x-1:focus {
  --tw-skew-x: 1deg;
}

.focus\:dq-skew-x-2:focus {
  --tw-skew-x: 2deg;
}

.focus\:dq-skew-x-3:focus {
  --tw-skew-x: 3deg;
}

.focus\:dq-skew-x-6:focus {
  --tw-skew-x: 6deg;
}

.focus\:dq-skew-x-12:focus {
  --tw-skew-x: 12deg;
}

.focus\:dq--skew-x-12:focus {
  --tw-skew-x: -12deg;
}

.focus\:dq--skew-x-6:focus {
  --tw-skew-x: -6deg;
}

.focus\:dq--skew-x-3:focus {
  --tw-skew-x: -3deg;
}

.focus\:dq--skew-x-2:focus {
  --tw-skew-x: -2deg;
}

.focus\:dq--skew-x-1:focus {
  --tw-skew-x: -1deg;
}

.focus\:dq-skew-y-0:focus {
  --tw-skew-y: 0deg;
}

.focus\:dq-skew-y-1:focus {
  --tw-skew-y: 1deg;
}

.focus\:dq-skew-y-2:focus {
  --tw-skew-y: 2deg;
}

.focus\:dq-skew-y-3:focus {
  --tw-skew-y: 3deg;
}

.focus\:dq-skew-y-6:focus {
  --tw-skew-y: 6deg;
}

.focus\:dq-skew-y-12:focus {
  --tw-skew-y: 12deg;
}

.focus\:dq--skew-y-12:focus {
  --tw-skew-y: -12deg;
}

.focus\:dq--skew-y-6:focus {
  --tw-skew-y: -6deg;
}

.focus\:dq--skew-y-3:focus {
  --tw-skew-y: -3deg;
}

.focus\:dq--skew-y-2:focus {
  --tw-skew-y: -2deg;
}

.focus\:dq--skew-y-1:focus {
  --tw-skew-y: -1deg;
}

.dq-scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.dq-scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.dq-scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.dq-scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.dq-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.dq-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.dq-scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.dq-scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.dq-scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.dq-scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.hover\:dq-scale-0:hover {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.hover\:dq-scale-50:hover {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.hover\:dq-scale-75:hover {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.hover\:dq-scale-90:hover {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.hover\:dq-scale-95:hover {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.hover\:dq-scale-100:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.hover\:dq-scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.hover\:dq-scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.hover\:dq-scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.hover\:dq-scale-150:hover {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.focus\:dq-scale-0:focus {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.focus\:dq-scale-50:focus {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.focus\:dq-scale-75:focus {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.focus\:dq-scale-90:focus {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.focus\:dq-scale-95:focus {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.focus\:dq-scale-100:focus {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.focus\:dq-scale-105:focus {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.focus\:dq-scale-110:focus {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.focus\:dq-scale-125:focus {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.focus\:dq-scale-150:focus {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.dq-scale-x-0 {
  --tw-scale-x: 0;
}

.dq-scale-x-50 {
  --tw-scale-x: .5;
}

.dq-scale-x-75 {
  --tw-scale-x: .75;
}

.dq-scale-x-90 {
  --tw-scale-x: .9;
}

.dq-scale-x-95 {
  --tw-scale-x: .95;
}

.dq-scale-x-100 {
  --tw-scale-x: 1;
}

.dq-scale-x-105 {
  --tw-scale-x: 1.05;
}

.dq-scale-x-110 {
  --tw-scale-x: 1.1;
}

.dq-scale-x-125 {
  --tw-scale-x: 1.25;
}

.dq-scale-x-150 {
  --tw-scale-x: 1.5;
}

.dq-scale-y-0 {
  --tw-scale-y: 0;
}

.dq-scale-y-50 {
  --tw-scale-y: .5;
}

.dq-scale-y-75 {
  --tw-scale-y: .75;
}

.dq-scale-y-90 {
  --tw-scale-y: .9;
}

.dq-scale-y-95 {
  --tw-scale-y: .95;
}

.dq-scale-y-100 {
  --tw-scale-y: 1;
}

.dq-scale-y-105 {
  --tw-scale-y: 1.05;
}

.dq-scale-y-110 {
  --tw-scale-y: 1.1;
}

.dq-scale-y-125 {
  --tw-scale-y: 1.25;
}

.dq-scale-y-150 {
  --tw-scale-y: 1.5;
}

.hover\:dq-scale-x-0:hover {
  --tw-scale-x: 0;
}

.hover\:dq-scale-x-50:hover {
  --tw-scale-x: .5;
}

.hover\:dq-scale-x-75:hover {
  --tw-scale-x: .75;
}

.hover\:dq-scale-x-90:hover {
  --tw-scale-x: .9;
}

.hover\:dq-scale-x-95:hover {
  --tw-scale-x: .95;
}

.hover\:dq-scale-x-100:hover {
  --tw-scale-x: 1;
}

.hover\:dq-scale-x-105:hover {
  --tw-scale-x: 1.05;
}

.hover\:dq-scale-x-110:hover {
  --tw-scale-x: 1.1;
}

.hover\:dq-scale-x-125:hover {
  --tw-scale-x: 1.25;
}

.hover\:dq-scale-x-150:hover {
  --tw-scale-x: 1.5;
}

.hover\:dq-scale-y-0:hover {
  --tw-scale-y: 0;
}

.hover\:dq-scale-y-50:hover {
  --tw-scale-y: .5;
}

.hover\:dq-scale-y-75:hover {
  --tw-scale-y: .75;
}

.hover\:dq-scale-y-90:hover {
  --tw-scale-y: .9;
}

.hover\:dq-scale-y-95:hover {
  --tw-scale-y: .95;
}

.hover\:dq-scale-y-100:hover {
  --tw-scale-y: 1;
}

.hover\:dq-scale-y-105:hover {
  --tw-scale-y: 1.05;
}

.hover\:dq-scale-y-110:hover {
  --tw-scale-y: 1.1;
}

.hover\:dq-scale-y-125:hover {
  --tw-scale-y: 1.25;
}

.hover\:dq-scale-y-150:hover {
  --tw-scale-y: 1.5;
}

.focus\:dq-scale-x-0:focus {
  --tw-scale-x: 0;
}

.focus\:dq-scale-x-50:focus {
  --tw-scale-x: .5;
}

.focus\:dq-scale-x-75:focus {
  --tw-scale-x: .75;
}

.focus\:dq-scale-x-90:focus {
  --tw-scale-x: .9;
}

.focus\:dq-scale-x-95:focus {
  --tw-scale-x: .95;
}

.focus\:dq-scale-x-100:focus {
  --tw-scale-x: 1;
}

.focus\:dq-scale-x-105:focus {
  --tw-scale-x: 1.05;
}

.focus\:dq-scale-x-110:focus {
  --tw-scale-x: 1.1;
}

.focus\:dq-scale-x-125:focus {
  --tw-scale-x: 1.25;
}

.focus\:dq-scale-x-150:focus {
  --tw-scale-x: 1.5;
}

.focus\:dq-scale-y-0:focus {
  --tw-scale-y: 0;
}

.focus\:dq-scale-y-50:focus {
  --tw-scale-y: .5;
}

.focus\:dq-scale-y-75:focus {
  --tw-scale-y: .75;
}

.focus\:dq-scale-y-90:focus {
  --tw-scale-y: .9;
}

.focus\:dq-scale-y-95:focus {
  --tw-scale-y: .95;
}

.focus\:dq-scale-y-100:focus {
  --tw-scale-y: 1;
}

.focus\:dq-scale-y-105:focus {
  --tw-scale-y: 1.05;
}

.focus\:dq-scale-y-110:focus {
  --tw-scale-y: 1.1;
}

.focus\:dq-scale-y-125:focus {
  --tw-scale-y: 1.25;
}

.focus\:dq-scale-y-150:focus {
  --tw-scale-y: 1.5;
}

@keyframes dq-spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes dq-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes dq-pulse {
  50% {
    opacity: .5;
  }
}

@keyframes dq-bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.dq-animate-none {
  animation: none;
}

.dq-animate-spin {
  animation: dq-spin 1s linear infinite;
}

.dq-animate-ping {
  animation: dq-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.dq-animate-pulse {
  animation: dq-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.dq-animate-bounce {
  animation: dq-bounce 1s infinite;
}

.dq-cursor-auto {
  cursor: auto;
}

.dq-cursor-default {
  cursor: default;
}

.dq-cursor-pointer {
  cursor: pointer;
}

.dq-cursor-wait {
  cursor: wait;
}

.dq-cursor-text {
  cursor: text;
}

.dq-cursor-move {
  cursor: move;
}

.dq-cursor-help {
  cursor: help;
}

.dq-cursor-not-allowed {
  cursor: not-allowed;
}

.dq-select-none {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.dq-select-text {
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
}

.dq-select-all {
  -webkit-user-select: all;
      -ms-user-select: all;
          user-select: all;
}

.dq-select-auto {
  -webkit-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}

.dq-resize-none {
  resize: none;
}

.dq-resize-y {
  resize: vertical;
}

.dq-resize-x {
  resize: horizontal;
}

.dq-resize {
  resize: both;
}

.dq-list-inside {
  list-style-position: inside;
}

.dq-list-outside {
  list-style-position: outside;
}

.dq-list-none {
  list-style-type: none;
}

.dq-list-disc {
  list-style-type: disc;
}

.dq-list-decimal {
  list-style-type: decimal;
}

.dq-appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.dq-auto-cols-auto {
  grid-auto-columns: auto;
}

.dq-auto-cols-min {
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content;
}

.dq-auto-cols-max {
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
}

.dq-auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.dq-grid-flow-row {
  grid-auto-flow: row;
}

.dq-grid-flow-col {
  grid-auto-flow: column;
}

.dq-grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.dq-grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.dq-auto-rows-auto {
  grid-auto-rows: auto;
}

.dq-auto-rows-min {
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}

.dq-auto-rows-max {
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
}

.dq-auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.dq-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.dq-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.dq-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.dq-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.dq-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.dq-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.dq-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.dq-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.dq-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.dq-grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.dq-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.dq-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.dq-grid-cols-none {
  grid-template-columns: none;
}

.dq-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.dq-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.dq-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.dq-grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.dq-grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.dq-grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.dq-grid-rows-none {
  grid-template-rows: none;
}

.dq-flex-row {
  flex-direction: row;
}

.dq-flex-row-reverse {
  flex-direction: row-reverse;
}

.dq-flex-col {
  flex-direction: column;
}

.dq-flex-col-reverse {
  flex-direction: column-reverse;
}

.dq-flex-wrap {
  flex-wrap: wrap;
}

.dq-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.dq-flex-nowrap {
  flex-wrap: nowrap;
}

.dq-place-content-center {
  place-content: center;
}

.dq-place-content-start {
  place-content: start;
}

.dq-place-content-end {
  place-content: end;
}

.dq-place-content-between {
  place-content: space-between;
}

.dq-place-content-around {
  place-content: space-around;
}

.dq-place-content-evenly {
  place-content: space-evenly;
}

.dq-place-content-stretch {
  place-content: stretch;
}

.dq-place-items-start {
  place-items: start;
}

.dq-place-items-end {
  place-items: end;
}

.dq-place-items-center {
  place-items: center;
}

.dq-place-items-stretch {
  place-items: stretch;
}

.dq-content-center {
  align-content: center;
}

.dq-content-start {
  align-content: flex-start;
}

.dq-content-end {
  align-content: flex-end;
}

.dq-content-between {
  align-content: space-between;
}

.dq-content-around {
  align-content: space-around;
}

.dq-content-evenly {
  align-content: space-evenly;
}

.dq-items-start {
  align-items: flex-start;
}

.dq-items-end {
  align-items: flex-end;
}

.dq-items-center {
  align-items: center;
}

.dq-items-baseline {
  align-items: baseline;
}

.dq-items-stretch {
  align-items: stretch;
}

.dq-justify-start {
  justify-content: flex-start;
}

.dq-justify-end {
  justify-content: flex-end;
}

.dq-justify-center {
  justify-content: center;
}

.dq-justify-between {
  justify-content: space-between;
}

.dq-justify-around {
  justify-content: space-around;
}

.dq-justify-evenly {
  justify-content: space-evenly;
}

.dq-justify-items-start {
  justify-items: start;
}

.dq-justify-items-end {
  justify-items: end;
}

.dq-justify-items-center {
  justify-items: center;
}

.dq-justify-items-stretch {
  justify-items: stretch;
}

.dq-gap-0 {
  gap: 0px;
}

.dq-gap-1 {
  gap: 0.25rem;
}

.dq-gap-2 {
  gap: 0.5rem;
}

.dq-gap-3 {
  gap: 0.75rem;
}

.dq-gap-4 {
  gap: 1rem;
}

.dq-gap-5 {
  gap: 1.25rem;
}

.dq-gap-6 {
  gap: 1.5rem;
}

.dq-gap-7 {
  gap: 1.75rem;
}

.dq-gap-8 {
  gap: 2rem;
}

.dq-gap-9 {
  gap: 2.25rem;
}

.dq-gap-10 {
  gap: 2.5rem;
}

.dq-gap-11 {
  gap: 2.75rem;
}

.dq-gap-12 {
  gap: 3rem;
}

.dq-gap-14 {
  gap: 3.5rem;
}

.dq-gap-16 {
  gap: 4rem;
}

.dq-gap-20 {
  gap: 5rem;
}

.dq-gap-24 {
  gap: 6rem;
}

.dq-gap-28 {
  gap: 7rem;
}

.dq-gap-32 {
  gap: 8rem;
}

.dq-gap-36 {
  gap: 9rem;
}

.dq-gap-40 {
  gap: 10rem;
}

.dq-gap-44 {
  gap: 11rem;
}

.dq-gap-48 {
  gap: 12rem;
}

.dq-gap-52 {
  gap: 13rem;
}

.dq-gap-56 {
  gap: 14rem;
}

.dq-gap-60 {
  gap: 15rem;
}

.dq-gap-64 {
  gap: 16rem;
}

.dq-gap-72 {
  gap: 18rem;
}

.dq-gap-80 {
  gap: 20rem;
}

.dq-gap-96 {
  gap: 24rem;
}

.dq-gap-px {
  gap: 1px;
}

.dq-gap-0\.5 {
  gap: 0.125rem;
}

.dq-gap-1\.5 {
  gap: 0.375rem;
}

.dq-gap-2\.5 {
  gap: 0.625rem;
}

.dq-gap-3\.5 {
  gap: 0.875rem;
}

.dq-gap-x-0 {
  -webkit-column-gap: 0px;
          column-gap: 0px;
}

.dq-gap-x-1 {
  -webkit-column-gap: 0.25rem;
          column-gap: 0.25rem;
}

.dq-gap-x-2 {
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
}

.dq-gap-x-3 {
  -webkit-column-gap: 0.75rem;
          column-gap: 0.75rem;
}

.dq-gap-x-4 {
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

.dq-gap-x-5 {
  -webkit-column-gap: 1.25rem;
          column-gap: 1.25rem;
}

.dq-gap-x-6 {
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
}

.dq-gap-x-7 {
  -webkit-column-gap: 1.75rem;
          column-gap: 1.75rem;
}

.dq-gap-x-8 {
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}

.dq-gap-x-9 {
  -webkit-column-gap: 2.25rem;
          column-gap: 2.25rem;
}

.dq-gap-x-10 {
  -webkit-column-gap: 2.5rem;
          column-gap: 2.5rem;
}

.dq-gap-x-11 {
  -webkit-column-gap: 2.75rem;
          column-gap: 2.75rem;
}

.dq-gap-x-12 {
  -webkit-column-gap: 3rem;
          column-gap: 3rem;
}

.dq-gap-x-14 {
  -webkit-column-gap: 3.5rem;
          column-gap: 3.5rem;
}

.dq-gap-x-16 {
  -webkit-column-gap: 4rem;
          column-gap: 4rem;
}

.dq-gap-x-20 {
  -webkit-column-gap: 5rem;
          column-gap: 5rem;
}

.dq-gap-x-24 {
  -webkit-column-gap: 6rem;
          column-gap: 6rem;
}

.dq-gap-x-28 {
  -webkit-column-gap: 7rem;
          column-gap: 7rem;
}

.dq-gap-x-32 {
  -webkit-column-gap: 8rem;
          column-gap: 8rem;
}

.dq-gap-x-36 {
  -webkit-column-gap: 9rem;
          column-gap: 9rem;
}

.dq-gap-x-40 {
  -webkit-column-gap: 10rem;
          column-gap: 10rem;
}

.dq-gap-x-44 {
  -webkit-column-gap: 11rem;
          column-gap: 11rem;
}

.dq-gap-x-48 {
  -webkit-column-gap: 12rem;
          column-gap: 12rem;
}

.dq-gap-x-52 {
  -webkit-column-gap: 13rem;
          column-gap: 13rem;
}

.dq-gap-x-56 {
  -webkit-column-gap: 14rem;
          column-gap: 14rem;
}

.dq-gap-x-60 {
  -webkit-column-gap: 15rem;
          column-gap: 15rem;
}

.dq-gap-x-64 {
  -webkit-column-gap: 16rem;
          column-gap: 16rem;
}

.dq-gap-x-72 {
  -webkit-column-gap: 18rem;
          column-gap: 18rem;
}

.dq-gap-x-80 {
  -webkit-column-gap: 20rem;
          column-gap: 20rem;
}

.dq-gap-x-96 {
  -webkit-column-gap: 24rem;
          column-gap: 24rem;
}

.dq-gap-x-px {
  -webkit-column-gap: 1px;
          column-gap: 1px;
}

.dq-gap-x-0\.5 {
  -webkit-column-gap: 0.125rem;
          column-gap: 0.125rem;
}

.dq-gap-x-1\.5 {
  -webkit-column-gap: 0.375rem;
          column-gap: 0.375rem;
}

.dq-gap-x-2\.5 {
  -webkit-column-gap: 0.625rem;
          column-gap: 0.625rem;
}

.dq-gap-x-3\.5 {
  -webkit-column-gap: 0.875rem;
          column-gap: 0.875rem;
}

.dq-gap-y-0 {
  row-gap: 0px;
}

.dq-gap-y-1 {
  row-gap: 0.25rem;
}

.dq-gap-y-2 {
  row-gap: 0.5rem;
}

.dq-gap-y-3 {
  row-gap: 0.75rem;
}

.dq-gap-y-4 {
  row-gap: 1rem;
}

.dq-gap-y-5 {
  row-gap: 1.25rem;
}

.dq-gap-y-6 {
  row-gap: 1.5rem;
}

.dq-gap-y-7 {
  row-gap: 1.75rem;
}

.dq-gap-y-8 {
  row-gap: 2rem;
}

.dq-gap-y-9 {
  row-gap: 2.25rem;
}

.dq-gap-y-10 {
  row-gap: 2.5rem;
}

.dq-gap-y-11 {
  row-gap: 2.75rem;
}

.dq-gap-y-12 {
  row-gap: 3rem;
}

.dq-gap-y-14 {
  row-gap: 3.5rem;
}

.dq-gap-y-16 {
  row-gap: 4rem;
}

.dq-gap-y-20 {
  row-gap: 5rem;
}

.dq-gap-y-24 {
  row-gap: 6rem;
}

.dq-gap-y-28 {
  row-gap: 7rem;
}

.dq-gap-y-32 {
  row-gap: 8rem;
}

.dq-gap-y-36 {
  row-gap: 9rem;
}

.dq-gap-y-40 {
  row-gap: 10rem;
}

.dq-gap-y-44 {
  row-gap: 11rem;
}

.dq-gap-y-48 {
  row-gap: 12rem;
}

.dq-gap-y-52 {
  row-gap: 13rem;
}

.dq-gap-y-56 {
  row-gap: 14rem;
}

.dq-gap-y-60 {
  row-gap: 15rem;
}

.dq-gap-y-64 {
  row-gap: 16rem;
}

.dq-gap-y-72 {
  row-gap: 18rem;
}

.dq-gap-y-80 {
  row-gap: 20rem;
}

.dq-gap-y-96 {
  row-gap: 24rem;
}

.dq-gap-y-px {
  row-gap: 1px;
}

.dq-gap-y-0\.5 {
  row-gap: 0.125rem;
}

.dq-gap-y-1\.5 {
  row-gap: 0.375rem;
}

.dq-gap-y-2\.5 {
  row-gap: 0.625rem;
}

.dq-gap-y-3\.5 {
  row-gap: 0.875rem;
}

.dq-space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5rem * var(--tw-space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6rem * var(--tw-space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(7rem * var(--tw-space-x-reverse));
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(9rem * var(--tw-space-x-reverse));
  margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10rem * var(--tw-space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(11rem * var(--tw-space-x-reverse));
  margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12rem * var(--tw-space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(13rem * var(--tw-space-x-reverse));
  margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(14rem * var(--tw-space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(15rem * var(--tw-space-x-reverse));
  margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(16rem * var(--tw-space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(18rem * var(--tw-space-x-reverse));
  margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20rem * var(--tw-space-x-reverse));
  margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(24rem * var(--tw-space-x-reverse));
  margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.875rem * var(--tw-space-x-reverse));
  margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2rem * var(--tw-space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3rem * var(--tw-space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-5rem * var(--tw-space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-7rem * var(--tw-space-x-reverse));
  margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-9rem * var(--tw-space-x-reverse));
  margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-10rem * var(--tw-space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-11rem * var(--tw-space-x-reverse));
  margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-12rem * var(--tw-space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-13rem * var(--tw-space-x-reverse));
  margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-14rem * var(--tw-space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-15rem * var(--tw-space-x-reverse));
  margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-16rem * var(--tw-space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-18rem * var(--tw-space-x-reverse));
  margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-20rem * var(--tw-space-x-reverse));
  margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-24rem * var(--tw-space-x-reverse));
  margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq--space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
}

.dq-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.dq-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.dq-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.dq-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.dq-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.dq-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.dq-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.dq-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.dq-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.dq-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
}

.dq-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.dq-space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
}

.dq-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.dq-space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}

.dq-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.dq-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}

.dq-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}

.dq-space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(7rem * var(--tw-space-y-reverse));
}

.dq-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse));
}

.dq-space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(9rem * var(--tw-space-y-reverse));
}

.dq-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10rem * var(--tw-space-y-reverse));
}

.dq-space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(11rem * var(--tw-space-y-reverse));
}

.dq-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12rem * var(--tw-space-y-reverse));
}

.dq-space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13rem * var(--tw-space-y-reverse));
}

.dq-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(14rem * var(--tw-space-y-reverse));
}

.dq-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15rem * var(--tw-space-y-reverse));
}

.dq-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}

.dq-space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(18rem * var(--tw-space-y-reverse));
}

.dq-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20rem * var(--tw-space-y-reverse));
}

.dq-space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24rem * var(--tw-space-y-reverse));
}

.dq-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse));
}

.dq-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
}

.dq-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}

.dq-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
}

.dq-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
}

.dq--space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.dq--space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
}

.dq--space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
}

.dq--space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
}

.dq--space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}

.dq--space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
}

.dq--space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
}

.dq--space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
}

.dq--space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
}

.dq--space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
}

.dq--space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
}

.dq--space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
}

.dq--space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
}

.dq--space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
}

.dq--space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
}

.dq--space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
}

.dq--space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
}

.dq--space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
}

.dq--space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
}

.dq--space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
}

.dq--space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
}

.dq--space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
}

.dq--space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
}

.dq--space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
}

.dq--space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
}

.dq--space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
}

.dq--space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
}

.dq--space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
}

.dq--space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
}

.dq--space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
}

.dq--space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}

.dq--space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
}

.dq--space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
}

.dq--space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
}

.dq--space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
}

.dq-space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}

.dq-space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.dq-divide-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.dq-divide-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
}

.dq-divide-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(4px * var(--tw-divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
}

.dq-divide-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(8px * var(--tw-divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
}

.dq-divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.dq-divide-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.dq-divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.dq-divide-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
}

.dq-divide-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
}

.dq-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.dq-divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1;
}

.dq-divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1;
}

.dq-divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.dq-divide-dashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed;
}

.dq-divide-dotted > :not([hidden]) ~ :not([hidden]) {
  border-style: dotted;
}

.dq-divide-double > :not([hidden]) ~ :not([hidden]) {
  border-style: double;
}

.dq-divide-none > :not([hidden]) ~ :not([hidden]) {
  border-style: none;
}

.dq-divide-transparent > :not([hidden]) ~ :not([hidden]) {
  border-color: transparent;
}

.dq-divide-current > :not([hidden]) ~ :not([hidden]) {
  border-color: currentColor;
}

.dq-divide-black > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
}

.dq-divide-white > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
}

.dq-divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
}

.dq-divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
}

.dq-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}

.dq-divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
}

.dq-divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
}

.dq-divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
}

.dq-divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
}

.dq-divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}

.dq-divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
}

.dq-divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
}

.dq-divide-red-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
}

.dq-divide-red-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
}

.dq-divide-red-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
}

.dq-divide-red-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
}

.dq-divide-red-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
}

.dq-divide-red-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
}

.dq-divide-red-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
}

.dq-divide-red-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
}

.dq-divide-red-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
}

.dq-divide-red-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
}

.dq-divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
}

.dq-divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
}

.dq-divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
}

.dq-divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
}

.dq-divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
}

.dq-divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
}

.dq-divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
}

.dq-divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
}

.dq-divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
}

.dq-divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
}

.dq-divide-green-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
}

.dq-divide-green-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
}

.dq-divide-green-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
}

.dq-divide-green-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
}

.dq-divide-green-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
}

.dq-divide-green-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
}

.dq-divide-green-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
}

.dq-divide-green-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
}

.dq-divide-green-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
}

.dq-divide-green-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
}

.dq-divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
}

.dq-divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
}

.dq-divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
}

.dq-divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
}

.dq-divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
}

.dq-divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
}

.dq-divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
}

.dq-divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
}

.dq-divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
}

.dq-divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
}

.dq-divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
}

.dq-divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
}

.dq-divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
}

.dq-divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
}

.dq-divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
}

.dq-divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
}

.dq-divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
}

.dq-divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
}

.dq-divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
}

.dq-divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
}

.dq-divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
}

.dq-divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
}

.dq-divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
}

.dq-divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
}

.dq-divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
}

.dq-divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
}

.dq-divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
}

.dq-divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
}

.dq-divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
}

.dq-divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
}

.dq-divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
}

.dq-divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
}

.dq-divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
}

.dq-divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
}

.dq-divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
}

.dq-divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
}

.dq-divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
}

.dq-divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
}

.dq-divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
}

.dq-divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
}

.dq-divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0;
}

.dq-divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.05;
}

.dq-divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.1;
}

.dq-divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.2;
}

.dq-divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.25;
}

.dq-divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.3;
}

.dq-divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.4;
}

.dq-divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.5;
}

.dq-divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.6;
}

.dq-divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.7;
}

.dq-divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.75;
}

.dq-divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.8;
}

.dq-divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.9;
}

.dq-divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.95;
}

.dq-divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
}

.dq-place-self-auto {
  place-self: auto;
}

.dq-place-self-start {
  place-self: start;
}

.dq-place-self-end {
  place-self: end;
}

.dq-place-self-center {
  place-self: center;
}

.dq-place-self-stretch {
  place-self: stretch;
}

.dq-self-auto {
  align-self: auto;
}

.dq-self-start {
  align-self: flex-start;
}

.dq-self-end {
  align-self: flex-end;
}

.dq-self-center {
  align-self: center;
}

.dq-self-stretch {
  align-self: stretch;
}

.dq-self-baseline {
  align-self: baseline;
}

.dq-justify-self-auto {
  justify-self: auto;
}

.dq-justify-self-start {
  justify-self: start;
}

.dq-justify-self-end {
  justify-self: end;
}

.dq-justify-self-center {
  justify-self: center;
}

.dq-justify-self-stretch {
  justify-self: stretch;
}

.dq-overflow-auto {
  overflow: auto;
}

.dq-overflow-hidden {
  overflow: hidden;
}

.dq-overflow-visible {
  overflow: visible;
}

.dq-overflow-scroll {
  overflow: scroll;
}

.dq-overflow-x-auto {
  overflow-x: auto;
}

.dq-overflow-y-auto {
  overflow-y: auto;
}

.dq-overflow-x-hidden {
  overflow-x: hidden;
}

.dq-overflow-y-hidden {
  overflow-y: hidden;
}

.dq-overflow-x-visible {
  overflow-x: visible;
}

.dq-overflow-y-visible {
  overflow-y: visible;
}

.dq-overflow-x-scroll {
  overflow-x: scroll;
}

.dq-overflow-y-scroll {
  overflow-y: scroll;
}

.dq-overscroll-auto {
  -ms-scroll-chaining: chained;
      overscroll-behavior: auto;
}

.dq-overscroll-contain {
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
}

.dq-overscroll-none {
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}

.dq-overscroll-y-auto {
  overscroll-behavior-y: auto;
}

.dq-overscroll-y-contain {
  overscroll-behavior-y: contain;
}

.dq-overscroll-y-none {
  overscroll-behavior-y: none;
}

.dq-overscroll-x-auto {
  overscroll-behavior-x: auto;
}

.dq-overscroll-x-contain {
  overscroll-behavior-x: contain;
}

.dq-overscroll-x-none {
  overscroll-behavior-x: none;
}

.dq-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dq-overflow-ellipsis {
  text-overflow: ellipsis;
}

.dq-overflow-clip {
  text-overflow: clip;
}

.dq-whitespace-normal {
  white-space: normal;
}

.dq-whitespace-nowrap {
  white-space: nowrap;
}

.dq-whitespace-pre {
  white-space: pre;
}

.dq-whitespace-pre-line {
  white-space: pre-line;
}

.dq-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.dq-break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.dq-break-words {
  overflow-wrap: break-word;
}

.dq-break-all {
  word-break: break-all;
}

.dq-rounded-none {
  border-radius: 0px;
}

.dq-rounded-sm {
  border-radius: 0.125rem;
}

.dq-rounded {
  border-radius: 0.25rem;
}

.dq-rounded-md {
  border-radius: 0.375rem;
}

.dq-rounded-lg {
  border-radius: 0.5rem;
}

.dq-rounded-xl {
  border-radius: 0.75rem;
}

.dq-rounded-2xl {
  border-radius: 1rem;
}

.dq-rounded-3xl {
  border-radius: 1.5rem;
}

.dq-rounded-full {
  border-radius: 9999px;
}

.dq-rounded-t-none {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.dq-rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.dq-rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.dq-rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.dq-rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.dq-rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.dq-rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.dq-rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.dq-rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.dq-rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dq-rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.dq-rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.dq-rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.dq-rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.dq-rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.dq-rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.dq-rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.dq-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.dq-rounded-b-none {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.dq-rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.dq-rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.dq-rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.dq-rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.dq-rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.dq-rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.dq-rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.dq-rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.dq-rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.dq-rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.dq-rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.dq-rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.dq-rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.dq-rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.dq-rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.dq-rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.dq-rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.dq-rounded-tl-none {
  border-top-left-radius: 0px;
}

.dq-rounded-tl-sm {
  border-top-left-radius: 0.125rem;
}

.dq-rounded-tl {
  border-top-left-radius: 0.25rem;
}

.dq-rounded-tl-md {
  border-top-left-radius: 0.375rem;
}

.dq-rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}

.dq-rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}

.dq-rounded-tl-2xl {
  border-top-left-radius: 1rem;
}

.dq-rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}

.dq-rounded-tl-full {
  border-top-left-radius: 9999px;
}

.dq-rounded-tr-none {
  border-top-right-radius: 0px;
}

.dq-rounded-tr-sm {
  border-top-right-radius: 0.125rem;
}

.dq-rounded-tr {
  border-top-right-radius: 0.25rem;
}

.dq-rounded-tr-md {
  border-top-right-radius: 0.375rem;
}

.dq-rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.dq-rounded-tr-xl {
  border-top-right-radius: 0.75rem;
}

.dq-rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.dq-rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}

.dq-rounded-tr-full {
  border-top-right-radius: 9999px;
}

.dq-rounded-br-none {
  border-bottom-right-radius: 0px;
}

.dq-rounded-br-sm {
  border-bottom-right-radius: 0.125rem;
}

.dq-rounded-br {
  border-bottom-right-radius: 0.25rem;
}

.dq-rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}

.dq-rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.dq-rounded-br-xl {
  border-bottom-right-radius: 0.75rem;
}

.dq-rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.dq-rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}

.dq-rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.dq-rounded-bl-none {
  border-bottom-left-radius: 0px;
}

.dq-rounded-bl-sm {
  border-bottom-left-radius: 0.125rem;
}

.dq-rounded-bl {
  border-bottom-left-radius: 0.25rem;
}

.dq-rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}

.dq-rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}

.dq-rounded-bl-xl {
  border-bottom-left-radius: 0.75rem;
}

.dq-rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.dq-rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}

.dq-rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.dq-border-0 {
  border-width: 0px;
}

.dq-border-2 {
  border-width: 2px;
}

.dq-border-4 {
  border-width: 4px;
}

.dq-border-8 {
  border-width: 8px;
}

.dq-border {
  border-width: 1px;
}

.dq-border-t-0 {
  border-top-width: 0px;
}

.dq-border-t-2 {
  border-top-width: 2px;
}

.dq-border-t-4 {
  border-top-width: 4px;
}

.dq-border-t-8 {
  border-top-width: 8px;
}

.dq-border-t {
  border-top-width: 1px;
}

.dq-border-r-0 {
  border-right-width: 0px;
}

.dq-border-r-2 {
  border-right-width: 2px;
}

.dq-border-r-4 {
  border-right-width: 4px;
}

.dq-border-r-8 {
  border-right-width: 8px;
}

.dq-border-r {
  border-right-width: 1px;
}

.dq-border-b-0 {
  border-bottom-width: 0px;
}

.dq-border-b-2 {
  border-bottom-width: 2px;
}

.dq-border-b-4 {
  border-bottom-width: 4px;
}

.dq-border-b-8 {
  border-bottom-width: 8px;
}

.dq-border-b {
  border-bottom-width: 1px;
}

.dq-border-l-0 {
  border-left-width: 0px;
}

.dq-border-l-2 {
  border-left-width: 2px;
}

.dq-border-l-4 {
  border-left-width: 4px;
}

.dq-border-l-8 {
  border-left-width: 8px;
}

.dq-border-l {
  border-left-width: 1px;
}

.dq-border-solid {
  border-style: solid;
}

.dq-border-dashed {
  border-style: dashed;
}

.dq-border-dotted {
  border-style: dotted;
}

.dq-border-double {
  border-style: double;
}

.dq-border-none {
  border-style: none;
}

.dq-border-transparent {
  border-color: transparent;
}

.dq-border-current {
  border-color: currentColor;
}

.dq-border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.dq-border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.dq-border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.dq-border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.dq-border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.dq-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.dq-border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.dq-border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.dq-border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.dq-border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.dq-border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.dq-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

.dq-border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}

.dq-border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}

.dq-border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}

.dq-border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}

.dq-border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.dq-border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.dq-border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}

.dq-border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}

.dq-border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}

.dq-border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}

.dq-border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}

.dq-border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}

.dq-border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}

.dq-border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}

.dq-border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}

.dq-border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}

.dq-border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}

.dq-border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}

.dq-border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}

.dq-border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}

.dq-border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}

.dq-border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}

.dq-border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}

.dq-border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.dq-border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.dq-border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.dq-border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}

.dq-border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.dq-border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}

.dq-border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}

.dq-border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}

.dq-border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}

.dq-border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}

.dq-border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.dq-border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}

.dq-border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.dq-border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}

.dq-border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.dq-border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}

.dq-border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}

.dq-border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}

.dq-border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}

.dq-border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.dq-border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.dq-border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.dq-border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.dq-border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.dq-border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.dq-border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}

.dq-border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}

.dq-border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}

.dq-border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}

.dq-border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}

.dq-border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}

.dq-border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}

.dq-border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}

.dq-border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}

.dq-border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}

.dq-border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}

.dq-border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}

.dq-border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}

.dq-border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}

.dq-border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}

.dq-border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}

.dq-border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}

.dq-border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.dq-border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}

.dq-border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}

.dq-border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}

.dq-border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-transparent {
  border-color: transparent;
}

.dq-group:hover .group-hover\:dq-border-current {
  border-color: currentColor;
}

.dq-group:hover .group-hover\:dq-border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}

.dq-group:hover .group-hover\:dq-border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.focus-within\:dq-border-transparent:focus-within {
  border-color: transparent;
}

.focus-within\:dq-border-current:focus-within {
  border-color: currentColor;
}

.focus-within\:dq-border-black:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.focus-within\:dq-border-white:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.focus-within\:dq-border-gray-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.focus-within\:dq-border-gray-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.focus-within\:dq-border-gray-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.focus-within\:dq-border-gray-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.focus-within\:dq-border-gray-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.focus-within\:dq-border-gray-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.focus-within\:dq-border-gray-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.focus-within\:dq-border-gray-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.focus-within\:dq-border-gray-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.focus-within\:dq-border-gray-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

.focus-within\:dq-border-red-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}

.focus-within\:dq-border-red-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}

.focus-within\:dq-border-red-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}

.focus-within\:dq-border-red-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}

.focus-within\:dq-border-red-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.focus-within\:dq-border-red-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.focus-within\:dq-border-red-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}

.focus-within\:dq-border-red-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}

.focus-within\:dq-border-red-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}

.focus-within\:dq-border-red-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}

.focus-within\:dq-border-yellow-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}

.focus-within\:dq-border-yellow-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}

.focus-within\:dq-border-yellow-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}

.focus-within\:dq-border-yellow-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}

.focus-within\:dq-border-yellow-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}

.focus-within\:dq-border-yellow-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}

.focus-within\:dq-border-yellow-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}

.focus-within\:dq-border-yellow-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}

.focus-within\:dq-border-yellow-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}

.focus-within\:dq-border-yellow-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}

.focus-within\:dq-border-green-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}

.focus-within\:dq-border-green-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}

.focus-within\:dq-border-green-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}

.focus-within\:dq-border-green-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.focus-within\:dq-border-green-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.focus-within\:dq-border-green-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.focus-within\:dq-border-green-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}

.focus-within\:dq-border-green-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.focus-within\:dq-border-green-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}

.focus-within\:dq-border-green-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}

.focus-within\:dq-border-blue-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}

.focus-within\:dq-border-blue-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}

.focus-within\:dq-border-blue-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}

.focus-within\:dq-border-blue-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.focus-within\:dq-border-blue-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}

.focus-within\:dq-border-blue-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.focus-within\:dq-border-blue-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}

.focus-within\:dq-border-blue-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.focus-within\:dq-border-blue-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}

.focus-within\:dq-border-blue-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}

.focus-within\:dq-border-indigo-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}

.focus-within\:dq-border-indigo-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}

.focus-within\:dq-border-indigo-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.focus-within\:dq-border-indigo-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.focus-within\:dq-border-indigo-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.focus-within\:dq-border-indigo-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.focus-within\:dq-border-indigo-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.focus-within\:dq-border-indigo-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.focus-within\:dq-border-indigo-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}

.focus-within\:dq-border-indigo-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}

.focus-within\:dq-border-purple-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}

.focus-within\:dq-border-purple-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}

.focus-within\:dq-border-purple-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}

.focus-within\:dq-border-purple-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}

.focus-within\:dq-border-purple-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}

.focus-within\:dq-border-purple-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}

.focus-within\:dq-border-purple-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}

.focus-within\:dq-border-purple-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}

.focus-within\:dq-border-purple-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}

.focus-within\:dq-border-purple-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}

.focus-within\:dq-border-pink-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}

.focus-within\:dq-border-pink-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}

.focus-within\:dq-border-pink-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}

.focus-within\:dq-border-pink-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}

.focus-within\:dq-border-pink-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}

.focus-within\:dq-border-pink-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.focus-within\:dq-border-pink-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}

.focus-within\:dq-border-pink-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}

.focus-within\:dq-border-pink-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}

.focus-within\:dq-border-pink-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.hover\:dq-border-transparent:hover {
  border-color: transparent;
}

.hover\:dq-border-current:hover {
  border-color: currentColor;
}

.hover\:dq-border-black:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.hover\:dq-border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.hover\:dq-border-gray-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.hover\:dq-border-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.hover\:dq-border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.hover\:dq-border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.hover\:dq-border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.hover\:dq-border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.hover\:dq-border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.hover\:dq-border-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.hover\:dq-border-gray-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.hover\:dq-border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

.hover\:dq-border-red-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}

.hover\:dq-border-red-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}

.hover\:dq-border-red-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}

.hover\:dq-border-red-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}

.hover\:dq-border-red-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.hover\:dq-border-red-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.hover\:dq-border-red-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}

.hover\:dq-border-red-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}

.hover\:dq-border-red-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}

.hover\:dq-border-red-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}

.hover\:dq-border-yellow-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}

.hover\:dq-border-yellow-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}

.hover\:dq-border-yellow-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}

.hover\:dq-border-yellow-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}

.hover\:dq-border-yellow-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}

.hover\:dq-border-yellow-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}

.hover\:dq-border-yellow-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}

.hover\:dq-border-yellow-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}

.hover\:dq-border-yellow-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}

.hover\:dq-border-yellow-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}

.hover\:dq-border-green-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}

.hover\:dq-border-green-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}

.hover\:dq-border-green-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}

.hover\:dq-border-green-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.hover\:dq-border-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.hover\:dq-border-green-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.hover\:dq-border-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}

.hover\:dq-border-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.hover\:dq-border-green-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}

.hover\:dq-border-green-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}

.hover\:dq-border-blue-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}

.hover\:dq-border-blue-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}

.hover\:dq-border-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}

.hover\:dq-border-blue-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.hover\:dq-border-blue-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}

.hover\:dq-border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.hover\:dq-border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}

.hover\:dq-border-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.hover\:dq-border-blue-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}

.hover\:dq-border-blue-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}

.hover\:dq-border-indigo-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}

.hover\:dq-border-indigo-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}

.hover\:dq-border-indigo-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.hover\:dq-border-indigo-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.hover\:dq-border-indigo-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.hover\:dq-border-indigo-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.hover\:dq-border-indigo-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.hover\:dq-border-indigo-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.hover\:dq-border-indigo-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}

.hover\:dq-border-indigo-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}

.hover\:dq-border-purple-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}

.hover\:dq-border-purple-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}

.hover\:dq-border-purple-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}

.hover\:dq-border-purple-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}

.hover\:dq-border-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}

.hover\:dq-border-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}

.hover\:dq-border-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}

.hover\:dq-border-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}

.hover\:dq-border-purple-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}

.hover\:dq-border-purple-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}

.hover\:dq-border-pink-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}

.hover\:dq-border-pink-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}

.hover\:dq-border-pink-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}

.hover\:dq-border-pink-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}

.hover\:dq-border-pink-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}

.hover\:dq-border-pink-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.hover\:dq-border-pink-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}

.hover\:dq-border-pink-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}

.hover\:dq-border-pink-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}

.hover\:dq-border-pink-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.focus\:dq-border-transparent:focus {
  border-color: transparent;
}

.focus\:dq-border-current:focus {
  border-color: currentColor;
}

.focus\:dq-border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.focus\:dq-border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.focus\:dq-border-gray-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.focus\:dq-border-gray-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.focus\:dq-border-gray-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.focus\:dq-border-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.focus\:dq-border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.focus\:dq-border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.focus\:dq-border-gray-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.focus\:dq-border-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.focus\:dq-border-gray-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.focus\:dq-border-gray-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

.focus\:dq-border-red-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}

.focus\:dq-border-red-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}

.focus\:dq-border-red-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}

.focus\:dq-border-red-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}

.focus\:dq-border-red-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.focus\:dq-border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.focus\:dq-border-red-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}

.focus\:dq-border-red-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}

.focus\:dq-border-red-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}

.focus\:dq-border-red-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}

.focus\:dq-border-yellow-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}

.focus\:dq-border-yellow-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}

.focus\:dq-border-yellow-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}

.focus\:dq-border-yellow-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}

.focus\:dq-border-yellow-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}

.focus\:dq-border-yellow-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}

.focus\:dq-border-yellow-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}

.focus\:dq-border-yellow-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}

.focus\:dq-border-yellow-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}

.focus\:dq-border-yellow-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}

.focus\:dq-border-green-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}

.focus\:dq-border-green-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}

.focus\:dq-border-green-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}

.focus\:dq-border-green-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.focus\:dq-border-green-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.focus\:dq-border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.focus\:dq-border-green-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}

.focus\:dq-border-green-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.focus\:dq-border-green-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}

.focus\:dq-border-green-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}

.focus\:dq-border-blue-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}

.focus\:dq-border-blue-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}

.focus\:dq-border-blue-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}

.focus\:dq-border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.focus\:dq-border-blue-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}

.focus\:dq-border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.focus\:dq-border-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}

.focus\:dq-border-blue-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.focus\:dq-border-blue-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}

.focus\:dq-border-blue-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}

.focus\:dq-border-indigo-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}

.focus\:dq-border-indigo-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}

.focus\:dq-border-indigo-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.focus\:dq-border-indigo-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.focus\:dq-border-indigo-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.focus\:dq-border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.focus\:dq-border-indigo-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.focus\:dq-border-indigo-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.focus\:dq-border-indigo-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}

.focus\:dq-border-indigo-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}

.focus\:dq-border-purple-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}

.focus\:dq-border-purple-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}

.focus\:dq-border-purple-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}

.focus\:dq-border-purple-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}

.focus\:dq-border-purple-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}

.focus\:dq-border-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}

.focus\:dq-border-purple-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}

.focus\:dq-border-purple-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}

.focus\:dq-border-purple-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}

.focus\:dq-border-purple-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}

.focus\:dq-border-pink-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}

.focus\:dq-border-pink-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}

.focus\:dq-border-pink-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}

.focus\:dq-border-pink-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}

.focus\:dq-border-pink-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}

.focus\:dq-border-pink-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.focus\:dq-border-pink-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}

.focus\:dq-border-pink-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}

.focus\:dq-border-pink-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}

.focus\:dq-border-pink-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.dq-border-opacity-0 {
  --tw-border-opacity: 0;
}

.dq-border-opacity-5 {
  --tw-border-opacity: 0.05;
}

.dq-border-opacity-10 {
  --tw-border-opacity: 0.1;
}

.dq-border-opacity-20 {
  --tw-border-opacity: 0.2;
}

.dq-border-opacity-25 {
  --tw-border-opacity: 0.25;
}

.dq-border-opacity-30 {
  --tw-border-opacity: 0.3;
}

.dq-border-opacity-40 {
  --tw-border-opacity: 0.4;
}

.dq-border-opacity-50 {
  --tw-border-opacity: 0.5;
}

.dq-border-opacity-60 {
  --tw-border-opacity: 0.6;
}

.dq-border-opacity-70 {
  --tw-border-opacity: 0.7;
}

.dq-border-opacity-75 {
  --tw-border-opacity: 0.75;
}

.dq-border-opacity-80 {
  --tw-border-opacity: 0.8;
}

.dq-border-opacity-90 {
  --tw-border-opacity: 0.9;
}

.dq-border-opacity-95 {
  --tw-border-opacity: 0.95;
}

.dq-border-opacity-100 {
  --tw-border-opacity: 1;
}

.dq-group:hover .group-hover\:dq-border-opacity-0 {
  --tw-border-opacity: 0;
}

.dq-group:hover .group-hover\:dq-border-opacity-5 {
  --tw-border-opacity: 0.05;
}

.dq-group:hover .group-hover\:dq-border-opacity-10 {
  --tw-border-opacity: 0.1;
}

.dq-group:hover .group-hover\:dq-border-opacity-20 {
  --tw-border-opacity: 0.2;
}

.dq-group:hover .group-hover\:dq-border-opacity-25 {
  --tw-border-opacity: 0.25;
}

.dq-group:hover .group-hover\:dq-border-opacity-30 {
  --tw-border-opacity: 0.3;
}

.dq-group:hover .group-hover\:dq-border-opacity-40 {
  --tw-border-opacity: 0.4;
}

.dq-group:hover .group-hover\:dq-border-opacity-50 {
  --tw-border-opacity: 0.5;
}

.dq-group:hover .group-hover\:dq-border-opacity-60 {
  --tw-border-opacity: 0.6;
}

.dq-group:hover .group-hover\:dq-border-opacity-70 {
  --tw-border-opacity: 0.7;
}

.dq-group:hover .group-hover\:dq-border-opacity-75 {
  --tw-border-opacity: 0.75;
}

.dq-group:hover .group-hover\:dq-border-opacity-80 {
  --tw-border-opacity: 0.8;
}

.dq-group:hover .group-hover\:dq-border-opacity-90 {
  --tw-border-opacity: 0.9;
}

.dq-group:hover .group-hover\:dq-border-opacity-95 {
  --tw-border-opacity: 0.95;
}

.dq-group:hover .group-hover\:dq-border-opacity-100 {
  --tw-border-opacity: 1;
}

.focus-within\:dq-border-opacity-0:focus-within {
  --tw-border-opacity: 0;
}

.focus-within\:dq-border-opacity-5:focus-within {
  --tw-border-opacity: 0.05;
}

.focus-within\:dq-border-opacity-10:focus-within {
  --tw-border-opacity: 0.1;
}

.focus-within\:dq-border-opacity-20:focus-within {
  --tw-border-opacity: 0.2;
}

.focus-within\:dq-border-opacity-25:focus-within {
  --tw-border-opacity: 0.25;
}

.focus-within\:dq-border-opacity-30:focus-within {
  --tw-border-opacity: 0.3;
}

.focus-within\:dq-border-opacity-40:focus-within {
  --tw-border-opacity: 0.4;
}

.focus-within\:dq-border-opacity-50:focus-within {
  --tw-border-opacity: 0.5;
}

.focus-within\:dq-border-opacity-60:focus-within {
  --tw-border-opacity: 0.6;
}

.focus-within\:dq-border-opacity-70:focus-within {
  --tw-border-opacity: 0.7;
}

.focus-within\:dq-border-opacity-75:focus-within {
  --tw-border-opacity: 0.75;
}

.focus-within\:dq-border-opacity-80:focus-within {
  --tw-border-opacity: 0.8;
}

.focus-within\:dq-border-opacity-90:focus-within {
  --tw-border-opacity: 0.9;
}

.focus-within\:dq-border-opacity-95:focus-within {
  --tw-border-opacity: 0.95;
}

.focus-within\:dq-border-opacity-100:focus-within {
  --tw-border-opacity: 1;
}

.hover\:dq-border-opacity-0:hover {
  --tw-border-opacity: 0;
}

.hover\:dq-border-opacity-5:hover {
  --tw-border-opacity: 0.05;
}

.hover\:dq-border-opacity-10:hover {
  --tw-border-opacity: 0.1;
}

.hover\:dq-border-opacity-20:hover {
  --tw-border-opacity: 0.2;
}

.hover\:dq-border-opacity-25:hover {
  --tw-border-opacity: 0.25;
}

.hover\:dq-border-opacity-30:hover {
  --tw-border-opacity: 0.3;
}

.hover\:dq-border-opacity-40:hover {
  --tw-border-opacity: 0.4;
}

.hover\:dq-border-opacity-50:hover {
  --tw-border-opacity: 0.5;
}

.hover\:dq-border-opacity-60:hover {
  --tw-border-opacity: 0.6;
}

.hover\:dq-border-opacity-70:hover {
  --tw-border-opacity: 0.7;
}

.hover\:dq-border-opacity-75:hover {
  --tw-border-opacity: 0.75;
}

.hover\:dq-border-opacity-80:hover {
  --tw-border-opacity: 0.8;
}

.hover\:dq-border-opacity-90:hover {
  --tw-border-opacity: 0.9;
}

.hover\:dq-border-opacity-95:hover {
  --tw-border-opacity: 0.95;
}

.hover\:dq-border-opacity-100:hover {
  --tw-border-opacity: 1;
}

.focus\:dq-border-opacity-0:focus {
  --tw-border-opacity: 0;
}

.focus\:dq-border-opacity-5:focus {
  --tw-border-opacity: 0.05;
}

.focus\:dq-border-opacity-10:focus {
  --tw-border-opacity: 0.1;
}

.focus\:dq-border-opacity-20:focus {
  --tw-border-opacity: 0.2;
}

.focus\:dq-border-opacity-25:focus {
  --tw-border-opacity: 0.25;
}

.focus\:dq-border-opacity-30:focus {
  --tw-border-opacity: 0.3;
}

.focus\:dq-border-opacity-40:focus {
  --tw-border-opacity: 0.4;
}

.focus\:dq-border-opacity-50:focus {
  --tw-border-opacity: 0.5;
}

.focus\:dq-border-opacity-60:focus {
  --tw-border-opacity: 0.6;
}

.focus\:dq-border-opacity-70:focus {
  --tw-border-opacity: 0.7;
}

.focus\:dq-border-opacity-75:focus {
  --tw-border-opacity: 0.75;
}

.focus\:dq-border-opacity-80:focus {
  --tw-border-opacity: 0.8;
}

.focus\:dq-border-opacity-90:focus {
  --tw-border-opacity: 0.9;
}

.focus\:dq-border-opacity-95:focus {
  --tw-border-opacity: 0.95;
}

.focus\:dq-border-opacity-100:focus {
  --tw-border-opacity: 1;
}

.dq-bg-transparent {
  background-color: transparent;
}

.dq-bg-current {
  background-color: currentColor;
}

.dq-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.dq-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dq-bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.dq-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.dq-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.dq-bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.dq-bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.dq-bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.dq-bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.dq-bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.dq-bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.dq-bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.dq-bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}

.dq-bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.dq-bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.dq-bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}

.dq-bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.dq-bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.dq-bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.dq-bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.dq-bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}

.dq-bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}

.dq-bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}

.dq-bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.dq-bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.dq-bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}

.dq-bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}

.dq-bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.dq-bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.dq-bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}

.dq-bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}

.dq-bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}

.dq-bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.dq-bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.dq-bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.dq-bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.dq-bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.dq-bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.dq-bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.dq-bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.dq-bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.dq-bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}

.dq-bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.dq-bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.dq-bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.dq-bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.dq-bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.dq-bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.dq-bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.dq-bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.dq-bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}

.dq-bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}

.dq-bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.dq-bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.dq-bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.dq-bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.dq-bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}

.dq-bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.dq-bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.dq-bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.dq-bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}

.dq-bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}

.dq-bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}

.dq-bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.dq-bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.dq-bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.dq-bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}

.dq-bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.dq-bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}

.dq-bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}

.dq-bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

.dq-bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}

.dq-bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}

.dq-bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.dq-bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.dq-bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.dq-bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}

.dq-bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.dq-bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.dq-bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}

.dq-bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.dq-bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-transparent {
  background-color: transparent;
}

.dq-group:hover .group-hover\:dq-bg-current {
  background-color: currentColor;
}

.dq-group:hover .group-hover\:dq-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.dq-group:hover .group-hover\:dq-bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-transparent:focus-within {
  background-color: transparent;
}

.focus-within\:dq-bg-current:focus-within {
  background-color: currentColor;
}

.focus-within\:dq-bg-black:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-white:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-gray-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-gray-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-gray-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-gray-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-gray-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-gray-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-gray-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-gray-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-gray-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-gray-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-red-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-red-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-red-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-red-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-red-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-red-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-red-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-red-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-red-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-red-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-yellow-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-yellow-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-yellow-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-yellow-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-yellow-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-yellow-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-yellow-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-yellow-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-yellow-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-yellow-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-green-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-green-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-green-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-green-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-green-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-green-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-green-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-green-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-green-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-green-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-blue-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-blue-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-blue-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-blue-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-blue-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-blue-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-blue-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-blue-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-blue-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-blue-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-indigo-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-indigo-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-indigo-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-indigo-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-indigo-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-indigo-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-indigo-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-indigo-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-indigo-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-indigo-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-purple-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-purple-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-purple-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-purple-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-purple-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-purple-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-purple-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-purple-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-purple-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-purple-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-pink-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-pink-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-pink-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-pink-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-pink-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-pink-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-pink-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-pink-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-pink-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.focus-within\:dq-bg-pink-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.hover\:dq-bg-transparent:hover {
  background-color: transparent;
}

.hover\:dq-bg-current:hover {
  background-color: currentColor;
}

.hover\:dq-bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.hover\:dq-bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:dq-bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.hover\:dq-bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.hover\:dq-bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.hover\:dq-bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.hover\:dq-bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.hover\:dq-bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.hover\:dq-bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.hover\:dq-bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.hover\:dq-bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.hover\:dq-bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.hover\:dq-bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}

.hover\:dq-bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.hover\:dq-bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.hover\:dq-bg-red-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}

.hover\:dq-bg-red-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.hover\:dq-bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.hover\:dq-bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.hover\:dq-bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.hover\:dq-bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}

.hover\:dq-bg-red-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}

.hover\:dq-bg-yellow-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}

.hover\:dq-bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.hover\:dq-bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.hover\:dq-bg-yellow-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}

.hover\:dq-bg-yellow-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}

.hover\:dq-bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.hover\:dq-bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.hover\:dq-bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}

.hover\:dq-bg-yellow-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}

.hover\:dq-bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}

.hover\:dq-bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.hover\:dq-bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.hover\:dq-bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.hover\:dq-bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.hover\:dq-bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.hover\:dq-bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.hover\:dq-bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.hover\:dq-bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.hover\:dq-bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.hover\:dq-bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}

.hover\:dq-bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.hover\:dq-bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.hover\:dq-bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.hover\:dq-bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.hover\:dq-bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.hover\:dq-bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.hover\:dq-bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.hover\:dq-bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.hover\:dq-bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}

.hover\:dq-bg-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}

.hover\:dq-bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.hover\:dq-bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.hover\:dq-bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.hover\:dq-bg-indigo-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.hover\:dq-bg-indigo-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}

.hover\:dq-bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.hover\:dq-bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.hover\:dq-bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.hover\:dq-bg-indigo-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}

.hover\:dq-bg-indigo-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}

.hover\:dq-bg-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}

.hover\:dq-bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.hover\:dq-bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.hover\:dq-bg-purple-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.hover\:dq-bg-purple-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}

.hover\:dq-bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.hover\:dq-bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}

.hover\:dq-bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}

.hover\:dq-bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

.hover\:dq-bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}

.hover\:dq-bg-pink-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}

.hover\:dq-bg-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.hover\:dq-bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.hover\:dq-bg-pink-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.hover\:dq-bg-pink-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}

.hover\:dq-bg-pink-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.hover\:dq-bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.hover\:dq-bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}

.hover\:dq-bg-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.hover\:dq-bg-pink-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.focus\:dq-bg-transparent:focus {
  background-color: transparent;
}

.focus\:dq-bg-current:focus {
  background-color: currentColor;
}

.focus\:dq-bg-black:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.focus\:dq-bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.focus\:dq-bg-gray-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.focus\:dq-bg-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.focus\:dq-bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.focus\:dq-bg-gray-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.focus\:dq-bg-gray-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.focus\:dq-bg-gray-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.focus\:dq-bg-gray-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.focus\:dq-bg-gray-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.focus\:dq-bg-gray-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.focus\:dq-bg-gray-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.focus\:dq-bg-red-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}

.focus\:dq-bg-red-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.focus\:dq-bg-red-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.focus\:dq-bg-red-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}

.focus\:dq-bg-red-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.focus\:dq-bg-red-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.focus\:dq-bg-red-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.focus\:dq-bg-red-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.focus\:dq-bg-red-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}

.focus\:dq-bg-red-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}

.focus\:dq-bg-yellow-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}

.focus\:dq-bg-yellow-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.focus\:dq-bg-yellow-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.focus\:dq-bg-yellow-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}

.focus\:dq-bg-yellow-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}

.focus\:dq-bg-yellow-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.focus\:dq-bg-yellow-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.focus\:dq-bg-yellow-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}

.focus\:dq-bg-yellow-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}

.focus\:dq-bg-yellow-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}

.focus\:dq-bg-green-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.focus\:dq-bg-green-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.focus\:dq-bg-green-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.focus\:dq-bg-green-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.focus\:dq-bg-green-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.focus\:dq-bg-green-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.focus\:dq-bg-green-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.focus\:dq-bg-green-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.focus\:dq-bg-green-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.focus\:dq-bg-green-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}

.focus\:dq-bg-blue-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.focus\:dq-bg-blue-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.focus\:dq-bg-blue-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.focus\:dq-bg-blue-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.focus\:dq-bg-blue-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.focus\:dq-bg-blue-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.focus\:dq-bg-blue-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.focus\:dq-bg-blue-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.focus\:dq-bg-blue-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}

.focus\:dq-bg-blue-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}

.focus\:dq-bg-indigo-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.focus\:dq-bg-indigo-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.focus\:dq-bg-indigo-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.focus\:dq-bg-indigo-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.focus\:dq-bg-indigo-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}

.focus\:dq-bg-indigo-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.focus\:dq-bg-indigo-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.focus\:dq-bg-indigo-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.focus\:dq-bg-indigo-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}

.focus\:dq-bg-indigo-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}

.focus\:dq-bg-purple-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}

.focus\:dq-bg-purple-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.focus\:dq-bg-purple-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.focus\:dq-bg-purple-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.focus\:dq-bg-purple-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}

.focus\:dq-bg-purple-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.focus\:dq-bg-purple-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}

.focus\:dq-bg-purple-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}

.focus\:dq-bg-purple-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

.focus\:dq-bg-purple-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}

.focus\:dq-bg-pink-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}

.focus\:dq-bg-pink-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.focus\:dq-bg-pink-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.focus\:dq-bg-pink-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.focus\:dq-bg-pink-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}

.focus\:dq-bg-pink-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.focus\:dq-bg-pink-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.focus\:dq-bg-pink-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}

.focus\:dq-bg-pink-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.focus\:dq-bg-pink-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.dq-bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.dq-bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}

.dq-bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}

.dq-bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}

.dq-bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}

.dq-bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}

.dq-bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}

.dq-bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.dq-bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}

.dq-bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}

.dq-bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}

.dq-bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}

.dq-bg-opacity-90 {
  --tw-bg-opacity: 0.9;
}

.dq-bg-opacity-95 {
  --tw-bg-opacity: 0.95;
}

.dq-bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.dq-group:hover .group-hover\:dq-bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.dq-group:hover .group-hover\:dq-bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}

.dq-group:hover .group-hover\:dq-bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}

.dq-group:hover .group-hover\:dq-bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}

.dq-group:hover .group-hover\:dq-bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}

.dq-group:hover .group-hover\:dq-bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}

.dq-group:hover .group-hover\:dq-bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}

.dq-group:hover .group-hover\:dq-bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.dq-group:hover .group-hover\:dq-bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}

.dq-group:hover .group-hover\:dq-bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}

.dq-group:hover .group-hover\:dq-bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}

.dq-group:hover .group-hover\:dq-bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}

.dq-group:hover .group-hover\:dq-bg-opacity-90 {
  --tw-bg-opacity: 0.9;
}

.dq-group:hover .group-hover\:dq-bg-opacity-95 {
  --tw-bg-opacity: 0.95;
}

.dq-group:hover .group-hover\:dq-bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.focus-within\:dq-bg-opacity-0:focus-within {
  --tw-bg-opacity: 0;
}

.focus-within\:dq-bg-opacity-5:focus-within {
  --tw-bg-opacity: 0.05;
}

.focus-within\:dq-bg-opacity-10:focus-within {
  --tw-bg-opacity: 0.1;
}

.focus-within\:dq-bg-opacity-20:focus-within {
  --tw-bg-opacity: 0.2;
}

.focus-within\:dq-bg-opacity-25:focus-within {
  --tw-bg-opacity: 0.25;
}

.focus-within\:dq-bg-opacity-30:focus-within {
  --tw-bg-opacity: 0.3;
}

.focus-within\:dq-bg-opacity-40:focus-within {
  --tw-bg-opacity: 0.4;
}

.focus-within\:dq-bg-opacity-50:focus-within {
  --tw-bg-opacity: 0.5;
}

.focus-within\:dq-bg-opacity-60:focus-within {
  --tw-bg-opacity: 0.6;
}

.focus-within\:dq-bg-opacity-70:focus-within {
  --tw-bg-opacity: 0.7;
}

.focus-within\:dq-bg-opacity-75:focus-within {
  --tw-bg-opacity: 0.75;
}

.focus-within\:dq-bg-opacity-80:focus-within {
  --tw-bg-opacity: 0.8;
}

.focus-within\:dq-bg-opacity-90:focus-within {
  --tw-bg-opacity: 0.9;
}

.focus-within\:dq-bg-opacity-95:focus-within {
  --tw-bg-opacity: 0.95;
}

.focus-within\:dq-bg-opacity-100:focus-within {
  --tw-bg-opacity: 1;
}

.hover\:dq-bg-opacity-0:hover {
  --tw-bg-opacity: 0;
}

.hover\:dq-bg-opacity-5:hover {
  --tw-bg-opacity: 0.05;
}

.hover\:dq-bg-opacity-10:hover {
  --tw-bg-opacity: 0.1;
}

.hover\:dq-bg-opacity-20:hover {
  --tw-bg-opacity: 0.2;
}

.hover\:dq-bg-opacity-25:hover {
  --tw-bg-opacity: 0.25;
}

.hover\:dq-bg-opacity-30:hover {
  --tw-bg-opacity: 0.3;
}

.hover\:dq-bg-opacity-40:hover {
  --tw-bg-opacity: 0.4;
}

.hover\:dq-bg-opacity-50:hover {
  --tw-bg-opacity: 0.5;
}

.hover\:dq-bg-opacity-60:hover {
  --tw-bg-opacity: 0.6;
}

.hover\:dq-bg-opacity-70:hover {
  --tw-bg-opacity: 0.7;
}

.hover\:dq-bg-opacity-75:hover {
  --tw-bg-opacity: 0.75;
}

.hover\:dq-bg-opacity-80:hover {
  --tw-bg-opacity: 0.8;
}

.hover\:dq-bg-opacity-90:hover {
  --tw-bg-opacity: 0.9;
}

.hover\:dq-bg-opacity-95:hover {
  --tw-bg-opacity: 0.95;
}

.hover\:dq-bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}

.focus\:dq-bg-opacity-0:focus {
  --tw-bg-opacity: 0;
}

.focus\:dq-bg-opacity-5:focus {
  --tw-bg-opacity: 0.05;
}

.focus\:dq-bg-opacity-10:focus {
  --tw-bg-opacity: 0.1;
}

.focus\:dq-bg-opacity-20:focus {
  --tw-bg-opacity: 0.2;
}

.focus\:dq-bg-opacity-25:focus {
  --tw-bg-opacity: 0.25;
}

.focus\:dq-bg-opacity-30:focus {
  --tw-bg-opacity: 0.3;
}

.focus\:dq-bg-opacity-40:focus {
  --tw-bg-opacity: 0.4;
}

.focus\:dq-bg-opacity-50:focus {
  --tw-bg-opacity: 0.5;
}

.focus\:dq-bg-opacity-60:focus {
  --tw-bg-opacity: 0.6;
}

.focus\:dq-bg-opacity-70:focus {
  --tw-bg-opacity: 0.7;
}

.focus\:dq-bg-opacity-75:focus {
  --tw-bg-opacity: 0.75;
}

.focus\:dq-bg-opacity-80:focus {
  --tw-bg-opacity: 0.8;
}

.focus\:dq-bg-opacity-90:focus {
  --tw-bg-opacity: 0.9;
}

.focus\:dq-bg-opacity-95:focus {
  --tw-bg-opacity: 0.95;
}

.focus\:dq-bg-opacity-100:focus {
  --tw-bg-opacity: 1;
}

.dq-bg-none {
  background-image: none;
}

.dq-bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.dq-bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.dq-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.dq-bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.dq-bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.dq-bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.dq-bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.dq-bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}

.dq-from-transparent {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.dq-from-current {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.dq-from-black {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.dq-from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.dq-from-gray-50 {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
}

.dq-from-gray-100 {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
}

.dq-from-gray-200 {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
}

.dq-from-gray-300 {
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
}

.dq-from-gray-400 {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
}

.dq-from-gray-500 {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
}

.dq-from-gray-600 {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
}

.dq-from-gray-700 {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
}

.dq-from-gray-800 {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
}

.dq-from-gray-900 {
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
}

.dq-from-red-50 {
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
}

.dq-from-red-100 {
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
}

.dq-from-red-200 {
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
}

.dq-from-red-300 {
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
}

.dq-from-red-400 {
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
}

.dq-from-red-500 {
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
}

.dq-from-red-600 {
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
}

.dq-from-red-700 {
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
}

.dq-from-red-800 {
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
}

.dq-from-red-900 {
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
}

.dq-from-yellow-50 {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
}

.dq-from-yellow-100 {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
}

.dq-from-yellow-200 {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
}

.dq-from-yellow-300 {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
}

.dq-from-yellow-400 {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
}

.dq-from-yellow-500 {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
}

.dq-from-yellow-600 {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
}

.dq-from-yellow-700 {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
}

.dq-from-yellow-800 {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
}

.dq-from-yellow-900 {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
}

.dq-from-green-50 {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
}

.dq-from-green-100 {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
}

.dq-from-green-200 {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
}

.dq-from-green-300 {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
}

.dq-from-green-400 {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
}

.dq-from-green-500 {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
}

.dq-from-green-600 {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
}

.dq-from-green-700 {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
}

.dq-from-green-800 {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
}

.dq-from-green-900 {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
}

.dq-from-blue-50 {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
}

.dq-from-blue-100 {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
}

.dq-from-blue-200 {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
}

.dq-from-blue-300 {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
}

.dq-from-blue-400 {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
}

.dq-from-blue-500 {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
}

.dq-from-blue-600 {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
}

.dq-from-blue-700 {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
}

.dq-from-blue-800 {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
}

.dq-from-blue-900 {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
}

.dq-from-indigo-50 {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
}

.dq-from-indigo-100 {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
}

.dq-from-indigo-200 {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
}

.dq-from-indigo-300 {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
}

.dq-from-indigo-400 {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
}

.dq-from-indigo-500 {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
}

.dq-from-indigo-600 {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
}

.dq-from-indigo-700 {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
}

.dq-from-indigo-800 {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
}

.dq-from-indigo-900 {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
}

.dq-from-purple-50 {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
}

.dq-from-purple-100 {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
}

.dq-from-purple-200 {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
}

.dq-from-purple-300 {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
}

.dq-from-purple-400 {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
}

.dq-from-purple-500 {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
}

.dq-from-purple-600 {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
}

.dq-from-purple-700 {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
}

.dq-from-purple-800 {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
}

.dq-from-purple-900 {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
}

.dq-from-pink-50 {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
}

.dq-from-pink-100 {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
}

.dq-from-pink-200 {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
}

.dq-from-pink-300 {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
}

.dq-from-pink-400 {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
}

.dq-from-pink-500 {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
}

.dq-from-pink-600 {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
}

.dq-from-pink-700 {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
}

.dq-from-pink-800 {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
}

.dq-from-pink-900 {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
}

.hover\:dq-from-transparent:hover {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.hover\:dq-from-current:hover {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:dq-from-black:hover {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.hover\:dq-from-white:hover {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:dq-from-gray-50:hover {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
}

.hover\:dq-from-gray-100:hover {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
}

.hover\:dq-from-gray-200:hover {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
}

.hover\:dq-from-gray-300:hover {
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
}

.hover\:dq-from-gray-400:hover {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
}

.hover\:dq-from-gray-500:hover {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
}

.hover\:dq-from-gray-600:hover {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
}

.hover\:dq-from-gray-700:hover {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
}

.hover\:dq-from-gray-800:hover {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
}

.hover\:dq-from-gray-900:hover {
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
}

.hover\:dq-from-red-50:hover {
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
}

.hover\:dq-from-red-100:hover {
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
}

.hover\:dq-from-red-200:hover {
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
}

.hover\:dq-from-red-300:hover {
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
}

.hover\:dq-from-red-400:hover {
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
}

.hover\:dq-from-red-500:hover {
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
}

.hover\:dq-from-red-600:hover {
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
}

.hover\:dq-from-red-700:hover {
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
}

.hover\:dq-from-red-800:hover {
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
}

.hover\:dq-from-red-900:hover {
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
}

.hover\:dq-from-yellow-50:hover {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
}

.hover\:dq-from-yellow-100:hover {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
}

.hover\:dq-from-yellow-200:hover {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
}

.hover\:dq-from-yellow-300:hover {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
}

.hover\:dq-from-yellow-400:hover {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
}

.hover\:dq-from-yellow-500:hover {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
}

.hover\:dq-from-yellow-600:hover {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
}

.hover\:dq-from-yellow-700:hover {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
}

.hover\:dq-from-yellow-800:hover {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
}

.hover\:dq-from-yellow-900:hover {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
}

.hover\:dq-from-green-50:hover {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
}

.hover\:dq-from-green-100:hover {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
}

.hover\:dq-from-green-200:hover {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
}

.hover\:dq-from-green-300:hover {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
}

.hover\:dq-from-green-400:hover {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
}

.hover\:dq-from-green-500:hover {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
}

.hover\:dq-from-green-600:hover {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
}

.hover\:dq-from-green-700:hover {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
}

.hover\:dq-from-green-800:hover {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
}

.hover\:dq-from-green-900:hover {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
}

.hover\:dq-from-blue-50:hover {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
}

.hover\:dq-from-blue-100:hover {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
}

.hover\:dq-from-blue-200:hover {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
}

.hover\:dq-from-blue-300:hover {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
}

.hover\:dq-from-blue-400:hover {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
}

.hover\:dq-from-blue-500:hover {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
}

.hover\:dq-from-blue-600:hover {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
}

.hover\:dq-from-blue-700:hover {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
}

.hover\:dq-from-blue-800:hover {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
}

.hover\:dq-from-blue-900:hover {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
}

.hover\:dq-from-indigo-50:hover {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
}

.hover\:dq-from-indigo-100:hover {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
}

.hover\:dq-from-indigo-200:hover {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
}

.hover\:dq-from-indigo-300:hover {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
}

.hover\:dq-from-indigo-400:hover {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
}

.hover\:dq-from-indigo-500:hover {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
}

.hover\:dq-from-indigo-600:hover {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
}

.hover\:dq-from-indigo-700:hover {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
}

.hover\:dq-from-indigo-800:hover {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
}

.hover\:dq-from-indigo-900:hover {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
}

.hover\:dq-from-purple-50:hover {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
}

.hover\:dq-from-purple-100:hover {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
}

.hover\:dq-from-purple-200:hover {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
}

.hover\:dq-from-purple-300:hover {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
}

.hover\:dq-from-purple-400:hover {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
}

.hover\:dq-from-purple-500:hover {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
}

.hover\:dq-from-purple-600:hover {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
}

.hover\:dq-from-purple-700:hover {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
}

.hover\:dq-from-purple-800:hover {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
}

.hover\:dq-from-purple-900:hover {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
}

.hover\:dq-from-pink-50:hover {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
}

.hover\:dq-from-pink-100:hover {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
}

.hover\:dq-from-pink-200:hover {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
}

.hover\:dq-from-pink-300:hover {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
}

.hover\:dq-from-pink-400:hover {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
}

.hover\:dq-from-pink-500:hover {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
}

.hover\:dq-from-pink-600:hover {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
}

.hover\:dq-from-pink-700:hover {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
}

.hover\:dq-from-pink-800:hover {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
}

.hover\:dq-from-pink-900:hover {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
}

.focus\:dq-from-transparent:focus {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.focus\:dq-from-current:focus {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:dq-from-black:focus {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.focus\:dq-from-white:focus {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:dq-from-gray-50:focus {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
}

.focus\:dq-from-gray-100:focus {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
}

.focus\:dq-from-gray-200:focus {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
}

.focus\:dq-from-gray-300:focus {
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
}

.focus\:dq-from-gray-400:focus {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
}

.focus\:dq-from-gray-500:focus {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
}

.focus\:dq-from-gray-600:focus {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
}

.focus\:dq-from-gray-700:focus {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
}

.focus\:dq-from-gray-800:focus {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
}

.focus\:dq-from-gray-900:focus {
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
}

.focus\:dq-from-red-50:focus {
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
}

.focus\:dq-from-red-100:focus {
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
}

.focus\:dq-from-red-200:focus {
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
}

.focus\:dq-from-red-300:focus {
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
}

.focus\:dq-from-red-400:focus {
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
}

.focus\:dq-from-red-500:focus {
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
}

.focus\:dq-from-red-600:focus {
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
}

.focus\:dq-from-red-700:focus {
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
}

.focus\:dq-from-red-800:focus {
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
}

.focus\:dq-from-red-900:focus {
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
}

.focus\:dq-from-yellow-50:focus {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
}

.focus\:dq-from-yellow-100:focus {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
}

.focus\:dq-from-yellow-200:focus {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
}

.focus\:dq-from-yellow-300:focus {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
}

.focus\:dq-from-yellow-400:focus {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
}

.focus\:dq-from-yellow-500:focus {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
}

.focus\:dq-from-yellow-600:focus {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
}

.focus\:dq-from-yellow-700:focus {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
}

.focus\:dq-from-yellow-800:focus {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
}

.focus\:dq-from-yellow-900:focus {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
}

.focus\:dq-from-green-50:focus {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
}

.focus\:dq-from-green-100:focus {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
}

.focus\:dq-from-green-200:focus {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
}

.focus\:dq-from-green-300:focus {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
}

.focus\:dq-from-green-400:focus {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
}

.focus\:dq-from-green-500:focus {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
}

.focus\:dq-from-green-600:focus {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
}

.focus\:dq-from-green-700:focus {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
}

.focus\:dq-from-green-800:focus {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
}

.focus\:dq-from-green-900:focus {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
}

.focus\:dq-from-blue-50:focus {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
}

.focus\:dq-from-blue-100:focus {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
}

.focus\:dq-from-blue-200:focus {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
}

.focus\:dq-from-blue-300:focus {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
}

.focus\:dq-from-blue-400:focus {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
}

.focus\:dq-from-blue-500:focus {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
}

.focus\:dq-from-blue-600:focus {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
}

.focus\:dq-from-blue-700:focus {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
}

.focus\:dq-from-blue-800:focus {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
}

.focus\:dq-from-blue-900:focus {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
}

.focus\:dq-from-indigo-50:focus {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
}

.focus\:dq-from-indigo-100:focus {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
}

.focus\:dq-from-indigo-200:focus {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
}

.focus\:dq-from-indigo-300:focus {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
}

.focus\:dq-from-indigo-400:focus {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
}

.focus\:dq-from-indigo-500:focus {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
}

.focus\:dq-from-indigo-600:focus {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
}

.focus\:dq-from-indigo-700:focus {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
}

.focus\:dq-from-indigo-800:focus {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
}

.focus\:dq-from-indigo-900:focus {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
}

.focus\:dq-from-purple-50:focus {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
}

.focus\:dq-from-purple-100:focus {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
}

.focus\:dq-from-purple-200:focus {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
}

.focus\:dq-from-purple-300:focus {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
}

.focus\:dq-from-purple-400:focus {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
}

.focus\:dq-from-purple-500:focus {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
}

.focus\:dq-from-purple-600:focus {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
}

.focus\:dq-from-purple-700:focus {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
}

.focus\:dq-from-purple-800:focus {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
}

.focus\:dq-from-purple-900:focus {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
}

.focus\:dq-from-pink-50:focus {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
}

.focus\:dq-from-pink-100:focus {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
}

.focus\:dq-from-pink-200:focus {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
}

.focus\:dq-from-pink-300:focus {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
}

.focus\:dq-from-pink-400:focus {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
}

.focus\:dq-from-pink-500:focus {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
}

.focus\:dq-from-pink-600:focus {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
}

.focus\:dq-from-pink-700:focus {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
}

.focus\:dq-from-pink-800:focus {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
}

.focus\:dq-from-pink-900:focus {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
}

.dq-via-transparent {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.dq-via-current {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.dq-via-black {
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.dq-via-white {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.dq-via-gray-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
}

.dq-via-gray-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
}

.dq-via-gray-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
}

.dq-via-gray-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
}

.dq-via-gray-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
}

.dq-via-gray-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
}

.dq-via-gray-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
}

.dq-via-gray-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
}

.dq-via-gray-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
}

.dq-via-gray-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
}

.dq-via-red-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
}

.dq-via-red-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
}

.dq-via-red-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
}

.dq-via-red-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
}

.dq-via-red-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
}

.dq-via-red-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
}

.dq-via-red-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
}

.dq-via-red-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
}

.dq-via-red-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
}

.dq-via-red-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
}

.dq-via-yellow-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
}

.dq-via-yellow-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
}

.dq-via-yellow-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
}

.dq-via-yellow-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
}

.dq-via-yellow-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
}

.dq-via-yellow-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
}

.dq-via-yellow-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
}

.dq-via-yellow-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
}

.dq-via-yellow-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
}

.dq-via-yellow-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
}

.dq-via-green-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
}

.dq-via-green-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
}

.dq-via-green-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
}

.dq-via-green-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
}

.dq-via-green-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
}

.dq-via-green-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
}

.dq-via-green-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
}

.dq-via-green-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
}

.dq-via-green-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
}

.dq-via-green-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
}

.dq-via-blue-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
}

.dq-via-blue-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
}

.dq-via-blue-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
}

.dq-via-blue-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
}

.dq-via-blue-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
}

.dq-via-blue-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
}

.dq-via-blue-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
}

.dq-via-blue-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
}

.dq-via-blue-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
}

.dq-via-blue-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
}

.dq-via-indigo-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
}

.dq-via-indigo-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
}

.dq-via-indigo-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
}

.dq-via-indigo-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
}

.dq-via-indigo-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
}

.dq-via-indigo-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
}

.dq-via-indigo-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
}

.dq-via-indigo-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
}

.dq-via-indigo-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
}

.dq-via-indigo-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
}

.dq-via-purple-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
}

.dq-via-purple-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
}

.dq-via-purple-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
}

.dq-via-purple-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
}

.dq-via-purple-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
}

.dq-via-purple-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
}

.dq-via-purple-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
}

.dq-via-purple-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
}

.dq-via-purple-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
}

.dq-via-purple-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
}

.dq-via-pink-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
}

.dq-via-pink-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
}

.dq-via-pink-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
}

.dq-via-pink-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
}

.dq-via-pink-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
}

.dq-via-pink-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
}

.dq-via-pink-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
}

.dq-via-pink-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
}

.dq-via-pink-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
}

.dq-via-pink-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
}

.hover\:dq-via-transparent:hover {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.hover\:dq-via-current:hover {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:dq-via-black:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.hover\:dq-via-white:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:dq-via-gray-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
}

.hover\:dq-via-gray-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
}

.hover\:dq-via-gray-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
}

.hover\:dq-via-gray-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
}

.hover\:dq-via-gray-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
}

.hover\:dq-via-gray-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
}

.hover\:dq-via-gray-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
}

.hover\:dq-via-gray-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
}

.hover\:dq-via-gray-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
}

.hover\:dq-via-gray-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
}

.hover\:dq-via-red-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
}

.hover\:dq-via-red-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
}

.hover\:dq-via-red-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
}

.hover\:dq-via-red-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
}

.hover\:dq-via-red-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
}

.hover\:dq-via-red-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
}

.hover\:dq-via-red-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
}

.hover\:dq-via-red-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
}

.hover\:dq-via-red-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
}

.hover\:dq-via-red-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
}

.hover\:dq-via-yellow-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
}

.hover\:dq-via-yellow-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
}

.hover\:dq-via-yellow-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
}

.hover\:dq-via-yellow-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
}

.hover\:dq-via-yellow-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
}

.hover\:dq-via-yellow-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
}

.hover\:dq-via-yellow-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
}

.hover\:dq-via-yellow-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
}

.hover\:dq-via-yellow-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
}

.hover\:dq-via-yellow-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
}

.hover\:dq-via-green-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
}

.hover\:dq-via-green-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
}

.hover\:dq-via-green-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
}

.hover\:dq-via-green-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
}

.hover\:dq-via-green-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
}

.hover\:dq-via-green-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
}

.hover\:dq-via-green-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
}

.hover\:dq-via-green-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
}

.hover\:dq-via-green-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
}

.hover\:dq-via-green-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
}

.hover\:dq-via-blue-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
}

.hover\:dq-via-blue-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
}

.hover\:dq-via-blue-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
}

.hover\:dq-via-blue-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
}

.hover\:dq-via-blue-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
}

.hover\:dq-via-blue-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
}

.hover\:dq-via-blue-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
}

.hover\:dq-via-blue-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
}

.hover\:dq-via-blue-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
}

.hover\:dq-via-blue-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
}

.hover\:dq-via-indigo-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
}

.hover\:dq-via-indigo-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
}

.hover\:dq-via-indigo-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
}

.hover\:dq-via-indigo-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
}

.hover\:dq-via-indigo-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
}

.hover\:dq-via-indigo-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
}

.hover\:dq-via-indigo-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
}

.hover\:dq-via-indigo-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
}

.hover\:dq-via-indigo-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
}

.hover\:dq-via-indigo-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
}

.hover\:dq-via-purple-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
}

.hover\:dq-via-purple-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
}

.hover\:dq-via-purple-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
}

.hover\:dq-via-purple-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
}

.hover\:dq-via-purple-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
}

.hover\:dq-via-purple-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
}

.hover\:dq-via-purple-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
}

.hover\:dq-via-purple-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
}

.hover\:dq-via-purple-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
}

.hover\:dq-via-purple-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
}

.hover\:dq-via-pink-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
}

.hover\:dq-via-pink-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
}

.hover\:dq-via-pink-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
}

.hover\:dq-via-pink-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
}

.hover\:dq-via-pink-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
}

.hover\:dq-via-pink-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
}

.hover\:dq-via-pink-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
}

.hover\:dq-via-pink-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
}

.hover\:dq-via-pink-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
}

.hover\:dq-via-pink-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
}

.focus\:dq-via-transparent:focus {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.focus\:dq-via-current:focus {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:dq-via-black:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.focus\:dq-via-white:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:dq-via-gray-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
}

.focus\:dq-via-gray-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
}

.focus\:dq-via-gray-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
}

.focus\:dq-via-gray-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
}

.focus\:dq-via-gray-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
}

.focus\:dq-via-gray-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
}

.focus\:dq-via-gray-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
}

.focus\:dq-via-gray-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
}

.focus\:dq-via-gray-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
}

.focus\:dq-via-gray-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
}

.focus\:dq-via-red-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
}

.focus\:dq-via-red-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
}

.focus\:dq-via-red-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
}

.focus\:dq-via-red-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
}

.focus\:dq-via-red-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
}

.focus\:dq-via-red-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
}

.focus\:dq-via-red-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
}

.focus\:dq-via-red-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
}

.focus\:dq-via-red-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
}

.focus\:dq-via-red-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
}

.focus\:dq-via-yellow-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
}

.focus\:dq-via-yellow-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
}

.focus\:dq-via-yellow-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
}

.focus\:dq-via-yellow-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
}

.focus\:dq-via-yellow-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
}

.focus\:dq-via-yellow-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
}

.focus\:dq-via-yellow-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
}

.focus\:dq-via-yellow-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
}

.focus\:dq-via-yellow-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
}

.focus\:dq-via-yellow-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
}

.focus\:dq-via-green-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
}

.focus\:dq-via-green-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
}

.focus\:dq-via-green-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
}

.focus\:dq-via-green-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
}

.focus\:dq-via-green-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
}

.focus\:dq-via-green-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
}

.focus\:dq-via-green-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
}

.focus\:dq-via-green-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
}

.focus\:dq-via-green-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
}

.focus\:dq-via-green-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
}

.focus\:dq-via-blue-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
}

.focus\:dq-via-blue-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
}

.focus\:dq-via-blue-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
}

.focus\:dq-via-blue-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
}

.focus\:dq-via-blue-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
}

.focus\:dq-via-blue-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
}

.focus\:dq-via-blue-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
}

.focus\:dq-via-blue-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
}

.focus\:dq-via-blue-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
}

.focus\:dq-via-blue-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
}

.focus\:dq-via-indigo-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
}

.focus\:dq-via-indigo-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
}

.focus\:dq-via-indigo-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
}

.focus\:dq-via-indigo-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
}

.focus\:dq-via-indigo-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
}

.focus\:dq-via-indigo-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
}

.focus\:dq-via-indigo-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
}

.focus\:dq-via-indigo-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
}

.focus\:dq-via-indigo-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
}

.focus\:dq-via-indigo-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
}

.focus\:dq-via-purple-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
}

.focus\:dq-via-purple-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
}

.focus\:dq-via-purple-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
}

.focus\:dq-via-purple-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
}

.focus\:dq-via-purple-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
}

.focus\:dq-via-purple-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
}

.focus\:dq-via-purple-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
}

.focus\:dq-via-purple-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
}

.focus\:dq-via-purple-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
}

.focus\:dq-via-purple-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
}

.focus\:dq-via-pink-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
}

.focus\:dq-via-pink-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
}

.focus\:dq-via-pink-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
}

.focus\:dq-via-pink-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
}

.focus\:dq-via-pink-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
}

.focus\:dq-via-pink-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
}

.focus\:dq-via-pink-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
}

.focus\:dq-via-pink-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
}

.focus\:dq-via-pink-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
}

.focus\:dq-via-pink-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
}

.dq-to-transparent {
  --tw-gradient-to: transparent;
}

.dq-to-current {
  --tw-gradient-to: currentColor;
}

.dq-to-black {
  --tw-gradient-to: #000;
}

.dq-to-white {
  --tw-gradient-to: #fff;
}

.dq-to-gray-50 {
  --tw-gradient-to: #f9fafb;
}

.dq-to-gray-100 {
  --tw-gradient-to: #f3f4f6;
}

.dq-to-gray-200 {
  --tw-gradient-to: #e5e7eb;
}

.dq-to-gray-300 {
  --tw-gradient-to: #d1d5db;
}

.dq-to-gray-400 {
  --tw-gradient-to: #9ca3af;
}

.dq-to-gray-500 {
  --tw-gradient-to: #6b7280;
}

.dq-to-gray-600 {
  --tw-gradient-to: #4b5563;
}

.dq-to-gray-700 {
  --tw-gradient-to: #374151;
}

.dq-to-gray-800 {
  --tw-gradient-to: #1f2937;
}

.dq-to-gray-900 {
  --tw-gradient-to: #111827;
}

.dq-to-red-50 {
  --tw-gradient-to: #fef2f2;
}

.dq-to-red-100 {
  --tw-gradient-to: #fee2e2;
}

.dq-to-red-200 {
  --tw-gradient-to: #fecaca;
}

.dq-to-red-300 {
  --tw-gradient-to: #fca5a5;
}

.dq-to-red-400 {
  --tw-gradient-to: #f87171;
}

.dq-to-red-500 {
  --tw-gradient-to: #ef4444;
}

.dq-to-red-600 {
  --tw-gradient-to: #dc2626;
}

.dq-to-red-700 {
  --tw-gradient-to: #b91c1c;
}

.dq-to-red-800 {
  --tw-gradient-to: #991b1b;
}

.dq-to-red-900 {
  --tw-gradient-to: #7f1d1d;
}

.dq-to-yellow-50 {
  --tw-gradient-to: #fffbeb;
}

.dq-to-yellow-100 {
  --tw-gradient-to: #fef3c7;
}

.dq-to-yellow-200 {
  --tw-gradient-to: #fde68a;
}

.dq-to-yellow-300 {
  --tw-gradient-to: #fcd34d;
}

.dq-to-yellow-400 {
  --tw-gradient-to: #fbbf24;
}

.dq-to-yellow-500 {
  --tw-gradient-to: #f59e0b;
}

.dq-to-yellow-600 {
  --tw-gradient-to: #d97706;
}

.dq-to-yellow-700 {
  --tw-gradient-to: #b45309;
}

.dq-to-yellow-800 {
  --tw-gradient-to: #92400e;
}

.dq-to-yellow-900 {
  --tw-gradient-to: #78350f;
}

.dq-to-green-50 {
  --tw-gradient-to: #ecfdf5;
}

.dq-to-green-100 {
  --tw-gradient-to: #d1fae5;
}

.dq-to-green-200 {
  --tw-gradient-to: #a7f3d0;
}

.dq-to-green-300 {
  --tw-gradient-to: #6ee7b7;
}

.dq-to-green-400 {
  --tw-gradient-to: #34d399;
}

.dq-to-green-500 {
  --tw-gradient-to: #10b981;
}

.dq-to-green-600 {
  --tw-gradient-to: #059669;
}

.dq-to-green-700 {
  --tw-gradient-to: #047857;
}

.dq-to-green-800 {
  --tw-gradient-to: #065f46;
}

.dq-to-green-900 {
  --tw-gradient-to: #064e3b;
}

.dq-to-blue-50 {
  --tw-gradient-to: #eff6ff;
}

.dq-to-blue-100 {
  --tw-gradient-to: #dbeafe;
}

.dq-to-blue-200 {
  --tw-gradient-to: #bfdbfe;
}

.dq-to-blue-300 {
  --tw-gradient-to: #93c5fd;
}

.dq-to-blue-400 {
  --tw-gradient-to: #60a5fa;
}

.dq-to-blue-500 {
  --tw-gradient-to: #3b82f6;
}

.dq-to-blue-600 {
  --tw-gradient-to: #2563eb;
}

.dq-to-blue-700 {
  --tw-gradient-to: #1d4ed8;
}

.dq-to-blue-800 {
  --tw-gradient-to: #1e40af;
}

.dq-to-blue-900 {
  --tw-gradient-to: #1e3a8a;
}

.dq-to-indigo-50 {
  --tw-gradient-to: #eef2ff;
}

.dq-to-indigo-100 {
  --tw-gradient-to: #e0e7ff;
}

.dq-to-indigo-200 {
  --tw-gradient-to: #c7d2fe;
}

.dq-to-indigo-300 {
  --tw-gradient-to: #a5b4fc;
}

.dq-to-indigo-400 {
  --tw-gradient-to: #818cf8;
}

.dq-to-indigo-500 {
  --tw-gradient-to: #6366f1;
}

.dq-to-indigo-600 {
  --tw-gradient-to: #4f46e5;
}

.dq-to-indigo-700 {
  --tw-gradient-to: #4338ca;
}

.dq-to-indigo-800 {
  --tw-gradient-to: #3730a3;
}

.dq-to-indigo-900 {
  --tw-gradient-to: #312e81;
}

.dq-to-purple-50 {
  --tw-gradient-to: #f5f3ff;
}

.dq-to-purple-100 {
  --tw-gradient-to: #ede9fe;
}

.dq-to-purple-200 {
  --tw-gradient-to: #ddd6fe;
}

.dq-to-purple-300 {
  --tw-gradient-to: #c4b5fd;
}

.dq-to-purple-400 {
  --tw-gradient-to: #a78bfa;
}

.dq-to-purple-500 {
  --tw-gradient-to: #8b5cf6;
}

.dq-to-purple-600 {
  --tw-gradient-to: #7c3aed;
}

.dq-to-purple-700 {
  --tw-gradient-to: #6d28d9;
}

.dq-to-purple-800 {
  --tw-gradient-to: #5b21b6;
}

.dq-to-purple-900 {
  --tw-gradient-to: #4c1d95;
}

.dq-to-pink-50 {
  --tw-gradient-to: #fdf2f8;
}

.dq-to-pink-100 {
  --tw-gradient-to: #fce7f3;
}

.dq-to-pink-200 {
  --tw-gradient-to: #fbcfe8;
}

.dq-to-pink-300 {
  --tw-gradient-to: #f9a8d4;
}

.dq-to-pink-400 {
  --tw-gradient-to: #f472b6;
}

.dq-to-pink-500 {
  --tw-gradient-to: #ec4899;
}

.dq-to-pink-600 {
  --tw-gradient-to: #db2777;
}

.dq-to-pink-700 {
  --tw-gradient-to: #be185d;
}

.dq-to-pink-800 {
  --tw-gradient-to: #9d174d;
}

.dq-to-pink-900 {
  --tw-gradient-to: #831843;
}

.hover\:dq-to-transparent:hover {
  --tw-gradient-to: transparent;
}

.hover\:dq-to-current:hover {
  --tw-gradient-to: currentColor;
}

.hover\:dq-to-black:hover {
  --tw-gradient-to: #000;
}

.hover\:dq-to-white:hover {
  --tw-gradient-to: #fff;
}

.hover\:dq-to-gray-50:hover {
  --tw-gradient-to: #f9fafb;
}

.hover\:dq-to-gray-100:hover {
  --tw-gradient-to: #f3f4f6;
}

.hover\:dq-to-gray-200:hover {
  --tw-gradient-to: #e5e7eb;
}

.hover\:dq-to-gray-300:hover {
  --tw-gradient-to: #d1d5db;
}

.hover\:dq-to-gray-400:hover {
  --tw-gradient-to: #9ca3af;
}

.hover\:dq-to-gray-500:hover {
  --tw-gradient-to: #6b7280;
}

.hover\:dq-to-gray-600:hover {
  --tw-gradient-to: #4b5563;
}

.hover\:dq-to-gray-700:hover {
  --tw-gradient-to: #374151;
}

.hover\:dq-to-gray-800:hover {
  --tw-gradient-to: #1f2937;
}

.hover\:dq-to-gray-900:hover {
  --tw-gradient-to: #111827;
}

.hover\:dq-to-red-50:hover {
  --tw-gradient-to: #fef2f2;
}

.hover\:dq-to-red-100:hover {
  --tw-gradient-to: #fee2e2;
}

.hover\:dq-to-red-200:hover {
  --tw-gradient-to: #fecaca;
}

.hover\:dq-to-red-300:hover {
  --tw-gradient-to: #fca5a5;
}

.hover\:dq-to-red-400:hover {
  --tw-gradient-to: #f87171;
}

.hover\:dq-to-red-500:hover {
  --tw-gradient-to: #ef4444;
}

.hover\:dq-to-red-600:hover {
  --tw-gradient-to: #dc2626;
}

.hover\:dq-to-red-700:hover {
  --tw-gradient-to: #b91c1c;
}

.hover\:dq-to-red-800:hover {
  --tw-gradient-to: #991b1b;
}

.hover\:dq-to-red-900:hover {
  --tw-gradient-to: #7f1d1d;
}

.hover\:dq-to-yellow-50:hover {
  --tw-gradient-to: #fffbeb;
}

.hover\:dq-to-yellow-100:hover {
  --tw-gradient-to: #fef3c7;
}

.hover\:dq-to-yellow-200:hover {
  --tw-gradient-to: #fde68a;
}

.hover\:dq-to-yellow-300:hover {
  --tw-gradient-to: #fcd34d;
}

.hover\:dq-to-yellow-400:hover {
  --tw-gradient-to: #fbbf24;
}

.hover\:dq-to-yellow-500:hover {
  --tw-gradient-to: #f59e0b;
}

.hover\:dq-to-yellow-600:hover {
  --tw-gradient-to: #d97706;
}

.hover\:dq-to-yellow-700:hover {
  --tw-gradient-to: #b45309;
}

.hover\:dq-to-yellow-800:hover {
  --tw-gradient-to: #92400e;
}

.hover\:dq-to-yellow-900:hover {
  --tw-gradient-to: #78350f;
}

.hover\:dq-to-green-50:hover {
  --tw-gradient-to: #ecfdf5;
}

.hover\:dq-to-green-100:hover {
  --tw-gradient-to: #d1fae5;
}

.hover\:dq-to-green-200:hover {
  --tw-gradient-to: #a7f3d0;
}

.hover\:dq-to-green-300:hover {
  --tw-gradient-to: #6ee7b7;
}

.hover\:dq-to-green-400:hover {
  --tw-gradient-to: #34d399;
}

.hover\:dq-to-green-500:hover {
  --tw-gradient-to: #10b981;
}

.hover\:dq-to-green-600:hover {
  --tw-gradient-to: #059669;
}

.hover\:dq-to-green-700:hover {
  --tw-gradient-to: #047857;
}

.hover\:dq-to-green-800:hover {
  --tw-gradient-to: #065f46;
}

.hover\:dq-to-green-900:hover {
  --tw-gradient-to: #064e3b;
}

.hover\:dq-to-blue-50:hover {
  --tw-gradient-to: #eff6ff;
}

.hover\:dq-to-blue-100:hover {
  --tw-gradient-to: #dbeafe;
}

.hover\:dq-to-blue-200:hover {
  --tw-gradient-to: #bfdbfe;
}

.hover\:dq-to-blue-300:hover {
  --tw-gradient-to: #93c5fd;
}

.hover\:dq-to-blue-400:hover {
  --tw-gradient-to: #60a5fa;
}

.hover\:dq-to-blue-500:hover {
  --tw-gradient-to: #3b82f6;
}

.hover\:dq-to-blue-600:hover {
  --tw-gradient-to: #2563eb;
}

.hover\:dq-to-blue-700:hover {
  --tw-gradient-to: #1d4ed8;
}

.hover\:dq-to-blue-800:hover {
  --tw-gradient-to: #1e40af;
}

.hover\:dq-to-blue-900:hover {
  --tw-gradient-to: #1e3a8a;
}

.hover\:dq-to-indigo-50:hover {
  --tw-gradient-to: #eef2ff;
}

.hover\:dq-to-indigo-100:hover {
  --tw-gradient-to: #e0e7ff;
}

.hover\:dq-to-indigo-200:hover {
  --tw-gradient-to: #c7d2fe;
}

.hover\:dq-to-indigo-300:hover {
  --tw-gradient-to: #a5b4fc;
}

.hover\:dq-to-indigo-400:hover {
  --tw-gradient-to: #818cf8;
}

.hover\:dq-to-indigo-500:hover {
  --tw-gradient-to: #6366f1;
}

.hover\:dq-to-indigo-600:hover {
  --tw-gradient-to: #4f46e5;
}

.hover\:dq-to-indigo-700:hover {
  --tw-gradient-to: #4338ca;
}

.hover\:dq-to-indigo-800:hover {
  --tw-gradient-to: #3730a3;
}

.hover\:dq-to-indigo-900:hover {
  --tw-gradient-to: #312e81;
}

.hover\:dq-to-purple-50:hover {
  --tw-gradient-to: #f5f3ff;
}

.hover\:dq-to-purple-100:hover {
  --tw-gradient-to: #ede9fe;
}

.hover\:dq-to-purple-200:hover {
  --tw-gradient-to: #ddd6fe;
}

.hover\:dq-to-purple-300:hover {
  --tw-gradient-to: #c4b5fd;
}

.hover\:dq-to-purple-400:hover {
  --tw-gradient-to: #a78bfa;
}

.hover\:dq-to-purple-500:hover {
  --tw-gradient-to: #8b5cf6;
}

.hover\:dq-to-purple-600:hover {
  --tw-gradient-to: #7c3aed;
}

.hover\:dq-to-purple-700:hover {
  --tw-gradient-to: #6d28d9;
}

.hover\:dq-to-purple-800:hover {
  --tw-gradient-to: #5b21b6;
}

.hover\:dq-to-purple-900:hover {
  --tw-gradient-to: #4c1d95;
}

.hover\:dq-to-pink-50:hover {
  --tw-gradient-to: #fdf2f8;
}

.hover\:dq-to-pink-100:hover {
  --tw-gradient-to: #fce7f3;
}

.hover\:dq-to-pink-200:hover {
  --tw-gradient-to: #fbcfe8;
}

.hover\:dq-to-pink-300:hover {
  --tw-gradient-to: #f9a8d4;
}

.hover\:dq-to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:dq-to-pink-500:hover {
  --tw-gradient-to: #ec4899;
}

.hover\:dq-to-pink-600:hover {
  --tw-gradient-to: #db2777;
}

.hover\:dq-to-pink-700:hover {
  --tw-gradient-to: #be185d;
}

.hover\:dq-to-pink-800:hover {
  --tw-gradient-to: #9d174d;
}

.hover\:dq-to-pink-900:hover {
  --tw-gradient-to: #831843;
}

.focus\:dq-to-transparent:focus {
  --tw-gradient-to: transparent;
}

.focus\:dq-to-current:focus {
  --tw-gradient-to: currentColor;
}

.focus\:dq-to-black:focus {
  --tw-gradient-to: #000;
}

.focus\:dq-to-white:focus {
  --tw-gradient-to: #fff;
}

.focus\:dq-to-gray-50:focus {
  --tw-gradient-to: #f9fafb;
}

.focus\:dq-to-gray-100:focus {
  --tw-gradient-to: #f3f4f6;
}

.focus\:dq-to-gray-200:focus {
  --tw-gradient-to: #e5e7eb;
}

.focus\:dq-to-gray-300:focus {
  --tw-gradient-to: #d1d5db;
}

.focus\:dq-to-gray-400:focus {
  --tw-gradient-to: #9ca3af;
}

.focus\:dq-to-gray-500:focus {
  --tw-gradient-to: #6b7280;
}

.focus\:dq-to-gray-600:focus {
  --tw-gradient-to: #4b5563;
}

.focus\:dq-to-gray-700:focus {
  --tw-gradient-to: #374151;
}

.focus\:dq-to-gray-800:focus {
  --tw-gradient-to: #1f2937;
}

.focus\:dq-to-gray-900:focus {
  --tw-gradient-to: #111827;
}

.focus\:dq-to-red-50:focus {
  --tw-gradient-to: #fef2f2;
}

.focus\:dq-to-red-100:focus {
  --tw-gradient-to: #fee2e2;
}

.focus\:dq-to-red-200:focus {
  --tw-gradient-to: #fecaca;
}

.focus\:dq-to-red-300:focus {
  --tw-gradient-to: #fca5a5;
}

.focus\:dq-to-red-400:focus {
  --tw-gradient-to: #f87171;
}

.focus\:dq-to-red-500:focus {
  --tw-gradient-to: #ef4444;
}

.focus\:dq-to-red-600:focus {
  --tw-gradient-to: #dc2626;
}

.focus\:dq-to-red-700:focus {
  --tw-gradient-to: #b91c1c;
}

.focus\:dq-to-red-800:focus {
  --tw-gradient-to: #991b1b;
}

.focus\:dq-to-red-900:focus {
  --tw-gradient-to: #7f1d1d;
}

.focus\:dq-to-yellow-50:focus {
  --tw-gradient-to: #fffbeb;
}

.focus\:dq-to-yellow-100:focus {
  --tw-gradient-to: #fef3c7;
}

.focus\:dq-to-yellow-200:focus {
  --tw-gradient-to: #fde68a;
}

.focus\:dq-to-yellow-300:focus {
  --tw-gradient-to: #fcd34d;
}

.focus\:dq-to-yellow-400:focus {
  --tw-gradient-to: #fbbf24;
}

.focus\:dq-to-yellow-500:focus {
  --tw-gradient-to: #f59e0b;
}

.focus\:dq-to-yellow-600:focus {
  --tw-gradient-to: #d97706;
}

.focus\:dq-to-yellow-700:focus {
  --tw-gradient-to: #b45309;
}

.focus\:dq-to-yellow-800:focus {
  --tw-gradient-to: #92400e;
}

.focus\:dq-to-yellow-900:focus {
  --tw-gradient-to: #78350f;
}

.focus\:dq-to-green-50:focus {
  --tw-gradient-to: #ecfdf5;
}

.focus\:dq-to-green-100:focus {
  --tw-gradient-to: #d1fae5;
}

.focus\:dq-to-green-200:focus {
  --tw-gradient-to: #a7f3d0;
}

.focus\:dq-to-green-300:focus {
  --tw-gradient-to: #6ee7b7;
}

.focus\:dq-to-green-400:focus {
  --tw-gradient-to: #34d399;
}

.focus\:dq-to-green-500:focus {
  --tw-gradient-to: #10b981;
}

.focus\:dq-to-green-600:focus {
  --tw-gradient-to: #059669;
}

.focus\:dq-to-green-700:focus {
  --tw-gradient-to: #047857;
}

.focus\:dq-to-green-800:focus {
  --tw-gradient-to: #065f46;
}

.focus\:dq-to-green-900:focus {
  --tw-gradient-to: #064e3b;
}

.focus\:dq-to-blue-50:focus {
  --tw-gradient-to: #eff6ff;
}

.focus\:dq-to-blue-100:focus {
  --tw-gradient-to: #dbeafe;
}

.focus\:dq-to-blue-200:focus {
  --tw-gradient-to: #bfdbfe;
}

.focus\:dq-to-blue-300:focus {
  --tw-gradient-to: #93c5fd;
}

.focus\:dq-to-blue-400:focus {
  --tw-gradient-to: #60a5fa;
}

.focus\:dq-to-blue-500:focus {
  --tw-gradient-to: #3b82f6;
}

.focus\:dq-to-blue-600:focus {
  --tw-gradient-to: #2563eb;
}

.focus\:dq-to-blue-700:focus {
  --tw-gradient-to: #1d4ed8;
}

.focus\:dq-to-blue-800:focus {
  --tw-gradient-to: #1e40af;
}

.focus\:dq-to-blue-900:focus {
  --tw-gradient-to: #1e3a8a;
}

.focus\:dq-to-indigo-50:focus {
  --tw-gradient-to: #eef2ff;
}

.focus\:dq-to-indigo-100:focus {
  --tw-gradient-to: #e0e7ff;
}

.focus\:dq-to-indigo-200:focus {
  --tw-gradient-to: #c7d2fe;
}

.focus\:dq-to-indigo-300:focus {
  --tw-gradient-to: #a5b4fc;
}

.focus\:dq-to-indigo-400:focus {
  --tw-gradient-to: #818cf8;
}

.focus\:dq-to-indigo-500:focus {
  --tw-gradient-to: #6366f1;
}

.focus\:dq-to-indigo-600:focus {
  --tw-gradient-to: #4f46e5;
}

.focus\:dq-to-indigo-700:focus {
  --tw-gradient-to: #4338ca;
}

.focus\:dq-to-indigo-800:focus {
  --tw-gradient-to: #3730a3;
}

.focus\:dq-to-indigo-900:focus {
  --tw-gradient-to: #312e81;
}

.focus\:dq-to-purple-50:focus {
  --tw-gradient-to: #f5f3ff;
}

.focus\:dq-to-purple-100:focus {
  --tw-gradient-to: #ede9fe;
}

.focus\:dq-to-purple-200:focus {
  --tw-gradient-to: #ddd6fe;
}

.focus\:dq-to-purple-300:focus {
  --tw-gradient-to: #c4b5fd;
}

.focus\:dq-to-purple-400:focus {
  --tw-gradient-to: #a78bfa;
}

.focus\:dq-to-purple-500:focus {
  --tw-gradient-to: #8b5cf6;
}

.focus\:dq-to-purple-600:focus {
  --tw-gradient-to: #7c3aed;
}

.focus\:dq-to-purple-700:focus {
  --tw-gradient-to: #6d28d9;
}

.focus\:dq-to-purple-800:focus {
  --tw-gradient-to: #5b21b6;
}

.focus\:dq-to-purple-900:focus {
  --tw-gradient-to: #4c1d95;
}

.focus\:dq-to-pink-50:focus {
  --tw-gradient-to: #fdf2f8;
}

.focus\:dq-to-pink-100:focus {
  --tw-gradient-to: #fce7f3;
}

.focus\:dq-to-pink-200:focus {
  --tw-gradient-to: #fbcfe8;
}

.focus\:dq-to-pink-300:focus {
  --tw-gradient-to: #f9a8d4;
}

.focus\:dq-to-pink-400:focus {
  --tw-gradient-to: #f472b6;
}

.focus\:dq-to-pink-500:focus {
  --tw-gradient-to: #ec4899;
}

.focus\:dq-to-pink-600:focus {
  --tw-gradient-to: #db2777;
}

.focus\:dq-to-pink-700:focus {
  --tw-gradient-to: #be185d;
}

.focus\:dq-to-pink-800:focus {
  --tw-gradient-to: #9d174d;
}

.focus\:dq-to-pink-900:focus {
  --tw-gradient-to: #831843;
}

.dq-decoration-slice {
  -webkit-box-decoration-break: slice;
          box-decoration-break: slice;
}

.dq-decoration-clone {
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
}

.dq-bg-auto {
  background-size: auto;
}

.dq-bg-cover {
  background-size: cover;
}

.dq-bg-contain {
  background-size: contain;
}

.dq-bg-fixed {
  background-attachment: fixed;
}

.dq-bg-local {
  background-attachment: local;
}

.dq-bg-scroll {
  background-attachment: scroll;
}

.dq-bg-clip-border {
  background-clip: border-box;
}

.dq-bg-clip-padding {
  background-clip: padding-box;
}

.dq-bg-clip-content {
  background-clip: content-box;
}

.dq-bg-clip-text {
  -webkit-background-clip: text;
          background-clip: text;
}

.dq-bg-bottom {
  background-position: bottom;
}

.dq-bg-center {
  background-position: center;
}

.dq-bg-left {
  background-position: left;
}

.dq-bg-left-bottom {
  background-position: left bottom;
}

.dq-bg-left-top {
  background-position: left top;
}

.dq-bg-right {
  background-position: right;
}

.dq-bg-right-bottom {
  background-position: right bottom;
}

.dq-bg-right-top {
  background-position: right top;
}

.dq-bg-top {
  background-position: top;
}

.dq-bg-repeat {
  background-repeat: repeat;
}

.dq-bg-no-repeat {
  background-repeat: no-repeat;
}

.dq-bg-repeat-x {
  background-repeat: repeat-x;
}

.dq-bg-repeat-y {
  background-repeat: repeat-y;
}

.dq-bg-repeat-round {
  background-repeat: round;
}

.dq-bg-repeat-space {
  background-repeat: space;
}

.dq-bg-origin-border {
  background-origin: border-box;
}

.dq-bg-origin-padding {
  background-origin: padding-box;
}

.dq-bg-origin-content {
  background-origin: content-box;
}

.dq-fill-current {
  fill: currentColor;
}

.dq-stroke-current {
  stroke: currentColor;
}

.dq-stroke-0 {
  stroke-width: 0;
}

.dq-stroke-1 {
  stroke-width: 1;
}

.dq-stroke-2 {
  stroke-width: 2;
}

.dq-object-contain {
  object-fit: contain;
}

.dq-object-cover {
  object-fit: cover;
}

.dq-object-fill {
  object-fit: fill;
}

.dq-object-none {
  object-fit: none;
}

.dq-object-scale-down {
  object-fit: scale-down;
}

.dq-object-bottom {
  object-position: bottom;
}

.dq-object-center {
  object-position: center;
}

.dq-object-left {
  object-position: left;
}

.dq-object-left-bottom {
  object-position: left bottom;
}

.dq-object-left-top {
  object-position: left top;
}

.dq-object-right {
  object-position: right;
}

.dq-object-right-bottom {
  object-position: right bottom;
}

.dq-object-right-top {
  object-position: right top;
}

.dq-object-top {
  object-position: top;
}

.dq-p-0 {
  padding: 0px;
}

.dq-p-1 {
  padding: 0.25rem;
}

.dq-p-2 {
  padding: 0.5rem;
}

.dq-p-3 {
  padding: 0.75rem;
}

.dq-p-4 {
  padding: 1rem;
}

.dq-p-5 {
  padding: 1.25rem;
}

.dq-p-6 {
  padding: 1.5rem;
}

.dq-p-7 {
  padding: 1.75rem;
}

.dq-p-8 {
  padding: 2rem;
}

.dq-p-9 {
  padding: 2.25rem;
}

.dq-p-10 {
  padding: 2.5rem;
}

.dq-p-11 {
  padding: 2.75rem;
}

.dq-p-12 {
  padding: 3rem;
}

.dq-p-14 {
  padding: 3.5rem;
}

.dq-p-16 {
  padding: 4rem;
}

.dq-p-20 {
  padding: 5rem;
}

.dq-p-24 {
  padding: 6rem;
}

.dq-p-28 {
  padding: 7rem;
}

.dq-p-32 {
  padding: 8rem;
}

.dq-p-36 {
  padding: 9rem;
}

.dq-p-40 {
  padding: 10rem;
}

.dq-p-44 {
  padding: 11rem;
}

.dq-p-48 {
  padding: 12rem;
}

.dq-p-52 {
  padding: 13rem;
}

.dq-p-56 {
  padding: 14rem;
}

.dq-p-60 {
  padding: 15rem;
}

.dq-p-64 {
  padding: 16rem;
}

.dq-p-72 {
  padding: 18rem;
}

.dq-p-80 {
  padding: 20rem;
}

.dq-p-96 {
  padding: 24rem;
}

.dq-p-px {
  padding: 1px;
}

.dq-p-0\.5 {
  padding: 0.125rem;
}

.dq-p-1\.5 {
  padding: 0.375rem;
}

.dq-p-2\.5 {
  padding: 0.625rem;
}

.dq-p-3\.5 {
  padding: 0.875rem;
}

.dq-px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.dq-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.dq-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.dq-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.dq-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.dq-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.dq-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.dq-px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.dq-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.dq-px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.dq-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.dq-px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.dq-px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.dq-px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.dq-px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.dq-px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.dq-px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.dq-px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.dq-px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.dq-px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.dq-px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.dq-px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.dq-px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.dq-px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.dq-px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.dq-px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.dq-px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.dq-px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.dq-px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.dq-px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.dq-px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.dq-px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.dq-px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.dq-px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.dq-px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.dq-py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dq-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.dq-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dq-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.dq-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.dq-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.dq-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.dq-py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.dq-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.dq-py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.dq-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.dq-py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.dq-py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.dq-py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.dq-py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.dq-py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.dq-py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.dq-py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.dq-py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.dq-py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.dq-py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.dq-py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.dq-py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.dq-py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.dq-py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.dq-py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.dq-py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.dq-py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.dq-py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.dq-py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

.dq-py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.dq-py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.dq-py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.dq-py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.dq-py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.dq-pt-0 {
  padding-top: 0px;
}

.dq-pt-1 {
  padding-top: 0.25rem;
}

.dq-pt-2 {
  padding-top: 0.5rem;
}

.dq-pt-3 {
  padding-top: 0.75rem;
}

.dq-pt-4 {
  padding-top: 1rem;
}

.dq-pt-5 {
  padding-top: 1.25rem;
}

.dq-pt-6 {
  padding-top: 1.5rem;
}

.dq-pt-7 {
  padding-top: 1.75rem;
}

.dq-pt-8 {
  padding-top: 2rem;
}

.dq-pt-9 {
  padding-top: 2.25rem;
}

.dq-pt-10 {
  padding-top: 2.5rem;
}

.dq-pt-11 {
  padding-top: 2.75rem;
}

.dq-pt-12 {
  padding-top: 3rem;
}

.dq-pt-14 {
  padding-top: 3.5rem;
}

.dq-pt-16 {
  padding-top: 4rem;
}

.dq-pt-20 {
  padding-top: 5rem;
}

.dq-pt-24 {
  padding-top: 6rem;
}

.dq-pt-28 {
  padding-top: 7rem;
}

.dq-pt-32 {
  padding-top: 8rem;
}

.dq-pt-36 {
  padding-top: 9rem;
}

.dq-pt-40 {
  padding-top: 10rem;
}

.dq-pt-44 {
  padding-top: 11rem;
}

.dq-pt-48 {
  padding-top: 12rem;
}

.dq-pt-52 {
  padding-top: 13rem;
}

.dq-pt-56 {
  padding-top: 14rem;
}

.dq-pt-60 {
  padding-top: 15rem;
}

.dq-pt-64 {
  padding-top: 16rem;
}

.dq-pt-72 {
  padding-top: 18rem;
}

.dq-pt-80 {
  padding-top: 20rem;
}

.dq-pt-96 {
  padding-top: 24rem;
}

.dq-pt-px {
  padding-top: 1px;
}

.dq-pt-0\.5 {
  padding-top: 0.125rem;
}

.dq-pt-1\.5 {
  padding-top: 0.375rem;
}

.dq-pt-2\.5 {
  padding-top: 0.625rem;
}

.dq-pt-3\.5 {
  padding-top: 0.875rem;
}

.dq-pr-0 {
  padding-right: 0px;
}

.dq-pr-1 {
  padding-right: 0.25rem;
}

.dq-pr-2 {
  padding-right: 0.5rem;
}

.dq-pr-3 {
  padding-right: 0.75rem;
}

.dq-pr-4 {
  padding-right: 1rem;
}

.dq-pr-5 {
  padding-right: 1.25rem;
}

.dq-pr-6 {
  padding-right: 1.5rem;
}

.dq-pr-7 {
  padding-right: 1.75rem;
}

.dq-pr-8 {
  padding-right: 2rem;
}

.dq-pr-9 {
  padding-right: 2.25rem;
}

.dq-pr-10 {
  padding-right: 2.5rem;
}

.dq-pr-11 {
  padding-right: 2.75rem;
}

.dq-pr-12 {
  padding-right: 3rem;
}

.dq-pr-14 {
  padding-right: 3.5rem;
}

.dq-pr-16 {
  padding-right: 4rem;
}

.dq-pr-20 {
  padding-right: 5rem;
}

.dq-pr-24 {
  padding-right: 6rem;
}

.dq-pr-28 {
  padding-right: 7rem;
}

.dq-pr-32 {
  padding-right: 8rem;
}

.dq-pr-36 {
  padding-right: 9rem;
}

.dq-pr-40 {
  padding-right: 10rem;
}

.dq-pr-44 {
  padding-right: 11rem;
}

.dq-pr-48 {
  padding-right: 12rem;
}

.dq-pr-52 {
  padding-right: 13rem;
}

.dq-pr-56 {
  padding-right: 14rem;
}

.dq-pr-60 {
  padding-right: 15rem;
}

.dq-pr-64 {
  padding-right: 16rem;
}

.dq-pr-72 {
  padding-right: 18rem;
}

.dq-pr-80 {
  padding-right: 20rem;
}

.dq-pr-96 {
  padding-right: 24rem;
}

.dq-pr-px {
  padding-right: 1px;
}

.dq-pr-0\.5 {
  padding-right: 0.125rem;
}

.dq-pr-1\.5 {
  padding-right: 0.375rem;
}

.dq-pr-2\.5 {
  padding-right: 0.625rem;
}

.dq-pr-3\.5 {
  padding-right: 0.875rem;
}

.dq-pb-0 {
  padding-bottom: 0px;
}

.dq-pb-1 {
  padding-bottom: 0.25rem;
}

.dq-pb-2 {
  padding-bottom: 0.5rem;
}

.dq-pb-3 {
  padding-bottom: 0.75rem;
}

.dq-pb-4 {
  padding-bottom: 1rem;
}

.dq-pb-5 {
  padding-bottom: 1.25rem;
}

.dq-pb-6 {
  padding-bottom: 1.5rem;
}

.dq-pb-7 {
  padding-bottom: 1.75rem;
}

.dq-pb-8 {
  padding-bottom: 2rem;
}

.dq-pb-9 {
  padding-bottom: 2.25rem;
}

.dq-pb-10 {
  padding-bottom: 2.5rem;
}

.dq-pb-11 {
  padding-bottom: 2.75rem;
}

.dq-pb-12 {
  padding-bottom: 3rem;
}

.dq-pb-14 {
  padding-bottom: 3.5rem;
}

.dq-pb-16 {
  padding-bottom: 4rem;
}

.dq-pb-20 {
  padding-bottom: 5rem;
}

.dq-pb-24 {
  padding-bottom: 6rem;
}

.dq-pb-28 {
  padding-bottom: 7rem;
}

.dq-pb-32 {
  padding-bottom: 8rem;
}

.dq-pb-36 {
  padding-bottom: 9rem;
}

.dq-pb-40 {
  padding-bottom: 10rem;
}

.dq-pb-44 {
  padding-bottom: 11rem;
}

.dq-pb-48 {
  padding-bottom: 12rem;
}

.dq-pb-52 {
  padding-bottom: 13rem;
}

.dq-pb-56 {
  padding-bottom: 14rem;
}

.dq-pb-60 {
  padding-bottom: 15rem;
}

.dq-pb-64 {
  padding-bottom: 16rem;
}

.dq-pb-72 {
  padding-bottom: 18rem;
}

.dq-pb-80 {
  padding-bottom: 20rem;
}

.dq-pb-96 {
  padding-bottom: 24rem;
}

.dq-pb-px {
  padding-bottom: 1px;
}

.dq-pb-0\.5 {
  padding-bottom: 0.125rem;
}

.dq-pb-1\.5 {
  padding-bottom: 0.375rem;
}

.dq-pb-2\.5 {
  padding-bottom: 0.625rem;
}

.dq-pb-3\.5 {
  padding-bottom: 0.875rem;
}

.dq-pl-0 {
  padding-left: 0px;
}

.dq-pl-1 {
  padding-left: 0.25rem;
}

.dq-pl-2 {
  padding-left: 0.5rem;
}

.dq-pl-3 {
  padding-left: 0.75rem;
}

.dq-pl-4 {
  padding-left: 1rem;
}

.dq-pl-5 {
  padding-left: 1.25rem;
}

.dq-pl-6 {
  padding-left: 1.5rem;
}

.dq-pl-7 {
  padding-left: 1.75rem;
}

.dq-pl-8 {
  padding-left: 2rem;
}

.dq-pl-9 {
  padding-left: 2.25rem;
}

.dq-pl-10 {
  padding-left: 2.5rem;
}

.dq-pl-11 {
  padding-left: 2.75rem;
}

.dq-pl-12 {
  padding-left: 3rem;
}

.dq-pl-14 {
  padding-left: 3.5rem;
}

.dq-pl-16 {
  padding-left: 4rem;
}

.dq-pl-20 {
  padding-left: 5rem;
}

.dq-pl-24 {
  padding-left: 6rem;
}

.dq-pl-28 {
  padding-left: 7rem;
}

.dq-pl-32 {
  padding-left: 8rem;
}

.dq-pl-36 {
  padding-left: 9rem;
}

.dq-pl-40 {
  padding-left: 10rem;
}

.dq-pl-44 {
  padding-left: 11rem;
}

.dq-pl-48 {
  padding-left: 12rem;
}

.dq-pl-52 {
  padding-left: 13rem;
}

.dq-pl-56 {
  padding-left: 14rem;
}

.dq-pl-60 {
  padding-left: 15rem;
}

.dq-pl-64 {
  padding-left: 16rem;
}

.dq-pl-72 {
  padding-left: 18rem;
}

.dq-pl-80 {
  padding-left: 20rem;
}

.dq-pl-96 {
  padding-left: 24rem;
}

.dq-pl-px {
  padding-left: 1px;
}

.dq-pl-0\.5 {
  padding-left: 0.125rem;
}

.dq-pl-1\.5 {
  padding-left: 0.375rem;
}

.dq-pl-2\.5 {
  padding-left: 0.625rem;
}

.dq-pl-3\.5 {
  padding-left: 0.875rem;
}

.dq-text-left {
  text-align: left;
}

.dq-text-center {
  text-align: center;
}

.dq-text-right {
  text-align: right;
}

.dq-text-justify {
  text-align: justify;
}

.dq-align-baseline {
  vertical-align: baseline;
}

.dq-align-top {
  vertical-align: top;
}

.dq-align-middle {
  vertical-align: middle;
}

.dq-align-bottom {
  vertical-align: bottom;
}

.dq-align-text-top {
  vertical-align: text-top;
}

.dq-align-text-bottom {
  vertical-align: text-bottom;
}

.dq-font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.dq-font-serif {
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
}

.dq-font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.dq-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.dq-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.dq-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.dq-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.dq-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.dq-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.dq-text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.dq-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.dq-text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.dq-text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.dq-text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.dq-text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.dq-text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.dq-font-thin {
  font-weight: 100;
}

.dq-font-extralight {
  font-weight: 200;
}

.dq-font-light {
  font-weight: 300;
}

.dq-font-normal {
  font-weight: 400;
}

.dq-font-medium {
  font-weight: 500;
}

.dq-font-semibold {
  font-weight: 600;
}

.dq-font-bold {
  font-weight: 700;
}

.dq-font-extrabold {
  font-weight: 800;
}

.dq-font-black {
  font-weight: 900;
}

.dq-uppercase {
  text-transform: uppercase;
}

.dq-lowercase {
  text-transform: lowercase;
}

.dq-capitalize {
  text-transform: capitalize;
}

.dq-normal-case {
  text-transform: none;
}

.dq-italic {
  font-style: italic;
}

.dq-not-italic {
  font-style: normal;
}

.dq-ordinal, .dq-slashed-zero, .dq-lining-nums, .dq-oldstyle-nums, .dq-proportional-nums, .dq-tabular-nums, .dq-diagonal-fractions, .dq-stacked-fractions {
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.dq-normal-nums {
  font-variant-numeric: normal;
}

.dq-ordinal {
  --tw-ordinal: ordinal;
}

.dq-slashed-zero {
  --tw-slashed-zero: slashed-zero;
}

.dq-lining-nums {
  --tw-numeric-figure: lining-nums;
}

.dq-oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums;
}

.dq-proportional-nums {
  --tw-numeric-spacing: proportional-nums;
}

.dq-tabular-nums {
  --tw-numeric-spacing: tabular-nums;
}

.dq-diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions;
}

.dq-stacked-fractions {
  --tw-numeric-fraction: stacked-fractions;
}

.dq-leading-3 {
  line-height: .75rem;
}

.dq-leading-4 {
  line-height: 1rem;
}

.dq-leading-5 {
  line-height: 1.25rem;
}

.dq-leading-6 {
  line-height: 1.5rem;
}

.dq-leading-7 {
  line-height: 1.75rem;
}

.dq-leading-8 {
  line-height: 2rem;
}

.dq-leading-9 {
  line-height: 2.25rem;
}

.dq-leading-10 {
  line-height: 2.5rem;
}

.dq-leading-none {
  line-height: 1;
}

.dq-leading-tight {
  line-height: 1.25;
}

.dq-leading-snug {
  line-height: 1.375;
}

.dq-leading-normal {
  line-height: 1.5;
}

.dq-leading-relaxed {
  line-height: 1.625;
}

.dq-leading-loose {
  line-height: 2;
}

.dq-tracking-tighter {
  letter-spacing: -0.05em;
}

.dq-tracking-tight {
  letter-spacing: -0.025em;
}

.dq-tracking-normal {
  letter-spacing: 0em;
}

.dq-tracking-wide {
  letter-spacing: 0.025em;
}

.dq-tracking-wider {
  letter-spacing: 0.05em;
}

.dq-tracking-widest {
  letter-spacing: 0.1em;
}

.dq-text-transparent {
  color: transparent;
}

.dq-text-current {
  color: currentColor;
}

.dq-text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.dq-text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dq-text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.dq-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.dq-text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.dq-text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.dq-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.dq-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.dq-text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.dq-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.dq-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.dq-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.dq-text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}

.dq-text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}

.dq-text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}

.dq-text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}

.dq-text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.dq-text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.dq-text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.dq-text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.dq-text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.dq-text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.dq-text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}

.dq-text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}

.dq-text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.dq-text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.dq-text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.dq-text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.dq-text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.dq-text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.dq-text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.dq-text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}

.dq-text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.dq-text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.dq-text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.dq-text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.dq-text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.dq-text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.dq-text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.dq-text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.dq-text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.dq-text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.dq-text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.dq-text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.dq-text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.dq-text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.dq-text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.dq-text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.dq-text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.dq-text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.dq-text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.dq-text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.dq-text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.dq-text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.dq-text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.dq-text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.dq-text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.dq-text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.dq-text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.dq-text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.dq-text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.dq-text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.dq-text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.dq-text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.dq-text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.dq-text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.dq-text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.dq-text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.dq-text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.dq-text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.dq-text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.dq-text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.dq-text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.dq-text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.dq-text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.dq-text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.dq-text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.dq-text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.dq-text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.dq-text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.dq-text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.dq-text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-transparent {
  color: transparent;
}

.dq-group:hover .group-hover\:dq-text-current {
  color: currentColor;
}

.dq-group:hover .group-hover\:dq-text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.dq-group:hover .group-hover\:dq-text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.focus-within\:dq-text-transparent:focus-within {
  color: transparent;
}

.focus-within\:dq-text-current:focus-within {
  color: currentColor;
}

.focus-within\:dq-text-black:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.focus-within\:dq-text-white:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.focus-within\:dq-text-gray-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.focus-within\:dq-text-gray-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.focus-within\:dq-text-gray-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.focus-within\:dq-text-gray-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.focus-within\:dq-text-gray-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.focus-within\:dq-text-gray-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.focus-within\:dq-text-gray-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.focus-within\:dq-text-gray-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.focus-within\:dq-text-gray-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.focus-within\:dq-text-gray-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.focus-within\:dq-text-red-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}

.focus-within\:dq-text-red-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}

.focus-within\:dq-text-red-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}

.focus-within\:dq-text-red-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}

.focus-within\:dq-text-red-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.focus-within\:dq-text-red-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.focus-within\:dq-text-red-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.focus-within\:dq-text-red-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.focus-within\:dq-text-red-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.focus-within\:dq-text-red-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.focus-within\:dq-text-yellow-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}

.focus-within\:dq-text-yellow-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}

.focus-within\:dq-text-yellow-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.focus-within\:dq-text-yellow-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.focus-within\:dq-text-yellow-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.focus-within\:dq-text-yellow-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.focus-within\:dq-text-yellow-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.focus-within\:dq-text-yellow-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.focus-within\:dq-text-yellow-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.focus-within\:dq-text-yellow-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}

.focus-within\:dq-text-green-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.focus-within\:dq-text-green-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.focus-within\:dq-text-green-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.focus-within\:dq-text-green-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.focus-within\:dq-text-green-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.focus-within\:dq-text-green-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.focus-within\:dq-text-green-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.focus-within\:dq-text-green-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.focus-within\:dq-text-green-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.focus-within\:dq-text-green-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.focus-within\:dq-text-blue-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.focus-within\:dq-text-blue-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.focus-within\:dq-text-blue-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.focus-within\:dq-text-blue-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.focus-within\:dq-text-blue-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.focus-within\:dq-text-blue-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.focus-within\:dq-text-blue-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.focus-within\:dq-text-blue-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.focus-within\:dq-text-blue-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.focus-within\:dq-text-blue-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.focus-within\:dq-text-indigo-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.focus-within\:dq-text-indigo-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.focus-within\:dq-text-indigo-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.focus-within\:dq-text-indigo-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.focus-within\:dq-text-indigo-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.focus-within\:dq-text-indigo-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.focus-within\:dq-text-indigo-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.focus-within\:dq-text-indigo-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.focus-within\:dq-text-indigo-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.focus-within\:dq-text-indigo-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.focus-within\:dq-text-purple-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.focus-within\:dq-text-purple-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.focus-within\:dq-text-purple-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.focus-within\:dq-text-purple-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.focus-within\:dq-text-purple-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.focus-within\:dq-text-purple-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.focus-within\:dq-text-purple-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.focus-within\:dq-text-purple-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.focus-within\:dq-text-purple-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.focus-within\:dq-text-purple-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.focus-within\:dq-text-pink-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.focus-within\:dq-text-pink-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.focus-within\:dq-text-pink-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.focus-within\:dq-text-pink-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.focus-within\:dq-text-pink-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.focus-within\:dq-text-pink-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.focus-within\:dq-text-pink-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.focus-within\:dq-text-pink-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.focus-within\:dq-text-pink-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.focus-within\:dq-text-pink-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.hover\:dq-text-transparent:hover {
  color: transparent;
}

.hover\:dq-text-current:hover {
  color: currentColor;
}

.hover\:dq-text-black:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.hover\:dq-text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:dq-text-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.hover\:dq-text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.hover\:dq-text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.hover\:dq-text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.hover\:dq-text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.hover\:dq-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.hover\:dq-text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.hover\:dq-text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.hover\:dq-text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.hover\:dq-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.hover\:dq-text-red-50:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}

.hover\:dq-text-red-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}

.hover\:dq-text-red-200:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}

.hover\:dq-text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}

.hover\:dq-text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.hover\:dq-text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.hover\:dq-text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.hover\:dq-text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.hover\:dq-text-red-800:hover {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.hover\:dq-text-red-900:hover {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.hover\:dq-text-yellow-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}

.hover\:dq-text-yellow-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}

.hover\:dq-text-yellow-200:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.hover\:dq-text-yellow-300:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.hover\:dq-text-yellow-400:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.hover\:dq-text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.hover\:dq-text-yellow-600:hover {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.hover\:dq-text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.hover\:dq-text-yellow-800:hover {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.hover\:dq-text-yellow-900:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}

.hover\:dq-text-green-50:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.hover\:dq-text-green-100:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.hover\:dq-text-green-200:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.hover\:dq-text-green-300:hover {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.hover\:dq-text-green-400:hover {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.hover\:dq-text-green-500:hover {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.hover\:dq-text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.hover\:dq-text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.hover\:dq-text-green-800:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.hover\:dq-text-green-900:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.hover\:dq-text-blue-50:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.hover\:dq-text-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.hover\:dq-text-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.hover\:dq-text-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.hover\:dq-text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.hover\:dq-text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.hover\:dq-text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.hover\:dq-text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.hover\:dq-text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.hover\:dq-text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.hover\:dq-text-indigo-50:hover {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.hover\:dq-text-indigo-100:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.hover\:dq-text-indigo-200:hover {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.hover\:dq-text-indigo-300:hover {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.hover\:dq-text-indigo-400:hover {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.hover\:dq-text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.hover\:dq-text-indigo-600:hover {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.hover\:dq-text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.hover\:dq-text-indigo-800:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.hover\:dq-text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.hover\:dq-text-purple-50:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.hover\:dq-text-purple-100:hover {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.hover\:dq-text-purple-200:hover {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.hover\:dq-text-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.hover\:dq-text-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.hover\:dq-text-purple-500:hover {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.hover\:dq-text-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.hover\:dq-text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.hover\:dq-text-purple-800:hover {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.hover\:dq-text-purple-900:hover {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.hover\:dq-text-pink-50:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.hover\:dq-text-pink-100:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.hover\:dq-text-pink-200:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.hover\:dq-text-pink-300:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.hover\:dq-text-pink-400:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.hover\:dq-text-pink-500:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.hover\:dq-text-pink-600:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.hover\:dq-text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.hover\:dq-text-pink-800:hover {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.hover\:dq-text-pink-900:hover {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.focus\:dq-text-transparent:focus {
  color: transparent;
}

.focus\:dq-text-current:focus {
  color: currentColor;
}

.focus\:dq-text-black:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.focus\:dq-text-white:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.focus\:dq-text-gray-50:focus {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.focus\:dq-text-gray-100:focus {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.focus\:dq-text-gray-200:focus {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.focus\:dq-text-gray-300:focus {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.focus\:dq-text-gray-400:focus {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.focus\:dq-text-gray-500:focus {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.focus\:dq-text-gray-600:focus {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.focus\:dq-text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.focus\:dq-text-gray-800:focus {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.focus\:dq-text-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.focus\:dq-text-red-50:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}

.focus\:dq-text-red-100:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}

.focus\:dq-text-red-200:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}

.focus\:dq-text-red-300:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}

.focus\:dq-text-red-400:focus {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.focus\:dq-text-red-500:focus {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.focus\:dq-text-red-600:focus {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.focus\:dq-text-red-700:focus {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.focus\:dq-text-red-800:focus {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.focus\:dq-text-red-900:focus {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.focus\:dq-text-yellow-50:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}

.focus\:dq-text-yellow-100:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}

.focus\:dq-text-yellow-200:focus {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.focus\:dq-text-yellow-300:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.focus\:dq-text-yellow-400:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.focus\:dq-text-yellow-500:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.focus\:dq-text-yellow-600:focus {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.focus\:dq-text-yellow-700:focus {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.focus\:dq-text-yellow-800:focus {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.focus\:dq-text-yellow-900:focus {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}

.focus\:dq-text-green-50:focus {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.focus\:dq-text-green-100:focus {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.focus\:dq-text-green-200:focus {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.focus\:dq-text-green-300:focus {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.focus\:dq-text-green-400:focus {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.focus\:dq-text-green-500:focus {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.focus\:dq-text-green-600:focus {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.focus\:dq-text-green-700:focus {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.focus\:dq-text-green-800:focus {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.focus\:dq-text-green-900:focus {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.focus\:dq-text-blue-50:focus {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.focus\:dq-text-blue-100:focus {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.focus\:dq-text-blue-200:focus {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.focus\:dq-text-blue-300:focus {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.focus\:dq-text-blue-400:focus {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.focus\:dq-text-blue-500:focus {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.focus\:dq-text-blue-600:focus {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.focus\:dq-text-blue-700:focus {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.focus\:dq-text-blue-800:focus {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.focus\:dq-text-blue-900:focus {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.focus\:dq-text-indigo-50:focus {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.focus\:dq-text-indigo-100:focus {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.focus\:dq-text-indigo-200:focus {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.focus\:dq-text-indigo-300:focus {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.focus\:dq-text-indigo-400:focus {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.focus\:dq-text-indigo-500:focus {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.focus\:dq-text-indigo-600:focus {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.focus\:dq-text-indigo-700:focus {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.focus\:dq-text-indigo-800:focus {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.focus\:dq-text-indigo-900:focus {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.focus\:dq-text-purple-50:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.focus\:dq-text-purple-100:focus {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.focus\:dq-text-purple-200:focus {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.focus\:dq-text-purple-300:focus {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.focus\:dq-text-purple-400:focus {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.focus\:dq-text-purple-500:focus {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.focus\:dq-text-purple-600:focus {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.focus\:dq-text-purple-700:focus {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.focus\:dq-text-purple-800:focus {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.focus\:dq-text-purple-900:focus {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.focus\:dq-text-pink-50:focus {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.focus\:dq-text-pink-100:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.focus\:dq-text-pink-200:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.focus\:dq-text-pink-300:focus {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.focus\:dq-text-pink-400:focus {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.focus\:dq-text-pink-500:focus {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.focus\:dq-text-pink-600:focus {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.focus\:dq-text-pink-700:focus {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.focus\:dq-text-pink-800:focus {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.focus\:dq-text-pink-900:focus {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.dq-text-opacity-0 {
  --tw-text-opacity: 0;
}

.dq-text-opacity-5 {
  --tw-text-opacity: 0.05;
}

.dq-text-opacity-10 {
  --tw-text-opacity: 0.1;
}

.dq-text-opacity-20 {
  --tw-text-opacity: 0.2;
}

.dq-text-opacity-25 {
  --tw-text-opacity: 0.25;
}

.dq-text-opacity-30 {
  --tw-text-opacity: 0.3;
}

.dq-text-opacity-40 {
  --tw-text-opacity: 0.4;
}

.dq-text-opacity-50 {
  --tw-text-opacity: 0.5;
}

.dq-text-opacity-60 {
  --tw-text-opacity: 0.6;
}

.dq-text-opacity-70 {
  --tw-text-opacity: 0.7;
}

.dq-text-opacity-75 {
  --tw-text-opacity: 0.75;
}

.dq-text-opacity-80 {
  --tw-text-opacity: 0.8;
}

.dq-text-opacity-90 {
  --tw-text-opacity: 0.9;
}

.dq-text-opacity-95 {
  --tw-text-opacity: 0.95;
}

.dq-text-opacity-100 {
  --tw-text-opacity: 1;
}

.dq-group:hover .group-hover\:dq-text-opacity-0 {
  --tw-text-opacity: 0;
}

.dq-group:hover .group-hover\:dq-text-opacity-5 {
  --tw-text-opacity: 0.05;
}

.dq-group:hover .group-hover\:dq-text-opacity-10 {
  --tw-text-opacity: 0.1;
}

.dq-group:hover .group-hover\:dq-text-opacity-20 {
  --tw-text-opacity: 0.2;
}

.dq-group:hover .group-hover\:dq-text-opacity-25 {
  --tw-text-opacity: 0.25;
}

.dq-group:hover .group-hover\:dq-text-opacity-30 {
  --tw-text-opacity: 0.3;
}

.dq-group:hover .group-hover\:dq-text-opacity-40 {
  --tw-text-opacity: 0.4;
}

.dq-group:hover .group-hover\:dq-text-opacity-50 {
  --tw-text-opacity: 0.5;
}

.dq-group:hover .group-hover\:dq-text-opacity-60 {
  --tw-text-opacity: 0.6;
}

.dq-group:hover .group-hover\:dq-text-opacity-70 {
  --tw-text-opacity: 0.7;
}

.dq-group:hover .group-hover\:dq-text-opacity-75 {
  --tw-text-opacity: 0.75;
}

.dq-group:hover .group-hover\:dq-text-opacity-80 {
  --tw-text-opacity: 0.8;
}

.dq-group:hover .group-hover\:dq-text-opacity-90 {
  --tw-text-opacity: 0.9;
}

.dq-group:hover .group-hover\:dq-text-opacity-95 {
  --tw-text-opacity: 0.95;
}

.dq-group:hover .group-hover\:dq-text-opacity-100 {
  --tw-text-opacity: 1;
}

.focus-within\:dq-text-opacity-0:focus-within {
  --tw-text-opacity: 0;
}

.focus-within\:dq-text-opacity-5:focus-within {
  --tw-text-opacity: 0.05;
}

.focus-within\:dq-text-opacity-10:focus-within {
  --tw-text-opacity: 0.1;
}

.focus-within\:dq-text-opacity-20:focus-within {
  --tw-text-opacity: 0.2;
}

.focus-within\:dq-text-opacity-25:focus-within {
  --tw-text-opacity: 0.25;
}

.focus-within\:dq-text-opacity-30:focus-within {
  --tw-text-opacity: 0.3;
}

.focus-within\:dq-text-opacity-40:focus-within {
  --tw-text-opacity: 0.4;
}

.focus-within\:dq-text-opacity-50:focus-within {
  --tw-text-opacity: 0.5;
}

.focus-within\:dq-text-opacity-60:focus-within {
  --tw-text-opacity: 0.6;
}

.focus-within\:dq-text-opacity-70:focus-within {
  --tw-text-opacity: 0.7;
}

.focus-within\:dq-text-opacity-75:focus-within {
  --tw-text-opacity: 0.75;
}

.focus-within\:dq-text-opacity-80:focus-within {
  --tw-text-opacity: 0.8;
}

.focus-within\:dq-text-opacity-90:focus-within {
  --tw-text-opacity: 0.9;
}

.focus-within\:dq-text-opacity-95:focus-within {
  --tw-text-opacity: 0.95;
}

.focus-within\:dq-text-opacity-100:focus-within {
  --tw-text-opacity: 1;
}

.hover\:dq-text-opacity-0:hover {
  --tw-text-opacity: 0;
}

.hover\:dq-text-opacity-5:hover {
  --tw-text-opacity: 0.05;
}

.hover\:dq-text-opacity-10:hover {
  --tw-text-opacity: 0.1;
}

.hover\:dq-text-opacity-20:hover {
  --tw-text-opacity: 0.2;
}

.hover\:dq-text-opacity-25:hover {
  --tw-text-opacity: 0.25;
}

.hover\:dq-text-opacity-30:hover {
  --tw-text-opacity: 0.3;
}

.hover\:dq-text-opacity-40:hover {
  --tw-text-opacity: 0.4;
}

.hover\:dq-text-opacity-50:hover {
  --tw-text-opacity: 0.5;
}

.hover\:dq-text-opacity-60:hover {
  --tw-text-opacity: 0.6;
}

.hover\:dq-text-opacity-70:hover {
  --tw-text-opacity: 0.7;
}

.hover\:dq-text-opacity-75:hover {
  --tw-text-opacity: 0.75;
}

.hover\:dq-text-opacity-80:hover {
  --tw-text-opacity: 0.8;
}

.hover\:dq-text-opacity-90:hover {
  --tw-text-opacity: 0.9;
}

.hover\:dq-text-opacity-95:hover {
  --tw-text-opacity: 0.95;
}

.hover\:dq-text-opacity-100:hover {
  --tw-text-opacity: 1;
}

.focus\:dq-text-opacity-0:focus {
  --tw-text-opacity: 0;
}

.focus\:dq-text-opacity-5:focus {
  --tw-text-opacity: 0.05;
}

.focus\:dq-text-opacity-10:focus {
  --tw-text-opacity: 0.1;
}

.focus\:dq-text-opacity-20:focus {
  --tw-text-opacity: 0.2;
}

.focus\:dq-text-opacity-25:focus {
  --tw-text-opacity: 0.25;
}

.focus\:dq-text-opacity-30:focus {
  --tw-text-opacity: 0.3;
}

.focus\:dq-text-opacity-40:focus {
  --tw-text-opacity: 0.4;
}

.focus\:dq-text-opacity-50:focus {
  --tw-text-opacity: 0.5;
}

.focus\:dq-text-opacity-60:focus {
  --tw-text-opacity: 0.6;
}

.focus\:dq-text-opacity-70:focus {
  --tw-text-opacity: 0.7;
}

.focus\:dq-text-opacity-75:focus {
  --tw-text-opacity: 0.75;
}

.focus\:dq-text-opacity-80:focus {
  --tw-text-opacity: 0.8;
}

.focus\:dq-text-opacity-90:focus {
  --tw-text-opacity: 0.9;
}

.focus\:dq-text-opacity-95:focus {
  --tw-text-opacity: 0.95;
}

.focus\:dq-text-opacity-100:focus {
  --tw-text-opacity: 1;
}

.dq-underline {
  text-decoration: underline;
}

.dq-line-through {
  text-decoration: line-through;
}

.dq-no-underline {
  text-decoration: none;
}

.dq-group:hover .group-hover\:dq-underline {
  text-decoration: underline;
}

.dq-group:hover .group-hover\:dq-line-through {
  text-decoration: line-through;
}

.dq-group:hover .group-hover\:dq-no-underline {
  text-decoration: none;
}

.focus-within\:dq-underline:focus-within {
  text-decoration: underline;
}

.focus-within\:dq-line-through:focus-within {
  text-decoration: line-through;
}

.focus-within\:dq-no-underline:focus-within {
  text-decoration: none;
}

.hover\:dq-underline:hover {
  text-decoration: underline;
}

.hover\:dq-line-through:hover {
  text-decoration: line-through;
}

.hover\:dq-no-underline:hover {
  text-decoration: none;
}

.focus\:dq-underline:focus {
  text-decoration: underline;
}

.focus\:dq-line-through:focus {
  text-decoration: line-through;
}

.focus\:dq-no-underline:focus {
  text-decoration: none;
}

.dq-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dq-subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.dq-placeholder-transparent::-webkit-input-placeholder {
  color: transparent;
}

.dq-placeholder-transparent:-ms-input-placeholder {
  color: transparent;
}

.dq-placeholder-transparent::placeholder {
  color: transparent;
}

.dq-placeholder-current::-webkit-input-placeholder {
  color: currentColor;
}

.dq-placeholder-current:-ms-input-placeholder {
  color: currentColor;
}

.dq-placeholder-current::placeholder {
  color: currentColor;
}

.dq-placeholder-black::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}

.dq-placeholder-black:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}

.dq-placeholder-black::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}

.dq-placeholder-white::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-white:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
}

.dq-placeholder-gray-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}

.dq-placeholder-red-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}

.dq-placeholder-yellow-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}

.dq-placeholder-green-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}

.dq-placeholder-blue-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}

.dq-placeholder-indigo-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}

.dq-placeholder-purple-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-200:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-500::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-600::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-600:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-700::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-700:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-800::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-800:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-900::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-900:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}

.dq-placeholder-pink-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-transparent:focus::-webkit-input-placeholder {
  color: transparent;
}

.focus\:dq-placeholder-transparent:focus:-ms-input-placeholder {
  color: transparent;
}

.focus\:dq-placeholder-transparent:focus::placeholder {
  color: transparent;
}

.focus\:dq-placeholder-current:focus::-webkit-input-placeholder {
  color: currentColor;
}

.focus\:dq-placeholder-current:focus:-ms-input-placeholder {
  color: currentColor;
}

.focus\:dq-placeholder-current:focus::placeholder {
  color: currentColor;
}

.focus\:dq-placeholder-black:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-black:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-black:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-white:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-white:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-white:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-gray-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-red-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-yellow-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-green-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-blue-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-indigo-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-purple-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-200:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-200:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-300:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-300:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-400:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-400:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-500:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-500:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-600:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-600:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-700:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-700:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-800:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-800:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-900:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-900:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}

.focus\:dq-placeholder-pink-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}

.dq-placeholder-opacity-0::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0;
}

.dq-placeholder-opacity-0:-ms-input-placeholder {
  --tw-placeholder-opacity: 0;
}

.dq-placeholder-opacity-0::placeholder {
  --tw-placeholder-opacity: 0;
}

.dq-placeholder-opacity-5::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.05;
}

.dq-placeholder-opacity-5:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.05;
}

.dq-placeholder-opacity-5::placeholder {
  --tw-placeholder-opacity: 0.05;
}

.dq-placeholder-opacity-10::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.1;
}

.dq-placeholder-opacity-10:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.1;
}

.dq-placeholder-opacity-10::placeholder {
  --tw-placeholder-opacity: 0.1;
}

.dq-placeholder-opacity-20::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.2;
}

.dq-placeholder-opacity-20:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.2;
}

.dq-placeholder-opacity-20::placeholder {
  --tw-placeholder-opacity: 0.2;
}

.dq-placeholder-opacity-25::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.25;
}

.dq-placeholder-opacity-25:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.25;
}

.dq-placeholder-opacity-25::placeholder {
  --tw-placeholder-opacity: 0.25;
}

.dq-placeholder-opacity-30::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.3;
}

.dq-placeholder-opacity-30:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.3;
}

.dq-placeholder-opacity-30::placeholder {
  --tw-placeholder-opacity: 0.3;
}

.dq-placeholder-opacity-40::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.4;
}

.dq-placeholder-opacity-40:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.4;
}

.dq-placeholder-opacity-40::placeholder {
  --tw-placeholder-opacity: 0.4;
}

.dq-placeholder-opacity-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.5;
}

.dq-placeholder-opacity-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.5;
}

.dq-placeholder-opacity-50::placeholder {
  --tw-placeholder-opacity: 0.5;
}

.dq-placeholder-opacity-60::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.6;
}

.dq-placeholder-opacity-60:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.6;
}

.dq-placeholder-opacity-60::placeholder {
  --tw-placeholder-opacity: 0.6;
}

.dq-placeholder-opacity-70::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.7;
}

.dq-placeholder-opacity-70:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.7;
}

.dq-placeholder-opacity-70::placeholder {
  --tw-placeholder-opacity: 0.7;
}

.dq-placeholder-opacity-75::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.75;
}

.dq-placeholder-opacity-75:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.75;
}

.dq-placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: 0.75;
}

.dq-placeholder-opacity-80::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.8;
}

.dq-placeholder-opacity-80:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.8;
}

.dq-placeholder-opacity-80::placeholder {
  --tw-placeholder-opacity: 0.8;
}

.dq-placeholder-opacity-90::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.9;
}

.dq-placeholder-opacity-90:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.9;
}

.dq-placeholder-opacity-90::placeholder {
  --tw-placeholder-opacity: 0.9;
}

.dq-placeholder-opacity-95::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.95;
}

.dq-placeholder-opacity-95:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.95;
}

.dq-placeholder-opacity-95::placeholder {
  --tw-placeholder-opacity: 0.95;
}

.dq-placeholder-opacity-100::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
}

.dq-placeholder-opacity-100:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
}

.dq-placeholder-opacity-100::placeholder {
  --tw-placeholder-opacity: 1;
}

.focus\:dq-placeholder-opacity-0:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0;
}

.focus\:dq-placeholder-opacity-0:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0;
}

.focus\:dq-placeholder-opacity-0:focus::placeholder {
  --tw-placeholder-opacity: 0;
}

.focus\:dq-placeholder-opacity-5:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.05;
}

.focus\:dq-placeholder-opacity-5:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.05;
}

.focus\:dq-placeholder-opacity-5:focus::placeholder {
  --tw-placeholder-opacity: 0.05;
}

.focus\:dq-placeholder-opacity-10:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.1;
}

.focus\:dq-placeholder-opacity-10:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.1;
}

.focus\:dq-placeholder-opacity-10:focus::placeholder {
  --tw-placeholder-opacity: 0.1;
}

.focus\:dq-placeholder-opacity-20:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.2;
}

.focus\:dq-placeholder-opacity-20:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.2;
}

.focus\:dq-placeholder-opacity-20:focus::placeholder {
  --tw-placeholder-opacity: 0.2;
}

.focus\:dq-placeholder-opacity-25:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.25;
}

.focus\:dq-placeholder-opacity-25:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.25;
}

.focus\:dq-placeholder-opacity-25:focus::placeholder {
  --tw-placeholder-opacity: 0.25;
}

.focus\:dq-placeholder-opacity-30:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.3;
}

.focus\:dq-placeholder-opacity-30:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.3;
}

.focus\:dq-placeholder-opacity-30:focus::placeholder {
  --tw-placeholder-opacity: 0.3;
}

.focus\:dq-placeholder-opacity-40:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.4;
}

.focus\:dq-placeholder-opacity-40:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.4;
}

.focus\:dq-placeholder-opacity-40:focus::placeholder {
  --tw-placeholder-opacity: 0.4;
}

.focus\:dq-placeholder-opacity-50:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.5;
}

.focus\:dq-placeholder-opacity-50:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.5;
}

.focus\:dq-placeholder-opacity-50:focus::placeholder {
  --tw-placeholder-opacity: 0.5;
}

.focus\:dq-placeholder-opacity-60:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.6;
}

.focus\:dq-placeholder-opacity-60:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.6;
}

.focus\:dq-placeholder-opacity-60:focus::placeholder {
  --tw-placeholder-opacity: 0.6;
}

.focus\:dq-placeholder-opacity-70:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.7;
}

.focus\:dq-placeholder-opacity-70:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.7;
}

.focus\:dq-placeholder-opacity-70:focus::placeholder {
  --tw-placeholder-opacity: 0.7;
}

.focus\:dq-placeholder-opacity-75:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.75;
}

.focus\:dq-placeholder-opacity-75:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.75;
}

.focus\:dq-placeholder-opacity-75:focus::placeholder {
  --tw-placeholder-opacity: 0.75;
}

.focus\:dq-placeholder-opacity-80:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.8;
}

.focus\:dq-placeholder-opacity-80:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.8;
}

.focus\:dq-placeholder-opacity-80:focus::placeholder {
  --tw-placeholder-opacity: 0.8;
}

.focus\:dq-placeholder-opacity-90:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.9;
}

.focus\:dq-placeholder-opacity-90:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.9;
}

.focus\:dq-placeholder-opacity-90:focus::placeholder {
  --tw-placeholder-opacity: 0.9;
}

.focus\:dq-placeholder-opacity-95:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.95;
}

.focus\:dq-placeholder-opacity-95:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.95;
}

.focus\:dq-placeholder-opacity-95:focus::placeholder {
  --tw-placeholder-opacity: 0.95;
}

.focus\:dq-placeholder-opacity-100:focus::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
}

.focus\:dq-placeholder-opacity-100:focus:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
}

.focus\:dq-placeholder-opacity-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
}

.dq-caret-transparent {
  caret-color: transparent;
}

.dq-caret-current {
  caret-color: currentColor;
}

.dq-caret-black {
  caret-color: #000;
}

.dq-caret-white {
  caret-color: #fff;
}

.dq-caret-gray-50 {
  caret-color: #f9fafb;
}

.dq-caret-gray-100 {
  caret-color: #f3f4f6;
}

.dq-caret-gray-200 {
  caret-color: #e5e7eb;
}

.dq-caret-gray-300 {
  caret-color: #d1d5db;
}

.dq-caret-gray-400 {
  caret-color: #9ca3af;
}

.dq-caret-gray-500 {
  caret-color: #6b7280;
}

.dq-caret-gray-600 {
  caret-color: #4b5563;
}

.dq-caret-gray-700 {
  caret-color: #374151;
}

.dq-caret-gray-800 {
  caret-color: #1f2937;
}

.dq-caret-gray-900 {
  caret-color: #111827;
}

.dq-caret-red-50 {
  caret-color: #fef2f2;
}

.dq-caret-red-100 {
  caret-color: #fee2e2;
}

.dq-caret-red-200 {
  caret-color: #fecaca;
}

.dq-caret-red-300 {
  caret-color: #fca5a5;
}

.dq-caret-red-400 {
  caret-color: #f87171;
}

.dq-caret-red-500 {
  caret-color: #ef4444;
}

.dq-caret-red-600 {
  caret-color: #dc2626;
}

.dq-caret-red-700 {
  caret-color: #b91c1c;
}

.dq-caret-red-800 {
  caret-color: #991b1b;
}

.dq-caret-red-900 {
  caret-color: #7f1d1d;
}

.dq-caret-yellow-50 {
  caret-color: #fffbeb;
}

.dq-caret-yellow-100 {
  caret-color: #fef3c7;
}

.dq-caret-yellow-200 {
  caret-color: #fde68a;
}

.dq-caret-yellow-300 {
  caret-color: #fcd34d;
}

.dq-caret-yellow-400 {
  caret-color: #fbbf24;
}

.dq-caret-yellow-500 {
  caret-color: #f59e0b;
}

.dq-caret-yellow-600 {
  caret-color: #d97706;
}

.dq-caret-yellow-700 {
  caret-color: #b45309;
}

.dq-caret-yellow-800 {
  caret-color: #92400e;
}

.dq-caret-yellow-900 {
  caret-color: #78350f;
}

.dq-caret-green-50 {
  caret-color: #ecfdf5;
}

.dq-caret-green-100 {
  caret-color: #d1fae5;
}

.dq-caret-green-200 {
  caret-color: #a7f3d0;
}

.dq-caret-green-300 {
  caret-color: #6ee7b7;
}

.dq-caret-green-400 {
  caret-color: #34d399;
}

.dq-caret-green-500 {
  caret-color: #10b981;
}

.dq-caret-green-600 {
  caret-color: #059669;
}

.dq-caret-green-700 {
  caret-color: #047857;
}

.dq-caret-green-800 {
  caret-color: #065f46;
}

.dq-caret-green-900 {
  caret-color: #064e3b;
}

.dq-caret-blue-50 {
  caret-color: #eff6ff;
}

.dq-caret-blue-100 {
  caret-color: #dbeafe;
}

.dq-caret-blue-200 {
  caret-color: #bfdbfe;
}

.dq-caret-blue-300 {
  caret-color: #93c5fd;
}

.dq-caret-blue-400 {
  caret-color: #60a5fa;
}

.dq-caret-blue-500 {
  caret-color: #3b82f6;
}

.dq-caret-blue-600 {
  caret-color: #2563eb;
}

.dq-caret-blue-700 {
  caret-color: #1d4ed8;
}

.dq-caret-blue-800 {
  caret-color: #1e40af;
}

.dq-caret-blue-900 {
  caret-color: #1e3a8a;
}

.dq-caret-indigo-50 {
  caret-color: #eef2ff;
}

.dq-caret-indigo-100 {
  caret-color: #e0e7ff;
}

.dq-caret-indigo-200 {
  caret-color: #c7d2fe;
}

.dq-caret-indigo-300 {
  caret-color: #a5b4fc;
}

.dq-caret-indigo-400 {
  caret-color: #818cf8;
}

.dq-caret-indigo-500 {
  caret-color: #6366f1;
}

.dq-caret-indigo-600 {
  caret-color: #4f46e5;
}

.dq-caret-indigo-700 {
  caret-color: #4338ca;
}

.dq-caret-indigo-800 {
  caret-color: #3730a3;
}

.dq-caret-indigo-900 {
  caret-color: #312e81;
}

.dq-caret-purple-50 {
  caret-color: #f5f3ff;
}

.dq-caret-purple-100 {
  caret-color: #ede9fe;
}

.dq-caret-purple-200 {
  caret-color: #ddd6fe;
}

.dq-caret-purple-300 {
  caret-color: #c4b5fd;
}

.dq-caret-purple-400 {
  caret-color: #a78bfa;
}

.dq-caret-purple-500 {
  caret-color: #8b5cf6;
}

.dq-caret-purple-600 {
  caret-color: #7c3aed;
}

.dq-caret-purple-700 {
  caret-color: #6d28d9;
}

.dq-caret-purple-800 {
  caret-color: #5b21b6;
}

.dq-caret-purple-900 {
  caret-color: #4c1d95;
}

.dq-caret-pink-50 {
  caret-color: #fdf2f8;
}

.dq-caret-pink-100 {
  caret-color: #fce7f3;
}

.dq-caret-pink-200 {
  caret-color: #fbcfe8;
}

.dq-caret-pink-300 {
  caret-color: #f9a8d4;
}

.dq-caret-pink-400 {
  caret-color: #f472b6;
}

.dq-caret-pink-500 {
  caret-color: #ec4899;
}

.dq-caret-pink-600 {
  caret-color: #db2777;
}

.dq-caret-pink-700 {
  caret-color: #be185d;
}

.dq-caret-pink-800 {
  caret-color: #9d174d;
}

.dq-caret-pink-900 {
  caret-color: #831843;
}

.dq-opacity-0 {
  opacity: 0;
}

.dq-opacity-5 {
  opacity: 0.05;
}

.dq-opacity-10 {
  opacity: 0.1;
}

.dq-opacity-20 {
  opacity: 0.2;
}

.dq-opacity-25 {
  opacity: 0.25;
}

.dq-opacity-30 {
  opacity: 0.3;
}

.dq-opacity-40 {
  opacity: 0.4;
}

.dq-opacity-50 {
  opacity: 0.5;
}

.dq-opacity-60 {
  opacity: 0.6;
}

.dq-opacity-70 {
  opacity: 0.7;
}

.dq-opacity-75 {
  opacity: 0.75;
}

.dq-opacity-80 {
  opacity: 0.8;
}

.dq-opacity-90 {
  opacity: 0.9;
}

.dq-opacity-95 {
  opacity: 0.95;
}

.dq-opacity-100 {
  opacity: 1;
}

.dq-group:hover .group-hover\:dq-opacity-0 {
  opacity: 0;
}

.dq-group:hover .group-hover\:dq-opacity-5 {
  opacity: 0.05;
}

.dq-group:hover .group-hover\:dq-opacity-10 {
  opacity: 0.1;
}

.dq-group:hover .group-hover\:dq-opacity-20 {
  opacity: 0.2;
}

.dq-group:hover .group-hover\:dq-opacity-25 {
  opacity: 0.25;
}

.dq-group:hover .group-hover\:dq-opacity-30 {
  opacity: 0.3;
}

.dq-group:hover .group-hover\:dq-opacity-40 {
  opacity: 0.4;
}

.dq-group:hover .group-hover\:dq-opacity-50 {
  opacity: 0.5;
}

.dq-group:hover .group-hover\:dq-opacity-60 {
  opacity: 0.6;
}

.dq-group:hover .group-hover\:dq-opacity-70 {
  opacity: 0.7;
}

.dq-group:hover .group-hover\:dq-opacity-75 {
  opacity: 0.75;
}

.dq-group:hover .group-hover\:dq-opacity-80 {
  opacity: 0.8;
}

.dq-group:hover .group-hover\:dq-opacity-90 {
  opacity: 0.9;
}

.dq-group:hover .group-hover\:dq-opacity-95 {
  opacity: 0.95;
}

.dq-group:hover .group-hover\:dq-opacity-100 {
  opacity: 1;
}

.focus-within\:dq-opacity-0:focus-within {
  opacity: 0;
}

.focus-within\:dq-opacity-5:focus-within {
  opacity: 0.05;
}

.focus-within\:dq-opacity-10:focus-within {
  opacity: 0.1;
}

.focus-within\:dq-opacity-20:focus-within {
  opacity: 0.2;
}

.focus-within\:dq-opacity-25:focus-within {
  opacity: 0.25;
}

.focus-within\:dq-opacity-30:focus-within {
  opacity: 0.3;
}

.focus-within\:dq-opacity-40:focus-within {
  opacity: 0.4;
}

.focus-within\:dq-opacity-50:focus-within {
  opacity: 0.5;
}

.focus-within\:dq-opacity-60:focus-within {
  opacity: 0.6;
}

.focus-within\:dq-opacity-70:focus-within {
  opacity: 0.7;
}

.focus-within\:dq-opacity-75:focus-within {
  opacity: 0.75;
}

.focus-within\:dq-opacity-80:focus-within {
  opacity: 0.8;
}

.focus-within\:dq-opacity-90:focus-within {
  opacity: 0.9;
}

.focus-within\:dq-opacity-95:focus-within {
  opacity: 0.95;
}

.focus-within\:dq-opacity-100:focus-within {
  opacity: 1;
}

.hover\:dq-opacity-0:hover {
  opacity: 0;
}

.hover\:dq-opacity-5:hover {
  opacity: 0.05;
}

.hover\:dq-opacity-10:hover {
  opacity: 0.1;
}

.hover\:dq-opacity-20:hover {
  opacity: 0.2;
}

.hover\:dq-opacity-25:hover {
  opacity: 0.25;
}

.hover\:dq-opacity-30:hover {
  opacity: 0.3;
}

.hover\:dq-opacity-40:hover {
  opacity: 0.4;
}

.hover\:dq-opacity-50:hover {
  opacity: 0.5;
}

.hover\:dq-opacity-60:hover {
  opacity: 0.6;
}

.hover\:dq-opacity-70:hover {
  opacity: 0.7;
}

.hover\:dq-opacity-75:hover {
  opacity: 0.75;
}

.hover\:dq-opacity-80:hover {
  opacity: 0.8;
}

.hover\:dq-opacity-90:hover {
  opacity: 0.9;
}

.hover\:dq-opacity-95:hover {
  opacity: 0.95;
}

.hover\:dq-opacity-100:hover {
  opacity: 1;
}

.focus\:dq-opacity-0:focus {
  opacity: 0;
}

.focus\:dq-opacity-5:focus {
  opacity: 0.05;
}

.focus\:dq-opacity-10:focus {
  opacity: 0.1;
}

.focus\:dq-opacity-20:focus {
  opacity: 0.2;
}

.focus\:dq-opacity-25:focus {
  opacity: 0.25;
}

.focus\:dq-opacity-30:focus {
  opacity: 0.3;
}

.focus\:dq-opacity-40:focus {
  opacity: 0.4;
}

.focus\:dq-opacity-50:focus {
  opacity: 0.5;
}

.focus\:dq-opacity-60:focus {
  opacity: 0.6;
}

.focus\:dq-opacity-70:focus {
  opacity: 0.7;
}

.focus\:dq-opacity-75:focus {
  opacity: 0.75;
}

.focus\:dq-opacity-80:focus {
  opacity: 0.8;
}

.focus\:dq-opacity-90:focus {
  opacity: 0.9;
}

.focus\:dq-opacity-95:focus {
  opacity: 0.95;
}

.focus\:dq-opacity-100:focus {
  opacity: 1;
}

.dq-bg-blend-normal {
  background-blend-mode: normal;
}

.dq-bg-blend-multiply {
  background-blend-mode: multiply;
}

.dq-bg-blend-screen {
  background-blend-mode: screen;
}

.dq-bg-blend-overlay {
  background-blend-mode: overlay;
}

.dq-bg-blend-darken {
  background-blend-mode: darken;
}

.dq-bg-blend-lighten {
  background-blend-mode: lighten;
}

.dq-bg-blend-color-dodge {
  background-blend-mode: color-dodge;
}

.dq-bg-blend-color-burn {
  background-blend-mode: color-burn;
}

.dq-bg-blend-hard-light {
  background-blend-mode: hard-light;
}

.dq-bg-blend-soft-light {
  background-blend-mode: soft-light;
}

.dq-bg-blend-difference {
  background-blend-mode: difference;
}

.dq-bg-blend-exclusion {
  background-blend-mode: exclusion;
}

.dq-bg-blend-hue {
  background-blend-mode: hue;
}

.dq-bg-blend-saturation {
  background-blend-mode: saturation;
}

.dq-bg-blend-color {
  background-blend-mode: color;
}

.dq-bg-blend-luminosity {
  background-blend-mode: luminosity;
}

.dq-mix-blend-normal {
  mix-blend-mode: normal;
}

.dq-mix-blend-multiply {
  mix-blend-mode: multiply;
}

.dq-mix-blend-screen {
  mix-blend-mode: screen;
}

.dq-mix-blend-overlay {
  mix-blend-mode: overlay;
}

.dq-mix-blend-darken {
  mix-blend-mode: darken;
}

.dq-mix-blend-lighten {
  mix-blend-mode: lighten;
}

.dq-mix-blend-color-dodge {
  mix-blend-mode: color-dodge;
}

.dq-mix-blend-color-burn {
  mix-blend-mode: color-burn;
}

.dq-mix-blend-hard-light {
  mix-blend-mode: hard-light;
}

.dq-mix-blend-soft-light {
  mix-blend-mode: soft-light;
}

.dq-mix-blend-difference {
  mix-blend-mode: difference;
}

.dq-mix-blend-exclusion {
  mix-blend-mode: exclusion;
}

.dq-mix-blend-hue {
  mix-blend-mode: hue;
}

.dq-mix-blend-saturation {
  mix-blend-mode: saturation;
}

.dq-mix-blend-color {
  mix-blend-mode: color;
}

.dq-mix-blend-luminosity {
  mix-blend-mode: luminosity;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.dq-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-group:hover .group-hover\:dq-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-group:hover .group-hover\:dq-shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-group:hover .group-hover\:dq-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-group:hover .group-hover\:dq-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-group:hover .group-hover\:dq-shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-group:hover .group-hover\:dq-shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-group:hover .group-hover\:dq-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-group:hover .group-hover\:dq-shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:dq-shadow-sm:focus-within {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:dq-shadow:focus-within {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:dq-shadow-md:focus-within {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:dq-shadow-lg:focus-within {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:dq-shadow-xl:focus-within {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:dq-shadow-2xl:focus-within {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:dq-shadow-inner:focus-within {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:dq-shadow-none:focus-within {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:dq-shadow-sm:hover {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:dq-shadow:hover {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:dq-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:dq-shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:dq-shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:dq-shadow-2xl:hover {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:dq-shadow-inner:hover {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:dq-shadow-none:hover {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:dq-shadow-sm:focus {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:dq-shadow:focus {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:dq-shadow-md:focus {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:dq-shadow-lg:focus {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:dq-shadow-xl:focus {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:dq-shadow-2xl:focus {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:dq-shadow-inner:focus {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:dq-shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dq-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dq-outline-white {
  outline: 2px dotted white;
  outline-offset: 2px;
}

.dq-outline-black {
  outline: 2px dotted black;
  outline-offset: 2px;
}

.focus-within\:dq-outline-none:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus-within\:dq-outline-white:focus-within {
  outline: 2px dotted white;
  outline-offset: 2px;
}

.focus-within\:dq-outline-black:focus-within {
  outline: 2px dotted black;
  outline-offset: 2px;
}

.focus\:dq-outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:dq-outline-white:focus {
  outline: 2px dotted white;
  outline-offset: 2px;
}

.focus\:dq-outline-black:focus {
  outline: 2px dotted black;
  outline-offset: 2px;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.dq-ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.dq-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.dq-ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.dq-ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.dq-ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.dq-ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:dq-ring-0:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:dq-ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:dq-ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:dq-ring-4:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:dq-ring-8:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:dq-ring:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:dq-ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:dq-ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:dq-ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:dq-ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:dq-ring-8:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:dq-ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.dq-ring-inset {
  --tw-ring-inset: inset;
}

.focus-within\:dq-ring-inset:focus-within {
  --tw-ring-inset: inset;
}

.focus\:dq-ring-inset:focus {
  --tw-ring-inset: inset;
}

.dq-ring-transparent {
  --tw-ring-color: transparent;
}

.dq-ring-current {
  --tw-ring-color: currentColor;
}

.dq-ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.dq-ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.dq-ring-gray-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}

.dq-ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}

.dq-ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}

.dq-ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

.dq-ring-gray-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}

.dq-ring-gray-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}

.dq-ring-gray-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}

.dq-ring-gray-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}

.dq-ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}

.dq-ring-gray-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}

.dq-ring-red-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}

.dq-ring-red-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}

.dq-ring-red-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}

.dq-ring-red-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}

.dq-ring-red-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}

.dq-ring-red-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}

.dq-ring-red-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}

.dq-ring-red-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}

.dq-ring-red-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}

.dq-ring-red-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}

.dq-ring-yellow-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}

.dq-ring-yellow-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}

.dq-ring-yellow-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}

.dq-ring-yellow-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}

.dq-ring-yellow-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}

.dq-ring-yellow-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}

.dq-ring-yellow-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}

.dq-ring-yellow-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}

.dq-ring-yellow-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}

.dq-ring-yellow-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}

.dq-ring-green-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}

.dq-ring-green-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}

.dq-ring-green-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}

.dq-ring-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}

.dq-ring-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}

.dq-ring-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}

.dq-ring-green-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}

.dq-ring-green-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}

.dq-ring-green-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}

.dq-ring-green-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}

.dq-ring-blue-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}

.dq-ring-blue-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}

.dq-ring-blue-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}

.dq-ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}

.dq-ring-blue-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}

.dq-ring-blue-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}

.dq-ring-blue-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}

.dq-ring-blue-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}

.dq-ring-blue-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}

.dq-ring-blue-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}

.dq-ring-indigo-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}

.dq-ring-indigo-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}

.dq-ring-indigo-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}

.dq-ring-indigo-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}

.dq-ring-indigo-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}

.dq-ring-indigo-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.dq-ring-indigo-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}

.dq-ring-indigo-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}

.dq-ring-indigo-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}

.dq-ring-indigo-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}

.dq-ring-purple-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}

.dq-ring-purple-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}

.dq-ring-purple-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}

.dq-ring-purple-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}

.dq-ring-purple-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}

.dq-ring-purple-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}

.dq-ring-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}

.dq-ring-purple-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}

.dq-ring-purple-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}

.dq-ring-purple-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}

.dq-ring-pink-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}

.dq-ring-pink-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}

.dq-ring-pink-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}

.dq-ring-pink-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}

.dq-ring-pink-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}

.dq-ring-pink-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}

.dq-ring-pink-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}

.dq-ring-pink-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}

.dq-ring-pink-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}

.dq-ring-pink-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-transparent:focus-within {
  --tw-ring-color: transparent;
}

.focus-within\:dq-ring-current:focus-within {
  --tw-ring-color: currentColor;
}

.focus-within\:dq-ring-black:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-white:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-gray-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-gray-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-gray-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-gray-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-gray-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-gray-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-gray-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-gray-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-gray-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-gray-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-red-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-red-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-red-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-red-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-red-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-red-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-red-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-red-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-red-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-red-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-yellow-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-yellow-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-yellow-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-yellow-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-yellow-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-yellow-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-yellow-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-yellow-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-yellow-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-yellow-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-green-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-green-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-green-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-green-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-green-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-green-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-green-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-green-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-green-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-green-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-blue-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-blue-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-blue-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-blue-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-blue-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-blue-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-blue-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-blue-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-blue-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-blue-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-indigo-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-indigo-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-indigo-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-indigo-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-indigo-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-indigo-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-indigo-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-indigo-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-indigo-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-indigo-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-purple-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-purple-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-purple-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-purple-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-purple-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-purple-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-purple-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-purple-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-purple-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-purple-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-pink-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-pink-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-pink-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-pink-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-pink-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-pink-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-pink-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-pink-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-pink-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}

.focus-within\:dq-ring-pink-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}

.focus\:dq-ring-transparent:focus {
  --tw-ring-color: transparent;
}

.focus\:dq-ring-current:focus {
  --tw-ring-color: currentColor;
}

.focus\:dq-ring-black:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.focus\:dq-ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus\:dq-ring-gray-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}

.focus\:dq-ring-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}

.focus\:dq-ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}

.focus\:dq-ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

.focus\:dq-ring-gray-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}

.focus\:dq-ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}

.focus\:dq-ring-gray-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}

.focus\:dq-ring-gray-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}

.focus\:dq-ring-gray-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}

.focus\:dq-ring-gray-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}

.focus\:dq-ring-red-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}

.focus\:dq-ring-red-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}

.focus\:dq-ring-red-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}

.focus\:dq-ring-red-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}

.focus\:dq-ring-red-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}

.focus\:dq-ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}

.focus\:dq-ring-red-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}

.focus\:dq-ring-red-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}

.focus\:dq-ring-red-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}

.focus\:dq-ring-red-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}

.focus\:dq-ring-yellow-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}

.focus\:dq-ring-yellow-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}

.focus\:dq-ring-yellow-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}

.focus\:dq-ring-yellow-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}

.focus\:dq-ring-yellow-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}

.focus\:dq-ring-yellow-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}

.focus\:dq-ring-yellow-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}

.focus\:dq-ring-yellow-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}

.focus\:dq-ring-yellow-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}

.focus\:dq-ring-yellow-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}

.focus\:dq-ring-green-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}

.focus\:dq-ring-green-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}

.focus\:dq-ring-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}

.focus\:dq-ring-green-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}

.focus\:dq-ring-green-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}

.focus\:dq-ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}

.focus\:dq-ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}

.focus\:dq-ring-green-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}

.focus\:dq-ring-green-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}

.focus\:dq-ring-green-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}

.focus\:dq-ring-blue-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}

.focus\:dq-ring-blue-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}

.focus\:dq-ring-blue-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}

.focus\:dq-ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}

.focus\:dq-ring-blue-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}

.focus\:dq-ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}

.focus\:dq-ring-blue-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}

.focus\:dq-ring-blue-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}

.focus\:dq-ring-blue-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}

.focus\:dq-ring-blue-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}

.focus\:dq-ring-indigo-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}

.focus\:dq-ring-indigo-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}

.focus\:dq-ring-indigo-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}

.focus\:dq-ring-indigo-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}

.focus\:dq-ring-indigo-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}

.focus\:dq-ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.focus\:dq-ring-indigo-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}

.focus\:dq-ring-indigo-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}

.focus\:dq-ring-indigo-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}

.focus\:dq-ring-indigo-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}

.focus\:dq-ring-purple-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}

.focus\:dq-ring-purple-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}

.focus\:dq-ring-purple-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}

.focus\:dq-ring-purple-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}

.focus\:dq-ring-purple-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}

.focus\:dq-ring-purple-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}

.focus\:dq-ring-purple-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}

.focus\:dq-ring-purple-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}

.focus\:dq-ring-purple-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}

.focus\:dq-ring-purple-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}

.focus\:dq-ring-pink-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}

.focus\:dq-ring-pink-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}

.focus\:dq-ring-pink-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}

.focus\:dq-ring-pink-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}

.focus\:dq-ring-pink-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}

.focus\:dq-ring-pink-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}

.focus\:dq-ring-pink-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}

.focus\:dq-ring-pink-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}

.focus\:dq-ring-pink-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}

.focus\:dq-ring-pink-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}

.dq-ring-opacity-0 {
  --tw-ring-opacity: 0;
}

.dq-ring-opacity-5 {
  --tw-ring-opacity: 0.05;
}

.dq-ring-opacity-10 {
  --tw-ring-opacity: 0.1;
}

.dq-ring-opacity-20 {
  --tw-ring-opacity: 0.2;
}

.dq-ring-opacity-25 {
  --tw-ring-opacity: 0.25;
}

.dq-ring-opacity-30 {
  --tw-ring-opacity: 0.3;
}

.dq-ring-opacity-40 {
  --tw-ring-opacity: 0.4;
}

.dq-ring-opacity-50 {
  --tw-ring-opacity: 0.5;
}

.dq-ring-opacity-60 {
  --tw-ring-opacity: 0.6;
}

.dq-ring-opacity-70 {
  --tw-ring-opacity: 0.7;
}

.dq-ring-opacity-75 {
  --tw-ring-opacity: 0.75;
}

.dq-ring-opacity-80 {
  --tw-ring-opacity: 0.8;
}

.dq-ring-opacity-90 {
  --tw-ring-opacity: 0.9;
}

.dq-ring-opacity-95 {
  --tw-ring-opacity: 0.95;
}

.dq-ring-opacity-100 {
  --tw-ring-opacity: 1;
}

.focus-within\:dq-ring-opacity-0:focus-within {
  --tw-ring-opacity: 0;
}

.focus-within\:dq-ring-opacity-5:focus-within {
  --tw-ring-opacity: 0.05;
}

.focus-within\:dq-ring-opacity-10:focus-within {
  --tw-ring-opacity: 0.1;
}

.focus-within\:dq-ring-opacity-20:focus-within {
  --tw-ring-opacity: 0.2;
}

.focus-within\:dq-ring-opacity-25:focus-within {
  --tw-ring-opacity: 0.25;
}

.focus-within\:dq-ring-opacity-30:focus-within {
  --tw-ring-opacity: 0.3;
}

.focus-within\:dq-ring-opacity-40:focus-within {
  --tw-ring-opacity: 0.4;
}

.focus-within\:dq-ring-opacity-50:focus-within {
  --tw-ring-opacity: 0.5;
}

.focus-within\:dq-ring-opacity-60:focus-within {
  --tw-ring-opacity: 0.6;
}

.focus-within\:dq-ring-opacity-70:focus-within {
  --tw-ring-opacity: 0.7;
}

.focus-within\:dq-ring-opacity-75:focus-within {
  --tw-ring-opacity: 0.75;
}

.focus-within\:dq-ring-opacity-80:focus-within {
  --tw-ring-opacity: 0.8;
}

.focus-within\:dq-ring-opacity-90:focus-within {
  --tw-ring-opacity: 0.9;
}

.focus-within\:dq-ring-opacity-95:focus-within {
  --tw-ring-opacity: 0.95;
}

.focus-within\:dq-ring-opacity-100:focus-within {
  --tw-ring-opacity: 1;
}

.focus\:dq-ring-opacity-0:focus {
  --tw-ring-opacity: 0;
}

.focus\:dq-ring-opacity-5:focus {
  --tw-ring-opacity: 0.05;
}

.focus\:dq-ring-opacity-10:focus {
  --tw-ring-opacity: 0.1;
}

.focus\:dq-ring-opacity-20:focus {
  --tw-ring-opacity: 0.2;
}

.focus\:dq-ring-opacity-25:focus {
  --tw-ring-opacity: 0.25;
}

.focus\:dq-ring-opacity-30:focus {
  --tw-ring-opacity: 0.3;
}

.focus\:dq-ring-opacity-40:focus {
  --tw-ring-opacity: 0.4;
}

.focus\:dq-ring-opacity-50:focus {
  --tw-ring-opacity: 0.5;
}

.focus\:dq-ring-opacity-60:focus {
  --tw-ring-opacity: 0.6;
}

.focus\:dq-ring-opacity-70:focus {
  --tw-ring-opacity: 0.7;
}

.focus\:dq-ring-opacity-75:focus {
  --tw-ring-opacity: 0.75;
}

.focus\:dq-ring-opacity-80:focus {
  --tw-ring-opacity: 0.8;
}

.focus\:dq-ring-opacity-90:focus {
  --tw-ring-opacity: 0.9;
}

.focus\:dq-ring-opacity-95:focus {
  --tw-ring-opacity: 0.95;
}

.focus\:dq-ring-opacity-100:focus {
  --tw-ring-opacity: 1;
}

.dq-ring-offset-0 {
  --tw-ring-offset-width: 0px;
}

.dq-ring-offset-1 {
  --tw-ring-offset-width: 1px;
}

.dq-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.dq-ring-offset-4 {
  --tw-ring-offset-width: 4px;
}

.dq-ring-offset-8 {
  --tw-ring-offset-width: 8px;
}

.focus-within\:dq-ring-offset-0:focus-within {
  --tw-ring-offset-width: 0px;
}

.focus-within\:dq-ring-offset-1:focus-within {
  --tw-ring-offset-width: 1px;
}

.focus-within\:dq-ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px;
}

.focus-within\:dq-ring-offset-4:focus-within {
  --tw-ring-offset-width: 4px;
}

.focus-within\:dq-ring-offset-8:focus-within {
  --tw-ring-offset-width: 8px;
}

.focus\:dq-ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.focus\:dq-ring-offset-1:focus {
  --tw-ring-offset-width: 1px;
}

.focus\:dq-ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:dq-ring-offset-4:focus {
  --tw-ring-offset-width: 4px;
}

.focus\:dq-ring-offset-8:focus {
  --tw-ring-offset-width: 8px;
}

.dq-ring-offset-transparent {
  --tw-ring-offset-color: transparent;
}

.dq-ring-offset-current {
  --tw-ring-offset-color: currentColor;
}

.dq-ring-offset-black {
  --tw-ring-offset-color: #000;
}

.dq-ring-offset-white {
  --tw-ring-offset-color: #fff;
}

.dq-ring-offset-gray-50 {
  --tw-ring-offset-color: #f9fafb;
}

.dq-ring-offset-gray-100 {
  --tw-ring-offset-color: #f3f4f6;
}

.dq-ring-offset-gray-200 {
  --tw-ring-offset-color: #e5e7eb;
}

.dq-ring-offset-gray-300 {
  --tw-ring-offset-color: #d1d5db;
}

.dq-ring-offset-gray-400 {
  --tw-ring-offset-color: #9ca3af;
}

.dq-ring-offset-gray-500 {
  --tw-ring-offset-color: #6b7280;
}

.dq-ring-offset-gray-600 {
  --tw-ring-offset-color: #4b5563;
}

.dq-ring-offset-gray-700 {
  --tw-ring-offset-color: #374151;
}

.dq-ring-offset-gray-800 {
  --tw-ring-offset-color: #1f2937;
}

.dq-ring-offset-gray-900 {
  --tw-ring-offset-color: #111827;
}

.dq-ring-offset-red-50 {
  --tw-ring-offset-color: #fef2f2;
}

.dq-ring-offset-red-100 {
  --tw-ring-offset-color: #fee2e2;
}

.dq-ring-offset-red-200 {
  --tw-ring-offset-color: #fecaca;
}

.dq-ring-offset-red-300 {
  --tw-ring-offset-color: #fca5a5;
}

.dq-ring-offset-red-400 {
  --tw-ring-offset-color: #f87171;
}

.dq-ring-offset-red-500 {
  --tw-ring-offset-color: #ef4444;
}

.dq-ring-offset-red-600 {
  --tw-ring-offset-color: #dc2626;
}

.dq-ring-offset-red-700 {
  --tw-ring-offset-color: #b91c1c;
}

.dq-ring-offset-red-800 {
  --tw-ring-offset-color: #991b1b;
}

.dq-ring-offset-red-900 {
  --tw-ring-offset-color: #7f1d1d;
}

.dq-ring-offset-yellow-50 {
  --tw-ring-offset-color: #fffbeb;
}

.dq-ring-offset-yellow-100 {
  --tw-ring-offset-color: #fef3c7;
}

.dq-ring-offset-yellow-200 {
  --tw-ring-offset-color: #fde68a;
}

.dq-ring-offset-yellow-300 {
  --tw-ring-offset-color: #fcd34d;
}

.dq-ring-offset-yellow-400 {
  --tw-ring-offset-color: #fbbf24;
}

.dq-ring-offset-yellow-500 {
  --tw-ring-offset-color: #f59e0b;
}

.dq-ring-offset-yellow-600 {
  --tw-ring-offset-color: #d97706;
}

.dq-ring-offset-yellow-700 {
  --tw-ring-offset-color: #b45309;
}

.dq-ring-offset-yellow-800 {
  --tw-ring-offset-color: #92400e;
}

.dq-ring-offset-yellow-900 {
  --tw-ring-offset-color: #78350f;
}

.dq-ring-offset-green-50 {
  --tw-ring-offset-color: #ecfdf5;
}

.dq-ring-offset-green-100 {
  --tw-ring-offset-color: #d1fae5;
}

.dq-ring-offset-green-200 {
  --tw-ring-offset-color: #a7f3d0;
}

.dq-ring-offset-green-300 {
  --tw-ring-offset-color: #6ee7b7;
}

.dq-ring-offset-green-400 {
  --tw-ring-offset-color: #34d399;
}

.dq-ring-offset-green-500 {
  --tw-ring-offset-color: #10b981;
}

.dq-ring-offset-green-600 {
  --tw-ring-offset-color: #059669;
}

.dq-ring-offset-green-700 {
  --tw-ring-offset-color: #047857;
}

.dq-ring-offset-green-800 {
  --tw-ring-offset-color: #065f46;
}

.dq-ring-offset-green-900 {
  --tw-ring-offset-color: #064e3b;
}

.dq-ring-offset-blue-50 {
  --tw-ring-offset-color: #eff6ff;
}

.dq-ring-offset-blue-100 {
  --tw-ring-offset-color: #dbeafe;
}

.dq-ring-offset-blue-200 {
  --tw-ring-offset-color: #bfdbfe;
}

.dq-ring-offset-blue-300 {
  --tw-ring-offset-color: #93c5fd;
}

.dq-ring-offset-blue-400 {
  --tw-ring-offset-color: #60a5fa;
}

.dq-ring-offset-blue-500 {
  --tw-ring-offset-color: #3b82f6;
}

.dq-ring-offset-blue-600 {
  --tw-ring-offset-color: #2563eb;
}

.dq-ring-offset-blue-700 {
  --tw-ring-offset-color: #1d4ed8;
}

.dq-ring-offset-blue-800 {
  --tw-ring-offset-color: #1e40af;
}

.dq-ring-offset-blue-900 {
  --tw-ring-offset-color: #1e3a8a;
}

.dq-ring-offset-indigo-50 {
  --tw-ring-offset-color: #eef2ff;
}

.dq-ring-offset-indigo-100 {
  --tw-ring-offset-color: #e0e7ff;
}

.dq-ring-offset-indigo-200 {
  --tw-ring-offset-color: #c7d2fe;
}

.dq-ring-offset-indigo-300 {
  --tw-ring-offset-color: #a5b4fc;
}

.dq-ring-offset-indigo-400 {
  --tw-ring-offset-color: #818cf8;
}

.dq-ring-offset-indigo-500 {
  --tw-ring-offset-color: #6366f1;
}

.dq-ring-offset-indigo-600 {
  --tw-ring-offset-color: #4f46e5;
}

.dq-ring-offset-indigo-700 {
  --tw-ring-offset-color: #4338ca;
}

.dq-ring-offset-indigo-800 {
  --tw-ring-offset-color: #3730a3;
}

.dq-ring-offset-indigo-900 {
  --tw-ring-offset-color: #312e81;
}

.dq-ring-offset-purple-50 {
  --tw-ring-offset-color: #f5f3ff;
}

.dq-ring-offset-purple-100 {
  --tw-ring-offset-color: #ede9fe;
}

.dq-ring-offset-purple-200 {
  --tw-ring-offset-color: #ddd6fe;
}

.dq-ring-offset-purple-300 {
  --tw-ring-offset-color: #c4b5fd;
}

.dq-ring-offset-purple-400 {
  --tw-ring-offset-color: #a78bfa;
}

.dq-ring-offset-purple-500 {
  --tw-ring-offset-color: #8b5cf6;
}

.dq-ring-offset-purple-600 {
  --tw-ring-offset-color: #7c3aed;
}

.dq-ring-offset-purple-700 {
  --tw-ring-offset-color: #6d28d9;
}

.dq-ring-offset-purple-800 {
  --tw-ring-offset-color: #5b21b6;
}

.dq-ring-offset-purple-900 {
  --tw-ring-offset-color: #4c1d95;
}

.dq-ring-offset-pink-50 {
  --tw-ring-offset-color: #fdf2f8;
}

.dq-ring-offset-pink-100 {
  --tw-ring-offset-color: #fce7f3;
}

.dq-ring-offset-pink-200 {
  --tw-ring-offset-color: #fbcfe8;
}

.dq-ring-offset-pink-300 {
  --tw-ring-offset-color: #f9a8d4;
}

.dq-ring-offset-pink-400 {
  --tw-ring-offset-color: #f472b6;
}

.dq-ring-offset-pink-500 {
  --tw-ring-offset-color: #ec4899;
}

.dq-ring-offset-pink-600 {
  --tw-ring-offset-color: #db2777;
}

.dq-ring-offset-pink-700 {
  --tw-ring-offset-color: #be185d;
}

.dq-ring-offset-pink-800 {
  --tw-ring-offset-color: #9d174d;
}

.dq-ring-offset-pink-900 {
  --tw-ring-offset-color: #831843;
}

.focus-within\:dq-ring-offset-transparent:focus-within {
  --tw-ring-offset-color: transparent;
}

.focus-within\:dq-ring-offset-current:focus-within {
  --tw-ring-offset-color: currentColor;
}

.focus-within\:dq-ring-offset-black:focus-within {
  --tw-ring-offset-color: #000;
}

.focus-within\:dq-ring-offset-white:focus-within {
  --tw-ring-offset-color: #fff;
}

.focus-within\:dq-ring-offset-gray-50:focus-within {
  --tw-ring-offset-color: #f9fafb;
}

.focus-within\:dq-ring-offset-gray-100:focus-within {
  --tw-ring-offset-color: #f3f4f6;
}

.focus-within\:dq-ring-offset-gray-200:focus-within {
  --tw-ring-offset-color: #e5e7eb;
}

.focus-within\:dq-ring-offset-gray-300:focus-within {
  --tw-ring-offset-color: #d1d5db;
}

.focus-within\:dq-ring-offset-gray-400:focus-within {
  --tw-ring-offset-color: #9ca3af;
}

.focus-within\:dq-ring-offset-gray-500:focus-within {
  --tw-ring-offset-color: #6b7280;
}

.focus-within\:dq-ring-offset-gray-600:focus-within {
  --tw-ring-offset-color: #4b5563;
}

.focus-within\:dq-ring-offset-gray-700:focus-within {
  --tw-ring-offset-color: #374151;
}

.focus-within\:dq-ring-offset-gray-800:focus-within {
  --tw-ring-offset-color: #1f2937;
}

.focus-within\:dq-ring-offset-gray-900:focus-within {
  --tw-ring-offset-color: #111827;
}

.focus-within\:dq-ring-offset-red-50:focus-within {
  --tw-ring-offset-color: #fef2f2;
}

.focus-within\:dq-ring-offset-red-100:focus-within {
  --tw-ring-offset-color: #fee2e2;
}

.focus-within\:dq-ring-offset-red-200:focus-within {
  --tw-ring-offset-color: #fecaca;
}

.focus-within\:dq-ring-offset-red-300:focus-within {
  --tw-ring-offset-color: #fca5a5;
}

.focus-within\:dq-ring-offset-red-400:focus-within {
  --tw-ring-offset-color: #f87171;
}

.focus-within\:dq-ring-offset-red-500:focus-within {
  --tw-ring-offset-color: #ef4444;
}

.focus-within\:dq-ring-offset-red-600:focus-within {
  --tw-ring-offset-color: #dc2626;
}

.focus-within\:dq-ring-offset-red-700:focus-within {
  --tw-ring-offset-color: #b91c1c;
}

.focus-within\:dq-ring-offset-red-800:focus-within {
  --tw-ring-offset-color: #991b1b;
}

.focus-within\:dq-ring-offset-red-900:focus-within {
  --tw-ring-offset-color: #7f1d1d;
}

.focus-within\:dq-ring-offset-yellow-50:focus-within {
  --tw-ring-offset-color: #fffbeb;
}

.focus-within\:dq-ring-offset-yellow-100:focus-within {
  --tw-ring-offset-color: #fef3c7;
}

.focus-within\:dq-ring-offset-yellow-200:focus-within {
  --tw-ring-offset-color: #fde68a;
}

.focus-within\:dq-ring-offset-yellow-300:focus-within {
  --tw-ring-offset-color: #fcd34d;
}

.focus-within\:dq-ring-offset-yellow-400:focus-within {
  --tw-ring-offset-color: #fbbf24;
}

.focus-within\:dq-ring-offset-yellow-500:focus-within {
  --tw-ring-offset-color: #f59e0b;
}

.focus-within\:dq-ring-offset-yellow-600:focus-within {
  --tw-ring-offset-color: #d97706;
}

.focus-within\:dq-ring-offset-yellow-700:focus-within {
  --tw-ring-offset-color: #b45309;
}

.focus-within\:dq-ring-offset-yellow-800:focus-within {
  --tw-ring-offset-color: #92400e;
}

.focus-within\:dq-ring-offset-yellow-900:focus-within {
  --tw-ring-offset-color: #78350f;
}

.focus-within\:dq-ring-offset-green-50:focus-within {
  --tw-ring-offset-color: #ecfdf5;
}

.focus-within\:dq-ring-offset-green-100:focus-within {
  --tw-ring-offset-color: #d1fae5;
}

.focus-within\:dq-ring-offset-green-200:focus-within {
  --tw-ring-offset-color: #a7f3d0;
}

.focus-within\:dq-ring-offset-green-300:focus-within {
  --tw-ring-offset-color: #6ee7b7;
}

.focus-within\:dq-ring-offset-green-400:focus-within {
  --tw-ring-offset-color: #34d399;
}

.focus-within\:dq-ring-offset-green-500:focus-within {
  --tw-ring-offset-color: #10b981;
}

.focus-within\:dq-ring-offset-green-600:focus-within {
  --tw-ring-offset-color: #059669;
}

.focus-within\:dq-ring-offset-green-700:focus-within {
  --tw-ring-offset-color: #047857;
}

.focus-within\:dq-ring-offset-green-800:focus-within {
  --tw-ring-offset-color: #065f46;
}

.focus-within\:dq-ring-offset-green-900:focus-within {
  --tw-ring-offset-color: #064e3b;
}

.focus-within\:dq-ring-offset-blue-50:focus-within {
  --tw-ring-offset-color: #eff6ff;
}

.focus-within\:dq-ring-offset-blue-100:focus-within {
  --tw-ring-offset-color: #dbeafe;
}

.focus-within\:dq-ring-offset-blue-200:focus-within {
  --tw-ring-offset-color: #bfdbfe;
}

.focus-within\:dq-ring-offset-blue-300:focus-within {
  --tw-ring-offset-color: #93c5fd;
}

.focus-within\:dq-ring-offset-blue-400:focus-within {
  --tw-ring-offset-color: #60a5fa;
}

.focus-within\:dq-ring-offset-blue-500:focus-within {
  --tw-ring-offset-color: #3b82f6;
}

.focus-within\:dq-ring-offset-blue-600:focus-within {
  --tw-ring-offset-color: #2563eb;
}

.focus-within\:dq-ring-offset-blue-700:focus-within {
  --tw-ring-offset-color: #1d4ed8;
}

.focus-within\:dq-ring-offset-blue-800:focus-within {
  --tw-ring-offset-color: #1e40af;
}

.focus-within\:dq-ring-offset-blue-900:focus-within {
  --tw-ring-offset-color: #1e3a8a;
}

.focus-within\:dq-ring-offset-indigo-50:focus-within {
  --tw-ring-offset-color: #eef2ff;
}

.focus-within\:dq-ring-offset-indigo-100:focus-within {
  --tw-ring-offset-color: #e0e7ff;
}

.focus-within\:dq-ring-offset-indigo-200:focus-within {
  --tw-ring-offset-color: #c7d2fe;
}

.focus-within\:dq-ring-offset-indigo-300:focus-within {
  --tw-ring-offset-color: #a5b4fc;
}

.focus-within\:dq-ring-offset-indigo-400:focus-within {
  --tw-ring-offset-color: #818cf8;
}

.focus-within\:dq-ring-offset-indigo-500:focus-within {
  --tw-ring-offset-color: #6366f1;
}

.focus-within\:dq-ring-offset-indigo-600:focus-within {
  --tw-ring-offset-color: #4f46e5;
}

.focus-within\:dq-ring-offset-indigo-700:focus-within {
  --tw-ring-offset-color: #4338ca;
}

.focus-within\:dq-ring-offset-indigo-800:focus-within {
  --tw-ring-offset-color: #3730a3;
}

.focus-within\:dq-ring-offset-indigo-900:focus-within {
  --tw-ring-offset-color: #312e81;
}

.focus-within\:dq-ring-offset-purple-50:focus-within {
  --tw-ring-offset-color: #f5f3ff;
}

.focus-within\:dq-ring-offset-purple-100:focus-within {
  --tw-ring-offset-color: #ede9fe;
}

.focus-within\:dq-ring-offset-purple-200:focus-within {
  --tw-ring-offset-color: #ddd6fe;
}

.focus-within\:dq-ring-offset-purple-300:focus-within {
  --tw-ring-offset-color: #c4b5fd;
}

.focus-within\:dq-ring-offset-purple-400:focus-within {
  --tw-ring-offset-color: #a78bfa;
}

.focus-within\:dq-ring-offset-purple-500:focus-within {
  --tw-ring-offset-color: #8b5cf6;
}

.focus-within\:dq-ring-offset-purple-600:focus-within {
  --tw-ring-offset-color: #7c3aed;
}

.focus-within\:dq-ring-offset-purple-700:focus-within {
  --tw-ring-offset-color: #6d28d9;
}

.focus-within\:dq-ring-offset-purple-800:focus-within {
  --tw-ring-offset-color: #5b21b6;
}

.focus-within\:dq-ring-offset-purple-900:focus-within {
  --tw-ring-offset-color: #4c1d95;
}

.focus-within\:dq-ring-offset-pink-50:focus-within {
  --tw-ring-offset-color: #fdf2f8;
}

.focus-within\:dq-ring-offset-pink-100:focus-within {
  --tw-ring-offset-color: #fce7f3;
}

.focus-within\:dq-ring-offset-pink-200:focus-within {
  --tw-ring-offset-color: #fbcfe8;
}

.focus-within\:dq-ring-offset-pink-300:focus-within {
  --tw-ring-offset-color: #f9a8d4;
}

.focus-within\:dq-ring-offset-pink-400:focus-within {
  --tw-ring-offset-color: #f472b6;
}

.focus-within\:dq-ring-offset-pink-500:focus-within {
  --tw-ring-offset-color: #ec4899;
}

.focus-within\:dq-ring-offset-pink-600:focus-within {
  --tw-ring-offset-color: #db2777;
}

.focus-within\:dq-ring-offset-pink-700:focus-within {
  --tw-ring-offset-color: #be185d;
}

.focus-within\:dq-ring-offset-pink-800:focus-within {
  --tw-ring-offset-color: #9d174d;
}

.focus-within\:dq-ring-offset-pink-900:focus-within {
  --tw-ring-offset-color: #831843;
}

.focus\:dq-ring-offset-transparent:focus {
  --tw-ring-offset-color: transparent;
}

.focus\:dq-ring-offset-current:focus {
  --tw-ring-offset-color: currentColor;
}

.focus\:dq-ring-offset-black:focus {
  --tw-ring-offset-color: #000;
}

.focus\:dq-ring-offset-white:focus {
  --tw-ring-offset-color: #fff;
}

.focus\:dq-ring-offset-gray-50:focus {
  --tw-ring-offset-color: #f9fafb;
}

.focus\:dq-ring-offset-gray-100:focus {
  --tw-ring-offset-color: #f3f4f6;
}

.focus\:dq-ring-offset-gray-200:focus {
  --tw-ring-offset-color: #e5e7eb;
}

.focus\:dq-ring-offset-gray-300:focus {
  --tw-ring-offset-color: #d1d5db;
}

.focus\:dq-ring-offset-gray-400:focus {
  --tw-ring-offset-color: #9ca3af;
}

.focus\:dq-ring-offset-gray-500:focus {
  --tw-ring-offset-color: #6b7280;
}

.focus\:dq-ring-offset-gray-600:focus {
  --tw-ring-offset-color: #4b5563;
}

.focus\:dq-ring-offset-gray-700:focus {
  --tw-ring-offset-color: #374151;
}

.focus\:dq-ring-offset-gray-800:focus {
  --tw-ring-offset-color: #1f2937;
}

.focus\:dq-ring-offset-gray-900:focus {
  --tw-ring-offset-color: #111827;
}

.focus\:dq-ring-offset-red-50:focus {
  --tw-ring-offset-color: #fef2f2;
}

.focus\:dq-ring-offset-red-100:focus {
  --tw-ring-offset-color: #fee2e2;
}

.focus\:dq-ring-offset-red-200:focus {
  --tw-ring-offset-color: #fecaca;
}

.focus\:dq-ring-offset-red-300:focus {
  --tw-ring-offset-color: #fca5a5;
}

.focus\:dq-ring-offset-red-400:focus {
  --tw-ring-offset-color: #f87171;
}

.focus\:dq-ring-offset-red-500:focus {
  --tw-ring-offset-color: #ef4444;
}

.focus\:dq-ring-offset-red-600:focus {
  --tw-ring-offset-color: #dc2626;
}

.focus\:dq-ring-offset-red-700:focus {
  --tw-ring-offset-color: #b91c1c;
}

.focus\:dq-ring-offset-red-800:focus {
  --tw-ring-offset-color: #991b1b;
}

.focus\:dq-ring-offset-red-900:focus {
  --tw-ring-offset-color: #7f1d1d;
}

.focus\:dq-ring-offset-yellow-50:focus {
  --tw-ring-offset-color: #fffbeb;
}

.focus\:dq-ring-offset-yellow-100:focus {
  --tw-ring-offset-color: #fef3c7;
}

.focus\:dq-ring-offset-yellow-200:focus {
  --tw-ring-offset-color: #fde68a;
}

.focus\:dq-ring-offset-yellow-300:focus {
  --tw-ring-offset-color: #fcd34d;
}

.focus\:dq-ring-offset-yellow-400:focus {
  --tw-ring-offset-color: #fbbf24;
}

.focus\:dq-ring-offset-yellow-500:focus {
  --tw-ring-offset-color: #f59e0b;
}

.focus\:dq-ring-offset-yellow-600:focus {
  --tw-ring-offset-color: #d97706;
}

.focus\:dq-ring-offset-yellow-700:focus {
  --tw-ring-offset-color: #b45309;
}

.focus\:dq-ring-offset-yellow-800:focus {
  --tw-ring-offset-color: #92400e;
}

.focus\:dq-ring-offset-yellow-900:focus {
  --tw-ring-offset-color: #78350f;
}

.focus\:dq-ring-offset-green-50:focus {
  --tw-ring-offset-color: #ecfdf5;
}

.focus\:dq-ring-offset-green-100:focus {
  --tw-ring-offset-color: #d1fae5;
}

.focus\:dq-ring-offset-green-200:focus {
  --tw-ring-offset-color: #a7f3d0;
}

.focus\:dq-ring-offset-green-300:focus {
  --tw-ring-offset-color: #6ee7b7;
}

.focus\:dq-ring-offset-green-400:focus {
  --tw-ring-offset-color: #34d399;
}

.focus\:dq-ring-offset-green-500:focus {
  --tw-ring-offset-color: #10b981;
}

.focus\:dq-ring-offset-green-600:focus {
  --tw-ring-offset-color: #059669;
}

.focus\:dq-ring-offset-green-700:focus {
  --tw-ring-offset-color: #047857;
}

.focus\:dq-ring-offset-green-800:focus {
  --tw-ring-offset-color: #065f46;
}

.focus\:dq-ring-offset-green-900:focus {
  --tw-ring-offset-color: #064e3b;
}

.focus\:dq-ring-offset-blue-50:focus {
  --tw-ring-offset-color: #eff6ff;
}

.focus\:dq-ring-offset-blue-100:focus {
  --tw-ring-offset-color: #dbeafe;
}

.focus\:dq-ring-offset-blue-200:focus {
  --tw-ring-offset-color: #bfdbfe;
}

.focus\:dq-ring-offset-blue-300:focus {
  --tw-ring-offset-color: #93c5fd;
}

.focus\:dq-ring-offset-blue-400:focus {
  --tw-ring-offset-color: #60a5fa;
}

.focus\:dq-ring-offset-blue-500:focus {
  --tw-ring-offset-color: #3b82f6;
}

.focus\:dq-ring-offset-blue-600:focus {
  --tw-ring-offset-color: #2563eb;
}

.focus\:dq-ring-offset-blue-700:focus {
  --tw-ring-offset-color: #1d4ed8;
}

.focus\:dq-ring-offset-blue-800:focus {
  --tw-ring-offset-color: #1e40af;
}

.focus\:dq-ring-offset-blue-900:focus {
  --tw-ring-offset-color: #1e3a8a;
}

.focus\:dq-ring-offset-indigo-50:focus {
  --tw-ring-offset-color: #eef2ff;
}

.focus\:dq-ring-offset-indigo-100:focus {
  --tw-ring-offset-color: #e0e7ff;
}

.focus\:dq-ring-offset-indigo-200:focus {
  --tw-ring-offset-color: #c7d2fe;
}

.focus\:dq-ring-offset-indigo-300:focus {
  --tw-ring-offset-color: #a5b4fc;
}

.focus\:dq-ring-offset-indigo-400:focus {
  --tw-ring-offset-color: #818cf8;
}

.focus\:dq-ring-offset-indigo-500:focus {
  --tw-ring-offset-color: #6366f1;
}

.focus\:dq-ring-offset-indigo-600:focus {
  --tw-ring-offset-color: #4f46e5;
}

.focus\:dq-ring-offset-indigo-700:focus {
  --tw-ring-offset-color: #4338ca;
}

.focus\:dq-ring-offset-indigo-800:focus {
  --tw-ring-offset-color: #3730a3;
}

.focus\:dq-ring-offset-indigo-900:focus {
  --tw-ring-offset-color: #312e81;
}

.focus\:dq-ring-offset-purple-50:focus {
  --tw-ring-offset-color: #f5f3ff;
}

.focus\:dq-ring-offset-purple-100:focus {
  --tw-ring-offset-color: #ede9fe;
}

.focus\:dq-ring-offset-purple-200:focus {
  --tw-ring-offset-color: #ddd6fe;
}

.focus\:dq-ring-offset-purple-300:focus {
  --tw-ring-offset-color: #c4b5fd;
}

.focus\:dq-ring-offset-purple-400:focus {
  --tw-ring-offset-color: #a78bfa;
}

.focus\:dq-ring-offset-purple-500:focus {
  --tw-ring-offset-color: #8b5cf6;
}

.focus\:dq-ring-offset-purple-600:focus {
  --tw-ring-offset-color: #7c3aed;
}

.focus\:dq-ring-offset-purple-700:focus {
  --tw-ring-offset-color: #6d28d9;
}

.focus\:dq-ring-offset-purple-800:focus {
  --tw-ring-offset-color: #5b21b6;
}

.focus\:dq-ring-offset-purple-900:focus {
  --tw-ring-offset-color: #4c1d95;
}

.focus\:dq-ring-offset-pink-50:focus {
  --tw-ring-offset-color: #fdf2f8;
}

.focus\:dq-ring-offset-pink-100:focus {
  --tw-ring-offset-color: #fce7f3;
}

.focus\:dq-ring-offset-pink-200:focus {
  --tw-ring-offset-color: #fbcfe8;
}

.focus\:dq-ring-offset-pink-300:focus {
  --tw-ring-offset-color: #f9a8d4;
}

.focus\:dq-ring-offset-pink-400:focus {
  --tw-ring-offset-color: #f472b6;
}

.focus\:dq-ring-offset-pink-500:focus {
  --tw-ring-offset-color: #ec4899;
}

.focus\:dq-ring-offset-pink-600:focus {
  --tw-ring-offset-color: #db2777;
}

.focus\:dq-ring-offset-pink-700:focus {
  --tw-ring-offset-color: #be185d;
}

.focus\:dq-ring-offset-pink-800:focus {
  --tw-ring-offset-color: #9d174d;
}

.focus\:dq-ring-offset-pink-900:focus {
  --tw-ring-offset-color: #831843;
}

.dq-filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.dq-filter-none {
  -webkit-filter: none;
          filter: none;
}

.dq-blur-0 {
  --tw-blur: blur(0);
}

.dq-blur-none {
  --tw-blur: blur(0);
}

.dq-blur-sm {
  --tw-blur: blur(4px);
}

.dq-blur {
  --tw-blur: blur(8px);
}

.dq-blur-md {
  --tw-blur: blur(12px);
}

.dq-blur-lg {
  --tw-blur: blur(16px);
}

.dq-blur-xl {
  --tw-blur: blur(24px);
}

.dq-blur-2xl {
  --tw-blur: blur(40px);
}

.dq-blur-3xl {
  --tw-blur: blur(64px);
}

.dq-brightness-0 {
  --tw-brightness: brightness(0);
}

.dq-brightness-50 {
  --tw-brightness: brightness(.5);
}

.dq-brightness-75 {
  --tw-brightness: brightness(.75);
}

.dq-brightness-90 {
  --tw-brightness: brightness(.9);
}

.dq-brightness-95 {
  --tw-brightness: brightness(.95);
}

.dq-brightness-100 {
  --tw-brightness: brightness(1);
}

.dq-brightness-105 {
  --tw-brightness: brightness(1.05);
}

.dq-brightness-110 {
  --tw-brightness: brightness(1.1);
}

.dq-brightness-125 {
  --tw-brightness: brightness(1.25);
}

.dq-brightness-150 {
  --tw-brightness: brightness(1.5);
}

.dq-brightness-200 {
  --tw-brightness: brightness(2);
}

.dq-contrast-0 {
  --tw-contrast: contrast(0);
}

.dq-contrast-50 {
  --tw-contrast: contrast(.5);
}

.dq-contrast-75 {
  --tw-contrast: contrast(.75);
}

.dq-contrast-100 {
  --tw-contrast: contrast(1);
}

.dq-contrast-125 {
  --tw-contrast: contrast(1.25);
}

.dq-contrast-150 {
  --tw-contrast: contrast(1.5);
}

.dq-contrast-200 {
  --tw-contrast: contrast(2);
}

.dq-drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
}

.dq-drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
}

.dq-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
}

.dq-drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
}

.dq-drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
}

.dq-drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
}

.dq-drop-shadow-none {
  --tw-drop-shadow: drop-shadow(0 0 #0000);
}

.dq-grayscale-0 {
  --tw-grayscale: grayscale(0);
}

.dq-grayscale {
  --tw-grayscale: grayscale(100%);
}

.dq-hue-rotate-0 {
  --tw-hue-rotate: hue-rotate(0deg);
}

.dq-hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(15deg);
}

.dq-hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(30deg);
}

.dq-hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(60deg);
}

.dq-hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(90deg);
}

.dq-hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(180deg);
}

.dq--hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(-180deg);
}

.dq--hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(-90deg);
}

.dq--hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(-60deg);
}

.dq--hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(-30deg);
}

.dq--hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(-15deg);
}

.dq-invert-0 {
  --tw-invert: invert(0);
}

.dq-invert {
  --tw-invert: invert(100%);
}

.dq-saturate-0 {
  --tw-saturate: saturate(0);
}

.dq-saturate-50 {
  --tw-saturate: saturate(.5);
}

.dq-saturate-100 {
  --tw-saturate: saturate(1);
}

.dq-saturate-150 {
  --tw-saturate: saturate(1.5);
}

.dq-saturate-200 {
  --tw-saturate: saturate(2);
}

.dq-sepia-0 {
  --tw-sepia: sepia(0);
}

.dq-sepia {
  --tw-sepia: sepia(100%);
}

.dq-backdrop-filter {
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.dq-backdrop-filter-none {
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}

.dq-backdrop-blur-0 {
  --tw-backdrop-blur: blur(0);
}

.dq-backdrop-blur-none {
  --tw-backdrop-blur: blur(0);
}

.dq-backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
}

.dq-backdrop-blur {
  --tw-backdrop-blur: blur(8px);
}

.dq-backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
}

.dq-backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
}

.dq-backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
}

.dq-backdrop-blur-2xl {
  --tw-backdrop-blur: blur(40px);
}

.dq-backdrop-blur-3xl {
  --tw-backdrop-blur: blur(64px);
}

.dq-backdrop-brightness-0 {
  --tw-backdrop-brightness: brightness(0);
}

.dq-backdrop-brightness-50 {
  --tw-backdrop-brightness: brightness(.5);
}

.dq-backdrop-brightness-75 {
  --tw-backdrop-brightness: brightness(.75);
}

.dq-backdrop-brightness-90 {
  --tw-backdrop-brightness: brightness(.9);
}

.dq-backdrop-brightness-95 {
  --tw-backdrop-brightness: brightness(.95);
}

.dq-backdrop-brightness-100 {
  --tw-backdrop-brightness: brightness(1);
}

.dq-backdrop-brightness-105 {
  --tw-backdrop-brightness: brightness(1.05);
}

.dq-backdrop-brightness-110 {
  --tw-backdrop-brightness: brightness(1.1);
}

.dq-backdrop-brightness-125 {
  --tw-backdrop-brightness: brightness(1.25);
}

.dq-backdrop-brightness-150 {
  --tw-backdrop-brightness: brightness(1.5);
}

.dq-backdrop-brightness-200 {
  --tw-backdrop-brightness: brightness(2);
}

.dq-backdrop-contrast-0 {
  --tw-backdrop-contrast: contrast(0);
}

.dq-backdrop-contrast-50 {
  --tw-backdrop-contrast: contrast(.5);
}

.dq-backdrop-contrast-75 {
  --tw-backdrop-contrast: contrast(.75);
}

.dq-backdrop-contrast-100 {
  --tw-backdrop-contrast: contrast(1);
}

.dq-backdrop-contrast-125 {
  --tw-backdrop-contrast: contrast(1.25);
}

.dq-backdrop-contrast-150 {
  --tw-backdrop-contrast: contrast(1.5);
}

.dq-backdrop-contrast-200 {
  --tw-backdrop-contrast: contrast(2);
}

.dq-backdrop-grayscale-0 {
  --tw-backdrop-grayscale: grayscale(0);
}

.dq-backdrop-grayscale {
  --tw-backdrop-grayscale: grayscale(100%);
}

.dq-backdrop-hue-rotate-0 {
  --tw-backdrop-hue-rotate: hue-rotate(0deg);
}

.dq-backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(15deg);
}

.dq-backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(30deg);
}

.dq-backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(60deg);
}

.dq-backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(90deg);
}

.dq-backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(180deg);
}

.dq--backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(-180deg);
}

.dq--backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(-90deg);
}

.dq--backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(-60deg);
}

.dq--backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(-30deg);
}

.dq--backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(-15deg);
}

.dq-backdrop-invert-0 {
  --tw-backdrop-invert: invert(0);
}

.dq-backdrop-invert {
  --tw-backdrop-invert: invert(100%);
}

.dq-backdrop-opacity-0 {
  --tw-backdrop-opacity: opacity(0);
}

.dq-backdrop-opacity-5 {
  --tw-backdrop-opacity: opacity(0.05);
}

.dq-backdrop-opacity-10 {
  --tw-backdrop-opacity: opacity(0.1);
}

.dq-backdrop-opacity-20 {
  --tw-backdrop-opacity: opacity(0.2);
}

.dq-backdrop-opacity-25 {
  --tw-backdrop-opacity: opacity(0.25);
}

.dq-backdrop-opacity-30 {
  --tw-backdrop-opacity: opacity(0.3);
}

.dq-backdrop-opacity-40 {
  --tw-backdrop-opacity: opacity(0.4);
}

.dq-backdrop-opacity-50 {
  --tw-backdrop-opacity: opacity(0.5);
}

.dq-backdrop-opacity-60 {
  --tw-backdrop-opacity: opacity(0.6);
}

.dq-backdrop-opacity-70 {
  --tw-backdrop-opacity: opacity(0.7);
}

.dq-backdrop-opacity-75 {
  --tw-backdrop-opacity: opacity(0.75);
}

.dq-backdrop-opacity-80 {
  --tw-backdrop-opacity: opacity(0.8);
}

.dq-backdrop-opacity-90 {
  --tw-backdrop-opacity: opacity(0.9);
}

.dq-backdrop-opacity-95 {
  --tw-backdrop-opacity: opacity(0.95);
}

.dq-backdrop-opacity-100 {
  --tw-backdrop-opacity: opacity(1);
}

.dq-backdrop-saturate-0 {
  --tw-backdrop-saturate: saturate(0);
}

.dq-backdrop-saturate-50 {
  --tw-backdrop-saturate: saturate(.5);
}

.dq-backdrop-saturate-100 {
  --tw-backdrop-saturate: saturate(1);
}

.dq-backdrop-saturate-150 {
  --tw-backdrop-saturate: saturate(1.5);
}

.dq-backdrop-saturate-200 {
  --tw-backdrop-saturate: saturate(2);
}

.dq-backdrop-sepia-0 {
  --tw-backdrop-sepia: sepia(0);
}

.dq-backdrop-sepia {
  --tw-backdrop-sepia: sepia(100%);
}

.dq-transition-none {
  transition-property: none;
}

.dq-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.dq-transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.dq-transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.dq-transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.dq-transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.dq-transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.dq-delay-75 {
  transition-delay: 75ms;
}

.dq-delay-100 {
  transition-delay: 100ms;
}

.dq-delay-150 {
  transition-delay: 150ms;
}

.dq-delay-200 {
  transition-delay: 200ms;
}

.dq-delay-300 {
  transition-delay: 300ms;
}

.dq-delay-500 {
  transition-delay: 500ms;
}

.dq-delay-700 {
  transition-delay: 700ms;
}

.dq-delay-1000 {
  transition-delay: 1000ms;
}

.dq-duration-75 {
  transition-duration: 75ms;
}

.dq-duration-100 {
  transition-duration: 100ms;
}

.dq-duration-150 {
  transition-duration: 150ms;
}

.dq-duration-200 {
  transition-duration: 200ms;
}

.dq-duration-300 {
  transition-duration: 300ms;
}

.dq-duration-500 {
  transition-duration: 500ms;
}

.dq-duration-700 {
  transition-duration: 700ms;
}

.dq-duration-1000 {
  transition-duration: 1000ms;
}

.dq-ease-linear {
  transition-timing-function: linear;
}

.dq-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.dq-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.dq-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.dq-content-none {
  content: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDataGrid-columnSeparator {
  visibility: hidden;
}

/* Third party libraries */

.rdw-editor-wrapper {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(196, 196, 196);
}

@media (min-width: 640px) {
  .sm\:dq-container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .sm\:dq-container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .sm\:dq-container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .sm\:dq-container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .sm\:dq-container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .sm\:dq-container {
      max-width: 1536px;
    }
  }

  .sm\:dq-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .sm\:dq-not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .sm\:focus-within\:dq-sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .sm\:focus-within\:dq-not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .sm\:focus\:dq-sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .sm\:focus\:dq-not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .sm\:dq-pointer-events-none {
    pointer-events: none;
  }

  .sm\:dq-pointer-events-auto {
    pointer-events: auto;
  }

  .sm\:dq-visible {
    visibility: visible;
  }

  .sm\:dq-invisible {
    visibility: hidden;
  }

  .sm\:dq-static {
    position: static;
  }

  .sm\:dq-fixed {
    position: fixed;
  }

  .sm\:dq-absolute {
    position: absolute;
  }

  .sm\:dq-relative {
    position: relative;
  }

  .sm\:dq-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .sm\:dq-inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .sm\:dq-inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }

  .sm\:dq-inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }

  .sm\:dq-inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }

  .sm\:dq-inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }

  .sm\:dq-inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }

  .sm\:dq-inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }

  .sm\:dq-inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }

  .sm\:dq-inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }

  .sm\:dq-inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }

  .sm\:dq-inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }

  .sm\:dq-inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }

  .sm\:dq-inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }

  .sm\:dq-inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }

  .sm\:dq-inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }

  .sm\:dq-inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }

  .sm\:dq-inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }

  .sm\:dq-inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }

  .sm\:dq-inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }

  .sm\:dq-inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }

  .sm\:dq-inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }

  .sm\:dq-inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }

  .sm\:dq-inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }

  .sm\:dq-inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }

  .sm\:dq-inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }

  .sm\:dq-inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }

  .sm\:dq-inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }

  .sm\:dq-inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }

  .sm\:dq-inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }

  .sm\:dq-inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }

  .sm\:dq-inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .sm\:dq-inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }

  .sm\:dq-inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }

  .sm\:dq-inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }

  .sm\:dq-inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }

  .sm\:dq-inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }

  .sm\:dq--inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .sm\:dq--inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }

  .sm\:dq--inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }

  .sm\:dq--inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }

  .sm\:dq--inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }

  .sm\:dq--inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }

  .sm\:dq--inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }

  .sm\:dq--inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }

  .sm\:dq--inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }

  .sm\:dq--inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }

  .sm\:dq--inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }

  .sm\:dq--inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }

  .sm\:dq--inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }

  .sm\:dq--inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }

  .sm\:dq--inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }

  .sm\:dq--inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }

  .sm\:dq--inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }

  .sm\:dq--inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }

  .sm\:dq--inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }

  .sm\:dq--inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }

  .sm\:dq--inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }

  .sm\:dq--inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }

  .sm\:dq--inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }

  .sm\:dq--inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }

  .sm\:dq--inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }

  .sm\:dq--inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }

  .sm\:dq--inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }

  .sm\:dq--inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }

  .sm\:dq--inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }

  .sm\:dq--inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }

  .sm\:dq--inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }

  .sm\:dq--inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }

  .sm\:dq--inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }

  .sm\:dq--inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }

  .sm\:dq--inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }

  .sm\:dq-inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }

  .sm\:dq-inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }

  .sm\:dq-inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }

  .sm\:dq-inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }

  .sm\:dq-inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }

  .sm\:dq-inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }

  .sm\:dq-inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }

  .sm\:dq--inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }

  .sm\:dq--inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }

  .sm\:dq--inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }

  .sm\:dq--inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }

  .sm\:dq--inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }

  .sm\:dq--inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }

  .sm\:dq--inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }

  .sm\:dq-inset-x-0 {
    left: 0px;
    right: 0px;
  }

  .sm\:dq-inset-x-1 {
    left: 0.25rem;
    right: 0.25rem;
  }

  .sm\:dq-inset-x-2 {
    left: 0.5rem;
    right: 0.5rem;
  }

  .sm\:dq-inset-x-3 {
    left: 0.75rem;
    right: 0.75rem;
  }

  .sm\:dq-inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .sm\:dq-inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .sm\:dq-inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .sm\:dq-inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .sm\:dq-inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .sm\:dq-inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .sm\:dq-inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .sm\:dq-inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .sm\:dq-inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .sm\:dq-inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .sm\:dq-inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .sm\:dq-inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .sm\:dq-inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .sm\:dq-inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .sm\:dq-inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .sm\:dq-inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .sm\:dq-inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .sm\:dq-inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .sm\:dq-inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .sm\:dq-inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .sm\:dq-inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .sm\:dq-inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .sm\:dq-inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .sm\:dq-inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .sm\:dq-inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .sm\:dq-inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .sm\:dq-inset-x-auto {
    left: auto;
    right: auto;
  }

  .sm\:dq-inset-x-px {
    left: 1px;
    right: 1px;
  }

  .sm\:dq-inset-x-0\.5 {
    left: 0.125rem;
    right: 0.125rem;
  }

  .sm\:dq-inset-x-1\.5 {
    left: 0.375rem;
    right: 0.375rem;
  }

  .sm\:dq-inset-x-2\.5 {
    left: 0.625rem;
    right: 0.625rem;
  }

  .sm\:dq-inset-x-3\.5 {
    left: 0.875rem;
    right: 0.875rem;
  }

  .sm\:dq--inset-x-0 {
    left: 0px;
    right: 0px;
  }

  .sm\:dq--inset-x-1 {
    left: -0.25rem;
    right: -0.25rem;
  }

  .sm\:dq--inset-x-2 {
    left: -0.5rem;
    right: -0.5rem;
  }

  .sm\:dq--inset-x-3 {
    left: -0.75rem;
    right: -0.75rem;
  }

  .sm\:dq--inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .sm\:dq--inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .sm\:dq--inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .sm\:dq--inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .sm\:dq--inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .sm\:dq--inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .sm\:dq--inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .sm\:dq--inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .sm\:dq--inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .sm\:dq--inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .sm\:dq--inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .sm\:dq--inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .sm\:dq--inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .sm\:dq--inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .sm\:dq--inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .sm\:dq--inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .sm\:dq--inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .sm\:dq--inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .sm\:dq--inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .sm\:dq--inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .sm\:dq--inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .sm\:dq--inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .sm\:dq--inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .sm\:dq--inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .sm\:dq--inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .sm\:dq--inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .sm\:dq--inset-x-px {
    left: -1px;
    right: -1px;
  }

  .sm\:dq--inset-x-0\.5 {
    left: -0.125rem;
    right: -0.125rem;
  }

  .sm\:dq--inset-x-1\.5 {
    left: -0.375rem;
    right: -0.375rem;
  }

  .sm\:dq--inset-x-2\.5 {
    left: -0.625rem;
    right: -0.625rem;
  }

  .sm\:dq--inset-x-3\.5 {
    left: -0.875rem;
    right: -0.875rem;
  }

  .sm\:dq-inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .sm\:dq-inset-x-1\/3 {
    left: 33.333333%;
    right: 33.333333%;
  }

  .sm\:dq-inset-x-2\/3 {
    left: 66.666667%;
    right: 66.666667%;
  }

  .sm\:dq-inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .sm\:dq-inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .sm\:dq-inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .sm\:dq-inset-x-full {
    left: 100%;
    right: 100%;
  }

  .sm\:dq--inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .sm\:dq--inset-x-1\/3 {
    left: -33.333333%;
    right: -33.333333%;
  }

  .sm\:dq--inset-x-2\/3 {
    left: -66.666667%;
    right: -66.666667%;
  }

  .sm\:dq--inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .sm\:dq--inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .sm\:dq--inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .sm\:dq--inset-x-full {
    left: -100%;
    right: -100%;
  }

  .sm\:dq-inset-y-0 {
    top: 0px;
    bottom: 0px;
  }

  .sm\:dq-inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }

  .sm\:dq-inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }

  .sm\:dq-inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }

  .sm\:dq-inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .sm\:dq-inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .sm\:dq-inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .sm\:dq-inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .sm\:dq-inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .sm\:dq-inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .sm\:dq-inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .sm\:dq-inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .sm\:dq-inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .sm\:dq-inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .sm\:dq-inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .sm\:dq-inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .sm\:dq-inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .sm\:dq-inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .sm\:dq-inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .sm\:dq-inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .sm\:dq-inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .sm\:dq-inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .sm\:dq-inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .sm\:dq-inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .sm\:dq-inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .sm\:dq-inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .sm\:dq-inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .sm\:dq-inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .sm\:dq-inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .sm\:dq-inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .sm\:dq-inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .sm\:dq-inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .sm\:dq-inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }

  .sm\:dq-inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }

  .sm\:dq-inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }

  .sm\:dq-inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }

  .sm\:dq--inset-y-0 {
    top: 0px;
    bottom: 0px;
  }

  .sm\:dq--inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }

  .sm\:dq--inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }

  .sm\:dq--inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }

  .sm\:dq--inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .sm\:dq--inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .sm\:dq--inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .sm\:dq--inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .sm\:dq--inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .sm\:dq--inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .sm\:dq--inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .sm\:dq--inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .sm\:dq--inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .sm\:dq--inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .sm\:dq--inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .sm\:dq--inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .sm\:dq--inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .sm\:dq--inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .sm\:dq--inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .sm\:dq--inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .sm\:dq--inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .sm\:dq--inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .sm\:dq--inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .sm\:dq--inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .sm\:dq--inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .sm\:dq--inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .sm\:dq--inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .sm\:dq--inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .sm\:dq--inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .sm\:dq--inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .sm\:dq--inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .sm\:dq--inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }

  .sm\:dq--inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }

  .sm\:dq--inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }

  .sm\:dq--inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }

  .sm\:dq-inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .sm\:dq-inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }

  .sm\:dq-inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }

  .sm\:dq-inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .sm\:dq-inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .sm\:dq-inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .sm\:dq-inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .sm\:dq--inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .sm\:dq--inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }

  .sm\:dq--inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }

  .sm\:dq--inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .sm\:dq--inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .sm\:dq--inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .sm\:dq--inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .sm\:dq-top-0 {
    top: 0px;
  }

  .sm\:dq-top-1 {
    top: 0.25rem;
  }

  .sm\:dq-top-2 {
    top: 0.5rem;
  }

  .sm\:dq-top-3 {
    top: 0.75rem;
  }

  .sm\:dq-top-4 {
    top: 1rem;
  }

  .sm\:dq-top-5 {
    top: 1.25rem;
  }

  .sm\:dq-top-6 {
    top: 1.5rem;
  }

  .sm\:dq-top-7 {
    top: 1.75rem;
  }

  .sm\:dq-top-8 {
    top: 2rem;
  }

  .sm\:dq-top-9 {
    top: 2.25rem;
  }

  .sm\:dq-top-10 {
    top: 2.5rem;
  }

  .sm\:dq-top-11 {
    top: 2.75rem;
  }

  .sm\:dq-top-12 {
    top: 3rem;
  }

  .sm\:dq-top-14 {
    top: 3.5rem;
  }

  .sm\:dq-top-16 {
    top: 4rem;
  }

  .sm\:dq-top-20 {
    top: 5rem;
  }

  .sm\:dq-top-24 {
    top: 6rem;
  }

  .sm\:dq-top-28 {
    top: 7rem;
  }

  .sm\:dq-top-32 {
    top: 8rem;
  }

  .sm\:dq-top-36 {
    top: 9rem;
  }

  .sm\:dq-top-40 {
    top: 10rem;
  }

  .sm\:dq-top-44 {
    top: 11rem;
  }

  .sm\:dq-top-48 {
    top: 12rem;
  }

  .sm\:dq-top-52 {
    top: 13rem;
  }

  .sm\:dq-top-56 {
    top: 14rem;
  }

  .sm\:dq-top-60 {
    top: 15rem;
  }

  .sm\:dq-top-64 {
    top: 16rem;
  }

  .sm\:dq-top-72 {
    top: 18rem;
  }

  .sm\:dq-top-80 {
    top: 20rem;
  }

  .sm\:dq-top-96 {
    top: 24rem;
  }

  .sm\:dq-top-auto {
    top: auto;
  }

  .sm\:dq-top-px {
    top: 1px;
  }

  .sm\:dq-top-0\.5 {
    top: 0.125rem;
  }

  .sm\:dq-top-1\.5 {
    top: 0.375rem;
  }

  .sm\:dq-top-2\.5 {
    top: 0.625rem;
  }

  .sm\:dq-top-3\.5 {
    top: 0.875rem;
  }

  .sm\:dq--top-0 {
    top: 0px;
  }

  .sm\:dq--top-1 {
    top: -0.25rem;
  }

  .sm\:dq--top-2 {
    top: -0.5rem;
  }

  .sm\:dq--top-3 {
    top: -0.75rem;
  }

  .sm\:dq--top-4 {
    top: -1rem;
  }

  .sm\:dq--top-5 {
    top: -1.25rem;
  }

  .sm\:dq--top-6 {
    top: -1.5rem;
  }

  .sm\:dq--top-7 {
    top: -1.75rem;
  }

  .sm\:dq--top-8 {
    top: -2rem;
  }

  .sm\:dq--top-9 {
    top: -2.25rem;
  }

  .sm\:dq--top-10 {
    top: -2.5rem;
  }

  .sm\:dq--top-11 {
    top: -2.75rem;
  }

  .sm\:dq--top-12 {
    top: -3rem;
  }

  .sm\:dq--top-14 {
    top: -3.5rem;
  }

  .sm\:dq--top-16 {
    top: -4rem;
  }

  .sm\:dq--top-20 {
    top: -5rem;
  }

  .sm\:dq--top-24 {
    top: -6rem;
  }

  .sm\:dq--top-28 {
    top: -7rem;
  }

  .sm\:dq--top-32 {
    top: -8rem;
  }

  .sm\:dq--top-36 {
    top: -9rem;
  }

  .sm\:dq--top-40 {
    top: -10rem;
  }

  .sm\:dq--top-44 {
    top: -11rem;
  }

  .sm\:dq--top-48 {
    top: -12rem;
  }

  .sm\:dq--top-52 {
    top: -13rem;
  }

  .sm\:dq--top-56 {
    top: -14rem;
  }

  .sm\:dq--top-60 {
    top: -15rem;
  }

  .sm\:dq--top-64 {
    top: -16rem;
  }

  .sm\:dq--top-72 {
    top: -18rem;
  }

  .sm\:dq--top-80 {
    top: -20rem;
  }

  .sm\:dq--top-96 {
    top: -24rem;
  }

  .sm\:dq--top-px {
    top: -1px;
  }

  .sm\:dq--top-0\.5 {
    top: -0.125rem;
  }

  .sm\:dq--top-1\.5 {
    top: -0.375rem;
  }

  .sm\:dq--top-2\.5 {
    top: -0.625rem;
  }

  .sm\:dq--top-3\.5 {
    top: -0.875rem;
  }

  .sm\:dq-top-1\/2 {
    top: 50%;
  }

  .sm\:dq-top-1\/3 {
    top: 33.333333%;
  }

  .sm\:dq-top-2\/3 {
    top: 66.666667%;
  }

  .sm\:dq-top-1\/4 {
    top: 25%;
  }

  .sm\:dq-top-2\/4 {
    top: 50%;
  }

  .sm\:dq-top-3\/4 {
    top: 75%;
  }

  .sm\:dq-top-full {
    top: 100%;
  }

  .sm\:dq--top-1\/2 {
    top: -50%;
  }

  .sm\:dq--top-1\/3 {
    top: -33.333333%;
  }

  .sm\:dq--top-2\/3 {
    top: -66.666667%;
  }

  .sm\:dq--top-1\/4 {
    top: -25%;
  }

  .sm\:dq--top-2\/4 {
    top: -50%;
  }

  .sm\:dq--top-3\/4 {
    top: -75%;
  }

  .sm\:dq--top-full {
    top: -100%;
  }

  .sm\:dq-right-0 {
    right: 0px;
  }

  .sm\:dq-right-1 {
    right: 0.25rem;
  }

  .sm\:dq-right-2 {
    right: 0.5rem;
  }

  .sm\:dq-right-3 {
    right: 0.75rem;
  }

  .sm\:dq-right-4 {
    right: 1rem;
  }

  .sm\:dq-right-5 {
    right: 1.25rem;
  }

  .sm\:dq-right-6 {
    right: 1.5rem;
  }

  .sm\:dq-right-7 {
    right: 1.75rem;
  }

  .sm\:dq-right-8 {
    right: 2rem;
  }

  .sm\:dq-right-9 {
    right: 2.25rem;
  }

  .sm\:dq-right-10 {
    right: 2.5rem;
  }

  .sm\:dq-right-11 {
    right: 2.75rem;
  }

  .sm\:dq-right-12 {
    right: 3rem;
  }

  .sm\:dq-right-14 {
    right: 3.5rem;
  }

  .sm\:dq-right-16 {
    right: 4rem;
  }

  .sm\:dq-right-20 {
    right: 5rem;
  }

  .sm\:dq-right-24 {
    right: 6rem;
  }

  .sm\:dq-right-28 {
    right: 7rem;
  }

  .sm\:dq-right-32 {
    right: 8rem;
  }

  .sm\:dq-right-36 {
    right: 9rem;
  }

  .sm\:dq-right-40 {
    right: 10rem;
  }

  .sm\:dq-right-44 {
    right: 11rem;
  }

  .sm\:dq-right-48 {
    right: 12rem;
  }

  .sm\:dq-right-52 {
    right: 13rem;
  }

  .sm\:dq-right-56 {
    right: 14rem;
  }

  .sm\:dq-right-60 {
    right: 15rem;
  }

  .sm\:dq-right-64 {
    right: 16rem;
  }

  .sm\:dq-right-72 {
    right: 18rem;
  }

  .sm\:dq-right-80 {
    right: 20rem;
  }

  .sm\:dq-right-96 {
    right: 24rem;
  }

  .sm\:dq-right-auto {
    right: auto;
  }

  .sm\:dq-right-px {
    right: 1px;
  }

  .sm\:dq-right-0\.5 {
    right: 0.125rem;
  }

  .sm\:dq-right-1\.5 {
    right: 0.375rem;
  }

  .sm\:dq-right-2\.5 {
    right: 0.625rem;
  }

  .sm\:dq-right-3\.5 {
    right: 0.875rem;
  }

  .sm\:dq--right-0 {
    right: 0px;
  }

  .sm\:dq--right-1 {
    right: -0.25rem;
  }

  .sm\:dq--right-2 {
    right: -0.5rem;
  }

  .sm\:dq--right-3 {
    right: -0.75rem;
  }

  .sm\:dq--right-4 {
    right: -1rem;
  }

  .sm\:dq--right-5 {
    right: -1.25rem;
  }

  .sm\:dq--right-6 {
    right: -1.5rem;
  }

  .sm\:dq--right-7 {
    right: -1.75rem;
  }

  .sm\:dq--right-8 {
    right: -2rem;
  }

  .sm\:dq--right-9 {
    right: -2.25rem;
  }

  .sm\:dq--right-10 {
    right: -2.5rem;
  }

  .sm\:dq--right-11 {
    right: -2.75rem;
  }

  .sm\:dq--right-12 {
    right: -3rem;
  }

  .sm\:dq--right-14 {
    right: -3.5rem;
  }

  .sm\:dq--right-16 {
    right: -4rem;
  }

  .sm\:dq--right-20 {
    right: -5rem;
  }

  .sm\:dq--right-24 {
    right: -6rem;
  }

  .sm\:dq--right-28 {
    right: -7rem;
  }

  .sm\:dq--right-32 {
    right: -8rem;
  }

  .sm\:dq--right-36 {
    right: -9rem;
  }

  .sm\:dq--right-40 {
    right: -10rem;
  }

  .sm\:dq--right-44 {
    right: -11rem;
  }

  .sm\:dq--right-48 {
    right: -12rem;
  }

  .sm\:dq--right-52 {
    right: -13rem;
  }

  .sm\:dq--right-56 {
    right: -14rem;
  }

  .sm\:dq--right-60 {
    right: -15rem;
  }

  .sm\:dq--right-64 {
    right: -16rem;
  }

  .sm\:dq--right-72 {
    right: -18rem;
  }

  .sm\:dq--right-80 {
    right: -20rem;
  }

  .sm\:dq--right-96 {
    right: -24rem;
  }

  .sm\:dq--right-px {
    right: -1px;
  }

  .sm\:dq--right-0\.5 {
    right: -0.125rem;
  }

  .sm\:dq--right-1\.5 {
    right: -0.375rem;
  }

  .sm\:dq--right-2\.5 {
    right: -0.625rem;
  }

  .sm\:dq--right-3\.5 {
    right: -0.875rem;
  }

  .sm\:dq-right-1\/2 {
    right: 50%;
  }

  .sm\:dq-right-1\/3 {
    right: 33.333333%;
  }

  .sm\:dq-right-2\/3 {
    right: 66.666667%;
  }

  .sm\:dq-right-1\/4 {
    right: 25%;
  }

  .sm\:dq-right-2\/4 {
    right: 50%;
  }

  .sm\:dq-right-3\/4 {
    right: 75%;
  }

  .sm\:dq-right-full {
    right: 100%;
  }

  .sm\:dq--right-1\/2 {
    right: -50%;
  }

  .sm\:dq--right-1\/3 {
    right: -33.333333%;
  }

  .sm\:dq--right-2\/3 {
    right: -66.666667%;
  }

  .sm\:dq--right-1\/4 {
    right: -25%;
  }

  .sm\:dq--right-2\/4 {
    right: -50%;
  }

  .sm\:dq--right-3\/4 {
    right: -75%;
  }

  .sm\:dq--right-full {
    right: -100%;
  }

  .sm\:dq-bottom-0 {
    bottom: 0px;
  }

  .sm\:dq-bottom-1 {
    bottom: 0.25rem;
  }

  .sm\:dq-bottom-2 {
    bottom: 0.5rem;
  }

  .sm\:dq-bottom-3 {
    bottom: 0.75rem;
  }

  .sm\:dq-bottom-4 {
    bottom: 1rem;
  }

  .sm\:dq-bottom-5 {
    bottom: 1.25rem;
  }

  .sm\:dq-bottom-6 {
    bottom: 1.5rem;
  }

  .sm\:dq-bottom-7 {
    bottom: 1.75rem;
  }

  .sm\:dq-bottom-8 {
    bottom: 2rem;
  }

  .sm\:dq-bottom-9 {
    bottom: 2.25rem;
  }

  .sm\:dq-bottom-10 {
    bottom: 2.5rem;
  }

  .sm\:dq-bottom-11 {
    bottom: 2.75rem;
  }

  .sm\:dq-bottom-12 {
    bottom: 3rem;
  }

  .sm\:dq-bottom-14 {
    bottom: 3.5rem;
  }

  .sm\:dq-bottom-16 {
    bottom: 4rem;
  }

  .sm\:dq-bottom-20 {
    bottom: 5rem;
  }

  .sm\:dq-bottom-24 {
    bottom: 6rem;
  }

  .sm\:dq-bottom-28 {
    bottom: 7rem;
  }

  .sm\:dq-bottom-32 {
    bottom: 8rem;
  }

  .sm\:dq-bottom-36 {
    bottom: 9rem;
  }

  .sm\:dq-bottom-40 {
    bottom: 10rem;
  }

  .sm\:dq-bottom-44 {
    bottom: 11rem;
  }

  .sm\:dq-bottom-48 {
    bottom: 12rem;
  }

  .sm\:dq-bottom-52 {
    bottom: 13rem;
  }

  .sm\:dq-bottom-56 {
    bottom: 14rem;
  }

  .sm\:dq-bottom-60 {
    bottom: 15rem;
  }

  .sm\:dq-bottom-64 {
    bottom: 16rem;
  }

  .sm\:dq-bottom-72 {
    bottom: 18rem;
  }

  .sm\:dq-bottom-80 {
    bottom: 20rem;
  }

  .sm\:dq-bottom-96 {
    bottom: 24rem;
  }

  .sm\:dq-bottom-auto {
    bottom: auto;
  }

  .sm\:dq-bottom-px {
    bottom: 1px;
  }

  .sm\:dq-bottom-0\.5 {
    bottom: 0.125rem;
  }

  .sm\:dq-bottom-1\.5 {
    bottom: 0.375rem;
  }

  .sm\:dq-bottom-2\.5 {
    bottom: 0.625rem;
  }

  .sm\:dq-bottom-3\.5 {
    bottom: 0.875rem;
  }

  .sm\:dq--bottom-0 {
    bottom: 0px;
  }

  .sm\:dq--bottom-1 {
    bottom: -0.25rem;
  }

  .sm\:dq--bottom-2 {
    bottom: -0.5rem;
  }

  .sm\:dq--bottom-3 {
    bottom: -0.75rem;
  }

  .sm\:dq--bottom-4 {
    bottom: -1rem;
  }

  .sm\:dq--bottom-5 {
    bottom: -1.25rem;
  }

  .sm\:dq--bottom-6 {
    bottom: -1.5rem;
  }

  .sm\:dq--bottom-7 {
    bottom: -1.75rem;
  }

  .sm\:dq--bottom-8 {
    bottom: -2rem;
  }

  .sm\:dq--bottom-9 {
    bottom: -2.25rem;
  }

  .sm\:dq--bottom-10 {
    bottom: -2.5rem;
  }

  .sm\:dq--bottom-11 {
    bottom: -2.75rem;
  }

  .sm\:dq--bottom-12 {
    bottom: -3rem;
  }

  .sm\:dq--bottom-14 {
    bottom: -3.5rem;
  }

  .sm\:dq--bottom-16 {
    bottom: -4rem;
  }

  .sm\:dq--bottom-20 {
    bottom: -5rem;
  }

  .sm\:dq--bottom-24 {
    bottom: -6rem;
  }

  .sm\:dq--bottom-28 {
    bottom: -7rem;
  }

  .sm\:dq--bottom-32 {
    bottom: -8rem;
  }

  .sm\:dq--bottom-36 {
    bottom: -9rem;
  }

  .sm\:dq--bottom-40 {
    bottom: -10rem;
  }

  .sm\:dq--bottom-44 {
    bottom: -11rem;
  }

  .sm\:dq--bottom-48 {
    bottom: -12rem;
  }

  .sm\:dq--bottom-52 {
    bottom: -13rem;
  }

  .sm\:dq--bottom-56 {
    bottom: -14rem;
  }

  .sm\:dq--bottom-60 {
    bottom: -15rem;
  }

  .sm\:dq--bottom-64 {
    bottom: -16rem;
  }

  .sm\:dq--bottom-72 {
    bottom: -18rem;
  }

  .sm\:dq--bottom-80 {
    bottom: -20rem;
  }

  .sm\:dq--bottom-96 {
    bottom: -24rem;
  }

  .sm\:dq--bottom-px {
    bottom: -1px;
  }

  .sm\:dq--bottom-0\.5 {
    bottom: -0.125rem;
  }

  .sm\:dq--bottom-1\.5 {
    bottom: -0.375rem;
  }

  .sm\:dq--bottom-2\.5 {
    bottom: -0.625rem;
  }

  .sm\:dq--bottom-3\.5 {
    bottom: -0.875rem;
  }

  .sm\:dq-bottom-1\/2 {
    bottom: 50%;
  }

  .sm\:dq-bottom-1\/3 {
    bottom: 33.333333%;
  }

  .sm\:dq-bottom-2\/3 {
    bottom: 66.666667%;
  }

  .sm\:dq-bottom-1\/4 {
    bottom: 25%;
  }

  .sm\:dq-bottom-2\/4 {
    bottom: 50%;
  }

  .sm\:dq-bottom-3\/4 {
    bottom: 75%;
  }

  .sm\:dq-bottom-full {
    bottom: 100%;
  }

  .sm\:dq--bottom-1\/2 {
    bottom: -50%;
  }

  .sm\:dq--bottom-1\/3 {
    bottom: -33.333333%;
  }

  .sm\:dq--bottom-2\/3 {
    bottom: -66.666667%;
  }

  .sm\:dq--bottom-1\/4 {
    bottom: -25%;
  }

  .sm\:dq--bottom-2\/4 {
    bottom: -50%;
  }

  .sm\:dq--bottom-3\/4 {
    bottom: -75%;
  }

  .sm\:dq--bottom-full {
    bottom: -100%;
  }

  .sm\:dq-left-0 {
    left: 0px;
  }

  .sm\:dq-left-1 {
    left: 0.25rem;
  }

  .sm\:dq-left-2 {
    left: 0.5rem;
  }

  .sm\:dq-left-3 {
    left: 0.75rem;
  }

  .sm\:dq-left-4 {
    left: 1rem;
  }

  .sm\:dq-left-5 {
    left: 1.25rem;
  }

  .sm\:dq-left-6 {
    left: 1.5rem;
  }

  .sm\:dq-left-7 {
    left: 1.75rem;
  }

  .sm\:dq-left-8 {
    left: 2rem;
  }

  .sm\:dq-left-9 {
    left: 2.25rem;
  }

  .sm\:dq-left-10 {
    left: 2.5rem;
  }

  .sm\:dq-left-11 {
    left: 2.75rem;
  }

  .sm\:dq-left-12 {
    left: 3rem;
  }

  .sm\:dq-left-14 {
    left: 3.5rem;
  }

  .sm\:dq-left-16 {
    left: 4rem;
  }

  .sm\:dq-left-20 {
    left: 5rem;
  }

  .sm\:dq-left-24 {
    left: 6rem;
  }

  .sm\:dq-left-28 {
    left: 7rem;
  }

  .sm\:dq-left-32 {
    left: 8rem;
  }

  .sm\:dq-left-36 {
    left: 9rem;
  }

  .sm\:dq-left-40 {
    left: 10rem;
  }

  .sm\:dq-left-44 {
    left: 11rem;
  }

  .sm\:dq-left-48 {
    left: 12rem;
  }

  .sm\:dq-left-52 {
    left: 13rem;
  }

  .sm\:dq-left-56 {
    left: 14rem;
  }

  .sm\:dq-left-60 {
    left: 15rem;
  }

  .sm\:dq-left-64 {
    left: 16rem;
  }

  .sm\:dq-left-72 {
    left: 18rem;
  }

  .sm\:dq-left-80 {
    left: 20rem;
  }

  .sm\:dq-left-96 {
    left: 24rem;
  }

  .sm\:dq-left-auto {
    left: auto;
  }

  .sm\:dq-left-px {
    left: 1px;
  }

  .sm\:dq-left-0\.5 {
    left: 0.125rem;
  }

  .sm\:dq-left-1\.5 {
    left: 0.375rem;
  }

  .sm\:dq-left-2\.5 {
    left: 0.625rem;
  }

  .sm\:dq-left-3\.5 {
    left: 0.875rem;
  }

  .sm\:dq--left-0 {
    left: 0px;
  }

  .sm\:dq--left-1 {
    left: -0.25rem;
  }

  .sm\:dq--left-2 {
    left: -0.5rem;
  }

  .sm\:dq--left-3 {
    left: -0.75rem;
  }

  .sm\:dq--left-4 {
    left: -1rem;
  }

  .sm\:dq--left-5 {
    left: -1.25rem;
  }

  .sm\:dq--left-6 {
    left: -1.5rem;
  }

  .sm\:dq--left-7 {
    left: -1.75rem;
  }

  .sm\:dq--left-8 {
    left: -2rem;
  }

  .sm\:dq--left-9 {
    left: -2.25rem;
  }

  .sm\:dq--left-10 {
    left: -2.5rem;
  }

  .sm\:dq--left-11 {
    left: -2.75rem;
  }

  .sm\:dq--left-12 {
    left: -3rem;
  }

  .sm\:dq--left-14 {
    left: -3.5rem;
  }

  .sm\:dq--left-16 {
    left: -4rem;
  }

  .sm\:dq--left-20 {
    left: -5rem;
  }

  .sm\:dq--left-24 {
    left: -6rem;
  }

  .sm\:dq--left-28 {
    left: -7rem;
  }

  .sm\:dq--left-32 {
    left: -8rem;
  }

  .sm\:dq--left-36 {
    left: -9rem;
  }

  .sm\:dq--left-40 {
    left: -10rem;
  }

  .sm\:dq--left-44 {
    left: -11rem;
  }

  .sm\:dq--left-48 {
    left: -12rem;
  }

  .sm\:dq--left-52 {
    left: -13rem;
  }

  .sm\:dq--left-56 {
    left: -14rem;
  }

  .sm\:dq--left-60 {
    left: -15rem;
  }

  .sm\:dq--left-64 {
    left: -16rem;
  }

  .sm\:dq--left-72 {
    left: -18rem;
  }

  .sm\:dq--left-80 {
    left: -20rem;
  }

  .sm\:dq--left-96 {
    left: -24rem;
  }

  .sm\:dq--left-px {
    left: -1px;
  }

  .sm\:dq--left-0\.5 {
    left: -0.125rem;
  }

  .sm\:dq--left-1\.5 {
    left: -0.375rem;
  }

  .sm\:dq--left-2\.5 {
    left: -0.625rem;
  }

  .sm\:dq--left-3\.5 {
    left: -0.875rem;
  }

  .sm\:dq-left-1\/2 {
    left: 50%;
  }

  .sm\:dq-left-1\/3 {
    left: 33.333333%;
  }

  .sm\:dq-left-2\/3 {
    left: 66.666667%;
  }

  .sm\:dq-left-1\/4 {
    left: 25%;
  }

  .sm\:dq-left-2\/4 {
    left: 50%;
  }

  .sm\:dq-left-3\/4 {
    left: 75%;
  }

  .sm\:dq-left-full {
    left: 100%;
  }

  .sm\:dq--left-1\/2 {
    left: -50%;
  }

  .sm\:dq--left-1\/3 {
    left: -33.333333%;
  }

  .sm\:dq--left-2\/3 {
    left: -66.666667%;
  }

  .sm\:dq--left-1\/4 {
    left: -25%;
  }

  .sm\:dq--left-2\/4 {
    left: -50%;
  }

  .sm\:dq--left-3\/4 {
    left: -75%;
  }

  .sm\:dq--left-full {
    left: -100%;
  }

  .sm\:dq-isolate {
    isolation: isolate;
  }

  .sm\:dq-isolation-auto {
    isolation: auto;
  }

  .sm\:dq-z-0 {
    z-index: 0;
  }

  .sm\:dq-z-10 {
    z-index: 10;
  }

  .sm\:dq-z-20 {
    z-index: 20;
  }

  .sm\:dq-z-30 {
    z-index: 30;
  }

  .sm\:dq-z-40 {
    z-index: 40;
  }

  .sm\:dq-z-50 {
    z-index: 50;
  }

  .sm\:dq-z-auto {
    z-index: auto;
  }

  .sm\:focus-within\:dq-z-0:focus-within {
    z-index: 0;
  }

  .sm\:focus-within\:dq-z-10:focus-within {
    z-index: 10;
  }

  .sm\:focus-within\:dq-z-20:focus-within {
    z-index: 20;
  }

  .sm\:focus-within\:dq-z-30:focus-within {
    z-index: 30;
  }

  .sm\:focus-within\:dq-z-40:focus-within {
    z-index: 40;
  }

  .sm\:focus-within\:dq-z-50:focus-within {
    z-index: 50;
  }

  .sm\:focus-within\:dq-z-auto:focus-within {
    z-index: auto;
  }

  .sm\:focus\:dq-z-0:focus {
    z-index: 0;
  }

  .sm\:focus\:dq-z-10:focus {
    z-index: 10;
  }

  .sm\:focus\:dq-z-20:focus {
    z-index: 20;
  }

  .sm\:focus\:dq-z-30:focus {
    z-index: 30;
  }

  .sm\:focus\:dq-z-40:focus {
    z-index: 40;
  }

  .sm\:focus\:dq-z-50:focus {
    z-index: 50;
  }

  .sm\:focus\:dq-z-auto:focus {
    z-index: auto;
  }

  .sm\:dq-order-1 {
    order: 1;
  }

  .sm\:dq-order-2 {
    order: 2;
  }

  .sm\:dq-order-3 {
    order: 3;
  }

  .sm\:dq-order-4 {
    order: 4;
  }

  .sm\:dq-order-5 {
    order: 5;
  }

  .sm\:dq-order-6 {
    order: 6;
  }

  .sm\:dq-order-7 {
    order: 7;
  }

  .sm\:dq-order-8 {
    order: 8;
  }

  .sm\:dq-order-9 {
    order: 9;
  }

  .sm\:dq-order-10 {
    order: 10;
  }

  .sm\:dq-order-11 {
    order: 11;
  }

  .sm\:dq-order-12 {
    order: 12;
  }

  .sm\:dq-order-first {
    order: -9999;
  }

  .sm\:dq-order-last {
    order: 9999;
  }

  .sm\:dq-order-none {
    order: 0;
  }

  .sm\:dq-col-auto {
    grid-column: auto;
  }

  .sm\:dq-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:dq-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:dq-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:dq-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:dq-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:dq-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:dq-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm\:dq-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:dq-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:dq-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm\:dq-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:dq-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:dq-col-span-full {
    grid-column: 1 / -1;
  }

  .sm\:dq-col-start-1 {
    grid-column-start: 1;
  }

  .sm\:dq-col-start-2 {
    grid-column-start: 2;
  }

  .sm\:dq-col-start-3 {
    grid-column-start: 3;
  }

  .sm\:dq-col-start-4 {
    grid-column-start: 4;
  }

  .sm\:dq-col-start-5 {
    grid-column-start: 5;
  }

  .sm\:dq-col-start-6 {
    grid-column-start: 6;
  }

  .sm\:dq-col-start-7 {
    grid-column-start: 7;
  }

  .sm\:dq-col-start-8 {
    grid-column-start: 8;
  }

  .sm\:dq-col-start-9 {
    grid-column-start: 9;
  }

  .sm\:dq-col-start-10 {
    grid-column-start: 10;
  }

  .sm\:dq-col-start-11 {
    grid-column-start: 11;
  }

  .sm\:dq-col-start-12 {
    grid-column-start: 12;
  }

  .sm\:dq-col-start-13 {
    grid-column-start: 13;
  }

  .sm\:dq-col-start-auto {
    grid-column-start: auto;
  }

  .sm\:dq-col-end-1 {
    grid-column-end: 1;
  }

  .sm\:dq-col-end-2 {
    grid-column-end: 2;
  }

  .sm\:dq-col-end-3 {
    grid-column-end: 3;
  }

  .sm\:dq-col-end-4 {
    grid-column-end: 4;
  }

  .sm\:dq-col-end-5 {
    grid-column-end: 5;
  }

  .sm\:dq-col-end-6 {
    grid-column-end: 6;
  }

  .sm\:dq-col-end-7 {
    grid-column-end: 7;
  }

  .sm\:dq-col-end-8 {
    grid-column-end: 8;
  }

  .sm\:dq-col-end-9 {
    grid-column-end: 9;
  }

  .sm\:dq-col-end-10 {
    grid-column-end: 10;
  }

  .sm\:dq-col-end-11 {
    grid-column-end: 11;
  }

  .sm\:dq-col-end-12 {
    grid-column-end: 12;
  }

  .sm\:dq-col-end-13 {
    grid-column-end: 13;
  }

  .sm\:dq-col-end-auto {
    grid-column-end: auto;
  }

  .sm\:dq-row-auto {
    grid-row: auto;
  }

  .sm\:dq-row-span-1 {
    grid-row: span 1 / span 1;
  }

  .sm\:dq-row-span-2 {
    grid-row: span 2 / span 2;
  }

  .sm\:dq-row-span-3 {
    grid-row: span 3 / span 3;
  }

  .sm\:dq-row-span-4 {
    grid-row: span 4 / span 4;
  }

  .sm\:dq-row-span-5 {
    grid-row: span 5 / span 5;
  }

  .sm\:dq-row-span-6 {
    grid-row: span 6 / span 6;
  }

  .sm\:dq-row-span-full {
    grid-row: 1 / -1;
  }

  .sm\:dq-row-start-1 {
    grid-row-start: 1;
  }

  .sm\:dq-row-start-2 {
    grid-row-start: 2;
  }

  .sm\:dq-row-start-3 {
    grid-row-start: 3;
  }

  .sm\:dq-row-start-4 {
    grid-row-start: 4;
  }

  .sm\:dq-row-start-5 {
    grid-row-start: 5;
  }

  .sm\:dq-row-start-6 {
    grid-row-start: 6;
  }

  .sm\:dq-row-start-7 {
    grid-row-start: 7;
  }

  .sm\:dq-row-start-auto {
    grid-row-start: auto;
  }

  .sm\:dq-row-end-1 {
    grid-row-end: 1;
  }

  .sm\:dq-row-end-2 {
    grid-row-end: 2;
  }

  .sm\:dq-row-end-3 {
    grid-row-end: 3;
  }

  .sm\:dq-row-end-4 {
    grid-row-end: 4;
  }

  .sm\:dq-row-end-5 {
    grid-row-end: 5;
  }

  .sm\:dq-row-end-6 {
    grid-row-end: 6;
  }

  .sm\:dq-row-end-7 {
    grid-row-end: 7;
  }

  .sm\:dq-row-end-auto {
    grid-row-end: auto;
  }

  .sm\:dq-float-right {
    float: right;
  }

  .sm\:dq-float-left {
    float: left;
  }

  .sm\:dq-float-none {
    float: none;
  }

  .sm\:dq-clear-left {
    clear: left;
  }

  .sm\:dq-clear-right {
    clear: right;
  }

  .sm\:dq-clear-both {
    clear: both;
  }

  .sm\:dq-clear-none {
    clear: none;
  }

  .sm\:dq-m-0 {
    margin: 0px;
  }

  .sm\:dq-m-1 {
    margin: 0.25rem;
  }

  .sm\:dq-m-2 {
    margin: 0.5rem;
  }

  .sm\:dq-m-3 {
    margin: 0.75rem;
  }

  .sm\:dq-m-4 {
    margin: 1rem;
  }

  .sm\:dq-m-5 {
    margin: 1.25rem;
  }

  .sm\:dq-m-6 {
    margin: 1.5rem;
  }

  .sm\:dq-m-7 {
    margin: 1.75rem;
  }

  .sm\:dq-m-8 {
    margin: 2rem;
  }

  .sm\:dq-m-9 {
    margin: 2.25rem;
  }

  .sm\:dq-m-10 {
    margin: 2.5rem;
  }

  .sm\:dq-m-11 {
    margin: 2.75rem;
  }

  .sm\:dq-m-12 {
    margin: 3rem;
  }

  .sm\:dq-m-14 {
    margin: 3.5rem;
  }

  .sm\:dq-m-16 {
    margin: 4rem;
  }

  .sm\:dq-m-20 {
    margin: 5rem;
  }

  .sm\:dq-m-24 {
    margin: 6rem;
  }

  .sm\:dq-m-28 {
    margin: 7rem;
  }

  .sm\:dq-m-32 {
    margin: 8rem;
  }

  .sm\:dq-m-36 {
    margin: 9rem;
  }

  .sm\:dq-m-40 {
    margin: 10rem;
  }

  .sm\:dq-m-44 {
    margin: 11rem;
  }

  .sm\:dq-m-48 {
    margin: 12rem;
  }

  .sm\:dq-m-52 {
    margin: 13rem;
  }

  .sm\:dq-m-56 {
    margin: 14rem;
  }

  .sm\:dq-m-60 {
    margin: 15rem;
  }

  .sm\:dq-m-64 {
    margin: 16rem;
  }

  .sm\:dq-m-72 {
    margin: 18rem;
  }

  .sm\:dq-m-80 {
    margin: 20rem;
  }

  .sm\:dq-m-96 {
    margin: 24rem;
  }

  .sm\:dq-m-auto {
    margin: auto;
  }

  .sm\:dq-m-px {
    margin: 1px;
  }

  .sm\:dq-m-0\.5 {
    margin: 0.125rem;
  }

  .sm\:dq-m-1\.5 {
    margin: 0.375rem;
  }

  .sm\:dq-m-2\.5 {
    margin: 0.625rem;
  }

  .sm\:dq-m-3\.5 {
    margin: 0.875rem;
  }

  .sm\:dq--m-0 {
    margin: 0px;
  }

  .sm\:dq--m-1 {
    margin: -0.25rem;
  }

  .sm\:dq--m-2 {
    margin: -0.5rem;
  }

  .sm\:dq--m-3 {
    margin: -0.75rem;
  }

  .sm\:dq--m-4 {
    margin: -1rem;
  }

  .sm\:dq--m-5 {
    margin: -1.25rem;
  }

  .sm\:dq--m-6 {
    margin: -1.5rem;
  }

  .sm\:dq--m-7 {
    margin: -1.75rem;
  }

  .sm\:dq--m-8 {
    margin: -2rem;
  }

  .sm\:dq--m-9 {
    margin: -2.25rem;
  }

  .sm\:dq--m-10 {
    margin: -2.5rem;
  }

  .sm\:dq--m-11 {
    margin: -2.75rem;
  }

  .sm\:dq--m-12 {
    margin: -3rem;
  }

  .sm\:dq--m-14 {
    margin: -3.5rem;
  }

  .sm\:dq--m-16 {
    margin: -4rem;
  }

  .sm\:dq--m-20 {
    margin: -5rem;
  }

  .sm\:dq--m-24 {
    margin: -6rem;
  }

  .sm\:dq--m-28 {
    margin: -7rem;
  }

  .sm\:dq--m-32 {
    margin: -8rem;
  }

  .sm\:dq--m-36 {
    margin: -9rem;
  }

  .sm\:dq--m-40 {
    margin: -10rem;
  }

  .sm\:dq--m-44 {
    margin: -11rem;
  }

  .sm\:dq--m-48 {
    margin: -12rem;
  }

  .sm\:dq--m-52 {
    margin: -13rem;
  }

  .sm\:dq--m-56 {
    margin: -14rem;
  }

  .sm\:dq--m-60 {
    margin: -15rem;
  }

  .sm\:dq--m-64 {
    margin: -16rem;
  }

  .sm\:dq--m-72 {
    margin: -18rem;
  }

  .sm\:dq--m-80 {
    margin: -20rem;
  }

  .sm\:dq--m-96 {
    margin: -24rem;
  }

  .sm\:dq--m-px {
    margin: -1px;
  }

  .sm\:dq--m-0\.5 {
    margin: -0.125rem;
  }

  .sm\:dq--m-1\.5 {
    margin: -0.375rem;
  }

  .sm\:dq--m-2\.5 {
    margin: -0.625rem;
  }

  .sm\:dq--m-3\.5 {
    margin: -0.875rem;
  }

  .sm\:dq-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sm\:dq-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .sm\:dq-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .sm\:dq-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .sm\:dq-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:dq-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .sm\:dq-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:dq-mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .sm\:dq-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:dq-mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .sm\:dq-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .sm\:dq-mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .sm\:dq-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .sm\:dq-mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .sm\:dq-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .sm\:dq-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .sm\:dq-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .sm\:dq-mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .sm\:dq-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .sm\:dq-mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .sm\:dq-mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .sm\:dq-mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .sm\:dq-mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .sm\:dq-mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .sm\:dq-mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .sm\:dq-mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .sm\:dq-mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .sm\:dq-mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .sm\:dq-mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .sm\:dq-mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .sm\:dq-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:dq-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .sm\:dq-mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }

  .sm\:dq-mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }

  .sm\:dq-mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .sm\:dq-mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }

  .sm\:dq--mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sm\:dq--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .sm\:dq--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .sm\:dq--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .sm\:dq--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm\:dq--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .sm\:dq--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:dq--mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .sm\:dq--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .sm\:dq--mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .sm\:dq--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .sm\:dq--mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .sm\:dq--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .sm\:dq--mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .sm\:dq--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm\:dq--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .sm\:dq--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .sm\:dq--mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .sm\:dq--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .sm\:dq--mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .sm\:dq--mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .sm\:dq--mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .sm\:dq--mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .sm\:dq--mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .sm\:dq--mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .sm\:dq--mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .sm\:dq--mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .sm\:dq--mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .sm\:dq--mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .sm\:dq--mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .sm\:dq--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .sm\:dq--mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }

  .sm\:dq--mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }

  .sm\:dq--mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }

  .sm\:dq--mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }

  .sm\:dq-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sm\:dq-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .sm\:dq-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .sm\:dq-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .sm\:dq-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm\:dq-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm\:dq-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .sm\:dq-my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .sm\:dq-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:dq-my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .sm\:dq-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:dq-my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .sm\:dq-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:dq-my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .sm\:dq-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:dq-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .sm\:dq-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .sm\:dq-my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .sm\:dq-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .sm\:dq-my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .sm\:dq-my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .sm\:dq-my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .sm\:dq-my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .sm\:dq-my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .sm\:dq-my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .sm\:dq-my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .sm\:dq-my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .sm\:dq-my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .sm\:dq-my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .sm\:dq-my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .sm\:dq-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .sm\:dq-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sm\:dq-my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }

  .sm\:dq-my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }

  .sm\:dq-my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .sm\:dq-my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }

  .sm\:dq--my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sm\:dq--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .sm\:dq--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .sm\:dq--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .sm\:dq--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .sm\:dq--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .sm\:dq--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .sm\:dq--my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .sm\:dq--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .sm\:dq--my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .sm\:dq--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .sm\:dq--my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .sm\:dq--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .sm\:dq--my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .sm\:dq--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .sm\:dq--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .sm\:dq--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .sm\:dq--my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .sm\:dq--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .sm\:dq--my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .sm\:dq--my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .sm\:dq--my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .sm\:dq--my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .sm\:dq--my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .sm\:dq--my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .sm\:dq--my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .sm\:dq--my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .sm\:dq--my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .sm\:dq--my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .sm\:dq--my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .sm\:dq--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .sm\:dq--my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }

  .sm\:dq--my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }

  .sm\:dq--my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }

  .sm\:dq--my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }

  .sm\:dq-mt-0 {
    margin-top: 0px;
  }

  .sm\:dq-mt-1 {
    margin-top: 0.25rem;
  }

  .sm\:dq-mt-2 {
    margin-top: 0.5rem;
  }

  .sm\:dq-mt-3 {
    margin-top: 0.75rem;
  }

  .sm\:dq-mt-4 {
    margin-top: 1rem;
  }

  .sm\:dq-mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:dq-mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:dq-mt-7 {
    margin-top: 1.75rem;
  }

  .sm\:dq-mt-8 {
    margin-top: 2rem;
  }

  .sm\:dq-mt-9 {
    margin-top: 2.25rem;
  }

  .sm\:dq-mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:dq-mt-11 {
    margin-top: 2.75rem;
  }

  .sm\:dq-mt-12 {
    margin-top: 3rem;
  }

  .sm\:dq-mt-14 {
    margin-top: 3.5rem;
  }

  .sm\:dq-mt-16 {
    margin-top: 4rem;
  }

  .sm\:dq-mt-20 {
    margin-top: 5rem;
  }

  .sm\:dq-mt-24 {
    margin-top: 6rem;
  }

  .sm\:dq-mt-28 {
    margin-top: 7rem;
  }

  .sm\:dq-mt-32 {
    margin-top: 8rem;
  }

  .sm\:dq-mt-36 {
    margin-top: 9rem;
  }

  .sm\:dq-mt-40 {
    margin-top: 10rem;
  }

  .sm\:dq-mt-44 {
    margin-top: 11rem;
  }

  .sm\:dq-mt-48 {
    margin-top: 12rem;
  }

  .sm\:dq-mt-52 {
    margin-top: 13rem;
  }

  .sm\:dq-mt-56 {
    margin-top: 14rem;
  }

  .sm\:dq-mt-60 {
    margin-top: 15rem;
  }

  .sm\:dq-mt-64 {
    margin-top: 16rem;
  }

  .sm\:dq-mt-72 {
    margin-top: 18rem;
  }

  .sm\:dq-mt-80 {
    margin-top: 20rem;
  }

  .sm\:dq-mt-96 {
    margin-top: 24rem;
  }

  .sm\:dq-mt-auto {
    margin-top: auto;
  }

  .sm\:dq-mt-px {
    margin-top: 1px;
  }

  .sm\:dq-mt-0\.5 {
    margin-top: 0.125rem;
  }

  .sm\:dq-mt-1\.5 {
    margin-top: 0.375rem;
  }

  .sm\:dq-mt-2\.5 {
    margin-top: 0.625rem;
  }

  .sm\:dq-mt-3\.5 {
    margin-top: 0.875rem;
  }

  .sm\:dq--mt-0 {
    margin-top: 0px;
  }

  .sm\:dq--mt-1 {
    margin-top: -0.25rem;
  }

  .sm\:dq--mt-2 {
    margin-top: -0.5rem;
  }

  .sm\:dq--mt-3 {
    margin-top: -0.75rem;
  }

  .sm\:dq--mt-4 {
    margin-top: -1rem;
  }

  .sm\:dq--mt-5 {
    margin-top: -1.25rem;
  }

  .sm\:dq--mt-6 {
    margin-top: -1.5rem;
  }

  .sm\:dq--mt-7 {
    margin-top: -1.75rem;
  }

  .sm\:dq--mt-8 {
    margin-top: -2rem;
  }

  .sm\:dq--mt-9 {
    margin-top: -2.25rem;
  }

  .sm\:dq--mt-10 {
    margin-top: -2.5rem;
  }

  .sm\:dq--mt-11 {
    margin-top: -2.75rem;
  }

  .sm\:dq--mt-12 {
    margin-top: -3rem;
  }

  .sm\:dq--mt-14 {
    margin-top: -3.5rem;
  }

  .sm\:dq--mt-16 {
    margin-top: -4rem;
  }

  .sm\:dq--mt-20 {
    margin-top: -5rem;
  }

  .sm\:dq--mt-24 {
    margin-top: -6rem;
  }

  .sm\:dq--mt-28 {
    margin-top: -7rem;
  }

  .sm\:dq--mt-32 {
    margin-top: -8rem;
  }

  .sm\:dq--mt-36 {
    margin-top: -9rem;
  }

  .sm\:dq--mt-40 {
    margin-top: -10rem;
  }

  .sm\:dq--mt-44 {
    margin-top: -11rem;
  }

  .sm\:dq--mt-48 {
    margin-top: -12rem;
  }

  .sm\:dq--mt-52 {
    margin-top: -13rem;
  }

  .sm\:dq--mt-56 {
    margin-top: -14rem;
  }

  .sm\:dq--mt-60 {
    margin-top: -15rem;
  }

  .sm\:dq--mt-64 {
    margin-top: -16rem;
  }

  .sm\:dq--mt-72 {
    margin-top: -18rem;
  }

  .sm\:dq--mt-80 {
    margin-top: -20rem;
  }

  .sm\:dq--mt-96 {
    margin-top: -24rem;
  }

  .sm\:dq--mt-px {
    margin-top: -1px;
  }

  .sm\:dq--mt-0\.5 {
    margin-top: -0.125rem;
  }

  .sm\:dq--mt-1\.5 {
    margin-top: -0.375rem;
  }

  .sm\:dq--mt-2\.5 {
    margin-top: -0.625rem;
  }

  .sm\:dq--mt-3\.5 {
    margin-top: -0.875rem;
  }

  .sm\:dq-mr-0 {
    margin-right: 0px;
  }

  .sm\:dq-mr-1 {
    margin-right: 0.25rem;
  }

  .sm\:dq-mr-2 {
    margin-right: 0.5rem;
  }

  .sm\:dq-mr-3 {
    margin-right: 0.75rem;
  }

  .sm\:dq-mr-4 {
    margin-right: 1rem;
  }

  .sm\:dq-mr-5 {
    margin-right: 1.25rem;
  }

  .sm\:dq-mr-6 {
    margin-right: 1.5rem;
  }

  .sm\:dq-mr-7 {
    margin-right: 1.75rem;
  }

  .sm\:dq-mr-8 {
    margin-right: 2rem;
  }

  .sm\:dq-mr-9 {
    margin-right: 2.25rem;
  }

  .sm\:dq-mr-10 {
    margin-right: 2.5rem;
  }

  .sm\:dq-mr-11 {
    margin-right: 2.75rem;
  }

  .sm\:dq-mr-12 {
    margin-right: 3rem;
  }

  .sm\:dq-mr-14 {
    margin-right: 3.5rem;
  }

  .sm\:dq-mr-16 {
    margin-right: 4rem;
  }

  .sm\:dq-mr-20 {
    margin-right: 5rem;
  }

  .sm\:dq-mr-24 {
    margin-right: 6rem;
  }

  .sm\:dq-mr-28 {
    margin-right: 7rem;
  }

  .sm\:dq-mr-32 {
    margin-right: 8rem;
  }

  .sm\:dq-mr-36 {
    margin-right: 9rem;
  }

  .sm\:dq-mr-40 {
    margin-right: 10rem;
  }

  .sm\:dq-mr-44 {
    margin-right: 11rem;
  }

  .sm\:dq-mr-48 {
    margin-right: 12rem;
  }

  .sm\:dq-mr-52 {
    margin-right: 13rem;
  }

  .sm\:dq-mr-56 {
    margin-right: 14rem;
  }

  .sm\:dq-mr-60 {
    margin-right: 15rem;
  }

  .sm\:dq-mr-64 {
    margin-right: 16rem;
  }

  .sm\:dq-mr-72 {
    margin-right: 18rem;
  }

  .sm\:dq-mr-80 {
    margin-right: 20rem;
  }

  .sm\:dq-mr-96 {
    margin-right: 24rem;
  }

  .sm\:dq-mr-auto {
    margin-right: auto;
  }

  .sm\:dq-mr-px {
    margin-right: 1px;
  }

  .sm\:dq-mr-0\.5 {
    margin-right: 0.125rem;
  }

  .sm\:dq-mr-1\.5 {
    margin-right: 0.375rem;
  }

  .sm\:dq-mr-2\.5 {
    margin-right: 0.625rem;
  }

  .sm\:dq-mr-3\.5 {
    margin-right: 0.875rem;
  }

  .sm\:dq--mr-0 {
    margin-right: 0px;
  }

  .sm\:dq--mr-1 {
    margin-right: -0.25rem;
  }

  .sm\:dq--mr-2 {
    margin-right: -0.5rem;
  }

  .sm\:dq--mr-3 {
    margin-right: -0.75rem;
  }

  .sm\:dq--mr-4 {
    margin-right: -1rem;
  }

  .sm\:dq--mr-5 {
    margin-right: -1.25rem;
  }

  .sm\:dq--mr-6 {
    margin-right: -1.5rem;
  }

  .sm\:dq--mr-7 {
    margin-right: -1.75rem;
  }

  .sm\:dq--mr-8 {
    margin-right: -2rem;
  }

  .sm\:dq--mr-9 {
    margin-right: -2.25rem;
  }

  .sm\:dq--mr-10 {
    margin-right: -2.5rem;
  }

  .sm\:dq--mr-11 {
    margin-right: -2.75rem;
  }

  .sm\:dq--mr-12 {
    margin-right: -3rem;
  }

  .sm\:dq--mr-14 {
    margin-right: -3.5rem;
  }

  .sm\:dq--mr-16 {
    margin-right: -4rem;
  }

  .sm\:dq--mr-20 {
    margin-right: -5rem;
  }

  .sm\:dq--mr-24 {
    margin-right: -6rem;
  }

  .sm\:dq--mr-28 {
    margin-right: -7rem;
  }

  .sm\:dq--mr-32 {
    margin-right: -8rem;
  }

  .sm\:dq--mr-36 {
    margin-right: -9rem;
  }

  .sm\:dq--mr-40 {
    margin-right: -10rem;
  }

  .sm\:dq--mr-44 {
    margin-right: -11rem;
  }

  .sm\:dq--mr-48 {
    margin-right: -12rem;
  }

  .sm\:dq--mr-52 {
    margin-right: -13rem;
  }

  .sm\:dq--mr-56 {
    margin-right: -14rem;
  }

  .sm\:dq--mr-60 {
    margin-right: -15rem;
  }

  .sm\:dq--mr-64 {
    margin-right: -16rem;
  }

  .sm\:dq--mr-72 {
    margin-right: -18rem;
  }

  .sm\:dq--mr-80 {
    margin-right: -20rem;
  }

  .sm\:dq--mr-96 {
    margin-right: -24rem;
  }

  .sm\:dq--mr-px {
    margin-right: -1px;
  }

  .sm\:dq--mr-0\.5 {
    margin-right: -0.125rem;
  }

  .sm\:dq--mr-1\.5 {
    margin-right: -0.375rem;
  }

  .sm\:dq--mr-2\.5 {
    margin-right: -0.625rem;
  }

  .sm\:dq--mr-3\.5 {
    margin-right: -0.875rem;
  }

  .sm\:dq-mb-0 {
    margin-bottom: 0px;
  }

  .sm\:dq-mb-1 {
    margin-bottom: 0.25rem;
  }

  .sm\:dq-mb-2 {
    margin-bottom: 0.5rem;
  }

  .sm\:dq-mb-3 {
    margin-bottom: 0.75rem;
  }

  .sm\:dq-mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:dq-mb-5 {
    margin-bottom: 1.25rem;
  }

  .sm\:dq-mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:dq-mb-7 {
    margin-bottom: 1.75rem;
  }

  .sm\:dq-mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:dq-mb-9 {
    margin-bottom: 2.25rem;
  }

  .sm\:dq-mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:dq-mb-11 {
    margin-bottom: 2.75rem;
  }

  .sm\:dq-mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:dq-mb-14 {
    margin-bottom: 3.5rem;
  }

  .sm\:dq-mb-16 {
    margin-bottom: 4rem;
  }

  .sm\:dq-mb-20 {
    margin-bottom: 5rem;
  }

  .sm\:dq-mb-24 {
    margin-bottom: 6rem;
  }

  .sm\:dq-mb-28 {
    margin-bottom: 7rem;
  }

  .sm\:dq-mb-32 {
    margin-bottom: 8rem;
  }

  .sm\:dq-mb-36 {
    margin-bottom: 9rem;
  }

  .sm\:dq-mb-40 {
    margin-bottom: 10rem;
  }

  .sm\:dq-mb-44 {
    margin-bottom: 11rem;
  }

  .sm\:dq-mb-48 {
    margin-bottom: 12rem;
  }

  .sm\:dq-mb-52 {
    margin-bottom: 13rem;
  }

  .sm\:dq-mb-56 {
    margin-bottom: 14rem;
  }

  .sm\:dq-mb-60 {
    margin-bottom: 15rem;
  }

  .sm\:dq-mb-64 {
    margin-bottom: 16rem;
  }

  .sm\:dq-mb-72 {
    margin-bottom: 18rem;
  }

  .sm\:dq-mb-80 {
    margin-bottom: 20rem;
  }

  .sm\:dq-mb-96 {
    margin-bottom: 24rem;
  }

  .sm\:dq-mb-auto {
    margin-bottom: auto;
  }

  .sm\:dq-mb-px {
    margin-bottom: 1px;
  }

  .sm\:dq-mb-0\.5 {
    margin-bottom: 0.125rem;
  }

  .sm\:dq-mb-1\.5 {
    margin-bottom: 0.375rem;
  }

  .sm\:dq-mb-2\.5 {
    margin-bottom: 0.625rem;
  }

  .sm\:dq-mb-3\.5 {
    margin-bottom: 0.875rem;
  }

  .sm\:dq--mb-0 {
    margin-bottom: 0px;
  }

  .sm\:dq--mb-1 {
    margin-bottom: -0.25rem;
  }

  .sm\:dq--mb-2 {
    margin-bottom: -0.5rem;
  }

  .sm\:dq--mb-3 {
    margin-bottom: -0.75rem;
  }

  .sm\:dq--mb-4 {
    margin-bottom: -1rem;
  }

  .sm\:dq--mb-5 {
    margin-bottom: -1.25rem;
  }

  .sm\:dq--mb-6 {
    margin-bottom: -1.5rem;
  }

  .sm\:dq--mb-7 {
    margin-bottom: -1.75rem;
  }

  .sm\:dq--mb-8 {
    margin-bottom: -2rem;
  }

  .sm\:dq--mb-9 {
    margin-bottom: -2.25rem;
  }

  .sm\:dq--mb-10 {
    margin-bottom: -2.5rem;
  }

  .sm\:dq--mb-11 {
    margin-bottom: -2.75rem;
  }

  .sm\:dq--mb-12 {
    margin-bottom: -3rem;
  }

  .sm\:dq--mb-14 {
    margin-bottom: -3.5rem;
  }

  .sm\:dq--mb-16 {
    margin-bottom: -4rem;
  }

  .sm\:dq--mb-20 {
    margin-bottom: -5rem;
  }

  .sm\:dq--mb-24 {
    margin-bottom: -6rem;
  }

  .sm\:dq--mb-28 {
    margin-bottom: -7rem;
  }

  .sm\:dq--mb-32 {
    margin-bottom: -8rem;
  }

  .sm\:dq--mb-36 {
    margin-bottom: -9rem;
  }

  .sm\:dq--mb-40 {
    margin-bottom: -10rem;
  }

  .sm\:dq--mb-44 {
    margin-bottom: -11rem;
  }

  .sm\:dq--mb-48 {
    margin-bottom: -12rem;
  }

  .sm\:dq--mb-52 {
    margin-bottom: -13rem;
  }

  .sm\:dq--mb-56 {
    margin-bottom: -14rem;
  }

  .sm\:dq--mb-60 {
    margin-bottom: -15rem;
  }

  .sm\:dq--mb-64 {
    margin-bottom: -16rem;
  }

  .sm\:dq--mb-72 {
    margin-bottom: -18rem;
  }

  .sm\:dq--mb-80 {
    margin-bottom: -20rem;
  }

  .sm\:dq--mb-96 {
    margin-bottom: -24rem;
  }

  .sm\:dq--mb-px {
    margin-bottom: -1px;
  }

  .sm\:dq--mb-0\.5 {
    margin-bottom: -0.125rem;
  }

  .sm\:dq--mb-1\.5 {
    margin-bottom: -0.375rem;
  }

  .sm\:dq--mb-2\.5 {
    margin-bottom: -0.625rem;
  }

  .sm\:dq--mb-3\.5 {
    margin-bottom: -0.875rem;
  }

  .sm\:dq-ml-0 {
    margin-left: 0px;
  }

  .sm\:dq-ml-1 {
    margin-left: 0.25rem;
  }

  .sm\:dq-ml-2 {
    margin-left: 0.5rem;
  }

  .sm\:dq-ml-3 {
    margin-left: 0.75rem;
  }

  .sm\:dq-ml-4 {
    margin-left: 1rem;
  }

  .sm\:dq-ml-5 {
    margin-left: 1.25rem;
  }

  .sm\:dq-ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:dq-ml-7 {
    margin-left: 1.75rem;
  }

  .sm\:dq-ml-8 {
    margin-left: 2rem;
  }

  .sm\:dq-ml-9 {
    margin-left: 2.25rem;
  }

  .sm\:dq-ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:dq-ml-11 {
    margin-left: 2.75rem;
  }

  .sm\:dq-ml-12 {
    margin-left: 3rem;
  }

  .sm\:dq-ml-14 {
    margin-left: 3.5rem;
  }

  .sm\:dq-ml-16 {
    margin-left: 4rem;
  }

  .sm\:dq-ml-20 {
    margin-left: 5rem;
  }

  .sm\:dq-ml-24 {
    margin-left: 6rem;
  }

  .sm\:dq-ml-28 {
    margin-left: 7rem;
  }

  .sm\:dq-ml-32 {
    margin-left: 8rem;
  }

  .sm\:dq-ml-36 {
    margin-left: 9rem;
  }

  .sm\:dq-ml-40 {
    margin-left: 10rem;
  }

  .sm\:dq-ml-44 {
    margin-left: 11rem;
  }

  .sm\:dq-ml-48 {
    margin-left: 12rem;
  }

  .sm\:dq-ml-52 {
    margin-left: 13rem;
  }

  .sm\:dq-ml-56 {
    margin-left: 14rem;
  }

  .sm\:dq-ml-60 {
    margin-left: 15rem;
  }

  .sm\:dq-ml-64 {
    margin-left: 16rem;
  }

  .sm\:dq-ml-72 {
    margin-left: 18rem;
  }

  .sm\:dq-ml-80 {
    margin-left: 20rem;
  }

  .sm\:dq-ml-96 {
    margin-left: 24rem;
  }

  .sm\:dq-ml-auto {
    margin-left: auto;
  }

  .sm\:dq-ml-px {
    margin-left: 1px;
  }

  .sm\:dq-ml-0\.5 {
    margin-left: 0.125rem;
  }

  .sm\:dq-ml-1\.5 {
    margin-left: 0.375rem;
  }

  .sm\:dq-ml-2\.5 {
    margin-left: 0.625rem;
  }

  .sm\:dq-ml-3\.5 {
    margin-left: 0.875rem;
  }

  .sm\:dq--ml-0 {
    margin-left: 0px;
  }

  .sm\:dq--ml-1 {
    margin-left: -0.25rem;
  }

  .sm\:dq--ml-2 {
    margin-left: -0.5rem;
  }

  .sm\:dq--ml-3 {
    margin-left: -0.75rem;
  }

  .sm\:dq--ml-4 {
    margin-left: -1rem;
  }

  .sm\:dq--ml-5 {
    margin-left: -1.25rem;
  }

  .sm\:dq--ml-6 {
    margin-left: -1.5rem;
  }

  .sm\:dq--ml-7 {
    margin-left: -1.75rem;
  }

  .sm\:dq--ml-8 {
    margin-left: -2rem;
  }

  .sm\:dq--ml-9 {
    margin-left: -2.25rem;
  }

  .sm\:dq--ml-10 {
    margin-left: -2.5rem;
  }

  .sm\:dq--ml-11 {
    margin-left: -2.75rem;
  }

  .sm\:dq--ml-12 {
    margin-left: -3rem;
  }

  .sm\:dq--ml-14 {
    margin-left: -3.5rem;
  }

  .sm\:dq--ml-16 {
    margin-left: -4rem;
  }

  .sm\:dq--ml-20 {
    margin-left: -5rem;
  }

  .sm\:dq--ml-24 {
    margin-left: -6rem;
  }

  .sm\:dq--ml-28 {
    margin-left: -7rem;
  }

  .sm\:dq--ml-32 {
    margin-left: -8rem;
  }

  .sm\:dq--ml-36 {
    margin-left: -9rem;
  }

  .sm\:dq--ml-40 {
    margin-left: -10rem;
  }

  .sm\:dq--ml-44 {
    margin-left: -11rem;
  }

  .sm\:dq--ml-48 {
    margin-left: -12rem;
  }

  .sm\:dq--ml-52 {
    margin-left: -13rem;
  }

  .sm\:dq--ml-56 {
    margin-left: -14rem;
  }

  .sm\:dq--ml-60 {
    margin-left: -15rem;
  }

  .sm\:dq--ml-64 {
    margin-left: -16rem;
  }

  .sm\:dq--ml-72 {
    margin-left: -18rem;
  }

  .sm\:dq--ml-80 {
    margin-left: -20rem;
  }

  .sm\:dq--ml-96 {
    margin-left: -24rem;
  }

  .sm\:dq--ml-px {
    margin-left: -1px;
  }

  .sm\:dq--ml-0\.5 {
    margin-left: -0.125rem;
  }

  .sm\:dq--ml-1\.5 {
    margin-left: -0.375rem;
  }

  .sm\:dq--ml-2\.5 {
    margin-left: -0.625rem;
  }

  .sm\:dq--ml-3\.5 {
    margin-left: -0.875rem;
  }

  .sm\:dq-box-border {
    box-sizing: border-box;
  }

  .sm\:dq-box-content {
    box-sizing: content-box;
  }

  .sm\:dq-block {
    display: block;
  }

  .sm\:dq-inline-block {
    display: inline-block;
  }

  .sm\:dq-inline {
    display: inline;
  }

  .sm\:dq-flex {
    display: flex;
  }

  .sm\:dq-inline-flex {
    display: inline-flex;
  }

  .sm\:dq-table {
    display: table;
  }

  .sm\:dq-inline-table {
    display: inline-table;
  }

  .sm\:dq-table-caption {
    display: table-caption;
  }

  .sm\:dq-table-cell {
    display: table-cell;
  }

  .sm\:dq-table-column {
    display: table-column;
  }

  .sm\:dq-table-column-group {
    display: table-column-group;
  }

  .sm\:dq-table-footer-group {
    display: table-footer-group;
  }

  .sm\:dq-table-header-group {
    display: table-header-group;
  }

  .sm\:dq-table-row-group {
    display: table-row-group;
  }

  .sm\:dq-table-row {
    display: table-row;
  }

  .sm\:dq-flow-root {
    display: flow-root;
  }

  .sm\:dq-grid {
    display: grid;
  }

  .sm\:dq-inline-grid {
    display: inline-grid;
  }

  .sm\:dq-contents {
    display: contents;
  }

  .sm\:dq-list-item {
    display: list-item;
  }

  .sm\:dq-hidden {
    display: none;
  }

  .sm\:dq-h-0 {
    height: 0px;
  }

  .sm\:dq-h-1 {
    height: 0.25rem;
  }

  .sm\:dq-h-2 {
    height: 0.5rem;
  }

  .sm\:dq-h-3 {
    height: 0.75rem;
  }

  .sm\:dq-h-4 {
    height: 1rem;
  }

  .sm\:dq-h-5 {
    height: 1.25rem;
  }

  .sm\:dq-h-6 {
    height: 1.5rem;
  }

  .sm\:dq-h-7 {
    height: 1.75rem;
  }

  .sm\:dq-h-8 {
    height: 2rem;
  }

  .sm\:dq-h-9 {
    height: 2.25rem;
  }

  .sm\:dq-h-10 {
    height: 2.5rem;
  }

  .sm\:dq-h-11 {
    height: 2.75rem;
  }

  .sm\:dq-h-12 {
    height: 3rem;
  }

  .sm\:dq-h-14 {
    height: 3.5rem;
  }

  .sm\:dq-h-16 {
    height: 4rem;
  }

  .sm\:dq-h-20 {
    height: 5rem;
  }

  .sm\:dq-h-24 {
    height: 6rem;
  }

  .sm\:dq-h-28 {
    height: 7rem;
  }

  .sm\:dq-h-32 {
    height: 8rem;
  }

  .sm\:dq-h-36 {
    height: 9rem;
  }

  .sm\:dq-h-40 {
    height: 10rem;
  }

  .sm\:dq-h-44 {
    height: 11rem;
  }

  .sm\:dq-h-48 {
    height: 12rem;
  }

  .sm\:dq-h-52 {
    height: 13rem;
  }

  .sm\:dq-h-56 {
    height: 14rem;
  }

  .sm\:dq-h-60 {
    height: 15rem;
  }

  .sm\:dq-h-64 {
    height: 16rem;
  }

  .sm\:dq-h-72 {
    height: 18rem;
  }

  .sm\:dq-h-80 {
    height: 20rem;
  }

  .sm\:dq-h-96 {
    height: 24rem;
  }

  .sm\:dq-h-auto {
    height: auto;
  }

  .sm\:dq-h-px {
    height: 1px;
  }

  .sm\:dq-h-0\.5 {
    height: 0.125rem;
  }

  .sm\:dq-h-1\.5 {
    height: 0.375rem;
  }

  .sm\:dq-h-2\.5 {
    height: 0.625rem;
  }

  .sm\:dq-h-3\.5 {
    height: 0.875rem;
  }

  .sm\:dq-h-1\/2 {
    height: 50%;
  }

  .sm\:dq-h-1\/3 {
    height: 33.333333%;
  }

  .sm\:dq-h-2\/3 {
    height: 66.666667%;
  }

  .sm\:dq-h-1\/4 {
    height: 25%;
  }

  .sm\:dq-h-2\/4 {
    height: 50%;
  }

  .sm\:dq-h-3\/4 {
    height: 75%;
  }

  .sm\:dq-h-1\/5 {
    height: 20%;
  }

  .sm\:dq-h-2\/5 {
    height: 40%;
  }

  .sm\:dq-h-3\/5 {
    height: 60%;
  }

  .sm\:dq-h-4\/5 {
    height: 80%;
  }

  .sm\:dq-h-1\/6 {
    height: 16.666667%;
  }

  .sm\:dq-h-2\/6 {
    height: 33.333333%;
  }

  .sm\:dq-h-3\/6 {
    height: 50%;
  }

  .sm\:dq-h-4\/6 {
    height: 66.666667%;
  }

  .sm\:dq-h-5\/6 {
    height: 83.333333%;
  }

  .sm\:dq-h-full {
    height: 100%;
  }

  .sm\:dq-h-screen {
    height: 100vh;
  }

  .sm\:dq-max-h-0 {
    max-height: 0px;
  }

  .sm\:dq-max-h-1 {
    max-height: 0.25rem;
  }

  .sm\:dq-max-h-2 {
    max-height: 0.5rem;
  }

  .sm\:dq-max-h-3 {
    max-height: 0.75rem;
  }

  .sm\:dq-max-h-4 {
    max-height: 1rem;
  }

  .sm\:dq-max-h-5 {
    max-height: 1.25rem;
  }

  .sm\:dq-max-h-6 {
    max-height: 1.5rem;
  }

  .sm\:dq-max-h-7 {
    max-height: 1.75rem;
  }

  .sm\:dq-max-h-8 {
    max-height: 2rem;
  }

  .sm\:dq-max-h-9 {
    max-height: 2.25rem;
  }

  .sm\:dq-max-h-10 {
    max-height: 2.5rem;
  }

  .sm\:dq-max-h-11 {
    max-height: 2.75rem;
  }

  .sm\:dq-max-h-12 {
    max-height: 3rem;
  }

  .sm\:dq-max-h-14 {
    max-height: 3.5rem;
  }

  .sm\:dq-max-h-16 {
    max-height: 4rem;
  }

  .sm\:dq-max-h-20 {
    max-height: 5rem;
  }

  .sm\:dq-max-h-24 {
    max-height: 6rem;
  }

  .sm\:dq-max-h-28 {
    max-height: 7rem;
  }

  .sm\:dq-max-h-32 {
    max-height: 8rem;
  }

  .sm\:dq-max-h-36 {
    max-height: 9rem;
  }

  .sm\:dq-max-h-40 {
    max-height: 10rem;
  }

  .sm\:dq-max-h-44 {
    max-height: 11rem;
  }

  .sm\:dq-max-h-48 {
    max-height: 12rem;
  }

  .sm\:dq-max-h-52 {
    max-height: 13rem;
  }

  .sm\:dq-max-h-56 {
    max-height: 14rem;
  }

  .sm\:dq-max-h-60 {
    max-height: 15rem;
  }

  .sm\:dq-max-h-64 {
    max-height: 16rem;
  }

  .sm\:dq-max-h-72 {
    max-height: 18rem;
  }

  .sm\:dq-max-h-80 {
    max-height: 20rem;
  }

  .sm\:dq-max-h-96 {
    max-height: 24rem;
  }

  .sm\:dq-max-h-px {
    max-height: 1px;
  }

  .sm\:dq-max-h-0\.5 {
    max-height: 0.125rem;
  }

  .sm\:dq-max-h-1\.5 {
    max-height: 0.375rem;
  }

  .sm\:dq-max-h-2\.5 {
    max-height: 0.625rem;
  }

  .sm\:dq-max-h-3\.5 {
    max-height: 0.875rem;
  }

  .sm\:dq-max-h-full {
    max-height: 100%;
  }

  .sm\:dq-max-h-screen {
    max-height: 100vh;
  }

  .sm\:dq-min-h-0 {
    min-height: 0px;
  }

  .sm\:dq-min-h-full {
    min-height: 100%;
  }

  .sm\:dq-min-h-screen {
    min-height: 100vh;
  }

  .sm\:dq-w-0 {
    width: 0px;
  }

  .sm\:dq-w-1 {
    width: 0.25rem;
  }

  .sm\:dq-w-2 {
    width: 0.5rem;
  }

  .sm\:dq-w-3 {
    width: 0.75rem;
  }

  .sm\:dq-w-4 {
    width: 1rem;
  }

  .sm\:dq-w-5 {
    width: 1.25rem;
  }

  .sm\:dq-w-6 {
    width: 1.5rem;
  }

  .sm\:dq-w-7 {
    width: 1.75rem;
  }

  .sm\:dq-w-8 {
    width: 2rem;
  }

  .sm\:dq-w-9 {
    width: 2.25rem;
  }

  .sm\:dq-w-10 {
    width: 2.5rem;
  }

  .sm\:dq-w-11 {
    width: 2.75rem;
  }

  .sm\:dq-w-12 {
    width: 3rem;
  }

  .sm\:dq-w-14 {
    width: 3.5rem;
  }

  .sm\:dq-w-16 {
    width: 4rem;
  }

  .sm\:dq-w-20 {
    width: 5rem;
  }

  .sm\:dq-w-24 {
    width: 6rem;
  }

  .sm\:dq-w-28 {
    width: 7rem;
  }

  .sm\:dq-w-32 {
    width: 8rem;
  }

  .sm\:dq-w-36 {
    width: 9rem;
  }

  .sm\:dq-w-40 {
    width: 10rem;
  }

  .sm\:dq-w-44 {
    width: 11rem;
  }

  .sm\:dq-w-48 {
    width: 12rem;
  }

  .sm\:dq-w-52 {
    width: 13rem;
  }

  .sm\:dq-w-56 {
    width: 14rem;
  }

  .sm\:dq-w-60 {
    width: 15rem;
  }

  .sm\:dq-w-64 {
    width: 16rem;
  }

  .sm\:dq-w-72 {
    width: 18rem;
  }

  .sm\:dq-w-80 {
    width: 20rem;
  }

  .sm\:dq-w-96 {
    width: 24rem;
  }

  .sm\:dq-w-auto {
    width: auto;
  }

  .sm\:dq-w-px {
    width: 1px;
  }

  .sm\:dq-w-0\.5 {
    width: 0.125rem;
  }

  .sm\:dq-w-1\.5 {
    width: 0.375rem;
  }

  .sm\:dq-w-2\.5 {
    width: 0.625rem;
  }

  .sm\:dq-w-3\.5 {
    width: 0.875rem;
  }

  .sm\:dq-w-1\/2 {
    width: 50%;
  }

  .sm\:dq-w-1\/3 {
    width: 33.333333%;
  }

  .sm\:dq-w-2\/3 {
    width: 66.666667%;
  }

  .sm\:dq-w-1\/4 {
    width: 25%;
  }

  .sm\:dq-w-2\/4 {
    width: 50%;
  }

  .sm\:dq-w-3\/4 {
    width: 75%;
  }

  .sm\:dq-w-1\/5 {
    width: 20%;
  }

  .sm\:dq-w-2\/5 {
    width: 40%;
  }

  .sm\:dq-w-3\/5 {
    width: 60%;
  }

  .sm\:dq-w-4\/5 {
    width: 80%;
  }

  .sm\:dq-w-1\/6 {
    width: 16.666667%;
  }

  .sm\:dq-w-2\/6 {
    width: 33.333333%;
  }

  .sm\:dq-w-3\/6 {
    width: 50%;
  }

  .sm\:dq-w-4\/6 {
    width: 66.666667%;
  }

  .sm\:dq-w-5\/6 {
    width: 83.333333%;
  }

  .sm\:dq-w-1\/12 {
    width: 8.333333%;
  }

  .sm\:dq-w-2\/12 {
    width: 16.666667%;
  }

  .sm\:dq-w-3\/12 {
    width: 25%;
  }

  .sm\:dq-w-4\/12 {
    width: 33.333333%;
  }

  .sm\:dq-w-5\/12 {
    width: 41.666667%;
  }

  .sm\:dq-w-6\/12 {
    width: 50%;
  }

  .sm\:dq-w-7\/12 {
    width: 58.333333%;
  }

  .sm\:dq-w-8\/12 {
    width: 66.666667%;
  }

  .sm\:dq-w-9\/12 {
    width: 75%;
  }

  .sm\:dq-w-10\/12 {
    width: 83.333333%;
  }

  .sm\:dq-w-11\/12 {
    width: 91.666667%;
  }

  .sm\:dq-w-full {
    width: 100%;
  }

  .sm\:dq-w-screen {
    width: 100vw;
  }

  .sm\:dq-w-min {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }

  .sm\:dq-w-max {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .sm\:dq-min-w-0 {
    min-width: 0px;
  }

  .sm\:dq-min-w-full {
    min-width: 100%;
  }

  .sm\:dq-min-w-min {
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
  }

  .sm\:dq-min-w-max {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
  }

  .sm\:dq-max-w-0 {
    max-width: 0rem;
  }

  .sm\:dq-max-w-none {
    max-width: none;
  }

  .sm\:dq-max-w-xs {
    max-width: 20rem;
  }

  .sm\:dq-max-w-sm {
    max-width: 24rem;
  }

  .sm\:dq-max-w-md {
    max-width: 28rem;
  }

  .sm\:dq-max-w-lg {
    max-width: 32rem;
  }

  .sm\:dq-max-w-xl {
    max-width: 36rem;
  }

  .sm\:dq-max-w-2xl {
    max-width: 42rem;
  }

  .sm\:dq-max-w-3xl {
    max-width: 48rem;
  }

  .sm\:dq-max-w-4xl {
    max-width: 56rem;
  }

  .sm\:dq-max-w-5xl {
    max-width: 64rem;
  }

  .sm\:dq-max-w-6xl {
    max-width: 72rem;
  }

  .sm\:dq-max-w-7xl {
    max-width: 80rem;
  }

  .sm\:dq-max-w-full {
    max-width: 100%;
  }

  .sm\:dq-max-w-min {
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
  }

  .sm\:dq-max-w-max {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }

  .sm\:dq-max-w-prose {
    max-width: 65ch;
  }

  .sm\:dq-max-w-screen-sm {
    max-width: 640px;
  }

  .sm\:dq-max-w-screen-md {
    max-width: 768px;
  }

  .sm\:dq-max-w-screen-lg {
    max-width: 1024px;
  }

  .sm\:dq-max-w-screen-xl {
    max-width: 1280px;
  }

  .sm\:dq-max-w-screen-2xl {
    max-width: 1536px;
  }

  .sm\:dq-flex-1 {
    flex: 1 1 0%;
  }

  .sm\:dq-flex-auto {
    flex: 1 1 auto;
  }

  .sm\:dq-flex-initial {
    flex: 0 1 auto;
  }

  .sm\:dq-flex-none {
    flex: none;
  }

  .sm\:dq-flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:dq-flex-shrink {
    flex-shrink: 1;
  }

  .sm\:dq-flex-grow-0 {
    flex-grow: 0;
  }

  .sm\:dq-flex-grow {
    flex-grow: 1;
  }

  .sm\:dq-table-auto {
    table-layout: auto;
  }

  .sm\:dq-table-fixed {
    table-layout: fixed;
  }

  .sm\:dq-border-collapse {
    border-collapse: collapse;
  }

  .sm\:dq-border-separate {
    border-collapse: separate;
  }

  .sm\:dq-origin-center {
    transform-origin: center;
  }

  .sm\:dq-origin-top {
    transform-origin: top;
  }

  .sm\:dq-origin-top-right {
    transform-origin: top right;
  }

  .sm\:dq-origin-right {
    transform-origin: right;
  }

  .sm\:dq-origin-bottom-right {
    transform-origin: bottom right;
  }

  .sm\:dq-origin-bottom {
    transform-origin: bottom;
  }

  .sm\:dq-origin-bottom-left {
    transform-origin: bottom left;
  }

  .sm\:dq-origin-left {
    transform-origin: left;
  }

  .sm\:dq-origin-top-left {
    transform-origin: top left;
  }

  .sm\:dq-transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:dq-transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:dq-transform-none {
    transform: none;
  }

  .sm\:dq-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .sm\:dq-translate-x-1 {
    --tw-translate-x: 0.25rem;
  }

  .sm\:dq-translate-x-2 {
    --tw-translate-x: 0.5rem;
  }

  .sm\:dq-translate-x-3 {
    --tw-translate-x: 0.75rem;
  }

  .sm\:dq-translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .sm\:dq-translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .sm\:dq-translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .sm\:dq-translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .sm\:dq-translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .sm\:dq-translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .sm\:dq-translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .sm\:dq-translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .sm\:dq-translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .sm\:dq-translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .sm\:dq-translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .sm\:dq-translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .sm\:dq-translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .sm\:dq-translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .sm\:dq-translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .sm\:dq-translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .sm\:dq-translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .sm\:dq-translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .sm\:dq-translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .sm\:dq-translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .sm\:dq-translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .sm\:dq-translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .sm\:dq-translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .sm\:dq-translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .sm\:dq-translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .sm\:dq-translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .sm\:dq-translate-x-px {
    --tw-translate-x: 1px;
  }

  .sm\:dq-translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }

  .sm\:dq-translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }

  .sm\:dq-translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }

  .sm\:dq-translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }

  .sm\:dq--translate-x-0 {
    --tw-translate-x: 0px;
  }

  .sm\:dq--translate-x-1 {
    --tw-translate-x: -0.25rem;
  }

  .sm\:dq--translate-x-2 {
    --tw-translate-x: -0.5rem;
  }

  .sm\:dq--translate-x-3 {
    --tw-translate-x: -0.75rem;
  }

  .sm\:dq--translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .sm\:dq--translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .sm\:dq--translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .sm\:dq--translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .sm\:dq--translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .sm\:dq--translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .sm\:dq--translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .sm\:dq--translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .sm\:dq--translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .sm\:dq--translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .sm\:dq--translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .sm\:dq--translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .sm\:dq--translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .sm\:dq--translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .sm\:dq--translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .sm\:dq--translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .sm\:dq--translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .sm\:dq--translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .sm\:dq--translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .sm\:dq--translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .sm\:dq--translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .sm\:dq--translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .sm\:dq--translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .sm\:dq--translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .sm\:dq--translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .sm\:dq--translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .sm\:dq--translate-x-px {
    --tw-translate-x: -1px;
  }

  .sm\:dq--translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }

  .sm\:dq--translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }

  .sm\:dq--translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }

  .sm\:dq--translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }

  .sm\:dq-translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .sm\:dq-translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }

  .sm\:dq-translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }

  .sm\:dq-translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .sm\:dq-translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .sm\:dq-translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .sm\:dq-translate-x-full {
    --tw-translate-x: 100%;
  }

  .sm\:dq--translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .sm\:dq--translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }

  .sm\:dq--translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }

  .sm\:dq--translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .sm\:dq--translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .sm\:dq--translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .sm\:dq--translate-x-full {
    --tw-translate-x: -100%;
  }

  .sm\:dq-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .sm\:dq-translate-y-1 {
    --tw-translate-y: 0.25rem;
  }

  .sm\:dq-translate-y-2 {
    --tw-translate-y: 0.5rem;
  }

  .sm\:dq-translate-y-3 {
    --tw-translate-y: 0.75rem;
  }

  .sm\:dq-translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .sm\:dq-translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .sm\:dq-translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .sm\:dq-translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .sm\:dq-translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .sm\:dq-translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .sm\:dq-translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .sm\:dq-translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .sm\:dq-translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .sm\:dq-translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .sm\:dq-translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .sm\:dq-translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .sm\:dq-translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .sm\:dq-translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .sm\:dq-translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .sm\:dq-translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .sm\:dq-translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .sm\:dq-translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .sm\:dq-translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .sm\:dq-translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .sm\:dq-translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .sm\:dq-translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .sm\:dq-translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .sm\:dq-translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .sm\:dq-translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .sm\:dq-translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .sm\:dq-translate-y-px {
    --tw-translate-y: 1px;
  }

  .sm\:dq-translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }

  .sm\:dq-translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }

  .sm\:dq-translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }

  .sm\:dq-translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }

  .sm\:dq--translate-y-0 {
    --tw-translate-y: 0px;
  }

  .sm\:dq--translate-y-1 {
    --tw-translate-y: -0.25rem;
  }

  .sm\:dq--translate-y-2 {
    --tw-translate-y: -0.5rem;
  }

  .sm\:dq--translate-y-3 {
    --tw-translate-y: -0.75rem;
  }

  .sm\:dq--translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .sm\:dq--translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .sm\:dq--translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .sm\:dq--translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .sm\:dq--translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .sm\:dq--translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .sm\:dq--translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .sm\:dq--translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .sm\:dq--translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .sm\:dq--translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .sm\:dq--translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .sm\:dq--translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .sm\:dq--translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .sm\:dq--translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .sm\:dq--translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .sm\:dq--translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .sm\:dq--translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .sm\:dq--translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .sm\:dq--translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .sm\:dq--translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .sm\:dq--translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .sm\:dq--translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .sm\:dq--translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .sm\:dq--translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .sm\:dq--translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .sm\:dq--translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .sm\:dq--translate-y-px {
    --tw-translate-y: -1px;
  }

  .sm\:dq--translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }

  .sm\:dq--translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }

  .sm\:dq--translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }

  .sm\:dq--translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }

  .sm\:dq-translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .sm\:dq-translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }

  .sm\:dq-translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }

  .sm\:dq-translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .sm\:dq-translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .sm\:dq-translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .sm\:dq-translate-y-full {
    --tw-translate-y: 100%;
  }

  .sm\:dq--translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .sm\:dq--translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }

  .sm\:dq--translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }

  .sm\:dq--translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .sm\:dq--translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .sm\:dq--translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .sm\:dq--translate-y-full {
    --tw-translate-y: -100%;
  }

  .sm\:hover\:dq-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .sm\:hover\:dq-translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }

  .sm\:hover\:dq-translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }

  .sm\:hover\:dq-translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }

  .sm\:hover\:dq-translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .sm\:hover\:dq-translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .sm\:hover\:dq-translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .sm\:hover\:dq-translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .sm\:hover\:dq-translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .sm\:hover\:dq-translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .sm\:hover\:dq-translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .sm\:hover\:dq-translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .sm\:hover\:dq-translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .sm\:hover\:dq-translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .sm\:hover\:dq-translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .sm\:hover\:dq-translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .sm\:hover\:dq-translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .sm\:hover\:dq-translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .sm\:hover\:dq-translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .sm\:hover\:dq-translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .sm\:hover\:dq-translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .sm\:hover\:dq-translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .sm\:hover\:dq-translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .sm\:hover\:dq-translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .sm\:hover\:dq-translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .sm\:hover\:dq-translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .sm\:hover\:dq-translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .sm\:hover\:dq-translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .sm\:hover\:dq-translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .sm\:hover\:dq-translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .sm\:hover\:dq-translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .sm\:hover\:dq-translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }

  .sm\:hover\:dq-translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }

  .sm\:hover\:dq-translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }

  .sm\:hover\:dq-translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }

  .sm\:hover\:dq--translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .sm\:hover\:dq--translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }

  .sm\:hover\:dq--translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }

  .sm\:hover\:dq--translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }

  .sm\:hover\:dq--translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .sm\:hover\:dq--translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .sm\:hover\:dq--translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .sm\:hover\:dq--translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .sm\:hover\:dq--translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .sm\:hover\:dq--translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .sm\:hover\:dq--translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .sm\:hover\:dq--translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .sm\:hover\:dq--translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .sm\:hover\:dq--translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .sm\:hover\:dq--translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .sm\:hover\:dq--translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .sm\:hover\:dq--translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .sm\:hover\:dq--translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .sm\:hover\:dq--translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .sm\:hover\:dq--translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .sm\:hover\:dq--translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .sm\:hover\:dq--translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .sm\:hover\:dq--translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .sm\:hover\:dq--translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .sm\:hover\:dq--translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .sm\:hover\:dq--translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .sm\:hover\:dq--translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .sm\:hover\:dq--translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .sm\:hover\:dq--translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .sm\:hover\:dq--translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .sm\:hover\:dq--translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .sm\:hover\:dq--translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }

  .sm\:hover\:dq--translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }

  .sm\:hover\:dq--translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }

  .sm\:hover\:dq--translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }

  .sm\:hover\:dq-translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .sm\:hover\:dq-translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }

  .sm\:hover\:dq-translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }

  .sm\:hover\:dq-translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .sm\:hover\:dq-translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .sm\:hover\:dq-translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .sm\:hover\:dq-translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .sm\:hover\:dq--translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .sm\:hover\:dq--translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }

  .sm\:hover\:dq--translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }

  .sm\:hover\:dq--translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .sm\:hover\:dq--translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .sm\:hover\:dq--translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .sm\:hover\:dq--translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .sm\:hover\:dq-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .sm\:hover\:dq-translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }

  .sm\:hover\:dq-translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }

  .sm\:hover\:dq-translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }

  .sm\:hover\:dq-translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .sm\:hover\:dq-translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .sm\:hover\:dq-translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .sm\:hover\:dq-translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .sm\:hover\:dq-translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .sm\:hover\:dq-translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .sm\:hover\:dq-translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .sm\:hover\:dq-translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .sm\:hover\:dq-translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .sm\:hover\:dq-translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .sm\:hover\:dq-translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .sm\:hover\:dq-translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .sm\:hover\:dq-translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .sm\:hover\:dq-translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .sm\:hover\:dq-translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .sm\:hover\:dq-translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .sm\:hover\:dq-translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .sm\:hover\:dq-translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .sm\:hover\:dq-translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .sm\:hover\:dq-translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .sm\:hover\:dq-translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .sm\:hover\:dq-translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .sm\:hover\:dq-translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .sm\:hover\:dq-translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .sm\:hover\:dq-translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .sm\:hover\:dq-translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .sm\:hover\:dq-translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .sm\:hover\:dq-translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }

  .sm\:hover\:dq-translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }

  .sm\:hover\:dq-translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }

  .sm\:hover\:dq-translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }

  .sm\:hover\:dq--translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .sm\:hover\:dq--translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }

  .sm\:hover\:dq--translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }

  .sm\:hover\:dq--translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }

  .sm\:hover\:dq--translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .sm\:hover\:dq--translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .sm\:hover\:dq--translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .sm\:hover\:dq--translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .sm\:hover\:dq--translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .sm\:hover\:dq--translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .sm\:hover\:dq--translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .sm\:hover\:dq--translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .sm\:hover\:dq--translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .sm\:hover\:dq--translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .sm\:hover\:dq--translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .sm\:hover\:dq--translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .sm\:hover\:dq--translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .sm\:hover\:dq--translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .sm\:hover\:dq--translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .sm\:hover\:dq--translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .sm\:hover\:dq--translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .sm\:hover\:dq--translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .sm\:hover\:dq--translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .sm\:hover\:dq--translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .sm\:hover\:dq--translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .sm\:hover\:dq--translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .sm\:hover\:dq--translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .sm\:hover\:dq--translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .sm\:hover\:dq--translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .sm\:hover\:dq--translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .sm\:hover\:dq--translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .sm\:hover\:dq--translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }

  .sm\:hover\:dq--translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }

  .sm\:hover\:dq--translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }

  .sm\:hover\:dq--translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }

  .sm\:hover\:dq-translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .sm\:hover\:dq-translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }

  .sm\:hover\:dq-translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }

  .sm\:hover\:dq-translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .sm\:hover\:dq-translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .sm\:hover\:dq-translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .sm\:hover\:dq-translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .sm\:hover\:dq--translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .sm\:hover\:dq--translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }

  .sm\:hover\:dq--translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }

  .sm\:hover\:dq--translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .sm\:hover\:dq--translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .sm\:hover\:dq--translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .sm\:hover\:dq--translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .sm\:focus\:dq-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .sm\:focus\:dq-translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }

  .sm\:focus\:dq-translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }

  .sm\:focus\:dq-translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }

  .sm\:focus\:dq-translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .sm\:focus\:dq-translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .sm\:focus\:dq-translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .sm\:focus\:dq-translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .sm\:focus\:dq-translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .sm\:focus\:dq-translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .sm\:focus\:dq-translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .sm\:focus\:dq-translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .sm\:focus\:dq-translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .sm\:focus\:dq-translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .sm\:focus\:dq-translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .sm\:focus\:dq-translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .sm\:focus\:dq-translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .sm\:focus\:dq-translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .sm\:focus\:dq-translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .sm\:focus\:dq-translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .sm\:focus\:dq-translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .sm\:focus\:dq-translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .sm\:focus\:dq-translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .sm\:focus\:dq-translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .sm\:focus\:dq-translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .sm\:focus\:dq-translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .sm\:focus\:dq-translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .sm\:focus\:dq-translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .sm\:focus\:dq-translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .sm\:focus\:dq-translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .sm\:focus\:dq-translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .sm\:focus\:dq-translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }

  .sm\:focus\:dq-translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }

  .sm\:focus\:dq-translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }

  .sm\:focus\:dq-translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }

  .sm\:focus\:dq--translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .sm\:focus\:dq--translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }

  .sm\:focus\:dq--translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }

  .sm\:focus\:dq--translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }

  .sm\:focus\:dq--translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .sm\:focus\:dq--translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .sm\:focus\:dq--translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .sm\:focus\:dq--translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .sm\:focus\:dq--translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .sm\:focus\:dq--translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .sm\:focus\:dq--translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .sm\:focus\:dq--translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .sm\:focus\:dq--translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .sm\:focus\:dq--translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .sm\:focus\:dq--translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .sm\:focus\:dq--translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .sm\:focus\:dq--translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .sm\:focus\:dq--translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .sm\:focus\:dq--translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .sm\:focus\:dq--translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .sm\:focus\:dq--translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .sm\:focus\:dq--translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .sm\:focus\:dq--translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .sm\:focus\:dq--translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .sm\:focus\:dq--translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .sm\:focus\:dq--translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .sm\:focus\:dq--translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .sm\:focus\:dq--translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .sm\:focus\:dq--translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .sm\:focus\:dq--translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .sm\:focus\:dq--translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .sm\:focus\:dq--translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }

  .sm\:focus\:dq--translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }

  .sm\:focus\:dq--translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }

  .sm\:focus\:dq--translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }

  .sm\:focus\:dq-translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .sm\:focus\:dq-translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }

  .sm\:focus\:dq-translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }

  .sm\:focus\:dq-translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .sm\:focus\:dq-translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .sm\:focus\:dq-translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .sm\:focus\:dq-translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .sm\:focus\:dq--translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .sm\:focus\:dq--translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }

  .sm\:focus\:dq--translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }

  .sm\:focus\:dq--translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .sm\:focus\:dq--translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .sm\:focus\:dq--translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .sm\:focus\:dq--translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .sm\:focus\:dq-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .sm\:focus\:dq-translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }

  .sm\:focus\:dq-translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }

  .sm\:focus\:dq-translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }

  .sm\:focus\:dq-translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .sm\:focus\:dq-translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .sm\:focus\:dq-translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .sm\:focus\:dq-translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .sm\:focus\:dq-translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .sm\:focus\:dq-translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .sm\:focus\:dq-translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .sm\:focus\:dq-translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .sm\:focus\:dq-translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .sm\:focus\:dq-translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .sm\:focus\:dq-translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .sm\:focus\:dq-translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .sm\:focus\:dq-translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .sm\:focus\:dq-translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .sm\:focus\:dq-translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .sm\:focus\:dq-translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .sm\:focus\:dq-translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .sm\:focus\:dq-translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .sm\:focus\:dq-translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .sm\:focus\:dq-translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .sm\:focus\:dq-translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .sm\:focus\:dq-translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .sm\:focus\:dq-translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .sm\:focus\:dq-translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .sm\:focus\:dq-translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .sm\:focus\:dq-translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .sm\:focus\:dq-translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .sm\:focus\:dq-translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }

  .sm\:focus\:dq-translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }

  .sm\:focus\:dq-translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }

  .sm\:focus\:dq-translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }

  .sm\:focus\:dq--translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .sm\:focus\:dq--translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }

  .sm\:focus\:dq--translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }

  .sm\:focus\:dq--translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }

  .sm\:focus\:dq--translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .sm\:focus\:dq--translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .sm\:focus\:dq--translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .sm\:focus\:dq--translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .sm\:focus\:dq--translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .sm\:focus\:dq--translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .sm\:focus\:dq--translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .sm\:focus\:dq--translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .sm\:focus\:dq--translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .sm\:focus\:dq--translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .sm\:focus\:dq--translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .sm\:focus\:dq--translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .sm\:focus\:dq--translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .sm\:focus\:dq--translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .sm\:focus\:dq--translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .sm\:focus\:dq--translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .sm\:focus\:dq--translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .sm\:focus\:dq--translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .sm\:focus\:dq--translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .sm\:focus\:dq--translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .sm\:focus\:dq--translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .sm\:focus\:dq--translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .sm\:focus\:dq--translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .sm\:focus\:dq--translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .sm\:focus\:dq--translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .sm\:focus\:dq--translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .sm\:focus\:dq--translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .sm\:focus\:dq--translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }

  .sm\:focus\:dq--translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }

  .sm\:focus\:dq--translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }

  .sm\:focus\:dq--translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }

  .sm\:focus\:dq-translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .sm\:focus\:dq-translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }

  .sm\:focus\:dq-translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }

  .sm\:focus\:dq-translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .sm\:focus\:dq-translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .sm\:focus\:dq-translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .sm\:focus\:dq-translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .sm\:focus\:dq--translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .sm\:focus\:dq--translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }

  .sm\:focus\:dq--translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }

  .sm\:focus\:dq--translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .sm\:focus\:dq--translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .sm\:focus\:dq--translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .sm\:focus\:dq--translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .sm\:dq-rotate-0 {
    --tw-rotate: 0deg;
  }

  .sm\:dq-rotate-1 {
    --tw-rotate: 1deg;
  }

  .sm\:dq-rotate-2 {
    --tw-rotate: 2deg;
  }

  .sm\:dq-rotate-3 {
    --tw-rotate: 3deg;
  }

  .sm\:dq-rotate-6 {
    --tw-rotate: 6deg;
  }

  .sm\:dq-rotate-12 {
    --tw-rotate: 12deg;
  }

  .sm\:dq-rotate-45 {
    --tw-rotate: 45deg;
  }

  .sm\:dq-rotate-90 {
    --tw-rotate: 90deg;
  }

  .sm\:dq-rotate-180 {
    --tw-rotate: 180deg;
  }

  .sm\:dq--rotate-180 {
    --tw-rotate: -180deg;
  }

  .sm\:dq--rotate-90 {
    --tw-rotate: -90deg;
  }

  .sm\:dq--rotate-45 {
    --tw-rotate: -45deg;
  }

  .sm\:dq--rotate-12 {
    --tw-rotate: -12deg;
  }

  .sm\:dq--rotate-6 {
    --tw-rotate: -6deg;
  }

  .sm\:dq--rotate-3 {
    --tw-rotate: -3deg;
  }

  .sm\:dq--rotate-2 {
    --tw-rotate: -2deg;
  }

  .sm\:dq--rotate-1 {
    --tw-rotate: -1deg;
  }

  .sm\:hover\:dq-rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .sm\:hover\:dq-rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .sm\:hover\:dq-rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .sm\:hover\:dq-rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .sm\:hover\:dq-rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .sm\:hover\:dq-rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .sm\:hover\:dq-rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .sm\:hover\:dq-rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .sm\:hover\:dq-rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .sm\:hover\:dq--rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .sm\:hover\:dq--rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .sm\:hover\:dq--rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .sm\:hover\:dq--rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .sm\:hover\:dq--rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .sm\:hover\:dq--rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .sm\:hover\:dq--rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .sm\:hover\:dq--rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .sm\:focus\:dq-rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .sm\:focus\:dq-rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .sm\:focus\:dq-rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .sm\:focus\:dq-rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .sm\:focus\:dq-rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .sm\:focus\:dq-rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .sm\:focus\:dq-rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .sm\:focus\:dq-rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .sm\:focus\:dq-rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .sm\:focus\:dq--rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .sm\:focus\:dq--rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .sm\:focus\:dq--rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .sm\:focus\:dq--rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .sm\:focus\:dq--rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .sm\:focus\:dq--rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .sm\:focus\:dq--rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .sm\:focus\:dq--rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .sm\:dq-skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .sm\:dq-skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .sm\:dq-skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .sm\:dq-skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .sm\:dq-skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .sm\:dq-skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .sm\:dq--skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .sm\:dq--skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .sm\:dq--skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .sm\:dq--skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .sm\:dq--skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .sm\:dq-skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .sm\:dq-skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .sm\:dq-skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .sm\:dq-skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .sm\:dq-skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .sm\:dq-skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .sm\:dq--skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .sm\:dq--skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .sm\:dq--skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .sm\:dq--skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .sm\:dq--skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .sm\:hover\:dq-skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .sm\:hover\:dq-skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .sm\:hover\:dq-skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .sm\:hover\:dq-skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .sm\:hover\:dq-skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .sm\:hover\:dq-skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .sm\:hover\:dq--skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .sm\:hover\:dq--skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .sm\:hover\:dq--skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .sm\:hover\:dq--skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .sm\:hover\:dq--skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .sm\:hover\:dq-skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .sm\:hover\:dq-skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .sm\:hover\:dq-skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .sm\:hover\:dq-skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .sm\:hover\:dq-skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .sm\:hover\:dq-skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .sm\:hover\:dq--skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .sm\:hover\:dq--skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .sm\:hover\:dq--skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .sm\:hover\:dq--skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .sm\:hover\:dq--skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .sm\:focus\:dq-skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .sm\:focus\:dq-skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .sm\:focus\:dq-skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .sm\:focus\:dq-skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .sm\:focus\:dq-skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .sm\:focus\:dq-skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .sm\:focus\:dq--skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .sm\:focus\:dq--skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .sm\:focus\:dq--skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .sm\:focus\:dq--skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .sm\:focus\:dq--skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .sm\:focus\:dq-skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .sm\:focus\:dq-skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .sm\:focus\:dq-skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .sm\:focus\:dq-skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .sm\:focus\:dq-skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .sm\:focus\:dq-skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .sm\:focus\:dq--skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .sm\:focus\:dq--skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .sm\:focus\:dq--skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .sm\:focus\:dq--skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .sm\:focus\:dq--skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .sm\:dq-scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:dq-scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:dq-scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:dq-scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:dq-scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:dq-scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:dq-scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:dq-scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:dq-scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:dq-scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:hover\:dq-scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:hover\:dq-scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:hover\:dq-scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:hover\:dq-scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:hover\:dq-scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:hover\:dq-scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:hover\:dq-scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:hover\:dq-scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:hover\:dq-scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:hover\:dq-scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:focus\:dq-scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:focus\:dq-scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:focus\:dq-scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:focus\:dq-scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:focus\:dq-scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:focus\:dq-scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:focus\:dq-scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:focus\:dq-scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:focus\:dq-scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:focus\:dq-scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:dq-scale-x-0 {
    --tw-scale-x: 0;
  }

  .sm\:dq-scale-x-50 {
    --tw-scale-x: .5;
  }

  .sm\:dq-scale-x-75 {
    --tw-scale-x: .75;
  }

  .sm\:dq-scale-x-90 {
    --tw-scale-x: .9;
  }

  .sm\:dq-scale-x-95 {
    --tw-scale-x: .95;
  }

  .sm\:dq-scale-x-100 {
    --tw-scale-x: 1;
  }

  .sm\:dq-scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .sm\:dq-scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .sm\:dq-scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .sm\:dq-scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .sm\:dq-scale-y-0 {
    --tw-scale-y: 0;
  }

  .sm\:dq-scale-y-50 {
    --tw-scale-y: .5;
  }

  .sm\:dq-scale-y-75 {
    --tw-scale-y: .75;
  }

  .sm\:dq-scale-y-90 {
    --tw-scale-y: .9;
  }

  .sm\:dq-scale-y-95 {
    --tw-scale-y: .95;
  }

  .sm\:dq-scale-y-100 {
    --tw-scale-y: 1;
  }

  .sm\:dq-scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .sm\:dq-scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .sm\:dq-scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .sm\:dq-scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .sm\:hover\:dq-scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .sm\:hover\:dq-scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .sm\:hover\:dq-scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .sm\:hover\:dq-scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .sm\:hover\:dq-scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .sm\:hover\:dq-scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .sm\:hover\:dq-scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .sm\:hover\:dq-scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .sm\:hover\:dq-scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .sm\:hover\:dq-scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .sm\:hover\:dq-scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .sm\:hover\:dq-scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .sm\:hover\:dq-scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .sm\:hover\:dq-scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .sm\:hover\:dq-scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .sm\:hover\:dq-scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .sm\:hover\:dq-scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .sm\:hover\:dq-scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .sm\:hover\:dq-scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .sm\:hover\:dq-scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .sm\:focus\:dq-scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .sm\:focus\:dq-scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .sm\:focus\:dq-scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .sm\:focus\:dq-scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .sm\:focus\:dq-scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .sm\:focus\:dq-scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .sm\:focus\:dq-scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .sm\:focus\:dq-scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .sm\:focus\:dq-scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .sm\:focus\:dq-scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .sm\:focus\:dq-scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .sm\:focus\:dq-scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .sm\:focus\:dq-scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .sm\:focus\:dq-scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .sm\:focus\:dq-scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .sm\:focus\:dq-scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .sm\:focus\:dq-scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .sm\:focus\:dq-scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .sm\:focus\:dq-scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .sm\:focus\:dq-scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .sm\:dq-animate-none {
    animation: none;
  }

  .sm\:dq-animate-spin {
    animation: dq-spin 1s linear infinite;
  }

  .sm\:dq-animate-ping {
    animation: dq-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .sm\:dq-animate-pulse {
    animation: dq-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .sm\:dq-animate-bounce {
    animation: dq-bounce 1s infinite;
  }

  .sm\:dq-cursor-auto {
    cursor: auto;
  }

  .sm\:dq-cursor-default {
    cursor: default;
  }

  .sm\:dq-cursor-pointer {
    cursor: pointer;
  }

  .sm\:dq-cursor-wait {
    cursor: wait;
  }

  .sm\:dq-cursor-text {
    cursor: text;
  }

  .sm\:dq-cursor-move {
    cursor: move;
  }

  .sm\:dq-cursor-help {
    cursor: help;
  }

  .sm\:dq-cursor-not-allowed {
    cursor: not-allowed;
  }

  .sm\:dq-select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .sm\:dq-select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .sm\:dq-select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .sm\:dq-select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }

  .sm\:dq-resize-none {
    resize: none;
  }

  .sm\:dq-resize-y {
    resize: vertical;
  }

  .sm\:dq-resize-x {
    resize: horizontal;
  }

  .sm\:dq-resize {
    resize: both;
  }

  .sm\:dq-list-inside {
    list-style-position: inside;
  }

  .sm\:dq-list-outside {
    list-style-position: outside;
  }

  .sm\:dq-list-none {
    list-style-type: none;
  }

  .sm\:dq-list-disc {
    list-style-type: disc;
  }

  .sm\:dq-list-decimal {
    list-style-type: decimal;
  }

  .sm\:dq-appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  .sm\:dq-auto-cols-auto {
    grid-auto-columns: auto;
  }

  .sm\:dq-auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .sm\:dq-auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .sm\:dq-auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .sm\:dq-grid-flow-row {
    grid-auto-flow: row;
  }

  .sm\:dq-grid-flow-col {
    grid-auto-flow: column;
  }

  .sm\:dq-grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .sm\:dq-grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .sm\:dq-auto-rows-auto {
    grid-auto-rows: auto;
  }

  .sm\:dq-auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .sm\:dq-auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .sm\:dq-auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .sm\:dq-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:dq-grid-cols-none {
    grid-template-columns: none;
  }

  .sm\:dq-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .sm\:dq-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .sm\:dq-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .sm\:dq-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .sm\:dq-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .sm\:dq-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .sm\:dq-grid-rows-none {
    grid-template-rows: none;
  }

  .sm\:dq-flex-row {
    flex-direction: row;
  }

  .sm\:dq-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:dq-flex-col {
    flex-direction: column;
  }

  .sm\:dq-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm\:dq-flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:dq-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .sm\:dq-flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:dq-place-content-center {
    place-content: center;
  }

  .sm\:dq-place-content-start {
    place-content: start;
  }

  .sm\:dq-place-content-end {
    place-content: end;
  }

  .sm\:dq-place-content-between {
    place-content: space-between;
  }

  .sm\:dq-place-content-around {
    place-content: space-around;
  }

  .sm\:dq-place-content-evenly {
    place-content: space-evenly;
  }

  .sm\:dq-place-content-stretch {
    place-content: stretch;
  }

  .sm\:dq-place-items-start {
    place-items: start;
  }

  .sm\:dq-place-items-end {
    place-items: end;
  }

  .sm\:dq-place-items-center {
    place-items: center;
  }

  .sm\:dq-place-items-stretch {
    place-items: stretch;
  }

  .sm\:dq-content-center {
    align-content: center;
  }

  .sm\:dq-content-start {
    align-content: flex-start;
  }

  .sm\:dq-content-end {
    align-content: flex-end;
  }

  .sm\:dq-content-between {
    align-content: space-between;
  }

  .sm\:dq-content-around {
    align-content: space-around;
  }

  .sm\:dq-content-evenly {
    align-content: space-evenly;
  }

  .sm\:dq-items-start {
    align-items: flex-start;
  }

  .sm\:dq-items-end {
    align-items: flex-end;
  }

  .sm\:dq-items-center {
    align-items: center;
  }

  .sm\:dq-items-baseline {
    align-items: baseline;
  }

  .sm\:dq-items-stretch {
    align-items: stretch;
  }

  .sm\:dq-justify-start {
    justify-content: flex-start;
  }

  .sm\:dq-justify-end {
    justify-content: flex-end;
  }

  .sm\:dq-justify-center {
    justify-content: center;
  }

  .sm\:dq-justify-between {
    justify-content: space-between;
  }

  .sm\:dq-justify-around {
    justify-content: space-around;
  }

  .sm\:dq-justify-evenly {
    justify-content: space-evenly;
  }

  .sm\:dq-justify-items-start {
    justify-items: start;
  }

  .sm\:dq-justify-items-end {
    justify-items: end;
  }

  .sm\:dq-justify-items-center {
    justify-items: center;
  }

  .sm\:dq-justify-items-stretch {
    justify-items: stretch;
  }

  .sm\:dq-gap-0 {
    gap: 0px;
  }

  .sm\:dq-gap-1 {
    gap: 0.25rem;
  }

  .sm\:dq-gap-2 {
    gap: 0.5rem;
  }

  .sm\:dq-gap-3 {
    gap: 0.75rem;
  }

  .sm\:dq-gap-4 {
    gap: 1rem;
  }

  .sm\:dq-gap-5 {
    gap: 1.25rem;
  }

  .sm\:dq-gap-6 {
    gap: 1.5rem;
  }

  .sm\:dq-gap-7 {
    gap: 1.75rem;
  }

  .sm\:dq-gap-8 {
    gap: 2rem;
  }

  .sm\:dq-gap-9 {
    gap: 2.25rem;
  }

  .sm\:dq-gap-10 {
    gap: 2.5rem;
  }

  .sm\:dq-gap-11 {
    gap: 2.75rem;
  }

  .sm\:dq-gap-12 {
    gap: 3rem;
  }

  .sm\:dq-gap-14 {
    gap: 3.5rem;
  }

  .sm\:dq-gap-16 {
    gap: 4rem;
  }

  .sm\:dq-gap-20 {
    gap: 5rem;
  }

  .sm\:dq-gap-24 {
    gap: 6rem;
  }

  .sm\:dq-gap-28 {
    gap: 7rem;
  }

  .sm\:dq-gap-32 {
    gap: 8rem;
  }

  .sm\:dq-gap-36 {
    gap: 9rem;
  }

  .sm\:dq-gap-40 {
    gap: 10rem;
  }

  .sm\:dq-gap-44 {
    gap: 11rem;
  }

  .sm\:dq-gap-48 {
    gap: 12rem;
  }

  .sm\:dq-gap-52 {
    gap: 13rem;
  }

  .sm\:dq-gap-56 {
    gap: 14rem;
  }

  .sm\:dq-gap-60 {
    gap: 15rem;
  }

  .sm\:dq-gap-64 {
    gap: 16rem;
  }

  .sm\:dq-gap-72 {
    gap: 18rem;
  }

  .sm\:dq-gap-80 {
    gap: 20rem;
  }

  .sm\:dq-gap-96 {
    gap: 24rem;
  }

  .sm\:dq-gap-px {
    gap: 1px;
  }

  .sm\:dq-gap-0\.5 {
    gap: 0.125rem;
  }

  .sm\:dq-gap-1\.5 {
    gap: 0.375rem;
  }

  .sm\:dq-gap-2\.5 {
    gap: 0.625rem;
  }

  .sm\:dq-gap-3\.5 {
    gap: 0.875rem;
  }

  .sm\:dq-gap-x-0 {
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .sm\:dq-gap-x-1 {
    -webkit-column-gap: 0.25rem;
            column-gap: 0.25rem;
  }

  .sm\:dq-gap-x-2 {
    -webkit-column-gap: 0.5rem;
            column-gap: 0.5rem;
  }

  .sm\:dq-gap-x-3 {
    -webkit-column-gap: 0.75rem;
            column-gap: 0.75rem;
  }

  .sm\:dq-gap-x-4 {
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
  }

  .sm\:dq-gap-x-5 {
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
  }

  .sm\:dq-gap-x-6 {
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
  }

  .sm\:dq-gap-x-7 {
    -webkit-column-gap: 1.75rem;
            column-gap: 1.75rem;
  }

  .sm\:dq-gap-x-8 {
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }

  .sm\:dq-gap-x-9 {
    -webkit-column-gap: 2.25rem;
            column-gap: 2.25rem;
  }

  .sm\:dq-gap-x-10 {
    -webkit-column-gap: 2.5rem;
            column-gap: 2.5rem;
  }

  .sm\:dq-gap-x-11 {
    -webkit-column-gap: 2.75rem;
            column-gap: 2.75rem;
  }

  .sm\:dq-gap-x-12 {
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }

  .sm\:dq-gap-x-14 {
    -webkit-column-gap: 3.5rem;
            column-gap: 3.5rem;
  }

  .sm\:dq-gap-x-16 {
    -webkit-column-gap: 4rem;
            column-gap: 4rem;
  }

  .sm\:dq-gap-x-20 {
    -webkit-column-gap: 5rem;
            column-gap: 5rem;
  }

  .sm\:dq-gap-x-24 {
    -webkit-column-gap: 6rem;
            column-gap: 6rem;
  }

  .sm\:dq-gap-x-28 {
    -webkit-column-gap: 7rem;
            column-gap: 7rem;
  }

  .sm\:dq-gap-x-32 {
    -webkit-column-gap: 8rem;
            column-gap: 8rem;
  }

  .sm\:dq-gap-x-36 {
    -webkit-column-gap: 9rem;
            column-gap: 9rem;
  }

  .sm\:dq-gap-x-40 {
    -webkit-column-gap: 10rem;
            column-gap: 10rem;
  }

  .sm\:dq-gap-x-44 {
    -webkit-column-gap: 11rem;
            column-gap: 11rem;
  }

  .sm\:dq-gap-x-48 {
    -webkit-column-gap: 12rem;
            column-gap: 12rem;
  }

  .sm\:dq-gap-x-52 {
    -webkit-column-gap: 13rem;
            column-gap: 13rem;
  }

  .sm\:dq-gap-x-56 {
    -webkit-column-gap: 14rem;
            column-gap: 14rem;
  }

  .sm\:dq-gap-x-60 {
    -webkit-column-gap: 15rem;
            column-gap: 15rem;
  }

  .sm\:dq-gap-x-64 {
    -webkit-column-gap: 16rem;
            column-gap: 16rem;
  }

  .sm\:dq-gap-x-72 {
    -webkit-column-gap: 18rem;
            column-gap: 18rem;
  }

  .sm\:dq-gap-x-80 {
    -webkit-column-gap: 20rem;
            column-gap: 20rem;
  }

  .sm\:dq-gap-x-96 {
    -webkit-column-gap: 24rem;
            column-gap: 24rem;
  }

  .sm\:dq-gap-x-px {
    -webkit-column-gap: 1px;
            column-gap: 1px;
  }

  .sm\:dq-gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
            column-gap: 0.125rem;
  }

  .sm\:dq-gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
            column-gap: 0.375rem;
  }

  .sm\:dq-gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
            column-gap: 0.625rem;
  }

  .sm\:dq-gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
            column-gap: 0.875rem;
  }

  .sm\:dq-gap-y-0 {
    row-gap: 0px;
  }

  .sm\:dq-gap-y-1 {
    row-gap: 0.25rem;
  }

  .sm\:dq-gap-y-2 {
    row-gap: 0.5rem;
  }

  .sm\:dq-gap-y-3 {
    row-gap: 0.75rem;
  }

  .sm\:dq-gap-y-4 {
    row-gap: 1rem;
  }

  .sm\:dq-gap-y-5 {
    row-gap: 1.25rem;
  }

  .sm\:dq-gap-y-6 {
    row-gap: 1.5rem;
  }

  .sm\:dq-gap-y-7 {
    row-gap: 1.75rem;
  }

  .sm\:dq-gap-y-8 {
    row-gap: 2rem;
  }

  .sm\:dq-gap-y-9 {
    row-gap: 2.25rem;
  }

  .sm\:dq-gap-y-10 {
    row-gap: 2.5rem;
  }

  .sm\:dq-gap-y-11 {
    row-gap: 2.75rem;
  }

  .sm\:dq-gap-y-12 {
    row-gap: 3rem;
  }

  .sm\:dq-gap-y-14 {
    row-gap: 3.5rem;
  }

  .sm\:dq-gap-y-16 {
    row-gap: 4rem;
  }

  .sm\:dq-gap-y-20 {
    row-gap: 5rem;
  }

  .sm\:dq-gap-y-24 {
    row-gap: 6rem;
  }

  .sm\:dq-gap-y-28 {
    row-gap: 7rem;
  }

  .sm\:dq-gap-y-32 {
    row-gap: 8rem;
  }

  .sm\:dq-gap-y-36 {
    row-gap: 9rem;
  }

  .sm\:dq-gap-y-40 {
    row-gap: 10rem;
  }

  .sm\:dq-gap-y-44 {
    row-gap: 11rem;
  }

  .sm\:dq-gap-y-48 {
    row-gap: 12rem;
  }

  .sm\:dq-gap-y-52 {
    row-gap: 13rem;
  }

  .sm\:dq-gap-y-56 {
    row-gap: 14rem;
  }

  .sm\:dq-gap-y-60 {
    row-gap: 15rem;
  }

  .sm\:dq-gap-y-64 {
    row-gap: 16rem;
  }

  .sm\:dq-gap-y-72 {
    row-gap: 18rem;
  }

  .sm\:dq-gap-y-80 {
    row-gap: 20rem;
  }

  .sm\:dq-gap-y-96 {
    row-gap: 24rem;
  }

  .sm\:dq-gap-y-px {
    row-gap: 1px;
  }

  .sm\:dq-gap-y-0\.5 {
    row-gap: 0.125rem;
  }

  .sm\:dq-gap-y-1\.5 {
    row-gap: 0.375rem;
  }

  .sm\:dq-gap-y-2\.5 {
    row-gap: 0.625rem;
  }

  .sm\:dq-gap-y-3\.5 {
    row-gap: 0.875rem;
  }

  .sm\:dq-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq--space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:dq-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }

  .sm\:dq--space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }

  .sm\:dq-space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .sm\:dq-space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .sm\:dq-divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:dq-divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:dq-divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:dq-divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:dq-divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:dq-divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .sm\:dq-divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .sm\:dq-divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .sm\:dq-divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .sm\:dq-divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .sm\:dq-divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .sm\:dq-divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .sm\:dq-divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .sm\:dq-divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .sm\:dq-divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .sm\:dq-divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .sm\:dq-divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .sm\:dq-divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }

  .sm\:dq-divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .sm\:dq-divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .sm\:dq-divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .sm\:dq-divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }

  .sm\:dq-divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }

  .sm\:dq-divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }

  .sm\:dq-divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }

  .sm\:dq-divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }

  .sm\:dq-divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }

  .sm\:dq-divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }

  .sm\:dq-divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }

  .sm\:dq-divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }

  .sm\:dq-divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }

  .sm\:dq-divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }

  .sm\:dq-divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }

  .sm\:dq-divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }

  .sm\:dq-divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .sm\:dq-place-self-auto {
    place-self: auto;
  }

  .sm\:dq-place-self-start {
    place-self: start;
  }

  .sm\:dq-place-self-end {
    place-self: end;
  }

  .sm\:dq-place-self-center {
    place-self: center;
  }

  .sm\:dq-place-self-stretch {
    place-self: stretch;
  }

  .sm\:dq-self-auto {
    align-self: auto;
  }

  .sm\:dq-self-start {
    align-self: flex-start;
  }

  .sm\:dq-self-end {
    align-self: flex-end;
  }

  .sm\:dq-self-center {
    align-self: center;
  }

  .sm\:dq-self-stretch {
    align-self: stretch;
  }

  .sm\:dq-self-baseline {
    align-self: baseline;
  }

  .sm\:dq-justify-self-auto {
    justify-self: auto;
  }

  .sm\:dq-justify-self-start {
    justify-self: start;
  }

  .sm\:dq-justify-self-end {
    justify-self: end;
  }

  .sm\:dq-justify-self-center {
    justify-self: center;
  }

  .sm\:dq-justify-self-stretch {
    justify-self: stretch;
  }

  .sm\:dq-overflow-auto {
    overflow: auto;
  }

  .sm\:dq-overflow-hidden {
    overflow: hidden;
  }

  .sm\:dq-overflow-visible {
    overflow: visible;
  }

  .sm\:dq-overflow-scroll {
    overflow: scroll;
  }

  .sm\:dq-overflow-x-auto {
    overflow-x: auto;
  }

  .sm\:dq-overflow-y-auto {
    overflow-y: auto;
  }

  .sm\:dq-overflow-x-hidden {
    overflow-x: hidden;
  }

  .sm\:dq-overflow-y-hidden {
    overflow-y: hidden;
  }

  .sm\:dq-overflow-x-visible {
    overflow-x: visible;
  }

  .sm\:dq-overflow-y-visible {
    overflow-y: visible;
  }

  .sm\:dq-overflow-x-scroll {
    overflow-x: scroll;
  }

  .sm\:dq-overflow-y-scroll {
    overflow-y: scroll;
  }

  .sm\:dq-overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
  }

  .sm\:dq-overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }

  .sm\:dq-overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
  }

  .sm\:dq-overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .sm\:dq-overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .sm\:dq-overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .sm\:dq-overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .sm\:dq-overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .sm\:dq-overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .sm\:dq-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sm\:dq-overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .sm\:dq-overflow-clip {
    text-overflow: clip;
  }

  .sm\:dq-whitespace-normal {
    white-space: normal;
  }

  .sm\:dq-whitespace-nowrap {
    white-space: nowrap;
  }

  .sm\:dq-whitespace-pre {
    white-space: pre;
  }

  .sm\:dq-whitespace-pre-line {
    white-space: pre-line;
  }

  .sm\:dq-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .sm\:dq-break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .sm\:dq-break-words {
    overflow-wrap: break-word;
  }

  .sm\:dq-break-all {
    word-break: break-all;
  }

  .sm\:dq-rounded-none {
    border-radius: 0px;
  }

  .sm\:dq-rounded-sm {
    border-radius: 0.125rem;
  }

  .sm\:dq-rounded {
    border-radius: 0.25rem;
  }

  .sm\:dq-rounded-md {
    border-radius: 0.375rem;
  }

  .sm\:dq-rounded-lg {
    border-radius: 0.5rem;
  }

  .sm\:dq-rounded-xl {
    border-radius: 0.75rem;
  }

  .sm\:dq-rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:dq-rounded-3xl {
    border-radius: 1.5rem;
  }

  .sm\:dq-rounded-full {
    border-radius: 9999px;
  }

  .sm\:dq-rounded-t-none {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .sm\:dq-rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .sm\:dq-rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .sm\:dq-rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .sm\:dq-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .sm\:dq-rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .sm\:dq-rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .sm\:dq-rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .sm\:dq-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .sm\:dq-rounded-r-none {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .sm\:dq-rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .sm\:dq-rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .sm\:dq-rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .sm\:dq-rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .sm\:dq-rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .sm\:dq-rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .sm\:dq-rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:dq-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .sm\:dq-rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .sm\:dq-rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:dq-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:dq-rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:dq-rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:dq-rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:dq-rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:dq-rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:dq-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:dq-rounded-l-none {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .sm\:dq-rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:dq-rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:dq-rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:dq-rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:dq-rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:dq-rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:dq-rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:dq-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:dq-rounded-tl-none {
    border-top-left-radius: 0px;
  }

  .sm\:dq-rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .sm\:dq-rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .sm\:dq-rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .sm\:dq-rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .sm\:dq-rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .sm\:dq-rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .sm\:dq-rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .sm\:dq-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .sm\:dq-rounded-tr-none {
    border-top-right-radius: 0px;
  }

  .sm\:dq-rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .sm\:dq-rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .sm\:dq-rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .sm\:dq-rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .sm\:dq-rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .sm\:dq-rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .sm\:dq-rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .sm\:dq-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .sm\:dq-rounded-br-none {
    border-bottom-right-radius: 0px;
  }

  .sm\:dq-rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .sm\:dq-rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .sm\:dq-rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .sm\:dq-rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .sm\:dq-rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .sm\:dq-rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .sm\:dq-rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:dq-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .sm\:dq-rounded-bl-none {
    border-bottom-left-radius: 0px;
  }

  .sm\:dq-rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:dq-rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:dq-rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:dq-rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:dq-rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:dq-rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .sm\:dq-rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:dq-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .sm\:dq-border-0 {
    border-width: 0px;
  }

  .sm\:dq-border-2 {
    border-width: 2px;
  }

  .sm\:dq-border-4 {
    border-width: 4px;
  }

  .sm\:dq-border-8 {
    border-width: 8px;
  }

  .sm\:dq-border {
    border-width: 1px;
  }

  .sm\:dq-border-t-0 {
    border-top-width: 0px;
  }

  .sm\:dq-border-t-2 {
    border-top-width: 2px;
  }

  .sm\:dq-border-t-4 {
    border-top-width: 4px;
  }

  .sm\:dq-border-t-8 {
    border-top-width: 8px;
  }

  .sm\:dq-border-t {
    border-top-width: 1px;
  }

  .sm\:dq-border-r-0 {
    border-right-width: 0px;
  }

  .sm\:dq-border-r-2 {
    border-right-width: 2px;
  }

  .sm\:dq-border-r-4 {
    border-right-width: 4px;
  }

  .sm\:dq-border-r-8 {
    border-right-width: 8px;
  }

  .sm\:dq-border-r {
    border-right-width: 1px;
  }

  .sm\:dq-border-b-0 {
    border-bottom-width: 0px;
  }

  .sm\:dq-border-b-2 {
    border-bottom-width: 2px;
  }

  .sm\:dq-border-b-4 {
    border-bottom-width: 4px;
  }

  .sm\:dq-border-b-8 {
    border-bottom-width: 8px;
  }

  .sm\:dq-border-b {
    border-bottom-width: 1px;
  }

  .sm\:dq-border-l-0 {
    border-left-width: 0px;
  }

  .sm\:dq-border-l-2 {
    border-left-width: 2px;
  }

  .sm\:dq-border-l-4 {
    border-left-width: 4px;
  }

  .sm\:dq-border-l-8 {
    border-left-width: 8px;
  }

  .sm\:dq-border-l {
    border-left-width: 1px;
  }

  .sm\:dq-border-solid {
    border-style: solid;
  }

  .sm\:dq-border-dashed {
    border-style: dashed;
  }

  .sm\:dq-border-dotted {
    border-style: dotted;
  }

  .sm\:dq-border-double {
    border-style: double;
  }

  .sm\:dq-border-none {
    border-style: none;
  }

  .sm\:dq-border-transparent {
    border-color: transparent;
  }

  .sm\:dq-border-current {
    border-color: currentColor;
  }

  .sm\:dq-border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm\:dq-border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:dq-border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm\:dq-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm\:dq-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm\:dq-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm\:dq-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm\:dq-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm\:dq-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm\:dq-border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm\:dq-border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm\:dq-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm\:dq-border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .sm\:dq-border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .sm\:dq-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .sm\:dq-border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .sm\:dq-border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .sm\:dq-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .sm\:dq-border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .sm\:dq-border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .sm\:dq-border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .sm\:dq-border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .sm\:dq-border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm\:dq-border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm\:dq-border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm\:dq-border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm\:dq-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm\:dq-border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm\:dq-border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm\:dq-border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm\:dq-border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm\:dq-border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm\:dq-border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm\:dq-border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm\:dq-border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm\:dq-border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm\:dq-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm\:dq-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm\:dq-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm\:dq-border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm\:dq-border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm\:dq-border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm\:dq-border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm\:dq-border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm\:dq-border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm\:dq-border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm\:dq-border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm\:dq-border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm\:dq-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm\:dq-border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm\:dq-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm\:dq-border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm\:dq-border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm\:dq-border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm\:dq-border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm\:dq-border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm\:dq-border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm\:dq-border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm\:dq-border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm\:dq-border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm\:dq-border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm\:dq-border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm\:dq-border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm\:dq-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm\:dq-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm\:dq-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm\:dq-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm\:dq-border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm\:dq-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm\:dq-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm\:dq-border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm\:dq-border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm\:dq-border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm\:dq-border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm\:dq-border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm\:dq-border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm\:dq-border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm\:dq-border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm\:dq-border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm\:dq-border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm\:dq-border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm\:dq-border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-transparent {
    border-color: transparent;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-current {
    border-color: currentColor;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-transparent:focus-within {
    border-color: transparent;
  }

  .sm\:focus-within\:dq-border-current:focus-within {
    border-color: currentColor;
  }

  .sm\:focus-within\:dq-border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm\:focus-within\:dq-border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-transparent:hover {
    border-color: transparent;
  }

  .sm\:hover\:dq-border-current:hover {
    border-color: currentColor;
  }

  .sm\:hover\:dq-border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm\:hover\:dq-border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-transparent:focus {
    border-color: transparent;
  }

  .sm\:focus\:dq-border-current:focus {
    border-color: currentColor;
  }

  .sm\:focus\:dq-border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm\:focus\:dq-border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm\:dq-border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .sm\:dq-border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .sm\:dq-border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .sm\:dq-border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .sm\:dq-border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .sm\:dq-border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .sm\:dq-border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .sm\:dq-border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .sm\:dq-border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .sm\:dq-border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .sm\:dq-border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .sm\:dq-border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .sm\:dq-border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .sm\:dq-border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .sm\:dq-border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .dq-group:hover .sm\:group-hover\:dq-border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .sm\:focus-within\:dq-border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .sm\:focus-within\:dq-border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }

  .sm\:focus-within\:dq-border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }

  .sm\:focus-within\:dq-border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }

  .sm\:focus-within\:dq-border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }

  .sm\:focus-within\:dq-border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }

  .sm\:focus-within\:dq-border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }

  .sm\:focus-within\:dq-border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }

  .sm\:focus-within\:dq-border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }

  .sm\:focus-within\:dq-border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }

  .sm\:focus-within\:dq-border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }

  .sm\:focus-within\:dq-border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }

  .sm\:focus-within\:dq-border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }

  .sm\:focus-within\:dq-border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }

  .sm\:focus-within\:dq-border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .sm\:hover\:dq-border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .sm\:hover\:dq-border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }

  .sm\:hover\:dq-border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }

  .sm\:hover\:dq-border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }

  .sm\:hover\:dq-border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }

  .sm\:hover\:dq-border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }

  .sm\:hover\:dq-border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }

  .sm\:hover\:dq-border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }

  .sm\:hover\:dq-border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }

  .sm\:hover\:dq-border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }

  .sm\:hover\:dq-border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }

  .sm\:hover\:dq-border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }

  .sm\:hover\:dq-border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }

  .sm\:hover\:dq-border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }

  .sm\:hover\:dq-border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .sm\:focus\:dq-border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .sm\:focus\:dq-border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }

  .sm\:focus\:dq-border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }

  .sm\:focus\:dq-border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }

  .sm\:focus\:dq-border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }

  .sm\:focus\:dq-border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }

  .sm\:focus\:dq-border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }

  .sm\:focus\:dq-border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }

  .sm\:focus\:dq-border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }

  .sm\:focus\:dq-border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }

  .sm\:focus\:dq-border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }

  .sm\:focus\:dq-border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }

  .sm\:focus\:dq-border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }

  .sm\:focus\:dq-border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }

  .sm\:focus\:dq-border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .sm\:dq-bg-transparent {
    background-color: transparent;
  }

  .sm\:dq-bg-current {
    background-color: currentColor;
  }

  .sm\:dq-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-transparent {
    background-color: transparent;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-current {
    background-color: currentColor;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-transparent:focus-within {
    background-color: transparent;
  }

  .sm\:focus-within\:dq-bg-current:focus-within {
    background-color: currentColor;
  }

  .sm\:focus-within\:dq-bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:dq-bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-transparent:hover {
    background-color: transparent;
  }

  .sm\:hover\:dq-bg-current:hover {
    background-color: currentColor;
  }

  .sm\:hover\:dq-bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm\:hover\:dq-bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-transparent:focus {
    background-color: transparent;
  }

  .sm\:focus\:dq-bg-current:focus {
    background-color: currentColor;
  }

  .sm\:focus\:dq-bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm\:focus\:dq-bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm\:dq-bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .sm\:dq-bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .sm\:dq-bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .sm\:dq-bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .sm\:dq-bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .sm\:dq-bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .sm\:dq-bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .sm\:dq-bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .sm\:dq-bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .sm\:dq-bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .sm\:dq-bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .sm\:dq-bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .sm\:dq-bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .sm\:dq-bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .sm\:dq-bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .dq-group:hover .sm\:group-hover\:dq-bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .sm\:focus-within\:dq-bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .sm\:focus-within\:dq-bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }

  .sm\:focus-within\:dq-bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }

  .sm\:focus-within\:dq-bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }

  .sm\:focus-within\:dq-bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }

  .sm\:focus-within\:dq-bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }

  .sm\:focus-within\:dq-bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }

  .sm\:focus-within\:dq-bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }

  .sm\:focus-within\:dq-bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }

  .sm\:focus-within\:dq-bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }

  .sm\:focus-within\:dq-bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }

  .sm\:focus-within\:dq-bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }

  .sm\:focus-within\:dq-bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }

  .sm\:focus-within\:dq-bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }

  .sm\:focus-within\:dq-bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .sm\:hover\:dq-bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .sm\:hover\:dq-bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }

  .sm\:hover\:dq-bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }

  .sm\:hover\:dq-bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }

  .sm\:hover\:dq-bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }

  .sm\:hover\:dq-bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }

  .sm\:hover\:dq-bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }

  .sm\:hover\:dq-bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }

  .sm\:hover\:dq-bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }

  .sm\:hover\:dq-bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }

  .sm\:hover\:dq-bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }

  .sm\:hover\:dq-bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }

  .sm\:hover\:dq-bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }

  .sm\:hover\:dq-bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }

  .sm\:hover\:dq-bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .sm\:focus\:dq-bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .sm\:focus\:dq-bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }

  .sm\:focus\:dq-bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }

  .sm\:focus\:dq-bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }

  .sm\:focus\:dq-bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }

  .sm\:focus\:dq-bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }

  .sm\:focus\:dq-bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }

  .sm\:focus\:dq-bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }

  .sm\:focus\:dq-bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }

  .sm\:focus\:dq-bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }

  .sm\:focus\:dq-bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }

  .sm\:focus\:dq-bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }

  .sm\:focus\:dq-bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }

  .sm\:focus\:dq-bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }

  .sm\:focus\:dq-bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .sm\:dq-bg-none {
    background-image: none;
  }

  .sm\:dq-bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .sm\:dq-bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .sm\:dq-bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .sm\:dq-bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .sm\:dq-bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .sm\:dq-bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .sm\:dq-bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .sm\:dq-bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .sm\:dq-from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:dq-from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:dq-from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:dq-from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:dq-from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .sm\:dq-from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .sm\:dq-from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .sm\:dq-from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .sm\:dq-from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .sm\:dq-from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .sm\:dq-from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .sm\:dq-from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .sm\:dq-from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .sm\:dq-from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .sm\:dq-from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .sm\:dq-from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .sm\:dq-from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .sm\:dq-from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .sm\:dq-from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .sm\:dq-from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .sm\:dq-from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .sm\:dq-from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .sm\:dq-from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .sm\:dq-from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .sm\:dq-from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .sm\:dq-from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .sm\:dq-from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .sm\:dq-from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .sm\:dq-from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .sm\:dq-from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .sm\:dq-from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .sm\:dq-from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .sm\:dq-from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .sm\:dq-from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .sm\:dq-from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .sm\:dq-from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .sm\:dq-from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .sm\:dq-from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .sm\:dq-from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .sm\:dq-from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .sm\:dq-from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .sm\:dq-from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .sm\:dq-from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .sm\:dq-from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .sm\:dq-from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .sm\:dq-from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .sm\:dq-from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .sm\:dq-from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .sm\:dq-from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .sm\:dq-from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .sm\:dq-from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .sm\:dq-from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .sm\:dq-from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .sm\:dq-from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .sm\:dq-from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .sm\:dq-from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .sm\:dq-from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .sm\:dq-from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .sm\:dq-from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .sm\:dq-from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .sm\:dq-from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .sm\:dq-from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .sm\:dq-from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .sm\:dq-from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .sm\:dq-from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .sm\:dq-from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .sm\:dq-from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .sm\:dq-from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .sm\:dq-from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .sm\:dq-from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .sm\:dq-from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .sm\:dq-from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .sm\:dq-from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .sm\:dq-from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .sm\:dq-from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .sm\:dq-from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .sm\:dq-from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .sm\:dq-from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .sm\:dq-from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .sm\:dq-from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .sm\:dq-from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .sm\:dq-from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .sm\:dq-from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .sm\:dq-from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .sm\:hover\:dq-from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:dq-from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:dq-from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:dq-from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:dq-from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .sm\:hover\:dq-from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .sm\:hover\:dq-from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .sm\:hover\:dq-from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .sm\:hover\:dq-from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .sm\:hover\:dq-from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .sm\:hover\:dq-from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .sm\:hover\:dq-from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .sm\:hover\:dq-from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .sm\:hover\:dq-from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .sm\:hover\:dq-from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .sm\:hover\:dq-from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .sm\:hover\:dq-from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .sm\:hover\:dq-from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .sm\:hover\:dq-from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .sm\:hover\:dq-from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .sm\:hover\:dq-from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .sm\:hover\:dq-from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .sm\:hover\:dq-from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .sm\:hover\:dq-from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .sm\:hover\:dq-from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .sm\:hover\:dq-from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .sm\:hover\:dq-from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .sm\:hover\:dq-from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .sm\:hover\:dq-from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .sm\:hover\:dq-from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .sm\:hover\:dq-from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .sm\:hover\:dq-from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .sm\:hover\:dq-from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .sm\:hover\:dq-from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .sm\:hover\:dq-from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .sm\:hover\:dq-from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .sm\:hover\:dq-from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .sm\:hover\:dq-from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .sm\:hover\:dq-from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .sm\:hover\:dq-from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .sm\:hover\:dq-from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .sm\:hover\:dq-from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .sm\:hover\:dq-from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .sm\:hover\:dq-from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .sm\:hover\:dq-from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .sm\:hover\:dq-from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .sm\:hover\:dq-from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .sm\:hover\:dq-from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .sm\:hover\:dq-from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .sm\:hover\:dq-from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .sm\:hover\:dq-from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .sm\:hover\:dq-from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .sm\:hover\:dq-from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .sm\:hover\:dq-from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .sm\:hover\:dq-from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .sm\:hover\:dq-from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .sm\:hover\:dq-from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .sm\:hover\:dq-from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .sm\:hover\:dq-from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .sm\:hover\:dq-from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .sm\:hover\:dq-from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .sm\:hover\:dq-from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .sm\:hover\:dq-from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .sm\:hover\:dq-from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .sm\:hover\:dq-from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .sm\:hover\:dq-from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .sm\:hover\:dq-from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .sm\:hover\:dq-from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .sm\:hover\:dq-from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .sm\:hover\:dq-from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .sm\:hover\:dq-from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .sm\:hover\:dq-from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .sm\:hover\:dq-from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .sm\:hover\:dq-from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .sm\:hover\:dq-from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .sm\:hover\:dq-from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .sm\:hover\:dq-from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .sm\:hover\:dq-from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .sm\:hover\:dq-from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .sm\:hover\:dq-from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .sm\:hover\:dq-from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .sm\:hover\:dq-from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .sm\:hover\:dq-from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .sm\:hover\:dq-from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .sm\:focus\:dq-from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:dq-from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:dq-from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:dq-from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:dq-from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .sm\:focus\:dq-from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .sm\:focus\:dq-from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .sm\:focus\:dq-from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .sm\:focus\:dq-from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .sm\:focus\:dq-from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .sm\:focus\:dq-from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .sm\:focus\:dq-from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .sm\:focus\:dq-from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .sm\:focus\:dq-from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .sm\:focus\:dq-from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .sm\:focus\:dq-from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .sm\:focus\:dq-from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .sm\:focus\:dq-from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .sm\:focus\:dq-from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .sm\:focus\:dq-from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .sm\:focus\:dq-from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .sm\:focus\:dq-from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .sm\:focus\:dq-from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .sm\:focus\:dq-from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .sm\:focus\:dq-from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .sm\:focus\:dq-from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .sm\:focus\:dq-from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .sm\:focus\:dq-from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .sm\:focus\:dq-from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .sm\:focus\:dq-from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .sm\:focus\:dq-from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .sm\:focus\:dq-from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .sm\:focus\:dq-from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .sm\:focus\:dq-from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .sm\:focus\:dq-from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .sm\:focus\:dq-from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .sm\:focus\:dq-from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .sm\:focus\:dq-from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .sm\:focus\:dq-from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .sm\:focus\:dq-from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .sm\:focus\:dq-from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .sm\:focus\:dq-from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .sm\:focus\:dq-from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .sm\:focus\:dq-from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .sm\:focus\:dq-from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .sm\:focus\:dq-from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .sm\:focus\:dq-from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .sm\:focus\:dq-from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .sm\:focus\:dq-from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .sm\:focus\:dq-from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .sm\:focus\:dq-from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .sm\:focus\:dq-from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .sm\:focus\:dq-from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .sm\:focus\:dq-from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .sm\:focus\:dq-from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .sm\:focus\:dq-from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .sm\:focus\:dq-from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .sm\:focus\:dq-from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .sm\:focus\:dq-from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .sm\:focus\:dq-from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .sm\:focus\:dq-from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .sm\:focus\:dq-from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .sm\:focus\:dq-from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .sm\:focus\:dq-from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .sm\:focus\:dq-from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .sm\:focus\:dq-from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .sm\:focus\:dq-from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .sm\:focus\:dq-from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .sm\:focus\:dq-from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .sm\:focus\:dq-from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .sm\:focus\:dq-from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .sm\:focus\:dq-from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .sm\:focus\:dq-from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .sm\:focus\:dq-from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .sm\:focus\:dq-from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .sm\:focus\:dq-from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .sm\:focus\:dq-from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .sm\:focus\:dq-from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .sm\:focus\:dq-from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .sm\:focus\:dq-from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .sm\:focus\:dq-from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .sm\:focus\:dq-from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .sm\:focus\:dq-from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .sm\:focus\:dq-from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .sm\:dq-via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:dq-via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:dq-via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:dq-via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:dq-via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .sm\:dq-via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .sm\:dq-via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .sm\:dq-via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .sm\:dq-via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .sm\:dq-via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .sm\:dq-via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .sm\:dq-via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .sm\:dq-via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .sm\:dq-via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .sm\:dq-via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .sm\:dq-via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .sm\:dq-via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .sm\:dq-via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .sm\:dq-via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .sm\:dq-via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .sm\:dq-via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .sm\:dq-via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .sm\:dq-via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .sm\:dq-via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .sm\:dq-via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .sm\:dq-via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .sm\:dq-via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .sm\:dq-via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .sm\:dq-via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .sm\:dq-via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .sm\:dq-via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .sm\:dq-via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .sm\:dq-via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .sm\:dq-via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .sm\:dq-via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .sm\:dq-via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .sm\:dq-via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .sm\:dq-via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .sm\:dq-via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .sm\:dq-via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .sm\:dq-via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .sm\:dq-via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .sm\:dq-via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .sm\:dq-via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .sm\:dq-via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .sm\:dq-via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .sm\:dq-via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .sm\:dq-via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .sm\:dq-via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .sm\:dq-via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .sm\:dq-via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .sm\:dq-via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .sm\:dq-via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .sm\:dq-via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .sm\:dq-via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .sm\:dq-via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .sm\:dq-via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .sm\:dq-via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .sm\:dq-via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .sm\:dq-via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .sm\:dq-via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .sm\:dq-via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .sm\:dq-via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .sm\:dq-via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .sm\:dq-via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .sm\:dq-via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .sm\:dq-via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .sm\:dq-via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .sm\:dq-via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .sm\:dq-via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .sm\:dq-via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .sm\:dq-via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .sm\:dq-via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .sm\:dq-via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .sm\:dq-via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .sm\:dq-via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .sm\:dq-via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .sm\:dq-via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .sm\:dq-via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .sm\:dq-via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .sm\:dq-via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .sm\:dq-via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .sm\:dq-via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .sm\:dq-via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .sm\:hover\:dq-via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:dq-via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:dq-via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:dq-via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:dq-via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .sm\:hover\:dq-via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .sm\:hover\:dq-via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .sm\:hover\:dq-via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .sm\:hover\:dq-via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .sm\:hover\:dq-via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .sm\:hover\:dq-via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .sm\:hover\:dq-via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .sm\:hover\:dq-via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .sm\:hover\:dq-via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .sm\:hover\:dq-via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .sm\:hover\:dq-via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .sm\:hover\:dq-via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .sm\:hover\:dq-via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .sm\:hover\:dq-via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .sm\:hover\:dq-via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .sm\:hover\:dq-via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .sm\:hover\:dq-via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .sm\:hover\:dq-via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .sm\:hover\:dq-via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .sm\:hover\:dq-via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .sm\:hover\:dq-via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .sm\:hover\:dq-via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .sm\:hover\:dq-via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .sm\:hover\:dq-via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .sm\:hover\:dq-via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .sm\:hover\:dq-via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .sm\:hover\:dq-via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .sm\:hover\:dq-via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .sm\:hover\:dq-via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .sm\:hover\:dq-via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .sm\:hover\:dq-via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .sm\:hover\:dq-via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .sm\:hover\:dq-via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .sm\:hover\:dq-via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .sm\:hover\:dq-via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .sm\:hover\:dq-via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .sm\:hover\:dq-via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .sm\:hover\:dq-via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .sm\:hover\:dq-via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .sm\:hover\:dq-via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .sm\:hover\:dq-via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .sm\:hover\:dq-via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .sm\:hover\:dq-via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .sm\:hover\:dq-via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .sm\:hover\:dq-via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .sm\:hover\:dq-via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .sm\:hover\:dq-via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .sm\:hover\:dq-via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .sm\:hover\:dq-via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .sm\:hover\:dq-via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .sm\:hover\:dq-via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .sm\:hover\:dq-via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .sm\:hover\:dq-via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .sm\:hover\:dq-via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .sm\:hover\:dq-via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .sm\:hover\:dq-via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .sm\:hover\:dq-via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .sm\:hover\:dq-via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .sm\:hover\:dq-via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .sm\:hover\:dq-via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .sm\:hover\:dq-via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .sm\:hover\:dq-via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .sm\:hover\:dq-via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .sm\:hover\:dq-via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .sm\:hover\:dq-via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .sm\:hover\:dq-via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .sm\:hover\:dq-via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .sm\:hover\:dq-via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .sm\:hover\:dq-via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .sm\:hover\:dq-via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .sm\:hover\:dq-via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .sm\:hover\:dq-via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .sm\:hover\:dq-via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .sm\:hover\:dq-via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .sm\:hover\:dq-via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .sm\:hover\:dq-via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .sm\:hover\:dq-via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .sm\:hover\:dq-via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .sm\:hover\:dq-via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .sm\:focus\:dq-via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:dq-via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:dq-via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:dq-via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:dq-via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .sm\:focus\:dq-via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .sm\:focus\:dq-via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .sm\:focus\:dq-via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .sm\:focus\:dq-via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .sm\:focus\:dq-via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .sm\:focus\:dq-via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .sm\:focus\:dq-via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .sm\:focus\:dq-via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .sm\:focus\:dq-via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .sm\:focus\:dq-via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .sm\:focus\:dq-via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .sm\:focus\:dq-via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .sm\:focus\:dq-via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .sm\:focus\:dq-via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .sm\:focus\:dq-via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .sm\:focus\:dq-via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .sm\:focus\:dq-via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .sm\:focus\:dq-via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .sm\:focus\:dq-via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .sm\:focus\:dq-via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .sm\:focus\:dq-via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .sm\:focus\:dq-via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .sm\:focus\:dq-via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .sm\:focus\:dq-via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .sm\:focus\:dq-via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .sm\:focus\:dq-via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .sm\:focus\:dq-via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .sm\:focus\:dq-via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .sm\:focus\:dq-via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .sm\:focus\:dq-via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .sm\:focus\:dq-via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .sm\:focus\:dq-via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .sm\:focus\:dq-via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .sm\:focus\:dq-via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .sm\:focus\:dq-via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .sm\:focus\:dq-via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .sm\:focus\:dq-via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .sm\:focus\:dq-via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .sm\:focus\:dq-via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .sm\:focus\:dq-via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .sm\:focus\:dq-via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .sm\:focus\:dq-via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .sm\:focus\:dq-via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .sm\:focus\:dq-via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .sm\:focus\:dq-via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .sm\:focus\:dq-via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .sm\:focus\:dq-via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .sm\:focus\:dq-via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .sm\:focus\:dq-via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .sm\:focus\:dq-via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .sm\:focus\:dq-via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .sm\:focus\:dq-via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .sm\:focus\:dq-via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .sm\:focus\:dq-via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .sm\:focus\:dq-via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .sm\:focus\:dq-via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .sm\:focus\:dq-via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .sm\:focus\:dq-via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .sm\:focus\:dq-via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .sm\:focus\:dq-via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .sm\:focus\:dq-via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .sm\:focus\:dq-via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .sm\:focus\:dq-via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .sm\:focus\:dq-via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .sm\:focus\:dq-via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .sm\:focus\:dq-via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .sm\:focus\:dq-via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .sm\:focus\:dq-via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .sm\:focus\:dq-via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .sm\:focus\:dq-via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .sm\:focus\:dq-via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .sm\:focus\:dq-via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .sm\:focus\:dq-via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .sm\:focus\:dq-via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .sm\:focus\:dq-via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .sm\:focus\:dq-via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .sm\:focus\:dq-via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .sm\:focus\:dq-via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .sm\:focus\:dq-via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .sm\:dq-to-transparent {
    --tw-gradient-to: transparent;
  }

  .sm\:dq-to-current {
    --tw-gradient-to: currentColor;
  }

  .sm\:dq-to-black {
    --tw-gradient-to: #000;
  }

  .sm\:dq-to-white {
    --tw-gradient-to: #fff;
  }

  .sm\:dq-to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .sm\:dq-to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .sm\:dq-to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .sm\:dq-to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .sm\:dq-to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .sm\:dq-to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .sm\:dq-to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .sm\:dq-to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .sm\:dq-to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .sm\:dq-to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .sm\:dq-to-red-50 {
    --tw-gradient-to: #fef2f2;
  }

  .sm\:dq-to-red-100 {
    --tw-gradient-to: #fee2e2;
  }

  .sm\:dq-to-red-200 {
    --tw-gradient-to: #fecaca;
  }

  .sm\:dq-to-red-300 {
    --tw-gradient-to: #fca5a5;
  }

  .sm\:dq-to-red-400 {
    --tw-gradient-to: #f87171;
  }

  .sm\:dq-to-red-500 {
    --tw-gradient-to: #ef4444;
  }

  .sm\:dq-to-red-600 {
    --tw-gradient-to: #dc2626;
  }

  .sm\:dq-to-red-700 {
    --tw-gradient-to: #b91c1c;
  }

  .sm\:dq-to-red-800 {
    --tw-gradient-to: #991b1b;
  }

  .sm\:dq-to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }

  .sm\:dq-to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .sm\:dq-to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .sm\:dq-to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .sm\:dq-to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .sm\:dq-to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .sm\:dq-to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .sm\:dq-to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .sm\:dq-to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .sm\:dq-to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .sm\:dq-to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .sm\:dq-to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .sm\:dq-to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .sm\:dq-to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .sm\:dq-to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .sm\:dq-to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .sm\:dq-to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .sm\:dq-to-green-600 {
    --tw-gradient-to: #059669;
  }

  .sm\:dq-to-green-700 {
    --tw-gradient-to: #047857;
  }

  .sm\:dq-to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .sm\:dq-to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .sm\:dq-to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .sm\:dq-to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .sm\:dq-to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .sm\:dq-to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .sm\:dq-to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .sm\:dq-to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .sm\:dq-to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .sm\:dq-to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .sm\:dq-to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .sm\:dq-to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .sm\:dq-to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .sm\:dq-to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .sm\:dq-to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .sm\:dq-to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .sm\:dq-to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .sm\:dq-to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .sm\:dq-to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .sm\:dq-to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .sm\:dq-to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .sm\:dq-to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .sm\:dq-to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .sm\:dq-to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .sm\:dq-to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .sm\:dq-to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .sm\:dq-to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .sm\:dq-to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .sm\:dq-to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .sm\:dq-to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .sm\:dq-to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .sm\:dq-to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .sm\:dq-to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .sm\:dq-to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .sm\:dq-to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .sm\:dq-to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .sm\:dq-to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .sm\:dq-to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .sm\:dq-to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .sm\:dq-to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .sm\:dq-to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .sm\:dq-to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .sm\:hover\:dq-to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .sm\:hover\:dq-to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .sm\:hover\:dq-to-black:hover {
    --tw-gradient-to: #000;
  }

  .sm\:hover\:dq-to-white:hover {
    --tw-gradient-to: #fff;
  }

  .sm\:hover\:dq-to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .sm\:hover\:dq-to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .sm\:hover\:dq-to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .sm\:hover\:dq-to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .sm\:hover\:dq-to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .sm\:hover\:dq-to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .sm\:hover\:dq-to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .sm\:hover\:dq-to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .sm\:hover\:dq-to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .sm\:hover\:dq-to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .sm\:hover\:dq-to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }

  .sm\:hover\:dq-to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }

  .sm\:hover\:dq-to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }

  .sm\:hover\:dq-to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }

  .sm\:hover\:dq-to-red-400:hover {
    --tw-gradient-to: #f87171;
  }

  .sm\:hover\:dq-to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }

  .sm\:hover\:dq-to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }

  .sm\:hover\:dq-to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }

  .sm\:hover\:dq-to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }

  .sm\:hover\:dq-to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }

  .sm\:hover\:dq-to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .sm\:hover\:dq-to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .sm\:hover\:dq-to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .sm\:hover\:dq-to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .sm\:hover\:dq-to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .sm\:hover\:dq-to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .sm\:hover\:dq-to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .sm\:hover\:dq-to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .sm\:hover\:dq-to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .sm\:hover\:dq-to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .sm\:hover\:dq-to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .sm\:hover\:dq-to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .sm\:hover\:dq-to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .sm\:hover\:dq-to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .sm\:hover\:dq-to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .sm\:hover\:dq-to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .sm\:hover\:dq-to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .sm\:hover\:dq-to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .sm\:hover\:dq-to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .sm\:hover\:dq-to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .sm\:hover\:dq-to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .sm\:hover\:dq-to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .sm\:hover\:dq-to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .sm\:hover\:dq-to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .sm\:hover\:dq-to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .sm\:hover\:dq-to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .sm\:hover\:dq-to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .sm\:hover\:dq-to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .sm\:hover\:dq-to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .sm\:hover\:dq-to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .sm\:hover\:dq-to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .sm\:hover\:dq-to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .sm\:hover\:dq-to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .sm\:hover\:dq-to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .sm\:hover\:dq-to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .sm\:hover\:dq-to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .sm\:hover\:dq-to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .sm\:hover\:dq-to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .sm\:hover\:dq-to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .sm\:hover\:dq-to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .sm\:hover\:dq-to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .sm\:hover\:dq-to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .sm\:hover\:dq-to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .sm\:hover\:dq-to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .sm\:hover\:dq-to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .sm\:hover\:dq-to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .sm\:hover\:dq-to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .sm\:hover\:dq-to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .sm\:hover\:dq-to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .sm\:hover\:dq-to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .sm\:hover\:dq-to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .sm\:hover\:dq-to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .sm\:hover\:dq-to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .sm\:hover\:dq-to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .sm\:hover\:dq-to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .sm\:hover\:dq-to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .sm\:hover\:dq-to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .sm\:hover\:dq-to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .sm\:hover\:dq-to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .sm\:hover\:dq-to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .sm\:focus\:dq-to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .sm\:focus\:dq-to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .sm\:focus\:dq-to-black:focus {
    --tw-gradient-to: #000;
  }

  .sm\:focus\:dq-to-white:focus {
    --tw-gradient-to: #fff;
  }

  .sm\:focus\:dq-to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .sm\:focus\:dq-to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .sm\:focus\:dq-to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .sm\:focus\:dq-to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .sm\:focus\:dq-to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .sm\:focus\:dq-to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .sm\:focus\:dq-to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .sm\:focus\:dq-to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .sm\:focus\:dq-to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .sm\:focus\:dq-to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .sm\:focus\:dq-to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }

  .sm\:focus\:dq-to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }

  .sm\:focus\:dq-to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }

  .sm\:focus\:dq-to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }

  .sm\:focus\:dq-to-red-400:focus {
    --tw-gradient-to: #f87171;
  }

  .sm\:focus\:dq-to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }

  .sm\:focus\:dq-to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }

  .sm\:focus\:dq-to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }

  .sm\:focus\:dq-to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }

  .sm\:focus\:dq-to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }

  .sm\:focus\:dq-to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .sm\:focus\:dq-to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .sm\:focus\:dq-to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .sm\:focus\:dq-to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .sm\:focus\:dq-to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .sm\:focus\:dq-to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .sm\:focus\:dq-to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .sm\:focus\:dq-to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .sm\:focus\:dq-to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .sm\:focus\:dq-to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .sm\:focus\:dq-to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .sm\:focus\:dq-to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .sm\:focus\:dq-to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .sm\:focus\:dq-to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .sm\:focus\:dq-to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .sm\:focus\:dq-to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .sm\:focus\:dq-to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .sm\:focus\:dq-to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .sm\:focus\:dq-to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .sm\:focus\:dq-to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .sm\:focus\:dq-to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .sm\:focus\:dq-to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .sm\:focus\:dq-to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .sm\:focus\:dq-to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .sm\:focus\:dq-to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .sm\:focus\:dq-to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .sm\:focus\:dq-to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .sm\:focus\:dq-to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .sm\:focus\:dq-to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .sm\:focus\:dq-to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .sm\:focus\:dq-to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .sm\:focus\:dq-to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .sm\:focus\:dq-to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .sm\:focus\:dq-to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .sm\:focus\:dq-to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .sm\:focus\:dq-to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .sm\:focus\:dq-to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .sm\:focus\:dq-to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .sm\:focus\:dq-to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .sm\:focus\:dq-to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .sm\:focus\:dq-to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .sm\:focus\:dq-to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .sm\:focus\:dq-to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .sm\:focus\:dq-to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .sm\:focus\:dq-to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .sm\:focus\:dq-to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .sm\:focus\:dq-to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .sm\:focus\:dq-to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .sm\:focus\:dq-to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .sm\:focus\:dq-to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .sm\:focus\:dq-to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .sm\:focus\:dq-to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .sm\:focus\:dq-to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .sm\:focus\:dq-to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .sm\:focus\:dq-to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .sm\:focus\:dq-to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .sm\:focus\:dq-to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .sm\:focus\:dq-to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .sm\:focus\:dq-to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .sm\:focus\:dq-to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .sm\:dq-decoration-slice {
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
  }

  .sm\:dq-decoration-clone {
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
  }

  .sm\:dq-bg-auto {
    background-size: auto;
  }

  .sm\:dq-bg-cover {
    background-size: cover;
  }

  .sm\:dq-bg-contain {
    background-size: contain;
  }

  .sm\:dq-bg-fixed {
    background-attachment: fixed;
  }

  .sm\:dq-bg-local {
    background-attachment: local;
  }

  .sm\:dq-bg-scroll {
    background-attachment: scroll;
  }

  .sm\:dq-bg-clip-border {
    background-clip: border-box;
  }

  .sm\:dq-bg-clip-padding {
    background-clip: padding-box;
  }

  .sm\:dq-bg-clip-content {
    background-clip: content-box;
  }

  .sm\:dq-bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .sm\:dq-bg-bottom {
    background-position: bottom;
  }

  .sm\:dq-bg-center {
    background-position: center;
  }

  .sm\:dq-bg-left {
    background-position: left;
  }

  .sm\:dq-bg-left-bottom {
    background-position: left bottom;
  }

  .sm\:dq-bg-left-top {
    background-position: left top;
  }

  .sm\:dq-bg-right {
    background-position: right;
  }

  .sm\:dq-bg-right-bottom {
    background-position: right bottom;
  }

  .sm\:dq-bg-right-top {
    background-position: right top;
  }

  .sm\:dq-bg-top {
    background-position: top;
  }

  .sm\:dq-bg-repeat {
    background-repeat: repeat;
  }

  .sm\:dq-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .sm\:dq-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .sm\:dq-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .sm\:dq-bg-repeat-round {
    background-repeat: round;
  }

  .sm\:dq-bg-repeat-space {
    background-repeat: space;
  }

  .sm\:dq-bg-origin-border {
    background-origin: border-box;
  }

  .sm\:dq-bg-origin-padding {
    background-origin: padding-box;
  }

  .sm\:dq-bg-origin-content {
    background-origin: content-box;
  }

  .sm\:dq-fill-current {
    fill: currentColor;
  }

  .sm\:dq-stroke-current {
    stroke: currentColor;
  }

  .sm\:dq-stroke-0 {
    stroke-width: 0;
  }

  .sm\:dq-stroke-1 {
    stroke-width: 1;
  }

  .sm\:dq-stroke-2 {
    stroke-width: 2;
  }

  .sm\:dq-object-contain {
    object-fit: contain;
  }

  .sm\:dq-object-cover {
    object-fit: cover;
  }

  .sm\:dq-object-fill {
    object-fit: fill;
  }

  .sm\:dq-object-none {
    object-fit: none;
  }

  .sm\:dq-object-scale-down {
    object-fit: scale-down;
  }

  .sm\:dq-object-bottom {
    object-position: bottom;
  }

  .sm\:dq-object-center {
    object-position: center;
  }

  .sm\:dq-object-left {
    object-position: left;
  }

  .sm\:dq-object-left-bottom {
    object-position: left bottom;
  }

  .sm\:dq-object-left-top {
    object-position: left top;
  }

  .sm\:dq-object-right {
    object-position: right;
  }

  .sm\:dq-object-right-bottom {
    object-position: right bottom;
  }

  .sm\:dq-object-right-top {
    object-position: right top;
  }

  .sm\:dq-object-top {
    object-position: top;
  }

  .sm\:dq-p-0 {
    padding: 0px;
  }

  .sm\:dq-p-1 {
    padding: 0.25rem;
  }

  .sm\:dq-p-2 {
    padding: 0.5rem;
  }

  .sm\:dq-p-3 {
    padding: 0.75rem;
  }

  .sm\:dq-p-4 {
    padding: 1rem;
  }

  .sm\:dq-p-5 {
    padding: 1.25rem;
  }

  .sm\:dq-p-6 {
    padding: 1.5rem;
  }

  .sm\:dq-p-7 {
    padding: 1.75rem;
  }

  .sm\:dq-p-8 {
    padding: 2rem;
  }

  .sm\:dq-p-9 {
    padding: 2.25rem;
  }

  .sm\:dq-p-10 {
    padding: 2.5rem;
  }

  .sm\:dq-p-11 {
    padding: 2.75rem;
  }

  .sm\:dq-p-12 {
    padding: 3rem;
  }

  .sm\:dq-p-14 {
    padding: 3.5rem;
  }

  .sm\:dq-p-16 {
    padding: 4rem;
  }

  .sm\:dq-p-20 {
    padding: 5rem;
  }

  .sm\:dq-p-24 {
    padding: 6rem;
  }

  .sm\:dq-p-28 {
    padding: 7rem;
  }

  .sm\:dq-p-32 {
    padding: 8rem;
  }

  .sm\:dq-p-36 {
    padding: 9rem;
  }

  .sm\:dq-p-40 {
    padding: 10rem;
  }

  .sm\:dq-p-44 {
    padding: 11rem;
  }

  .sm\:dq-p-48 {
    padding: 12rem;
  }

  .sm\:dq-p-52 {
    padding: 13rem;
  }

  .sm\:dq-p-56 {
    padding: 14rem;
  }

  .sm\:dq-p-60 {
    padding: 15rem;
  }

  .sm\:dq-p-64 {
    padding: 16rem;
  }

  .sm\:dq-p-72 {
    padding: 18rem;
  }

  .sm\:dq-p-80 {
    padding: 20rem;
  }

  .sm\:dq-p-96 {
    padding: 24rem;
  }

  .sm\:dq-p-px {
    padding: 1px;
  }

  .sm\:dq-p-0\.5 {
    padding: 0.125rem;
  }

  .sm\:dq-p-1\.5 {
    padding: 0.375rem;
  }

  .sm\:dq-p-2\.5 {
    padding: 0.625rem;
  }

  .sm\:dq-p-3\.5 {
    padding: 0.875rem;
  }

  .sm\:dq-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .sm\:dq-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .sm\:dq-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .sm\:dq-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .sm\:dq-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:dq-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:dq-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:dq-px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .sm\:dq-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:dq-px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .sm\:dq-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:dq-px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .sm\:dq-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:dq-px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .sm\:dq-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:dq-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:dq-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:dq-px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .sm\:dq-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .sm\:dq-px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .sm\:dq-px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .sm\:dq-px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .sm\:dq-px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .sm\:dq-px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .sm\:dq-px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .sm\:dq-px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .sm\:dq-px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .sm\:dq-px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .sm\:dq-px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .sm\:dq-px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .sm\:dq-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .sm\:dq-px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }

  .sm\:dq-px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }

  .sm\:dq-px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .sm\:dq-px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }

  .sm\:dq-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .sm\:dq-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .sm\:dq-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .sm\:dq-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .sm\:dq-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:dq-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:dq-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:dq-py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .sm\:dq-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:dq-py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .sm\:dq-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:dq-py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .sm\:dq-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm\:dq-py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .sm\:dq-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm\:dq-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .sm\:dq-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:dq-py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .sm\:dq-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:dq-py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .sm\:dq-py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .sm\:dq-py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .sm\:dq-py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .sm\:dq-py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .sm\:dq-py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .sm\:dq-py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .sm\:dq-py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .sm\:dq-py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .sm\:dq-py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .sm\:dq-py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .sm\:dq-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .sm\:dq-py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }

  .sm\:dq-py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  .sm\:dq-py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .sm\:dq-py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  .sm\:dq-pt-0 {
    padding-top: 0px;
  }

  .sm\:dq-pt-1 {
    padding-top: 0.25rem;
  }

  .sm\:dq-pt-2 {
    padding-top: 0.5rem;
  }

  .sm\:dq-pt-3 {
    padding-top: 0.75rem;
  }

  .sm\:dq-pt-4 {
    padding-top: 1rem;
  }

  .sm\:dq-pt-5 {
    padding-top: 1.25rem;
  }

  .sm\:dq-pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:dq-pt-7 {
    padding-top: 1.75rem;
  }

  .sm\:dq-pt-8 {
    padding-top: 2rem;
  }

  .sm\:dq-pt-9 {
    padding-top: 2.25rem;
  }

  .sm\:dq-pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:dq-pt-11 {
    padding-top: 2.75rem;
  }

  .sm\:dq-pt-12 {
    padding-top: 3rem;
  }

  .sm\:dq-pt-14 {
    padding-top: 3.5rem;
  }

  .sm\:dq-pt-16 {
    padding-top: 4rem;
  }

  .sm\:dq-pt-20 {
    padding-top: 5rem;
  }

  .sm\:dq-pt-24 {
    padding-top: 6rem;
  }

  .sm\:dq-pt-28 {
    padding-top: 7rem;
  }

  .sm\:dq-pt-32 {
    padding-top: 8rem;
  }

  .sm\:dq-pt-36 {
    padding-top: 9rem;
  }

  .sm\:dq-pt-40 {
    padding-top: 10rem;
  }

  .sm\:dq-pt-44 {
    padding-top: 11rem;
  }

  .sm\:dq-pt-48 {
    padding-top: 12rem;
  }

  .sm\:dq-pt-52 {
    padding-top: 13rem;
  }

  .sm\:dq-pt-56 {
    padding-top: 14rem;
  }

  .sm\:dq-pt-60 {
    padding-top: 15rem;
  }

  .sm\:dq-pt-64 {
    padding-top: 16rem;
  }

  .sm\:dq-pt-72 {
    padding-top: 18rem;
  }

  .sm\:dq-pt-80 {
    padding-top: 20rem;
  }

  .sm\:dq-pt-96 {
    padding-top: 24rem;
  }

  .sm\:dq-pt-px {
    padding-top: 1px;
  }

  .sm\:dq-pt-0\.5 {
    padding-top: 0.125rem;
  }

  .sm\:dq-pt-1\.5 {
    padding-top: 0.375rem;
  }

  .sm\:dq-pt-2\.5 {
    padding-top: 0.625rem;
  }

  .sm\:dq-pt-3\.5 {
    padding-top: 0.875rem;
  }

  .sm\:dq-pr-0 {
    padding-right: 0px;
  }

  .sm\:dq-pr-1 {
    padding-right: 0.25rem;
  }

  .sm\:dq-pr-2 {
    padding-right: 0.5rem;
  }

  .sm\:dq-pr-3 {
    padding-right: 0.75rem;
  }

  .sm\:dq-pr-4 {
    padding-right: 1rem;
  }

  .sm\:dq-pr-5 {
    padding-right: 1.25rem;
  }

  .sm\:dq-pr-6 {
    padding-right: 1.5rem;
  }

  .sm\:dq-pr-7 {
    padding-right: 1.75rem;
  }

  .sm\:dq-pr-8 {
    padding-right: 2rem;
  }

  .sm\:dq-pr-9 {
    padding-right: 2.25rem;
  }

  .sm\:dq-pr-10 {
    padding-right: 2.5rem;
  }

  .sm\:dq-pr-11 {
    padding-right: 2.75rem;
  }

  .sm\:dq-pr-12 {
    padding-right: 3rem;
  }

  .sm\:dq-pr-14 {
    padding-right: 3.5rem;
  }

  .sm\:dq-pr-16 {
    padding-right: 4rem;
  }

  .sm\:dq-pr-20 {
    padding-right: 5rem;
  }

  .sm\:dq-pr-24 {
    padding-right: 6rem;
  }

  .sm\:dq-pr-28 {
    padding-right: 7rem;
  }

  .sm\:dq-pr-32 {
    padding-right: 8rem;
  }

  .sm\:dq-pr-36 {
    padding-right: 9rem;
  }

  .sm\:dq-pr-40 {
    padding-right: 10rem;
  }

  .sm\:dq-pr-44 {
    padding-right: 11rem;
  }

  .sm\:dq-pr-48 {
    padding-right: 12rem;
  }

  .sm\:dq-pr-52 {
    padding-right: 13rem;
  }

  .sm\:dq-pr-56 {
    padding-right: 14rem;
  }

  .sm\:dq-pr-60 {
    padding-right: 15rem;
  }

  .sm\:dq-pr-64 {
    padding-right: 16rem;
  }

  .sm\:dq-pr-72 {
    padding-right: 18rem;
  }

  .sm\:dq-pr-80 {
    padding-right: 20rem;
  }

  .sm\:dq-pr-96 {
    padding-right: 24rem;
  }

  .sm\:dq-pr-px {
    padding-right: 1px;
  }

  .sm\:dq-pr-0\.5 {
    padding-right: 0.125rem;
  }

  .sm\:dq-pr-1\.5 {
    padding-right: 0.375rem;
  }

  .sm\:dq-pr-2\.5 {
    padding-right: 0.625rem;
  }

  .sm\:dq-pr-3\.5 {
    padding-right: 0.875rem;
  }

  .sm\:dq-pb-0 {
    padding-bottom: 0px;
  }

  .sm\:dq-pb-1 {
    padding-bottom: 0.25rem;
  }

  .sm\:dq-pb-2 {
    padding-bottom: 0.5rem;
  }

  .sm\:dq-pb-3 {
    padding-bottom: 0.75rem;
  }

  .sm\:dq-pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:dq-pb-5 {
    padding-bottom: 1.25rem;
  }

  .sm\:dq-pb-6 {
    padding-bottom: 1.5rem;
  }

  .sm\:dq-pb-7 {
    padding-bottom: 1.75rem;
  }

  .sm\:dq-pb-8 {
    padding-bottom: 2rem;
  }

  .sm\:dq-pb-9 {
    padding-bottom: 2.25rem;
  }

  .sm\:dq-pb-10 {
    padding-bottom: 2.5rem;
  }

  .sm\:dq-pb-11 {
    padding-bottom: 2.75rem;
  }

  .sm\:dq-pb-12 {
    padding-bottom: 3rem;
  }

  .sm\:dq-pb-14 {
    padding-bottom: 3.5rem;
  }

  .sm\:dq-pb-16 {
    padding-bottom: 4rem;
  }

  .sm\:dq-pb-20 {
    padding-bottom: 5rem;
  }

  .sm\:dq-pb-24 {
    padding-bottom: 6rem;
  }

  .sm\:dq-pb-28 {
    padding-bottom: 7rem;
  }

  .sm\:dq-pb-32 {
    padding-bottom: 8rem;
  }

  .sm\:dq-pb-36 {
    padding-bottom: 9rem;
  }

  .sm\:dq-pb-40 {
    padding-bottom: 10rem;
  }

  .sm\:dq-pb-44 {
    padding-bottom: 11rem;
  }

  .sm\:dq-pb-48 {
    padding-bottom: 12rem;
  }

  .sm\:dq-pb-52 {
    padding-bottom: 13rem;
  }

  .sm\:dq-pb-56 {
    padding-bottom: 14rem;
  }

  .sm\:dq-pb-60 {
    padding-bottom: 15rem;
  }

  .sm\:dq-pb-64 {
    padding-bottom: 16rem;
  }

  .sm\:dq-pb-72 {
    padding-bottom: 18rem;
  }

  .sm\:dq-pb-80 {
    padding-bottom: 20rem;
  }

  .sm\:dq-pb-96 {
    padding-bottom: 24rem;
  }

  .sm\:dq-pb-px {
    padding-bottom: 1px;
  }

  .sm\:dq-pb-0\.5 {
    padding-bottom: 0.125rem;
  }

  .sm\:dq-pb-1\.5 {
    padding-bottom: 0.375rem;
  }

  .sm\:dq-pb-2\.5 {
    padding-bottom: 0.625rem;
  }

  .sm\:dq-pb-3\.5 {
    padding-bottom: 0.875rem;
  }

  .sm\:dq-pl-0 {
    padding-left: 0px;
  }

  .sm\:dq-pl-1 {
    padding-left: 0.25rem;
  }

  .sm\:dq-pl-2 {
    padding-left: 0.5rem;
  }

  .sm\:dq-pl-3 {
    padding-left: 0.75rem;
  }

  .sm\:dq-pl-4 {
    padding-left: 1rem;
  }

  .sm\:dq-pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:dq-pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:dq-pl-7 {
    padding-left: 1.75rem;
  }

  .sm\:dq-pl-8 {
    padding-left: 2rem;
  }

  .sm\:dq-pl-9 {
    padding-left: 2.25rem;
  }

  .sm\:dq-pl-10 {
    padding-left: 2.5rem;
  }

  .sm\:dq-pl-11 {
    padding-left: 2.75rem;
  }

  .sm\:dq-pl-12 {
    padding-left: 3rem;
  }

  .sm\:dq-pl-14 {
    padding-left: 3.5rem;
  }

  .sm\:dq-pl-16 {
    padding-left: 4rem;
  }

  .sm\:dq-pl-20 {
    padding-left: 5rem;
  }

  .sm\:dq-pl-24 {
    padding-left: 6rem;
  }

  .sm\:dq-pl-28 {
    padding-left: 7rem;
  }

  .sm\:dq-pl-32 {
    padding-left: 8rem;
  }

  .sm\:dq-pl-36 {
    padding-left: 9rem;
  }

  .sm\:dq-pl-40 {
    padding-left: 10rem;
  }

  .sm\:dq-pl-44 {
    padding-left: 11rem;
  }

  .sm\:dq-pl-48 {
    padding-left: 12rem;
  }

  .sm\:dq-pl-52 {
    padding-left: 13rem;
  }

  .sm\:dq-pl-56 {
    padding-left: 14rem;
  }

  .sm\:dq-pl-60 {
    padding-left: 15rem;
  }

  .sm\:dq-pl-64 {
    padding-left: 16rem;
  }

  .sm\:dq-pl-72 {
    padding-left: 18rem;
  }

  .sm\:dq-pl-80 {
    padding-left: 20rem;
  }

  .sm\:dq-pl-96 {
    padding-left: 24rem;
  }

  .sm\:dq-pl-px {
    padding-left: 1px;
  }

  .sm\:dq-pl-0\.5 {
    padding-left: 0.125rem;
  }

  .sm\:dq-pl-1\.5 {
    padding-left: 0.375rem;
  }

  .sm\:dq-pl-2\.5 {
    padding-left: 0.625rem;
  }

  .sm\:dq-pl-3\.5 {
    padding-left: 0.875rem;
  }

  .sm\:dq-text-left {
    text-align: left;
  }

  .sm\:dq-text-center {
    text-align: center;
  }

  .sm\:dq-text-right {
    text-align: right;
  }

  .sm\:dq-text-justify {
    text-align: justify;
  }

  .sm\:dq-align-baseline {
    vertical-align: baseline;
  }

  .sm\:dq-align-top {
    vertical-align: top;
  }

  .sm\:dq-align-middle {
    vertical-align: middle;
  }

  .sm\:dq-align-bottom {
    vertical-align: bottom;
  }

  .sm\:dq-align-text-top {
    vertical-align: text-top;
  }

  .sm\:dq-align-text-bottom {
    vertical-align: text-bottom;
  }

  .sm\:dq-font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .sm\:dq-font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .sm\:dq-font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .sm\:dq-text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .sm\:dq-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .sm\:dq-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:dq-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:dq-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:dq-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:dq-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:dq-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:dq-text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:dq-text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .sm\:dq-text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .sm\:dq-text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .sm\:dq-text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .sm\:dq-font-thin {
    font-weight: 100;
  }

  .sm\:dq-font-extralight {
    font-weight: 200;
  }

  .sm\:dq-font-light {
    font-weight: 300;
  }

  .sm\:dq-font-normal {
    font-weight: 400;
  }

  .sm\:dq-font-medium {
    font-weight: 500;
  }

  .sm\:dq-font-semibold {
    font-weight: 600;
  }

  .sm\:dq-font-bold {
    font-weight: 700;
  }

  .sm\:dq-font-extrabold {
    font-weight: 800;
  }

  .sm\:dq-font-black {
    font-weight: 900;
  }

  .sm\:dq-uppercase {
    text-transform: uppercase;
  }

  .sm\:dq-lowercase {
    text-transform: lowercase;
  }

  .sm\:dq-capitalize {
    text-transform: capitalize;
  }

  .sm\:dq-normal-case {
    text-transform: none;
  }

  .sm\:dq-italic {
    font-style: italic;
  }

  .sm\:dq-not-italic {
    font-style: normal;
  }

  .sm\:dq-ordinal, .sm\:dq-slashed-zero, .sm\:dq-lining-nums, .sm\:dq-oldstyle-nums, .sm\:dq-proportional-nums, .sm\:dq-tabular-nums, .sm\:dq-diagonal-fractions, .sm\:dq-stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:dq-normal-nums {
    font-variant-numeric: normal;
  }

  .sm\:dq-ordinal {
    --tw-ordinal: ordinal;
  }

  .sm\:dq-slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .sm\:dq-lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .sm\:dq-oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .sm\:dq-proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .sm\:dq-tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .sm\:dq-diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .sm\:dq-stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .sm\:dq-leading-3 {
    line-height: .75rem;
  }

  .sm\:dq-leading-4 {
    line-height: 1rem;
  }

  .sm\:dq-leading-5 {
    line-height: 1.25rem;
  }

  .sm\:dq-leading-6 {
    line-height: 1.5rem;
  }

  .sm\:dq-leading-7 {
    line-height: 1.75rem;
  }

  .sm\:dq-leading-8 {
    line-height: 2rem;
  }

  .sm\:dq-leading-9 {
    line-height: 2.25rem;
  }

  .sm\:dq-leading-10 {
    line-height: 2.5rem;
  }

  .sm\:dq-leading-none {
    line-height: 1;
  }

  .sm\:dq-leading-tight {
    line-height: 1.25;
  }

  .sm\:dq-leading-snug {
    line-height: 1.375;
  }

  .sm\:dq-leading-normal {
    line-height: 1.5;
  }

  .sm\:dq-leading-relaxed {
    line-height: 1.625;
  }

  .sm\:dq-leading-loose {
    line-height: 2;
  }

  .sm\:dq-tracking-tighter {
    letter-spacing: -0.05em;
  }

  .sm\:dq-tracking-tight {
    letter-spacing: -0.025em;
  }

  .sm\:dq-tracking-normal {
    letter-spacing: 0em;
  }

  .sm\:dq-tracking-wide {
    letter-spacing: 0.025em;
  }

  .sm\:dq-tracking-wider {
    letter-spacing: 0.05em;
  }

  .sm\:dq-tracking-widest {
    letter-spacing: 0.1em;
  }

  .sm\:dq-text-transparent {
    color: transparent;
  }

  .sm\:dq-text-current {
    color: currentColor;
  }

  .sm\:dq-text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm\:dq-text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:dq-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm\:dq-text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm\:dq-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm\:dq-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm\:dq-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm\:dq-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm\:dq-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm\:dq-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm\:dq-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm\:dq-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm\:dq-text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .sm\:dq-text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .sm\:dq-text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .sm\:dq-text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .sm\:dq-text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .sm\:dq-text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .sm\:dq-text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .sm\:dq-text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .sm\:dq-text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .sm\:dq-text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .sm\:dq-text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm\:dq-text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm\:dq-text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm\:dq-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm\:dq-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm\:dq-text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm\:dq-text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm\:dq-text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm\:dq-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm\:dq-text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm\:dq-text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm\:dq-text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm\:dq-text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm\:dq-text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm\:dq-text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm\:dq-text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm\:dq-text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm\:dq-text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm\:dq-text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm\:dq-text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm\:dq-text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm\:dq-text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm\:dq-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm\:dq-text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm\:dq-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm\:dq-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm\:dq-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm\:dq-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm\:dq-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm\:dq-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm\:dq-text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm\:dq-text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm\:dq-text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm\:dq-text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm\:dq-text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm\:dq-text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm\:dq-text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm\:dq-text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm\:dq-text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm\:dq-text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm\:dq-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm\:dq-text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm\:dq-text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm\:dq-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm\:dq-text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm\:dq-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm\:dq-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm\:dq-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm\:dq-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm\:dq-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm\:dq-text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm\:dq-text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm\:dq-text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm\:dq-text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm\:dq-text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm\:dq-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm\:dq-text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm\:dq-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm\:dq-text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm\:dq-text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-transparent {
    color: transparent;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-current {
    color: currentColor;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .dq-group:hover .sm\:group-hover\:dq-text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-transparent:focus-within {
    color: transparent;
  }

  .sm\:focus-within\:dq-text-current:focus-within {
    color: currentColor;
  }

  .sm\:focus-within\:dq-text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm\:focus-within\:dq-text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-transparent:hover {
    color: transparent;
  }

  .sm\:hover\:dq-text-current:hover {
    color: currentColor;
  }

  .sm\:hover\:dq-text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm\:hover\:dq-text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-transparent:focus {
    color: transparent;
  }

  .sm\:focus\:dq-text-current:focus {
    color: currentColor;
  }

  .sm\:focus\:dq-text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm\:focus\:dq-text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm\:dq-text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .sm\:dq-text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .sm\:dq-text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .sm\:dq-text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .sm\:dq-text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .sm\:dq-text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .sm\:dq-text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .sm\:dq-text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .sm\:dq-text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .sm\:dq-text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .sm\:dq-text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .sm\:dq-text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .sm\:dq-text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .sm\:dq-text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .sm\:dq-text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .dq-group:hover .sm\:group-hover\:dq-text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .sm\:focus-within\:dq-text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .sm\:focus-within\:dq-text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }

  .sm\:focus-within\:dq-text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }

  .sm\:focus-within\:dq-text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }

  .sm\:focus-within\:dq-text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }

  .sm\:focus-within\:dq-text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }

  .sm\:focus-within\:dq-text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }

  .sm\:focus-within\:dq-text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }

  .sm\:focus-within\:dq-text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }

  .sm\:focus-within\:dq-text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }

  .sm\:focus-within\:dq-text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }

  .sm\:focus-within\:dq-text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }

  .sm\:focus-within\:dq-text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }

  .sm\:focus-within\:dq-text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }

  .sm\:focus-within\:dq-text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .sm\:hover\:dq-text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .sm\:hover\:dq-text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }

  .sm\:hover\:dq-text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }

  .sm\:hover\:dq-text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }

  .sm\:hover\:dq-text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }

  .sm\:hover\:dq-text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }

  .sm\:hover\:dq-text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }

  .sm\:hover\:dq-text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }

  .sm\:hover\:dq-text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }

  .sm\:hover\:dq-text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }

  .sm\:hover\:dq-text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }

  .sm\:hover\:dq-text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }

  .sm\:hover\:dq-text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }

  .sm\:hover\:dq-text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }

  .sm\:hover\:dq-text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .sm\:focus\:dq-text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .sm\:focus\:dq-text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }

  .sm\:focus\:dq-text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }

  .sm\:focus\:dq-text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }

  .sm\:focus\:dq-text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }

  .sm\:focus\:dq-text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }

  .sm\:focus\:dq-text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }

  .sm\:focus\:dq-text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }

  .sm\:focus\:dq-text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }

  .sm\:focus\:dq-text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }

  .sm\:focus\:dq-text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }

  .sm\:focus\:dq-text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }

  .sm\:focus\:dq-text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }

  .sm\:focus\:dq-text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }

  .sm\:focus\:dq-text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .sm\:dq-underline {
    text-decoration: underline;
  }

  .sm\:dq-line-through {
    text-decoration: line-through;
  }

  .sm\:dq-no-underline {
    text-decoration: none;
  }

  .dq-group:hover .sm\:group-hover\:dq-underline {
    text-decoration: underline;
  }

  .dq-group:hover .sm\:group-hover\:dq-line-through {
    text-decoration: line-through;
  }

  .dq-group:hover .sm\:group-hover\:dq-no-underline {
    text-decoration: none;
  }

  .sm\:focus-within\:dq-underline:focus-within {
    text-decoration: underline;
  }

  .sm\:focus-within\:dq-line-through:focus-within {
    text-decoration: line-through;
  }

  .sm\:focus-within\:dq-no-underline:focus-within {
    text-decoration: none;
  }

  .sm\:hover\:dq-underline:hover {
    text-decoration: underline;
  }

  .sm\:hover\:dq-line-through:hover {
    text-decoration: line-through;
  }

  .sm\:hover\:dq-no-underline:hover {
    text-decoration: none;
  }

  .sm\:focus\:dq-underline:focus {
    text-decoration: underline;
  }

  .sm\:focus\:dq-line-through:focus {
    text-decoration: line-through;
  }

  .sm\:focus\:dq-no-underline:focus {
    text-decoration: none;
  }

  .sm\:dq-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sm\:dq-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .sm\:dq-placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }

  .sm\:dq-placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }

  .sm\:dq-placeholder-transparent::placeholder {
    color: transparent;
  }

  .sm\:dq-placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }

  .sm\:dq-placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }

  .sm\:dq-placeholder-current::placeholder {
    color: currentColor;
  }

  .sm\:dq-placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .sm\:focus\:dq-placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }

  .sm\:focus\:dq-placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .sm\:focus\:dq-placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }

  .sm\:focus\:dq-placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }

  .sm\:focus\:dq-placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .sm\:focus\:dq-placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:dq-placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .sm\:dq-placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm\:dq-placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm\:dq-placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm\:dq-placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .sm\:dq-placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .sm\:dq-placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .sm\:dq-placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .sm\:dq-placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .sm\:dq-placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .sm\:dq-placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .sm\:dq-placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .sm\:dq-placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .sm\:dq-placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:dq-placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:dq-placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:dq-placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .sm\:dq-placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .sm\:dq-placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .sm\:dq-placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .sm\:dq-placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .sm\:dq-placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .sm\:dq-placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:dq-placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:dq-placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:dq-placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .sm\:dq-placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .sm\:dq-placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .sm\:dq-placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .sm\:dq-placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .sm\:dq-placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .sm\:dq-placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:dq-placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:dq-placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:dq-placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .sm\:dq-placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .sm\:dq-placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .sm\:dq-placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:dq-placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:dq-placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:dq-placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .sm\:dq-placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .sm\:dq-placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .sm\:dq-placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm\:dq-placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm\:dq-placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm\:focus\:dq-placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm\:focus\:dq-placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm\:focus\:dq-placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm\:focus\:dq-placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .sm\:focus\:dq-placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .sm\:focus\:dq-placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .sm\:focus\:dq-placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .sm\:focus\:dq-placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .sm\:focus\:dq-placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .sm\:focus\:dq-placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .sm\:focus\:dq-placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .sm\:focus\:dq-placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .sm\:focus\:dq-placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:focus\:dq-placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:focus\:dq-placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:focus\:dq-placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .sm\:focus\:dq-placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .sm\:focus\:dq-placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .sm\:focus\:dq-placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .sm\:focus\:dq-placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .sm\:focus\:dq-placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .sm\:focus\:dq-placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:focus\:dq-placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:focus\:dq-placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:focus\:dq-placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .sm\:focus\:dq-placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .sm\:focus\:dq-placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .sm\:focus\:dq-placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .sm\:focus\:dq-placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .sm\:focus\:dq-placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .sm\:focus\:dq-placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:focus\:dq-placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:focus\:dq-placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:focus\:dq-placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .sm\:focus\:dq-placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .sm\:focus\:dq-placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .sm\:focus\:dq-placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:focus\:dq-placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:focus\:dq-placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .sm\:focus\:dq-placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .sm\:focus\:dq-placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .sm\:focus\:dq-placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .sm\:focus\:dq-placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm\:focus\:dq-placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm\:focus\:dq-placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm\:dq-opacity-0 {
    opacity: 0;
  }

  .sm\:dq-opacity-5 {
    opacity: 0.05;
  }

  .sm\:dq-opacity-10 {
    opacity: 0.1;
  }

  .sm\:dq-opacity-20 {
    opacity: 0.2;
  }

  .sm\:dq-opacity-25 {
    opacity: 0.25;
  }

  .sm\:dq-opacity-30 {
    opacity: 0.3;
  }

  .sm\:dq-opacity-40 {
    opacity: 0.4;
  }

  .sm\:dq-opacity-50 {
    opacity: 0.5;
  }

  .sm\:dq-opacity-60 {
    opacity: 0.6;
  }

  .sm\:dq-opacity-70 {
    opacity: 0.7;
  }

  .sm\:dq-opacity-75 {
    opacity: 0.75;
  }

  .sm\:dq-opacity-80 {
    opacity: 0.8;
  }

  .sm\:dq-opacity-90 {
    opacity: 0.9;
  }

  .sm\:dq-opacity-95 {
    opacity: 0.95;
  }

  .sm\:dq-opacity-100 {
    opacity: 1;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-0 {
    opacity: 0;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-5 {
    opacity: 0.05;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-10 {
    opacity: 0.1;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-20 {
    opacity: 0.2;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-25 {
    opacity: 0.25;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-30 {
    opacity: 0.3;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-40 {
    opacity: 0.4;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-50 {
    opacity: 0.5;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-60 {
    opacity: 0.6;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-70 {
    opacity: 0.7;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-75 {
    opacity: 0.75;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-80 {
    opacity: 0.8;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-90 {
    opacity: 0.9;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-95 {
    opacity: 0.95;
  }

  .dq-group:hover .sm\:group-hover\:dq-opacity-100 {
    opacity: 1;
  }

  .sm\:focus-within\:dq-opacity-0:focus-within {
    opacity: 0;
  }

  .sm\:focus-within\:dq-opacity-5:focus-within {
    opacity: 0.05;
  }

  .sm\:focus-within\:dq-opacity-10:focus-within {
    opacity: 0.1;
  }

  .sm\:focus-within\:dq-opacity-20:focus-within {
    opacity: 0.2;
  }

  .sm\:focus-within\:dq-opacity-25:focus-within {
    opacity: 0.25;
  }

  .sm\:focus-within\:dq-opacity-30:focus-within {
    opacity: 0.3;
  }

  .sm\:focus-within\:dq-opacity-40:focus-within {
    opacity: 0.4;
  }

  .sm\:focus-within\:dq-opacity-50:focus-within {
    opacity: 0.5;
  }

  .sm\:focus-within\:dq-opacity-60:focus-within {
    opacity: 0.6;
  }

  .sm\:focus-within\:dq-opacity-70:focus-within {
    opacity: 0.7;
  }

  .sm\:focus-within\:dq-opacity-75:focus-within {
    opacity: 0.75;
  }

  .sm\:focus-within\:dq-opacity-80:focus-within {
    opacity: 0.8;
  }

  .sm\:focus-within\:dq-opacity-90:focus-within {
    opacity: 0.9;
  }

  .sm\:focus-within\:dq-opacity-95:focus-within {
    opacity: 0.95;
  }

  .sm\:focus-within\:dq-opacity-100:focus-within {
    opacity: 1;
  }

  .sm\:hover\:dq-opacity-0:hover {
    opacity: 0;
  }

  .sm\:hover\:dq-opacity-5:hover {
    opacity: 0.05;
  }

  .sm\:hover\:dq-opacity-10:hover {
    opacity: 0.1;
  }

  .sm\:hover\:dq-opacity-20:hover {
    opacity: 0.2;
  }

  .sm\:hover\:dq-opacity-25:hover {
    opacity: 0.25;
  }

  .sm\:hover\:dq-opacity-30:hover {
    opacity: 0.3;
  }

  .sm\:hover\:dq-opacity-40:hover {
    opacity: 0.4;
  }

  .sm\:hover\:dq-opacity-50:hover {
    opacity: 0.5;
  }

  .sm\:hover\:dq-opacity-60:hover {
    opacity: 0.6;
  }

  .sm\:hover\:dq-opacity-70:hover {
    opacity: 0.7;
  }

  .sm\:hover\:dq-opacity-75:hover {
    opacity: 0.75;
  }

  .sm\:hover\:dq-opacity-80:hover {
    opacity: 0.8;
  }

  .sm\:hover\:dq-opacity-90:hover {
    opacity: 0.9;
  }

  .sm\:hover\:dq-opacity-95:hover {
    opacity: 0.95;
  }

  .sm\:hover\:dq-opacity-100:hover {
    opacity: 1;
  }

  .sm\:focus\:dq-opacity-0:focus {
    opacity: 0;
  }

  .sm\:focus\:dq-opacity-5:focus {
    opacity: 0.05;
  }

  .sm\:focus\:dq-opacity-10:focus {
    opacity: 0.1;
  }

  .sm\:focus\:dq-opacity-20:focus {
    opacity: 0.2;
  }

  .sm\:focus\:dq-opacity-25:focus {
    opacity: 0.25;
  }

  .sm\:focus\:dq-opacity-30:focus {
    opacity: 0.3;
  }

  .sm\:focus\:dq-opacity-40:focus {
    opacity: 0.4;
  }

  .sm\:focus\:dq-opacity-50:focus {
    opacity: 0.5;
  }

  .sm\:focus\:dq-opacity-60:focus {
    opacity: 0.6;
  }

  .sm\:focus\:dq-opacity-70:focus {
    opacity: 0.7;
  }

  .sm\:focus\:dq-opacity-75:focus {
    opacity: 0.75;
  }

  .sm\:focus\:dq-opacity-80:focus {
    opacity: 0.8;
  }

  .sm\:focus\:dq-opacity-90:focus {
    opacity: 0.9;
  }

  .sm\:focus\:dq-opacity-95:focus {
    opacity: 0.95;
  }

  .sm\:focus\:dq-opacity-100:focus {
    opacity: 1;
  }

  .sm\:dq-bg-blend-normal {
    background-blend-mode: normal;
  }

  .sm\:dq-bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .sm\:dq-bg-blend-screen {
    background-blend-mode: screen;
  }

  .sm\:dq-bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .sm\:dq-bg-blend-darken {
    background-blend-mode: darken;
  }

  .sm\:dq-bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .sm\:dq-bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .sm\:dq-bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .sm\:dq-bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .sm\:dq-bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .sm\:dq-bg-blend-difference {
    background-blend-mode: difference;
  }

  .sm\:dq-bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .sm\:dq-bg-blend-hue {
    background-blend-mode: hue;
  }

  .sm\:dq-bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .sm\:dq-bg-blend-color {
    background-blend-mode: color;
  }

  .sm\:dq-bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .sm\:dq-mix-blend-normal {
    mix-blend-mode: normal;
  }

  .sm\:dq-mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .sm\:dq-mix-blend-screen {
    mix-blend-mode: screen;
  }

  .sm\:dq-mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .sm\:dq-mix-blend-darken {
    mix-blend-mode: darken;
  }

  .sm\:dq-mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .sm\:dq-mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .sm\:dq-mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .sm\:dq-mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .sm\:dq-mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .sm\:dq-mix-blend-difference {
    mix-blend-mode: difference;
  }

  .sm\:dq-mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .sm\:dq-mix-blend-hue {
    mix-blend-mode: hue;
  }

  .sm\:dq-mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .sm\:dq-mix-blend-color {
    mix-blend-mode: color;
  }

  .sm\:dq-mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .sm\:dq-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:dq-shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:dq-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:dq-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:dq-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:dq-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:dq-shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:dq-shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .sm\:group-hover\:dq-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .sm\:group-hover\:dq-shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .sm\:group-hover\:dq-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .sm\:group-hover\:dq-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .sm\:group-hover\:dq-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .sm\:group-hover\:dq-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .sm\:group-hover\:dq-shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .sm\:group-hover\:dq-shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:dq-shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:dq-shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:dq-shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:dq-shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:dq-shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:dq-shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:dq-shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:dq-shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:dq-shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:dq-shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:dq-shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:dq-shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:dq-shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:dq-shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:dq-shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:dq-shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:dq-shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:dq-shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:dq-shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:dq-shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:dq-shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:dq-shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:dq-shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:dq-shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:dq-outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .sm\:dq-outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .sm\:dq-outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .sm\:focus-within\:dq-outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .sm\:focus-within\:dq-outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .sm\:focus-within\:dq-outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .sm\:focus\:dq-outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .sm\:focus\:dq-outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .sm\:focus\:dq-outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .sm\:dq-ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:dq-ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:dq-ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:dq-ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:dq-ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:dq-ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:dq-ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:dq-ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:dq-ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:dq-ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:dq-ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:dq-ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:dq-ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:dq-ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:dq-ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:dq-ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:dq-ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:dq-ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:dq-ring-inset {
    --tw-ring-inset: inset;
  }

  .sm\:focus-within\:dq-ring-inset:focus-within {
    --tw-ring-inset: inset;
  }

  .sm\:focus\:dq-ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .sm\:dq-ring-transparent {
    --tw-ring-color: transparent;
  }

  .sm\:dq-ring-current {
    --tw-ring-color: currentColor;
  }

  .sm\:dq-ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .sm\:focus-within\:dq-ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .sm\:focus-within\:dq-ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:dq-ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .sm\:focus\:dq-ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .sm\:focus\:dq-ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .sm\:focus\:dq-ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .sm\:dq-ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .sm\:dq-ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }

  .sm\:dq-ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }

  .sm\:dq-ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }

  .sm\:dq-ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }

  .sm\:dq-ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }

  .sm\:dq-ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }

  .sm\:dq-ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }

  .sm\:dq-ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }

  .sm\:dq-ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }

  .sm\:dq-ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }

  .sm\:dq-ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }

  .sm\:dq-ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }

  .sm\:dq-ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }

  .sm\:dq-ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .sm\:focus-within\:dq-ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .sm\:focus-within\:dq-ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }

  .sm\:focus-within\:dq-ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }

  .sm\:focus-within\:dq-ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }

  .sm\:focus-within\:dq-ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }

  .sm\:focus-within\:dq-ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }

  .sm\:focus-within\:dq-ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }

  .sm\:focus-within\:dq-ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }

  .sm\:focus-within\:dq-ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }

  .sm\:focus-within\:dq-ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }

  .sm\:focus-within\:dq-ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }

  .sm\:focus-within\:dq-ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }

  .sm\:focus-within\:dq-ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }

  .sm\:focus-within\:dq-ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }

  .sm\:focus-within\:dq-ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .sm\:focus\:dq-ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .sm\:focus\:dq-ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }

  .sm\:focus\:dq-ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }

  .sm\:focus\:dq-ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }

  .sm\:focus\:dq-ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }

  .sm\:focus\:dq-ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }

  .sm\:focus\:dq-ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }

  .sm\:focus\:dq-ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }

  .sm\:focus\:dq-ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }

  .sm\:focus\:dq-ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }

  .sm\:focus\:dq-ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }

  .sm\:focus\:dq-ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }

  .sm\:focus\:dq-ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }

  .sm\:focus\:dq-ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }

  .sm\:focus\:dq-ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .sm\:dq-ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .sm\:dq-ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .sm\:dq-ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .sm\:dq-ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .sm\:dq-ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .sm\:focus-within\:dq-ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .sm\:focus-within\:dq-ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .sm\:focus-within\:dq-ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .sm\:focus-within\:dq-ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .sm\:focus-within\:dq-ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .sm\:focus\:dq-ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .sm\:focus\:dq-ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .sm\:focus\:dq-ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .sm\:focus\:dq-ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .sm\:focus\:dq-ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .sm\:dq-ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .sm\:dq-ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .sm\:dq-ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .sm\:dq-ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .sm\:dq-ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .sm\:dq-ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .sm\:dq-ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .sm\:dq-ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .sm\:dq-ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .sm\:dq-ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .sm\:dq-ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .sm\:dq-ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .sm\:dq-ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .sm\:dq-ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .sm\:dq-ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }

  .sm\:dq-ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }

  .sm\:dq-ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }

  .sm\:dq-ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }

  .sm\:dq-ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }

  .sm\:dq-ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }

  .sm\:dq-ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }

  .sm\:dq-ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }

  .sm\:dq-ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }

  .sm\:dq-ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }

  .sm\:dq-ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .sm\:dq-ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .sm\:dq-ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .sm\:dq-ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .sm\:dq-ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .sm\:dq-ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .sm\:dq-ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .sm\:dq-ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .sm\:dq-ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .sm\:dq-ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .sm\:dq-ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .sm\:dq-ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .sm\:dq-ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .sm\:dq-ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .sm\:dq-ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .sm\:dq-ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .sm\:dq-ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .sm\:dq-ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .sm\:dq-ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .sm\:dq-ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .sm\:dq-ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .sm\:dq-ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .sm\:dq-ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .sm\:dq-ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .sm\:dq-ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .sm\:dq-ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .sm\:dq-ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .sm\:dq-ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .sm\:dq-ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .sm\:dq-ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .sm\:dq-ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .sm\:dq-ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .sm\:dq-ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .sm\:dq-ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .sm\:dq-ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .sm\:dq-ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .sm\:dq-ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .sm\:dq-ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .sm\:dq-ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .sm\:dq-ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .sm\:dq-ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .sm\:dq-ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .sm\:dq-ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .sm\:dq-ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .sm\:dq-ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .sm\:dq-ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .sm\:dq-ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .sm\:dq-ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .sm\:dq-ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .sm\:dq-ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .sm\:dq-ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .sm\:dq-ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .sm\:dq-ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .sm\:dq-ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .sm\:dq-ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .sm\:dq-ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .sm\:dq-ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .sm\:dq-ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .sm\:dq-ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .sm\:dq-ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .sm\:focus-within\:dq-ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .sm\:focus-within\:dq-ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .sm\:focus-within\:dq-ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .sm\:focus-within\:dq-ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .sm\:focus-within\:dq-ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .sm\:focus-within\:dq-ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .sm\:focus-within\:dq-ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .sm\:focus-within\:dq-ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .sm\:focus-within\:dq-ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .sm\:focus-within\:dq-ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .sm\:focus-within\:dq-ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .sm\:focus-within\:dq-ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .sm\:focus-within\:dq-ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .sm\:focus-within\:dq-ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .sm\:focus-within\:dq-ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }

  .sm\:focus-within\:dq-ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }

  .sm\:focus-within\:dq-ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }

  .sm\:focus-within\:dq-ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }

  .sm\:focus-within\:dq-ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }

  .sm\:focus-within\:dq-ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }

  .sm\:focus-within\:dq-ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }

  .sm\:focus-within\:dq-ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }

  .sm\:focus-within\:dq-ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }

  .sm\:focus-within\:dq-ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }

  .sm\:focus-within\:dq-ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .sm\:focus-within\:dq-ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .sm\:focus-within\:dq-ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .sm\:focus-within\:dq-ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .sm\:focus-within\:dq-ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .sm\:focus-within\:dq-ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .sm\:focus-within\:dq-ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .sm\:focus-within\:dq-ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .sm\:focus-within\:dq-ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .sm\:focus-within\:dq-ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .sm\:focus-within\:dq-ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .sm\:focus-within\:dq-ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .sm\:focus-within\:dq-ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .sm\:focus-within\:dq-ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .sm\:focus-within\:dq-ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .sm\:focus-within\:dq-ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .sm\:focus-within\:dq-ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .sm\:focus-within\:dq-ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .sm\:focus-within\:dq-ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .sm\:focus-within\:dq-ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .sm\:focus-within\:dq-ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .sm\:focus-within\:dq-ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .sm\:focus-within\:dq-ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .sm\:focus-within\:dq-ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .sm\:focus-within\:dq-ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .sm\:focus-within\:dq-ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .sm\:focus-within\:dq-ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .sm\:focus-within\:dq-ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .sm\:focus-within\:dq-ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .sm\:focus-within\:dq-ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .sm\:focus-within\:dq-ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .sm\:focus-within\:dq-ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .sm\:focus-within\:dq-ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .sm\:focus-within\:dq-ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .sm\:focus-within\:dq-ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .sm\:focus-within\:dq-ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .sm\:focus-within\:dq-ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .sm\:focus-within\:dq-ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .sm\:focus-within\:dq-ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .sm\:focus-within\:dq-ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .sm\:focus-within\:dq-ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .sm\:focus-within\:dq-ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .sm\:focus-within\:dq-ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .sm\:focus-within\:dq-ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .sm\:focus-within\:dq-ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .sm\:focus-within\:dq-ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .sm\:focus-within\:dq-ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .sm\:focus-within\:dq-ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .sm\:focus-within\:dq-ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .sm\:focus-within\:dq-ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .sm\:focus-within\:dq-ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .sm\:focus-within\:dq-ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .sm\:focus-within\:dq-ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .sm\:focus-within\:dq-ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .sm\:focus-within\:dq-ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .sm\:focus-within\:dq-ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .sm\:focus-within\:dq-ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .sm\:focus-within\:dq-ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .sm\:focus-within\:dq-ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .sm\:focus-within\:dq-ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .sm\:focus\:dq-ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .sm\:focus\:dq-ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .sm\:focus\:dq-ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .sm\:focus\:dq-ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .sm\:focus\:dq-ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .sm\:focus\:dq-ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .sm\:focus\:dq-ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .sm\:focus\:dq-ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .sm\:focus\:dq-ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .sm\:focus\:dq-ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .sm\:focus\:dq-ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .sm\:focus\:dq-ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .sm\:focus\:dq-ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .sm\:focus\:dq-ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .sm\:focus\:dq-ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }

  .sm\:focus\:dq-ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }

  .sm\:focus\:dq-ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }

  .sm\:focus\:dq-ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }

  .sm\:focus\:dq-ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }

  .sm\:focus\:dq-ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }

  .sm\:focus\:dq-ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }

  .sm\:focus\:dq-ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }

  .sm\:focus\:dq-ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }

  .sm\:focus\:dq-ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }

  .sm\:focus\:dq-ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .sm\:focus\:dq-ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .sm\:focus\:dq-ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .sm\:focus\:dq-ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .sm\:focus\:dq-ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .sm\:focus\:dq-ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .sm\:focus\:dq-ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .sm\:focus\:dq-ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .sm\:focus\:dq-ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .sm\:focus\:dq-ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .sm\:focus\:dq-ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .sm\:focus\:dq-ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .sm\:focus\:dq-ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .sm\:focus\:dq-ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .sm\:focus\:dq-ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .sm\:focus\:dq-ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .sm\:focus\:dq-ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .sm\:focus\:dq-ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .sm\:focus\:dq-ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .sm\:focus\:dq-ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .sm\:focus\:dq-ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .sm\:focus\:dq-ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .sm\:focus\:dq-ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .sm\:focus\:dq-ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .sm\:focus\:dq-ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .sm\:focus\:dq-ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .sm\:focus\:dq-ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .sm\:focus\:dq-ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .sm\:focus\:dq-ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .sm\:focus\:dq-ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .sm\:focus\:dq-ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .sm\:focus\:dq-ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .sm\:focus\:dq-ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .sm\:focus\:dq-ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .sm\:focus\:dq-ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .sm\:focus\:dq-ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .sm\:focus\:dq-ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .sm\:focus\:dq-ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .sm\:focus\:dq-ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .sm\:focus\:dq-ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .sm\:focus\:dq-ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .sm\:focus\:dq-ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .sm\:focus\:dq-ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .sm\:focus\:dq-ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .sm\:focus\:dq-ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .sm\:focus\:dq-ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .sm\:focus\:dq-ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .sm\:focus\:dq-ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .sm\:focus\:dq-ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .sm\:focus\:dq-ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .sm\:focus\:dq-ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .sm\:focus\:dq-ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .sm\:focus\:dq-ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .sm\:focus\:dq-ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .sm\:focus\:dq-ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .sm\:focus\:dq-ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .sm\:focus\:dq-ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .sm\:focus\:dq-ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .sm\:focus\:dq-ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .sm\:focus\:dq-ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .sm\:dq-filter {
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:dq-filter-none {
    -webkit-filter: none;
            filter: none;
  }

  .sm\:dq-blur-0 {
    --tw-blur: blur(0);
  }

  .sm\:dq-blur-none {
    --tw-blur: blur(0);
  }

  .sm\:dq-blur-sm {
    --tw-blur: blur(4px);
  }

  .sm\:dq-blur {
    --tw-blur: blur(8px);
  }

  .sm\:dq-blur-md {
    --tw-blur: blur(12px);
  }

  .sm\:dq-blur-lg {
    --tw-blur: blur(16px);
  }

  .sm\:dq-blur-xl {
    --tw-blur: blur(24px);
  }

  .sm\:dq-blur-2xl {
    --tw-blur: blur(40px);
  }

  .sm\:dq-blur-3xl {
    --tw-blur: blur(64px);
  }

  .sm\:dq-brightness-0 {
    --tw-brightness: brightness(0);
  }

  .sm\:dq-brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .sm\:dq-brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .sm\:dq-brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .sm\:dq-brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .sm\:dq-brightness-100 {
    --tw-brightness: brightness(1);
  }

  .sm\:dq-brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .sm\:dq-brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .sm\:dq-brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .sm\:dq-brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .sm\:dq-brightness-200 {
    --tw-brightness: brightness(2);
  }

  .sm\:dq-contrast-0 {
    --tw-contrast: contrast(0);
  }

  .sm\:dq-contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .sm\:dq-contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .sm\:dq-contrast-100 {
    --tw-contrast: contrast(1);
  }

  .sm\:dq-contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .sm\:dq-contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .sm\:dq-contrast-200 {
    --tw-contrast: contrast(2);
  }

  .sm\:dq-drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .sm\:dq-drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .sm\:dq-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .sm\:dq-drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .sm\:dq-drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .sm\:dq-drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .sm\:dq-drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .sm\:dq-grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .sm\:dq-grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .sm\:dq-hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .sm\:dq-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .sm\:dq-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .sm\:dq-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .sm\:dq-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .sm\:dq-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .sm\:dq--hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .sm\:dq--hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .sm\:dq--hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .sm\:dq--hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .sm\:dq--hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .sm\:dq-invert-0 {
    --tw-invert: invert(0);
  }

  .sm\:dq-invert {
    --tw-invert: invert(100%);
  }

  .sm\:dq-saturate-0 {
    --tw-saturate: saturate(0);
  }

  .sm\:dq-saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .sm\:dq-saturate-100 {
    --tw-saturate: saturate(1);
  }

  .sm\:dq-saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .sm\:dq-saturate-200 {
    --tw-saturate: saturate(2);
  }

  .sm\:dq-sepia-0 {
    --tw-sepia: sepia(0);
  }

  .sm\:dq-sepia {
    --tw-sepia: sepia(100%);
  }

  .sm\:dq-backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:dq-backdrop-filter-none {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }

  .sm\:dq-backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }

  .sm\:dq-backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .sm\:dq-backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .sm\:dq-backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .sm\:dq-backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .sm\:dq-backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .sm\:dq-backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .sm\:dq-backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .sm\:dq-backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .sm\:dq-backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .sm\:dq-backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .sm\:dq-backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .sm\:dq-backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .sm\:dq-backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .sm\:dq-backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .sm\:dq-backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .sm\:dq-backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .sm\:dq-backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .sm\:dq-backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .sm\:dq-backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .sm\:dq-backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .sm\:dq-backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .sm\:dq-backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .sm\:dq-backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .sm\:dq-backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .sm\:dq-backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .sm\:dq-backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .sm\:dq-backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .sm\:dq-backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .sm\:dq-backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .sm\:dq-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .sm\:dq-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .sm\:dq-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .sm\:dq-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .sm\:dq-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .sm\:dq--backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .sm\:dq--backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .sm\:dq--backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .sm\:dq--backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .sm\:dq--backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .sm\:dq-backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .sm\:dq-backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .sm\:dq-backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .sm\:dq-backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }

  .sm\:dq-backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }

  .sm\:dq-backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }

  .sm\:dq-backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }

  .sm\:dq-backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }

  .sm\:dq-backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }

  .sm\:dq-backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }

  .sm\:dq-backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }

  .sm\:dq-backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }

  .sm\:dq-backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }

  .sm\:dq-backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }

  .sm\:dq-backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }

  .sm\:dq-backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }

  .sm\:dq-backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .sm\:dq-backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .sm\:dq-backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .sm\:dq-backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .sm\:dq-backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .sm\:dq-backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .sm\:dq-backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .sm\:dq-backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .sm\:dq-transition-none {
    transition-property: none;
  }

  .sm\:dq-transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .sm\:dq-transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .sm\:dq-transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .sm\:dq-transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .sm\:dq-transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .sm\:dq-transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .sm\:dq-delay-75 {
    transition-delay: 75ms;
  }

  .sm\:dq-delay-100 {
    transition-delay: 100ms;
  }

  .sm\:dq-delay-150 {
    transition-delay: 150ms;
  }

  .sm\:dq-delay-200 {
    transition-delay: 200ms;
  }

  .sm\:dq-delay-300 {
    transition-delay: 300ms;
  }

  .sm\:dq-delay-500 {
    transition-delay: 500ms;
  }

  .sm\:dq-delay-700 {
    transition-delay: 700ms;
  }

  .sm\:dq-delay-1000 {
    transition-delay: 1000ms;
  }

  .sm\:dq-duration-75 {
    transition-duration: 75ms;
  }

  .sm\:dq-duration-100 {
    transition-duration: 100ms;
  }

  .sm\:dq-duration-150 {
    transition-duration: 150ms;
  }

  .sm\:dq-duration-200 {
    transition-duration: 200ms;
  }

  .sm\:dq-duration-300 {
    transition-duration: 300ms;
  }

  .sm\:dq-duration-500 {
    transition-duration: 500ms;
  }

  .sm\:dq-duration-700 {
    transition-duration: 700ms;
  }

  .sm\:dq-duration-1000 {
    transition-duration: 1000ms;
  }

  .sm\:dq-ease-linear {
    transition-timing-function: linear;
  }

  .sm\:dq-ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .sm\:dq-ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .sm\:dq-ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 768px) {
  .md\:dq-container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .md\:dq-container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .md\:dq-container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .md\:dq-container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .md\:dq-container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .md\:dq-container {
      max-width: 1536px;
    }
  }

  .md\:dq-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .md\:dq-not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .md\:focus-within\:dq-sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .md\:focus-within\:dq-not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .md\:focus\:dq-sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .md\:focus\:dq-not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .md\:dq-pointer-events-none {
    pointer-events: none;
  }

  .md\:dq-pointer-events-auto {
    pointer-events: auto;
  }

  .md\:dq-visible {
    visibility: visible;
  }

  .md\:dq-invisible {
    visibility: hidden;
  }

  .md\:dq-static {
    position: static;
  }

  .md\:dq-fixed {
    position: fixed;
  }

  .md\:dq-absolute {
    position: absolute;
  }

  .md\:dq-relative {
    position: relative;
  }

  .md\:dq-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .md\:dq-inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .md\:dq-inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }

  .md\:dq-inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }

  .md\:dq-inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }

  .md\:dq-inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }

  .md\:dq-inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }

  .md\:dq-inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }

  .md\:dq-inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }

  .md\:dq-inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }

  .md\:dq-inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }

  .md\:dq-inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }

  .md\:dq-inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }

  .md\:dq-inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }

  .md\:dq-inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }

  .md\:dq-inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }

  .md\:dq-inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }

  .md\:dq-inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }

  .md\:dq-inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }

  .md\:dq-inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }

  .md\:dq-inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }

  .md\:dq-inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }

  .md\:dq-inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }

  .md\:dq-inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }

  .md\:dq-inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }

  .md\:dq-inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }

  .md\:dq-inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }

  .md\:dq-inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }

  .md\:dq-inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }

  .md\:dq-inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }

  .md\:dq-inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }

  .md\:dq-inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .md\:dq-inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }

  .md\:dq-inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }

  .md\:dq-inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }

  .md\:dq-inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }

  .md\:dq-inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }

  .md\:dq--inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .md\:dq--inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }

  .md\:dq--inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }

  .md\:dq--inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }

  .md\:dq--inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }

  .md\:dq--inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }

  .md\:dq--inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }

  .md\:dq--inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }

  .md\:dq--inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }

  .md\:dq--inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }

  .md\:dq--inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }

  .md\:dq--inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }

  .md\:dq--inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }

  .md\:dq--inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }

  .md\:dq--inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }

  .md\:dq--inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }

  .md\:dq--inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }

  .md\:dq--inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }

  .md\:dq--inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }

  .md\:dq--inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }

  .md\:dq--inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }

  .md\:dq--inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }

  .md\:dq--inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }

  .md\:dq--inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }

  .md\:dq--inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }

  .md\:dq--inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }

  .md\:dq--inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }

  .md\:dq--inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }

  .md\:dq--inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }

  .md\:dq--inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }

  .md\:dq--inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }

  .md\:dq--inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }

  .md\:dq--inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }

  .md\:dq--inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }

  .md\:dq--inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }

  .md\:dq-inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }

  .md\:dq-inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }

  .md\:dq-inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }

  .md\:dq-inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }

  .md\:dq-inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }

  .md\:dq-inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }

  .md\:dq-inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }

  .md\:dq--inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }

  .md\:dq--inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }

  .md\:dq--inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }

  .md\:dq--inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }

  .md\:dq--inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }

  .md\:dq--inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }

  .md\:dq--inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }

  .md\:dq-inset-x-0 {
    left: 0px;
    right: 0px;
  }

  .md\:dq-inset-x-1 {
    left: 0.25rem;
    right: 0.25rem;
  }

  .md\:dq-inset-x-2 {
    left: 0.5rem;
    right: 0.5rem;
  }

  .md\:dq-inset-x-3 {
    left: 0.75rem;
    right: 0.75rem;
  }

  .md\:dq-inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .md\:dq-inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .md\:dq-inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .md\:dq-inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .md\:dq-inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .md\:dq-inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .md\:dq-inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .md\:dq-inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .md\:dq-inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .md\:dq-inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .md\:dq-inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .md\:dq-inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .md\:dq-inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .md\:dq-inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .md\:dq-inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .md\:dq-inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .md\:dq-inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .md\:dq-inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .md\:dq-inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .md\:dq-inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .md\:dq-inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .md\:dq-inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .md\:dq-inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .md\:dq-inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .md\:dq-inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .md\:dq-inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .md\:dq-inset-x-auto {
    left: auto;
    right: auto;
  }

  .md\:dq-inset-x-px {
    left: 1px;
    right: 1px;
  }

  .md\:dq-inset-x-0\.5 {
    left: 0.125rem;
    right: 0.125rem;
  }

  .md\:dq-inset-x-1\.5 {
    left: 0.375rem;
    right: 0.375rem;
  }

  .md\:dq-inset-x-2\.5 {
    left: 0.625rem;
    right: 0.625rem;
  }

  .md\:dq-inset-x-3\.5 {
    left: 0.875rem;
    right: 0.875rem;
  }

  .md\:dq--inset-x-0 {
    left: 0px;
    right: 0px;
  }

  .md\:dq--inset-x-1 {
    left: -0.25rem;
    right: -0.25rem;
  }

  .md\:dq--inset-x-2 {
    left: -0.5rem;
    right: -0.5rem;
  }

  .md\:dq--inset-x-3 {
    left: -0.75rem;
    right: -0.75rem;
  }

  .md\:dq--inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .md\:dq--inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .md\:dq--inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .md\:dq--inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .md\:dq--inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .md\:dq--inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .md\:dq--inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .md\:dq--inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .md\:dq--inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .md\:dq--inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .md\:dq--inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .md\:dq--inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .md\:dq--inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .md\:dq--inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .md\:dq--inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .md\:dq--inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .md\:dq--inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .md\:dq--inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .md\:dq--inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .md\:dq--inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .md\:dq--inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .md\:dq--inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .md\:dq--inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .md\:dq--inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .md\:dq--inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .md\:dq--inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .md\:dq--inset-x-px {
    left: -1px;
    right: -1px;
  }

  .md\:dq--inset-x-0\.5 {
    left: -0.125rem;
    right: -0.125rem;
  }

  .md\:dq--inset-x-1\.5 {
    left: -0.375rem;
    right: -0.375rem;
  }

  .md\:dq--inset-x-2\.5 {
    left: -0.625rem;
    right: -0.625rem;
  }

  .md\:dq--inset-x-3\.5 {
    left: -0.875rem;
    right: -0.875rem;
  }

  .md\:dq-inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .md\:dq-inset-x-1\/3 {
    left: 33.333333%;
    right: 33.333333%;
  }

  .md\:dq-inset-x-2\/3 {
    left: 66.666667%;
    right: 66.666667%;
  }

  .md\:dq-inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .md\:dq-inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .md\:dq-inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .md\:dq-inset-x-full {
    left: 100%;
    right: 100%;
  }

  .md\:dq--inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .md\:dq--inset-x-1\/3 {
    left: -33.333333%;
    right: -33.333333%;
  }

  .md\:dq--inset-x-2\/3 {
    left: -66.666667%;
    right: -66.666667%;
  }

  .md\:dq--inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .md\:dq--inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .md\:dq--inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .md\:dq--inset-x-full {
    left: -100%;
    right: -100%;
  }

  .md\:dq-inset-y-0 {
    top: 0px;
    bottom: 0px;
  }

  .md\:dq-inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }

  .md\:dq-inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }

  .md\:dq-inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }

  .md\:dq-inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .md\:dq-inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .md\:dq-inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .md\:dq-inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .md\:dq-inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .md\:dq-inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .md\:dq-inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .md\:dq-inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .md\:dq-inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .md\:dq-inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .md\:dq-inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .md\:dq-inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .md\:dq-inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .md\:dq-inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .md\:dq-inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .md\:dq-inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .md\:dq-inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .md\:dq-inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .md\:dq-inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .md\:dq-inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .md\:dq-inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .md\:dq-inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .md\:dq-inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .md\:dq-inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .md\:dq-inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .md\:dq-inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .md\:dq-inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .md\:dq-inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .md\:dq-inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }

  .md\:dq-inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }

  .md\:dq-inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }

  .md\:dq-inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }

  .md\:dq--inset-y-0 {
    top: 0px;
    bottom: 0px;
  }

  .md\:dq--inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }

  .md\:dq--inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }

  .md\:dq--inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }

  .md\:dq--inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .md\:dq--inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .md\:dq--inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .md\:dq--inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .md\:dq--inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .md\:dq--inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .md\:dq--inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .md\:dq--inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .md\:dq--inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .md\:dq--inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .md\:dq--inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .md\:dq--inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .md\:dq--inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .md\:dq--inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .md\:dq--inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .md\:dq--inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .md\:dq--inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .md\:dq--inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .md\:dq--inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .md\:dq--inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .md\:dq--inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .md\:dq--inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .md\:dq--inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .md\:dq--inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .md\:dq--inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .md\:dq--inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .md\:dq--inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .md\:dq--inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }

  .md\:dq--inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }

  .md\:dq--inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }

  .md\:dq--inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }

  .md\:dq-inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .md\:dq-inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }

  .md\:dq-inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }

  .md\:dq-inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .md\:dq-inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .md\:dq-inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .md\:dq-inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .md\:dq--inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .md\:dq--inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }

  .md\:dq--inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }

  .md\:dq--inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .md\:dq--inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .md\:dq--inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .md\:dq--inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .md\:dq-top-0 {
    top: 0px;
  }

  .md\:dq-top-1 {
    top: 0.25rem;
  }

  .md\:dq-top-2 {
    top: 0.5rem;
  }

  .md\:dq-top-3 {
    top: 0.75rem;
  }

  .md\:dq-top-4 {
    top: 1rem;
  }

  .md\:dq-top-5 {
    top: 1.25rem;
  }

  .md\:dq-top-6 {
    top: 1.5rem;
  }

  .md\:dq-top-7 {
    top: 1.75rem;
  }

  .md\:dq-top-8 {
    top: 2rem;
  }

  .md\:dq-top-9 {
    top: 2.25rem;
  }

  .md\:dq-top-10 {
    top: 2.5rem;
  }

  .md\:dq-top-11 {
    top: 2.75rem;
  }

  .md\:dq-top-12 {
    top: 3rem;
  }

  .md\:dq-top-14 {
    top: 3.5rem;
  }

  .md\:dq-top-16 {
    top: 4rem;
  }

  .md\:dq-top-20 {
    top: 5rem;
  }

  .md\:dq-top-24 {
    top: 6rem;
  }

  .md\:dq-top-28 {
    top: 7rem;
  }

  .md\:dq-top-32 {
    top: 8rem;
  }

  .md\:dq-top-36 {
    top: 9rem;
  }

  .md\:dq-top-40 {
    top: 10rem;
  }

  .md\:dq-top-44 {
    top: 11rem;
  }

  .md\:dq-top-48 {
    top: 12rem;
  }

  .md\:dq-top-52 {
    top: 13rem;
  }

  .md\:dq-top-56 {
    top: 14rem;
  }

  .md\:dq-top-60 {
    top: 15rem;
  }

  .md\:dq-top-64 {
    top: 16rem;
  }

  .md\:dq-top-72 {
    top: 18rem;
  }

  .md\:dq-top-80 {
    top: 20rem;
  }

  .md\:dq-top-96 {
    top: 24rem;
  }

  .md\:dq-top-auto {
    top: auto;
  }

  .md\:dq-top-px {
    top: 1px;
  }

  .md\:dq-top-0\.5 {
    top: 0.125rem;
  }

  .md\:dq-top-1\.5 {
    top: 0.375rem;
  }

  .md\:dq-top-2\.5 {
    top: 0.625rem;
  }

  .md\:dq-top-3\.5 {
    top: 0.875rem;
  }

  .md\:dq--top-0 {
    top: 0px;
  }

  .md\:dq--top-1 {
    top: -0.25rem;
  }

  .md\:dq--top-2 {
    top: -0.5rem;
  }

  .md\:dq--top-3 {
    top: -0.75rem;
  }

  .md\:dq--top-4 {
    top: -1rem;
  }

  .md\:dq--top-5 {
    top: -1.25rem;
  }

  .md\:dq--top-6 {
    top: -1.5rem;
  }

  .md\:dq--top-7 {
    top: -1.75rem;
  }

  .md\:dq--top-8 {
    top: -2rem;
  }

  .md\:dq--top-9 {
    top: -2.25rem;
  }

  .md\:dq--top-10 {
    top: -2.5rem;
  }

  .md\:dq--top-11 {
    top: -2.75rem;
  }

  .md\:dq--top-12 {
    top: -3rem;
  }

  .md\:dq--top-14 {
    top: -3.5rem;
  }

  .md\:dq--top-16 {
    top: -4rem;
  }

  .md\:dq--top-20 {
    top: -5rem;
  }

  .md\:dq--top-24 {
    top: -6rem;
  }

  .md\:dq--top-28 {
    top: -7rem;
  }

  .md\:dq--top-32 {
    top: -8rem;
  }

  .md\:dq--top-36 {
    top: -9rem;
  }

  .md\:dq--top-40 {
    top: -10rem;
  }

  .md\:dq--top-44 {
    top: -11rem;
  }

  .md\:dq--top-48 {
    top: -12rem;
  }

  .md\:dq--top-52 {
    top: -13rem;
  }

  .md\:dq--top-56 {
    top: -14rem;
  }

  .md\:dq--top-60 {
    top: -15rem;
  }

  .md\:dq--top-64 {
    top: -16rem;
  }

  .md\:dq--top-72 {
    top: -18rem;
  }

  .md\:dq--top-80 {
    top: -20rem;
  }

  .md\:dq--top-96 {
    top: -24rem;
  }

  .md\:dq--top-px {
    top: -1px;
  }

  .md\:dq--top-0\.5 {
    top: -0.125rem;
  }

  .md\:dq--top-1\.5 {
    top: -0.375rem;
  }

  .md\:dq--top-2\.5 {
    top: -0.625rem;
  }

  .md\:dq--top-3\.5 {
    top: -0.875rem;
  }

  .md\:dq-top-1\/2 {
    top: 50%;
  }

  .md\:dq-top-1\/3 {
    top: 33.333333%;
  }

  .md\:dq-top-2\/3 {
    top: 66.666667%;
  }

  .md\:dq-top-1\/4 {
    top: 25%;
  }

  .md\:dq-top-2\/4 {
    top: 50%;
  }

  .md\:dq-top-3\/4 {
    top: 75%;
  }

  .md\:dq-top-full {
    top: 100%;
  }

  .md\:dq--top-1\/2 {
    top: -50%;
  }

  .md\:dq--top-1\/3 {
    top: -33.333333%;
  }

  .md\:dq--top-2\/3 {
    top: -66.666667%;
  }

  .md\:dq--top-1\/4 {
    top: -25%;
  }

  .md\:dq--top-2\/4 {
    top: -50%;
  }

  .md\:dq--top-3\/4 {
    top: -75%;
  }

  .md\:dq--top-full {
    top: -100%;
  }

  .md\:dq-right-0 {
    right: 0px;
  }

  .md\:dq-right-1 {
    right: 0.25rem;
  }

  .md\:dq-right-2 {
    right: 0.5rem;
  }

  .md\:dq-right-3 {
    right: 0.75rem;
  }

  .md\:dq-right-4 {
    right: 1rem;
  }

  .md\:dq-right-5 {
    right: 1.25rem;
  }

  .md\:dq-right-6 {
    right: 1.5rem;
  }

  .md\:dq-right-7 {
    right: 1.75rem;
  }

  .md\:dq-right-8 {
    right: 2rem;
  }

  .md\:dq-right-9 {
    right: 2.25rem;
  }

  .md\:dq-right-10 {
    right: 2.5rem;
  }

  .md\:dq-right-11 {
    right: 2.75rem;
  }

  .md\:dq-right-12 {
    right: 3rem;
  }

  .md\:dq-right-14 {
    right: 3.5rem;
  }

  .md\:dq-right-16 {
    right: 4rem;
  }

  .md\:dq-right-20 {
    right: 5rem;
  }

  .md\:dq-right-24 {
    right: 6rem;
  }

  .md\:dq-right-28 {
    right: 7rem;
  }

  .md\:dq-right-32 {
    right: 8rem;
  }

  .md\:dq-right-36 {
    right: 9rem;
  }

  .md\:dq-right-40 {
    right: 10rem;
  }

  .md\:dq-right-44 {
    right: 11rem;
  }

  .md\:dq-right-48 {
    right: 12rem;
  }

  .md\:dq-right-52 {
    right: 13rem;
  }

  .md\:dq-right-56 {
    right: 14rem;
  }

  .md\:dq-right-60 {
    right: 15rem;
  }

  .md\:dq-right-64 {
    right: 16rem;
  }

  .md\:dq-right-72 {
    right: 18rem;
  }

  .md\:dq-right-80 {
    right: 20rem;
  }

  .md\:dq-right-96 {
    right: 24rem;
  }

  .md\:dq-right-auto {
    right: auto;
  }

  .md\:dq-right-px {
    right: 1px;
  }

  .md\:dq-right-0\.5 {
    right: 0.125rem;
  }

  .md\:dq-right-1\.5 {
    right: 0.375rem;
  }

  .md\:dq-right-2\.5 {
    right: 0.625rem;
  }

  .md\:dq-right-3\.5 {
    right: 0.875rem;
  }

  .md\:dq--right-0 {
    right: 0px;
  }

  .md\:dq--right-1 {
    right: -0.25rem;
  }

  .md\:dq--right-2 {
    right: -0.5rem;
  }

  .md\:dq--right-3 {
    right: -0.75rem;
  }

  .md\:dq--right-4 {
    right: -1rem;
  }

  .md\:dq--right-5 {
    right: -1.25rem;
  }

  .md\:dq--right-6 {
    right: -1.5rem;
  }

  .md\:dq--right-7 {
    right: -1.75rem;
  }

  .md\:dq--right-8 {
    right: -2rem;
  }

  .md\:dq--right-9 {
    right: -2.25rem;
  }

  .md\:dq--right-10 {
    right: -2.5rem;
  }

  .md\:dq--right-11 {
    right: -2.75rem;
  }

  .md\:dq--right-12 {
    right: -3rem;
  }

  .md\:dq--right-14 {
    right: -3.5rem;
  }

  .md\:dq--right-16 {
    right: -4rem;
  }

  .md\:dq--right-20 {
    right: -5rem;
  }

  .md\:dq--right-24 {
    right: -6rem;
  }

  .md\:dq--right-28 {
    right: -7rem;
  }

  .md\:dq--right-32 {
    right: -8rem;
  }

  .md\:dq--right-36 {
    right: -9rem;
  }

  .md\:dq--right-40 {
    right: -10rem;
  }

  .md\:dq--right-44 {
    right: -11rem;
  }

  .md\:dq--right-48 {
    right: -12rem;
  }

  .md\:dq--right-52 {
    right: -13rem;
  }

  .md\:dq--right-56 {
    right: -14rem;
  }

  .md\:dq--right-60 {
    right: -15rem;
  }

  .md\:dq--right-64 {
    right: -16rem;
  }

  .md\:dq--right-72 {
    right: -18rem;
  }

  .md\:dq--right-80 {
    right: -20rem;
  }

  .md\:dq--right-96 {
    right: -24rem;
  }

  .md\:dq--right-px {
    right: -1px;
  }

  .md\:dq--right-0\.5 {
    right: -0.125rem;
  }

  .md\:dq--right-1\.5 {
    right: -0.375rem;
  }

  .md\:dq--right-2\.5 {
    right: -0.625rem;
  }

  .md\:dq--right-3\.5 {
    right: -0.875rem;
  }

  .md\:dq-right-1\/2 {
    right: 50%;
  }

  .md\:dq-right-1\/3 {
    right: 33.333333%;
  }

  .md\:dq-right-2\/3 {
    right: 66.666667%;
  }

  .md\:dq-right-1\/4 {
    right: 25%;
  }

  .md\:dq-right-2\/4 {
    right: 50%;
  }

  .md\:dq-right-3\/4 {
    right: 75%;
  }

  .md\:dq-right-full {
    right: 100%;
  }

  .md\:dq--right-1\/2 {
    right: -50%;
  }

  .md\:dq--right-1\/3 {
    right: -33.333333%;
  }

  .md\:dq--right-2\/3 {
    right: -66.666667%;
  }

  .md\:dq--right-1\/4 {
    right: -25%;
  }

  .md\:dq--right-2\/4 {
    right: -50%;
  }

  .md\:dq--right-3\/4 {
    right: -75%;
  }

  .md\:dq--right-full {
    right: -100%;
  }

  .md\:dq-bottom-0 {
    bottom: 0px;
  }

  .md\:dq-bottom-1 {
    bottom: 0.25rem;
  }

  .md\:dq-bottom-2 {
    bottom: 0.5rem;
  }

  .md\:dq-bottom-3 {
    bottom: 0.75rem;
  }

  .md\:dq-bottom-4 {
    bottom: 1rem;
  }

  .md\:dq-bottom-5 {
    bottom: 1.25rem;
  }

  .md\:dq-bottom-6 {
    bottom: 1.5rem;
  }

  .md\:dq-bottom-7 {
    bottom: 1.75rem;
  }

  .md\:dq-bottom-8 {
    bottom: 2rem;
  }

  .md\:dq-bottom-9 {
    bottom: 2.25rem;
  }

  .md\:dq-bottom-10 {
    bottom: 2.5rem;
  }

  .md\:dq-bottom-11 {
    bottom: 2.75rem;
  }

  .md\:dq-bottom-12 {
    bottom: 3rem;
  }

  .md\:dq-bottom-14 {
    bottom: 3.5rem;
  }

  .md\:dq-bottom-16 {
    bottom: 4rem;
  }

  .md\:dq-bottom-20 {
    bottom: 5rem;
  }

  .md\:dq-bottom-24 {
    bottom: 6rem;
  }

  .md\:dq-bottom-28 {
    bottom: 7rem;
  }

  .md\:dq-bottom-32 {
    bottom: 8rem;
  }

  .md\:dq-bottom-36 {
    bottom: 9rem;
  }

  .md\:dq-bottom-40 {
    bottom: 10rem;
  }

  .md\:dq-bottom-44 {
    bottom: 11rem;
  }

  .md\:dq-bottom-48 {
    bottom: 12rem;
  }

  .md\:dq-bottom-52 {
    bottom: 13rem;
  }

  .md\:dq-bottom-56 {
    bottom: 14rem;
  }

  .md\:dq-bottom-60 {
    bottom: 15rem;
  }

  .md\:dq-bottom-64 {
    bottom: 16rem;
  }

  .md\:dq-bottom-72 {
    bottom: 18rem;
  }

  .md\:dq-bottom-80 {
    bottom: 20rem;
  }

  .md\:dq-bottom-96 {
    bottom: 24rem;
  }

  .md\:dq-bottom-auto {
    bottom: auto;
  }

  .md\:dq-bottom-px {
    bottom: 1px;
  }

  .md\:dq-bottom-0\.5 {
    bottom: 0.125rem;
  }

  .md\:dq-bottom-1\.5 {
    bottom: 0.375rem;
  }

  .md\:dq-bottom-2\.5 {
    bottom: 0.625rem;
  }

  .md\:dq-bottom-3\.5 {
    bottom: 0.875rem;
  }

  .md\:dq--bottom-0 {
    bottom: 0px;
  }

  .md\:dq--bottom-1 {
    bottom: -0.25rem;
  }

  .md\:dq--bottom-2 {
    bottom: -0.5rem;
  }

  .md\:dq--bottom-3 {
    bottom: -0.75rem;
  }

  .md\:dq--bottom-4 {
    bottom: -1rem;
  }

  .md\:dq--bottom-5 {
    bottom: -1.25rem;
  }

  .md\:dq--bottom-6 {
    bottom: -1.5rem;
  }

  .md\:dq--bottom-7 {
    bottom: -1.75rem;
  }

  .md\:dq--bottom-8 {
    bottom: -2rem;
  }

  .md\:dq--bottom-9 {
    bottom: -2.25rem;
  }

  .md\:dq--bottom-10 {
    bottom: -2.5rem;
  }

  .md\:dq--bottom-11 {
    bottom: -2.75rem;
  }

  .md\:dq--bottom-12 {
    bottom: -3rem;
  }

  .md\:dq--bottom-14 {
    bottom: -3.5rem;
  }

  .md\:dq--bottom-16 {
    bottom: -4rem;
  }

  .md\:dq--bottom-20 {
    bottom: -5rem;
  }

  .md\:dq--bottom-24 {
    bottom: -6rem;
  }

  .md\:dq--bottom-28 {
    bottom: -7rem;
  }

  .md\:dq--bottom-32 {
    bottom: -8rem;
  }

  .md\:dq--bottom-36 {
    bottom: -9rem;
  }

  .md\:dq--bottom-40 {
    bottom: -10rem;
  }

  .md\:dq--bottom-44 {
    bottom: -11rem;
  }

  .md\:dq--bottom-48 {
    bottom: -12rem;
  }

  .md\:dq--bottom-52 {
    bottom: -13rem;
  }

  .md\:dq--bottom-56 {
    bottom: -14rem;
  }

  .md\:dq--bottom-60 {
    bottom: -15rem;
  }

  .md\:dq--bottom-64 {
    bottom: -16rem;
  }

  .md\:dq--bottom-72 {
    bottom: -18rem;
  }

  .md\:dq--bottom-80 {
    bottom: -20rem;
  }

  .md\:dq--bottom-96 {
    bottom: -24rem;
  }

  .md\:dq--bottom-px {
    bottom: -1px;
  }

  .md\:dq--bottom-0\.5 {
    bottom: -0.125rem;
  }

  .md\:dq--bottom-1\.5 {
    bottom: -0.375rem;
  }

  .md\:dq--bottom-2\.5 {
    bottom: -0.625rem;
  }

  .md\:dq--bottom-3\.5 {
    bottom: -0.875rem;
  }

  .md\:dq-bottom-1\/2 {
    bottom: 50%;
  }

  .md\:dq-bottom-1\/3 {
    bottom: 33.333333%;
  }

  .md\:dq-bottom-2\/3 {
    bottom: 66.666667%;
  }

  .md\:dq-bottom-1\/4 {
    bottom: 25%;
  }

  .md\:dq-bottom-2\/4 {
    bottom: 50%;
  }

  .md\:dq-bottom-3\/4 {
    bottom: 75%;
  }

  .md\:dq-bottom-full {
    bottom: 100%;
  }

  .md\:dq--bottom-1\/2 {
    bottom: -50%;
  }

  .md\:dq--bottom-1\/3 {
    bottom: -33.333333%;
  }

  .md\:dq--bottom-2\/3 {
    bottom: -66.666667%;
  }

  .md\:dq--bottom-1\/4 {
    bottom: -25%;
  }

  .md\:dq--bottom-2\/4 {
    bottom: -50%;
  }

  .md\:dq--bottom-3\/4 {
    bottom: -75%;
  }

  .md\:dq--bottom-full {
    bottom: -100%;
  }

  .md\:dq-left-0 {
    left: 0px;
  }

  .md\:dq-left-1 {
    left: 0.25rem;
  }

  .md\:dq-left-2 {
    left: 0.5rem;
  }

  .md\:dq-left-3 {
    left: 0.75rem;
  }

  .md\:dq-left-4 {
    left: 1rem;
  }

  .md\:dq-left-5 {
    left: 1.25rem;
  }

  .md\:dq-left-6 {
    left: 1.5rem;
  }

  .md\:dq-left-7 {
    left: 1.75rem;
  }

  .md\:dq-left-8 {
    left: 2rem;
  }

  .md\:dq-left-9 {
    left: 2.25rem;
  }

  .md\:dq-left-10 {
    left: 2.5rem;
  }

  .md\:dq-left-11 {
    left: 2.75rem;
  }

  .md\:dq-left-12 {
    left: 3rem;
  }

  .md\:dq-left-14 {
    left: 3.5rem;
  }

  .md\:dq-left-16 {
    left: 4rem;
  }

  .md\:dq-left-20 {
    left: 5rem;
  }

  .md\:dq-left-24 {
    left: 6rem;
  }

  .md\:dq-left-28 {
    left: 7rem;
  }

  .md\:dq-left-32 {
    left: 8rem;
  }

  .md\:dq-left-36 {
    left: 9rem;
  }

  .md\:dq-left-40 {
    left: 10rem;
  }

  .md\:dq-left-44 {
    left: 11rem;
  }

  .md\:dq-left-48 {
    left: 12rem;
  }

  .md\:dq-left-52 {
    left: 13rem;
  }

  .md\:dq-left-56 {
    left: 14rem;
  }

  .md\:dq-left-60 {
    left: 15rem;
  }

  .md\:dq-left-64 {
    left: 16rem;
  }

  .md\:dq-left-72 {
    left: 18rem;
  }

  .md\:dq-left-80 {
    left: 20rem;
  }

  .md\:dq-left-96 {
    left: 24rem;
  }

  .md\:dq-left-auto {
    left: auto;
  }

  .md\:dq-left-px {
    left: 1px;
  }

  .md\:dq-left-0\.5 {
    left: 0.125rem;
  }

  .md\:dq-left-1\.5 {
    left: 0.375rem;
  }

  .md\:dq-left-2\.5 {
    left: 0.625rem;
  }

  .md\:dq-left-3\.5 {
    left: 0.875rem;
  }

  .md\:dq--left-0 {
    left: 0px;
  }

  .md\:dq--left-1 {
    left: -0.25rem;
  }

  .md\:dq--left-2 {
    left: -0.5rem;
  }

  .md\:dq--left-3 {
    left: -0.75rem;
  }

  .md\:dq--left-4 {
    left: -1rem;
  }

  .md\:dq--left-5 {
    left: -1.25rem;
  }

  .md\:dq--left-6 {
    left: -1.5rem;
  }

  .md\:dq--left-7 {
    left: -1.75rem;
  }

  .md\:dq--left-8 {
    left: -2rem;
  }

  .md\:dq--left-9 {
    left: -2.25rem;
  }

  .md\:dq--left-10 {
    left: -2.5rem;
  }

  .md\:dq--left-11 {
    left: -2.75rem;
  }

  .md\:dq--left-12 {
    left: -3rem;
  }

  .md\:dq--left-14 {
    left: -3.5rem;
  }

  .md\:dq--left-16 {
    left: -4rem;
  }

  .md\:dq--left-20 {
    left: -5rem;
  }

  .md\:dq--left-24 {
    left: -6rem;
  }

  .md\:dq--left-28 {
    left: -7rem;
  }

  .md\:dq--left-32 {
    left: -8rem;
  }

  .md\:dq--left-36 {
    left: -9rem;
  }

  .md\:dq--left-40 {
    left: -10rem;
  }

  .md\:dq--left-44 {
    left: -11rem;
  }

  .md\:dq--left-48 {
    left: -12rem;
  }

  .md\:dq--left-52 {
    left: -13rem;
  }

  .md\:dq--left-56 {
    left: -14rem;
  }

  .md\:dq--left-60 {
    left: -15rem;
  }

  .md\:dq--left-64 {
    left: -16rem;
  }

  .md\:dq--left-72 {
    left: -18rem;
  }

  .md\:dq--left-80 {
    left: -20rem;
  }

  .md\:dq--left-96 {
    left: -24rem;
  }

  .md\:dq--left-px {
    left: -1px;
  }

  .md\:dq--left-0\.5 {
    left: -0.125rem;
  }

  .md\:dq--left-1\.5 {
    left: -0.375rem;
  }

  .md\:dq--left-2\.5 {
    left: -0.625rem;
  }

  .md\:dq--left-3\.5 {
    left: -0.875rem;
  }

  .md\:dq-left-1\/2 {
    left: 50%;
  }

  .md\:dq-left-1\/3 {
    left: 33.333333%;
  }

  .md\:dq-left-2\/3 {
    left: 66.666667%;
  }

  .md\:dq-left-1\/4 {
    left: 25%;
  }

  .md\:dq-left-2\/4 {
    left: 50%;
  }

  .md\:dq-left-3\/4 {
    left: 75%;
  }

  .md\:dq-left-full {
    left: 100%;
  }

  .md\:dq--left-1\/2 {
    left: -50%;
  }

  .md\:dq--left-1\/3 {
    left: -33.333333%;
  }

  .md\:dq--left-2\/3 {
    left: -66.666667%;
  }

  .md\:dq--left-1\/4 {
    left: -25%;
  }

  .md\:dq--left-2\/4 {
    left: -50%;
  }

  .md\:dq--left-3\/4 {
    left: -75%;
  }

  .md\:dq--left-full {
    left: -100%;
  }

  .md\:dq-isolate {
    isolation: isolate;
  }

  .md\:dq-isolation-auto {
    isolation: auto;
  }

  .md\:dq-z-0 {
    z-index: 0;
  }

  .md\:dq-z-10 {
    z-index: 10;
  }

  .md\:dq-z-20 {
    z-index: 20;
  }

  .md\:dq-z-30 {
    z-index: 30;
  }

  .md\:dq-z-40 {
    z-index: 40;
  }

  .md\:dq-z-50 {
    z-index: 50;
  }

  .md\:dq-z-auto {
    z-index: auto;
  }

  .md\:focus-within\:dq-z-0:focus-within {
    z-index: 0;
  }

  .md\:focus-within\:dq-z-10:focus-within {
    z-index: 10;
  }

  .md\:focus-within\:dq-z-20:focus-within {
    z-index: 20;
  }

  .md\:focus-within\:dq-z-30:focus-within {
    z-index: 30;
  }

  .md\:focus-within\:dq-z-40:focus-within {
    z-index: 40;
  }

  .md\:focus-within\:dq-z-50:focus-within {
    z-index: 50;
  }

  .md\:focus-within\:dq-z-auto:focus-within {
    z-index: auto;
  }

  .md\:focus\:dq-z-0:focus {
    z-index: 0;
  }

  .md\:focus\:dq-z-10:focus {
    z-index: 10;
  }

  .md\:focus\:dq-z-20:focus {
    z-index: 20;
  }

  .md\:focus\:dq-z-30:focus {
    z-index: 30;
  }

  .md\:focus\:dq-z-40:focus {
    z-index: 40;
  }

  .md\:focus\:dq-z-50:focus {
    z-index: 50;
  }

  .md\:focus\:dq-z-auto:focus {
    z-index: auto;
  }

  .md\:dq-order-1 {
    order: 1;
  }

  .md\:dq-order-2 {
    order: 2;
  }

  .md\:dq-order-3 {
    order: 3;
  }

  .md\:dq-order-4 {
    order: 4;
  }

  .md\:dq-order-5 {
    order: 5;
  }

  .md\:dq-order-6 {
    order: 6;
  }

  .md\:dq-order-7 {
    order: 7;
  }

  .md\:dq-order-8 {
    order: 8;
  }

  .md\:dq-order-9 {
    order: 9;
  }

  .md\:dq-order-10 {
    order: 10;
  }

  .md\:dq-order-11 {
    order: 11;
  }

  .md\:dq-order-12 {
    order: 12;
  }

  .md\:dq-order-first {
    order: -9999;
  }

  .md\:dq-order-last {
    order: 9999;
  }

  .md\:dq-order-none {
    order: 0;
  }

  .md\:dq-col-auto {
    grid-column: auto;
  }

  .md\:dq-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:dq-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:dq-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:dq-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:dq-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:dq-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:dq-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:dq-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:dq-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:dq-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:dq-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:dq-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:dq-col-span-full {
    grid-column: 1 / -1;
  }

  .md\:dq-col-start-1 {
    grid-column-start: 1;
  }

  .md\:dq-col-start-2 {
    grid-column-start: 2;
  }

  .md\:dq-col-start-3 {
    grid-column-start: 3;
  }

  .md\:dq-col-start-4 {
    grid-column-start: 4;
  }

  .md\:dq-col-start-5 {
    grid-column-start: 5;
  }

  .md\:dq-col-start-6 {
    grid-column-start: 6;
  }

  .md\:dq-col-start-7 {
    grid-column-start: 7;
  }

  .md\:dq-col-start-8 {
    grid-column-start: 8;
  }

  .md\:dq-col-start-9 {
    grid-column-start: 9;
  }

  .md\:dq-col-start-10 {
    grid-column-start: 10;
  }

  .md\:dq-col-start-11 {
    grid-column-start: 11;
  }

  .md\:dq-col-start-12 {
    grid-column-start: 12;
  }

  .md\:dq-col-start-13 {
    grid-column-start: 13;
  }

  .md\:dq-col-start-auto {
    grid-column-start: auto;
  }

  .md\:dq-col-end-1 {
    grid-column-end: 1;
  }

  .md\:dq-col-end-2 {
    grid-column-end: 2;
  }

  .md\:dq-col-end-3 {
    grid-column-end: 3;
  }

  .md\:dq-col-end-4 {
    grid-column-end: 4;
  }

  .md\:dq-col-end-5 {
    grid-column-end: 5;
  }

  .md\:dq-col-end-6 {
    grid-column-end: 6;
  }

  .md\:dq-col-end-7 {
    grid-column-end: 7;
  }

  .md\:dq-col-end-8 {
    grid-column-end: 8;
  }

  .md\:dq-col-end-9 {
    grid-column-end: 9;
  }

  .md\:dq-col-end-10 {
    grid-column-end: 10;
  }

  .md\:dq-col-end-11 {
    grid-column-end: 11;
  }

  .md\:dq-col-end-12 {
    grid-column-end: 12;
  }

  .md\:dq-col-end-13 {
    grid-column-end: 13;
  }

  .md\:dq-col-end-auto {
    grid-column-end: auto;
  }

  .md\:dq-row-auto {
    grid-row: auto;
  }

  .md\:dq-row-span-1 {
    grid-row: span 1 / span 1;
  }

  .md\:dq-row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:dq-row-span-3 {
    grid-row: span 3 / span 3;
  }

  .md\:dq-row-span-4 {
    grid-row: span 4 / span 4;
  }

  .md\:dq-row-span-5 {
    grid-row: span 5 / span 5;
  }

  .md\:dq-row-span-6 {
    grid-row: span 6 / span 6;
  }

  .md\:dq-row-span-full {
    grid-row: 1 / -1;
  }

  .md\:dq-row-start-1 {
    grid-row-start: 1;
  }

  .md\:dq-row-start-2 {
    grid-row-start: 2;
  }

  .md\:dq-row-start-3 {
    grid-row-start: 3;
  }

  .md\:dq-row-start-4 {
    grid-row-start: 4;
  }

  .md\:dq-row-start-5 {
    grid-row-start: 5;
  }

  .md\:dq-row-start-6 {
    grid-row-start: 6;
  }

  .md\:dq-row-start-7 {
    grid-row-start: 7;
  }

  .md\:dq-row-start-auto {
    grid-row-start: auto;
  }

  .md\:dq-row-end-1 {
    grid-row-end: 1;
  }

  .md\:dq-row-end-2 {
    grid-row-end: 2;
  }

  .md\:dq-row-end-3 {
    grid-row-end: 3;
  }

  .md\:dq-row-end-4 {
    grid-row-end: 4;
  }

  .md\:dq-row-end-5 {
    grid-row-end: 5;
  }

  .md\:dq-row-end-6 {
    grid-row-end: 6;
  }

  .md\:dq-row-end-7 {
    grid-row-end: 7;
  }

  .md\:dq-row-end-auto {
    grid-row-end: auto;
  }

  .md\:dq-float-right {
    float: right;
  }

  .md\:dq-float-left {
    float: left;
  }

  .md\:dq-float-none {
    float: none;
  }

  .md\:dq-clear-left {
    clear: left;
  }

  .md\:dq-clear-right {
    clear: right;
  }

  .md\:dq-clear-both {
    clear: both;
  }

  .md\:dq-clear-none {
    clear: none;
  }

  .md\:dq-m-0 {
    margin: 0px;
  }

  .md\:dq-m-1 {
    margin: 0.25rem;
  }

  .md\:dq-m-2 {
    margin: 0.5rem;
  }

  .md\:dq-m-3 {
    margin: 0.75rem;
  }

  .md\:dq-m-4 {
    margin: 1rem;
  }

  .md\:dq-m-5 {
    margin: 1.25rem;
  }

  .md\:dq-m-6 {
    margin: 1.5rem;
  }

  .md\:dq-m-7 {
    margin: 1.75rem;
  }

  .md\:dq-m-8 {
    margin: 2rem;
  }

  .md\:dq-m-9 {
    margin: 2.25rem;
  }

  .md\:dq-m-10 {
    margin: 2.5rem;
  }

  .md\:dq-m-11 {
    margin: 2.75rem;
  }

  .md\:dq-m-12 {
    margin: 3rem;
  }

  .md\:dq-m-14 {
    margin: 3.5rem;
  }

  .md\:dq-m-16 {
    margin: 4rem;
  }

  .md\:dq-m-20 {
    margin: 5rem;
  }

  .md\:dq-m-24 {
    margin: 6rem;
  }

  .md\:dq-m-28 {
    margin: 7rem;
  }

  .md\:dq-m-32 {
    margin: 8rem;
  }

  .md\:dq-m-36 {
    margin: 9rem;
  }

  .md\:dq-m-40 {
    margin: 10rem;
  }

  .md\:dq-m-44 {
    margin: 11rem;
  }

  .md\:dq-m-48 {
    margin: 12rem;
  }

  .md\:dq-m-52 {
    margin: 13rem;
  }

  .md\:dq-m-56 {
    margin: 14rem;
  }

  .md\:dq-m-60 {
    margin: 15rem;
  }

  .md\:dq-m-64 {
    margin: 16rem;
  }

  .md\:dq-m-72 {
    margin: 18rem;
  }

  .md\:dq-m-80 {
    margin: 20rem;
  }

  .md\:dq-m-96 {
    margin: 24rem;
  }

  .md\:dq-m-auto {
    margin: auto;
  }

  .md\:dq-m-px {
    margin: 1px;
  }

  .md\:dq-m-0\.5 {
    margin: 0.125rem;
  }

  .md\:dq-m-1\.5 {
    margin: 0.375rem;
  }

  .md\:dq-m-2\.5 {
    margin: 0.625rem;
  }

  .md\:dq-m-3\.5 {
    margin: 0.875rem;
  }

  .md\:dq--m-0 {
    margin: 0px;
  }

  .md\:dq--m-1 {
    margin: -0.25rem;
  }

  .md\:dq--m-2 {
    margin: -0.5rem;
  }

  .md\:dq--m-3 {
    margin: -0.75rem;
  }

  .md\:dq--m-4 {
    margin: -1rem;
  }

  .md\:dq--m-5 {
    margin: -1.25rem;
  }

  .md\:dq--m-6 {
    margin: -1.5rem;
  }

  .md\:dq--m-7 {
    margin: -1.75rem;
  }

  .md\:dq--m-8 {
    margin: -2rem;
  }

  .md\:dq--m-9 {
    margin: -2.25rem;
  }

  .md\:dq--m-10 {
    margin: -2.5rem;
  }

  .md\:dq--m-11 {
    margin: -2.75rem;
  }

  .md\:dq--m-12 {
    margin: -3rem;
  }

  .md\:dq--m-14 {
    margin: -3.5rem;
  }

  .md\:dq--m-16 {
    margin: -4rem;
  }

  .md\:dq--m-20 {
    margin: -5rem;
  }

  .md\:dq--m-24 {
    margin: -6rem;
  }

  .md\:dq--m-28 {
    margin: -7rem;
  }

  .md\:dq--m-32 {
    margin: -8rem;
  }

  .md\:dq--m-36 {
    margin: -9rem;
  }

  .md\:dq--m-40 {
    margin: -10rem;
  }

  .md\:dq--m-44 {
    margin: -11rem;
  }

  .md\:dq--m-48 {
    margin: -12rem;
  }

  .md\:dq--m-52 {
    margin: -13rem;
  }

  .md\:dq--m-56 {
    margin: -14rem;
  }

  .md\:dq--m-60 {
    margin: -15rem;
  }

  .md\:dq--m-64 {
    margin: -16rem;
  }

  .md\:dq--m-72 {
    margin: -18rem;
  }

  .md\:dq--m-80 {
    margin: -20rem;
  }

  .md\:dq--m-96 {
    margin: -24rem;
  }

  .md\:dq--m-px {
    margin: -1px;
  }

  .md\:dq--m-0\.5 {
    margin: -0.125rem;
  }

  .md\:dq--m-1\.5 {
    margin: -0.375rem;
  }

  .md\:dq--m-2\.5 {
    margin: -0.625rem;
  }

  .md\:dq--m-3\.5 {
    margin: -0.875rem;
  }

  .md\:dq-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md\:dq-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .md\:dq-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .md\:dq-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .md\:dq-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:dq-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:dq-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:dq-mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .md\:dq-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:dq-mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .md\:dq-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md\:dq-mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .md\:dq-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:dq-mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .md\:dq-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:dq-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .md\:dq-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .md\:dq-mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .md\:dq-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .md\:dq-mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .md\:dq-mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .md\:dq-mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .md\:dq-mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .md\:dq-mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .md\:dq-mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .md\:dq-mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .md\:dq-mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .md\:dq-mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .md\:dq-mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .md\:dq-mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .md\:dq-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:dq-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .md\:dq-mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }

  .md\:dq-mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }

  .md\:dq-mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .md\:dq-mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }

  .md\:dq--mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md\:dq--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .md\:dq--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .md\:dq--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .md\:dq--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .md\:dq--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .md\:dq--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .md\:dq--mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .md\:dq--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md\:dq--mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .md\:dq--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .md\:dq--mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .md\:dq--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .md\:dq--mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .md\:dq--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .md\:dq--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .md\:dq--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .md\:dq--mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .md\:dq--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .md\:dq--mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .md\:dq--mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .md\:dq--mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .md\:dq--mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .md\:dq--mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .md\:dq--mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .md\:dq--mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .md\:dq--mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .md\:dq--mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .md\:dq--mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .md\:dq--mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .md\:dq--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .md\:dq--mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }

  .md\:dq--mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }

  .md\:dq--mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }

  .md\:dq--mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }

  .md\:dq-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .md\:dq-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .md\:dq-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .md\:dq-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .md\:dq-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:dq-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md\:dq-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:dq-my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .md\:dq-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:dq-my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .md\:dq-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:dq-my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .md\:dq-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .md\:dq-my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .md\:dq-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md\:dq-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:dq-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md\:dq-my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .md\:dq-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .md\:dq-my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .md\:dq-my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .md\:dq-my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .md\:dq-my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .md\:dq-my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .md\:dq-my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .md\:dq-my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .md\:dq-my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .md\:dq-my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .md\:dq-my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .md\:dq-my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .md\:dq-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .md\:dq-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .md\:dq-my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }

  .md\:dq-my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }

  .md\:dq-my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .md\:dq-my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }

  .md\:dq--my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .md\:dq--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .md\:dq--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .md\:dq--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .md\:dq--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .md\:dq--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .md\:dq--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .md\:dq--my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .md\:dq--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .md\:dq--my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .md\:dq--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .md\:dq--my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .md\:dq--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .md\:dq--my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .md\:dq--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .md\:dq--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .md\:dq--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .md\:dq--my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .md\:dq--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .md\:dq--my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .md\:dq--my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .md\:dq--my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .md\:dq--my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .md\:dq--my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .md\:dq--my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .md\:dq--my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .md\:dq--my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .md\:dq--my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .md\:dq--my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .md\:dq--my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .md\:dq--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .md\:dq--my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }

  .md\:dq--my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }

  .md\:dq--my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }

  .md\:dq--my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }

  .md\:dq-mt-0 {
    margin-top: 0px;
  }

  .md\:dq-mt-1 {
    margin-top: 0.25rem;
  }

  .md\:dq-mt-2 {
    margin-top: 0.5rem;
  }

  .md\:dq-mt-3 {
    margin-top: 0.75rem;
  }

  .md\:dq-mt-4 {
    margin-top: 1rem;
  }

  .md\:dq-mt-5 {
    margin-top: 1.25rem;
  }

  .md\:dq-mt-6 {
    margin-top: 1.5rem;
  }

  .md\:dq-mt-7 {
    margin-top: 1.75rem;
  }

  .md\:dq-mt-8 {
    margin-top: 2rem;
  }

  .md\:dq-mt-9 {
    margin-top: 2.25rem;
  }

  .md\:dq-mt-10 {
    margin-top: 2.5rem;
  }

  .md\:dq-mt-11 {
    margin-top: 2.75rem;
  }

  .md\:dq-mt-12 {
    margin-top: 3rem;
  }

  .md\:dq-mt-14 {
    margin-top: 3.5rem;
  }

  .md\:dq-mt-16 {
    margin-top: 4rem;
  }

  .md\:dq-mt-20 {
    margin-top: 5rem;
  }

  .md\:dq-mt-24 {
    margin-top: 6rem;
  }

  .md\:dq-mt-28 {
    margin-top: 7rem;
  }

  .md\:dq-mt-32 {
    margin-top: 8rem;
  }

  .md\:dq-mt-36 {
    margin-top: 9rem;
  }

  .md\:dq-mt-40 {
    margin-top: 10rem;
  }

  .md\:dq-mt-44 {
    margin-top: 11rem;
  }

  .md\:dq-mt-48 {
    margin-top: 12rem;
  }

  .md\:dq-mt-52 {
    margin-top: 13rem;
  }

  .md\:dq-mt-56 {
    margin-top: 14rem;
  }

  .md\:dq-mt-60 {
    margin-top: 15rem;
  }

  .md\:dq-mt-64 {
    margin-top: 16rem;
  }

  .md\:dq-mt-72 {
    margin-top: 18rem;
  }

  .md\:dq-mt-80 {
    margin-top: 20rem;
  }

  .md\:dq-mt-96 {
    margin-top: 24rem;
  }

  .md\:dq-mt-auto {
    margin-top: auto;
  }

  .md\:dq-mt-px {
    margin-top: 1px;
  }

  .md\:dq-mt-0\.5 {
    margin-top: 0.125rem;
  }

  .md\:dq-mt-1\.5 {
    margin-top: 0.375rem;
  }

  .md\:dq-mt-2\.5 {
    margin-top: 0.625rem;
  }

  .md\:dq-mt-3\.5 {
    margin-top: 0.875rem;
  }

  .md\:dq--mt-0 {
    margin-top: 0px;
  }

  .md\:dq--mt-1 {
    margin-top: -0.25rem;
  }

  .md\:dq--mt-2 {
    margin-top: -0.5rem;
  }

  .md\:dq--mt-3 {
    margin-top: -0.75rem;
  }

  .md\:dq--mt-4 {
    margin-top: -1rem;
  }

  .md\:dq--mt-5 {
    margin-top: -1.25rem;
  }

  .md\:dq--mt-6 {
    margin-top: -1.5rem;
  }

  .md\:dq--mt-7 {
    margin-top: -1.75rem;
  }

  .md\:dq--mt-8 {
    margin-top: -2rem;
  }

  .md\:dq--mt-9 {
    margin-top: -2.25rem;
  }

  .md\:dq--mt-10 {
    margin-top: -2.5rem;
  }

  .md\:dq--mt-11 {
    margin-top: -2.75rem;
  }

  .md\:dq--mt-12 {
    margin-top: -3rem;
  }

  .md\:dq--mt-14 {
    margin-top: -3.5rem;
  }

  .md\:dq--mt-16 {
    margin-top: -4rem;
  }

  .md\:dq--mt-20 {
    margin-top: -5rem;
  }

  .md\:dq--mt-24 {
    margin-top: -6rem;
  }

  .md\:dq--mt-28 {
    margin-top: -7rem;
  }

  .md\:dq--mt-32 {
    margin-top: -8rem;
  }

  .md\:dq--mt-36 {
    margin-top: -9rem;
  }

  .md\:dq--mt-40 {
    margin-top: -10rem;
  }

  .md\:dq--mt-44 {
    margin-top: -11rem;
  }

  .md\:dq--mt-48 {
    margin-top: -12rem;
  }

  .md\:dq--mt-52 {
    margin-top: -13rem;
  }

  .md\:dq--mt-56 {
    margin-top: -14rem;
  }

  .md\:dq--mt-60 {
    margin-top: -15rem;
  }

  .md\:dq--mt-64 {
    margin-top: -16rem;
  }

  .md\:dq--mt-72 {
    margin-top: -18rem;
  }

  .md\:dq--mt-80 {
    margin-top: -20rem;
  }

  .md\:dq--mt-96 {
    margin-top: -24rem;
  }

  .md\:dq--mt-px {
    margin-top: -1px;
  }

  .md\:dq--mt-0\.5 {
    margin-top: -0.125rem;
  }

  .md\:dq--mt-1\.5 {
    margin-top: -0.375rem;
  }

  .md\:dq--mt-2\.5 {
    margin-top: -0.625rem;
  }

  .md\:dq--mt-3\.5 {
    margin-top: -0.875rem;
  }

  .md\:dq-mr-0 {
    margin-right: 0px;
  }

  .md\:dq-mr-1 {
    margin-right: 0.25rem;
  }

  .md\:dq-mr-2 {
    margin-right: 0.5rem;
  }

  .md\:dq-mr-3 {
    margin-right: 0.75rem;
  }

  .md\:dq-mr-4 {
    margin-right: 1rem;
  }

  .md\:dq-mr-5 {
    margin-right: 1.25rem;
  }

  .md\:dq-mr-6 {
    margin-right: 1.5rem;
  }

  .md\:dq-mr-7 {
    margin-right: 1.75rem;
  }

  .md\:dq-mr-8 {
    margin-right: 2rem;
  }

  .md\:dq-mr-9 {
    margin-right: 2.25rem;
  }

  .md\:dq-mr-10 {
    margin-right: 2.5rem;
  }

  .md\:dq-mr-11 {
    margin-right: 2.75rem;
  }

  .md\:dq-mr-12 {
    margin-right: 3rem;
  }

  .md\:dq-mr-14 {
    margin-right: 3.5rem;
  }

  .md\:dq-mr-16 {
    margin-right: 4rem;
  }

  .md\:dq-mr-20 {
    margin-right: 5rem;
  }

  .md\:dq-mr-24 {
    margin-right: 6rem;
  }

  .md\:dq-mr-28 {
    margin-right: 7rem;
  }

  .md\:dq-mr-32 {
    margin-right: 8rem;
  }

  .md\:dq-mr-36 {
    margin-right: 9rem;
  }

  .md\:dq-mr-40 {
    margin-right: 10rem;
  }

  .md\:dq-mr-44 {
    margin-right: 11rem;
  }

  .md\:dq-mr-48 {
    margin-right: 12rem;
  }

  .md\:dq-mr-52 {
    margin-right: 13rem;
  }

  .md\:dq-mr-56 {
    margin-right: 14rem;
  }

  .md\:dq-mr-60 {
    margin-right: 15rem;
  }

  .md\:dq-mr-64 {
    margin-right: 16rem;
  }

  .md\:dq-mr-72 {
    margin-right: 18rem;
  }

  .md\:dq-mr-80 {
    margin-right: 20rem;
  }

  .md\:dq-mr-96 {
    margin-right: 24rem;
  }

  .md\:dq-mr-auto {
    margin-right: auto;
  }

  .md\:dq-mr-px {
    margin-right: 1px;
  }

  .md\:dq-mr-0\.5 {
    margin-right: 0.125rem;
  }

  .md\:dq-mr-1\.5 {
    margin-right: 0.375rem;
  }

  .md\:dq-mr-2\.5 {
    margin-right: 0.625rem;
  }

  .md\:dq-mr-3\.5 {
    margin-right: 0.875rem;
  }

  .md\:dq--mr-0 {
    margin-right: 0px;
  }

  .md\:dq--mr-1 {
    margin-right: -0.25rem;
  }

  .md\:dq--mr-2 {
    margin-right: -0.5rem;
  }

  .md\:dq--mr-3 {
    margin-right: -0.75rem;
  }

  .md\:dq--mr-4 {
    margin-right: -1rem;
  }

  .md\:dq--mr-5 {
    margin-right: -1.25rem;
  }

  .md\:dq--mr-6 {
    margin-right: -1.5rem;
  }

  .md\:dq--mr-7 {
    margin-right: -1.75rem;
  }

  .md\:dq--mr-8 {
    margin-right: -2rem;
  }

  .md\:dq--mr-9 {
    margin-right: -2.25rem;
  }

  .md\:dq--mr-10 {
    margin-right: -2.5rem;
  }

  .md\:dq--mr-11 {
    margin-right: -2.75rem;
  }

  .md\:dq--mr-12 {
    margin-right: -3rem;
  }

  .md\:dq--mr-14 {
    margin-right: -3.5rem;
  }

  .md\:dq--mr-16 {
    margin-right: -4rem;
  }

  .md\:dq--mr-20 {
    margin-right: -5rem;
  }

  .md\:dq--mr-24 {
    margin-right: -6rem;
  }

  .md\:dq--mr-28 {
    margin-right: -7rem;
  }

  .md\:dq--mr-32 {
    margin-right: -8rem;
  }

  .md\:dq--mr-36 {
    margin-right: -9rem;
  }

  .md\:dq--mr-40 {
    margin-right: -10rem;
  }

  .md\:dq--mr-44 {
    margin-right: -11rem;
  }

  .md\:dq--mr-48 {
    margin-right: -12rem;
  }

  .md\:dq--mr-52 {
    margin-right: -13rem;
  }

  .md\:dq--mr-56 {
    margin-right: -14rem;
  }

  .md\:dq--mr-60 {
    margin-right: -15rem;
  }

  .md\:dq--mr-64 {
    margin-right: -16rem;
  }

  .md\:dq--mr-72 {
    margin-right: -18rem;
  }

  .md\:dq--mr-80 {
    margin-right: -20rem;
  }

  .md\:dq--mr-96 {
    margin-right: -24rem;
  }

  .md\:dq--mr-px {
    margin-right: -1px;
  }

  .md\:dq--mr-0\.5 {
    margin-right: -0.125rem;
  }

  .md\:dq--mr-1\.5 {
    margin-right: -0.375rem;
  }

  .md\:dq--mr-2\.5 {
    margin-right: -0.625rem;
  }

  .md\:dq--mr-3\.5 {
    margin-right: -0.875rem;
  }

  .md\:dq-mb-0 {
    margin-bottom: 0px;
  }

  .md\:dq-mb-1 {
    margin-bottom: 0.25rem;
  }

  .md\:dq-mb-2 {
    margin-bottom: 0.5rem;
  }

  .md\:dq-mb-3 {
    margin-bottom: 0.75rem;
  }

  .md\:dq-mb-4 {
    margin-bottom: 1rem;
  }

  .md\:dq-mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:dq-mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:dq-mb-7 {
    margin-bottom: 1.75rem;
  }

  .md\:dq-mb-8 {
    margin-bottom: 2rem;
  }

  .md\:dq-mb-9 {
    margin-bottom: 2.25rem;
  }

  .md\:dq-mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:dq-mb-11 {
    margin-bottom: 2.75rem;
  }

  .md\:dq-mb-12 {
    margin-bottom: 3rem;
  }

  .md\:dq-mb-14 {
    margin-bottom: 3.5rem;
  }

  .md\:dq-mb-16 {
    margin-bottom: 4rem;
  }

  .md\:dq-mb-20 {
    margin-bottom: 5rem;
  }

  .md\:dq-mb-24 {
    margin-bottom: 6rem;
  }

  .md\:dq-mb-28 {
    margin-bottom: 7rem;
  }

  .md\:dq-mb-32 {
    margin-bottom: 8rem;
  }

  .md\:dq-mb-36 {
    margin-bottom: 9rem;
  }

  .md\:dq-mb-40 {
    margin-bottom: 10rem;
  }

  .md\:dq-mb-44 {
    margin-bottom: 11rem;
  }

  .md\:dq-mb-48 {
    margin-bottom: 12rem;
  }

  .md\:dq-mb-52 {
    margin-bottom: 13rem;
  }

  .md\:dq-mb-56 {
    margin-bottom: 14rem;
  }

  .md\:dq-mb-60 {
    margin-bottom: 15rem;
  }

  .md\:dq-mb-64 {
    margin-bottom: 16rem;
  }

  .md\:dq-mb-72 {
    margin-bottom: 18rem;
  }

  .md\:dq-mb-80 {
    margin-bottom: 20rem;
  }

  .md\:dq-mb-96 {
    margin-bottom: 24rem;
  }

  .md\:dq-mb-auto {
    margin-bottom: auto;
  }

  .md\:dq-mb-px {
    margin-bottom: 1px;
  }

  .md\:dq-mb-0\.5 {
    margin-bottom: 0.125rem;
  }

  .md\:dq-mb-1\.5 {
    margin-bottom: 0.375rem;
  }

  .md\:dq-mb-2\.5 {
    margin-bottom: 0.625rem;
  }

  .md\:dq-mb-3\.5 {
    margin-bottom: 0.875rem;
  }

  .md\:dq--mb-0 {
    margin-bottom: 0px;
  }

  .md\:dq--mb-1 {
    margin-bottom: -0.25rem;
  }

  .md\:dq--mb-2 {
    margin-bottom: -0.5rem;
  }

  .md\:dq--mb-3 {
    margin-bottom: -0.75rem;
  }

  .md\:dq--mb-4 {
    margin-bottom: -1rem;
  }

  .md\:dq--mb-5 {
    margin-bottom: -1.25rem;
  }

  .md\:dq--mb-6 {
    margin-bottom: -1.5rem;
  }

  .md\:dq--mb-7 {
    margin-bottom: -1.75rem;
  }

  .md\:dq--mb-8 {
    margin-bottom: -2rem;
  }

  .md\:dq--mb-9 {
    margin-bottom: -2.25rem;
  }

  .md\:dq--mb-10 {
    margin-bottom: -2.5rem;
  }

  .md\:dq--mb-11 {
    margin-bottom: -2.75rem;
  }

  .md\:dq--mb-12 {
    margin-bottom: -3rem;
  }

  .md\:dq--mb-14 {
    margin-bottom: -3.5rem;
  }

  .md\:dq--mb-16 {
    margin-bottom: -4rem;
  }

  .md\:dq--mb-20 {
    margin-bottom: -5rem;
  }

  .md\:dq--mb-24 {
    margin-bottom: -6rem;
  }

  .md\:dq--mb-28 {
    margin-bottom: -7rem;
  }

  .md\:dq--mb-32 {
    margin-bottom: -8rem;
  }

  .md\:dq--mb-36 {
    margin-bottom: -9rem;
  }

  .md\:dq--mb-40 {
    margin-bottom: -10rem;
  }

  .md\:dq--mb-44 {
    margin-bottom: -11rem;
  }

  .md\:dq--mb-48 {
    margin-bottom: -12rem;
  }

  .md\:dq--mb-52 {
    margin-bottom: -13rem;
  }

  .md\:dq--mb-56 {
    margin-bottom: -14rem;
  }

  .md\:dq--mb-60 {
    margin-bottom: -15rem;
  }

  .md\:dq--mb-64 {
    margin-bottom: -16rem;
  }

  .md\:dq--mb-72 {
    margin-bottom: -18rem;
  }

  .md\:dq--mb-80 {
    margin-bottom: -20rem;
  }

  .md\:dq--mb-96 {
    margin-bottom: -24rem;
  }

  .md\:dq--mb-px {
    margin-bottom: -1px;
  }

  .md\:dq--mb-0\.5 {
    margin-bottom: -0.125rem;
  }

  .md\:dq--mb-1\.5 {
    margin-bottom: -0.375rem;
  }

  .md\:dq--mb-2\.5 {
    margin-bottom: -0.625rem;
  }

  .md\:dq--mb-3\.5 {
    margin-bottom: -0.875rem;
  }

  .md\:dq-ml-0 {
    margin-left: 0px;
  }

  .md\:dq-ml-1 {
    margin-left: 0.25rem;
  }

  .md\:dq-ml-2 {
    margin-left: 0.5rem;
  }

  .md\:dq-ml-3 {
    margin-left: 0.75rem;
  }

  .md\:dq-ml-4 {
    margin-left: 1rem;
  }

  .md\:dq-ml-5 {
    margin-left: 1.25rem;
  }

  .md\:dq-ml-6 {
    margin-left: 1.5rem;
  }

  .md\:dq-ml-7 {
    margin-left: 1.75rem;
  }

  .md\:dq-ml-8 {
    margin-left: 2rem;
  }

  .md\:dq-ml-9 {
    margin-left: 2.25rem;
  }

  .md\:dq-ml-10 {
    margin-left: 2.5rem;
  }

  .md\:dq-ml-11 {
    margin-left: 2.75rem;
  }

  .md\:dq-ml-12 {
    margin-left: 3rem;
  }

  .md\:dq-ml-14 {
    margin-left: 3.5rem;
  }

  .md\:dq-ml-16 {
    margin-left: 4rem;
  }

  .md\:dq-ml-20 {
    margin-left: 5rem;
  }

  .md\:dq-ml-24 {
    margin-left: 6rem;
  }

  .md\:dq-ml-28 {
    margin-left: 7rem;
  }

  .md\:dq-ml-32 {
    margin-left: 8rem;
  }

  .md\:dq-ml-36 {
    margin-left: 9rem;
  }

  .md\:dq-ml-40 {
    margin-left: 10rem;
  }

  .md\:dq-ml-44 {
    margin-left: 11rem;
  }

  .md\:dq-ml-48 {
    margin-left: 12rem;
  }

  .md\:dq-ml-52 {
    margin-left: 13rem;
  }

  .md\:dq-ml-56 {
    margin-left: 14rem;
  }

  .md\:dq-ml-60 {
    margin-left: 15rem;
  }

  .md\:dq-ml-64 {
    margin-left: 16rem;
  }

  .md\:dq-ml-72 {
    margin-left: 18rem;
  }

  .md\:dq-ml-80 {
    margin-left: 20rem;
  }

  .md\:dq-ml-96 {
    margin-left: 24rem;
  }

  .md\:dq-ml-auto {
    margin-left: auto;
  }

  .md\:dq-ml-px {
    margin-left: 1px;
  }

  .md\:dq-ml-0\.5 {
    margin-left: 0.125rem;
  }

  .md\:dq-ml-1\.5 {
    margin-left: 0.375rem;
  }

  .md\:dq-ml-2\.5 {
    margin-left: 0.625rem;
  }

  .md\:dq-ml-3\.5 {
    margin-left: 0.875rem;
  }

  .md\:dq--ml-0 {
    margin-left: 0px;
  }

  .md\:dq--ml-1 {
    margin-left: -0.25rem;
  }

  .md\:dq--ml-2 {
    margin-left: -0.5rem;
  }

  .md\:dq--ml-3 {
    margin-left: -0.75rem;
  }

  .md\:dq--ml-4 {
    margin-left: -1rem;
  }

  .md\:dq--ml-5 {
    margin-left: -1.25rem;
  }

  .md\:dq--ml-6 {
    margin-left: -1.5rem;
  }

  .md\:dq--ml-7 {
    margin-left: -1.75rem;
  }

  .md\:dq--ml-8 {
    margin-left: -2rem;
  }

  .md\:dq--ml-9 {
    margin-left: -2.25rem;
  }

  .md\:dq--ml-10 {
    margin-left: -2.5rem;
  }

  .md\:dq--ml-11 {
    margin-left: -2.75rem;
  }

  .md\:dq--ml-12 {
    margin-left: -3rem;
  }

  .md\:dq--ml-14 {
    margin-left: -3.5rem;
  }

  .md\:dq--ml-16 {
    margin-left: -4rem;
  }

  .md\:dq--ml-20 {
    margin-left: -5rem;
  }

  .md\:dq--ml-24 {
    margin-left: -6rem;
  }

  .md\:dq--ml-28 {
    margin-left: -7rem;
  }

  .md\:dq--ml-32 {
    margin-left: -8rem;
  }

  .md\:dq--ml-36 {
    margin-left: -9rem;
  }

  .md\:dq--ml-40 {
    margin-left: -10rem;
  }

  .md\:dq--ml-44 {
    margin-left: -11rem;
  }

  .md\:dq--ml-48 {
    margin-left: -12rem;
  }

  .md\:dq--ml-52 {
    margin-left: -13rem;
  }

  .md\:dq--ml-56 {
    margin-left: -14rem;
  }

  .md\:dq--ml-60 {
    margin-left: -15rem;
  }

  .md\:dq--ml-64 {
    margin-left: -16rem;
  }

  .md\:dq--ml-72 {
    margin-left: -18rem;
  }

  .md\:dq--ml-80 {
    margin-left: -20rem;
  }

  .md\:dq--ml-96 {
    margin-left: -24rem;
  }

  .md\:dq--ml-px {
    margin-left: -1px;
  }

  .md\:dq--ml-0\.5 {
    margin-left: -0.125rem;
  }

  .md\:dq--ml-1\.5 {
    margin-left: -0.375rem;
  }

  .md\:dq--ml-2\.5 {
    margin-left: -0.625rem;
  }

  .md\:dq--ml-3\.5 {
    margin-left: -0.875rem;
  }

  .md\:dq-box-border {
    box-sizing: border-box;
  }

  .md\:dq-box-content {
    box-sizing: content-box;
  }

  .md\:dq-block {
    display: block;
  }

  .md\:dq-inline-block {
    display: inline-block;
  }

  .md\:dq-inline {
    display: inline;
  }

  .md\:dq-flex {
    display: flex;
  }

  .md\:dq-inline-flex {
    display: inline-flex;
  }

  .md\:dq-table {
    display: table;
  }

  .md\:dq-inline-table {
    display: inline-table;
  }

  .md\:dq-table-caption {
    display: table-caption;
  }

  .md\:dq-table-cell {
    display: table-cell;
  }

  .md\:dq-table-column {
    display: table-column;
  }

  .md\:dq-table-column-group {
    display: table-column-group;
  }

  .md\:dq-table-footer-group {
    display: table-footer-group;
  }

  .md\:dq-table-header-group {
    display: table-header-group;
  }

  .md\:dq-table-row-group {
    display: table-row-group;
  }

  .md\:dq-table-row {
    display: table-row;
  }

  .md\:dq-flow-root {
    display: flow-root;
  }

  .md\:dq-grid {
    display: grid;
  }

  .md\:dq-inline-grid {
    display: inline-grid;
  }

  .md\:dq-contents {
    display: contents;
  }

  .md\:dq-list-item {
    display: list-item;
  }

  .md\:dq-hidden {
    display: none;
  }

  .md\:dq-h-0 {
    height: 0px;
  }

  .md\:dq-h-1 {
    height: 0.25rem;
  }

  .md\:dq-h-2 {
    height: 0.5rem;
  }

  .md\:dq-h-3 {
    height: 0.75rem;
  }

  .md\:dq-h-4 {
    height: 1rem;
  }

  .md\:dq-h-5 {
    height: 1.25rem;
  }

  .md\:dq-h-6 {
    height: 1.5rem;
  }

  .md\:dq-h-7 {
    height: 1.75rem;
  }

  .md\:dq-h-8 {
    height: 2rem;
  }

  .md\:dq-h-9 {
    height: 2.25rem;
  }

  .md\:dq-h-10 {
    height: 2.5rem;
  }

  .md\:dq-h-11 {
    height: 2.75rem;
  }

  .md\:dq-h-12 {
    height: 3rem;
  }

  .md\:dq-h-14 {
    height: 3.5rem;
  }

  .md\:dq-h-16 {
    height: 4rem;
  }

  .md\:dq-h-20 {
    height: 5rem;
  }

  .md\:dq-h-24 {
    height: 6rem;
  }

  .md\:dq-h-28 {
    height: 7rem;
  }

  .md\:dq-h-32 {
    height: 8rem;
  }

  .md\:dq-h-36 {
    height: 9rem;
  }

  .md\:dq-h-40 {
    height: 10rem;
  }

  .md\:dq-h-44 {
    height: 11rem;
  }

  .md\:dq-h-48 {
    height: 12rem;
  }

  .md\:dq-h-52 {
    height: 13rem;
  }

  .md\:dq-h-56 {
    height: 14rem;
  }

  .md\:dq-h-60 {
    height: 15rem;
  }

  .md\:dq-h-64 {
    height: 16rem;
  }

  .md\:dq-h-72 {
    height: 18rem;
  }

  .md\:dq-h-80 {
    height: 20rem;
  }

  .md\:dq-h-96 {
    height: 24rem;
  }

  .md\:dq-h-auto {
    height: auto;
  }

  .md\:dq-h-px {
    height: 1px;
  }

  .md\:dq-h-0\.5 {
    height: 0.125rem;
  }

  .md\:dq-h-1\.5 {
    height: 0.375rem;
  }

  .md\:dq-h-2\.5 {
    height: 0.625rem;
  }

  .md\:dq-h-3\.5 {
    height: 0.875rem;
  }

  .md\:dq-h-1\/2 {
    height: 50%;
  }

  .md\:dq-h-1\/3 {
    height: 33.333333%;
  }

  .md\:dq-h-2\/3 {
    height: 66.666667%;
  }

  .md\:dq-h-1\/4 {
    height: 25%;
  }

  .md\:dq-h-2\/4 {
    height: 50%;
  }

  .md\:dq-h-3\/4 {
    height: 75%;
  }

  .md\:dq-h-1\/5 {
    height: 20%;
  }

  .md\:dq-h-2\/5 {
    height: 40%;
  }

  .md\:dq-h-3\/5 {
    height: 60%;
  }

  .md\:dq-h-4\/5 {
    height: 80%;
  }

  .md\:dq-h-1\/6 {
    height: 16.666667%;
  }

  .md\:dq-h-2\/6 {
    height: 33.333333%;
  }

  .md\:dq-h-3\/6 {
    height: 50%;
  }

  .md\:dq-h-4\/6 {
    height: 66.666667%;
  }

  .md\:dq-h-5\/6 {
    height: 83.333333%;
  }

  .md\:dq-h-full {
    height: 100%;
  }

  .md\:dq-h-screen {
    height: 100vh;
  }

  .md\:dq-max-h-0 {
    max-height: 0px;
  }

  .md\:dq-max-h-1 {
    max-height: 0.25rem;
  }

  .md\:dq-max-h-2 {
    max-height: 0.5rem;
  }

  .md\:dq-max-h-3 {
    max-height: 0.75rem;
  }

  .md\:dq-max-h-4 {
    max-height: 1rem;
  }

  .md\:dq-max-h-5 {
    max-height: 1.25rem;
  }

  .md\:dq-max-h-6 {
    max-height: 1.5rem;
  }

  .md\:dq-max-h-7 {
    max-height: 1.75rem;
  }

  .md\:dq-max-h-8 {
    max-height: 2rem;
  }

  .md\:dq-max-h-9 {
    max-height: 2.25rem;
  }

  .md\:dq-max-h-10 {
    max-height: 2.5rem;
  }

  .md\:dq-max-h-11 {
    max-height: 2.75rem;
  }

  .md\:dq-max-h-12 {
    max-height: 3rem;
  }

  .md\:dq-max-h-14 {
    max-height: 3.5rem;
  }

  .md\:dq-max-h-16 {
    max-height: 4rem;
  }

  .md\:dq-max-h-20 {
    max-height: 5rem;
  }

  .md\:dq-max-h-24 {
    max-height: 6rem;
  }

  .md\:dq-max-h-28 {
    max-height: 7rem;
  }

  .md\:dq-max-h-32 {
    max-height: 8rem;
  }

  .md\:dq-max-h-36 {
    max-height: 9rem;
  }

  .md\:dq-max-h-40 {
    max-height: 10rem;
  }

  .md\:dq-max-h-44 {
    max-height: 11rem;
  }

  .md\:dq-max-h-48 {
    max-height: 12rem;
  }

  .md\:dq-max-h-52 {
    max-height: 13rem;
  }

  .md\:dq-max-h-56 {
    max-height: 14rem;
  }

  .md\:dq-max-h-60 {
    max-height: 15rem;
  }

  .md\:dq-max-h-64 {
    max-height: 16rem;
  }

  .md\:dq-max-h-72 {
    max-height: 18rem;
  }

  .md\:dq-max-h-80 {
    max-height: 20rem;
  }

  .md\:dq-max-h-96 {
    max-height: 24rem;
  }

  .md\:dq-max-h-px {
    max-height: 1px;
  }

  .md\:dq-max-h-0\.5 {
    max-height: 0.125rem;
  }

  .md\:dq-max-h-1\.5 {
    max-height: 0.375rem;
  }

  .md\:dq-max-h-2\.5 {
    max-height: 0.625rem;
  }

  .md\:dq-max-h-3\.5 {
    max-height: 0.875rem;
  }

  .md\:dq-max-h-full {
    max-height: 100%;
  }

  .md\:dq-max-h-screen {
    max-height: 100vh;
  }

  .md\:dq-min-h-0 {
    min-height: 0px;
  }

  .md\:dq-min-h-full {
    min-height: 100%;
  }

  .md\:dq-min-h-screen {
    min-height: 100vh;
  }

  .md\:dq-w-0 {
    width: 0px;
  }

  .md\:dq-w-1 {
    width: 0.25rem;
  }

  .md\:dq-w-2 {
    width: 0.5rem;
  }

  .md\:dq-w-3 {
    width: 0.75rem;
  }

  .md\:dq-w-4 {
    width: 1rem;
  }

  .md\:dq-w-5 {
    width: 1.25rem;
  }

  .md\:dq-w-6 {
    width: 1.5rem;
  }

  .md\:dq-w-7 {
    width: 1.75rem;
  }

  .md\:dq-w-8 {
    width: 2rem;
  }

  .md\:dq-w-9 {
    width: 2.25rem;
  }

  .md\:dq-w-10 {
    width: 2.5rem;
  }

  .md\:dq-w-11 {
    width: 2.75rem;
  }

  .md\:dq-w-12 {
    width: 3rem;
  }

  .md\:dq-w-14 {
    width: 3.5rem;
  }

  .md\:dq-w-16 {
    width: 4rem;
  }

  .md\:dq-w-20 {
    width: 5rem;
  }

  .md\:dq-w-24 {
    width: 6rem;
  }

  .md\:dq-w-28 {
    width: 7rem;
  }

  .md\:dq-w-32 {
    width: 8rem;
  }

  .md\:dq-w-36 {
    width: 9rem;
  }

  .md\:dq-w-40 {
    width: 10rem;
  }

  .md\:dq-w-44 {
    width: 11rem;
  }

  .md\:dq-w-48 {
    width: 12rem;
  }

  .md\:dq-w-52 {
    width: 13rem;
  }

  .md\:dq-w-56 {
    width: 14rem;
  }

  .md\:dq-w-60 {
    width: 15rem;
  }

  .md\:dq-w-64 {
    width: 16rem;
  }

  .md\:dq-w-72 {
    width: 18rem;
  }

  .md\:dq-w-80 {
    width: 20rem;
  }

  .md\:dq-w-96 {
    width: 24rem;
  }

  .md\:dq-w-auto {
    width: auto;
  }

  .md\:dq-w-px {
    width: 1px;
  }

  .md\:dq-w-0\.5 {
    width: 0.125rem;
  }

  .md\:dq-w-1\.5 {
    width: 0.375rem;
  }

  .md\:dq-w-2\.5 {
    width: 0.625rem;
  }

  .md\:dq-w-3\.5 {
    width: 0.875rem;
  }

  .md\:dq-w-1\/2 {
    width: 50%;
  }

  .md\:dq-w-1\/3 {
    width: 33.333333%;
  }

  .md\:dq-w-2\/3 {
    width: 66.666667%;
  }

  .md\:dq-w-1\/4 {
    width: 25%;
  }

  .md\:dq-w-2\/4 {
    width: 50%;
  }

  .md\:dq-w-3\/4 {
    width: 75%;
  }

  .md\:dq-w-1\/5 {
    width: 20%;
  }

  .md\:dq-w-2\/5 {
    width: 40%;
  }

  .md\:dq-w-3\/5 {
    width: 60%;
  }

  .md\:dq-w-4\/5 {
    width: 80%;
  }

  .md\:dq-w-1\/6 {
    width: 16.666667%;
  }

  .md\:dq-w-2\/6 {
    width: 33.333333%;
  }

  .md\:dq-w-3\/6 {
    width: 50%;
  }

  .md\:dq-w-4\/6 {
    width: 66.666667%;
  }

  .md\:dq-w-5\/6 {
    width: 83.333333%;
  }

  .md\:dq-w-1\/12 {
    width: 8.333333%;
  }

  .md\:dq-w-2\/12 {
    width: 16.666667%;
  }

  .md\:dq-w-3\/12 {
    width: 25%;
  }

  .md\:dq-w-4\/12 {
    width: 33.333333%;
  }

  .md\:dq-w-5\/12 {
    width: 41.666667%;
  }

  .md\:dq-w-6\/12 {
    width: 50%;
  }

  .md\:dq-w-7\/12 {
    width: 58.333333%;
  }

  .md\:dq-w-8\/12 {
    width: 66.666667%;
  }

  .md\:dq-w-9\/12 {
    width: 75%;
  }

  .md\:dq-w-10\/12 {
    width: 83.333333%;
  }

  .md\:dq-w-11\/12 {
    width: 91.666667%;
  }

  .md\:dq-w-full {
    width: 100%;
  }

  .md\:dq-w-screen {
    width: 100vw;
  }

  .md\:dq-w-min {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }

  .md\:dq-w-max {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .md\:dq-min-w-0 {
    min-width: 0px;
  }

  .md\:dq-min-w-full {
    min-width: 100%;
  }

  .md\:dq-min-w-min {
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
  }

  .md\:dq-min-w-max {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
  }

  .md\:dq-max-w-0 {
    max-width: 0rem;
  }

  .md\:dq-max-w-none {
    max-width: none;
  }

  .md\:dq-max-w-xs {
    max-width: 20rem;
  }

  .md\:dq-max-w-sm {
    max-width: 24rem;
  }

  .md\:dq-max-w-md {
    max-width: 28rem;
  }

  .md\:dq-max-w-lg {
    max-width: 32rem;
  }

  .md\:dq-max-w-xl {
    max-width: 36rem;
  }

  .md\:dq-max-w-2xl {
    max-width: 42rem;
  }

  .md\:dq-max-w-3xl {
    max-width: 48rem;
  }

  .md\:dq-max-w-4xl {
    max-width: 56rem;
  }

  .md\:dq-max-w-5xl {
    max-width: 64rem;
  }

  .md\:dq-max-w-6xl {
    max-width: 72rem;
  }

  .md\:dq-max-w-7xl {
    max-width: 80rem;
  }

  .md\:dq-max-w-full {
    max-width: 100%;
  }

  .md\:dq-max-w-min {
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
  }

  .md\:dq-max-w-max {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }

  .md\:dq-max-w-prose {
    max-width: 65ch;
  }

  .md\:dq-max-w-screen-sm {
    max-width: 640px;
  }

  .md\:dq-max-w-screen-md {
    max-width: 768px;
  }

  .md\:dq-max-w-screen-lg {
    max-width: 1024px;
  }

  .md\:dq-max-w-screen-xl {
    max-width: 1280px;
  }

  .md\:dq-max-w-screen-2xl {
    max-width: 1536px;
  }

  .md\:dq-flex-1 {
    flex: 1 1 0%;
  }

  .md\:dq-flex-auto {
    flex: 1 1 auto;
  }

  .md\:dq-flex-initial {
    flex: 0 1 auto;
  }

  .md\:dq-flex-none {
    flex: none;
  }

  .md\:dq-flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:dq-flex-shrink {
    flex-shrink: 1;
  }

  .md\:dq-flex-grow-0 {
    flex-grow: 0;
  }

  .md\:dq-flex-grow {
    flex-grow: 1;
  }

  .md\:dq-table-auto {
    table-layout: auto;
  }

  .md\:dq-table-fixed {
    table-layout: fixed;
  }

  .md\:dq-border-collapse {
    border-collapse: collapse;
  }

  .md\:dq-border-separate {
    border-collapse: separate;
  }

  .md\:dq-origin-center {
    transform-origin: center;
  }

  .md\:dq-origin-top {
    transform-origin: top;
  }

  .md\:dq-origin-top-right {
    transform-origin: top right;
  }

  .md\:dq-origin-right {
    transform-origin: right;
  }

  .md\:dq-origin-bottom-right {
    transform-origin: bottom right;
  }

  .md\:dq-origin-bottom {
    transform-origin: bottom;
  }

  .md\:dq-origin-bottom-left {
    transform-origin: bottom left;
  }

  .md\:dq-origin-left {
    transform-origin: left;
  }

  .md\:dq-origin-top-left {
    transform-origin: top left;
  }

  .md\:dq-transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:dq-transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:dq-transform-none {
    transform: none;
  }

  .md\:dq-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .md\:dq-translate-x-1 {
    --tw-translate-x: 0.25rem;
  }

  .md\:dq-translate-x-2 {
    --tw-translate-x: 0.5rem;
  }

  .md\:dq-translate-x-3 {
    --tw-translate-x: 0.75rem;
  }

  .md\:dq-translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .md\:dq-translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .md\:dq-translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .md\:dq-translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .md\:dq-translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .md\:dq-translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .md\:dq-translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .md\:dq-translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .md\:dq-translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .md\:dq-translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .md\:dq-translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .md\:dq-translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .md\:dq-translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .md\:dq-translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .md\:dq-translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .md\:dq-translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .md\:dq-translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .md\:dq-translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .md\:dq-translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .md\:dq-translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .md\:dq-translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .md\:dq-translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .md\:dq-translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .md\:dq-translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .md\:dq-translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .md\:dq-translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .md\:dq-translate-x-px {
    --tw-translate-x: 1px;
  }

  .md\:dq-translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }

  .md\:dq-translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }

  .md\:dq-translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }

  .md\:dq-translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }

  .md\:dq--translate-x-0 {
    --tw-translate-x: 0px;
  }

  .md\:dq--translate-x-1 {
    --tw-translate-x: -0.25rem;
  }

  .md\:dq--translate-x-2 {
    --tw-translate-x: -0.5rem;
  }

  .md\:dq--translate-x-3 {
    --tw-translate-x: -0.75rem;
  }

  .md\:dq--translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .md\:dq--translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .md\:dq--translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .md\:dq--translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .md\:dq--translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .md\:dq--translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .md\:dq--translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .md\:dq--translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .md\:dq--translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .md\:dq--translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .md\:dq--translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .md\:dq--translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .md\:dq--translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .md\:dq--translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .md\:dq--translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .md\:dq--translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .md\:dq--translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .md\:dq--translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .md\:dq--translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .md\:dq--translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .md\:dq--translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .md\:dq--translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .md\:dq--translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .md\:dq--translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .md\:dq--translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .md\:dq--translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .md\:dq--translate-x-px {
    --tw-translate-x: -1px;
  }

  .md\:dq--translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }

  .md\:dq--translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }

  .md\:dq--translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }

  .md\:dq--translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }

  .md\:dq-translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .md\:dq-translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }

  .md\:dq-translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }

  .md\:dq-translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .md\:dq-translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .md\:dq-translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .md\:dq-translate-x-full {
    --tw-translate-x: 100%;
  }

  .md\:dq--translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .md\:dq--translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }

  .md\:dq--translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }

  .md\:dq--translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .md\:dq--translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .md\:dq--translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .md\:dq--translate-x-full {
    --tw-translate-x: -100%;
  }

  .md\:dq-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .md\:dq-translate-y-1 {
    --tw-translate-y: 0.25rem;
  }

  .md\:dq-translate-y-2 {
    --tw-translate-y: 0.5rem;
  }

  .md\:dq-translate-y-3 {
    --tw-translate-y: 0.75rem;
  }

  .md\:dq-translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .md\:dq-translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .md\:dq-translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .md\:dq-translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .md\:dq-translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .md\:dq-translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .md\:dq-translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .md\:dq-translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .md\:dq-translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .md\:dq-translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .md\:dq-translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .md\:dq-translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .md\:dq-translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .md\:dq-translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .md\:dq-translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .md\:dq-translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .md\:dq-translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .md\:dq-translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .md\:dq-translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .md\:dq-translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .md\:dq-translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .md\:dq-translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .md\:dq-translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .md\:dq-translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .md\:dq-translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .md\:dq-translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .md\:dq-translate-y-px {
    --tw-translate-y: 1px;
  }

  .md\:dq-translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }

  .md\:dq-translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }

  .md\:dq-translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }

  .md\:dq-translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }

  .md\:dq--translate-y-0 {
    --tw-translate-y: 0px;
  }

  .md\:dq--translate-y-1 {
    --tw-translate-y: -0.25rem;
  }

  .md\:dq--translate-y-2 {
    --tw-translate-y: -0.5rem;
  }

  .md\:dq--translate-y-3 {
    --tw-translate-y: -0.75rem;
  }

  .md\:dq--translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .md\:dq--translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .md\:dq--translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .md\:dq--translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .md\:dq--translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .md\:dq--translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .md\:dq--translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .md\:dq--translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .md\:dq--translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .md\:dq--translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .md\:dq--translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .md\:dq--translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .md\:dq--translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .md\:dq--translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .md\:dq--translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .md\:dq--translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .md\:dq--translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .md\:dq--translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .md\:dq--translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .md\:dq--translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .md\:dq--translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .md\:dq--translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .md\:dq--translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .md\:dq--translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .md\:dq--translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .md\:dq--translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .md\:dq--translate-y-px {
    --tw-translate-y: -1px;
  }

  .md\:dq--translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }

  .md\:dq--translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }

  .md\:dq--translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }

  .md\:dq--translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }

  .md\:dq-translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .md\:dq-translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }

  .md\:dq-translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }

  .md\:dq-translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .md\:dq-translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .md\:dq-translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .md\:dq-translate-y-full {
    --tw-translate-y: 100%;
  }

  .md\:dq--translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .md\:dq--translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }

  .md\:dq--translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }

  .md\:dq--translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .md\:dq--translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .md\:dq--translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .md\:dq--translate-y-full {
    --tw-translate-y: -100%;
  }

  .md\:hover\:dq-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .md\:hover\:dq-translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }

  .md\:hover\:dq-translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }

  .md\:hover\:dq-translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }

  .md\:hover\:dq-translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .md\:hover\:dq-translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .md\:hover\:dq-translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .md\:hover\:dq-translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .md\:hover\:dq-translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .md\:hover\:dq-translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .md\:hover\:dq-translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .md\:hover\:dq-translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .md\:hover\:dq-translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .md\:hover\:dq-translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .md\:hover\:dq-translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .md\:hover\:dq-translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .md\:hover\:dq-translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .md\:hover\:dq-translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .md\:hover\:dq-translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .md\:hover\:dq-translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .md\:hover\:dq-translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .md\:hover\:dq-translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .md\:hover\:dq-translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .md\:hover\:dq-translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .md\:hover\:dq-translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .md\:hover\:dq-translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .md\:hover\:dq-translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .md\:hover\:dq-translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .md\:hover\:dq-translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .md\:hover\:dq-translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .md\:hover\:dq-translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .md\:hover\:dq-translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }

  .md\:hover\:dq-translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }

  .md\:hover\:dq-translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }

  .md\:hover\:dq-translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }

  .md\:hover\:dq--translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .md\:hover\:dq--translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }

  .md\:hover\:dq--translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }

  .md\:hover\:dq--translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }

  .md\:hover\:dq--translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .md\:hover\:dq--translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .md\:hover\:dq--translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .md\:hover\:dq--translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .md\:hover\:dq--translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .md\:hover\:dq--translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .md\:hover\:dq--translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .md\:hover\:dq--translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .md\:hover\:dq--translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .md\:hover\:dq--translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .md\:hover\:dq--translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .md\:hover\:dq--translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .md\:hover\:dq--translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .md\:hover\:dq--translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .md\:hover\:dq--translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .md\:hover\:dq--translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .md\:hover\:dq--translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .md\:hover\:dq--translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .md\:hover\:dq--translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .md\:hover\:dq--translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .md\:hover\:dq--translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .md\:hover\:dq--translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .md\:hover\:dq--translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .md\:hover\:dq--translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .md\:hover\:dq--translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .md\:hover\:dq--translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .md\:hover\:dq--translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .md\:hover\:dq--translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }

  .md\:hover\:dq--translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }

  .md\:hover\:dq--translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }

  .md\:hover\:dq--translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }

  .md\:hover\:dq-translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .md\:hover\:dq-translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }

  .md\:hover\:dq-translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }

  .md\:hover\:dq-translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .md\:hover\:dq-translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .md\:hover\:dq-translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .md\:hover\:dq-translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .md\:hover\:dq--translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .md\:hover\:dq--translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }

  .md\:hover\:dq--translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }

  .md\:hover\:dq--translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .md\:hover\:dq--translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .md\:hover\:dq--translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .md\:hover\:dq--translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .md\:hover\:dq-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .md\:hover\:dq-translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }

  .md\:hover\:dq-translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }

  .md\:hover\:dq-translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }

  .md\:hover\:dq-translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .md\:hover\:dq-translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .md\:hover\:dq-translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .md\:hover\:dq-translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .md\:hover\:dq-translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .md\:hover\:dq-translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .md\:hover\:dq-translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .md\:hover\:dq-translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .md\:hover\:dq-translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .md\:hover\:dq-translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .md\:hover\:dq-translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .md\:hover\:dq-translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .md\:hover\:dq-translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .md\:hover\:dq-translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .md\:hover\:dq-translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .md\:hover\:dq-translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .md\:hover\:dq-translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .md\:hover\:dq-translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .md\:hover\:dq-translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .md\:hover\:dq-translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .md\:hover\:dq-translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .md\:hover\:dq-translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .md\:hover\:dq-translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .md\:hover\:dq-translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .md\:hover\:dq-translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .md\:hover\:dq-translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .md\:hover\:dq-translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .md\:hover\:dq-translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }

  .md\:hover\:dq-translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }

  .md\:hover\:dq-translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }

  .md\:hover\:dq-translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }

  .md\:hover\:dq--translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .md\:hover\:dq--translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }

  .md\:hover\:dq--translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }

  .md\:hover\:dq--translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }

  .md\:hover\:dq--translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .md\:hover\:dq--translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .md\:hover\:dq--translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .md\:hover\:dq--translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .md\:hover\:dq--translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .md\:hover\:dq--translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .md\:hover\:dq--translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .md\:hover\:dq--translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .md\:hover\:dq--translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .md\:hover\:dq--translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .md\:hover\:dq--translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .md\:hover\:dq--translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .md\:hover\:dq--translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .md\:hover\:dq--translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .md\:hover\:dq--translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .md\:hover\:dq--translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .md\:hover\:dq--translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .md\:hover\:dq--translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .md\:hover\:dq--translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .md\:hover\:dq--translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .md\:hover\:dq--translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .md\:hover\:dq--translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .md\:hover\:dq--translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .md\:hover\:dq--translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .md\:hover\:dq--translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .md\:hover\:dq--translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .md\:hover\:dq--translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .md\:hover\:dq--translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }

  .md\:hover\:dq--translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }

  .md\:hover\:dq--translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }

  .md\:hover\:dq--translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }

  .md\:hover\:dq-translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .md\:hover\:dq-translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }

  .md\:hover\:dq-translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }

  .md\:hover\:dq-translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .md\:hover\:dq-translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .md\:hover\:dq-translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .md\:hover\:dq-translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .md\:hover\:dq--translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .md\:hover\:dq--translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }

  .md\:hover\:dq--translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }

  .md\:hover\:dq--translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .md\:hover\:dq--translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .md\:hover\:dq--translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .md\:hover\:dq--translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .md\:focus\:dq-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .md\:focus\:dq-translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }

  .md\:focus\:dq-translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }

  .md\:focus\:dq-translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }

  .md\:focus\:dq-translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .md\:focus\:dq-translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .md\:focus\:dq-translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .md\:focus\:dq-translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .md\:focus\:dq-translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .md\:focus\:dq-translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .md\:focus\:dq-translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .md\:focus\:dq-translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .md\:focus\:dq-translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .md\:focus\:dq-translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .md\:focus\:dq-translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .md\:focus\:dq-translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .md\:focus\:dq-translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .md\:focus\:dq-translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .md\:focus\:dq-translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .md\:focus\:dq-translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .md\:focus\:dq-translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .md\:focus\:dq-translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .md\:focus\:dq-translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .md\:focus\:dq-translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .md\:focus\:dq-translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .md\:focus\:dq-translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .md\:focus\:dq-translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .md\:focus\:dq-translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .md\:focus\:dq-translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .md\:focus\:dq-translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .md\:focus\:dq-translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .md\:focus\:dq-translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }

  .md\:focus\:dq-translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }

  .md\:focus\:dq-translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }

  .md\:focus\:dq-translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }

  .md\:focus\:dq--translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .md\:focus\:dq--translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }

  .md\:focus\:dq--translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }

  .md\:focus\:dq--translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }

  .md\:focus\:dq--translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .md\:focus\:dq--translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .md\:focus\:dq--translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .md\:focus\:dq--translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .md\:focus\:dq--translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .md\:focus\:dq--translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .md\:focus\:dq--translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .md\:focus\:dq--translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .md\:focus\:dq--translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .md\:focus\:dq--translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .md\:focus\:dq--translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .md\:focus\:dq--translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .md\:focus\:dq--translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .md\:focus\:dq--translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .md\:focus\:dq--translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .md\:focus\:dq--translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .md\:focus\:dq--translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .md\:focus\:dq--translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .md\:focus\:dq--translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .md\:focus\:dq--translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .md\:focus\:dq--translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .md\:focus\:dq--translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .md\:focus\:dq--translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .md\:focus\:dq--translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .md\:focus\:dq--translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .md\:focus\:dq--translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .md\:focus\:dq--translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .md\:focus\:dq--translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }

  .md\:focus\:dq--translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }

  .md\:focus\:dq--translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }

  .md\:focus\:dq--translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }

  .md\:focus\:dq-translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .md\:focus\:dq-translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }

  .md\:focus\:dq-translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }

  .md\:focus\:dq-translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .md\:focus\:dq-translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .md\:focus\:dq-translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .md\:focus\:dq-translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .md\:focus\:dq--translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .md\:focus\:dq--translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }

  .md\:focus\:dq--translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }

  .md\:focus\:dq--translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .md\:focus\:dq--translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .md\:focus\:dq--translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .md\:focus\:dq--translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .md\:focus\:dq-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .md\:focus\:dq-translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }

  .md\:focus\:dq-translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }

  .md\:focus\:dq-translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }

  .md\:focus\:dq-translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .md\:focus\:dq-translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .md\:focus\:dq-translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .md\:focus\:dq-translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .md\:focus\:dq-translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .md\:focus\:dq-translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .md\:focus\:dq-translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .md\:focus\:dq-translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .md\:focus\:dq-translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .md\:focus\:dq-translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .md\:focus\:dq-translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .md\:focus\:dq-translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .md\:focus\:dq-translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .md\:focus\:dq-translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .md\:focus\:dq-translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .md\:focus\:dq-translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .md\:focus\:dq-translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .md\:focus\:dq-translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .md\:focus\:dq-translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .md\:focus\:dq-translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .md\:focus\:dq-translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .md\:focus\:dq-translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .md\:focus\:dq-translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .md\:focus\:dq-translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .md\:focus\:dq-translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .md\:focus\:dq-translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .md\:focus\:dq-translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .md\:focus\:dq-translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }

  .md\:focus\:dq-translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }

  .md\:focus\:dq-translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }

  .md\:focus\:dq-translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }

  .md\:focus\:dq--translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .md\:focus\:dq--translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }

  .md\:focus\:dq--translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }

  .md\:focus\:dq--translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }

  .md\:focus\:dq--translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .md\:focus\:dq--translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .md\:focus\:dq--translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .md\:focus\:dq--translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .md\:focus\:dq--translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .md\:focus\:dq--translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .md\:focus\:dq--translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .md\:focus\:dq--translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .md\:focus\:dq--translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .md\:focus\:dq--translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .md\:focus\:dq--translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .md\:focus\:dq--translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .md\:focus\:dq--translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .md\:focus\:dq--translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .md\:focus\:dq--translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .md\:focus\:dq--translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .md\:focus\:dq--translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .md\:focus\:dq--translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .md\:focus\:dq--translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .md\:focus\:dq--translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .md\:focus\:dq--translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .md\:focus\:dq--translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .md\:focus\:dq--translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .md\:focus\:dq--translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .md\:focus\:dq--translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .md\:focus\:dq--translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .md\:focus\:dq--translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .md\:focus\:dq--translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }

  .md\:focus\:dq--translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }

  .md\:focus\:dq--translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }

  .md\:focus\:dq--translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }

  .md\:focus\:dq-translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .md\:focus\:dq-translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }

  .md\:focus\:dq-translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }

  .md\:focus\:dq-translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .md\:focus\:dq-translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .md\:focus\:dq-translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .md\:focus\:dq-translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .md\:focus\:dq--translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .md\:focus\:dq--translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }

  .md\:focus\:dq--translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }

  .md\:focus\:dq--translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .md\:focus\:dq--translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .md\:focus\:dq--translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .md\:focus\:dq--translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .md\:dq-rotate-0 {
    --tw-rotate: 0deg;
  }

  .md\:dq-rotate-1 {
    --tw-rotate: 1deg;
  }

  .md\:dq-rotate-2 {
    --tw-rotate: 2deg;
  }

  .md\:dq-rotate-3 {
    --tw-rotate: 3deg;
  }

  .md\:dq-rotate-6 {
    --tw-rotate: 6deg;
  }

  .md\:dq-rotate-12 {
    --tw-rotate: 12deg;
  }

  .md\:dq-rotate-45 {
    --tw-rotate: 45deg;
  }

  .md\:dq-rotate-90 {
    --tw-rotate: 90deg;
  }

  .md\:dq-rotate-180 {
    --tw-rotate: 180deg;
  }

  .md\:dq--rotate-180 {
    --tw-rotate: -180deg;
  }

  .md\:dq--rotate-90 {
    --tw-rotate: -90deg;
  }

  .md\:dq--rotate-45 {
    --tw-rotate: -45deg;
  }

  .md\:dq--rotate-12 {
    --tw-rotate: -12deg;
  }

  .md\:dq--rotate-6 {
    --tw-rotate: -6deg;
  }

  .md\:dq--rotate-3 {
    --tw-rotate: -3deg;
  }

  .md\:dq--rotate-2 {
    --tw-rotate: -2deg;
  }

  .md\:dq--rotate-1 {
    --tw-rotate: -1deg;
  }

  .md\:hover\:dq-rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .md\:hover\:dq-rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .md\:hover\:dq-rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .md\:hover\:dq-rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .md\:hover\:dq-rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .md\:hover\:dq-rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .md\:hover\:dq-rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .md\:hover\:dq-rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .md\:hover\:dq-rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .md\:hover\:dq--rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .md\:hover\:dq--rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .md\:hover\:dq--rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .md\:hover\:dq--rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .md\:hover\:dq--rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .md\:hover\:dq--rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .md\:hover\:dq--rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .md\:hover\:dq--rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .md\:focus\:dq-rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .md\:focus\:dq-rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .md\:focus\:dq-rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .md\:focus\:dq-rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .md\:focus\:dq-rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .md\:focus\:dq-rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .md\:focus\:dq-rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .md\:focus\:dq-rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .md\:focus\:dq-rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .md\:focus\:dq--rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .md\:focus\:dq--rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .md\:focus\:dq--rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .md\:focus\:dq--rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .md\:focus\:dq--rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .md\:focus\:dq--rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .md\:focus\:dq--rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .md\:focus\:dq--rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .md\:dq-skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .md\:dq-skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .md\:dq-skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .md\:dq-skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .md\:dq-skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .md\:dq-skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .md\:dq--skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .md\:dq--skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .md\:dq--skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .md\:dq--skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .md\:dq--skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .md\:dq-skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .md\:dq-skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .md\:dq-skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .md\:dq-skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .md\:dq-skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .md\:dq-skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .md\:dq--skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .md\:dq--skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .md\:dq--skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .md\:dq--skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .md\:dq--skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .md\:hover\:dq-skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .md\:hover\:dq-skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .md\:hover\:dq-skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .md\:hover\:dq-skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .md\:hover\:dq-skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .md\:hover\:dq-skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .md\:hover\:dq--skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .md\:hover\:dq--skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .md\:hover\:dq--skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .md\:hover\:dq--skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .md\:hover\:dq--skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .md\:hover\:dq-skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .md\:hover\:dq-skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .md\:hover\:dq-skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .md\:hover\:dq-skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .md\:hover\:dq-skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .md\:hover\:dq-skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .md\:hover\:dq--skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .md\:hover\:dq--skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .md\:hover\:dq--skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .md\:hover\:dq--skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .md\:hover\:dq--skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .md\:focus\:dq-skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .md\:focus\:dq-skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .md\:focus\:dq-skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .md\:focus\:dq-skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .md\:focus\:dq-skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .md\:focus\:dq-skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .md\:focus\:dq--skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .md\:focus\:dq--skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .md\:focus\:dq--skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .md\:focus\:dq--skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .md\:focus\:dq--skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .md\:focus\:dq-skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .md\:focus\:dq-skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .md\:focus\:dq-skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .md\:focus\:dq-skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .md\:focus\:dq-skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .md\:focus\:dq-skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .md\:focus\:dq--skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .md\:focus\:dq--skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .md\:focus\:dq--skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .md\:focus\:dq--skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .md\:focus\:dq--skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .md\:dq-scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:dq-scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:dq-scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:dq-scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:dq-scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:dq-scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:dq-scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:dq-scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:dq-scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:dq-scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:hover\:dq-scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:hover\:dq-scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:hover\:dq-scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:hover\:dq-scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:hover\:dq-scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:hover\:dq-scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:hover\:dq-scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:hover\:dq-scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:hover\:dq-scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:hover\:dq-scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:focus\:dq-scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:focus\:dq-scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:focus\:dq-scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:focus\:dq-scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:focus\:dq-scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:focus\:dq-scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:focus\:dq-scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:focus\:dq-scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:focus\:dq-scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:focus\:dq-scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:dq-scale-x-0 {
    --tw-scale-x: 0;
  }

  .md\:dq-scale-x-50 {
    --tw-scale-x: .5;
  }

  .md\:dq-scale-x-75 {
    --tw-scale-x: .75;
  }

  .md\:dq-scale-x-90 {
    --tw-scale-x: .9;
  }

  .md\:dq-scale-x-95 {
    --tw-scale-x: .95;
  }

  .md\:dq-scale-x-100 {
    --tw-scale-x: 1;
  }

  .md\:dq-scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .md\:dq-scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .md\:dq-scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .md\:dq-scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .md\:dq-scale-y-0 {
    --tw-scale-y: 0;
  }

  .md\:dq-scale-y-50 {
    --tw-scale-y: .5;
  }

  .md\:dq-scale-y-75 {
    --tw-scale-y: .75;
  }

  .md\:dq-scale-y-90 {
    --tw-scale-y: .9;
  }

  .md\:dq-scale-y-95 {
    --tw-scale-y: .95;
  }

  .md\:dq-scale-y-100 {
    --tw-scale-y: 1;
  }

  .md\:dq-scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .md\:dq-scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .md\:dq-scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .md\:dq-scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .md\:hover\:dq-scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .md\:hover\:dq-scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .md\:hover\:dq-scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .md\:hover\:dq-scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .md\:hover\:dq-scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .md\:hover\:dq-scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .md\:hover\:dq-scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .md\:hover\:dq-scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .md\:hover\:dq-scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .md\:hover\:dq-scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .md\:hover\:dq-scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .md\:hover\:dq-scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .md\:hover\:dq-scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .md\:hover\:dq-scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .md\:hover\:dq-scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .md\:hover\:dq-scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .md\:hover\:dq-scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .md\:hover\:dq-scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .md\:hover\:dq-scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .md\:hover\:dq-scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .md\:focus\:dq-scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .md\:focus\:dq-scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .md\:focus\:dq-scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .md\:focus\:dq-scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .md\:focus\:dq-scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .md\:focus\:dq-scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .md\:focus\:dq-scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .md\:focus\:dq-scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .md\:focus\:dq-scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .md\:focus\:dq-scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .md\:focus\:dq-scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .md\:focus\:dq-scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .md\:focus\:dq-scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .md\:focus\:dq-scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .md\:focus\:dq-scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .md\:focus\:dq-scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .md\:focus\:dq-scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .md\:focus\:dq-scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .md\:focus\:dq-scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .md\:focus\:dq-scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .md\:dq-animate-none {
    animation: none;
  }

  .md\:dq-animate-spin {
    animation: dq-spin 1s linear infinite;
  }

  .md\:dq-animate-ping {
    animation: dq-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .md\:dq-animate-pulse {
    animation: dq-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .md\:dq-animate-bounce {
    animation: dq-bounce 1s infinite;
  }

  .md\:dq-cursor-auto {
    cursor: auto;
  }

  .md\:dq-cursor-default {
    cursor: default;
  }

  .md\:dq-cursor-pointer {
    cursor: pointer;
  }

  .md\:dq-cursor-wait {
    cursor: wait;
  }

  .md\:dq-cursor-text {
    cursor: text;
  }

  .md\:dq-cursor-move {
    cursor: move;
  }

  .md\:dq-cursor-help {
    cursor: help;
  }

  .md\:dq-cursor-not-allowed {
    cursor: not-allowed;
  }

  .md\:dq-select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .md\:dq-select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .md\:dq-select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .md\:dq-select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }

  .md\:dq-resize-none {
    resize: none;
  }

  .md\:dq-resize-y {
    resize: vertical;
  }

  .md\:dq-resize-x {
    resize: horizontal;
  }

  .md\:dq-resize {
    resize: both;
  }

  .md\:dq-list-inside {
    list-style-position: inside;
  }

  .md\:dq-list-outside {
    list-style-position: outside;
  }

  .md\:dq-list-none {
    list-style-type: none;
  }

  .md\:dq-list-disc {
    list-style-type: disc;
  }

  .md\:dq-list-decimal {
    list-style-type: decimal;
  }

  .md\:dq-appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  .md\:dq-auto-cols-auto {
    grid-auto-columns: auto;
  }

  .md\:dq-auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .md\:dq-auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .md\:dq-auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .md\:dq-grid-flow-row {
    grid-auto-flow: row;
  }

  .md\:dq-grid-flow-col {
    grid-auto-flow: column;
  }

  .md\:dq-grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .md\:dq-grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .md\:dq-auto-rows-auto {
    grid-auto-rows: auto;
  }

  .md\:dq-auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .md\:dq-auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .md\:dq-auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .md\:dq-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:dq-grid-cols-none {
    grid-template-columns: none;
  }

  .md\:dq-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .md\:dq-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:dq-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md\:dq-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .md\:dq-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .md\:dq-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .md\:dq-grid-rows-none {
    grid-template-rows: none;
  }

  .md\:dq-flex-row {
    flex-direction: row;
  }

  .md\:dq-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:dq-flex-col {
    flex-direction: column;
  }

  .md\:dq-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:dq-flex-wrap {
    flex-wrap: wrap;
  }

  .md\:dq-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .md\:dq-flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:dq-place-content-center {
    place-content: center;
  }

  .md\:dq-place-content-start {
    place-content: start;
  }

  .md\:dq-place-content-end {
    place-content: end;
  }

  .md\:dq-place-content-between {
    place-content: space-between;
  }

  .md\:dq-place-content-around {
    place-content: space-around;
  }

  .md\:dq-place-content-evenly {
    place-content: space-evenly;
  }

  .md\:dq-place-content-stretch {
    place-content: stretch;
  }

  .md\:dq-place-items-start {
    place-items: start;
  }

  .md\:dq-place-items-end {
    place-items: end;
  }

  .md\:dq-place-items-center {
    place-items: center;
  }

  .md\:dq-place-items-stretch {
    place-items: stretch;
  }

  .md\:dq-content-center {
    align-content: center;
  }

  .md\:dq-content-start {
    align-content: flex-start;
  }

  .md\:dq-content-end {
    align-content: flex-end;
  }

  .md\:dq-content-between {
    align-content: space-between;
  }

  .md\:dq-content-around {
    align-content: space-around;
  }

  .md\:dq-content-evenly {
    align-content: space-evenly;
  }

  .md\:dq-items-start {
    align-items: flex-start;
  }

  .md\:dq-items-end {
    align-items: flex-end;
  }

  .md\:dq-items-center {
    align-items: center;
  }

  .md\:dq-items-baseline {
    align-items: baseline;
  }

  .md\:dq-items-stretch {
    align-items: stretch;
  }

  .md\:dq-justify-start {
    justify-content: flex-start;
  }

  .md\:dq-justify-end {
    justify-content: flex-end;
  }

  .md\:dq-justify-center {
    justify-content: center;
  }

  .md\:dq-justify-between {
    justify-content: space-between;
  }

  .md\:dq-justify-around {
    justify-content: space-around;
  }

  .md\:dq-justify-evenly {
    justify-content: space-evenly;
  }

  .md\:dq-justify-items-start {
    justify-items: start;
  }

  .md\:dq-justify-items-end {
    justify-items: end;
  }

  .md\:dq-justify-items-center {
    justify-items: center;
  }

  .md\:dq-justify-items-stretch {
    justify-items: stretch;
  }

  .md\:dq-gap-0 {
    gap: 0px;
  }

  .md\:dq-gap-1 {
    gap: 0.25rem;
  }

  .md\:dq-gap-2 {
    gap: 0.5rem;
  }

  .md\:dq-gap-3 {
    gap: 0.75rem;
  }

  .md\:dq-gap-4 {
    gap: 1rem;
  }

  .md\:dq-gap-5 {
    gap: 1.25rem;
  }

  .md\:dq-gap-6 {
    gap: 1.5rem;
  }

  .md\:dq-gap-7 {
    gap: 1.75rem;
  }

  .md\:dq-gap-8 {
    gap: 2rem;
  }

  .md\:dq-gap-9 {
    gap: 2.25rem;
  }

  .md\:dq-gap-10 {
    gap: 2.5rem;
  }

  .md\:dq-gap-11 {
    gap: 2.75rem;
  }

  .md\:dq-gap-12 {
    gap: 3rem;
  }

  .md\:dq-gap-14 {
    gap: 3.5rem;
  }

  .md\:dq-gap-16 {
    gap: 4rem;
  }

  .md\:dq-gap-20 {
    gap: 5rem;
  }

  .md\:dq-gap-24 {
    gap: 6rem;
  }

  .md\:dq-gap-28 {
    gap: 7rem;
  }

  .md\:dq-gap-32 {
    gap: 8rem;
  }

  .md\:dq-gap-36 {
    gap: 9rem;
  }

  .md\:dq-gap-40 {
    gap: 10rem;
  }

  .md\:dq-gap-44 {
    gap: 11rem;
  }

  .md\:dq-gap-48 {
    gap: 12rem;
  }

  .md\:dq-gap-52 {
    gap: 13rem;
  }

  .md\:dq-gap-56 {
    gap: 14rem;
  }

  .md\:dq-gap-60 {
    gap: 15rem;
  }

  .md\:dq-gap-64 {
    gap: 16rem;
  }

  .md\:dq-gap-72 {
    gap: 18rem;
  }

  .md\:dq-gap-80 {
    gap: 20rem;
  }

  .md\:dq-gap-96 {
    gap: 24rem;
  }

  .md\:dq-gap-px {
    gap: 1px;
  }

  .md\:dq-gap-0\.5 {
    gap: 0.125rem;
  }

  .md\:dq-gap-1\.5 {
    gap: 0.375rem;
  }

  .md\:dq-gap-2\.5 {
    gap: 0.625rem;
  }

  .md\:dq-gap-3\.5 {
    gap: 0.875rem;
  }

  .md\:dq-gap-x-0 {
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .md\:dq-gap-x-1 {
    -webkit-column-gap: 0.25rem;
            column-gap: 0.25rem;
  }

  .md\:dq-gap-x-2 {
    -webkit-column-gap: 0.5rem;
            column-gap: 0.5rem;
  }

  .md\:dq-gap-x-3 {
    -webkit-column-gap: 0.75rem;
            column-gap: 0.75rem;
  }

  .md\:dq-gap-x-4 {
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
  }

  .md\:dq-gap-x-5 {
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
  }

  .md\:dq-gap-x-6 {
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
  }

  .md\:dq-gap-x-7 {
    -webkit-column-gap: 1.75rem;
            column-gap: 1.75rem;
  }

  .md\:dq-gap-x-8 {
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }

  .md\:dq-gap-x-9 {
    -webkit-column-gap: 2.25rem;
            column-gap: 2.25rem;
  }

  .md\:dq-gap-x-10 {
    -webkit-column-gap: 2.5rem;
            column-gap: 2.5rem;
  }

  .md\:dq-gap-x-11 {
    -webkit-column-gap: 2.75rem;
            column-gap: 2.75rem;
  }

  .md\:dq-gap-x-12 {
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }

  .md\:dq-gap-x-14 {
    -webkit-column-gap: 3.5rem;
            column-gap: 3.5rem;
  }

  .md\:dq-gap-x-16 {
    -webkit-column-gap: 4rem;
            column-gap: 4rem;
  }

  .md\:dq-gap-x-20 {
    -webkit-column-gap: 5rem;
            column-gap: 5rem;
  }

  .md\:dq-gap-x-24 {
    -webkit-column-gap: 6rem;
            column-gap: 6rem;
  }

  .md\:dq-gap-x-28 {
    -webkit-column-gap: 7rem;
            column-gap: 7rem;
  }

  .md\:dq-gap-x-32 {
    -webkit-column-gap: 8rem;
            column-gap: 8rem;
  }

  .md\:dq-gap-x-36 {
    -webkit-column-gap: 9rem;
            column-gap: 9rem;
  }

  .md\:dq-gap-x-40 {
    -webkit-column-gap: 10rem;
            column-gap: 10rem;
  }

  .md\:dq-gap-x-44 {
    -webkit-column-gap: 11rem;
            column-gap: 11rem;
  }

  .md\:dq-gap-x-48 {
    -webkit-column-gap: 12rem;
            column-gap: 12rem;
  }

  .md\:dq-gap-x-52 {
    -webkit-column-gap: 13rem;
            column-gap: 13rem;
  }

  .md\:dq-gap-x-56 {
    -webkit-column-gap: 14rem;
            column-gap: 14rem;
  }

  .md\:dq-gap-x-60 {
    -webkit-column-gap: 15rem;
            column-gap: 15rem;
  }

  .md\:dq-gap-x-64 {
    -webkit-column-gap: 16rem;
            column-gap: 16rem;
  }

  .md\:dq-gap-x-72 {
    -webkit-column-gap: 18rem;
            column-gap: 18rem;
  }

  .md\:dq-gap-x-80 {
    -webkit-column-gap: 20rem;
            column-gap: 20rem;
  }

  .md\:dq-gap-x-96 {
    -webkit-column-gap: 24rem;
            column-gap: 24rem;
  }

  .md\:dq-gap-x-px {
    -webkit-column-gap: 1px;
            column-gap: 1px;
  }

  .md\:dq-gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
            column-gap: 0.125rem;
  }

  .md\:dq-gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
            column-gap: 0.375rem;
  }

  .md\:dq-gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
            column-gap: 0.625rem;
  }

  .md\:dq-gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
            column-gap: 0.875rem;
  }

  .md\:dq-gap-y-0 {
    row-gap: 0px;
  }

  .md\:dq-gap-y-1 {
    row-gap: 0.25rem;
  }

  .md\:dq-gap-y-2 {
    row-gap: 0.5rem;
  }

  .md\:dq-gap-y-3 {
    row-gap: 0.75rem;
  }

  .md\:dq-gap-y-4 {
    row-gap: 1rem;
  }

  .md\:dq-gap-y-5 {
    row-gap: 1.25rem;
  }

  .md\:dq-gap-y-6 {
    row-gap: 1.5rem;
  }

  .md\:dq-gap-y-7 {
    row-gap: 1.75rem;
  }

  .md\:dq-gap-y-8 {
    row-gap: 2rem;
  }

  .md\:dq-gap-y-9 {
    row-gap: 2.25rem;
  }

  .md\:dq-gap-y-10 {
    row-gap: 2.5rem;
  }

  .md\:dq-gap-y-11 {
    row-gap: 2.75rem;
  }

  .md\:dq-gap-y-12 {
    row-gap: 3rem;
  }

  .md\:dq-gap-y-14 {
    row-gap: 3.5rem;
  }

  .md\:dq-gap-y-16 {
    row-gap: 4rem;
  }

  .md\:dq-gap-y-20 {
    row-gap: 5rem;
  }

  .md\:dq-gap-y-24 {
    row-gap: 6rem;
  }

  .md\:dq-gap-y-28 {
    row-gap: 7rem;
  }

  .md\:dq-gap-y-32 {
    row-gap: 8rem;
  }

  .md\:dq-gap-y-36 {
    row-gap: 9rem;
  }

  .md\:dq-gap-y-40 {
    row-gap: 10rem;
  }

  .md\:dq-gap-y-44 {
    row-gap: 11rem;
  }

  .md\:dq-gap-y-48 {
    row-gap: 12rem;
  }

  .md\:dq-gap-y-52 {
    row-gap: 13rem;
  }

  .md\:dq-gap-y-56 {
    row-gap: 14rem;
  }

  .md\:dq-gap-y-60 {
    row-gap: 15rem;
  }

  .md\:dq-gap-y-64 {
    row-gap: 16rem;
  }

  .md\:dq-gap-y-72 {
    row-gap: 18rem;
  }

  .md\:dq-gap-y-80 {
    row-gap: 20rem;
  }

  .md\:dq-gap-y-96 {
    row-gap: 24rem;
  }

  .md\:dq-gap-y-px {
    row-gap: 1px;
  }

  .md\:dq-gap-y-0\.5 {
    row-gap: 0.125rem;
  }

  .md\:dq-gap-y-1\.5 {
    row-gap: 0.375rem;
  }

  .md\:dq-gap-y-2\.5 {
    row-gap: 0.625rem;
  }

  .md\:dq-gap-y-3\.5 {
    row-gap: 0.875rem;
  }

  .md\:dq-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq--space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:dq-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }

  .md\:dq--space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }

  .md\:dq-space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .md\:dq-space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .md\:dq-divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:dq-divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:dq-divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:dq-divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:dq-divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:dq-divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .md\:dq-divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .md\:dq-divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .md\:dq-divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .md\:dq-divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .md\:dq-divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .md\:dq-divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .md\:dq-divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .md\:dq-divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .md\:dq-divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .md\:dq-divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .md\:dq-divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .md\:dq-divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }

  .md\:dq-divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .md\:dq-divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .md\:dq-divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .md\:dq-divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .md\:dq-divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .md\:dq-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .md\:dq-divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .md\:dq-divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .md\:dq-divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .md\:dq-divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .md\:dq-divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .md\:dq-divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .md\:dq-divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .md\:dq-divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }

  .md\:dq-divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }

  .md\:dq-divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }

  .md\:dq-divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }

  .md\:dq-divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }

  .md\:dq-divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }

  .md\:dq-divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }

  .md\:dq-divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }

  .md\:dq-divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }

  .md\:dq-divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }

  .md\:dq-divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .md\:dq-divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .md\:dq-divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .md\:dq-divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .md\:dq-divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .md\:dq-divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .md\:dq-divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .md\:dq-divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .md\:dq-divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .md\:dq-divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .md\:dq-divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .md\:dq-divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .md\:dq-divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .md\:dq-divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .md\:dq-divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .md\:dq-divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .md\:dq-divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .md\:dq-divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .md\:dq-divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .md\:dq-divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .md\:dq-divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .md\:dq-divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .md\:dq-divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .md\:dq-divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .md\:dq-divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .md\:dq-divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .md\:dq-divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .md\:dq-divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .md\:dq-divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .md\:dq-divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .md\:dq-divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .md\:dq-divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .md\:dq-divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .md\:dq-divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .md\:dq-divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .md\:dq-divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .md\:dq-divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .md\:dq-divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .md\:dq-divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .md\:dq-divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .md\:dq-divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .md\:dq-divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .md\:dq-divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .md\:dq-divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .md\:dq-divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .md\:dq-divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .md\:dq-divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .md\:dq-divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .md\:dq-divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .md\:dq-divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .md\:dq-divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .md\:dq-divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .md\:dq-divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .md\:dq-divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .md\:dq-divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .md\:dq-divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .md\:dq-divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .md\:dq-divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .md\:dq-divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .md\:dq-divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .md\:dq-divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .md\:dq-divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }

  .md\:dq-divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }

  .md\:dq-divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }

  .md\:dq-divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }

  .md\:dq-divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }

  .md\:dq-divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }

  .md\:dq-divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }

  .md\:dq-divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }

  .md\:dq-divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }

  .md\:dq-divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }

  .md\:dq-divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }

  .md\:dq-divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }

  .md\:dq-divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }

  .md\:dq-divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .md\:dq-place-self-auto {
    place-self: auto;
  }

  .md\:dq-place-self-start {
    place-self: start;
  }

  .md\:dq-place-self-end {
    place-self: end;
  }

  .md\:dq-place-self-center {
    place-self: center;
  }

  .md\:dq-place-self-stretch {
    place-self: stretch;
  }

  .md\:dq-self-auto {
    align-self: auto;
  }

  .md\:dq-self-start {
    align-self: flex-start;
  }

  .md\:dq-self-end {
    align-self: flex-end;
  }

  .md\:dq-self-center {
    align-self: center;
  }

  .md\:dq-self-stretch {
    align-self: stretch;
  }

  .md\:dq-self-baseline {
    align-self: baseline;
  }

  .md\:dq-justify-self-auto {
    justify-self: auto;
  }

  .md\:dq-justify-self-start {
    justify-self: start;
  }

  .md\:dq-justify-self-end {
    justify-self: end;
  }

  .md\:dq-justify-self-center {
    justify-self: center;
  }

  .md\:dq-justify-self-stretch {
    justify-self: stretch;
  }

  .md\:dq-overflow-auto {
    overflow: auto;
  }

  .md\:dq-overflow-hidden {
    overflow: hidden;
  }

  .md\:dq-overflow-visible {
    overflow: visible;
  }

  .md\:dq-overflow-scroll {
    overflow: scroll;
  }

  .md\:dq-overflow-x-auto {
    overflow-x: auto;
  }

  .md\:dq-overflow-y-auto {
    overflow-y: auto;
  }

  .md\:dq-overflow-x-hidden {
    overflow-x: hidden;
  }

  .md\:dq-overflow-y-hidden {
    overflow-y: hidden;
  }

  .md\:dq-overflow-x-visible {
    overflow-x: visible;
  }

  .md\:dq-overflow-y-visible {
    overflow-y: visible;
  }

  .md\:dq-overflow-x-scroll {
    overflow-x: scroll;
  }

  .md\:dq-overflow-y-scroll {
    overflow-y: scroll;
  }

  .md\:dq-overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
  }

  .md\:dq-overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }

  .md\:dq-overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
  }

  .md\:dq-overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .md\:dq-overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .md\:dq-overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .md\:dq-overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .md\:dq-overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .md\:dq-overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .md\:dq-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .md\:dq-overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .md\:dq-overflow-clip {
    text-overflow: clip;
  }

  .md\:dq-whitespace-normal {
    white-space: normal;
  }

  .md\:dq-whitespace-nowrap {
    white-space: nowrap;
  }

  .md\:dq-whitespace-pre {
    white-space: pre;
  }

  .md\:dq-whitespace-pre-line {
    white-space: pre-line;
  }

  .md\:dq-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .md\:dq-break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .md\:dq-break-words {
    overflow-wrap: break-word;
  }

  .md\:dq-break-all {
    word-break: break-all;
  }

  .md\:dq-rounded-none {
    border-radius: 0px;
  }

  .md\:dq-rounded-sm {
    border-radius: 0.125rem;
  }

  .md\:dq-rounded {
    border-radius: 0.25rem;
  }

  .md\:dq-rounded-md {
    border-radius: 0.375rem;
  }

  .md\:dq-rounded-lg {
    border-radius: 0.5rem;
  }

  .md\:dq-rounded-xl {
    border-radius: 0.75rem;
  }

  .md\:dq-rounded-2xl {
    border-radius: 1rem;
  }

  .md\:dq-rounded-3xl {
    border-radius: 1.5rem;
  }

  .md\:dq-rounded-full {
    border-radius: 9999px;
  }

  .md\:dq-rounded-t-none {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .md\:dq-rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .md\:dq-rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .md\:dq-rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .md\:dq-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .md\:dq-rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .md\:dq-rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .md\:dq-rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .md\:dq-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .md\:dq-rounded-r-none {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .md\:dq-rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .md\:dq-rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .md\:dq-rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .md\:dq-rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .md\:dq-rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .md\:dq-rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .md\:dq-rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .md\:dq-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .md\:dq-rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .md\:dq-rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .md\:dq-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .md\:dq-rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .md\:dq-rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .md\:dq-rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .md\:dq-rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:dq-rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:dq-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:dq-rounded-l-none {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .md\:dq-rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .md\:dq-rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .md\:dq-rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .md\:dq-rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .md\:dq-rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .md\:dq-rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:dq-rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:dq-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:dq-rounded-tl-none {
    border-top-left-radius: 0px;
  }

  .md\:dq-rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .md\:dq-rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .md\:dq-rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .md\:dq-rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .md\:dq-rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .md\:dq-rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .md\:dq-rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .md\:dq-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .md\:dq-rounded-tr-none {
    border-top-right-radius: 0px;
  }

  .md\:dq-rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .md\:dq-rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .md\:dq-rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .md\:dq-rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .md\:dq-rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .md\:dq-rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .md\:dq-rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .md\:dq-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .md\:dq-rounded-br-none {
    border-bottom-right-radius: 0px;
  }

  .md\:dq-rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .md\:dq-rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .md\:dq-rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .md\:dq-rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .md\:dq-rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .md\:dq-rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .md\:dq-rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .md\:dq-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .md\:dq-rounded-bl-none {
    border-bottom-left-radius: 0px;
  }

  .md\:dq-rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .md\:dq-rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .md\:dq-rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .md\:dq-rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .md\:dq-rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .md\:dq-rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .md\:dq-rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .md\:dq-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .md\:dq-border-0 {
    border-width: 0px;
  }

  .md\:dq-border-2 {
    border-width: 2px;
  }

  .md\:dq-border-4 {
    border-width: 4px;
  }

  .md\:dq-border-8 {
    border-width: 8px;
  }

  .md\:dq-border {
    border-width: 1px;
  }

  .md\:dq-border-t-0 {
    border-top-width: 0px;
  }

  .md\:dq-border-t-2 {
    border-top-width: 2px;
  }

  .md\:dq-border-t-4 {
    border-top-width: 4px;
  }

  .md\:dq-border-t-8 {
    border-top-width: 8px;
  }

  .md\:dq-border-t {
    border-top-width: 1px;
  }

  .md\:dq-border-r-0 {
    border-right-width: 0px;
  }

  .md\:dq-border-r-2 {
    border-right-width: 2px;
  }

  .md\:dq-border-r-4 {
    border-right-width: 4px;
  }

  .md\:dq-border-r-8 {
    border-right-width: 8px;
  }

  .md\:dq-border-r {
    border-right-width: 1px;
  }

  .md\:dq-border-b-0 {
    border-bottom-width: 0px;
  }

  .md\:dq-border-b-2 {
    border-bottom-width: 2px;
  }

  .md\:dq-border-b-4 {
    border-bottom-width: 4px;
  }

  .md\:dq-border-b-8 {
    border-bottom-width: 8px;
  }

  .md\:dq-border-b {
    border-bottom-width: 1px;
  }

  .md\:dq-border-l-0 {
    border-left-width: 0px;
  }

  .md\:dq-border-l-2 {
    border-left-width: 2px;
  }

  .md\:dq-border-l-4 {
    border-left-width: 4px;
  }

  .md\:dq-border-l-8 {
    border-left-width: 8px;
  }

  .md\:dq-border-l {
    border-left-width: 1px;
  }

  .md\:dq-border-solid {
    border-style: solid;
  }

  .md\:dq-border-dashed {
    border-style: dashed;
  }

  .md\:dq-border-dotted {
    border-style: dotted;
  }

  .md\:dq-border-double {
    border-style: double;
  }

  .md\:dq-border-none {
    border-style: none;
  }

  .md\:dq-border-transparent {
    border-color: transparent;
  }

  .md\:dq-border-current {
    border-color: currentColor;
  }

  .md\:dq-border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md\:dq-border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:dq-border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md\:dq-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md\:dq-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md\:dq-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md\:dq-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md\:dq-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md\:dq-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md\:dq-border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md\:dq-border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md\:dq-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md\:dq-border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .md\:dq-border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .md\:dq-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .md\:dq-border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .md\:dq-border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .md\:dq-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .md\:dq-border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .md\:dq-border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .md\:dq-border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .md\:dq-border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .md\:dq-border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md\:dq-border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md\:dq-border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md\:dq-border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md\:dq-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md\:dq-border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md\:dq-border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md\:dq-border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md\:dq-border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md\:dq-border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md\:dq-border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md\:dq-border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md\:dq-border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md\:dq-border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md\:dq-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md\:dq-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md\:dq-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md\:dq-border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md\:dq-border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md\:dq-border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md\:dq-border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md\:dq-border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md\:dq-border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md\:dq-border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md\:dq-border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md\:dq-border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md\:dq-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md\:dq-border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md\:dq-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md\:dq-border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md\:dq-border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md\:dq-border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md\:dq-border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md\:dq-border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md\:dq-border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md\:dq-border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md\:dq-border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md\:dq-border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md\:dq-border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md\:dq-border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md\:dq-border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md\:dq-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md\:dq-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md\:dq-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md\:dq-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md\:dq-border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md\:dq-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md\:dq-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md\:dq-border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md\:dq-border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md\:dq-border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md\:dq-border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md\:dq-border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md\:dq-border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md\:dq-border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md\:dq-border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md\:dq-border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md\:dq-border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md\:dq-border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md\:dq-border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-transparent {
    border-color: transparent;
  }

  .dq-group:hover .md\:group-hover\:dq-border-current {
    border-color: currentColor;
  }

  .dq-group:hover .md\:group-hover\:dq-border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-transparent:focus-within {
    border-color: transparent;
  }

  .md\:focus-within\:dq-border-current:focus-within {
    border-color: currentColor;
  }

  .md\:focus-within\:dq-border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md\:focus-within\:dq-border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-transparent:hover {
    border-color: transparent;
  }

  .md\:hover\:dq-border-current:hover {
    border-color: currentColor;
  }

  .md\:hover\:dq-border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md\:hover\:dq-border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-transparent:focus {
    border-color: transparent;
  }

  .md\:focus\:dq-border-current:focus {
    border-color: currentColor;
  }

  .md\:focus\:dq-border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md\:focus\:dq-border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md\:dq-border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .md\:dq-border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .md\:dq-border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .md\:dq-border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .md\:dq-border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .md\:dq-border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .md\:dq-border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .md\:dq-border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .md\:dq-border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .md\:dq-border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .md\:dq-border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .md\:dq-border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .md\:dq-border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .md\:dq-border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .md\:dq-border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .dq-group:hover .md\:group-hover\:dq-border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .md\:focus-within\:dq-border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .md\:focus-within\:dq-border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }

  .md\:focus-within\:dq-border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }

  .md\:focus-within\:dq-border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }

  .md\:focus-within\:dq-border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }

  .md\:focus-within\:dq-border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }

  .md\:focus-within\:dq-border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }

  .md\:focus-within\:dq-border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }

  .md\:focus-within\:dq-border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }

  .md\:focus-within\:dq-border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }

  .md\:focus-within\:dq-border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }

  .md\:focus-within\:dq-border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }

  .md\:focus-within\:dq-border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }

  .md\:focus-within\:dq-border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }

  .md\:focus-within\:dq-border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .md\:hover\:dq-border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .md\:hover\:dq-border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }

  .md\:hover\:dq-border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }

  .md\:hover\:dq-border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }

  .md\:hover\:dq-border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }

  .md\:hover\:dq-border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }

  .md\:hover\:dq-border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }

  .md\:hover\:dq-border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }

  .md\:hover\:dq-border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }

  .md\:hover\:dq-border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }

  .md\:hover\:dq-border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }

  .md\:hover\:dq-border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }

  .md\:hover\:dq-border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }

  .md\:hover\:dq-border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }

  .md\:hover\:dq-border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .md\:focus\:dq-border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .md\:focus\:dq-border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }

  .md\:focus\:dq-border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }

  .md\:focus\:dq-border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }

  .md\:focus\:dq-border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }

  .md\:focus\:dq-border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }

  .md\:focus\:dq-border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }

  .md\:focus\:dq-border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }

  .md\:focus\:dq-border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }

  .md\:focus\:dq-border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }

  .md\:focus\:dq-border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }

  .md\:focus\:dq-border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }

  .md\:focus\:dq-border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }

  .md\:focus\:dq-border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }

  .md\:focus\:dq-border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .md\:dq-bg-transparent {
    background-color: transparent;
  }

  .md\:dq-bg-current {
    background-color: currentColor;
  }

  .md\:dq-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md\:dq-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:dq-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md\:dq-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md\:dq-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md\:dq-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md\:dq-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md\:dq-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md\:dq-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md\:dq-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md\:dq-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md\:dq-bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md\:dq-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .md\:dq-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .md\:dq-bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .md\:dq-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .md\:dq-bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .md\:dq-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .md\:dq-bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .md\:dq-bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .md\:dq-bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .md\:dq-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .md\:dq-bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md\:dq-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md\:dq-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md\:dq-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md\:dq-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md\:dq-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md\:dq-bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md\:dq-bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md\:dq-bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md\:dq-bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md\:dq-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md\:dq-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md\:dq-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md\:dq-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md\:dq-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md\:dq-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md\:dq-bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md\:dq-bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md\:dq-bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md\:dq-bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md\:dq-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md\:dq-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md\:dq-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md\:dq-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md\:dq-bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md\:dq-bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md\:dq-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md\:dq-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md\:dq-bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md\:dq-bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md\:dq-bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md\:dq-bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md\:dq-bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md\:dq-bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md\:dq-bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md\:dq-bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md\:dq-bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md\:dq-bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md\:dq-bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md\:dq-bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md\:dq-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md\:dq-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md\:dq-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md\:dq-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md\:dq-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md\:dq-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md\:dq-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md\:dq-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md\:dq-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md\:dq-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md\:dq-bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md\:dq-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md\:dq-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md\:dq-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md\:dq-bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md\:dq-bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md\:dq-bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md\:dq-bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md\:dq-bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md\:dq-bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-transparent {
    background-color: transparent;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-current {
    background-color: currentColor;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-transparent:focus-within {
    background-color: transparent;
  }

  .md\:focus-within\:dq-bg-current:focus-within {
    background-color: currentColor;
  }

  .md\:focus-within\:dq-bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md\:focus-within\:dq-bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-transparent:hover {
    background-color: transparent;
  }

  .md\:hover\:dq-bg-current:hover {
    background-color: currentColor;
  }

  .md\:hover\:dq-bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md\:hover\:dq-bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-transparent:focus {
    background-color: transparent;
  }

  .md\:focus\:dq-bg-current:focus {
    background-color: currentColor;
  }

  .md\:focus\:dq-bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md\:focus\:dq-bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md\:dq-bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .md\:dq-bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .md\:dq-bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .md\:dq-bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .md\:dq-bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .md\:dq-bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .md\:dq-bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .md\:dq-bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .md\:dq-bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .md\:dq-bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .md\:dq-bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .md\:dq-bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .md\:dq-bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .md\:dq-bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .md\:dq-bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .dq-group:hover .md\:group-hover\:dq-bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .md\:focus-within\:dq-bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .md\:focus-within\:dq-bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }

  .md\:focus-within\:dq-bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }

  .md\:focus-within\:dq-bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }

  .md\:focus-within\:dq-bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }

  .md\:focus-within\:dq-bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }

  .md\:focus-within\:dq-bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }

  .md\:focus-within\:dq-bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }

  .md\:focus-within\:dq-bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }

  .md\:focus-within\:dq-bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }

  .md\:focus-within\:dq-bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }

  .md\:focus-within\:dq-bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }

  .md\:focus-within\:dq-bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }

  .md\:focus-within\:dq-bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }

  .md\:focus-within\:dq-bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .md\:hover\:dq-bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .md\:hover\:dq-bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }

  .md\:hover\:dq-bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }

  .md\:hover\:dq-bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }

  .md\:hover\:dq-bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }

  .md\:hover\:dq-bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }

  .md\:hover\:dq-bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }

  .md\:hover\:dq-bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }

  .md\:hover\:dq-bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }

  .md\:hover\:dq-bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }

  .md\:hover\:dq-bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }

  .md\:hover\:dq-bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }

  .md\:hover\:dq-bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }

  .md\:hover\:dq-bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }

  .md\:hover\:dq-bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .md\:focus\:dq-bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .md\:focus\:dq-bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }

  .md\:focus\:dq-bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }

  .md\:focus\:dq-bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }

  .md\:focus\:dq-bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }

  .md\:focus\:dq-bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }

  .md\:focus\:dq-bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }

  .md\:focus\:dq-bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }

  .md\:focus\:dq-bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }

  .md\:focus\:dq-bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }

  .md\:focus\:dq-bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }

  .md\:focus\:dq-bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }

  .md\:focus\:dq-bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }

  .md\:focus\:dq-bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }

  .md\:focus\:dq-bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .md\:dq-bg-none {
    background-image: none;
  }

  .md\:dq-bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .md\:dq-bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .md\:dq-bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .md\:dq-bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .md\:dq-bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .md\:dq-bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .md\:dq-bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .md\:dq-bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .md\:dq-from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:dq-from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:dq-from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:dq-from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:dq-from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .md\:dq-from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .md\:dq-from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .md\:dq-from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .md\:dq-from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .md\:dq-from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .md\:dq-from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .md\:dq-from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .md\:dq-from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .md\:dq-from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .md\:dq-from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .md\:dq-from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .md\:dq-from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .md\:dq-from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .md\:dq-from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .md\:dq-from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .md\:dq-from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .md\:dq-from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .md\:dq-from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .md\:dq-from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .md\:dq-from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .md\:dq-from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .md\:dq-from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .md\:dq-from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .md\:dq-from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .md\:dq-from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .md\:dq-from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .md\:dq-from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .md\:dq-from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .md\:dq-from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .md\:dq-from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .md\:dq-from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .md\:dq-from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .md\:dq-from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .md\:dq-from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .md\:dq-from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .md\:dq-from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .md\:dq-from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .md\:dq-from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .md\:dq-from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .md\:dq-from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .md\:dq-from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .md\:dq-from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .md\:dq-from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .md\:dq-from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .md\:dq-from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .md\:dq-from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .md\:dq-from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .md\:dq-from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .md\:dq-from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .md\:dq-from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .md\:dq-from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .md\:dq-from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .md\:dq-from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .md\:dq-from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .md\:dq-from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .md\:dq-from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .md\:dq-from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .md\:dq-from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .md\:dq-from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .md\:dq-from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .md\:dq-from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .md\:dq-from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .md\:dq-from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .md\:dq-from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .md\:dq-from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .md\:dq-from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .md\:dq-from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .md\:dq-from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .md\:dq-from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .md\:dq-from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .md\:dq-from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .md\:dq-from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .md\:dq-from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .md\:dq-from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .md\:dq-from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .md\:dq-from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .md\:dq-from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .md\:dq-from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .md\:dq-from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .md\:hover\:dq-from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:hover\:dq-from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:dq-from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:hover\:dq-from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:dq-from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .md\:hover\:dq-from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .md\:hover\:dq-from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .md\:hover\:dq-from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .md\:hover\:dq-from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .md\:hover\:dq-from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .md\:hover\:dq-from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .md\:hover\:dq-from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .md\:hover\:dq-from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .md\:hover\:dq-from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .md\:hover\:dq-from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .md\:hover\:dq-from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .md\:hover\:dq-from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .md\:hover\:dq-from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .md\:hover\:dq-from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .md\:hover\:dq-from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .md\:hover\:dq-from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .md\:hover\:dq-from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .md\:hover\:dq-from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .md\:hover\:dq-from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .md\:hover\:dq-from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .md\:hover\:dq-from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .md\:hover\:dq-from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .md\:hover\:dq-from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .md\:hover\:dq-from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .md\:hover\:dq-from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .md\:hover\:dq-from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .md\:hover\:dq-from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .md\:hover\:dq-from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .md\:hover\:dq-from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .md\:hover\:dq-from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .md\:hover\:dq-from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .md\:hover\:dq-from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .md\:hover\:dq-from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .md\:hover\:dq-from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .md\:hover\:dq-from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .md\:hover\:dq-from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .md\:hover\:dq-from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .md\:hover\:dq-from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .md\:hover\:dq-from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .md\:hover\:dq-from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .md\:hover\:dq-from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .md\:hover\:dq-from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .md\:hover\:dq-from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .md\:hover\:dq-from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .md\:hover\:dq-from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .md\:hover\:dq-from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .md\:hover\:dq-from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .md\:hover\:dq-from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .md\:hover\:dq-from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .md\:hover\:dq-from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .md\:hover\:dq-from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .md\:hover\:dq-from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .md\:hover\:dq-from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .md\:hover\:dq-from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .md\:hover\:dq-from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .md\:hover\:dq-from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .md\:hover\:dq-from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .md\:hover\:dq-from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .md\:hover\:dq-from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .md\:hover\:dq-from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .md\:hover\:dq-from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .md\:hover\:dq-from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .md\:hover\:dq-from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .md\:hover\:dq-from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .md\:hover\:dq-from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .md\:hover\:dq-from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .md\:hover\:dq-from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .md\:hover\:dq-from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .md\:hover\:dq-from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .md\:hover\:dq-from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .md\:hover\:dq-from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .md\:hover\:dq-from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .md\:hover\:dq-from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .md\:hover\:dq-from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .md\:hover\:dq-from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .md\:hover\:dq-from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .md\:hover\:dq-from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .md\:hover\:dq-from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .md\:hover\:dq-from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .md\:focus\:dq-from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:focus\:dq-from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:dq-from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:focus\:dq-from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:dq-from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .md\:focus\:dq-from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .md\:focus\:dq-from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .md\:focus\:dq-from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .md\:focus\:dq-from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .md\:focus\:dq-from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .md\:focus\:dq-from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .md\:focus\:dq-from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .md\:focus\:dq-from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .md\:focus\:dq-from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .md\:focus\:dq-from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .md\:focus\:dq-from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .md\:focus\:dq-from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .md\:focus\:dq-from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .md\:focus\:dq-from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .md\:focus\:dq-from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .md\:focus\:dq-from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .md\:focus\:dq-from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .md\:focus\:dq-from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .md\:focus\:dq-from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .md\:focus\:dq-from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .md\:focus\:dq-from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .md\:focus\:dq-from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .md\:focus\:dq-from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .md\:focus\:dq-from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .md\:focus\:dq-from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .md\:focus\:dq-from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .md\:focus\:dq-from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .md\:focus\:dq-from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .md\:focus\:dq-from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .md\:focus\:dq-from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .md\:focus\:dq-from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .md\:focus\:dq-from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .md\:focus\:dq-from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .md\:focus\:dq-from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .md\:focus\:dq-from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .md\:focus\:dq-from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .md\:focus\:dq-from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .md\:focus\:dq-from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .md\:focus\:dq-from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .md\:focus\:dq-from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .md\:focus\:dq-from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .md\:focus\:dq-from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .md\:focus\:dq-from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .md\:focus\:dq-from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .md\:focus\:dq-from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .md\:focus\:dq-from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .md\:focus\:dq-from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .md\:focus\:dq-from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .md\:focus\:dq-from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .md\:focus\:dq-from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .md\:focus\:dq-from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .md\:focus\:dq-from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .md\:focus\:dq-from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .md\:focus\:dq-from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .md\:focus\:dq-from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .md\:focus\:dq-from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .md\:focus\:dq-from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .md\:focus\:dq-from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .md\:focus\:dq-from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .md\:focus\:dq-from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .md\:focus\:dq-from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .md\:focus\:dq-from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .md\:focus\:dq-from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .md\:focus\:dq-from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .md\:focus\:dq-from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .md\:focus\:dq-from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .md\:focus\:dq-from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .md\:focus\:dq-from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .md\:focus\:dq-from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .md\:focus\:dq-from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .md\:focus\:dq-from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .md\:focus\:dq-from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .md\:focus\:dq-from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .md\:focus\:dq-from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .md\:focus\:dq-from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .md\:focus\:dq-from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .md\:focus\:dq-from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .md\:focus\:dq-from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .md\:focus\:dq-from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .md\:dq-via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:dq-via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:dq-via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:dq-via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:dq-via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .md\:dq-via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .md\:dq-via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .md\:dq-via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .md\:dq-via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .md\:dq-via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .md\:dq-via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .md\:dq-via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .md\:dq-via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .md\:dq-via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .md\:dq-via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .md\:dq-via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .md\:dq-via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .md\:dq-via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .md\:dq-via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .md\:dq-via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .md\:dq-via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .md\:dq-via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .md\:dq-via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .md\:dq-via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .md\:dq-via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .md\:dq-via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .md\:dq-via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .md\:dq-via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .md\:dq-via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .md\:dq-via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .md\:dq-via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .md\:dq-via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .md\:dq-via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .md\:dq-via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .md\:dq-via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .md\:dq-via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .md\:dq-via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .md\:dq-via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .md\:dq-via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .md\:dq-via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .md\:dq-via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .md\:dq-via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .md\:dq-via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .md\:dq-via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .md\:dq-via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .md\:dq-via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .md\:dq-via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .md\:dq-via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .md\:dq-via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .md\:dq-via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .md\:dq-via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .md\:dq-via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .md\:dq-via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .md\:dq-via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .md\:dq-via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .md\:dq-via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .md\:dq-via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .md\:dq-via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .md\:dq-via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .md\:dq-via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .md\:dq-via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .md\:dq-via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .md\:dq-via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .md\:dq-via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .md\:dq-via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .md\:dq-via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .md\:dq-via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .md\:dq-via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .md\:dq-via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .md\:dq-via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .md\:dq-via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .md\:dq-via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .md\:dq-via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .md\:dq-via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .md\:dq-via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .md\:dq-via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .md\:dq-via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .md\:dq-via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .md\:dq-via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .md\:dq-via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .md\:dq-via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .md\:dq-via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .md\:dq-via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .md\:dq-via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .md\:hover\:dq-via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:hover\:dq-via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:dq-via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:hover\:dq-via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:dq-via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .md\:hover\:dq-via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .md\:hover\:dq-via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .md\:hover\:dq-via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .md\:hover\:dq-via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .md\:hover\:dq-via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .md\:hover\:dq-via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .md\:hover\:dq-via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .md\:hover\:dq-via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .md\:hover\:dq-via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .md\:hover\:dq-via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .md\:hover\:dq-via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .md\:hover\:dq-via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .md\:hover\:dq-via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .md\:hover\:dq-via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .md\:hover\:dq-via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .md\:hover\:dq-via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .md\:hover\:dq-via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .md\:hover\:dq-via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .md\:hover\:dq-via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .md\:hover\:dq-via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .md\:hover\:dq-via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .md\:hover\:dq-via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .md\:hover\:dq-via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .md\:hover\:dq-via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .md\:hover\:dq-via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .md\:hover\:dq-via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .md\:hover\:dq-via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .md\:hover\:dq-via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .md\:hover\:dq-via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .md\:hover\:dq-via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .md\:hover\:dq-via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .md\:hover\:dq-via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .md\:hover\:dq-via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .md\:hover\:dq-via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .md\:hover\:dq-via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .md\:hover\:dq-via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .md\:hover\:dq-via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .md\:hover\:dq-via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .md\:hover\:dq-via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .md\:hover\:dq-via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .md\:hover\:dq-via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .md\:hover\:dq-via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .md\:hover\:dq-via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .md\:hover\:dq-via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .md\:hover\:dq-via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .md\:hover\:dq-via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .md\:hover\:dq-via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .md\:hover\:dq-via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .md\:hover\:dq-via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .md\:hover\:dq-via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .md\:hover\:dq-via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .md\:hover\:dq-via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .md\:hover\:dq-via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .md\:hover\:dq-via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .md\:hover\:dq-via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .md\:hover\:dq-via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .md\:hover\:dq-via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .md\:hover\:dq-via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .md\:hover\:dq-via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .md\:hover\:dq-via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .md\:hover\:dq-via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .md\:hover\:dq-via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .md\:hover\:dq-via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .md\:hover\:dq-via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .md\:hover\:dq-via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .md\:hover\:dq-via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .md\:hover\:dq-via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .md\:hover\:dq-via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .md\:hover\:dq-via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .md\:hover\:dq-via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .md\:hover\:dq-via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .md\:hover\:dq-via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .md\:hover\:dq-via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .md\:hover\:dq-via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .md\:hover\:dq-via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .md\:hover\:dq-via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .md\:hover\:dq-via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .md\:hover\:dq-via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .md\:hover\:dq-via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .md\:focus\:dq-via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:focus\:dq-via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:dq-via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:focus\:dq-via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:dq-via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .md\:focus\:dq-via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .md\:focus\:dq-via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .md\:focus\:dq-via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .md\:focus\:dq-via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .md\:focus\:dq-via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .md\:focus\:dq-via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .md\:focus\:dq-via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .md\:focus\:dq-via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .md\:focus\:dq-via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .md\:focus\:dq-via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .md\:focus\:dq-via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .md\:focus\:dq-via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .md\:focus\:dq-via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .md\:focus\:dq-via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .md\:focus\:dq-via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .md\:focus\:dq-via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .md\:focus\:dq-via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .md\:focus\:dq-via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .md\:focus\:dq-via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .md\:focus\:dq-via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .md\:focus\:dq-via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .md\:focus\:dq-via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .md\:focus\:dq-via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .md\:focus\:dq-via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .md\:focus\:dq-via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .md\:focus\:dq-via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .md\:focus\:dq-via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .md\:focus\:dq-via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .md\:focus\:dq-via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .md\:focus\:dq-via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .md\:focus\:dq-via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .md\:focus\:dq-via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .md\:focus\:dq-via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .md\:focus\:dq-via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .md\:focus\:dq-via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .md\:focus\:dq-via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .md\:focus\:dq-via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .md\:focus\:dq-via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .md\:focus\:dq-via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .md\:focus\:dq-via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .md\:focus\:dq-via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .md\:focus\:dq-via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .md\:focus\:dq-via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .md\:focus\:dq-via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .md\:focus\:dq-via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .md\:focus\:dq-via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .md\:focus\:dq-via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .md\:focus\:dq-via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .md\:focus\:dq-via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .md\:focus\:dq-via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .md\:focus\:dq-via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .md\:focus\:dq-via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .md\:focus\:dq-via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .md\:focus\:dq-via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .md\:focus\:dq-via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .md\:focus\:dq-via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .md\:focus\:dq-via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .md\:focus\:dq-via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .md\:focus\:dq-via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .md\:focus\:dq-via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .md\:focus\:dq-via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .md\:focus\:dq-via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .md\:focus\:dq-via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .md\:focus\:dq-via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .md\:focus\:dq-via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .md\:focus\:dq-via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .md\:focus\:dq-via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .md\:focus\:dq-via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .md\:focus\:dq-via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .md\:focus\:dq-via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .md\:focus\:dq-via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .md\:focus\:dq-via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .md\:focus\:dq-via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .md\:focus\:dq-via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .md\:focus\:dq-via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .md\:focus\:dq-via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .md\:focus\:dq-via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .md\:focus\:dq-via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .md\:focus\:dq-via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .md\:dq-to-transparent {
    --tw-gradient-to: transparent;
  }

  .md\:dq-to-current {
    --tw-gradient-to: currentColor;
  }

  .md\:dq-to-black {
    --tw-gradient-to: #000;
  }

  .md\:dq-to-white {
    --tw-gradient-to: #fff;
  }

  .md\:dq-to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .md\:dq-to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .md\:dq-to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .md\:dq-to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .md\:dq-to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .md\:dq-to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .md\:dq-to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .md\:dq-to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .md\:dq-to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .md\:dq-to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .md\:dq-to-red-50 {
    --tw-gradient-to: #fef2f2;
  }

  .md\:dq-to-red-100 {
    --tw-gradient-to: #fee2e2;
  }

  .md\:dq-to-red-200 {
    --tw-gradient-to: #fecaca;
  }

  .md\:dq-to-red-300 {
    --tw-gradient-to: #fca5a5;
  }

  .md\:dq-to-red-400 {
    --tw-gradient-to: #f87171;
  }

  .md\:dq-to-red-500 {
    --tw-gradient-to: #ef4444;
  }

  .md\:dq-to-red-600 {
    --tw-gradient-to: #dc2626;
  }

  .md\:dq-to-red-700 {
    --tw-gradient-to: #b91c1c;
  }

  .md\:dq-to-red-800 {
    --tw-gradient-to: #991b1b;
  }

  .md\:dq-to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }

  .md\:dq-to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .md\:dq-to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .md\:dq-to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .md\:dq-to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .md\:dq-to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .md\:dq-to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .md\:dq-to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .md\:dq-to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .md\:dq-to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .md\:dq-to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .md\:dq-to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .md\:dq-to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .md\:dq-to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .md\:dq-to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .md\:dq-to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .md\:dq-to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .md\:dq-to-green-600 {
    --tw-gradient-to: #059669;
  }

  .md\:dq-to-green-700 {
    --tw-gradient-to: #047857;
  }

  .md\:dq-to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .md\:dq-to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .md\:dq-to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .md\:dq-to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .md\:dq-to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .md\:dq-to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .md\:dq-to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .md\:dq-to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .md\:dq-to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .md\:dq-to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .md\:dq-to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .md\:dq-to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .md\:dq-to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .md\:dq-to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .md\:dq-to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .md\:dq-to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .md\:dq-to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .md\:dq-to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .md\:dq-to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .md\:dq-to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .md\:dq-to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .md\:dq-to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .md\:dq-to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .md\:dq-to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .md\:dq-to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .md\:dq-to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .md\:dq-to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .md\:dq-to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .md\:dq-to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .md\:dq-to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .md\:dq-to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .md\:dq-to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .md\:dq-to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .md\:dq-to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .md\:dq-to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .md\:dq-to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .md\:dq-to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .md\:dq-to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .md\:dq-to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .md\:dq-to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .md\:dq-to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .md\:dq-to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .md\:hover\:dq-to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .md\:hover\:dq-to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .md\:hover\:dq-to-black:hover {
    --tw-gradient-to: #000;
  }

  .md\:hover\:dq-to-white:hover {
    --tw-gradient-to: #fff;
  }

  .md\:hover\:dq-to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .md\:hover\:dq-to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .md\:hover\:dq-to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .md\:hover\:dq-to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .md\:hover\:dq-to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .md\:hover\:dq-to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .md\:hover\:dq-to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .md\:hover\:dq-to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .md\:hover\:dq-to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .md\:hover\:dq-to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .md\:hover\:dq-to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }

  .md\:hover\:dq-to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }

  .md\:hover\:dq-to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }

  .md\:hover\:dq-to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }

  .md\:hover\:dq-to-red-400:hover {
    --tw-gradient-to: #f87171;
  }

  .md\:hover\:dq-to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }

  .md\:hover\:dq-to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }

  .md\:hover\:dq-to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }

  .md\:hover\:dq-to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }

  .md\:hover\:dq-to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }

  .md\:hover\:dq-to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .md\:hover\:dq-to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .md\:hover\:dq-to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .md\:hover\:dq-to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .md\:hover\:dq-to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .md\:hover\:dq-to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .md\:hover\:dq-to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .md\:hover\:dq-to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .md\:hover\:dq-to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .md\:hover\:dq-to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .md\:hover\:dq-to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .md\:hover\:dq-to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .md\:hover\:dq-to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .md\:hover\:dq-to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .md\:hover\:dq-to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .md\:hover\:dq-to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .md\:hover\:dq-to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .md\:hover\:dq-to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .md\:hover\:dq-to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .md\:hover\:dq-to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .md\:hover\:dq-to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .md\:hover\:dq-to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .md\:hover\:dq-to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .md\:hover\:dq-to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .md\:hover\:dq-to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .md\:hover\:dq-to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .md\:hover\:dq-to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .md\:hover\:dq-to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .md\:hover\:dq-to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .md\:hover\:dq-to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .md\:hover\:dq-to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .md\:hover\:dq-to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .md\:hover\:dq-to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .md\:hover\:dq-to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .md\:hover\:dq-to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .md\:hover\:dq-to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .md\:hover\:dq-to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .md\:hover\:dq-to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .md\:hover\:dq-to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .md\:hover\:dq-to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .md\:hover\:dq-to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .md\:hover\:dq-to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .md\:hover\:dq-to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .md\:hover\:dq-to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .md\:hover\:dq-to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .md\:hover\:dq-to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .md\:hover\:dq-to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .md\:hover\:dq-to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .md\:hover\:dq-to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .md\:hover\:dq-to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .md\:hover\:dq-to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .md\:hover\:dq-to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .md\:hover\:dq-to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .md\:hover\:dq-to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .md\:hover\:dq-to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .md\:hover\:dq-to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .md\:hover\:dq-to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .md\:hover\:dq-to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .md\:hover\:dq-to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .md\:hover\:dq-to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .md\:focus\:dq-to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .md\:focus\:dq-to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .md\:focus\:dq-to-black:focus {
    --tw-gradient-to: #000;
  }

  .md\:focus\:dq-to-white:focus {
    --tw-gradient-to: #fff;
  }

  .md\:focus\:dq-to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .md\:focus\:dq-to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .md\:focus\:dq-to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .md\:focus\:dq-to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .md\:focus\:dq-to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .md\:focus\:dq-to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .md\:focus\:dq-to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .md\:focus\:dq-to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .md\:focus\:dq-to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .md\:focus\:dq-to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .md\:focus\:dq-to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }

  .md\:focus\:dq-to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }

  .md\:focus\:dq-to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }

  .md\:focus\:dq-to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }

  .md\:focus\:dq-to-red-400:focus {
    --tw-gradient-to: #f87171;
  }

  .md\:focus\:dq-to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }

  .md\:focus\:dq-to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }

  .md\:focus\:dq-to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }

  .md\:focus\:dq-to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }

  .md\:focus\:dq-to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }

  .md\:focus\:dq-to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .md\:focus\:dq-to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .md\:focus\:dq-to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .md\:focus\:dq-to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .md\:focus\:dq-to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .md\:focus\:dq-to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .md\:focus\:dq-to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .md\:focus\:dq-to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .md\:focus\:dq-to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .md\:focus\:dq-to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .md\:focus\:dq-to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .md\:focus\:dq-to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .md\:focus\:dq-to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .md\:focus\:dq-to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .md\:focus\:dq-to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .md\:focus\:dq-to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .md\:focus\:dq-to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .md\:focus\:dq-to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .md\:focus\:dq-to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .md\:focus\:dq-to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .md\:focus\:dq-to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .md\:focus\:dq-to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .md\:focus\:dq-to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .md\:focus\:dq-to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .md\:focus\:dq-to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .md\:focus\:dq-to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .md\:focus\:dq-to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .md\:focus\:dq-to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .md\:focus\:dq-to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .md\:focus\:dq-to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .md\:focus\:dq-to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .md\:focus\:dq-to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .md\:focus\:dq-to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .md\:focus\:dq-to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .md\:focus\:dq-to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .md\:focus\:dq-to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .md\:focus\:dq-to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .md\:focus\:dq-to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .md\:focus\:dq-to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .md\:focus\:dq-to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .md\:focus\:dq-to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .md\:focus\:dq-to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .md\:focus\:dq-to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .md\:focus\:dq-to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .md\:focus\:dq-to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .md\:focus\:dq-to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .md\:focus\:dq-to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .md\:focus\:dq-to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .md\:focus\:dq-to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .md\:focus\:dq-to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .md\:focus\:dq-to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .md\:focus\:dq-to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .md\:focus\:dq-to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .md\:focus\:dq-to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .md\:focus\:dq-to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .md\:focus\:dq-to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .md\:focus\:dq-to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .md\:focus\:dq-to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .md\:focus\:dq-to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .md\:focus\:dq-to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .md\:dq-decoration-slice {
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
  }

  .md\:dq-decoration-clone {
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
  }

  .md\:dq-bg-auto {
    background-size: auto;
  }

  .md\:dq-bg-cover {
    background-size: cover;
  }

  .md\:dq-bg-contain {
    background-size: contain;
  }

  .md\:dq-bg-fixed {
    background-attachment: fixed;
  }

  .md\:dq-bg-local {
    background-attachment: local;
  }

  .md\:dq-bg-scroll {
    background-attachment: scroll;
  }

  .md\:dq-bg-clip-border {
    background-clip: border-box;
  }

  .md\:dq-bg-clip-padding {
    background-clip: padding-box;
  }

  .md\:dq-bg-clip-content {
    background-clip: content-box;
  }

  .md\:dq-bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .md\:dq-bg-bottom {
    background-position: bottom;
  }

  .md\:dq-bg-center {
    background-position: center;
  }

  .md\:dq-bg-left {
    background-position: left;
  }

  .md\:dq-bg-left-bottom {
    background-position: left bottom;
  }

  .md\:dq-bg-left-top {
    background-position: left top;
  }

  .md\:dq-bg-right {
    background-position: right;
  }

  .md\:dq-bg-right-bottom {
    background-position: right bottom;
  }

  .md\:dq-bg-right-top {
    background-position: right top;
  }

  .md\:dq-bg-top {
    background-position: top;
  }

  .md\:dq-bg-repeat {
    background-repeat: repeat;
  }

  .md\:dq-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .md\:dq-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .md\:dq-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .md\:dq-bg-repeat-round {
    background-repeat: round;
  }

  .md\:dq-bg-repeat-space {
    background-repeat: space;
  }

  .md\:dq-bg-origin-border {
    background-origin: border-box;
  }

  .md\:dq-bg-origin-padding {
    background-origin: padding-box;
  }

  .md\:dq-bg-origin-content {
    background-origin: content-box;
  }

  .md\:dq-fill-current {
    fill: currentColor;
  }

  .md\:dq-stroke-current {
    stroke: currentColor;
  }

  .md\:dq-stroke-0 {
    stroke-width: 0;
  }

  .md\:dq-stroke-1 {
    stroke-width: 1;
  }

  .md\:dq-stroke-2 {
    stroke-width: 2;
  }

  .md\:dq-object-contain {
    object-fit: contain;
  }

  .md\:dq-object-cover {
    object-fit: cover;
  }

  .md\:dq-object-fill {
    object-fit: fill;
  }

  .md\:dq-object-none {
    object-fit: none;
  }

  .md\:dq-object-scale-down {
    object-fit: scale-down;
  }

  .md\:dq-object-bottom {
    object-position: bottom;
  }

  .md\:dq-object-center {
    object-position: center;
  }

  .md\:dq-object-left {
    object-position: left;
  }

  .md\:dq-object-left-bottom {
    object-position: left bottom;
  }

  .md\:dq-object-left-top {
    object-position: left top;
  }

  .md\:dq-object-right {
    object-position: right;
  }

  .md\:dq-object-right-bottom {
    object-position: right bottom;
  }

  .md\:dq-object-right-top {
    object-position: right top;
  }

  .md\:dq-object-top {
    object-position: top;
  }

  .md\:dq-p-0 {
    padding: 0px;
  }

  .md\:dq-p-1 {
    padding: 0.25rem;
  }

  .md\:dq-p-2 {
    padding: 0.5rem;
  }

  .md\:dq-p-3 {
    padding: 0.75rem;
  }

  .md\:dq-p-4 {
    padding: 1rem;
  }

  .md\:dq-p-5 {
    padding: 1.25rem;
  }

  .md\:dq-p-6 {
    padding: 1.5rem;
  }

  .md\:dq-p-7 {
    padding: 1.75rem;
  }

  .md\:dq-p-8 {
    padding: 2rem;
  }

  .md\:dq-p-9 {
    padding: 2.25rem;
  }

  .md\:dq-p-10 {
    padding: 2.5rem;
  }

  .md\:dq-p-11 {
    padding: 2.75rem;
  }

  .md\:dq-p-12 {
    padding: 3rem;
  }

  .md\:dq-p-14 {
    padding: 3.5rem;
  }

  .md\:dq-p-16 {
    padding: 4rem;
  }

  .md\:dq-p-20 {
    padding: 5rem;
  }

  .md\:dq-p-24 {
    padding: 6rem;
  }

  .md\:dq-p-28 {
    padding: 7rem;
  }

  .md\:dq-p-32 {
    padding: 8rem;
  }

  .md\:dq-p-36 {
    padding: 9rem;
  }

  .md\:dq-p-40 {
    padding: 10rem;
  }

  .md\:dq-p-44 {
    padding: 11rem;
  }

  .md\:dq-p-48 {
    padding: 12rem;
  }

  .md\:dq-p-52 {
    padding: 13rem;
  }

  .md\:dq-p-56 {
    padding: 14rem;
  }

  .md\:dq-p-60 {
    padding: 15rem;
  }

  .md\:dq-p-64 {
    padding: 16rem;
  }

  .md\:dq-p-72 {
    padding: 18rem;
  }

  .md\:dq-p-80 {
    padding: 20rem;
  }

  .md\:dq-p-96 {
    padding: 24rem;
  }

  .md\:dq-p-px {
    padding: 1px;
  }

  .md\:dq-p-0\.5 {
    padding: 0.125rem;
  }

  .md\:dq-p-1\.5 {
    padding: 0.375rem;
  }

  .md\:dq-p-2\.5 {
    padding: 0.625rem;
  }

  .md\:dq-p-3\.5 {
    padding: 0.875rem;
  }

  .md\:dq-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:dq-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .md\:dq-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .md\:dq-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .md\:dq-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:dq-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:dq-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:dq-px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:dq-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:dq-px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .md\:dq-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:dq-px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .md\:dq-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:dq-px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .md\:dq-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:dq-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:dq-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:dq-px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .md\:dq-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:dq-px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .md\:dq-px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .md\:dq-px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .md\:dq-px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .md\:dq-px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .md\:dq-px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .md\:dq-px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .md\:dq-px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .md\:dq-px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .md\:dq-px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .md\:dq-px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .md\:dq-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .md\:dq-px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }

  .md\:dq-px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }

  .md\:dq-px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .md\:dq-px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }

  .md\:dq-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .md\:dq-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .md\:dq-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .md\:dq-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .md\:dq-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:dq-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:dq-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:dq-py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .md\:dq-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:dq-py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .md\:dq-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:dq-py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .md\:dq-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:dq-py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .md\:dq-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:dq-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:dq-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:dq-py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .md\:dq-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:dq-py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .md\:dq-py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .md\:dq-py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .md\:dq-py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .md\:dq-py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .md\:dq-py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .md\:dq-py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .md\:dq-py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .md\:dq-py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .md\:dq-py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .md\:dq-py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .md\:dq-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .md\:dq-py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }

  .md\:dq-py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  .md\:dq-py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .md\:dq-py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  .md\:dq-pt-0 {
    padding-top: 0px;
  }

  .md\:dq-pt-1 {
    padding-top: 0.25rem;
  }

  .md\:dq-pt-2 {
    padding-top: 0.5rem;
  }

  .md\:dq-pt-3 {
    padding-top: 0.75rem;
  }

  .md\:dq-pt-4 {
    padding-top: 1rem;
  }

  .md\:dq-pt-5 {
    padding-top: 1.25rem;
  }

  .md\:dq-pt-6 {
    padding-top: 1.5rem;
  }

  .md\:dq-pt-7 {
    padding-top: 1.75rem;
  }

  .md\:dq-pt-8 {
    padding-top: 2rem;
  }

  .md\:dq-pt-9 {
    padding-top: 2.25rem;
  }

  .md\:dq-pt-10 {
    padding-top: 2.5rem;
  }

  .md\:dq-pt-11 {
    padding-top: 2.75rem;
  }

  .md\:dq-pt-12 {
    padding-top: 3rem;
  }

  .md\:dq-pt-14 {
    padding-top: 3.5rem;
  }

  .md\:dq-pt-16 {
    padding-top: 4rem;
  }

  .md\:dq-pt-20 {
    padding-top: 5rem;
  }

  .md\:dq-pt-24 {
    padding-top: 6rem;
  }

  .md\:dq-pt-28 {
    padding-top: 7rem;
  }

  .md\:dq-pt-32 {
    padding-top: 8rem;
  }

  .md\:dq-pt-36 {
    padding-top: 9rem;
  }

  .md\:dq-pt-40 {
    padding-top: 10rem;
  }

  .md\:dq-pt-44 {
    padding-top: 11rem;
  }

  .md\:dq-pt-48 {
    padding-top: 12rem;
  }

  .md\:dq-pt-52 {
    padding-top: 13rem;
  }

  .md\:dq-pt-56 {
    padding-top: 14rem;
  }

  .md\:dq-pt-60 {
    padding-top: 15rem;
  }

  .md\:dq-pt-64 {
    padding-top: 16rem;
  }

  .md\:dq-pt-72 {
    padding-top: 18rem;
  }

  .md\:dq-pt-80 {
    padding-top: 20rem;
  }

  .md\:dq-pt-96 {
    padding-top: 24rem;
  }

  .md\:dq-pt-px {
    padding-top: 1px;
  }

  .md\:dq-pt-0\.5 {
    padding-top: 0.125rem;
  }

  .md\:dq-pt-1\.5 {
    padding-top: 0.375rem;
  }

  .md\:dq-pt-2\.5 {
    padding-top: 0.625rem;
  }

  .md\:dq-pt-3\.5 {
    padding-top: 0.875rem;
  }

  .md\:dq-pr-0 {
    padding-right: 0px;
  }

  .md\:dq-pr-1 {
    padding-right: 0.25rem;
  }

  .md\:dq-pr-2 {
    padding-right: 0.5rem;
  }

  .md\:dq-pr-3 {
    padding-right: 0.75rem;
  }

  .md\:dq-pr-4 {
    padding-right: 1rem;
  }

  .md\:dq-pr-5 {
    padding-right: 1.25rem;
  }

  .md\:dq-pr-6 {
    padding-right: 1.5rem;
  }

  .md\:dq-pr-7 {
    padding-right: 1.75rem;
  }

  .md\:dq-pr-8 {
    padding-right: 2rem;
  }

  .md\:dq-pr-9 {
    padding-right: 2.25rem;
  }

  .md\:dq-pr-10 {
    padding-right: 2.5rem;
  }

  .md\:dq-pr-11 {
    padding-right: 2.75rem;
  }

  .md\:dq-pr-12 {
    padding-right: 3rem;
  }

  .md\:dq-pr-14 {
    padding-right: 3.5rem;
  }

  .md\:dq-pr-16 {
    padding-right: 4rem;
  }

  .md\:dq-pr-20 {
    padding-right: 5rem;
  }

  .md\:dq-pr-24 {
    padding-right: 6rem;
  }

  .md\:dq-pr-28 {
    padding-right: 7rem;
  }

  .md\:dq-pr-32 {
    padding-right: 8rem;
  }

  .md\:dq-pr-36 {
    padding-right: 9rem;
  }

  .md\:dq-pr-40 {
    padding-right: 10rem;
  }

  .md\:dq-pr-44 {
    padding-right: 11rem;
  }

  .md\:dq-pr-48 {
    padding-right: 12rem;
  }

  .md\:dq-pr-52 {
    padding-right: 13rem;
  }

  .md\:dq-pr-56 {
    padding-right: 14rem;
  }

  .md\:dq-pr-60 {
    padding-right: 15rem;
  }

  .md\:dq-pr-64 {
    padding-right: 16rem;
  }

  .md\:dq-pr-72 {
    padding-right: 18rem;
  }

  .md\:dq-pr-80 {
    padding-right: 20rem;
  }

  .md\:dq-pr-96 {
    padding-right: 24rem;
  }

  .md\:dq-pr-px {
    padding-right: 1px;
  }

  .md\:dq-pr-0\.5 {
    padding-right: 0.125rem;
  }

  .md\:dq-pr-1\.5 {
    padding-right: 0.375rem;
  }

  .md\:dq-pr-2\.5 {
    padding-right: 0.625rem;
  }

  .md\:dq-pr-3\.5 {
    padding-right: 0.875rem;
  }

  .md\:dq-pb-0 {
    padding-bottom: 0px;
  }

  .md\:dq-pb-1 {
    padding-bottom: 0.25rem;
  }

  .md\:dq-pb-2 {
    padding-bottom: 0.5rem;
  }

  .md\:dq-pb-3 {
    padding-bottom: 0.75rem;
  }

  .md\:dq-pb-4 {
    padding-bottom: 1rem;
  }

  .md\:dq-pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:dq-pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:dq-pb-7 {
    padding-bottom: 1.75rem;
  }

  .md\:dq-pb-8 {
    padding-bottom: 2rem;
  }

  .md\:dq-pb-9 {
    padding-bottom: 2.25rem;
  }

  .md\:dq-pb-10 {
    padding-bottom: 2.5rem;
  }

  .md\:dq-pb-11 {
    padding-bottom: 2.75rem;
  }

  .md\:dq-pb-12 {
    padding-bottom: 3rem;
  }

  .md\:dq-pb-14 {
    padding-bottom: 3.5rem;
  }

  .md\:dq-pb-16 {
    padding-bottom: 4rem;
  }

  .md\:dq-pb-20 {
    padding-bottom: 5rem;
  }

  .md\:dq-pb-24 {
    padding-bottom: 6rem;
  }

  .md\:dq-pb-28 {
    padding-bottom: 7rem;
  }

  .md\:dq-pb-32 {
    padding-bottom: 8rem;
  }

  .md\:dq-pb-36 {
    padding-bottom: 9rem;
  }

  .md\:dq-pb-40 {
    padding-bottom: 10rem;
  }

  .md\:dq-pb-44 {
    padding-bottom: 11rem;
  }

  .md\:dq-pb-48 {
    padding-bottom: 12rem;
  }

  .md\:dq-pb-52 {
    padding-bottom: 13rem;
  }

  .md\:dq-pb-56 {
    padding-bottom: 14rem;
  }

  .md\:dq-pb-60 {
    padding-bottom: 15rem;
  }

  .md\:dq-pb-64 {
    padding-bottom: 16rem;
  }

  .md\:dq-pb-72 {
    padding-bottom: 18rem;
  }

  .md\:dq-pb-80 {
    padding-bottom: 20rem;
  }

  .md\:dq-pb-96 {
    padding-bottom: 24rem;
  }

  .md\:dq-pb-px {
    padding-bottom: 1px;
  }

  .md\:dq-pb-0\.5 {
    padding-bottom: 0.125rem;
  }

  .md\:dq-pb-1\.5 {
    padding-bottom: 0.375rem;
  }

  .md\:dq-pb-2\.5 {
    padding-bottom: 0.625rem;
  }

  .md\:dq-pb-3\.5 {
    padding-bottom: 0.875rem;
  }

  .md\:dq-pl-0 {
    padding-left: 0px;
  }

  .md\:dq-pl-1 {
    padding-left: 0.25rem;
  }

  .md\:dq-pl-2 {
    padding-left: 0.5rem;
  }

  .md\:dq-pl-3 {
    padding-left: 0.75rem;
  }

  .md\:dq-pl-4 {
    padding-left: 1rem;
  }

  .md\:dq-pl-5 {
    padding-left: 1.25rem;
  }

  .md\:dq-pl-6 {
    padding-left: 1.5rem;
  }

  .md\:dq-pl-7 {
    padding-left: 1.75rem;
  }

  .md\:dq-pl-8 {
    padding-left: 2rem;
  }

  .md\:dq-pl-9 {
    padding-left: 2.25rem;
  }

  .md\:dq-pl-10 {
    padding-left: 2.5rem;
  }

  .md\:dq-pl-11 {
    padding-left: 2.75rem;
  }

  .md\:dq-pl-12 {
    padding-left: 3rem;
  }

  .md\:dq-pl-14 {
    padding-left: 3.5rem;
  }

  .md\:dq-pl-16 {
    padding-left: 4rem;
  }

  .md\:dq-pl-20 {
    padding-left: 5rem;
  }

  .md\:dq-pl-24 {
    padding-left: 6rem;
  }

  .md\:dq-pl-28 {
    padding-left: 7rem;
  }

  .md\:dq-pl-32 {
    padding-left: 8rem;
  }

  .md\:dq-pl-36 {
    padding-left: 9rem;
  }

  .md\:dq-pl-40 {
    padding-left: 10rem;
  }

  .md\:dq-pl-44 {
    padding-left: 11rem;
  }

  .md\:dq-pl-48 {
    padding-left: 12rem;
  }

  .md\:dq-pl-52 {
    padding-left: 13rem;
  }

  .md\:dq-pl-56 {
    padding-left: 14rem;
  }

  .md\:dq-pl-60 {
    padding-left: 15rem;
  }

  .md\:dq-pl-64 {
    padding-left: 16rem;
  }

  .md\:dq-pl-72 {
    padding-left: 18rem;
  }

  .md\:dq-pl-80 {
    padding-left: 20rem;
  }

  .md\:dq-pl-96 {
    padding-left: 24rem;
  }

  .md\:dq-pl-px {
    padding-left: 1px;
  }

  .md\:dq-pl-0\.5 {
    padding-left: 0.125rem;
  }

  .md\:dq-pl-1\.5 {
    padding-left: 0.375rem;
  }

  .md\:dq-pl-2\.5 {
    padding-left: 0.625rem;
  }

  .md\:dq-pl-3\.5 {
    padding-left: 0.875rem;
  }

  .md\:dq-text-left {
    text-align: left;
  }

  .md\:dq-text-center {
    text-align: center;
  }

  .md\:dq-text-right {
    text-align: right;
  }

  .md\:dq-text-justify {
    text-align: justify;
  }

  .md\:dq-align-baseline {
    vertical-align: baseline;
  }

  .md\:dq-align-top {
    vertical-align: top;
  }

  .md\:dq-align-middle {
    vertical-align: middle;
  }

  .md\:dq-align-bottom {
    vertical-align: bottom;
  }

  .md\:dq-align-text-top {
    vertical-align: text-top;
  }

  .md\:dq-align-text-bottom {
    vertical-align: text-bottom;
  }

  .md\:dq-font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .md\:dq-font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .md\:dq-font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .md\:dq-text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .md\:dq-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .md\:dq-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:dq-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:dq-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:dq-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:dq-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:dq-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:dq-text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:dq-text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:dq-text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md\:dq-text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .md\:dq-text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .md\:dq-font-thin {
    font-weight: 100;
  }

  .md\:dq-font-extralight {
    font-weight: 200;
  }

  .md\:dq-font-light {
    font-weight: 300;
  }

  .md\:dq-font-normal {
    font-weight: 400;
  }

  .md\:dq-font-medium {
    font-weight: 500;
  }

  .md\:dq-font-semibold {
    font-weight: 600;
  }

  .md\:dq-font-bold {
    font-weight: 700;
  }

  .md\:dq-font-extrabold {
    font-weight: 800;
  }

  .md\:dq-font-black {
    font-weight: 900;
  }

  .md\:dq-uppercase {
    text-transform: uppercase;
  }

  .md\:dq-lowercase {
    text-transform: lowercase;
  }

  .md\:dq-capitalize {
    text-transform: capitalize;
  }

  .md\:dq-normal-case {
    text-transform: none;
  }

  .md\:dq-italic {
    font-style: italic;
  }

  .md\:dq-not-italic {
    font-style: normal;
  }

  .md\:dq-ordinal, .md\:dq-slashed-zero, .md\:dq-lining-nums, .md\:dq-oldstyle-nums, .md\:dq-proportional-nums, .md\:dq-tabular-nums, .md\:dq-diagonal-fractions, .md\:dq-stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:dq-normal-nums {
    font-variant-numeric: normal;
  }

  .md\:dq-ordinal {
    --tw-ordinal: ordinal;
  }

  .md\:dq-slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .md\:dq-lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .md\:dq-oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .md\:dq-proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .md\:dq-tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .md\:dq-diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .md\:dq-stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .md\:dq-leading-3 {
    line-height: .75rem;
  }

  .md\:dq-leading-4 {
    line-height: 1rem;
  }

  .md\:dq-leading-5 {
    line-height: 1.25rem;
  }

  .md\:dq-leading-6 {
    line-height: 1.5rem;
  }

  .md\:dq-leading-7 {
    line-height: 1.75rem;
  }

  .md\:dq-leading-8 {
    line-height: 2rem;
  }

  .md\:dq-leading-9 {
    line-height: 2.25rem;
  }

  .md\:dq-leading-10 {
    line-height: 2.5rem;
  }

  .md\:dq-leading-none {
    line-height: 1;
  }

  .md\:dq-leading-tight {
    line-height: 1.25;
  }

  .md\:dq-leading-snug {
    line-height: 1.375;
  }

  .md\:dq-leading-normal {
    line-height: 1.5;
  }

  .md\:dq-leading-relaxed {
    line-height: 1.625;
  }

  .md\:dq-leading-loose {
    line-height: 2;
  }

  .md\:dq-tracking-tighter {
    letter-spacing: -0.05em;
  }

  .md\:dq-tracking-tight {
    letter-spacing: -0.025em;
  }

  .md\:dq-tracking-normal {
    letter-spacing: 0em;
  }

  .md\:dq-tracking-wide {
    letter-spacing: 0.025em;
  }

  .md\:dq-tracking-wider {
    letter-spacing: 0.05em;
  }

  .md\:dq-tracking-widest {
    letter-spacing: 0.1em;
  }

  .md\:dq-text-transparent {
    color: transparent;
  }

  .md\:dq-text-current {
    color: currentColor;
  }

  .md\:dq-text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md\:dq-text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:dq-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md\:dq-text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md\:dq-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md\:dq-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md\:dq-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md\:dq-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md\:dq-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md\:dq-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md\:dq-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md\:dq-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md\:dq-text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .md\:dq-text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .md\:dq-text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .md\:dq-text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .md\:dq-text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .md\:dq-text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .md\:dq-text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .md\:dq-text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .md\:dq-text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .md\:dq-text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .md\:dq-text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md\:dq-text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md\:dq-text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md\:dq-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md\:dq-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md\:dq-text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md\:dq-text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md\:dq-text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md\:dq-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md\:dq-text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md\:dq-text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md\:dq-text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md\:dq-text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md\:dq-text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md\:dq-text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md\:dq-text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md\:dq-text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md\:dq-text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md\:dq-text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md\:dq-text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md\:dq-text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md\:dq-text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md\:dq-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md\:dq-text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md\:dq-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md\:dq-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md\:dq-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md\:dq-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md\:dq-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md\:dq-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md\:dq-text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md\:dq-text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md\:dq-text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md\:dq-text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md\:dq-text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md\:dq-text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md\:dq-text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md\:dq-text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md\:dq-text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md\:dq-text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md\:dq-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md\:dq-text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md\:dq-text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md\:dq-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md\:dq-text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md\:dq-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md\:dq-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md\:dq-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md\:dq-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md\:dq-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md\:dq-text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md\:dq-text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md\:dq-text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md\:dq-text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md\:dq-text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md\:dq-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md\:dq-text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md\:dq-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md\:dq-text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md\:dq-text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-transparent {
    color: transparent;
  }

  .dq-group:hover .md\:group-hover\:dq-text-current {
    color: currentColor;
  }

  .dq-group:hover .md\:group-hover\:dq-text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .dq-group:hover .md\:group-hover\:dq-text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-transparent:focus-within {
    color: transparent;
  }

  .md\:focus-within\:dq-text-current:focus-within {
    color: currentColor;
  }

  .md\:focus-within\:dq-text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md\:focus-within\:dq-text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-transparent:hover {
    color: transparent;
  }

  .md\:hover\:dq-text-current:hover {
    color: currentColor;
  }

  .md\:hover\:dq-text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md\:hover\:dq-text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-transparent:focus {
    color: transparent;
  }

  .md\:focus\:dq-text-current:focus {
    color: currentColor;
  }

  .md\:focus\:dq-text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md\:focus\:dq-text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md\:dq-text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .md\:dq-text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .md\:dq-text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .md\:dq-text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .md\:dq-text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .md\:dq-text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .md\:dq-text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .md\:dq-text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .md\:dq-text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .md\:dq-text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .md\:dq-text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .md\:dq-text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .md\:dq-text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .md\:dq-text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .md\:dq-text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .dq-group:hover .md\:group-hover\:dq-text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .md\:focus-within\:dq-text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .md\:focus-within\:dq-text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }

  .md\:focus-within\:dq-text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }

  .md\:focus-within\:dq-text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }

  .md\:focus-within\:dq-text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }

  .md\:focus-within\:dq-text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }

  .md\:focus-within\:dq-text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }

  .md\:focus-within\:dq-text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }

  .md\:focus-within\:dq-text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }

  .md\:focus-within\:dq-text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }

  .md\:focus-within\:dq-text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }

  .md\:focus-within\:dq-text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }

  .md\:focus-within\:dq-text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }

  .md\:focus-within\:dq-text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }

  .md\:focus-within\:dq-text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .md\:hover\:dq-text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .md\:hover\:dq-text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }

  .md\:hover\:dq-text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }

  .md\:hover\:dq-text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }

  .md\:hover\:dq-text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }

  .md\:hover\:dq-text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }

  .md\:hover\:dq-text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }

  .md\:hover\:dq-text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }

  .md\:hover\:dq-text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }

  .md\:hover\:dq-text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }

  .md\:hover\:dq-text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }

  .md\:hover\:dq-text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }

  .md\:hover\:dq-text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }

  .md\:hover\:dq-text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }

  .md\:hover\:dq-text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .md\:focus\:dq-text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .md\:focus\:dq-text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }

  .md\:focus\:dq-text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }

  .md\:focus\:dq-text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }

  .md\:focus\:dq-text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }

  .md\:focus\:dq-text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }

  .md\:focus\:dq-text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }

  .md\:focus\:dq-text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }

  .md\:focus\:dq-text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }

  .md\:focus\:dq-text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }

  .md\:focus\:dq-text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }

  .md\:focus\:dq-text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }

  .md\:focus\:dq-text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }

  .md\:focus\:dq-text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }

  .md\:focus\:dq-text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .md\:dq-underline {
    text-decoration: underline;
  }

  .md\:dq-line-through {
    text-decoration: line-through;
  }

  .md\:dq-no-underline {
    text-decoration: none;
  }

  .dq-group:hover .md\:group-hover\:dq-underline {
    text-decoration: underline;
  }

  .dq-group:hover .md\:group-hover\:dq-line-through {
    text-decoration: line-through;
  }

  .dq-group:hover .md\:group-hover\:dq-no-underline {
    text-decoration: none;
  }

  .md\:focus-within\:dq-underline:focus-within {
    text-decoration: underline;
  }

  .md\:focus-within\:dq-line-through:focus-within {
    text-decoration: line-through;
  }

  .md\:focus-within\:dq-no-underline:focus-within {
    text-decoration: none;
  }

  .md\:hover\:dq-underline:hover {
    text-decoration: underline;
  }

  .md\:hover\:dq-line-through:hover {
    text-decoration: line-through;
  }

  .md\:hover\:dq-no-underline:hover {
    text-decoration: none;
  }

  .md\:focus\:dq-underline:focus {
    text-decoration: underline;
  }

  .md\:focus\:dq-line-through:focus {
    text-decoration: line-through;
  }

  .md\:focus\:dq-no-underline:focus {
    text-decoration: none;
  }

  .md\:dq-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .md\:dq-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .md\:dq-placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }

  .md\:dq-placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }

  .md\:dq-placeholder-transparent::placeholder {
    color: transparent;
  }

  .md\:dq-placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }

  .md\:dq-placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }

  .md\:dq-placeholder-current::placeholder {
    color: currentColor;
  }

  .md\:dq-placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .md\:focus\:dq-placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }

  .md\:focus\:dq-placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .md\:focus\:dq-placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }

  .md\:focus\:dq-placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }

  .md\:focus\:dq-placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .md\:focus\:dq-placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .md\:focus\:dq-placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .md\:dq-placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md\:dq-placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md\:dq-placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md\:dq-placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .md\:dq-placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .md\:dq-placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .md\:dq-placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .md\:dq-placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .md\:dq-placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .md\:dq-placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .md\:dq-placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .md\:dq-placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .md\:dq-placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .md\:dq-placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .md\:dq-placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .md\:dq-placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .md\:dq-placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .md\:dq-placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .md\:dq-placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .md\:dq-placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .md\:dq-placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .md\:dq-placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .md\:dq-placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .md\:dq-placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .md\:dq-placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .md\:dq-placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .md\:dq-placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .md\:dq-placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .md\:dq-placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .md\:dq-placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .md\:dq-placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .md\:dq-placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .md\:dq-placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .md\:dq-placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .md\:dq-placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .md\:dq-placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .md\:dq-placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .md\:dq-placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .md\:dq-placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .md\:dq-placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .md\:dq-placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .md\:dq-placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .md\:dq-placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md\:dq-placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md\:dq-placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md\:focus\:dq-placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md\:focus\:dq-placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md\:focus\:dq-placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md\:focus\:dq-placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .md\:focus\:dq-placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .md\:focus\:dq-placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .md\:focus\:dq-placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .md\:focus\:dq-placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .md\:focus\:dq-placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .md\:focus\:dq-placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .md\:focus\:dq-placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .md\:focus\:dq-placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .md\:focus\:dq-placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .md\:focus\:dq-placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .md\:focus\:dq-placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .md\:focus\:dq-placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .md\:focus\:dq-placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .md\:focus\:dq-placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .md\:focus\:dq-placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .md\:focus\:dq-placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .md\:focus\:dq-placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .md\:focus\:dq-placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .md\:focus\:dq-placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .md\:focus\:dq-placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .md\:focus\:dq-placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .md\:focus\:dq-placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .md\:focus\:dq-placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .md\:focus\:dq-placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .md\:focus\:dq-placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .md\:focus\:dq-placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .md\:focus\:dq-placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .md\:focus\:dq-placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .md\:focus\:dq-placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .md\:focus\:dq-placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .md\:focus\:dq-placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .md\:focus\:dq-placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .md\:focus\:dq-placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .md\:focus\:dq-placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .md\:focus\:dq-placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .md\:focus\:dq-placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .md\:focus\:dq-placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .md\:focus\:dq-placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .md\:focus\:dq-placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md\:focus\:dq-placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md\:focus\:dq-placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md\:dq-opacity-0 {
    opacity: 0;
  }

  .md\:dq-opacity-5 {
    opacity: 0.05;
  }

  .md\:dq-opacity-10 {
    opacity: 0.1;
  }

  .md\:dq-opacity-20 {
    opacity: 0.2;
  }

  .md\:dq-opacity-25 {
    opacity: 0.25;
  }

  .md\:dq-opacity-30 {
    opacity: 0.3;
  }

  .md\:dq-opacity-40 {
    opacity: 0.4;
  }

  .md\:dq-opacity-50 {
    opacity: 0.5;
  }

  .md\:dq-opacity-60 {
    opacity: 0.6;
  }

  .md\:dq-opacity-70 {
    opacity: 0.7;
  }

  .md\:dq-opacity-75 {
    opacity: 0.75;
  }

  .md\:dq-opacity-80 {
    opacity: 0.8;
  }

  .md\:dq-opacity-90 {
    opacity: 0.9;
  }

  .md\:dq-opacity-95 {
    opacity: 0.95;
  }

  .md\:dq-opacity-100 {
    opacity: 1;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-0 {
    opacity: 0;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-5 {
    opacity: 0.05;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-10 {
    opacity: 0.1;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-20 {
    opacity: 0.2;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-25 {
    opacity: 0.25;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-30 {
    opacity: 0.3;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-40 {
    opacity: 0.4;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-50 {
    opacity: 0.5;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-60 {
    opacity: 0.6;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-70 {
    opacity: 0.7;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-75 {
    opacity: 0.75;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-80 {
    opacity: 0.8;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-90 {
    opacity: 0.9;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-95 {
    opacity: 0.95;
  }

  .dq-group:hover .md\:group-hover\:dq-opacity-100 {
    opacity: 1;
  }

  .md\:focus-within\:dq-opacity-0:focus-within {
    opacity: 0;
  }

  .md\:focus-within\:dq-opacity-5:focus-within {
    opacity: 0.05;
  }

  .md\:focus-within\:dq-opacity-10:focus-within {
    opacity: 0.1;
  }

  .md\:focus-within\:dq-opacity-20:focus-within {
    opacity: 0.2;
  }

  .md\:focus-within\:dq-opacity-25:focus-within {
    opacity: 0.25;
  }

  .md\:focus-within\:dq-opacity-30:focus-within {
    opacity: 0.3;
  }

  .md\:focus-within\:dq-opacity-40:focus-within {
    opacity: 0.4;
  }

  .md\:focus-within\:dq-opacity-50:focus-within {
    opacity: 0.5;
  }

  .md\:focus-within\:dq-opacity-60:focus-within {
    opacity: 0.6;
  }

  .md\:focus-within\:dq-opacity-70:focus-within {
    opacity: 0.7;
  }

  .md\:focus-within\:dq-opacity-75:focus-within {
    opacity: 0.75;
  }

  .md\:focus-within\:dq-opacity-80:focus-within {
    opacity: 0.8;
  }

  .md\:focus-within\:dq-opacity-90:focus-within {
    opacity: 0.9;
  }

  .md\:focus-within\:dq-opacity-95:focus-within {
    opacity: 0.95;
  }

  .md\:focus-within\:dq-opacity-100:focus-within {
    opacity: 1;
  }

  .md\:hover\:dq-opacity-0:hover {
    opacity: 0;
  }

  .md\:hover\:dq-opacity-5:hover {
    opacity: 0.05;
  }

  .md\:hover\:dq-opacity-10:hover {
    opacity: 0.1;
  }

  .md\:hover\:dq-opacity-20:hover {
    opacity: 0.2;
  }

  .md\:hover\:dq-opacity-25:hover {
    opacity: 0.25;
  }

  .md\:hover\:dq-opacity-30:hover {
    opacity: 0.3;
  }

  .md\:hover\:dq-opacity-40:hover {
    opacity: 0.4;
  }

  .md\:hover\:dq-opacity-50:hover {
    opacity: 0.5;
  }

  .md\:hover\:dq-opacity-60:hover {
    opacity: 0.6;
  }

  .md\:hover\:dq-opacity-70:hover {
    opacity: 0.7;
  }

  .md\:hover\:dq-opacity-75:hover {
    opacity: 0.75;
  }

  .md\:hover\:dq-opacity-80:hover {
    opacity: 0.8;
  }

  .md\:hover\:dq-opacity-90:hover {
    opacity: 0.9;
  }

  .md\:hover\:dq-opacity-95:hover {
    opacity: 0.95;
  }

  .md\:hover\:dq-opacity-100:hover {
    opacity: 1;
  }

  .md\:focus\:dq-opacity-0:focus {
    opacity: 0;
  }

  .md\:focus\:dq-opacity-5:focus {
    opacity: 0.05;
  }

  .md\:focus\:dq-opacity-10:focus {
    opacity: 0.1;
  }

  .md\:focus\:dq-opacity-20:focus {
    opacity: 0.2;
  }

  .md\:focus\:dq-opacity-25:focus {
    opacity: 0.25;
  }

  .md\:focus\:dq-opacity-30:focus {
    opacity: 0.3;
  }

  .md\:focus\:dq-opacity-40:focus {
    opacity: 0.4;
  }

  .md\:focus\:dq-opacity-50:focus {
    opacity: 0.5;
  }

  .md\:focus\:dq-opacity-60:focus {
    opacity: 0.6;
  }

  .md\:focus\:dq-opacity-70:focus {
    opacity: 0.7;
  }

  .md\:focus\:dq-opacity-75:focus {
    opacity: 0.75;
  }

  .md\:focus\:dq-opacity-80:focus {
    opacity: 0.8;
  }

  .md\:focus\:dq-opacity-90:focus {
    opacity: 0.9;
  }

  .md\:focus\:dq-opacity-95:focus {
    opacity: 0.95;
  }

  .md\:focus\:dq-opacity-100:focus {
    opacity: 1;
  }

  .md\:dq-bg-blend-normal {
    background-blend-mode: normal;
  }

  .md\:dq-bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .md\:dq-bg-blend-screen {
    background-blend-mode: screen;
  }

  .md\:dq-bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .md\:dq-bg-blend-darken {
    background-blend-mode: darken;
  }

  .md\:dq-bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .md\:dq-bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .md\:dq-bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .md\:dq-bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .md\:dq-bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .md\:dq-bg-blend-difference {
    background-blend-mode: difference;
  }

  .md\:dq-bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .md\:dq-bg-blend-hue {
    background-blend-mode: hue;
  }

  .md\:dq-bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .md\:dq-bg-blend-color {
    background-blend-mode: color;
  }

  .md\:dq-bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .md\:dq-mix-blend-normal {
    mix-blend-mode: normal;
  }

  .md\:dq-mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .md\:dq-mix-blend-screen {
    mix-blend-mode: screen;
  }

  .md\:dq-mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .md\:dq-mix-blend-darken {
    mix-blend-mode: darken;
  }

  .md\:dq-mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .md\:dq-mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .md\:dq-mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .md\:dq-mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .md\:dq-mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .md\:dq-mix-blend-difference {
    mix-blend-mode: difference;
  }

  .md\:dq-mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .md\:dq-mix-blend-hue {
    mix-blend-mode: hue;
  }

  .md\:dq-mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .md\:dq-mix-blend-color {
    mix-blend-mode: color;
  }

  .md\:dq-mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .md\:dq-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:dq-shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:dq-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:dq-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:dq-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:dq-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:dq-shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:dq-shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .md\:group-hover\:dq-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .md\:group-hover\:dq-shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .md\:group-hover\:dq-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .md\:group-hover\:dq-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .md\:group-hover\:dq-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .md\:group-hover\:dq-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .md\:group-hover\:dq-shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .md\:group-hover\:dq-shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:dq-shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:dq-shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:dq-shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:dq-shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:dq-shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:dq-shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:dq-shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:dq-shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:dq-shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:dq-shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:dq-shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:dq-shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:dq-shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:dq-shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:dq-shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:dq-shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:dq-shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:dq-shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:dq-shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:dq-shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:dq-shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:dq-shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:dq-shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:dq-shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:dq-outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .md\:dq-outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .md\:dq-outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .md\:focus-within\:dq-outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .md\:focus-within\:dq-outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .md\:focus-within\:dq-outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .md\:focus\:dq-outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .md\:focus\:dq-outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .md\:focus\:dq-outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .md\:dq-ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:dq-ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:dq-ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:dq-ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:dq-ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:dq-ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:dq-ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:dq-ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:dq-ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:dq-ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:dq-ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:dq-ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:dq-ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:dq-ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:dq-ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:dq-ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:dq-ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:dq-ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:dq-ring-inset {
    --tw-ring-inset: inset;
  }

  .md\:focus-within\:dq-ring-inset:focus-within {
    --tw-ring-inset: inset;
  }

  .md\:focus\:dq-ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .md\:dq-ring-transparent {
    --tw-ring-color: transparent;
  }

  .md\:dq-ring-current {
    --tw-ring-color: currentColor;
  }

  .md\:dq-ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md\:dq-ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:dq-ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .md\:dq-ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .md\:dq-ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .md\:dq-ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .md\:dq-ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .md\:dq-ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .md\:dq-ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .md\:dq-ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .md\:dq-ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .md\:dq-ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .md\:dq-ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .md\:dq-ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .md\:dq-ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .md\:dq-ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .md\:dq-ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .md\:dq-ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .md\:dq-ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .md\:dq-ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .md\:dq-ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .md\:dq-ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .md\:dq-ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .md\:dq-ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .md\:dq-ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .md\:dq-ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .md\:dq-ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .md\:dq-ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .md\:dq-ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .md\:dq-ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .md\:dq-ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .md\:dq-ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .md\:dq-ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .md\:dq-ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .md\:dq-ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .md\:dq-ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .md\:dq-ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .md\:dq-ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .md\:dq-ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .md\:dq-ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .md\:dq-ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .md\:dq-ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .md\:dq-ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .md\:dq-ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .md\:dq-ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .md\:dq-ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .md\:dq-ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .md\:dq-ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .md\:dq-ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .md\:dq-ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .md\:dq-ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .md\:dq-ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .md\:dq-ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .md\:dq-ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .md\:dq-ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .md\:dq-ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .md\:dq-ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .md\:dq-ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .md\:dq-ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .md\:dq-ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .md\:dq-ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .md\:dq-ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .md\:dq-ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .md\:dq-ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .md\:dq-ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .md\:dq-ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .md\:dq-ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .md\:dq-ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .md\:dq-ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .md\:dq-ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .md\:dq-ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .md\:dq-ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .md\:dq-ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .md\:dq-ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .md\:dq-ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .md\:dq-ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .md\:dq-ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .md\:dq-ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .md\:dq-ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .md\:dq-ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .md\:dq-ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .md\:dq-ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .md\:focus-within\:dq-ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .md\:focus-within\:dq-ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .md\:focus-within\:dq-ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .md\:focus\:dq-ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .md\:focus\:dq-ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .md\:focus\:dq-ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .md\:dq-ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .md\:dq-ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }

  .md\:dq-ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }

  .md\:dq-ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }

  .md\:dq-ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }

  .md\:dq-ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }

  .md\:dq-ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }

  .md\:dq-ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }

  .md\:dq-ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }

  .md\:dq-ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }

  .md\:dq-ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }

  .md\:dq-ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }

  .md\:dq-ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }

  .md\:dq-ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }

  .md\:dq-ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .md\:focus-within\:dq-ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .md\:focus-within\:dq-ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }

  .md\:focus-within\:dq-ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }

  .md\:focus-within\:dq-ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }

  .md\:focus-within\:dq-ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }

  .md\:focus-within\:dq-ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }

  .md\:focus-within\:dq-ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }

  .md\:focus-within\:dq-ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }

  .md\:focus-within\:dq-ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }

  .md\:focus-within\:dq-ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }

  .md\:focus-within\:dq-ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }

  .md\:focus-within\:dq-ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }

  .md\:focus-within\:dq-ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }

  .md\:focus-within\:dq-ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }

  .md\:focus-within\:dq-ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .md\:focus\:dq-ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .md\:focus\:dq-ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }

  .md\:focus\:dq-ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }

  .md\:focus\:dq-ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }

  .md\:focus\:dq-ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }

  .md\:focus\:dq-ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }

  .md\:focus\:dq-ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }

  .md\:focus\:dq-ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }

  .md\:focus\:dq-ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }

  .md\:focus\:dq-ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }

  .md\:focus\:dq-ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }

  .md\:focus\:dq-ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }

  .md\:focus\:dq-ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }

  .md\:focus\:dq-ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }

  .md\:focus\:dq-ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .md\:dq-ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .md\:dq-ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .md\:dq-ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .md\:dq-ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .md\:dq-ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .md\:focus-within\:dq-ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .md\:focus-within\:dq-ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .md\:focus-within\:dq-ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .md\:focus-within\:dq-ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .md\:focus-within\:dq-ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .md\:focus\:dq-ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .md\:focus\:dq-ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .md\:focus\:dq-ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .md\:focus\:dq-ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .md\:focus\:dq-ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .md\:dq-ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .md\:dq-ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .md\:dq-ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .md\:dq-ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .md\:dq-ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .md\:dq-ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .md\:dq-ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .md\:dq-ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .md\:dq-ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .md\:dq-ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .md\:dq-ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .md\:dq-ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .md\:dq-ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .md\:dq-ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .md\:dq-ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }

  .md\:dq-ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }

  .md\:dq-ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }

  .md\:dq-ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }

  .md\:dq-ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }

  .md\:dq-ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }

  .md\:dq-ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }

  .md\:dq-ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }

  .md\:dq-ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }

  .md\:dq-ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }

  .md\:dq-ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .md\:dq-ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .md\:dq-ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .md\:dq-ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .md\:dq-ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .md\:dq-ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .md\:dq-ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .md\:dq-ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .md\:dq-ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .md\:dq-ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .md\:dq-ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .md\:dq-ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .md\:dq-ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .md\:dq-ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .md\:dq-ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .md\:dq-ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .md\:dq-ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .md\:dq-ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .md\:dq-ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .md\:dq-ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .md\:dq-ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .md\:dq-ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .md\:dq-ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .md\:dq-ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .md\:dq-ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .md\:dq-ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .md\:dq-ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .md\:dq-ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .md\:dq-ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .md\:dq-ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .md\:dq-ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .md\:dq-ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .md\:dq-ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .md\:dq-ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .md\:dq-ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .md\:dq-ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .md\:dq-ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .md\:dq-ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .md\:dq-ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .md\:dq-ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .md\:dq-ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .md\:dq-ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .md\:dq-ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .md\:dq-ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .md\:dq-ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .md\:dq-ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .md\:dq-ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .md\:dq-ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .md\:dq-ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .md\:dq-ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .md\:dq-ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .md\:dq-ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .md\:dq-ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .md\:dq-ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .md\:dq-ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .md\:dq-ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .md\:dq-ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .md\:dq-ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .md\:dq-ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .md\:dq-ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .md\:focus-within\:dq-ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .md\:focus-within\:dq-ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .md\:focus-within\:dq-ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .md\:focus-within\:dq-ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .md\:focus-within\:dq-ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .md\:focus-within\:dq-ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .md\:focus-within\:dq-ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .md\:focus-within\:dq-ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .md\:focus-within\:dq-ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .md\:focus-within\:dq-ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .md\:focus-within\:dq-ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .md\:focus-within\:dq-ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .md\:focus-within\:dq-ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .md\:focus-within\:dq-ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .md\:focus-within\:dq-ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }

  .md\:focus-within\:dq-ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }

  .md\:focus-within\:dq-ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }

  .md\:focus-within\:dq-ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }

  .md\:focus-within\:dq-ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }

  .md\:focus-within\:dq-ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }

  .md\:focus-within\:dq-ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }

  .md\:focus-within\:dq-ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }

  .md\:focus-within\:dq-ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }

  .md\:focus-within\:dq-ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }

  .md\:focus-within\:dq-ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .md\:focus-within\:dq-ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .md\:focus-within\:dq-ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .md\:focus-within\:dq-ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .md\:focus-within\:dq-ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .md\:focus-within\:dq-ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .md\:focus-within\:dq-ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .md\:focus-within\:dq-ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .md\:focus-within\:dq-ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .md\:focus-within\:dq-ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .md\:focus-within\:dq-ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .md\:focus-within\:dq-ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .md\:focus-within\:dq-ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .md\:focus-within\:dq-ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .md\:focus-within\:dq-ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .md\:focus-within\:dq-ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .md\:focus-within\:dq-ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .md\:focus-within\:dq-ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .md\:focus-within\:dq-ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .md\:focus-within\:dq-ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .md\:focus-within\:dq-ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .md\:focus-within\:dq-ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .md\:focus-within\:dq-ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .md\:focus-within\:dq-ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .md\:focus-within\:dq-ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .md\:focus-within\:dq-ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .md\:focus-within\:dq-ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .md\:focus-within\:dq-ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .md\:focus-within\:dq-ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .md\:focus-within\:dq-ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .md\:focus-within\:dq-ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .md\:focus-within\:dq-ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .md\:focus-within\:dq-ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .md\:focus-within\:dq-ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .md\:focus-within\:dq-ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .md\:focus-within\:dq-ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .md\:focus-within\:dq-ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .md\:focus-within\:dq-ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .md\:focus-within\:dq-ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .md\:focus-within\:dq-ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .md\:focus-within\:dq-ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .md\:focus-within\:dq-ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .md\:focus-within\:dq-ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .md\:focus-within\:dq-ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .md\:focus-within\:dq-ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .md\:focus-within\:dq-ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .md\:focus-within\:dq-ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .md\:focus-within\:dq-ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .md\:focus-within\:dq-ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .md\:focus-within\:dq-ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .md\:focus-within\:dq-ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .md\:focus-within\:dq-ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .md\:focus-within\:dq-ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .md\:focus-within\:dq-ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .md\:focus-within\:dq-ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .md\:focus-within\:dq-ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .md\:focus-within\:dq-ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .md\:focus-within\:dq-ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .md\:focus-within\:dq-ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .md\:focus-within\:dq-ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .md\:focus\:dq-ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .md\:focus\:dq-ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .md\:focus\:dq-ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .md\:focus\:dq-ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .md\:focus\:dq-ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .md\:focus\:dq-ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .md\:focus\:dq-ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .md\:focus\:dq-ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .md\:focus\:dq-ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .md\:focus\:dq-ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .md\:focus\:dq-ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .md\:focus\:dq-ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .md\:focus\:dq-ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .md\:focus\:dq-ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .md\:focus\:dq-ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }

  .md\:focus\:dq-ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }

  .md\:focus\:dq-ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }

  .md\:focus\:dq-ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }

  .md\:focus\:dq-ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }

  .md\:focus\:dq-ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }

  .md\:focus\:dq-ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }

  .md\:focus\:dq-ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }

  .md\:focus\:dq-ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }

  .md\:focus\:dq-ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }

  .md\:focus\:dq-ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .md\:focus\:dq-ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .md\:focus\:dq-ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .md\:focus\:dq-ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .md\:focus\:dq-ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .md\:focus\:dq-ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .md\:focus\:dq-ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .md\:focus\:dq-ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .md\:focus\:dq-ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .md\:focus\:dq-ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .md\:focus\:dq-ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .md\:focus\:dq-ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .md\:focus\:dq-ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .md\:focus\:dq-ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .md\:focus\:dq-ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .md\:focus\:dq-ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .md\:focus\:dq-ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .md\:focus\:dq-ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .md\:focus\:dq-ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .md\:focus\:dq-ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .md\:focus\:dq-ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .md\:focus\:dq-ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .md\:focus\:dq-ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .md\:focus\:dq-ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .md\:focus\:dq-ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .md\:focus\:dq-ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .md\:focus\:dq-ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .md\:focus\:dq-ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .md\:focus\:dq-ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .md\:focus\:dq-ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .md\:focus\:dq-ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .md\:focus\:dq-ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .md\:focus\:dq-ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .md\:focus\:dq-ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .md\:focus\:dq-ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .md\:focus\:dq-ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .md\:focus\:dq-ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .md\:focus\:dq-ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .md\:focus\:dq-ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .md\:focus\:dq-ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .md\:focus\:dq-ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .md\:focus\:dq-ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .md\:focus\:dq-ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .md\:focus\:dq-ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .md\:focus\:dq-ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .md\:focus\:dq-ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .md\:focus\:dq-ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .md\:focus\:dq-ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .md\:focus\:dq-ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .md\:focus\:dq-ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .md\:focus\:dq-ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .md\:focus\:dq-ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .md\:focus\:dq-ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .md\:focus\:dq-ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .md\:focus\:dq-ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .md\:focus\:dq-ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .md\:focus\:dq-ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .md\:focus\:dq-ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .md\:focus\:dq-ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .md\:focus\:dq-ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .md\:dq-filter {
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:dq-filter-none {
    -webkit-filter: none;
            filter: none;
  }

  .md\:dq-blur-0 {
    --tw-blur: blur(0);
  }

  .md\:dq-blur-none {
    --tw-blur: blur(0);
  }

  .md\:dq-blur-sm {
    --tw-blur: blur(4px);
  }

  .md\:dq-blur {
    --tw-blur: blur(8px);
  }

  .md\:dq-blur-md {
    --tw-blur: blur(12px);
  }

  .md\:dq-blur-lg {
    --tw-blur: blur(16px);
  }

  .md\:dq-blur-xl {
    --tw-blur: blur(24px);
  }

  .md\:dq-blur-2xl {
    --tw-blur: blur(40px);
  }

  .md\:dq-blur-3xl {
    --tw-blur: blur(64px);
  }

  .md\:dq-brightness-0 {
    --tw-brightness: brightness(0);
  }

  .md\:dq-brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .md\:dq-brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .md\:dq-brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .md\:dq-brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .md\:dq-brightness-100 {
    --tw-brightness: brightness(1);
  }

  .md\:dq-brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .md\:dq-brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .md\:dq-brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .md\:dq-brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .md\:dq-brightness-200 {
    --tw-brightness: brightness(2);
  }

  .md\:dq-contrast-0 {
    --tw-contrast: contrast(0);
  }

  .md\:dq-contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .md\:dq-contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .md\:dq-contrast-100 {
    --tw-contrast: contrast(1);
  }

  .md\:dq-contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .md\:dq-contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .md\:dq-contrast-200 {
    --tw-contrast: contrast(2);
  }

  .md\:dq-drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .md\:dq-drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .md\:dq-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .md\:dq-drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .md\:dq-drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .md\:dq-drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .md\:dq-drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .md\:dq-grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .md\:dq-grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .md\:dq-hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .md\:dq-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .md\:dq-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .md\:dq-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .md\:dq-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .md\:dq-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .md\:dq--hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .md\:dq--hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .md\:dq--hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .md\:dq--hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .md\:dq--hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .md\:dq-invert-0 {
    --tw-invert: invert(0);
  }

  .md\:dq-invert {
    --tw-invert: invert(100%);
  }

  .md\:dq-saturate-0 {
    --tw-saturate: saturate(0);
  }

  .md\:dq-saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .md\:dq-saturate-100 {
    --tw-saturate: saturate(1);
  }

  .md\:dq-saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .md\:dq-saturate-200 {
    --tw-saturate: saturate(2);
  }

  .md\:dq-sepia-0 {
    --tw-sepia: sepia(0);
  }

  .md\:dq-sepia {
    --tw-sepia: sepia(100%);
  }

  .md\:dq-backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:dq-backdrop-filter-none {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }

  .md\:dq-backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }

  .md\:dq-backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .md\:dq-backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .md\:dq-backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .md\:dq-backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .md\:dq-backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .md\:dq-backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .md\:dq-backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .md\:dq-backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .md\:dq-backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .md\:dq-backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .md\:dq-backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .md\:dq-backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .md\:dq-backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .md\:dq-backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .md\:dq-backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .md\:dq-backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .md\:dq-backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .md\:dq-backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .md\:dq-backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .md\:dq-backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .md\:dq-backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .md\:dq-backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .md\:dq-backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .md\:dq-backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .md\:dq-backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .md\:dq-backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .md\:dq-backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .md\:dq-backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .md\:dq-backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .md\:dq-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .md\:dq-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .md\:dq-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .md\:dq-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .md\:dq-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .md\:dq--backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .md\:dq--backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .md\:dq--backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .md\:dq--backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .md\:dq--backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .md\:dq-backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .md\:dq-backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .md\:dq-backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .md\:dq-backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }

  .md\:dq-backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }

  .md\:dq-backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }

  .md\:dq-backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }

  .md\:dq-backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }

  .md\:dq-backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }

  .md\:dq-backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }

  .md\:dq-backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }

  .md\:dq-backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }

  .md\:dq-backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }

  .md\:dq-backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }

  .md\:dq-backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }

  .md\:dq-backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }

  .md\:dq-backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .md\:dq-backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .md\:dq-backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .md\:dq-backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .md\:dq-backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .md\:dq-backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .md\:dq-backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .md\:dq-backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .md\:dq-transition-none {
    transition-property: none;
  }

  .md\:dq-transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .md\:dq-transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .md\:dq-transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .md\:dq-transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .md\:dq-transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .md\:dq-transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .md\:dq-delay-75 {
    transition-delay: 75ms;
  }

  .md\:dq-delay-100 {
    transition-delay: 100ms;
  }

  .md\:dq-delay-150 {
    transition-delay: 150ms;
  }

  .md\:dq-delay-200 {
    transition-delay: 200ms;
  }

  .md\:dq-delay-300 {
    transition-delay: 300ms;
  }

  .md\:dq-delay-500 {
    transition-delay: 500ms;
  }

  .md\:dq-delay-700 {
    transition-delay: 700ms;
  }

  .md\:dq-delay-1000 {
    transition-delay: 1000ms;
  }

  .md\:dq-duration-75 {
    transition-duration: 75ms;
  }

  .md\:dq-duration-100 {
    transition-duration: 100ms;
  }

  .md\:dq-duration-150 {
    transition-duration: 150ms;
  }

  .md\:dq-duration-200 {
    transition-duration: 200ms;
  }

  .md\:dq-duration-300 {
    transition-duration: 300ms;
  }

  .md\:dq-duration-500 {
    transition-duration: 500ms;
  }

  .md\:dq-duration-700 {
    transition-duration: 700ms;
  }

  .md\:dq-duration-1000 {
    transition-duration: 1000ms;
  }

  .md\:dq-ease-linear {
    transition-timing-function: linear;
  }

  .md\:dq-ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .md\:dq-ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .md\:dq-ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 1024px) {
  .lg\:dq-container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .lg\:dq-container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .lg\:dq-container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .lg\:dq-container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .lg\:dq-container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .lg\:dq-container {
      max-width: 1536px;
    }
  }

  .lg\:dq-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .lg\:dq-not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .lg\:focus-within\:dq-sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .lg\:focus-within\:dq-not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .lg\:focus\:dq-sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .lg\:focus\:dq-not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .lg\:dq-pointer-events-none {
    pointer-events: none;
  }

  .lg\:dq-pointer-events-auto {
    pointer-events: auto;
  }

  .lg\:dq-visible {
    visibility: visible;
  }

  .lg\:dq-invisible {
    visibility: hidden;
  }

  .lg\:dq-static {
    position: static;
  }

  .lg\:dq-fixed {
    position: fixed;
  }

  .lg\:dq-absolute {
    position: absolute;
  }

  .lg\:dq-relative {
    position: relative;
  }

  .lg\:dq-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .lg\:dq-inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .lg\:dq-inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }

  .lg\:dq-inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }

  .lg\:dq-inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }

  .lg\:dq-inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }

  .lg\:dq-inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }

  .lg\:dq-inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }

  .lg\:dq-inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }

  .lg\:dq-inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }

  .lg\:dq-inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }

  .lg\:dq-inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }

  .lg\:dq-inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }

  .lg\:dq-inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }

  .lg\:dq-inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }

  .lg\:dq-inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }

  .lg\:dq-inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }

  .lg\:dq-inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }

  .lg\:dq-inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }

  .lg\:dq-inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }

  .lg\:dq-inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }

  .lg\:dq-inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }

  .lg\:dq-inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }

  .lg\:dq-inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }

  .lg\:dq-inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }

  .lg\:dq-inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }

  .lg\:dq-inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }

  .lg\:dq-inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }

  .lg\:dq-inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }

  .lg\:dq-inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }

  .lg\:dq-inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }

  .lg\:dq-inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .lg\:dq-inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }

  .lg\:dq-inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }

  .lg\:dq-inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }

  .lg\:dq-inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }

  .lg\:dq-inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }

  .lg\:dq--inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .lg\:dq--inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }

  .lg\:dq--inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }

  .lg\:dq--inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }

  .lg\:dq--inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }

  .lg\:dq--inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }

  .lg\:dq--inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }

  .lg\:dq--inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }

  .lg\:dq--inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }

  .lg\:dq--inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }

  .lg\:dq--inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }

  .lg\:dq--inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }

  .lg\:dq--inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }

  .lg\:dq--inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }

  .lg\:dq--inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }

  .lg\:dq--inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }

  .lg\:dq--inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }

  .lg\:dq--inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }

  .lg\:dq--inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }

  .lg\:dq--inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }

  .lg\:dq--inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }

  .lg\:dq--inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }

  .lg\:dq--inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }

  .lg\:dq--inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }

  .lg\:dq--inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }

  .lg\:dq--inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }

  .lg\:dq--inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }

  .lg\:dq--inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }

  .lg\:dq--inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }

  .lg\:dq--inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }

  .lg\:dq--inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }

  .lg\:dq--inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }

  .lg\:dq--inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }

  .lg\:dq--inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }

  .lg\:dq--inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }

  .lg\:dq-inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }

  .lg\:dq-inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }

  .lg\:dq-inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }

  .lg\:dq-inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }

  .lg\:dq-inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }

  .lg\:dq-inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }

  .lg\:dq-inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }

  .lg\:dq--inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }

  .lg\:dq--inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }

  .lg\:dq--inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }

  .lg\:dq--inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }

  .lg\:dq--inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }

  .lg\:dq--inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }

  .lg\:dq--inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }

  .lg\:dq-inset-x-0 {
    left: 0px;
    right: 0px;
  }

  .lg\:dq-inset-x-1 {
    left: 0.25rem;
    right: 0.25rem;
  }

  .lg\:dq-inset-x-2 {
    left: 0.5rem;
    right: 0.5rem;
  }

  .lg\:dq-inset-x-3 {
    left: 0.75rem;
    right: 0.75rem;
  }

  .lg\:dq-inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .lg\:dq-inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .lg\:dq-inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .lg\:dq-inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .lg\:dq-inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .lg\:dq-inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .lg\:dq-inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .lg\:dq-inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .lg\:dq-inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .lg\:dq-inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .lg\:dq-inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .lg\:dq-inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .lg\:dq-inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .lg\:dq-inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .lg\:dq-inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .lg\:dq-inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .lg\:dq-inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .lg\:dq-inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .lg\:dq-inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .lg\:dq-inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .lg\:dq-inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .lg\:dq-inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .lg\:dq-inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .lg\:dq-inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .lg\:dq-inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .lg\:dq-inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .lg\:dq-inset-x-auto {
    left: auto;
    right: auto;
  }

  .lg\:dq-inset-x-px {
    left: 1px;
    right: 1px;
  }

  .lg\:dq-inset-x-0\.5 {
    left: 0.125rem;
    right: 0.125rem;
  }

  .lg\:dq-inset-x-1\.5 {
    left: 0.375rem;
    right: 0.375rem;
  }

  .lg\:dq-inset-x-2\.5 {
    left: 0.625rem;
    right: 0.625rem;
  }

  .lg\:dq-inset-x-3\.5 {
    left: 0.875rem;
    right: 0.875rem;
  }

  .lg\:dq--inset-x-0 {
    left: 0px;
    right: 0px;
  }

  .lg\:dq--inset-x-1 {
    left: -0.25rem;
    right: -0.25rem;
  }

  .lg\:dq--inset-x-2 {
    left: -0.5rem;
    right: -0.5rem;
  }

  .lg\:dq--inset-x-3 {
    left: -0.75rem;
    right: -0.75rem;
  }

  .lg\:dq--inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .lg\:dq--inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .lg\:dq--inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .lg\:dq--inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .lg\:dq--inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .lg\:dq--inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .lg\:dq--inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .lg\:dq--inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .lg\:dq--inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .lg\:dq--inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .lg\:dq--inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .lg\:dq--inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .lg\:dq--inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .lg\:dq--inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .lg\:dq--inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .lg\:dq--inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .lg\:dq--inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .lg\:dq--inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .lg\:dq--inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .lg\:dq--inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .lg\:dq--inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .lg\:dq--inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .lg\:dq--inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .lg\:dq--inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .lg\:dq--inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .lg\:dq--inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .lg\:dq--inset-x-px {
    left: -1px;
    right: -1px;
  }

  .lg\:dq--inset-x-0\.5 {
    left: -0.125rem;
    right: -0.125rem;
  }

  .lg\:dq--inset-x-1\.5 {
    left: -0.375rem;
    right: -0.375rem;
  }

  .lg\:dq--inset-x-2\.5 {
    left: -0.625rem;
    right: -0.625rem;
  }

  .lg\:dq--inset-x-3\.5 {
    left: -0.875rem;
    right: -0.875rem;
  }

  .lg\:dq-inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .lg\:dq-inset-x-1\/3 {
    left: 33.333333%;
    right: 33.333333%;
  }

  .lg\:dq-inset-x-2\/3 {
    left: 66.666667%;
    right: 66.666667%;
  }

  .lg\:dq-inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .lg\:dq-inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .lg\:dq-inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .lg\:dq-inset-x-full {
    left: 100%;
    right: 100%;
  }

  .lg\:dq--inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .lg\:dq--inset-x-1\/3 {
    left: -33.333333%;
    right: -33.333333%;
  }

  .lg\:dq--inset-x-2\/3 {
    left: -66.666667%;
    right: -66.666667%;
  }

  .lg\:dq--inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .lg\:dq--inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .lg\:dq--inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .lg\:dq--inset-x-full {
    left: -100%;
    right: -100%;
  }

  .lg\:dq-inset-y-0 {
    top: 0px;
    bottom: 0px;
  }

  .lg\:dq-inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }

  .lg\:dq-inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }

  .lg\:dq-inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }

  .lg\:dq-inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .lg\:dq-inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .lg\:dq-inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .lg\:dq-inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .lg\:dq-inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .lg\:dq-inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .lg\:dq-inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .lg\:dq-inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .lg\:dq-inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .lg\:dq-inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .lg\:dq-inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .lg\:dq-inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .lg\:dq-inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .lg\:dq-inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .lg\:dq-inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .lg\:dq-inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .lg\:dq-inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .lg\:dq-inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .lg\:dq-inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .lg\:dq-inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .lg\:dq-inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .lg\:dq-inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .lg\:dq-inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .lg\:dq-inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .lg\:dq-inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .lg\:dq-inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .lg\:dq-inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .lg\:dq-inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .lg\:dq-inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }

  .lg\:dq-inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }

  .lg\:dq-inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }

  .lg\:dq-inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }

  .lg\:dq--inset-y-0 {
    top: 0px;
    bottom: 0px;
  }

  .lg\:dq--inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }

  .lg\:dq--inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }

  .lg\:dq--inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }

  .lg\:dq--inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .lg\:dq--inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .lg\:dq--inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .lg\:dq--inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .lg\:dq--inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .lg\:dq--inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .lg\:dq--inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .lg\:dq--inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .lg\:dq--inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .lg\:dq--inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .lg\:dq--inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .lg\:dq--inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .lg\:dq--inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .lg\:dq--inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .lg\:dq--inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .lg\:dq--inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .lg\:dq--inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .lg\:dq--inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .lg\:dq--inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .lg\:dq--inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .lg\:dq--inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .lg\:dq--inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .lg\:dq--inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .lg\:dq--inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .lg\:dq--inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .lg\:dq--inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .lg\:dq--inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .lg\:dq--inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }

  .lg\:dq--inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }

  .lg\:dq--inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }

  .lg\:dq--inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }

  .lg\:dq-inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .lg\:dq-inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }

  .lg\:dq-inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }

  .lg\:dq-inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .lg\:dq-inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .lg\:dq-inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .lg\:dq-inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .lg\:dq--inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .lg\:dq--inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }

  .lg\:dq--inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }

  .lg\:dq--inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .lg\:dq--inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .lg\:dq--inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .lg\:dq--inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .lg\:dq-top-0 {
    top: 0px;
  }

  .lg\:dq-top-1 {
    top: 0.25rem;
  }

  .lg\:dq-top-2 {
    top: 0.5rem;
  }

  .lg\:dq-top-3 {
    top: 0.75rem;
  }

  .lg\:dq-top-4 {
    top: 1rem;
  }

  .lg\:dq-top-5 {
    top: 1.25rem;
  }

  .lg\:dq-top-6 {
    top: 1.5rem;
  }

  .lg\:dq-top-7 {
    top: 1.75rem;
  }

  .lg\:dq-top-8 {
    top: 2rem;
  }

  .lg\:dq-top-9 {
    top: 2.25rem;
  }

  .lg\:dq-top-10 {
    top: 2.5rem;
  }

  .lg\:dq-top-11 {
    top: 2.75rem;
  }

  .lg\:dq-top-12 {
    top: 3rem;
  }

  .lg\:dq-top-14 {
    top: 3.5rem;
  }

  .lg\:dq-top-16 {
    top: 4rem;
  }

  .lg\:dq-top-20 {
    top: 5rem;
  }

  .lg\:dq-top-24 {
    top: 6rem;
  }

  .lg\:dq-top-28 {
    top: 7rem;
  }

  .lg\:dq-top-32 {
    top: 8rem;
  }

  .lg\:dq-top-36 {
    top: 9rem;
  }

  .lg\:dq-top-40 {
    top: 10rem;
  }

  .lg\:dq-top-44 {
    top: 11rem;
  }

  .lg\:dq-top-48 {
    top: 12rem;
  }

  .lg\:dq-top-52 {
    top: 13rem;
  }

  .lg\:dq-top-56 {
    top: 14rem;
  }

  .lg\:dq-top-60 {
    top: 15rem;
  }

  .lg\:dq-top-64 {
    top: 16rem;
  }

  .lg\:dq-top-72 {
    top: 18rem;
  }

  .lg\:dq-top-80 {
    top: 20rem;
  }

  .lg\:dq-top-96 {
    top: 24rem;
  }

  .lg\:dq-top-auto {
    top: auto;
  }

  .lg\:dq-top-px {
    top: 1px;
  }

  .lg\:dq-top-0\.5 {
    top: 0.125rem;
  }

  .lg\:dq-top-1\.5 {
    top: 0.375rem;
  }

  .lg\:dq-top-2\.5 {
    top: 0.625rem;
  }

  .lg\:dq-top-3\.5 {
    top: 0.875rem;
  }

  .lg\:dq--top-0 {
    top: 0px;
  }

  .lg\:dq--top-1 {
    top: -0.25rem;
  }

  .lg\:dq--top-2 {
    top: -0.5rem;
  }

  .lg\:dq--top-3 {
    top: -0.75rem;
  }

  .lg\:dq--top-4 {
    top: -1rem;
  }

  .lg\:dq--top-5 {
    top: -1.25rem;
  }

  .lg\:dq--top-6 {
    top: -1.5rem;
  }

  .lg\:dq--top-7 {
    top: -1.75rem;
  }

  .lg\:dq--top-8 {
    top: -2rem;
  }

  .lg\:dq--top-9 {
    top: -2.25rem;
  }

  .lg\:dq--top-10 {
    top: -2.5rem;
  }

  .lg\:dq--top-11 {
    top: -2.75rem;
  }

  .lg\:dq--top-12 {
    top: -3rem;
  }

  .lg\:dq--top-14 {
    top: -3.5rem;
  }

  .lg\:dq--top-16 {
    top: -4rem;
  }

  .lg\:dq--top-20 {
    top: -5rem;
  }

  .lg\:dq--top-24 {
    top: -6rem;
  }

  .lg\:dq--top-28 {
    top: -7rem;
  }

  .lg\:dq--top-32 {
    top: -8rem;
  }

  .lg\:dq--top-36 {
    top: -9rem;
  }

  .lg\:dq--top-40 {
    top: -10rem;
  }

  .lg\:dq--top-44 {
    top: -11rem;
  }

  .lg\:dq--top-48 {
    top: -12rem;
  }

  .lg\:dq--top-52 {
    top: -13rem;
  }

  .lg\:dq--top-56 {
    top: -14rem;
  }

  .lg\:dq--top-60 {
    top: -15rem;
  }

  .lg\:dq--top-64 {
    top: -16rem;
  }

  .lg\:dq--top-72 {
    top: -18rem;
  }

  .lg\:dq--top-80 {
    top: -20rem;
  }

  .lg\:dq--top-96 {
    top: -24rem;
  }

  .lg\:dq--top-px {
    top: -1px;
  }

  .lg\:dq--top-0\.5 {
    top: -0.125rem;
  }

  .lg\:dq--top-1\.5 {
    top: -0.375rem;
  }

  .lg\:dq--top-2\.5 {
    top: -0.625rem;
  }

  .lg\:dq--top-3\.5 {
    top: -0.875rem;
  }

  .lg\:dq-top-1\/2 {
    top: 50%;
  }

  .lg\:dq-top-1\/3 {
    top: 33.333333%;
  }

  .lg\:dq-top-2\/3 {
    top: 66.666667%;
  }

  .lg\:dq-top-1\/4 {
    top: 25%;
  }

  .lg\:dq-top-2\/4 {
    top: 50%;
  }

  .lg\:dq-top-3\/4 {
    top: 75%;
  }

  .lg\:dq-top-full {
    top: 100%;
  }

  .lg\:dq--top-1\/2 {
    top: -50%;
  }

  .lg\:dq--top-1\/3 {
    top: -33.333333%;
  }

  .lg\:dq--top-2\/3 {
    top: -66.666667%;
  }

  .lg\:dq--top-1\/4 {
    top: -25%;
  }

  .lg\:dq--top-2\/4 {
    top: -50%;
  }

  .lg\:dq--top-3\/4 {
    top: -75%;
  }

  .lg\:dq--top-full {
    top: -100%;
  }

  .lg\:dq-right-0 {
    right: 0px;
  }

  .lg\:dq-right-1 {
    right: 0.25rem;
  }

  .lg\:dq-right-2 {
    right: 0.5rem;
  }

  .lg\:dq-right-3 {
    right: 0.75rem;
  }

  .lg\:dq-right-4 {
    right: 1rem;
  }

  .lg\:dq-right-5 {
    right: 1.25rem;
  }

  .lg\:dq-right-6 {
    right: 1.5rem;
  }

  .lg\:dq-right-7 {
    right: 1.75rem;
  }

  .lg\:dq-right-8 {
    right: 2rem;
  }

  .lg\:dq-right-9 {
    right: 2.25rem;
  }

  .lg\:dq-right-10 {
    right: 2.5rem;
  }

  .lg\:dq-right-11 {
    right: 2.75rem;
  }

  .lg\:dq-right-12 {
    right: 3rem;
  }

  .lg\:dq-right-14 {
    right: 3.5rem;
  }

  .lg\:dq-right-16 {
    right: 4rem;
  }

  .lg\:dq-right-20 {
    right: 5rem;
  }

  .lg\:dq-right-24 {
    right: 6rem;
  }

  .lg\:dq-right-28 {
    right: 7rem;
  }

  .lg\:dq-right-32 {
    right: 8rem;
  }

  .lg\:dq-right-36 {
    right: 9rem;
  }

  .lg\:dq-right-40 {
    right: 10rem;
  }

  .lg\:dq-right-44 {
    right: 11rem;
  }

  .lg\:dq-right-48 {
    right: 12rem;
  }

  .lg\:dq-right-52 {
    right: 13rem;
  }

  .lg\:dq-right-56 {
    right: 14rem;
  }

  .lg\:dq-right-60 {
    right: 15rem;
  }

  .lg\:dq-right-64 {
    right: 16rem;
  }

  .lg\:dq-right-72 {
    right: 18rem;
  }

  .lg\:dq-right-80 {
    right: 20rem;
  }

  .lg\:dq-right-96 {
    right: 24rem;
  }

  .lg\:dq-right-auto {
    right: auto;
  }

  .lg\:dq-right-px {
    right: 1px;
  }

  .lg\:dq-right-0\.5 {
    right: 0.125rem;
  }

  .lg\:dq-right-1\.5 {
    right: 0.375rem;
  }

  .lg\:dq-right-2\.5 {
    right: 0.625rem;
  }

  .lg\:dq-right-3\.5 {
    right: 0.875rem;
  }

  .lg\:dq--right-0 {
    right: 0px;
  }

  .lg\:dq--right-1 {
    right: -0.25rem;
  }

  .lg\:dq--right-2 {
    right: -0.5rem;
  }

  .lg\:dq--right-3 {
    right: -0.75rem;
  }

  .lg\:dq--right-4 {
    right: -1rem;
  }

  .lg\:dq--right-5 {
    right: -1.25rem;
  }

  .lg\:dq--right-6 {
    right: -1.5rem;
  }

  .lg\:dq--right-7 {
    right: -1.75rem;
  }

  .lg\:dq--right-8 {
    right: -2rem;
  }

  .lg\:dq--right-9 {
    right: -2.25rem;
  }

  .lg\:dq--right-10 {
    right: -2.5rem;
  }

  .lg\:dq--right-11 {
    right: -2.75rem;
  }

  .lg\:dq--right-12 {
    right: -3rem;
  }

  .lg\:dq--right-14 {
    right: -3.5rem;
  }

  .lg\:dq--right-16 {
    right: -4rem;
  }

  .lg\:dq--right-20 {
    right: -5rem;
  }

  .lg\:dq--right-24 {
    right: -6rem;
  }

  .lg\:dq--right-28 {
    right: -7rem;
  }

  .lg\:dq--right-32 {
    right: -8rem;
  }

  .lg\:dq--right-36 {
    right: -9rem;
  }

  .lg\:dq--right-40 {
    right: -10rem;
  }

  .lg\:dq--right-44 {
    right: -11rem;
  }

  .lg\:dq--right-48 {
    right: -12rem;
  }

  .lg\:dq--right-52 {
    right: -13rem;
  }

  .lg\:dq--right-56 {
    right: -14rem;
  }

  .lg\:dq--right-60 {
    right: -15rem;
  }

  .lg\:dq--right-64 {
    right: -16rem;
  }

  .lg\:dq--right-72 {
    right: -18rem;
  }

  .lg\:dq--right-80 {
    right: -20rem;
  }

  .lg\:dq--right-96 {
    right: -24rem;
  }

  .lg\:dq--right-px {
    right: -1px;
  }

  .lg\:dq--right-0\.5 {
    right: -0.125rem;
  }

  .lg\:dq--right-1\.5 {
    right: -0.375rem;
  }

  .lg\:dq--right-2\.5 {
    right: -0.625rem;
  }

  .lg\:dq--right-3\.5 {
    right: -0.875rem;
  }

  .lg\:dq-right-1\/2 {
    right: 50%;
  }

  .lg\:dq-right-1\/3 {
    right: 33.333333%;
  }

  .lg\:dq-right-2\/3 {
    right: 66.666667%;
  }

  .lg\:dq-right-1\/4 {
    right: 25%;
  }

  .lg\:dq-right-2\/4 {
    right: 50%;
  }

  .lg\:dq-right-3\/4 {
    right: 75%;
  }

  .lg\:dq-right-full {
    right: 100%;
  }

  .lg\:dq--right-1\/2 {
    right: -50%;
  }

  .lg\:dq--right-1\/3 {
    right: -33.333333%;
  }

  .lg\:dq--right-2\/3 {
    right: -66.666667%;
  }

  .lg\:dq--right-1\/4 {
    right: -25%;
  }

  .lg\:dq--right-2\/4 {
    right: -50%;
  }

  .lg\:dq--right-3\/4 {
    right: -75%;
  }

  .lg\:dq--right-full {
    right: -100%;
  }

  .lg\:dq-bottom-0 {
    bottom: 0px;
  }

  .lg\:dq-bottom-1 {
    bottom: 0.25rem;
  }

  .lg\:dq-bottom-2 {
    bottom: 0.5rem;
  }

  .lg\:dq-bottom-3 {
    bottom: 0.75rem;
  }

  .lg\:dq-bottom-4 {
    bottom: 1rem;
  }

  .lg\:dq-bottom-5 {
    bottom: 1.25rem;
  }

  .lg\:dq-bottom-6 {
    bottom: 1.5rem;
  }

  .lg\:dq-bottom-7 {
    bottom: 1.75rem;
  }

  .lg\:dq-bottom-8 {
    bottom: 2rem;
  }

  .lg\:dq-bottom-9 {
    bottom: 2.25rem;
  }

  .lg\:dq-bottom-10 {
    bottom: 2.5rem;
  }

  .lg\:dq-bottom-11 {
    bottom: 2.75rem;
  }

  .lg\:dq-bottom-12 {
    bottom: 3rem;
  }

  .lg\:dq-bottom-14 {
    bottom: 3.5rem;
  }

  .lg\:dq-bottom-16 {
    bottom: 4rem;
  }

  .lg\:dq-bottom-20 {
    bottom: 5rem;
  }

  .lg\:dq-bottom-24 {
    bottom: 6rem;
  }

  .lg\:dq-bottom-28 {
    bottom: 7rem;
  }

  .lg\:dq-bottom-32 {
    bottom: 8rem;
  }

  .lg\:dq-bottom-36 {
    bottom: 9rem;
  }

  .lg\:dq-bottom-40 {
    bottom: 10rem;
  }

  .lg\:dq-bottom-44 {
    bottom: 11rem;
  }

  .lg\:dq-bottom-48 {
    bottom: 12rem;
  }

  .lg\:dq-bottom-52 {
    bottom: 13rem;
  }

  .lg\:dq-bottom-56 {
    bottom: 14rem;
  }

  .lg\:dq-bottom-60 {
    bottom: 15rem;
  }

  .lg\:dq-bottom-64 {
    bottom: 16rem;
  }

  .lg\:dq-bottom-72 {
    bottom: 18rem;
  }

  .lg\:dq-bottom-80 {
    bottom: 20rem;
  }

  .lg\:dq-bottom-96 {
    bottom: 24rem;
  }

  .lg\:dq-bottom-auto {
    bottom: auto;
  }

  .lg\:dq-bottom-px {
    bottom: 1px;
  }

  .lg\:dq-bottom-0\.5 {
    bottom: 0.125rem;
  }

  .lg\:dq-bottom-1\.5 {
    bottom: 0.375rem;
  }

  .lg\:dq-bottom-2\.5 {
    bottom: 0.625rem;
  }

  .lg\:dq-bottom-3\.5 {
    bottom: 0.875rem;
  }

  .lg\:dq--bottom-0 {
    bottom: 0px;
  }

  .lg\:dq--bottom-1 {
    bottom: -0.25rem;
  }

  .lg\:dq--bottom-2 {
    bottom: -0.5rem;
  }

  .lg\:dq--bottom-3 {
    bottom: -0.75rem;
  }

  .lg\:dq--bottom-4 {
    bottom: -1rem;
  }

  .lg\:dq--bottom-5 {
    bottom: -1.25rem;
  }

  .lg\:dq--bottom-6 {
    bottom: -1.5rem;
  }

  .lg\:dq--bottom-7 {
    bottom: -1.75rem;
  }

  .lg\:dq--bottom-8 {
    bottom: -2rem;
  }

  .lg\:dq--bottom-9 {
    bottom: -2.25rem;
  }

  .lg\:dq--bottom-10 {
    bottom: -2.5rem;
  }

  .lg\:dq--bottom-11 {
    bottom: -2.75rem;
  }

  .lg\:dq--bottom-12 {
    bottom: -3rem;
  }

  .lg\:dq--bottom-14 {
    bottom: -3.5rem;
  }

  .lg\:dq--bottom-16 {
    bottom: -4rem;
  }

  .lg\:dq--bottom-20 {
    bottom: -5rem;
  }

  .lg\:dq--bottom-24 {
    bottom: -6rem;
  }

  .lg\:dq--bottom-28 {
    bottom: -7rem;
  }

  .lg\:dq--bottom-32 {
    bottom: -8rem;
  }

  .lg\:dq--bottom-36 {
    bottom: -9rem;
  }

  .lg\:dq--bottom-40 {
    bottom: -10rem;
  }

  .lg\:dq--bottom-44 {
    bottom: -11rem;
  }

  .lg\:dq--bottom-48 {
    bottom: -12rem;
  }

  .lg\:dq--bottom-52 {
    bottom: -13rem;
  }

  .lg\:dq--bottom-56 {
    bottom: -14rem;
  }

  .lg\:dq--bottom-60 {
    bottom: -15rem;
  }

  .lg\:dq--bottom-64 {
    bottom: -16rem;
  }

  .lg\:dq--bottom-72 {
    bottom: -18rem;
  }

  .lg\:dq--bottom-80 {
    bottom: -20rem;
  }

  .lg\:dq--bottom-96 {
    bottom: -24rem;
  }

  .lg\:dq--bottom-px {
    bottom: -1px;
  }

  .lg\:dq--bottom-0\.5 {
    bottom: -0.125rem;
  }

  .lg\:dq--bottom-1\.5 {
    bottom: -0.375rem;
  }

  .lg\:dq--bottom-2\.5 {
    bottom: -0.625rem;
  }

  .lg\:dq--bottom-3\.5 {
    bottom: -0.875rem;
  }

  .lg\:dq-bottom-1\/2 {
    bottom: 50%;
  }

  .lg\:dq-bottom-1\/3 {
    bottom: 33.333333%;
  }

  .lg\:dq-bottom-2\/3 {
    bottom: 66.666667%;
  }

  .lg\:dq-bottom-1\/4 {
    bottom: 25%;
  }

  .lg\:dq-bottom-2\/4 {
    bottom: 50%;
  }

  .lg\:dq-bottom-3\/4 {
    bottom: 75%;
  }

  .lg\:dq-bottom-full {
    bottom: 100%;
  }

  .lg\:dq--bottom-1\/2 {
    bottom: -50%;
  }

  .lg\:dq--bottom-1\/3 {
    bottom: -33.333333%;
  }

  .lg\:dq--bottom-2\/3 {
    bottom: -66.666667%;
  }

  .lg\:dq--bottom-1\/4 {
    bottom: -25%;
  }

  .lg\:dq--bottom-2\/4 {
    bottom: -50%;
  }

  .lg\:dq--bottom-3\/4 {
    bottom: -75%;
  }

  .lg\:dq--bottom-full {
    bottom: -100%;
  }

  .lg\:dq-left-0 {
    left: 0px;
  }

  .lg\:dq-left-1 {
    left: 0.25rem;
  }

  .lg\:dq-left-2 {
    left: 0.5rem;
  }

  .lg\:dq-left-3 {
    left: 0.75rem;
  }

  .lg\:dq-left-4 {
    left: 1rem;
  }

  .lg\:dq-left-5 {
    left: 1.25rem;
  }

  .lg\:dq-left-6 {
    left: 1.5rem;
  }

  .lg\:dq-left-7 {
    left: 1.75rem;
  }

  .lg\:dq-left-8 {
    left: 2rem;
  }

  .lg\:dq-left-9 {
    left: 2.25rem;
  }

  .lg\:dq-left-10 {
    left: 2.5rem;
  }

  .lg\:dq-left-11 {
    left: 2.75rem;
  }

  .lg\:dq-left-12 {
    left: 3rem;
  }

  .lg\:dq-left-14 {
    left: 3.5rem;
  }

  .lg\:dq-left-16 {
    left: 4rem;
  }

  .lg\:dq-left-20 {
    left: 5rem;
  }

  .lg\:dq-left-24 {
    left: 6rem;
  }

  .lg\:dq-left-28 {
    left: 7rem;
  }

  .lg\:dq-left-32 {
    left: 8rem;
  }

  .lg\:dq-left-36 {
    left: 9rem;
  }

  .lg\:dq-left-40 {
    left: 10rem;
  }

  .lg\:dq-left-44 {
    left: 11rem;
  }

  .lg\:dq-left-48 {
    left: 12rem;
  }

  .lg\:dq-left-52 {
    left: 13rem;
  }

  .lg\:dq-left-56 {
    left: 14rem;
  }

  .lg\:dq-left-60 {
    left: 15rem;
  }

  .lg\:dq-left-64 {
    left: 16rem;
  }

  .lg\:dq-left-72 {
    left: 18rem;
  }

  .lg\:dq-left-80 {
    left: 20rem;
  }

  .lg\:dq-left-96 {
    left: 24rem;
  }

  .lg\:dq-left-auto {
    left: auto;
  }

  .lg\:dq-left-px {
    left: 1px;
  }

  .lg\:dq-left-0\.5 {
    left: 0.125rem;
  }

  .lg\:dq-left-1\.5 {
    left: 0.375rem;
  }

  .lg\:dq-left-2\.5 {
    left: 0.625rem;
  }

  .lg\:dq-left-3\.5 {
    left: 0.875rem;
  }

  .lg\:dq--left-0 {
    left: 0px;
  }

  .lg\:dq--left-1 {
    left: -0.25rem;
  }

  .lg\:dq--left-2 {
    left: -0.5rem;
  }

  .lg\:dq--left-3 {
    left: -0.75rem;
  }

  .lg\:dq--left-4 {
    left: -1rem;
  }

  .lg\:dq--left-5 {
    left: -1.25rem;
  }

  .lg\:dq--left-6 {
    left: -1.5rem;
  }

  .lg\:dq--left-7 {
    left: -1.75rem;
  }

  .lg\:dq--left-8 {
    left: -2rem;
  }

  .lg\:dq--left-9 {
    left: -2.25rem;
  }

  .lg\:dq--left-10 {
    left: -2.5rem;
  }

  .lg\:dq--left-11 {
    left: -2.75rem;
  }

  .lg\:dq--left-12 {
    left: -3rem;
  }

  .lg\:dq--left-14 {
    left: -3.5rem;
  }

  .lg\:dq--left-16 {
    left: -4rem;
  }

  .lg\:dq--left-20 {
    left: -5rem;
  }

  .lg\:dq--left-24 {
    left: -6rem;
  }

  .lg\:dq--left-28 {
    left: -7rem;
  }

  .lg\:dq--left-32 {
    left: -8rem;
  }

  .lg\:dq--left-36 {
    left: -9rem;
  }

  .lg\:dq--left-40 {
    left: -10rem;
  }

  .lg\:dq--left-44 {
    left: -11rem;
  }

  .lg\:dq--left-48 {
    left: -12rem;
  }

  .lg\:dq--left-52 {
    left: -13rem;
  }

  .lg\:dq--left-56 {
    left: -14rem;
  }

  .lg\:dq--left-60 {
    left: -15rem;
  }

  .lg\:dq--left-64 {
    left: -16rem;
  }

  .lg\:dq--left-72 {
    left: -18rem;
  }

  .lg\:dq--left-80 {
    left: -20rem;
  }

  .lg\:dq--left-96 {
    left: -24rem;
  }

  .lg\:dq--left-px {
    left: -1px;
  }

  .lg\:dq--left-0\.5 {
    left: -0.125rem;
  }

  .lg\:dq--left-1\.5 {
    left: -0.375rem;
  }

  .lg\:dq--left-2\.5 {
    left: -0.625rem;
  }

  .lg\:dq--left-3\.5 {
    left: -0.875rem;
  }

  .lg\:dq-left-1\/2 {
    left: 50%;
  }

  .lg\:dq-left-1\/3 {
    left: 33.333333%;
  }

  .lg\:dq-left-2\/3 {
    left: 66.666667%;
  }

  .lg\:dq-left-1\/4 {
    left: 25%;
  }

  .lg\:dq-left-2\/4 {
    left: 50%;
  }

  .lg\:dq-left-3\/4 {
    left: 75%;
  }

  .lg\:dq-left-full {
    left: 100%;
  }

  .lg\:dq--left-1\/2 {
    left: -50%;
  }

  .lg\:dq--left-1\/3 {
    left: -33.333333%;
  }

  .lg\:dq--left-2\/3 {
    left: -66.666667%;
  }

  .lg\:dq--left-1\/4 {
    left: -25%;
  }

  .lg\:dq--left-2\/4 {
    left: -50%;
  }

  .lg\:dq--left-3\/4 {
    left: -75%;
  }

  .lg\:dq--left-full {
    left: -100%;
  }

  .lg\:dq-isolate {
    isolation: isolate;
  }

  .lg\:dq-isolation-auto {
    isolation: auto;
  }

  .lg\:dq-z-0 {
    z-index: 0;
  }

  .lg\:dq-z-10 {
    z-index: 10;
  }

  .lg\:dq-z-20 {
    z-index: 20;
  }

  .lg\:dq-z-30 {
    z-index: 30;
  }

  .lg\:dq-z-40 {
    z-index: 40;
  }

  .lg\:dq-z-50 {
    z-index: 50;
  }

  .lg\:dq-z-auto {
    z-index: auto;
  }

  .lg\:focus-within\:dq-z-0:focus-within {
    z-index: 0;
  }

  .lg\:focus-within\:dq-z-10:focus-within {
    z-index: 10;
  }

  .lg\:focus-within\:dq-z-20:focus-within {
    z-index: 20;
  }

  .lg\:focus-within\:dq-z-30:focus-within {
    z-index: 30;
  }

  .lg\:focus-within\:dq-z-40:focus-within {
    z-index: 40;
  }

  .lg\:focus-within\:dq-z-50:focus-within {
    z-index: 50;
  }

  .lg\:focus-within\:dq-z-auto:focus-within {
    z-index: auto;
  }

  .lg\:focus\:dq-z-0:focus {
    z-index: 0;
  }

  .lg\:focus\:dq-z-10:focus {
    z-index: 10;
  }

  .lg\:focus\:dq-z-20:focus {
    z-index: 20;
  }

  .lg\:focus\:dq-z-30:focus {
    z-index: 30;
  }

  .lg\:focus\:dq-z-40:focus {
    z-index: 40;
  }

  .lg\:focus\:dq-z-50:focus {
    z-index: 50;
  }

  .lg\:focus\:dq-z-auto:focus {
    z-index: auto;
  }

  .lg\:dq-order-1 {
    order: 1;
  }

  .lg\:dq-order-2 {
    order: 2;
  }

  .lg\:dq-order-3 {
    order: 3;
  }

  .lg\:dq-order-4 {
    order: 4;
  }

  .lg\:dq-order-5 {
    order: 5;
  }

  .lg\:dq-order-6 {
    order: 6;
  }

  .lg\:dq-order-7 {
    order: 7;
  }

  .lg\:dq-order-8 {
    order: 8;
  }

  .lg\:dq-order-9 {
    order: 9;
  }

  .lg\:dq-order-10 {
    order: 10;
  }

  .lg\:dq-order-11 {
    order: 11;
  }

  .lg\:dq-order-12 {
    order: 12;
  }

  .lg\:dq-order-first {
    order: -9999;
  }

  .lg\:dq-order-last {
    order: 9999;
  }

  .lg\:dq-order-none {
    order: 0;
  }

  .lg\:dq-col-auto {
    grid-column: auto;
  }

  .lg\:dq-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:dq-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:dq-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:dq-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:dq-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:dq-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:dq-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:dq-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:dq-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:dq-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:dq-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:dq-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:dq-col-span-full {
    grid-column: 1 / -1;
  }

  .lg\:dq-col-start-1 {
    grid-column-start: 1;
  }

  .lg\:dq-col-start-2 {
    grid-column-start: 2;
  }

  .lg\:dq-col-start-3 {
    grid-column-start: 3;
  }

  .lg\:dq-col-start-4 {
    grid-column-start: 4;
  }

  .lg\:dq-col-start-5 {
    grid-column-start: 5;
  }

  .lg\:dq-col-start-6 {
    grid-column-start: 6;
  }

  .lg\:dq-col-start-7 {
    grid-column-start: 7;
  }

  .lg\:dq-col-start-8 {
    grid-column-start: 8;
  }

  .lg\:dq-col-start-9 {
    grid-column-start: 9;
  }

  .lg\:dq-col-start-10 {
    grid-column-start: 10;
  }

  .lg\:dq-col-start-11 {
    grid-column-start: 11;
  }

  .lg\:dq-col-start-12 {
    grid-column-start: 12;
  }

  .lg\:dq-col-start-13 {
    grid-column-start: 13;
  }

  .lg\:dq-col-start-auto {
    grid-column-start: auto;
  }

  .lg\:dq-col-end-1 {
    grid-column-end: 1;
  }

  .lg\:dq-col-end-2 {
    grid-column-end: 2;
  }

  .lg\:dq-col-end-3 {
    grid-column-end: 3;
  }

  .lg\:dq-col-end-4 {
    grid-column-end: 4;
  }

  .lg\:dq-col-end-5 {
    grid-column-end: 5;
  }

  .lg\:dq-col-end-6 {
    grid-column-end: 6;
  }

  .lg\:dq-col-end-7 {
    grid-column-end: 7;
  }

  .lg\:dq-col-end-8 {
    grid-column-end: 8;
  }

  .lg\:dq-col-end-9 {
    grid-column-end: 9;
  }

  .lg\:dq-col-end-10 {
    grid-column-end: 10;
  }

  .lg\:dq-col-end-11 {
    grid-column-end: 11;
  }

  .lg\:dq-col-end-12 {
    grid-column-end: 12;
  }

  .lg\:dq-col-end-13 {
    grid-column-end: 13;
  }

  .lg\:dq-col-end-auto {
    grid-column-end: auto;
  }

  .lg\:dq-row-auto {
    grid-row: auto;
  }

  .lg\:dq-row-span-1 {
    grid-row: span 1 / span 1;
  }

  .lg\:dq-row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:dq-row-span-3 {
    grid-row: span 3 / span 3;
  }

  .lg\:dq-row-span-4 {
    grid-row: span 4 / span 4;
  }

  .lg\:dq-row-span-5 {
    grid-row: span 5 / span 5;
  }

  .lg\:dq-row-span-6 {
    grid-row: span 6 / span 6;
  }

  .lg\:dq-row-span-full {
    grid-row: 1 / -1;
  }

  .lg\:dq-row-start-1 {
    grid-row-start: 1;
  }

  .lg\:dq-row-start-2 {
    grid-row-start: 2;
  }

  .lg\:dq-row-start-3 {
    grid-row-start: 3;
  }

  .lg\:dq-row-start-4 {
    grid-row-start: 4;
  }

  .lg\:dq-row-start-5 {
    grid-row-start: 5;
  }

  .lg\:dq-row-start-6 {
    grid-row-start: 6;
  }

  .lg\:dq-row-start-7 {
    grid-row-start: 7;
  }

  .lg\:dq-row-start-auto {
    grid-row-start: auto;
  }

  .lg\:dq-row-end-1 {
    grid-row-end: 1;
  }

  .lg\:dq-row-end-2 {
    grid-row-end: 2;
  }

  .lg\:dq-row-end-3 {
    grid-row-end: 3;
  }

  .lg\:dq-row-end-4 {
    grid-row-end: 4;
  }

  .lg\:dq-row-end-5 {
    grid-row-end: 5;
  }

  .lg\:dq-row-end-6 {
    grid-row-end: 6;
  }

  .lg\:dq-row-end-7 {
    grid-row-end: 7;
  }

  .lg\:dq-row-end-auto {
    grid-row-end: auto;
  }

  .lg\:dq-float-right {
    float: right;
  }

  .lg\:dq-float-left {
    float: left;
  }

  .lg\:dq-float-none {
    float: none;
  }

  .lg\:dq-clear-left {
    clear: left;
  }

  .lg\:dq-clear-right {
    clear: right;
  }

  .lg\:dq-clear-both {
    clear: both;
  }

  .lg\:dq-clear-none {
    clear: none;
  }

  .lg\:dq-m-0 {
    margin: 0px;
  }

  .lg\:dq-m-1 {
    margin: 0.25rem;
  }

  .lg\:dq-m-2 {
    margin: 0.5rem;
  }

  .lg\:dq-m-3 {
    margin: 0.75rem;
  }

  .lg\:dq-m-4 {
    margin: 1rem;
  }

  .lg\:dq-m-5 {
    margin: 1.25rem;
  }

  .lg\:dq-m-6 {
    margin: 1.5rem;
  }

  .lg\:dq-m-7 {
    margin: 1.75rem;
  }

  .lg\:dq-m-8 {
    margin: 2rem;
  }

  .lg\:dq-m-9 {
    margin: 2.25rem;
  }

  .lg\:dq-m-10 {
    margin: 2.5rem;
  }

  .lg\:dq-m-11 {
    margin: 2.75rem;
  }

  .lg\:dq-m-12 {
    margin: 3rem;
  }

  .lg\:dq-m-14 {
    margin: 3.5rem;
  }

  .lg\:dq-m-16 {
    margin: 4rem;
  }

  .lg\:dq-m-20 {
    margin: 5rem;
  }

  .lg\:dq-m-24 {
    margin: 6rem;
  }

  .lg\:dq-m-28 {
    margin: 7rem;
  }

  .lg\:dq-m-32 {
    margin: 8rem;
  }

  .lg\:dq-m-36 {
    margin: 9rem;
  }

  .lg\:dq-m-40 {
    margin: 10rem;
  }

  .lg\:dq-m-44 {
    margin: 11rem;
  }

  .lg\:dq-m-48 {
    margin: 12rem;
  }

  .lg\:dq-m-52 {
    margin: 13rem;
  }

  .lg\:dq-m-56 {
    margin: 14rem;
  }

  .lg\:dq-m-60 {
    margin: 15rem;
  }

  .lg\:dq-m-64 {
    margin: 16rem;
  }

  .lg\:dq-m-72 {
    margin: 18rem;
  }

  .lg\:dq-m-80 {
    margin: 20rem;
  }

  .lg\:dq-m-96 {
    margin: 24rem;
  }

  .lg\:dq-m-auto {
    margin: auto;
  }

  .lg\:dq-m-px {
    margin: 1px;
  }

  .lg\:dq-m-0\.5 {
    margin: 0.125rem;
  }

  .lg\:dq-m-1\.5 {
    margin: 0.375rem;
  }

  .lg\:dq-m-2\.5 {
    margin: 0.625rem;
  }

  .lg\:dq-m-3\.5 {
    margin: 0.875rem;
  }

  .lg\:dq--m-0 {
    margin: 0px;
  }

  .lg\:dq--m-1 {
    margin: -0.25rem;
  }

  .lg\:dq--m-2 {
    margin: -0.5rem;
  }

  .lg\:dq--m-3 {
    margin: -0.75rem;
  }

  .lg\:dq--m-4 {
    margin: -1rem;
  }

  .lg\:dq--m-5 {
    margin: -1.25rem;
  }

  .lg\:dq--m-6 {
    margin: -1.5rem;
  }

  .lg\:dq--m-7 {
    margin: -1.75rem;
  }

  .lg\:dq--m-8 {
    margin: -2rem;
  }

  .lg\:dq--m-9 {
    margin: -2.25rem;
  }

  .lg\:dq--m-10 {
    margin: -2.5rem;
  }

  .lg\:dq--m-11 {
    margin: -2.75rem;
  }

  .lg\:dq--m-12 {
    margin: -3rem;
  }

  .lg\:dq--m-14 {
    margin: -3.5rem;
  }

  .lg\:dq--m-16 {
    margin: -4rem;
  }

  .lg\:dq--m-20 {
    margin: -5rem;
  }

  .lg\:dq--m-24 {
    margin: -6rem;
  }

  .lg\:dq--m-28 {
    margin: -7rem;
  }

  .lg\:dq--m-32 {
    margin: -8rem;
  }

  .lg\:dq--m-36 {
    margin: -9rem;
  }

  .lg\:dq--m-40 {
    margin: -10rem;
  }

  .lg\:dq--m-44 {
    margin: -11rem;
  }

  .lg\:dq--m-48 {
    margin: -12rem;
  }

  .lg\:dq--m-52 {
    margin: -13rem;
  }

  .lg\:dq--m-56 {
    margin: -14rem;
  }

  .lg\:dq--m-60 {
    margin: -15rem;
  }

  .lg\:dq--m-64 {
    margin: -16rem;
  }

  .lg\:dq--m-72 {
    margin: -18rem;
  }

  .lg\:dq--m-80 {
    margin: -20rem;
  }

  .lg\:dq--m-96 {
    margin: -24rem;
  }

  .lg\:dq--m-px {
    margin: -1px;
  }

  .lg\:dq--m-0\.5 {
    margin: -0.125rem;
  }

  .lg\:dq--m-1\.5 {
    margin: -0.375rem;
  }

  .lg\:dq--m-2\.5 {
    margin: -0.625rem;
  }

  .lg\:dq--m-3\.5 {
    margin: -0.875rem;
  }

  .lg\:dq-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lg\:dq-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .lg\:dq-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .lg\:dq-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .lg\:dq-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:dq-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:dq-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:dq-mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .lg\:dq-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:dq-mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .lg\:dq-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:dq-mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .lg\:dq-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:dq-mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .lg\:dq-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:dq-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:dq-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:dq-mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .lg\:dq-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg\:dq-mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .lg\:dq-mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .lg\:dq-mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .lg\:dq-mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .lg\:dq-mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .lg\:dq-mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .lg\:dq-mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .lg\:dq-mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .lg\:dq-mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .lg\:dq-mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .lg\:dq-mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .lg\:dq-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:dq-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .lg\:dq-mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }

  .lg\:dq-mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }

  .lg\:dq-mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .lg\:dq-mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }

  .lg\:dq--mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lg\:dq--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .lg\:dq--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .lg\:dq--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .lg\:dq--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg\:dq--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:dq--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .lg\:dq--mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .lg\:dq--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:dq--mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .lg\:dq--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .lg\:dq--mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .lg\:dq--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg\:dq--mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .lg\:dq--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg\:dq--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .lg\:dq--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .lg\:dq--mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .lg\:dq--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .lg\:dq--mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .lg\:dq--mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .lg\:dq--mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .lg\:dq--mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .lg\:dq--mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .lg\:dq--mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .lg\:dq--mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .lg\:dq--mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .lg\:dq--mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .lg\:dq--mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .lg\:dq--mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .lg\:dq--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .lg\:dq--mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }

  .lg\:dq--mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }

  .lg\:dq--mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }

  .lg\:dq--mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }

  .lg\:dq-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .lg\:dq-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .lg\:dq-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .lg\:dq-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .lg\:dq-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:dq-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:dq-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:dq-my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .lg\:dq-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:dq-my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .lg\:dq-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:dq-my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .lg\:dq-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:dq-my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .lg\:dq-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:dq-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:dq-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg\:dq-my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .lg\:dq-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:dq-my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .lg\:dq-my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .lg\:dq-my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .lg\:dq-my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .lg\:dq-my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .lg\:dq-my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .lg\:dq-my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .lg\:dq-my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .lg\:dq-my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .lg\:dq-my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .lg\:dq-my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .lg\:dq-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .lg\:dq-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .lg\:dq-my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }

  .lg\:dq-my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }

  .lg\:dq-my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .lg\:dq-my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }

  .lg\:dq--my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .lg\:dq--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .lg\:dq--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .lg\:dq--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .lg\:dq--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg\:dq--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .lg\:dq--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .lg\:dq--my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .lg\:dq--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg\:dq--my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .lg\:dq--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .lg\:dq--my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .lg\:dq--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg\:dq--my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .lg\:dq--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .lg\:dq--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .lg\:dq--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .lg\:dq--my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .lg\:dq--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .lg\:dq--my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .lg\:dq--my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .lg\:dq--my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .lg\:dq--my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .lg\:dq--my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .lg\:dq--my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .lg\:dq--my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .lg\:dq--my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .lg\:dq--my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .lg\:dq--my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .lg\:dq--my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .lg\:dq--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .lg\:dq--my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }

  .lg\:dq--my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }

  .lg\:dq--my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }

  .lg\:dq--my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }

  .lg\:dq-mt-0 {
    margin-top: 0px;
  }

  .lg\:dq-mt-1 {
    margin-top: 0.25rem;
  }

  .lg\:dq-mt-2 {
    margin-top: 0.5rem;
  }

  .lg\:dq-mt-3 {
    margin-top: 0.75rem;
  }

  .lg\:dq-mt-4 {
    margin-top: 1rem;
  }

  .lg\:dq-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:dq-mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:dq-mt-7 {
    margin-top: 1.75rem;
  }

  .lg\:dq-mt-8 {
    margin-top: 2rem;
  }

  .lg\:dq-mt-9 {
    margin-top: 2.25rem;
  }

  .lg\:dq-mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:dq-mt-11 {
    margin-top: 2.75rem;
  }

  .lg\:dq-mt-12 {
    margin-top: 3rem;
  }

  .lg\:dq-mt-14 {
    margin-top: 3.5rem;
  }

  .lg\:dq-mt-16 {
    margin-top: 4rem;
  }

  .lg\:dq-mt-20 {
    margin-top: 5rem;
  }

  .lg\:dq-mt-24 {
    margin-top: 6rem;
  }

  .lg\:dq-mt-28 {
    margin-top: 7rem;
  }

  .lg\:dq-mt-32 {
    margin-top: 8rem;
  }

  .lg\:dq-mt-36 {
    margin-top: 9rem;
  }

  .lg\:dq-mt-40 {
    margin-top: 10rem;
  }

  .lg\:dq-mt-44 {
    margin-top: 11rem;
  }

  .lg\:dq-mt-48 {
    margin-top: 12rem;
  }

  .lg\:dq-mt-52 {
    margin-top: 13rem;
  }

  .lg\:dq-mt-56 {
    margin-top: 14rem;
  }

  .lg\:dq-mt-60 {
    margin-top: 15rem;
  }

  .lg\:dq-mt-64 {
    margin-top: 16rem;
  }

  .lg\:dq-mt-72 {
    margin-top: 18rem;
  }

  .lg\:dq-mt-80 {
    margin-top: 20rem;
  }

  .lg\:dq-mt-96 {
    margin-top: 24rem;
  }

  .lg\:dq-mt-auto {
    margin-top: auto;
  }

  .lg\:dq-mt-px {
    margin-top: 1px;
  }

  .lg\:dq-mt-0\.5 {
    margin-top: 0.125rem;
  }

  .lg\:dq-mt-1\.5 {
    margin-top: 0.375rem;
  }

  .lg\:dq-mt-2\.5 {
    margin-top: 0.625rem;
  }

  .lg\:dq-mt-3\.5 {
    margin-top: 0.875rem;
  }

  .lg\:dq--mt-0 {
    margin-top: 0px;
  }

  .lg\:dq--mt-1 {
    margin-top: -0.25rem;
  }

  .lg\:dq--mt-2 {
    margin-top: -0.5rem;
  }

  .lg\:dq--mt-3 {
    margin-top: -0.75rem;
  }

  .lg\:dq--mt-4 {
    margin-top: -1rem;
  }

  .lg\:dq--mt-5 {
    margin-top: -1.25rem;
  }

  .lg\:dq--mt-6 {
    margin-top: -1.5rem;
  }

  .lg\:dq--mt-7 {
    margin-top: -1.75rem;
  }

  .lg\:dq--mt-8 {
    margin-top: -2rem;
  }

  .lg\:dq--mt-9 {
    margin-top: -2.25rem;
  }

  .lg\:dq--mt-10 {
    margin-top: -2.5rem;
  }

  .lg\:dq--mt-11 {
    margin-top: -2.75rem;
  }

  .lg\:dq--mt-12 {
    margin-top: -3rem;
  }

  .lg\:dq--mt-14 {
    margin-top: -3.5rem;
  }

  .lg\:dq--mt-16 {
    margin-top: -4rem;
  }

  .lg\:dq--mt-20 {
    margin-top: -5rem;
  }

  .lg\:dq--mt-24 {
    margin-top: -6rem;
  }

  .lg\:dq--mt-28 {
    margin-top: -7rem;
  }

  .lg\:dq--mt-32 {
    margin-top: -8rem;
  }

  .lg\:dq--mt-36 {
    margin-top: -9rem;
  }

  .lg\:dq--mt-40 {
    margin-top: -10rem;
  }

  .lg\:dq--mt-44 {
    margin-top: -11rem;
  }

  .lg\:dq--mt-48 {
    margin-top: -12rem;
  }

  .lg\:dq--mt-52 {
    margin-top: -13rem;
  }

  .lg\:dq--mt-56 {
    margin-top: -14rem;
  }

  .lg\:dq--mt-60 {
    margin-top: -15rem;
  }

  .lg\:dq--mt-64 {
    margin-top: -16rem;
  }

  .lg\:dq--mt-72 {
    margin-top: -18rem;
  }

  .lg\:dq--mt-80 {
    margin-top: -20rem;
  }

  .lg\:dq--mt-96 {
    margin-top: -24rem;
  }

  .lg\:dq--mt-px {
    margin-top: -1px;
  }

  .lg\:dq--mt-0\.5 {
    margin-top: -0.125rem;
  }

  .lg\:dq--mt-1\.5 {
    margin-top: -0.375rem;
  }

  .lg\:dq--mt-2\.5 {
    margin-top: -0.625rem;
  }

  .lg\:dq--mt-3\.5 {
    margin-top: -0.875rem;
  }

  .lg\:dq-mr-0 {
    margin-right: 0px;
  }

  .lg\:dq-mr-1 {
    margin-right: 0.25rem;
  }

  .lg\:dq-mr-2 {
    margin-right: 0.5rem;
  }

  .lg\:dq-mr-3 {
    margin-right: 0.75rem;
  }

  .lg\:dq-mr-4 {
    margin-right: 1rem;
  }

  .lg\:dq-mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:dq-mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:dq-mr-7 {
    margin-right: 1.75rem;
  }

  .lg\:dq-mr-8 {
    margin-right: 2rem;
  }

  .lg\:dq-mr-9 {
    margin-right: 2.25rem;
  }

  .lg\:dq-mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:dq-mr-11 {
    margin-right: 2.75rem;
  }

  .lg\:dq-mr-12 {
    margin-right: 3rem;
  }

  .lg\:dq-mr-14 {
    margin-right: 3.5rem;
  }

  .lg\:dq-mr-16 {
    margin-right: 4rem;
  }

  .lg\:dq-mr-20 {
    margin-right: 5rem;
  }

  .lg\:dq-mr-24 {
    margin-right: 6rem;
  }

  .lg\:dq-mr-28 {
    margin-right: 7rem;
  }

  .lg\:dq-mr-32 {
    margin-right: 8rem;
  }

  .lg\:dq-mr-36 {
    margin-right: 9rem;
  }

  .lg\:dq-mr-40 {
    margin-right: 10rem;
  }

  .lg\:dq-mr-44 {
    margin-right: 11rem;
  }

  .lg\:dq-mr-48 {
    margin-right: 12rem;
  }

  .lg\:dq-mr-52 {
    margin-right: 13rem;
  }

  .lg\:dq-mr-56 {
    margin-right: 14rem;
  }

  .lg\:dq-mr-60 {
    margin-right: 15rem;
  }

  .lg\:dq-mr-64 {
    margin-right: 16rem;
  }

  .lg\:dq-mr-72 {
    margin-right: 18rem;
  }

  .lg\:dq-mr-80 {
    margin-right: 20rem;
  }

  .lg\:dq-mr-96 {
    margin-right: 24rem;
  }

  .lg\:dq-mr-auto {
    margin-right: auto;
  }

  .lg\:dq-mr-px {
    margin-right: 1px;
  }

  .lg\:dq-mr-0\.5 {
    margin-right: 0.125rem;
  }

  .lg\:dq-mr-1\.5 {
    margin-right: 0.375rem;
  }

  .lg\:dq-mr-2\.5 {
    margin-right: 0.625rem;
  }

  .lg\:dq-mr-3\.5 {
    margin-right: 0.875rem;
  }

  .lg\:dq--mr-0 {
    margin-right: 0px;
  }

  .lg\:dq--mr-1 {
    margin-right: -0.25rem;
  }

  .lg\:dq--mr-2 {
    margin-right: -0.5rem;
  }

  .lg\:dq--mr-3 {
    margin-right: -0.75rem;
  }

  .lg\:dq--mr-4 {
    margin-right: -1rem;
  }

  .lg\:dq--mr-5 {
    margin-right: -1.25rem;
  }

  .lg\:dq--mr-6 {
    margin-right: -1.5rem;
  }

  .lg\:dq--mr-7 {
    margin-right: -1.75rem;
  }

  .lg\:dq--mr-8 {
    margin-right: -2rem;
  }

  .lg\:dq--mr-9 {
    margin-right: -2.25rem;
  }

  .lg\:dq--mr-10 {
    margin-right: -2.5rem;
  }

  .lg\:dq--mr-11 {
    margin-right: -2.75rem;
  }

  .lg\:dq--mr-12 {
    margin-right: -3rem;
  }

  .lg\:dq--mr-14 {
    margin-right: -3.5rem;
  }

  .lg\:dq--mr-16 {
    margin-right: -4rem;
  }

  .lg\:dq--mr-20 {
    margin-right: -5rem;
  }

  .lg\:dq--mr-24 {
    margin-right: -6rem;
  }

  .lg\:dq--mr-28 {
    margin-right: -7rem;
  }

  .lg\:dq--mr-32 {
    margin-right: -8rem;
  }

  .lg\:dq--mr-36 {
    margin-right: -9rem;
  }

  .lg\:dq--mr-40 {
    margin-right: -10rem;
  }

  .lg\:dq--mr-44 {
    margin-right: -11rem;
  }

  .lg\:dq--mr-48 {
    margin-right: -12rem;
  }

  .lg\:dq--mr-52 {
    margin-right: -13rem;
  }

  .lg\:dq--mr-56 {
    margin-right: -14rem;
  }

  .lg\:dq--mr-60 {
    margin-right: -15rem;
  }

  .lg\:dq--mr-64 {
    margin-right: -16rem;
  }

  .lg\:dq--mr-72 {
    margin-right: -18rem;
  }

  .lg\:dq--mr-80 {
    margin-right: -20rem;
  }

  .lg\:dq--mr-96 {
    margin-right: -24rem;
  }

  .lg\:dq--mr-px {
    margin-right: -1px;
  }

  .lg\:dq--mr-0\.5 {
    margin-right: -0.125rem;
  }

  .lg\:dq--mr-1\.5 {
    margin-right: -0.375rem;
  }

  .lg\:dq--mr-2\.5 {
    margin-right: -0.625rem;
  }

  .lg\:dq--mr-3\.5 {
    margin-right: -0.875rem;
  }

  .lg\:dq-mb-0 {
    margin-bottom: 0px;
  }

  .lg\:dq-mb-1 {
    margin-bottom: 0.25rem;
  }

  .lg\:dq-mb-2 {
    margin-bottom: 0.5rem;
  }

  .lg\:dq-mb-3 {
    margin-bottom: 0.75rem;
  }

  .lg\:dq-mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:dq-mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:dq-mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:dq-mb-7 {
    margin-bottom: 1.75rem;
  }

  .lg\:dq-mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:dq-mb-9 {
    margin-bottom: 2.25rem;
  }

  .lg\:dq-mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:dq-mb-11 {
    margin-bottom: 2.75rem;
  }

  .lg\:dq-mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:dq-mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:dq-mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:dq-mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:dq-mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:dq-mb-28 {
    margin-bottom: 7rem;
  }

  .lg\:dq-mb-32 {
    margin-bottom: 8rem;
  }

  .lg\:dq-mb-36 {
    margin-bottom: 9rem;
  }

  .lg\:dq-mb-40 {
    margin-bottom: 10rem;
  }

  .lg\:dq-mb-44 {
    margin-bottom: 11rem;
  }

  .lg\:dq-mb-48 {
    margin-bottom: 12rem;
  }

  .lg\:dq-mb-52 {
    margin-bottom: 13rem;
  }

  .lg\:dq-mb-56 {
    margin-bottom: 14rem;
  }

  .lg\:dq-mb-60 {
    margin-bottom: 15rem;
  }

  .lg\:dq-mb-64 {
    margin-bottom: 16rem;
  }

  .lg\:dq-mb-72 {
    margin-bottom: 18rem;
  }

  .lg\:dq-mb-80 {
    margin-bottom: 20rem;
  }

  .lg\:dq-mb-96 {
    margin-bottom: 24rem;
  }

  .lg\:dq-mb-auto {
    margin-bottom: auto;
  }

  .lg\:dq-mb-px {
    margin-bottom: 1px;
  }

  .lg\:dq-mb-0\.5 {
    margin-bottom: 0.125rem;
  }

  .lg\:dq-mb-1\.5 {
    margin-bottom: 0.375rem;
  }

  .lg\:dq-mb-2\.5 {
    margin-bottom: 0.625rem;
  }

  .lg\:dq-mb-3\.5 {
    margin-bottom: 0.875rem;
  }

  .lg\:dq--mb-0 {
    margin-bottom: 0px;
  }

  .lg\:dq--mb-1 {
    margin-bottom: -0.25rem;
  }

  .lg\:dq--mb-2 {
    margin-bottom: -0.5rem;
  }

  .lg\:dq--mb-3 {
    margin-bottom: -0.75rem;
  }

  .lg\:dq--mb-4 {
    margin-bottom: -1rem;
  }

  .lg\:dq--mb-5 {
    margin-bottom: -1.25rem;
  }

  .lg\:dq--mb-6 {
    margin-bottom: -1.5rem;
  }

  .lg\:dq--mb-7 {
    margin-bottom: -1.75rem;
  }

  .lg\:dq--mb-8 {
    margin-bottom: -2rem;
  }

  .lg\:dq--mb-9 {
    margin-bottom: -2.25rem;
  }

  .lg\:dq--mb-10 {
    margin-bottom: -2.5rem;
  }

  .lg\:dq--mb-11 {
    margin-bottom: -2.75rem;
  }

  .lg\:dq--mb-12 {
    margin-bottom: -3rem;
  }

  .lg\:dq--mb-14 {
    margin-bottom: -3.5rem;
  }

  .lg\:dq--mb-16 {
    margin-bottom: -4rem;
  }

  .lg\:dq--mb-20 {
    margin-bottom: -5rem;
  }

  .lg\:dq--mb-24 {
    margin-bottom: -6rem;
  }

  .lg\:dq--mb-28 {
    margin-bottom: -7rem;
  }

  .lg\:dq--mb-32 {
    margin-bottom: -8rem;
  }

  .lg\:dq--mb-36 {
    margin-bottom: -9rem;
  }

  .lg\:dq--mb-40 {
    margin-bottom: -10rem;
  }

  .lg\:dq--mb-44 {
    margin-bottom: -11rem;
  }

  .lg\:dq--mb-48 {
    margin-bottom: -12rem;
  }

  .lg\:dq--mb-52 {
    margin-bottom: -13rem;
  }

  .lg\:dq--mb-56 {
    margin-bottom: -14rem;
  }

  .lg\:dq--mb-60 {
    margin-bottom: -15rem;
  }

  .lg\:dq--mb-64 {
    margin-bottom: -16rem;
  }

  .lg\:dq--mb-72 {
    margin-bottom: -18rem;
  }

  .lg\:dq--mb-80 {
    margin-bottom: -20rem;
  }

  .lg\:dq--mb-96 {
    margin-bottom: -24rem;
  }

  .lg\:dq--mb-px {
    margin-bottom: -1px;
  }

  .lg\:dq--mb-0\.5 {
    margin-bottom: -0.125rem;
  }

  .lg\:dq--mb-1\.5 {
    margin-bottom: -0.375rem;
  }

  .lg\:dq--mb-2\.5 {
    margin-bottom: -0.625rem;
  }

  .lg\:dq--mb-3\.5 {
    margin-bottom: -0.875rem;
  }

  .lg\:dq-ml-0 {
    margin-left: 0px;
  }

  .lg\:dq-ml-1 {
    margin-left: 0.25rem;
  }

  .lg\:dq-ml-2 {
    margin-left: 0.5rem;
  }

  .lg\:dq-ml-3 {
    margin-left: 0.75rem;
  }

  .lg\:dq-ml-4 {
    margin-left: 1rem;
  }

  .lg\:dq-ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:dq-ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:dq-ml-7 {
    margin-left: 1.75rem;
  }

  .lg\:dq-ml-8 {
    margin-left: 2rem;
  }

  .lg\:dq-ml-9 {
    margin-left: 2.25rem;
  }

  .lg\:dq-ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:dq-ml-11 {
    margin-left: 2.75rem;
  }

  .lg\:dq-ml-12 {
    margin-left: 3rem;
  }

  .lg\:dq-ml-14 {
    margin-left: 3.5rem;
  }

  .lg\:dq-ml-16 {
    margin-left: 4rem;
  }

  .lg\:dq-ml-20 {
    margin-left: 5rem;
  }

  .lg\:dq-ml-24 {
    margin-left: 6rem;
  }

  .lg\:dq-ml-28 {
    margin-left: 7rem;
  }

  .lg\:dq-ml-32 {
    margin-left: 8rem;
  }

  .lg\:dq-ml-36 {
    margin-left: 9rem;
  }

  .lg\:dq-ml-40 {
    margin-left: 10rem;
  }

  .lg\:dq-ml-44 {
    margin-left: 11rem;
  }

  .lg\:dq-ml-48 {
    margin-left: 12rem;
  }

  .lg\:dq-ml-52 {
    margin-left: 13rem;
  }

  .lg\:dq-ml-56 {
    margin-left: 14rem;
  }

  .lg\:dq-ml-60 {
    margin-left: 15rem;
  }

  .lg\:dq-ml-64 {
    margin-left: 16rem;
  }

  .lg\:dq-ml-72 {
    margin-left: 18rem;
  }

  .lg\:dq-ml-80 {
    margin-left: 20rem;
  }

  .lg\:dq-ml-96 {
    margin-left: 24rem;
  }

  .lg\:dq-ml-auto {
    margin-left: auto;
  }

  .lg\:dq-ml-px {
    margin-left: 1px;
  }

  .lg\:dq-ml-0\.5 {
    margin-left: 0.125rem;
  }

  .lg\:dq-ml-1\.5 {
    margin-left: 0.375rem;
  }

  .lg\:dq-ml-2\.5 {
    margin-left: 0.625rem;
  }

  .lg\:dq-ml-3\.5 {
    margin-left: 0.875rem;
  }

  .lg\:dq--ml-0 {
    margin-left: 0px;
  }

  .lg\:dq--ml-1 {
    margin-left: -0.25rem;
  }

  .lg\:dq--ml-2 {
    margin-left: -0.5rem;
  }

  .lg\:dq--ml-3 {
    margin-left: -0.75rem;
  }

  .lg\:dq--ml-4 {
    margin-left: -1rem;
  }

  .lg\:dq--ml-5 {
    margin-left: -1.25rem;
  }

  .lg\:dq--ml-6 {
    margin-left: -1.5rem;
  }

  .lg\:dq--ml-7 {
    margin-left: -1.75rem;
  }

  .lg\:dq--ml-8 {
    margin-left: -2rem;
  }

  .lg\:dq--ml-9 {
    margin-left: -2.25rem;
  }

  .lg\:dq--ml-10 {
    margin-left: -2.5rem;
  }

  .lg\:dq--ml-11 {
    margin-left: -2.75rem;
  }

  .lg\:dq--ml-12 {
    margin-left: -3rem;
  }

  .lg\:dq--ml-14 {
    margin-left: -3.5rem;
  }

  .lg\:dq--ml-16 {
    margin-left: -4rem;
  }

  .lg\:dq--ml-20 {
    margin-left: -5rem;
  }

  .lg\:dq--ml-24 {
    margin-left: -6rem;
  }

  .lg\:dq--ml-28 {
    margin-left: -7rem;
  }

  .lg\:dq--ml-32 {
    margin-left: -8rem;
  }

  .lg\:dq--ml-36 {
    margin-left: -9rem;
  }

  .lg\:dq--ml-40 {
    margin-left: -10rem;
  }

  .lg\:dq--ml-44 {
    margin-left: -11rem;
  }

  .lg\:dq--ml-48 {
    margin-left: -12rem;
  }

  .lg\:dq--ml-52 {
    margin-left: -13rem;
  }

  .lg\:dq--ml-56 {
    margin-left: -14rem;
  }

  .lg\:dq--ml-60 {
    margin-left: -15rem;
  }

  .lg\:dq--ml-64 {
    margin-left: -16rem;
  }

  .lg\:dq--ml-72 {
    margin-left: -18rem;
  }

  .lg\:dq--ml-80 {
    margin-left: -20rem;
  }

  .lg\:dq--ml-96 {
    margin-left: -24rem;
  }

  .lg\:dq--ml-px {
    margin-left: -1px;
  }

  .lg\:dq--ml-0\.5 {
    margin-left: -0.125rem;
  }

  .lg\:dq--ml-1\.5 {
    margin-left: -0.375rem;
  }

  .lg\:dq--ml-2\.5 {
    margin-left: -0.625rem;
  }

  .lg\:dq--ml-3\.5 {
    margin-left: -0.875rem;
  }

  .lg\:dq-box-border {
    box-sizing: border-box;
  }

  .lg\:dq-box-content {
    box-sizing: content-box;
  }

  .lg\:dq-block {
    display: block;
  }

  .lg\:dq-inline-block {
    display: inline-block;
  }

  .lg\:dq-inline {
    display: inline;
  }

  .lg\:dq-flex {
    display: flex;
  }

  .lg\:dq-inline-flex {
    display: inline-flex;
  }

  .lg\:dq-table {
    display: table;
  }

  .lg\:dq-inline-table {
    display: inline-table;
  }

  .lg\:dq-table-caption {
    display: table-caption;
  }

  .lg\:dq-table-cell {
    display: table-cell;
  }

  .lg\:dq-table-column {
    display: table-column;
  }

  .lg\:dq-table-column-group {
    display: table-column-group;
  }

  .lg\:dq-table-footer-group {
    display: table-footer-group;
  }

  .lg\:dq-table-header-group {
    display: table-header-group;
  }

  .lg\:dq-table-row-group {
    display: table-row-group;
  }

  .lg\:dq-table-row {
    display: table-row;
  }

  .lg\:dq-flow-root {
    display: flow-root;
  }

  .lg\:dq-grid {
    display: grid;
  }

  .lg\:dq-inline-grid {
    display: inline-grid;
  }

  .lg\:dq-contents {
    display: contents;
  }

  .lg\:dq-list-item {
    display: list-item;
  }

  .lg\:dq-hidden {
    display: none;
  }

  .lg\:dq-h-0 {
    height: 0px;
  }

  .lg\:dq-h-1 {
    height: 0.25rem;
  }

  .lg\:dq-h-2 {
    height: 0.5rem;
  }

  .lg\:dq-h-3 {
    height: 0.75rem;
  }

  .lg\:dq-h-4 {
    height: 1rem;
  }

  .lg\:dq-h-5 {
    height: 1.25rem;
  }

  .lg\:dq-h-6 {
    height: 1.5rem;
  }

  .lg\:dq-h-7 {
    height: 1.75rem;
  }

  .lg\:dq-h-8 {
    height: 2rem;
  }

  .lg\:dq-h-9 {
    height: 2.25rem;
  }

  .lg\:dq-h-10 {
    height: 2.5rem;
  }

  .lg\:dq-h-11 {
    height: 2.75rem;
  }

  .lg\:dq-h-12 {
    height: 3rem;
  }

  .lg\:dq-h-14 {
    height: 3.5rem;
  }

  .lg\:dq-h-16 {
    height: 4rem;
  }

  .lg\:dq-h-20 {
    height: 5rem;
  }

  .lg\:dq-h-24 {
    height: 6rem;
  }

  .lg\:dq-h-28 {
    height: 7rem;
  }

  .lg\:dq-h-32 {
    height: 8rem;
  }

  .lg\:dq-h-36 {
    height: 9rem;
  }

  .lg\:dq-h-40 {
    height: 10rem;
  }

  .lg\:dq-h-44 {
    height: 11rem;
  }

  .lg\:dq-h-48 {
    height: 12rem;
  }

  .lg\:dq-h-52 {
    height: 13rem;
  }

  .lg\:dq-h-56 {
    height: 14rem;
  }

  .lg\:dq-h-60 {
    height: 15rem;
  }

  .lg\:dq-h-64 {
    height: 16rem;
  }

  .lg\:dq-h-72 {
    height: 18rem;
  }

  .lg\:dq-h-80 {
    height: 20rem;
  }

  .lg\:dq-h-96 {
    height: 24rem;
  }

  .lg\:dq-h-auto {
    height: auto;
  }

  .lg\:dq-h-px {
    height: 1px;
  }

  .lg\:dq-h-0\.5 {
    height: 0.125rem;
  }

  .lg\:dq-h-1\.5 {
    height: 0.375rem;
  }

  .lg\:dq-h-2\.5 {
    height: 0.625rem;
  }

  .lg\:dq-h-3\.5 {
    height: 0.875rem;
  }

  .lg\:dq-h-1\/2 {
    height: 50%;
  }

  .lg\:dq-h-1\/3 {
    height: 33.333333%;
  }

  .lg\:dq-h-2\/3 {
    height: 66.666667%;
  }

  .lg\:dq-h-1\/4 {
    height: 25%;
  }

  .lg\:dq-h-2\/4 {
    height: 50%;
  }

  .lg\:dq-h-3\/4 {
    height: 75%;
  }

  .lg\:dq-h-1\/5 {
    height: 20%;
  }

  .lg\:dq-h-2\/5 {
    height: 40%;
  }

  .lg\:dq-h-3\/5 {
    height: 60%;
  }

  .lg\:dq-h-4\/5 {
    height: 80%;
  }

  .lg\:dq-h-1\/6 {
    height: 16.666667%;
  }

  .lg\:dq-h-2\/6 {
    height: 33.333333%;
  }

  .lg\:dq-h-3\/6 {
    height: 50%;
  }

  .lg\:dq-h-4\/6 {
    height: 66.666667%;
  }

  .lg\:dq-h-5\/6 {
    height: 83.333333%;
  }

  .lg\:dq-h-full {
    height: 100%;
  }

  .lg\:dq-h-screen {
    height: 100vh;
  }

  .lg\:dq-max-h-0 {
    max-height: 0px;
  }

  .lg\:dq-max-h-1 {
    max-height: 0.25rem;
  }

  .lg\:dq-max-h-2 {
    max-height: 0.5rem;
  }

  .lg\:dq-max-h-3 {
    max-height: 0.75rem;
  }

  .lg\:dq-max-h-4 {
    max-height: 1rem;
  }

  .lg\:dq-max-h-5 {
    max-height: 1.25rem;
  }

  .lg\:dq-max-h-6 {
    max-height: 1.5rem;
  }

  .lg\:dq-max-h-7 {
    max-height: 1.75rem;
  }

  .lg\:dq-max-h-8 {
    max-height: 2rem;
  }

  .lg\:dq-max-h-9 {
    max-height: 2.25rem;
  }

  .lg\:dq-max-h-10 {
    max-height: 2.5rem;
  }

  .lg\:dq-max-h-11 {
    max-height: 2.75rem;
  }

  .lg\:dq-max-h-12 {
    max-height: 3rem;
  }

  .lg\:dq-max-h-14 {
    max-height: 3.5rem;
  }

  .lg\:dq-max-h-16 {
    max-height: 4rem;
  }

  .lg\:dq-max-h-20 {
    max-height: 5rem;
  }

  .lg\:dq-max-h-24 {
    max-height: 6rem;
  }

  .lg\:dq-max-h-28 {
    max-height: 7rem;
  }

  .lg\:dq-max-h-32 {
    max-height: 8rem;
  }

  .lg\:dq-max-h-36 {
    max-height: 9rem;
  }

  .lg\:dq-max-h-40 {
    max-height: 10rem;
  }

  .lg\:dq-max-h-44 {
    max-height: 11rem;
  }

  .lg\:dq-max-h-48 {
    max-height: 12rem;
  }

  .lg\:dq-max-h-52 {
    max-height: 13rem;
  }

  .lg\:dq-max-h-56 {
    max-height: 14rem;
  }

  .lg\:dq-max-h-60 {
    max-height: 15rem;
  }

  .lg\:dq-max-h-64 {
    max-height: 16rem;
  }

  .lg\:dq-max-h-72 {
    max-height: 18rem;
  }

  .lg\:dq-max-h-80 {
    max-height: 20rem;
  }

  .lg\:dq-max-h-96 {
    max-height: 24rem;
  }

  .lg\:dq-max-h-px {
    max-height: 1px;
  }

  .lg\:dq-max-h-0\.5 {
    max-height: 0.125rem;
  }

  .lg\:dq-max-h-1\.5 {
    max-height: 0.375rem;
  }

  .lg\:dq-max-h-2\.5 {
    max-height: 0.625rem;
  }

  .lg\:dq-max-h-3\.5 {
    max-height: 0.875rem;
  }

  .lg\:dq-max-h-full {
    max-height: 100%;
  }

  .lg\:dq-max-h-screen {
    max-height: 100vh;
  }

  .lg\:dq-min-h-0 {
    min-height: 0px;
  }

  .lg\:dq-min-h-full {
    min-height: 100%;
  }

  .lg\:dq-min-h-screen {
    min-height: 100vh;
  }

  .lg\:dq-w-0 {
    width: 0px;
  }

  .lg\:dq-w-1 {
    width: 0.25rem;
  }

  .lg\:dq-w-2 {
    width: 0.5rem;
  }

  .lg\:dq-w-3 {
    width: 0.75rem;
  }

  .lg\:dq-w-4 {
    width: 1rem;
  }

  .lg\:dq-w-5 {
    width: 1.25rem;
  }

  .lg\:dq-w-6 {
    width: 1.5rem;
  }

  .lg\:dq-w-7 {
    width: 1.75rem;
  }

  .lg\:dq-w-8 {
    width: 2rem;
  }

  .lg\:dq-w-9 {
    width: 2.25rem;
  }

  .lg\:dq-w-10 {
    width: 2.5rem;
  }

  .lg\:dq-w-11 {
    width: 2.75rem;
  }

  .lg\:dq-w-12 {
    width: 3rem;
  }

  .lg\:dq-w-14 {
    width: 3.5rem;
  }

  .lg\:dq-w-16 {
    width: 4rem;
  }

  .lg\:dq-w-20 {
    width: 5rem;
  }

  .lg\:dq-w-24 {
    width: 6rem;
  }

  .lg\:dq-w-28 {
    width: 7rem;
  }

  .lg\:dq-w-32 {
    width: 8rem;
  }

  .lg\:dq-w-36 {
    width: 9rem;
  }

  .lg\:dq-w-40 {
    width: 10rem;
  }

  .lg\:dq-w-44 {
    width: 11rem;
  }

  .lg\:dq-w-48 {
    width: 12rem;
  }

  .lg\:dq-w-52 {
    width: 13rem;
  }

  .lg\:dq-w-56 {
    width: 14rem;
  }

  .lg\:dq-w-60 {
    width: 15rem;
  }

  .lg\:dq-w-64 {
    width: 16rem;
  }

  .lg\:dq-w-72 {
    width: 18rem;
  }

  .lg\:dq-w-80 {
    width: 20rem;
  }

  .lg\:dq-w-96 {
    width: 24rem;
  }

  .lg\:dq-w-auto {
    width: auto;
  }

  .lg\:dq-w-px {
    width: 1px;
  }

  .lg\:dq-w-0\.5 {
    width: 0.125rem;
  }

  .lg\:dq-w-1\.5 {
    width: 0.375rem;
  }

  .lg\:dq-w-2\.5 {
    width: 0.625rem;
  }

  .lg\:dq-w-3\.5 {
    width: 0.875rem;
  }

  .lg\:dq-w-1\/2 {
    width: 50%;
  }

  .lg\:dq-w-1\/3 {
    width: 33.333333%;
  }

  .lg\:dq-w-2\/3 {
    width: 66.666667%;
  }

  .lg\:dq-w-1\/4 {
    width: 25%;
  }

  .lg\:dq-w-2\/4 {
    width: 50%;
  }

  .lg\:dq-w-3\/4 {
    width: 75%;
  }

  .lg\:dq-w-1\/5 {
    width: 20%;
  }

  .lg\:dq-w-2\/5 {
    width: 40%;
  }

  .lg\:dq-w-3\/5 {
    width: 60%;
  }

  .lg\:dq-w-4\/5 {
    width: 80%;
  }

  .lg\:dq-w-1\/6 {
    width: 16.666667%;
  }

  .lg\:dq-w-2\/6 {
    width: 33.333333%;
  }

  .lg\:dq-w-3\/6 {
    width: 50%;
  }

  .lg\:dq-w-4\/6 {
    width: 66.666667%;
  }

  .lg\:dq-w-5\/6 {
    width: 83.333333%;
  }

  .lg\:dq-w-1\/12 {
    width: 8.333333%;
  }

  .lg\:dq-w-2\/12 {
    width: 16.666667%;
  }

  .lg\:dq-w-3\/12 {
    width: 25%;
  }

  .lg\:dq-w-4\/12 {
    width: 33.333333%;
  }

  .lg\:dq-w-5\/12 {
    width: 41.666667%;
  }

  .lg\:dq-w-6\/12 {
    width: 50%;
  }

  .lg\:dq-w-7\/12 {
    width: 58.333333%;
  }

  .lg\:dq-w-8\/12 {
    width: 66.666667%;
  }

  .lg\:dq-w-9\/12 {
    width: 75%;
  }

  .lg\:dq-w-10\/12 {
    width: 83.333333%;
  }

  .lg\:dq-w-11\/12 {
    width: 91.666667%;
  }

  .lg\:dq-w-full {
    width: 100%;
  }

  .lg\:dq-w-screen {
    width: 100vw;
  }

  .lg\:dq-w-min {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }

  .lg\:dq-w-max {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .lg\:dq-min-w-0 {
    min-width: 0px;
  }

  .lg\:dq-min-w-full {
    min-width: 100%;
  }

  .lg\:dq-min-w-min {
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
  }

  .lg\:dq-min-w-max {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
  }

  .lg\:dq-max-w-0 {
    max-width: 0rem;
  }

  .lg\:dq-max-w-none {
    max-width: none;
  }

  .lg\:dq-max-w-xs {
    max-width: 20rem;
  }

  .lg\:dq-max-w-sm {
    max-width: 24rem;
  }

  .lg\:dq-max-w-md {
    max-width: 28rem;
  }

  .lg\:dq-max-w-lg {
    max-width: 32rem;
  }

  .lg\:dq-max-w-xl {
    max-width: 36rem;
  }

  .lg\:dq-max-w-2xl {
    max-width: 42rem;
  }

  .lg\:dq-max-w-3xl {
    max-width: 48rem;
  }

  .lg\:dq-max-w-4xl {
    max-width: 56rem;
  }

  .lg\:dq-max-w-5xl {
    max-width: 64rem;
  }

  .lg\:dq-max-w-6xl {
    max-width: 72rem;
  }

  .lg\:dq-max-w-7xl {
    max-width: 80rem;
  }

  .lg\:dq-max-w-full {
    max-width: 100%;
  }

  .lg\:dq-max-w-min {
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
  }

  .lg\:dq-max-w-max {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }

  .lg\:dq-max-w-prose {
    max-width: 65ch;
  }

  .lg\:dq-max-w-screen-sm {
    max-width: 640px;
  }

  .lg\:dq-max-w-screen-md {
    max-width: 768px;
  }

  .lg\:dq-max-w-screen-lg {
    max-width: 1024px;
  }

  .lg\:dq-max-w-screen-xl {
    max-width: 1280px;
  }

  .lg\:dq-max-w-screen-2xl {
    max-width: 1536px;
  }

  .lg\:dq-flex-1 {
    flex: 1 1 0%;
  }

  .lg\:dq-flex-auto {
    flex: 1 1 auto;
  }

  .lg\:dq-flex-initial {
    flex: 0 1 auto;
  }

  .lg\:dq-flex-none {
    flex: none;
  }

  .lg\:dq-flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:dq-flex-shrink {
    flex-shrink: 1;
  }

  .lg\:dq-flex-grow-0 {
    flex-grow: 0;
  }

  .lg\:dq-flex-grow {
    flex-grow: 1;
  }

  .lg\:dq-table-auto {
    table-layout: auto;
  }

  .lg\:dq-table-fixed {
    table-layout: fixed;
  }

  .lg\:dq-border-collapse {
    border-collapse: collapse;
  }

  .lg\:dq-border-separate {
    border-collapse: separate;
  }

  .lg\:dq-origin-center {
    transform-origin: center;
  }

  .lg\:dq-origin-top {
    transform-origin: top;
  }

  .lg\:dq-origin-top-right {
    transform-origin: top right;
  }

  .lg\:dq-origin-right {
    transform-origin: right;
  }

  .lg\:dq-origin-bottom-right {
    transform-origin: bottom right;
  }

  .lg\:dq-origin-bottom {
    transform-origin: bottom;
  }

  .lg\:dq-origin-bottom-left {
    transform-origin: bottom left;
  }

  .lg\:dq-origin-left {
    transform-origin: left;
  }

  .lg\:dq-origin-top-left {
    transform-origin: top left;
  }

  .lg\:dq-transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:dq-transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:dq-transform-none {
    transform: none;
  }

  .lg\:dq-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .lg\:dq-translate-x-1 {
    --tw-translate-x: 0.25rem;
  }

  .lg\:dq-translate-x-2 {
    --tw-translate-x: 0.5rem;
  }

  .lg\:dq-translate-x-3 {
    --tw-translate-x: 0.75rem;
  }

  .lg\:dq-translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .lg\:dq-translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .lg\:dq-translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .lg\:dq-translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .lg\:dq-translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .lg\:dq-translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .lg\:dq-translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .lg\:dq-translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .lg\:dq-translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .lg\:dq-translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .lg\:dq-translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .lg\:dq-translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .lg\:dq-translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .lg\:dq-translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .lg\:dq-translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .lg\:dq-translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .lg\:dq-translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .lg\:dq-translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .lg\:dq-translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .lg\:dq-translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .lg\:dq-translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .lg\:dq-translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .lg\:dq-translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .lg\:dq-translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .lg\:dq-translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .lg\:dq-translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .lg\:dq-translate-x-px {
    --tw-translate-x: 1px;
  }

  .lg\:dq-translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }

  .lg\:dq-translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }

  .lg\:dq-translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }

  .lg\:dq-translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }

  .lg\:dq--translate-x-0 {
    --tw-translate-x: 0px;
  }

  .lg\:dq--translate-x-1 {
    --tw-translate-x: -0.25rem;
  }

  .lg\:dq--translate-x-2 {
    --tw-translate-x: -0.5rem;
  }

  .lg\:dq--translate-x-3 {
    --tw-translate-x: -0.75rem;
  }

  .lg\:dq--translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .lg\:dq--translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .lg\:dq--translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .lg\:dq--translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .lg\:dq--translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .lg\:dq--translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .lg\:dq--translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .lg\:dq--translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .lg\:dq--translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .lg\:dq--translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .lg\:dq--translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .lg\:dq--translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .lg\:dq--translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .lg\:dq--translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .lg\:dq--translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .lg\:dq--translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .lg\:dq--translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .lg\:dq--translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .lg\:dq--translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .lg\:dq--translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .lg\:dq--translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .lg\:dq--translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .lg\:dq--translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .lg\:dq--translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .lg\:dq--translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .lg\:dq--translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .lg\:dq--translate-x-px {
    --tw-translate-x: -1px;
  }

  .lg\:dq--translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }

  .lg\:dq--translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }

  .lg\:dq--translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }

  .lg\:dq--translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }

  .lg\:dq-translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .lg\:dq-translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }

  .lg\:dq-translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }

  .lg\:dq-translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .lg\:dq-translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .lg\:dq-translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .lg\:dq-translate-x-full {
    --tw-translate-x: 100%;
  }

  .lg\:dq--translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .lg\:dq--translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }

  .lg\:dq--translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }

  .lg\:dq--translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .lg\:dq--translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .lg\:dq--translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .lg\:dq--translate-x-full {
    --tw-translate-x: -100%;
  }

  .lg\:dq-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .lg\:dq-translate-y-1 {
    --tw-translate-y: 0.25rem;
  }

  .lg\:dq-translate-y-2 {
    --tw-translate-y: 0.5rem;
  }

  .lg\:dq-translate-y-3 {
    --tw-translate-y: 0.75rem;
  }

  .lg\:dq-translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .lg\:dq-translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .lg\:dq-translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .lg\:dq-translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .lg\:dq-translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .lg\:dq-translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .lg\:dq-translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .lg\:dq-translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .lg\:dq-translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .lg\:dq-translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .lg\:dq-translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .lg\:dq-translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .lg\:dq-translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .lg\:dq-translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .lg\:dq-translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .lg\:dq-translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .lg\:dq-translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .lg\:dq-translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .lg\:dq-translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .lg\:dq-translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .lg\:dq-translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .lg\:dq-translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .lg\:dq-translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .lg\:dq-translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .lg\:dq-translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .lg\:dq-translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .lg\:dq-translate-y-px {
    --tw-translate-y: 1px;
  }

  .lg\:dq-translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }

  .lg\:dq-translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }

  .lg\:dq-translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }

  .lg\:dq-translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }

  .lg\:dq--translate-y-0 {
    --tw-translate-y: 0px;
  }

  .lg\:dq--translate-y-1 {
    --tw-translate-y: -0.25rem;
  }

  .lg\:dq--translate-y-2 {
    --tw-translate-y: -0.5rem;
  }

  .lg\:dq--translate-y-3 {
    --tw-translate-y: -0.75rem;
  }

  .lg\:dq--translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .lg\:dq--translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .lg\:dq--translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .lg\:dq--translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .lg\:dq--translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .lg\:dq--translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .lg\:dq--translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .lg\:dq--translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .lg\:dq--translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .lg\:dq--translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .lg\:dq--translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .lg\:dq--translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .lg\:dq--translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .lg\:dq--translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .lg\:dq--translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .lg\:dq--translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .lg\:dq--translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .lg\:dq--translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .lg\:dq--translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .lg\:dq--translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .lg\:dq--translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .lg\:dq--translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .lg\:dq--translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .lg\:dq--translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .lg\:dq--translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .lg\:dq--translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .lg\:dq--translate-y-px {
    --tw-translate-y: -1px;
  }

  .lg\:dq--translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }

  .lg\:dq--translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }

  .lg\:dq--translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }

  .lg\:dq--translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }

  .lg\:dq-translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .lg\:dq-translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }

  .lg\:dq-translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }

  .lg\:dq-translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .lg\:dq-translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .lg\:dq-translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .lg\:dq-translate-y-full {
    --tw-translate-y: 100%;
  }

  .lg\:dq--translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .lg\:dq--translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }

  .lg\:dq--translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }

  .lg\:dq--translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .lg\:dq--translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .lg\:dq--translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .lg\:dq--translate-y-full {
    --tw-translate-y: -100%;
  }

  .lg\:hover\:dq-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .lg\:hover\:dq-translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }

  .lg\:hover\:dq-translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }

  .lg\:hover\:dq-translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }

  .lg\:hover\:dq-translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .lg\:hover\:dq-translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .lg\:hover\:dq-translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .lg\:hover\:dq-translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .lg\:hover\:dq-translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .lg\:hover\:dq-translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .lg\:hover\:dq-translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .lg\:hover\:dq-translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .lg\:hover\:dq-translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .lg\:hover\:dq-translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .lg\:hover\:dq-translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .lg\:hover\:dq-translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .lg\:hover\:dq-translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .lg\:hover\:dq-translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .lg\:hover\:dq-translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .lg\:hover\:dq-translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .lg\:hover\:dq-translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .lg\:hover\:dq-translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .lg\:hover\:dq-translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .lg\:hover\:dq-translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .lg\:hover\:dq-translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .lg\:hover\:dq-translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .lg\:hover\:dq-translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .lg\:hover\:dq-translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .lg\:hover\:dq-translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .lg\:hover\:dq-translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .lg\:hover\:dq-translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .lg\:hover\:dq-translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }

  .lg\:hover\:dq-translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }

  .lg\:hover\:dq-translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }

  .lg\:hover\:dq-translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }

  .lg\:hover\:dq--translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .lg\:hover\:dq--translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }

  .lg\:hover\:dq--translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }

  .lg\:hover\:dq--translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }

  .lg\:hover\:dq--translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .lg\:hover\:dq--translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .lg\:hover\:dq--translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .lg\:hover\:dq--translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .lg\:hover\:dq--translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .lg\:hover\:dq--translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .lg\:hover\:dq--translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .lg\:hover\:dq--translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .lg\:hover\:dq--translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .lg\:hover\:dq--translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .lg\:hover\:dq--translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .lg\:hover\:dq--translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .lg\:hover\:dq--translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .lg\:hover\:dq--translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .lg\:hover\:dq--translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .lg\:hover\:dq--translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .lg\:hover\:dq--translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .lg\:hover\:dq--translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .lg\:hover\:dq--translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .lg\:hover\:dq--translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .lg\:hover\:dq--translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .lg\:hover\:dq--translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .lg\:hover\:dq--translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .lg\:hover\:dq--translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .lg\:hover\:dq--translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .lg\:hover\:dq--translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .lg\:hover\:dq--translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .lg\:hover\:dq--translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }

  .lg\:hover\:dq--translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }

  .lg\:hover\:dq--translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }

  .lg\:hover\:dq--translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }

  .lg\:hover\:dq-translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .lg\:hover\:dq-translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }

  .lg\:hover\:dq-translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }

  .lg\:hover\:dq-translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .lg\:hover\:dq-translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .lg\:hover\:dq-translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .lg\:hover\:dq-translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .lg\:hover\:dq--translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .lg\:hover\:dq--translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }

  .lg\:hover\:dq--translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }

  .lg\:hover\:dq--translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .lg\:hover\:dq--translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .lg\:hover\:dq--translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .lg\:hover\:dq--translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .lg\:hover\:dq-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .lg\:hover\:dq-translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }

  .lg\:hover\:dq-translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }

  .lg\:hover\:dq-translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }

  .lg\:hover\:dq-translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .lg\:hover\:dq-translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .lg\:hover\:dq-translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .lg\:hover\:dq-translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .lg\:hover\:dq-translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .lg\:hover\:dq-translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .lg\:hover\:dq-translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .lg\:hover\:dq-translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .lg\:hover\:dq-translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .lg\:hover\:dq-translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .lg\:hover\:dq-translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .lg\:hover\:dq-translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .lg\:hover\:dq-translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .lg\:hover\:dq-translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .lg\:hover\:dq-translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .lg\:hover\:dq-translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .lg\:hover\:dq-translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .lg\:hover\:dq-translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .lg\:hover\:dq-translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .lg\:hover\:dq-translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .lg\:hover\:dq-translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .lg\:hover\:dq-translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .lg\:hover\:dq-translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .lg\:hover\:dq-translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .lg\:hover\:dq-translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .lg\:hover\:dq-translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .lg\:hover\:dq-translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .lg\:hover\:dq-translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }

  .lg\:hover\:dq-translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }

  .lg\:hover\:dq-translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }

  .lg\:hover\:dq-translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }

  .lg\:hover\:dq--translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .lg\:hover\:dq--translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }

  .lg\:hover\:dq--translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }

  .lg\:hover\:dq--translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }

  .lg\:hover\:dq--translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .lg\:hover\:dq--translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .lg\:hover\:dq--translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .lg\:hover\:dq--translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .lg\:hover\:dq--translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .lg\:hover\:dq--translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .lg\:hover\:dq--translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .lg\:hover\:dq--translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .lg\:hover\:dq--translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .lg\:hover\:dq--translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .lg\:hover\:dq--translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .lg\:hover\:dq--translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .lg\:hover\:dq--translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .lg\:hover\:dq--translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .lg\:hover\:dq--translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .lg\:hover\:dq--translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .lg\:hover\:dq--translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .lg\:hover\:dq--translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .lg\:hover\:dq--translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .lg\:hover\:dq--translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .lg\:hover\:dq--translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .lg\:hover\:dq--translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .lg\:hover\:dq--translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .lg\:hover\:dq--translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .lg\:hover\:dq--translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .lg\:hover\:dq--translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .lg\:hover\:dq--translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .lg\:hover\:dq--translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }

  .lg\:hover\:dq--translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }

  .lg\:hover\:dq--translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }

  .lg\:hover\:dq--translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }

  .lg\:hover\:dq-translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .lg\:hover\:dq-translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }

  .lg\:hover\:dq-translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }

  .lg\:hover\:dq-translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .lg\:hover\:dq-translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .lg\:hover\:dq-translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .lg\:hover\:dq-translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .lg\:hover\:dq--translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .lg\:hover\:dq--translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }

  .lg\:hover\:dq--translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }

  .lg\:hover\:dq--translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .lg\:hover\:dq--translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .lg\:hover\:dq--translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .lg\:hover\:dq--translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .lg\:focus\:dq-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .lg\:focus\:dq-translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }

  .lg\:focus\:dq-translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }

  .lg\:focus\:dq-translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }

  .lg\:focus\:dq-translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .lg\:focus\:dq-translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .lg\:focus\:dq-translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .lg\:focus\:dq-translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .lg\:focus\:dq-translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .lg\:focus\:dq-translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .lg\:focus\:dq-translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .lg\:focus\:dq-translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .lg\:focus\:dq-translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .lg\:focus\:dq-translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .lg\:focus\:dq-translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .lg\:focus\:dq-translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .lg\:focus\:dq-translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .lg\:focus\:dq-translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .lg\:focus\:dq-translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .lg\:focus\:dq-translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .lg\:focus\:dq-translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .lg\:focus\:dq-translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .lg\:focus\:dq-translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .lg\:focus\:dq-translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .lg\:focus\:dq-translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .lg\:focus\:dq-translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .lg\:focus\:dq-translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .lg\:focus\:dq-translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .lg\:focus\:dq-translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .lg\:focus\:dq-translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .lg\:focus\:dq-translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .lg\:focus\:dq-translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }

  .lg\:focus\:dq-translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }

  .lg\:focus\:dq-translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }

  .lg\:focus\:dq-translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }

  .lg\:focus\:dq--translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .lg\:focus\:dq--translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }

  .lg\:focus\:dq--translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }

  .lg\:focus\:dq--translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }

  .lg\:focus\:dq--translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .lg\:focus\:dq--translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .lg\:focus\:dq--translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .lg\:focus\:dq--translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .lg\:focus\:dq--translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .lg\:focus\:dq--translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .lg\:focus\:dq--translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .lg\:focus\:dq--translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .lg\:focus\:dq--translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .lg\:focus\:dq--translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .lg\:focus\:dq--translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .lg\:focus\:dq--translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .lg\:focus\:dq--translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .lg\:focus\:dq--translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .lg\:focus\:dq--translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .lg\:focus\:dq--translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .lg\:focus\:dq--translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .lg\:focus\:dq--translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .lg\:focus\:dq--translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .lg\:focus\:dq--translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .lg\:focus\:dq--translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .lg\:focus\:dq--translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .lg\:focus\:dq--translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .lg\:focus\:dq--translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .lg\:focus\:dq--translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .lg\:focus\:dq--translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .lg\:focus\:dq--translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .lg\:focus\:dq--translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }

  .lg\:focus\:dq--translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }

  .lg\:focus\:dq--translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }

  .lg\:focus\:dq--translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }

  .lg\:focus\:dq-translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .lg\:focus\:dq-translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }

  .lg\:focus\:dq-translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }

  .lg\:focus\:dq-translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .lg\:focus\:dq-translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .lg\:focus\:dq-translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .lg\:focus\:dq-translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .lg\:focus\:dq--translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .lg\:focus\:dq--translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }

  .lg\:focus\:dq--translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }

  .lg\:focus\:dq--translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .lg\:focus\:dq--translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .lg\:focus\:dq--translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .lg\:focus\:dq--translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .lg\:focus\:dq-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .lg\:focus\:dq-translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }

  .lg\:focus\:dq-translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }

  .lg\:focus\:dq-translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }

  .lg\:focus\:dq-translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .lg\:focus\:dq-translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .lg\:focus\:dq-translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .lg\:focus\:dq-translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .lg\:focus\:dq-translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .lg\:focus\:dq-translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .lg\:focus\:dq-translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .lg\:focus\:dq-translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .lg\:focus\:dq-translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .lg\:focus\:dq-translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .lg\:focus\:dq-translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .lg\:focus\:dq-translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .lg\:focus\:dq-translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .lg\:focus\:dq-translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .lg\:focus\:dq-translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .lg\:focus\:dq-translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .lg\:focus\:dq-translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .lg\:focus\:dq-translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .lg\:focus\:dq-translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .lg\:focus\:dq-translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .lg\:focus\:dq-translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .lg\:focus\:dq-translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .lg\:focus\:dq-translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .lg\:focus\:dq-translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .lg\:focus\:dq-translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .lg\:focus\:dq-translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .lg\:focus\:dq-translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .lg\:focus\:dq-translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }

  .lg\:focus\:dq-translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }

  .lg\:focus\:dq-translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }

  .lg\:focus\:dq-translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }

  .lg\:focus\:dq--translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .lg\:focus\:dq--translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }

  .lg\:focus\:dq--translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }

  .lg\:focus\:dq--translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }

  .lg\:focus\:dq--translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .lg\:focus\:dq--translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .lg\:focus\:dq--translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .lg\:focus\:dq--translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .lg\:focus\:dq--translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .lg\:focus\:dq--translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .lg\:focus\:dq--translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .lg\:focus\:dq--translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .lg\:focus\:dq--translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .lg\:focus\:dq--translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .lg\:focus\:dq--translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .lg\:focus\:dq--translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .lg\:focus\:dq--translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .lg\:focus\:dq--translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .lg\:focus\:dq--translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .lg\:focus\:dq--translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .lg\:focus\:dq--translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .lg\:focus\:dq--translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .lg\:focus\:dq--translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .lg\:focus\:dq--translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .lg\:focus\:dq--translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .lg\:focus\:dq--translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .lg\:focus\:dq--translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .lg\:focus\:dq--translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .lg\:focus\:dq--translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .lg\:focus\:dq--translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .lg\:focus\:dq--translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .lg\:focus\:dq--translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }

  .lg\:focus\:dq--translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }

  .lg\:focus\:dq--translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }

  .lg\:focus\:dq--translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }

  .lg\:focus\:dq-translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .lg\:focus\:dq-translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }

  .lg\:focus\:dq-translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }

  .lg\:focus\:dq-translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .lg\:focus\:dq-translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .lg\:focus\:dq-translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .lg\:focus\:dq-translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .lg\:focus\:dq--translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .lg\:focus\:dq--translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }

  .lg\:focus\:dq--translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }

  .lg\:focus\:dq--translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .lg\:focus\:dq--translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .lg\:focus\:dq--translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .lg\:focus\:dq--translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .lg\:dq-rotate-0 {
    --tw-rotate: 0deg;
  }

  .lg\:dq-rotate-1 {
    --tw-rotate: 1deg;
  }

  .lg\:dq-rotate-2 {
    --tw-rotate: 2deg;
  }

  .lg\:dq-rotate-3 {
    --tw-rotate: 3deg;
  }

  .lg\:dq-rotate-6 {
    --tw-rotate: 6deg;
  }

  .lg\:dq-rotate-12 {
    --tw-rotate: 12deg;
  }

  .lg\:dq-rotate-45 {
    --tw-rotate: 45deg;
  }

  .lg\:dq-rotate-90 {
    --tw-rotate: 90deg;
  }

  .lg\:dq-rotate-180 {
    --tw-rotate: 180deg;
  }

  .lg\:dq--rotate-180 {
    --tw-rotate: -180deg;
  }

  .lg\:dq--rotate-90 {
    --tw-rotate: -90deg;
  }

  .lg\:dq--rotate-45 {
    --tw-rotate: -45deg;
  }

  .lg\:dq--rotate-12 {
    --tw-rotate: -12deg;
  }

  .lg\:dq--rotate-6 {
    --tw-rotate: -6deg;
  }

  .lg\:dq--rotate-3 {
    --tw-rotate: -3deg;
  }

  .lg\:dq--rotate-2 {
    --tw-rotate: -2deg;
  }

  .lg\:dq--rotate-1 {
    --tw-rotate: -1deg;
  }

  .lg\:hover\:dq-rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .lg\:hover\:dq-rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .lg\:hover\:dq-rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .lg\:hover\:dq-rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .lg\:hover\:dq-rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .lg\:hover\:dq-rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .lg\:hover\:dq-rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .lg\:hover\:dq-rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .lg\:hover\:dq-rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .lg\:hover\:dq--rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .lg\:hover\:dq--rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .lg\:hover\:dq--rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .lg\:hover\:dq--rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .lg\:hover\:dq--rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .lg\:hover\:dq--rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .lg\:hover\:dq--rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .lg\:hover\:dq--rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .lg\:focus\:dq-rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .lg\:focus\:dq-rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .lg\:focus\:dq-rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .lg\:focus\:dq-rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .lg\:focus\:dq-rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .lg\:focus\:dq-rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .lg\:focus\:dq-rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .lg\:focus\:dq-rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .lg\:focus\:dq-rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .lg\:focus\:dq--rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .lg\:focus\:dq--rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .lg\:focus\:dq--rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .lg\:focus\:dq--rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .lg\:focus\:dq--rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .lg\:focus\:dq--rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .lg\:focus\:dq--rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .lg\:focus\:dq--rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .lg\:dq-skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .lg\:dq-skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .lg\:dq-skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .lg\:dq-skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .lg\:dq-skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .lg\:dq-skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .lg\:dq--skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .lg\:dq--skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .lg\:dq--skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .lg\:dq--skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .lg\:dq--skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .lg\:dq-skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .lg\:dq-skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .lg\:dq-skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .lg\:dq-skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .lg\:dq-skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .lg\:dq-skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .lg\:dq--skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .lg\:dq--skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .lg\:dq--skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .lg\:dq--skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .lg\:dq--skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .lg\:hover\:dq-skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .lg\:hover\:dq-skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .lg\:hover\:dq-skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .lg\:hover\:dq-skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .lg\:hover\:dq-skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .lg\:hover\:dq-skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .lg\:hover\:dq--skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .lg\:hover\:dq--skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .lg\:hover\:dq--skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .lg\:hover\:dq--skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .lg\:hover\:dq--skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .lg\:hover\:dq-skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .lg\:hover\:dq-skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .lg\:hover\:dq-skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .lg\:hover\:dq-skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .lg\:hover\:dq-skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .lg\:hover\:dq-skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .lg\:hover\:dq--skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .lg\:hover\:dq--skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .lg\:hover\:dq--skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .lg\:hover\:dq--skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .lg\:hover\:dq--skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .lg\:focus\:dq-skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .lg\:focus\:dq-skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .lg\:focus\:dq-skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .lg\:focus\:dq-skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .lg\:focus\:dq-skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .lg\:focus\:dq-skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .lg\:focus\:dq--skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .lg\:focus\:dq--skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .lg\:focus\:dq--skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .lg\:focus\:dq--skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .lg\:focus\:dq--skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .lg\:focus\:dq-skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .lg\:focus\:dq-skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .lg\:focus\:dq-skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .lg\:focus\:dq-skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .lg\:focus\:dq-skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .lg\:focus\:dq-skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .lg\:focus\:dq--skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .lg\:focus\:dq--skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .lg\:focus\:dq--skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .lg\:focus\:dq--skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .lg\:focus\:dq--skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .lg\:dq-scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:dq-scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:dq-scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:dq-scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:dq-scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:dq-scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:dq-scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:dq-scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:dq-scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:dq-scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:hover\:dq-scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:hover\:dq-scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:hover\:dq-scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:hover\:dq-scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:hover\:dq-scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:hover\:dq-scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:hover\:dq-scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:hover\:dq-scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:hover\:dq-scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:hover\:dq-scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:focus\:dq-scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:focus\:dq-scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:focus\:dq-scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:focus\:dq-scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:focus\:dq-scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:focus\:dq-scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:focus\:dq-scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:focus\:dq-scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:focus\:dq-scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:focus\:dq-scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:dq-scale-x-0 {
    --tw-scale-x: 0;
  }

  .lg\:dq-scale-x-50 {
    --tw-scale-x: .5;
  }

  .lg\:dq-scale-x-75 {
    --tw-scale-x: .75;
  }

  .lg\:dq-scale-x-90 {
    --tw-scale-x: .9;
  }

  .lg\:dq-scale-x-95 {
    --tw-scale-x: .95;
  }

  .lg\:dq-scale-x-100 {
    --tw-scale-x: 1;
  }

  .lg\:dq-scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .lg\:dq-scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .lg\:dq-scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .lg\:dq-scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .lg\:dq-scale-y-0 {
    --tw-scale-y: 0;
  }

  .lg\:dq-scale-y-50 {
    --tw-scale-y: .5;
  }

  .lg\:dq-scale-y-75 {
    --tw-scale-y: .75;
  }

  .lg\:dq-scale-y-90 {
    --tw-scale-y: .9;
  }

  .lg\:dq-scale-y-95 {
    --tw-scale-y: .95;
  }

  .lg\:dq-scale-y-100 {
    --tw-scale-y: 1;
  }

  .lg\:dq-scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .lg\:dq-scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .lg\:dq-scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .lg\:dq-scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .lg\:hover\:dq-scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .lg\:hover\:dq-scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .lg\:hover\:dq-scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .lg\:hover\:dq-scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .lg\:hover\:dq-scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .lg\:hover\:dq-scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .lg\:hover\:dq-scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .lg\:hover\:dq-scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .lg\:hover\:dq-scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .lg\:hover\:dq-scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .lg\:hover\:dq-scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .lg\:hover\:dq-scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .lg\:hover\:dq-scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .lg\:hover\:dq-scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .lg\:hover\:dq-scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .lg\:hover\:dq-scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .lg\:hover\:dq-scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .lg\:hover\:dq-scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .lg\:hover\:dq-scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .lg\:hover\:dq-scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .lg\:focus\:dq-scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .lg\:focus\:dq-scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .lg\:focus\:dq-scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .lg\:focus\:dq-scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .lg\:focus\:dq-scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .lg\:focus\:dq-scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .lg\:focus\:dq-scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .lg\:focus\:dq-scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .lg\:focus\:dq-scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .lg\:focus\:dq-scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .lg\:focus\:dq-scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .lg\:focus\:dq-scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .lg\:focus\:dq-scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .lg\:focus\:dq-scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .lg\:focus\:dq-scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .lg\:focus\:dq-scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .lg\:focus\:dq-scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .lg\:focus\:dq-scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .lg\:focus\:dq-scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .lg\:focus\:dq-scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .lg\:dq-animate-none {
    animation: none;
  }

  .lg\:dq-animate-spin {
    animation: dq-spin 1s linear infinite;
  }

  .lg\:dq-animate-ping {
    animation: dq-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .lg\:dq-animate-pulse {
    animation: dq-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .lg\:dq-animate-bounce {
    animation: dq-bounce 1s infinite;
  }

  .lg\:dq-cursor-auto {
    cursor: auto;
  }

  .lg\:dq-cursor-default {
    cursor: default;
  }

  .lg\:dq-cursor-pointer {
    cursor: pointer;
  }

  .lg\:dq-cursor-wait {
    cursor: wait;
  }

  .lg\:dq-cursor-text {
    cursor: text;
  }

  .lg\:dq-cursor-move {
    cursor: move;
  }

  .lg\:dq-cursor-help {
    cursor: help;
  }

  .lg\:dq-cursor-not-allowed {
    cursor: not-allowed;
  }

  .lg\:dq-select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .lg\:dq-select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .lg\:dq-select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .lg\:dq-select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }

  .lg\:dq-resize-none {
    resize: none;
  }

  .lg\:dq-resize-y {
    resize: vertical;
  }

  .lg\:dq-resize-x {
    resize: horizontal;
  }

  .lg\:dq-resize {
    resize: both;
  }

  .lg\:dq-list-inside {
    list-style-position: inside;
  }

  .lg\:dq-list-outside {
    list-style-position: outside;
  }

  .lg\:dq-list-none {
    list-style-type: none;
  }

  .lg\:dq-list-disc {
    list-style-type: disc;
  }

  .lg\:dq-list-decimal {
    list-style-type: decimal;
  }

  .lg\:dq-appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  .lg\:dq-auto-cols-auto {
    grid-auto-columns: auto;
  }

  .lg\:dq-auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .lg\:dq-auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .lg\:dq-auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .lg\:dq-grid-flow-row {
    grid-auto-flow: row;
  }

  .lg\:dq-grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:dq-grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .lg\:dq-grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .lg\:dq-auto-rows-auto {
    grid-auto-rows: auto;
  }

  .lg\:dq-auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .lg\:dq-auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .lg\:dq-auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .lg\:dq-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:dq-grid-cols-none {
    grid-template-columns: none;
  }

  .lg\:dq-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .lg\:dq-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:dq-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:dq-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:dq-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg\:dq-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg\:dq-grid-rows-none {
    grid-template-rows: none;
  }

  .lg\:dq-flex-row {
    flex-direction: row;
  }

  .lg\:dq-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:dq-flex-col {
    flex-direction: column;
  }

  .lg\:dq-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:dq-flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:dq-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .lg\:dq-flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:dq-place-content-center {
    place-content: center;
  }

  .lg\:dq-place-content-start {
    place-content: start;
  }

  .lg\:dq-place-content-end {
    place-content: end;
  }

  .lg\:dq-place-content-between {
    place-content: space-between;
  }

  .lg\:dq-place-content-around {
    place-content: space-around;
  }

  .lg\:dq-place-content-evenly {
    place-content: space-evenly;
  }

  .lg\:dq-place-content-stretch {
    place-content: stretch;
  }

  .lg\:dq-place-items-start {
    place-items: start;
  }

  .lg\:dq-place-items-end {
    place-items: end;
  }

  .lg\:dq-place-items-center {
    place-items: center;
  }

  .lg\:dq-place-items-stretch {
    place-items: stretch;
  }

  .lg\:dq-content-center {
    align-content: center;
  }

  .lg\:dq-content-start {
    align-content: flex-start;
  }

  .lg\:dq-content-end {
    align-content: flex-end;
  }

  .lg\:dq-content-between {
    align-content: space-between;
  }

  .lg\:dq-content-around {
    align-content: space-around;
  }

  .lg\:dq-content-evenly {
    align-content: space-evenly;
  }

  .lg\:dq-items-start {
    align-items: flex-start;
  }

  .lg\:dq-items-end {
    align-items: flex-end;
  }

  .lg\:dq-items-center {
    align-items: center;
  }

  .lg\:dq-items-baseline {
    align-items: baseline;
  }

  .lg\:dq-items-stretch {
    align-items: stretch;
  }

  .lg\:dq-justify-start {
    justify-content: flex-start;
  }

  .lg\:dq-justify-end {
    justify-content: flex-end;
  }

  .lg\:dq-justify-center {
    justify-content: center;
  }

  .lg\:dq-justify-between {
    justify-content: space-between;
  }

  .lg\:dq-justify-around {
    justify-content: space-around;
  }

  .lg\:dq-justify-evenly {
    justify-content: space-evenly;
  }

  .lg\:dq-justify-items-start {
    justify-items: start;
  }

  .lg\:dq-justify-items-end {
    justify-items: end;
  }

  .lg\:dq-justify-items-center {
    justify-items: center;
  }

  .lg\:dq-justify-items-stretch {
    justify-items: stretch;
  }

  .lg\:dq-gap-0 {
    gap: 0px;
  }

  .lg\:dq-gap-1 {
    gap: 0.25rem;
  }

  .lg\:dq-gap-2 {
    gap: 0.5rem;
  }

  .lg\:dq-gap-3 {
    gap: 0.75rem;
  }

  .lg\:dq-gap-4 {
    gap: 1rem;
  }

  .lg\:dq-gap-5 {
    gap: 1.25rem;
  }

  .lg\:dq-gap-6 {
    gap: 1.5rem;
  }

  .lg\:dq-gap-7 {
    gap: 1.75rem;
  }

  .lg\:dq-gap-8 {
    gap: 2rem;
  }

  .lg\:dq-gap-9 {
    gap: 2.25rem;
  }

  .lg\:dq-gap-10 {
    gap: 2.5rem;
  }

  .lg\:dq-gap-11 {
    gap: 2.75rem;
  }

  .lg\:dq-gap-12 {
    gap: 3rem;
  }

  .lg\:dq-gap-14 {
    gap: 3.5rem;
  }

  .lg\:dq-gap-16 {
    gap: 4rem;
  }

  .lg\:dq-gap-20 {
    gap: 5rem;
  }

  .lg\:dq-gap-24 {
    gap: 6rem;
  }

  .lg\:dq-gap-28 {
    gap: 7rem;
  }

  .lg\:dq-gap-32 {
    gap: 8rem;
  }

  .lg\:dq-gap-36 {
    gap: 9rem;
  }

  .lg\:dq-gap-40 {
    gap: 10rem;
  }

  .lg\:dq-gap-44 {
    gap: 11rem;
  }

  .lg\:dq-gap-48 {
    gap: 12rem;
  }

  .lg\:dq-gap-52 {
    gap: 13rem;
  }

  .lg\:dq-gap-56 {
    gap: 14rem;
  }

  .lg\:dq-gap-60 {
    gap: 15rem;
  }

  .lg\:dq-gap-64 {
    gap: 16rem;
  }

  .lg\:dq-gap-72 {
    gap: 18rem;
  }

  .lg\:dq-gap-80 {
    gap: 20rem;
  }

  .lg\:dq-gap-96 {
    gap: 24rem;
  }

  .lg\:dq-gap-px {
    gap: 1px;
  }

  .lg\:dq-gap-0\.5 {
    gap: 0.125rem;
  }

  .lg\:dq-gap-1\.5 {
    gap: 0.375rem;
  }

  .lg\:dq-gap-2\.5 {
    gap: 0.625rem;
  }

  .lg\:dq-gap-3\.5 {
    gap: 0.875rem;
  }

  .lg\:dq-gap-x-0 {
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .lg\:dq-gap-x-1 {
    -webkit-column-gap: 0.25rem;
            column-gap: 0.25rem;
  }

  .lg\:dq-gap-x-2 {
    -webkit-column-gap: 0.5rem;
            column-gap: 0.5rem;
  }

  .lg\:dq-gap-x-3 {
    -webkit-column-gap: 0.75rem;
            column-gap: 0.75rem;
  }

  .lg\:dq-gap-x-4 {
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
  }

  .lg\:dq-gap-x-5 {
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
  }

  .lg\:dq-gap-x-6 {
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
  }

  .lg\:dq-gap-x-7 {
    -webkit-column-gap: 1.75rem;
            column-gap: 1.75rem;
  }

  .lg\:dq-gap-x-8 {
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }

  .lg\:dq-gap-x-9 {
    -webkit-column-gap: 2.25rem;
            column-gap: 2.25rem;
  }

  .lg\:dq-gap-x-10 {
    -webkit-column-gap: 2.5rem;
            column-gap: 2.5rem;
  }

  .lg\:dq-gap-x-11 {
    -webkit-column-gap: 2.75rem;
            column-gap: 2.75rem;
  }

  .lg\:dq-gap-x-12 {
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }

  .lg\:dq-gap-x-14 {
    -webkit-column-gap: 3.5rem;
            column-gap: 3.5rem;
  }

  .lg\:dq-gap-x-16 {
    -webkit-column-gap: 4rem;
            column-gap: 4rem;
  }

  .lg\:dq-gap-x-20 {
    -webkit-column-gap: 5rem;
            column-gap: 5rem;
  }

  .lg\:dq-gap-x-24 {
    -webkit-column-gap: 6rem;
            column-gap: 6rem;
  }

  .lg\:dq-gap-x-28 {
    -webkit-column-gap: 7rem;
            column-gap: 7rem;
  }

  .lg\:dq-gap-x-32 {
    -webkit-column-gap: 8rem;
            column-gap: 8rem;
  }

  .lg\:dq-gap-x-36 {
    -webkit-column-gap: 9rem;
            column-gap: 9rem;
  }

  .lg\:dq-gap-x-40 {
    -webkit-column-gap: 10rem;
            column-gap: 10rem;
  }

  .lg\:dq-gap-x-44 {
    -webkit-column-gap: 11rem;
            column-gap: 11rem;
  }

  .lg\:dq-gap-x-48 {
    -webkit-column-gap: 12rem;
            column-gap: 12rem;
  }

  .lg\:dq-gap-x-52 {
    -webkit-column-gap: 13rem;
            column-gap: 13rem;
  }

  .lg\:dq-gap-x-56 {
    -webkit-column-gap: 14rem;
            column-gap: 14rem;
  }

  .lg\:dq-gap-x-60 {
    -webkit-column-gap: 15rem;
            column-gap: 15rem;
  }

  .lg\:dq-gap-x-64 {
    -webkit-column-gap: 16rem;
            column-gap: 16rem;
  }

  .lg\:dq-gap-x-72 {
    -webkit-column-gap: 18rem;
            column-gap: 18rem;
  }

  .lg\:dq-gap-x-80 {
    -webkit-column-gap: 20rem;
            column-gap: 20rem;
  }

  .lg\:dq-gap-x-96 {
    -webkit-column-gap: 24rem;
            column-gap: 24rem;
  }

  .lg\:dq-gap-x-px {
    -webkit-column-gap: 1px;
            column-gap: 1px;
  }

  .lg\:dq-gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
            column-gap: 0.125rem;
  }

  .lg\:dq-gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
            column-gap: 0.375rem;
  }

  .lg\:dq-gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
            column-gap: 0.625rem;
  }

  .lg\:dq-gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
            column-gap: 0.875rem;
  }

  .lg\:dq-gap-y-0 {
    row-gap: 0px;
  }

  .lg\:dq-gap-y-1 {
    row-gap: 0.25rem;
  }

  .lg\:dq-gap-y-2 {
    row-gap: 0.5rem;
  }

  .lg\:dq-gap-y-3 {
    row-gap: 0.75rem;
  }

  .lg\:dq-gap-y-4 {
    row-gap: 1rem;
  }

  .lg\:dq-gap-y-5 {
    row-gap: 1.25rem;
  }

  .lg\:dq-gap-y-6 {
    row-gap: 1.5rem;
  }

  .lg\:dq-gap-y-7 {
    row-gap: 1.75rem;
  }

  .lg\:dq-gap-y-8 {
    row-gap: 2rem;
  }

  .lg\:dq-gap-y-9 {
    row-gap: 2.25rem;
  }

  .lg\:dq-gap-y-10 {
    row-gap: 2.5rem;
  }

  .lg\:dq-gap-y-11 {
    row-gap: 2.75rem;
  }

  .lg\:dq-gap-y-12 {
    row-gap: 3rem;
  }

  .lg\:dq-gap-y-14 {
    row-gap: 3.5rem;
  }

  .lg\:dq-gap-y-16 {
    row-gap: 4rem;
  }

  .lg\:dq-gap-y-20 {
    row-gap: 5rem;
  }

  .lg\:dq-gap-y-24 {
    row-gap: 6rem;
  }

  .lg\:dq-gap-y-28 {
    row-gap: 7rem;
  }

  .lg\:dq-gap-y-32 {
    row-gap: 8rem;
  }

  .lg\:dq-gap-y-36 {
    row-gap: 9rem;
  }

  .lg\:dq-gap-y-40 {
    row-gap: 10rem;
  }

  .lg\:dq-gap-y-44 {
    row-gap: 11rem;
  }

  .lg\:dq-gap-y-48 {
    row-gap: 12rem;
  }

  .lg\:dq-gap-y-52 {
    row-gap: 13rem;
  }

  .lg\:dq-gap-y-56 {
    row-gap: 14rem;
  }

  .lg\:dq-gap-y-60 {
    row-gap: 15rem;
  }

  .lg\:dq-gap-y-64 {
    row-gap: 16rem;
  }

  .lg\:dq-gap-y-72 {
    row-gap: 18rem;
  }

  .lg\:dq-gap-y-80 {
    row-gap: 20rem;
  }

  .lg\:dq-gap-y-96 {
    row-gap: 24rem;
  }

  .lg\:dq-gap-y-px {
    row-gap: 1px;
  }

  .lg\:dq-gap-y-0\.5 {
    row-gap: 0.125rem;
  }

  .lg\:dq-gap-y-1\.5 {
    row-gap: 0.375rem;
  }

  .lg\:dq-gap-y-2\.5 {
    row-gap: 0.625rem;
  }

  .lg\:dq-gap-y-3\.5 {
    row-gap: 0.875rem;
  }

  .lg\:dq-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq--space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:dq-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }

  .lg\:dq--space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }

  .lg\:dq-space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .lg\:dq-space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .lg\:dq-divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:dq-divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:dq-divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:dq-divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:dq-divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:dq-divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .lg\:dq-divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .lg\:dq-divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .lg\:dq-divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .lg\:dq-divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .lg\:dq-divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .lg\:dq-divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .lg\:dq-divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .lg\:dq-divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .lg\:dq-divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .lg\:dq-divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .lg\:dq-divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .lg\:dq-divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }

  .lg\:dq-divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .lg\:dq-divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .lg\:dq-divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .lg\:dq-divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }

  .lg\:dq-divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }

  .lg\:dq-divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }

  .lg\:dq-divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }

  .lg\:dq-divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }

  .lg\:dq-divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }

  .lg\:dq-divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }

  .lg\:dq-divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }

  .lg\:dq-divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }

  .lg\:dq-divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }

  .lg\:dq-divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }

  .lg\:dq-divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }

  .lg\:dq-divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }

  .lg\:dq-divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .lg\:dq-place-self-auto {
    place-self: auto;
  }

  .lg\:dq-place-self-start {
    place-self: start;
  }

  .lg\:dq-place-self-end {
    place-self: end;
  }

  .lg\:dq-place-self-center {
    place-self: center;
  }

  .lg\:dq-place-self-stretch {
    place-self: stretch;
  }

  .lg\:dq-self-auto {
    align-self: auto;
  }

  .lg\:dq-self-start {
    align-self: flex-start;
  }

  .lg\:dq-self-end {
    align-self: flex-end;
  }

  .lg\:dq-self-center {
    align-self: center;
  }

  .lg\:dq-self-stretch {
    align-self: stretch;
  }

  .lg\:dq-self-baseline {
    align-self: baseline;
  }

  .lg\:dq-justify-self-auto {
    justify-self: auto;
  }

  .lg\:dq-justify-self-start {
    justify-self: start;
  }

  .lg\:dq-justify-self-end {
    justify-self: end;
  }

  .lg\:dq-justify-self-center {
    justify-self: center;
  }

  .lg\:dq-justify-self-stretch {
    justify-self: stretch;
  }

  .lg\:dq-overflow-auto {
    overflow: auto;
  }

  .lg\:dq-overflow-hidden {
    overflow: hidden;
  }

  .lg\:dq-overflow-visible {
    overflow: visible;
  }

  .lg\:dq-overflow-scroll {
    overflow: scroll;
  }

  .lg\:dq-overflow-x-auto {
    overflow-x: auto;
  }

  .lg\:dq-overflow-y-auto {
    overflow-y: auto;
  }

  .lg\:dq-overflow-x-hidden {
    overflow-x: hidden;
  }

  .lg\:dq-overflow-y-hidden {
    overflow-y: hidden;
  }

  .lg\:dq-overflow-x-visible {
    overflow-x: visible;
  }

  .lg\:dq-overflow-y-visible {
    overflow-y: visible;
  }

  .lg\:dq-overflow-x-scroll {
    overflow-x: scroll;
  }

  .lg\:dq-overflow-y-scroll {
    overflow-y: scroll;
  }

  .lg\:dq-overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
  }

  .lg\:dq-overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }

  .lg\:dq-overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
  }

  .lg\:dq-overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .lg\:dq-overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .lg\:dq-overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .lg\:dq-overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .lg\:dq-overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .lg\:dq-overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .lg\:dq-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lg\:dq-overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .lg\:dq-overflow-clip {
    text-overflow: clip;
  }

  .lg\:dq-whitespace-normal {
    white-space: normal;
  }

  .lg\:dq-whitespace-nowrap {
    white-space: nowrap;
  }

  .lg\:dq-whitespace-pre {
    white-space: pre;
  }

  .lg\:dq-whitespace-pre-line {
    white-space: pre-line;
  }

  .lg\:dq-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .lg\:dq-break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .lg\:dq-break-words {
    overflow-wrap: break-word;
  }

  .lg\:dq-break-all {
    word-break: break-all;
  }

  .lg\:dq-rounded-none {
    border-radius: 0px;
  }

  .lg\:dq-rounded-sm {
    border-radius: 0.125rem;
  }

  .lg\:dq-rounded {
    border-radius: 0.25rem;
  }

  .lg\:dq-rounded-md {
    border-radius: 0.375rem;
  }

  .lg\:dq-rounded-lg {
    border-radius: 0.5rem;
  }

  .lg\:dq-rounded-xl {
    border-radius: 0.75rem;
  }

  .lg\:dq-rounded-2xl {
    border-radius: 1rem;
  }

  .lg\:dq-rounded-3xl {
    border-radius: 1.5rem;
  }

  .lg\:dq-rounded-full {
    border-radius: 9999px;
  }

  .lg\:dq-rounded-t-none {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .lg\:dq-rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .lg\:dq-rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .lg\:dq-rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .lg\:dq-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .lg\:dq-rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .lg\:dq-rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .lg\:dq-rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .lg\:dq-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .lg\:dq-rounded-r-none {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .lg\:dq-rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .lg\:dq-rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .lg\:dq-rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .lg\:dq-rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .lg\:dq-rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .lg\:dq-rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .lg\:dq-rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:dq-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .lg\:dq-rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .lg\:dq-rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:dq-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:dq-rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:dq-rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:dq-rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:dq-rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:dq-rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:dq-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:dq-rounded-l-none {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .lg\:dq-rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:dq-rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:dq-rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:dq-rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:dq-rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:dq-rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:dq-rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:dq-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:dq-rounded-tl-none {
    border-top-left-radius: 0px;
  }

  .lg\:dq-rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .lg\:dq-rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .lg\:dq-rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .lg\:dq-rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .lg\:dq-rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .lg\:dq-rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .lg\:dq-rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .lg\:dq-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .lg\:dq-rounded-tr-none {
    border-top-right-radius: 0px;
  }

  .lg\:dq-rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .lg\:dq-rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .lg\:dq-rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .lg\:dq-rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .lg\:dq-rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .lg\:dq-rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .lg\:dq-rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .lg\:dq-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .lg\:dq-rounded-br-none {
    border-bottom-right-radius: 0px;
  }

  .lg\:dq-rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .lg\:dq-rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .lg\:dq-rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .lg\:dq-rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .lg\:dq-rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .lg\:dq-rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .lg\:dq-rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:dq-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .lg\:dq-rounded-bl-none {
    border-bottom-left-radius: 0px;
  }

  .lg\:dq-rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:dq-rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:dq-rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:dq-rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:dq-rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:dq-rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .lg\:dq-rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:dq-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .lg\:dq-border-0 {
    border-width: 0px;
  }

  .lg\:dq-border-2 {
    border-width: 2px;
  }

  .lg\:dq-border-4 {
    border-width: 4px;
  }

  .lg\:dq-border-8 {
    border-width: 8px;
  }

  .lg\:dq-border {
    border-width: 1px;
  }

  .lg\:dq-border-t-0 {
    border-top-width: 0px;
  }

  .lg\:dq-border-t-2 {
    border-top-width: 2px;
  }

  .lg\:dq-border-t-4 {
    border-top-width: 4px;
  }

  .lg\:dq-border-t-8 {
    border-top-width: 8px;
  }

  .lg\:dq-border-t {
    border-top-width: 1px;
  }

  .lg\:dq-border-r-0 {
    border-right-width: 0px;
  }

  .lg\:dq-border-r-2 {
    border-right-width: 2px;
  }

  .lg\:dq-border-r-4 {
    border-right-width: 4px;
  }

  .lg\:dq-border-r-8 {
    border-right-width: 8px;
  }

  .lg\:dq-border-r {
    border-right-width: 1px;
  }

  .lg\:dq-border-b-0 {
    border-bottom-width: 0px;
  }

  .lg\:dq-border-b-2 {
    border-bottom-width: 2px;
  }

  .lg\:dq-border-b-4 {
    border-bottom-width: 4px;
  }

  .lg\:dq-border-b-8 {
    border-bottom-width: 8px;
  }

  .lg\:dq-border-b {
    border-bottom-width: 1px;
  }

  .lg\:dq-border-l-0 {
    border-left-width: 0px;
  }

  .lg\:dq-border-l-2 {
    border-left-width: 2px;
  }

  .lg\:dq-border-l-4 {
    border-left-width: 4px;
  }

  .lg\:dq-border-l-8 {
    border-left-width: 8px;
  }

  .lg\:dq-border-l {
    border-left-width: 1px;
  }

  .lg\:dq-border-solid {
    border-style: solid;
  }

  .lg\:dq-border-dashed {
    border-style: dashed;
  }

  .lg\:dq-border-dotted {
    border-style: dotted;
  }

  .lg\:dq-border-double {
    border-style: double;
  }

  .lg\:dq-border-none {
    border-style: none;
  }

  .lg\:dq-border-transparent {
    border-color: transparent;
  }

  .lg\:dq-border-current {
    border-color: currentColor;
  }

  .lg\:dq-border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg\:dq-border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:dq-border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg\:dq-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg\:dq-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg\:dq-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg\:dq-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg\:dq-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg\:dq-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg\:dq-border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg\:dq-border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg\:dq-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg\:dq-border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .lg\:dq-border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .lg\:dq-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .lg\:dq-border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .lg\:dq-border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .lg\:dq-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .lg\:dq-border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .lg\:dq-border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .lg\:dq-border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .lg\:dq-border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .lg\:dq-border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg\:dq-border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg\:dq-border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg\:dq-border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg\:dq-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg\:dq-border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg\:dq-border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg\:dq-border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg\:dq-border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg\:dq-border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg\:dq-border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg\:dq-border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg\:dq-border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg\:dq-border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg\:dq-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg\:dq-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg\:dq-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg\:dq-border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg\:dq-border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg\:dq-border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg\:dq-border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg\:dq-border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg\:dq-border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg\:dq-border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg\:dq-border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg\:dq-border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg\:dq-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg\:dq-border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg\:dq-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg\:dq-border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg\:dq-border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg\:dq-border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg\:dq-border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg\:dq-border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg\:dq-border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg\:dq-border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg\:dq-border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg\:dq-border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg\:dq-border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg\:dq-border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg\:dq-border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg\:dq-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg\:dq-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg\:dq-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg\:dq-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg\:dq-border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg\:dq-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg\:dq-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg\:dq-border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg\:dq-border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg\:dq-border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg\:dq-border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg\:dq-border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg\:dq-border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg\:dq-border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg\:dq-border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg\:dq-border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg\:dq-border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg\:dq-border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg\:dq-border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-transparent {
    border-color: transparent;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-current {
    border-color: currentColor;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-transparent:focus-within {
    border-color: transparent;
  }

  .lg\:focus-within\:dq-border-current:focus-within {
    border-color: currentColor;
  }

  .lg\:focus-within\:dq-border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg\:focus-within\:dq-border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-transparent:hover {
    border-color: transparent;
  }

  .lg\:hover\:dq-border-current:hover {
    border-color: currentColor;
  }

  .lg\:hover\:dq-border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg\:hover\:dq-border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-transparent:focus {
    border-color: transparent;
  }

  .lg\:focus\:dq-border-current:focus {
    border-color: currentColor;
  }

  .lg\:focus\:dq-border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg\:focus\:dq-border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg\:dq-border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .lg\:dq-border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .lg\:dq-border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .lg\:dq-border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .lg\:dq-border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .lg\:dq-border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .lg\:dq-border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .lg\:dq-border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .lg\:dq-border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .lg\:dq-border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .lg\:dq-border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .lg\:dq-border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .lg\:dq-border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .lg\:dq-border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .lg\:dq-border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .dq-group:hover .lg\:group-hover\:dq-border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .lg\:focus-within\:dq-border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .lg\:focus-within\:dq-border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }

  .lg\:focus-within\:dq-border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }

  .lg\:focus-within\:dq-border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }

  .lg\:focus-within\:dq-border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }

  .lg\:focus-within\:dq-border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }

  .lg\:focus-within\:dq-border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }

  .lg\:focus-within\:dq-border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }

  .lg\:focus-within\:dq-border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }

  .lg\:focus-within\:dq-border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }

  .lg\:focus-within\:dq-border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }

  .lg\:focus-within\:dq-border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }

  .lg\:focus-within\:dq-border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }

  .lg\:focus-within\:dq-border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }

  .lg\:focus-within\:dq-border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .lg\:hover\:dq-border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .lg\:hover\:dq-border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }

  .lg\:hover\:dq-border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }

  .lg\:hover\:dq-border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }

  .lg\:hover\:dq-border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }

  .lg\:hover\:dq-border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }

  .lg\:hover\:dq-border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }

  .lg\:hover\:dq-border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }

  .lg\:hover\:dq-border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }

  .lg\:hover\:dq-border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }

  .lg\:hover\:dq-border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }

  .lg\:hover\:dq-border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }

  .lg\:hover\:dq-border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }

  .lg\:hover\:dq-border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }

  .lg\:hover\:dq-border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .lg\:focus\:dq-border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .lg\:focus\:dq-border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }

  .lg\:focus\:dq-border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }

  .lg\:focus\:dq-border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }

  .lg\:focus\:dq-border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }

  .lg\:focus\:dq-border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }

  .lg\:focus\:dq-border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }

  .lg\:focus\:dq-border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }

  .lg\:focus\:dq-border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }

  .lg\:focus\:dq-border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }

  .lg\:focus\:dq-border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }

  .lg\:focus\:dq-border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }

  .lg\:focus\:dq-border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }

  .lg\:focus\:dq-border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }

  .lg\:focus\:dq-border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .lg\:dq-bg-transparent {
    background-color: transparent;
  }

  .lg\:dq-bg-current {
    background-color: currentColor;
  }

  .lg\:dq-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-transparent {
    background-color: transparent;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-current {
    background-color: currentColor;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-transparent:focus-within {
    background-color: transparent;
  }

  .lg\:focus-within\:dq-bg-current:focus-within {
    background-color: currentColor;
  }

  .lg\:focus-within\:dq-bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:dq-bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-transparent:hover {
    background-color: transparent;
  }

  .lg\:hover\:dq-bg-current:hover {
    background-color: currentColor;
  }

  .lg\:hover\:dq-bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg\:hover\:dq-bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-transparent:focus {
    background-color: transparent;
  }

  .lg\:focus\:dq-bg-current:focus {
    background-color: currentColor;
  }

  .lg\:focus\:dq-bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg\:focus\:dq-bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg\:dq-bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .lg\:dq-bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .lg\:dq-bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .lg\:dq-bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .lg\:dq-bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .lg\:dq-bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .lg\:dq-bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .lg\:dq-bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .lg\:dq-bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .lg\:dq-bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .lg\:dq-bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .lg\:dq-bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .lg\:dq-bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .lg\:dq-bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .lg\:dq-bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .dq-group:hover .lg\:group-hover\:dq-bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .lg\:focus-within\:dq-bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .lg\:focus-within\:dq-bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }

  .lg\:focus-within\:dq-bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }

  .lg\:focus-within\:dq-bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }

  .lg\:focus-within\:dq-bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }

  .lg\:focus-within\:dq-bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }

  .lg\:focus-within\:dq-bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }

  .lg\:focus-within\:dq-bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }

  .lg\:focus-within\:dq-bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }

  .lg\:focus-within\:dq-bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }

  .lg\:focus-within\:dq-bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }

  .lg\:focus-within\:dq-bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }

  .lg\:focus-within\:dq-bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }

  .lg\:focus-within\:dq-bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }

  .lg\:focus-within\:dq-bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .lg\:hover\:dq-bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .lg\:hover\:dq-bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }

  .lg\:hover\:dq-bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }

  .lg\:hover\:dq-bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }

  .lg\:hover\:dq-bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }

  .lg\:hover\:dq-bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }

  .lg\:hover\:dq-bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }

  .lg\:hover\:dq-bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }

  .lg\:hover\:dq-bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }

  .lg\:hover\:dq-bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }

  .lg\:hover\:dq-bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }

  .lg\:hover\:dq-bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }

  .lg\:hover\:dq-bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }

  .lg\:hover\:dq-bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }

  .lg\:hover\:dq-bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .lg\:focus\:dq-bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .lg\:focus\:dq-bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }

  .lg\:focus\:dq-bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }

  .lg\:focus\:dq-bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }

  .lg\:focus\:dq-bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }

  .lg\:focus\:dq-bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }

  .lg\:focus\:dq-bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }

  .lg\:focus\:dq-bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }

  .lg\:focus\:dq-bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }

  .lg\:focus\:dq-bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }

  .lg\:focus\:dq-bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }

  .lg\:focus\:dq-bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }

  .lg\:focus\:dq-bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }

  .lg\:focus\:dq-bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }

  .lg\:focus\:dq-bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .lg\:dq-bg-none {
    background-image: none;
  }

  .lg\:dq-bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .lg\:dq-bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .lg\:dq-bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .lg\:dq-bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .lg\:dq-bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .lg\:dq-bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .lg\:dq-bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .lg\:dq-bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .lg\:dq-from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:dq-from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:dq-from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:dq-from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:dq-from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .lg\:dq-from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .lg\:dq-from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .lg\:dq-from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .lg\:dq-from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .lg\:dq-from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .lg\:dq-from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .lg\:dq-from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .lg\:dq-from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .lg\:dq-from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .lg\:dq-from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .lg\:dq-from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .lg\:dq-from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .lg\:dq-from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .lg\:dq-from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .lg\:dq-from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .lg\:dq-from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .lg\:dq-from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .lg\:dq-from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .lg\:dq-from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .lg\:dq-from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .lg\:dq-from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .lg\:dq-from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .lg\:dq-from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .lg\:dq-from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .lg\:dq-from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .lg\:dq-from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .lg\:dq-from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .lg\:dq-from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .lg\:dq-from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .lg\:dq-from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .lg\:dq-from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .lg\:dq-from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .lg\:dq-from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .lg\:dq-from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .lg\:dq-from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .lg\:dq-from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .lg\:dq-from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .lg\:dq-from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .lg\:dq-from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .lg\:dq-from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .lg\:dq-from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .lg\:dq-from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .lg\:dq-from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .lg\:dq-from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .lg\:dq-from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .lg\:dq-from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .lg\:dq-from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .lg\:dq-from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .lg\:dq-from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .lg\:dq-from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .lg\:dq-from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .lg\:dq-from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .lg\:dq-from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .lg\:dq-from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .lg\:dq-from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .lg\:dq-from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .lg\:dq-from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .lg\:dq-from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .lg\:dq-from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .lg\:dq-from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .lg\:dq-from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .lg\:dq-from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .lg\:dq-from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .lg\:dq-from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .lg\:dq-from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .lg\:dq-from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .lg\:dq-from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .lg\:dq-from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .lg\:dq-from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .lg\:dq-from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .lg\:dq-from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .lg\:dq-from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .lg\:dq-from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .lg\:dq-from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .lg\:dq-from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .lg\:dq-from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .lg\:dq-from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .lg\:dq-from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .lg\:dq-from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .lg\:hover\:dq-from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:dq-from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:dq-from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:dq-from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:dq-from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .lg\:hover\:dq-from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .lg\:hover\:dq-from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .lg\:hover\:dq-from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .lg\:hover\:dq-from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .lg\:hover\:dq-from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .lg\:hover\:dq-from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .lg\:hover\:dq-from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .lg\:hover\:dq-from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .lg\:hover\:dq-from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .lg\:hover\:dq-from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .lg\:hover\:dq-from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .lg\:hover\:dq-from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .lg\:hover\:dq-from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .lg\:hover\:dq-from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .lg\:hover\:dq-from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .lg\:hover\:dq-from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .lg\:hover\:dq-from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .lg\:hover\:dq-from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .lg\:hover\:dq-from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .lg\:hover\:dq-from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .lg\:hover\:dq-from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .lg\:hover\:dq-from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .lg\:hover\:dq-from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .lg\:hover\:dq-from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .lg\:hover\:dq-from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .lg\:hover\:dq-from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .lg\:hover\:dq-from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .lg\:hover\:dq-from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .lg\:hover\:dq-from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .lg\:hover\:dq-from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .lg\:hover\:dq-from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .lg\:hover\:dq-from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .lg\:hover\:dq-from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .lg\:hover\:dq-from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .lg\:hover\:dq-from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .lg\:hover\:dq-from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .lg\:hover\:dq-from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .lg\:hover\:dq-from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .lg\:hover\:dq-from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .lg\:hover\:dq-from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .lg\:hover\:dq-from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .lg\:hover\:dq-from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .lg\:hover\:dq-from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .lg\:hover\:dq-from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .lg\:hover\:dq-from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .lg\:hover\:dq-from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .lg\:hover\:dq-from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .lg\:hover\:dq-from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .lg\:hover\:dq-from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .lg\:hover\:dq-from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .lg\:hover\:dq-from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .lg\:hover\:dq-from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .lg\:hover\:dq-from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .lg\:hover\:dq-from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .lg\:hover\:dq-from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .lg\:hover\:dq-from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .lg\:hover\:dq-from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .lg\:hover\:dq-from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .lg\:hover\:dq-from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .lg\:hover\:dq-from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .lg\:hover\:dq-from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .lg\:hover\:dq-from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .lg\:hover\:dq-from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .lg\:hover\:dq-from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .lg\:hover\:dq-from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .lg\:hover\:dq-from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .lg\:hover\:dq-from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .lg\:hover\:dq-from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .lg\:hover\:dq-from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .lg\:hover\:dq-from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .lg\:hover\:dq-from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .lg\:hover\:dq-from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .lg\:hover\:dq-from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .lg\:hover\:dq-from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .lg\:hover\:dq-from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .lg\:hover\:dq-from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .lg\:hover\:dq-from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .lg\:hover\:dq-from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .lg\:hover\:dq-from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .lg\:focus\:dq-from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:dq-from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:dq-from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:dq-from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:dq-from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .lg\:focus\:dq-from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .lg\:focus\:dq-from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .lg\:focus\:dq-from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .lg\:focus\:dq-from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .lg\:focus\:dq-from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .lg\:focus\:dq-from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .lg\:focus\:dq-from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .lg\:focus\:dq-from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .lg\:focus\:dq-from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .lg\:focus\:dq-from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .lg\:focus\:dq-from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .lg\:focus\:dq-from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .lg\:focus\:dq-from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .lg\:focus\:dq-from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .lg\:focus\:dq-from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .lg\:focus\:dq-from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .lg\:focus\:dq-from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .lg\:focus\:dq-from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .lg\:focus\:dq-from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .lg\:focus\:dq-from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .lg\:focus\:dq-from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .lg\:focus\:dq-from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .lg\:focus\:dq-from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .lg\:focus\:dq-from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .lg\:focus\:dq-from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .lg\:focus\:dq-from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .lg\:focus\:dq-from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .lg\:focus\:dq-from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .lg\:focus\:dq-from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .lg\:focus\:dq-from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .lg\:focus\:dq-from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .lg\:focus\:dq-from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .lg\:focus\:dq-from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .lg\:focus\:dq-from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .lg\:focus\:dq-from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .lg\:focus\:dq-from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .lg\:focus\:dq-from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .lg\:focus\:dq-from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .lg\:focus\:dq-from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .lg\:focus\:dq-from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .lg\:focus\:dq-from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .lg\:focus\:dq-from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .lg\:focus\:dq-from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .lg\:focus\:dq-from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .lg\:focus\:dq-from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .lg\:focus\:dq-from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .lg\:focus\:dq-from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .lg\:focus\:dq-from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .lg\:focus\:dq-from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .lg\:focus\:dq-from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .lg\:focus\:dq-from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .lg\:focus\:dq-from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .lg\:focus\:dq-from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .lg\:focus\:dq-from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .lg\:focus\:dq-from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .lg\:focus\:dq-from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .lg\:focus\:dq-from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .lg\:focus\:dq-from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .lg\:focus\:dq-from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .lg\:focus\:dq-from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .lg\:focus\:dq-from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .lg\:focus\:dq-from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .lg\:focus\:dq-from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .lg\:focus\:dq-from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .lg\:focus\:dq-from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .lg\:focus\:dq-from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .lg\:focus\:dq-from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .lg\:focus\:dq-from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .lg\:focus\:dq-from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .lg\:focus\:dq-from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .lg\:focus\:dq-from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .lg\:focus\:dq-from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .lg\:focus\:dq-from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .lg\:focus\:dq-from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .lg\:focus\:dq-from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .lg\:focus\:dq-from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .lg\:focus\:dq-from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .lg\:focus\:dq-from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .lg\:focus\:dq-from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .lg\:dq-via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:dq-via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:dq-via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:dq-via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:dq-via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .lg\:dq-via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .lg\:dq-via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .lg\:dq-via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .lg\:dq-via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .lg\:dq-via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .lg\:dq-via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .lg\:dq-via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .lg\:dq-via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .lg\:dq-via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .lg\:dq-via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .lg\:dq-via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .lg\:dq-via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .lg\:dq-via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .lg\:dq-via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .lg\:dq-via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .lg\:dq-via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .lg\:dq-via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .lg\:dq-via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .lg\:dq-via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .lg\:dq-via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .lg\:dq-via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .lg\:dq-via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .lg\:dq-via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .lg\:dq-via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .lg\:dq-via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .lg\:dq-via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .lg\:dq-via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .lg\:dq-via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .lg\:dq-via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .lg\:dq-via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .lg\:dq-via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .lg\:dq-via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .lg\:dq-via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .lg\:dq-via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .lg\:dq-via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .lg\:dq-via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .lg\:dq-via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .lg\:dq-via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .lg\:dq-via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .lg\:dq-via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .lg\:dq-via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .lg\:dq-via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .lg\:dq-via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .lg\:dq-via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .lg\:dq-via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .lg\:dq-via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .lg\:dq-via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .lg\:dq-via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .lg\:dq-via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .lg\:dq-via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .lg\:dq-via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .lg\:dq-via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .lg\:dq-via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .lg\:dq-via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .lg\:dq-via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .lg\:dq-via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .lg\:dq-via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .lg\:dq-via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .lg\:dq-via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .lg\:dq-via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .lg\:dq-via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .lg\:dq-via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .lg\:dq-via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .lg\:dq-via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .lg\:dq-via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .lg\:dq-via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .lg\:dq-via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .lg\:dq-via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .lg\:dq-via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .lg\:dq-via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .lg\:dq-via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .lg\:dq-via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .lg\:dq-via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .lg\:dq-via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .lg\:dq-via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .lg\:dq-via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .lg\:dq-via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .lg\:dq-via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .lg\:dq-via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .lg\:hover\:dq-via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:dq-via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:dq-via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:dq-via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:dq-via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .lg\:hover\:dq-via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .lg\:hover\:dq-via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .lg\:hover\:dq-via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .lg\:hover\:dq-via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .lg\:hover\:dq-via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .lg\:hover\:dq-via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .lg\:hover\:dq-via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .lg\:hover\:dq-via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .lg\:hover\:dq-via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .lg\:hover\:dq-via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .lg\:hover\:dq-via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .lg\:hover\:dq-via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .lg\:hover\:dq-via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .lg\:hover\:dq-via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .lg\:hover\:dq-via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .lg\:hover\:dq-via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .lg\:hover\:dq-via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .lg\:hover\:dq-via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .lg\:hover\:dq-via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .lg\:hover\:dq-via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .lg\:hover\:dq-via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .lg\:hover\:dq-via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .lg\:hover\:dq-via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .lg\:hover\:dq-via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .lg\:hover\:dq-via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .lg\:hover\:dq-via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .lg\:hover\:dq-via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .lg\:hover\:dq-via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .lg\:hover\:dq-via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .lg\:hover\:dq-via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .lg\:hover\:dq-via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .lg\:hover\:dq-via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .lg\:hover\:dq-via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .lg\:hover\:dq-via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .lg\:hover\:dq-via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .lg\:hover\:dq-via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .lg\:hover\:dq-via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .lg\:hover\:dq-via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .lg\:hover\:dq-via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .lg\:hover\:dq-via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .lg\:hover\:dq-via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .lg\:hover\:dq-via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .lg\:hover\:dq-via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .lg\:hover\:dq-via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .lg\:hover\:dq-via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .lg\:hover\:dq-via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .lg\:hover\:dq-via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .lg\:hover\:dq-via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .lg\:hover\:dq-via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .lg\:hover\:dq-via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .lg\:hover\:dq-via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .lg\:hover\:dq-via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .lg\:hover\:dq-via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .lg\:hover\:dq-via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .lg\:hover\:dq-via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .lg\:hover\:dq-via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .lg\:hover\:dq-via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .lg\:hover\:dq-via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .lg\:hover\:dq-via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .lg\:hover\:dq-via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .lg\:hover\:dq-via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .lg\:hover\:dq-via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .lg\:hover\:dq-via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .lg\:hover\:dq-via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .lg\:hover\:dq-via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .lg\:hover\:dq-via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .lg\:hover\:dq-via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .lg\:hover\:dq-via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .lg\:hover\:dq-via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .lg\:hover\:dq-via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .lg\:hover\:dq-via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .lg\:hover\:dq-via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .lg\:hover\:dq-via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .lg\:hover\:dq-via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .lg\:hover\:dq-via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .lg\:hover\:dq-via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .lg\:hover\:dq-via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .lg\:hover\:dq-via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .lg\:hover\:dq-via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .lg\:focus\:dq-via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:dq-via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:dq-via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:dq-via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:dq-via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .lg\:focus\:dq-via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .lg\:focus\:dq-via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .lg\:focus\:dq-via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .lg\:focus\:dq-via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .lg\:focus\:dq-via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .lg\:focus\:dq-via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .lg\:focus\:dq-via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .lg\:focus\:dq-via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .lg\:focus\:dq-via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .lg\:focus\:dq-via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .lg\:focus\:dq-via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .lg\:focus\:dq-via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .lg\:focus\:dq-via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .lg\:focus\:dq-via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .lg\:focus\:dq-via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .lg\:focus\:dq-via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .lg\:focus\:dq-via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .lg\:focus\:dq-via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .lg\:focus\:dq-via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .lg\:focus\:dq-via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .lg\:focus\:dq-via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .lg\:focus\:dq-via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .lg\:focus\:dq-via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .lg\:focus\:dq-via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .lg\:focus\:dq-via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .lg\:focus\:dq-via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .lg\:focus\:dq-via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .lg\:focus\:dq-via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .lg\:focus\:dq-via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .lg\:focus\:dq-via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .lg\:focus\:dq-via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .lg\:focus\:dq-via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .lg\:focus\:dq-via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .lg\:focus\:dq-via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .lg\:focus\:dq-via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .lg\:focus\:dq-via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .lg\:focus\:dq-via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .lg\:focus\:dq-via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .lg\:focus\:dq-via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .lg\:focus\:dq-via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .lg\:focus\:dq-via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .lg\:focus\:dq-via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .lg\:focus\:dq-via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .lg\:focus\:dq-via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .lg\:focus\:dq-via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .lg\:focus\:dq-via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .lg\:focus\:dq-via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .lg\:focus\:dq-via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .lg\:focus\:dq-via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .lg\:focus\:dq-via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .lg\:focus\:dq-via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .lg\:focus\:dq-via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .lg\:focus\:dq-via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .lg\:focus\:dq-via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .lg\:focus\:dq-via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .lg\:focus\:dq-via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .lg\:focus\:dq-via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .lg\:focus\:dq-via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .lg\:focus\:dq-via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .lg\:focus\:dq-via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .lg\:focus\:dq-via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .lg\:focus\:dq-via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .lg\:focus\:dq-via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .lg\:focus\:dq-via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .lg\:focus\:dq-via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .lg\:focus\:dq-via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .lg\:focus\:dq-via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .lg\:focus\:dq-via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .lg\:focus\:dq-via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .lg\:focus\:dq-via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .lg\:focus\:dq-via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .lg\:focus\:dq-via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .lg\:focus\:dq-via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .lg\:focus\:dq-via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .lg\:focus\:dq-via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .lg\:focus\:dq-via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .lg\:focus\:dq-via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .lg\:focus\:dq-via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .lg\:focus\:dq-via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .lg\:dq-to-transparent {
    --tw-gradient-to: transparent;
  }

  .lg\:dq-to-current {
    --tw-gradient-to: currentColor;
  }

  .lg\:dq-to-black {
    --tw-gradient-to: #000;
  }

  .lg\:dq-to-white {
    --tw-gradient-to: #fff;
  }

  .lg\:dq-to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .lg\:dq-to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .lg\:dq-to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .lg\:dq-to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .lg\:dq-to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .lg\:dq-to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .lg\:dq-to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .lg\:dq-to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .lg\:dq-to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .lg\:dq-to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .lg\:dq-to-red-50 {
    --tw-gradient-to: #fef2f2;
  }

  .lg\:dq-to-red-100 {
    --tw-gradient-to: #fee2e2;
  }

  .lg\:dq-to-red-200 {
    --tw-gradient-to: #fecaca;
  }

  .lg\:dq-to-red-300 {
    --tw-gradient-to: #fca5a5;
  }

  .lg\:dq-to-red-400 {
    --tw-gradient-to: #f87171;
  }

  .lg\:dq-to-red-500 {
    --tw-gradient-to: #ef4444;
  }

  .lg\:dq-to-red-600 {
    --tw-gradient-to: #dc2626;
  }

  .lg\:dq-to-red-700 {
    --tw-gradient-to: #b91c1c;
  }

  .lg\:dq-to-red-800 {
    --tw-gradient-to: #991b1b;
  }

  .lg\:dq-to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }

  .lg\:dq-to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .lg\:dq-to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .lg\:dq-to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .lg\:dq-to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .lg\:dq-to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .lg\:dq-to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .lg\:dq-to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .lg\:dq-to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .lg\:dq-to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .lg\:dq-to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .lg\:dq-to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .lg\:dq-to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .lg\:dq-to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .lg\:dq-to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .lg\:dq-to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .lg\:dq-to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .lg\:dq-to-green-600 {
    --tw-gradient-to: #059669;
  }

  .lg\:dq-to-green-700 {
    --tw-gradient-to: #047857;
  }

  .lg\:dq-to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .lg\:dq-to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .lg\:dq-to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .lg\:dq-to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .lg\:dq-to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .lg\:dq-to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .lg\:dq-to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .lg\:dq-to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .lg\:dq-to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .lg\:dq-to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .lg\:dq-to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .lg\:dq-to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .lg\:dq-to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .lg\:dq-to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .lg\:dq-to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .lg\:dq-to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .lg\:dq-to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .lg\:dq-to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .lg\:dq-to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .lg\:dq-to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .lg\:dq-to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .lg\:dq-to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .lg\:dq-to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .lg\:dq-to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .lg\:dq-to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .lg\:dq-to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .lg\:dq-to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .lg\:dq-to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .lg\:dq-to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .lg\:dq-to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .lg\:dq-to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .lg\:dq-to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .lg\:dq-to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .lg\:dq-to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .lg\:dq-to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .lg\:dq-to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .lg\:dq-to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .lg\:dq-to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .lg\:dq-to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .lg\:dq-to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .lg\:dq-to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .lg\:dq-to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .lg\:hover\:dq-to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .lg\:hover\:dq-to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .lg\:hover\:dq-to-black:hover {
    --tw-gradient-to: #000;
  }

  .lg\:hover\:dq-to-white:hover {
    --tw-gradient-to: #fff;
  }

  .lg\:hover\:dq-to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .lg\:hover\:dq-to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .lg\:hover\:dq-to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .lg\:hover\:dq-to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .lg\:hover\:dq-to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .lg\:hover\:dq-to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .lg\:hover\:dq-to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .lg\:hover\:dq-to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .lg\:hover\:dq-to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .lg\:hover\:dq-to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .lg\:hover\:dq-to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }

  .lg\:hover\:dq-to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }

  .lg\:hover\:dq-to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }

  .lg\:hover\:dq-to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }

  .lg\:hover\:dq-to-red-400:hover {
    --tw-gradient-to: #f87171;
  }

  .lg\:hover\:dq-to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }

  .lg\:hover\:dq-to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }

  .lg\:hover\:dq-to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }

  .lg\:hover\:dq-to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }

  .lg\:hover\:dq-to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }

  .lg\:hover\:dq-to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .lg\:hover\:dq-to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .lg\:hover\:dq-to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .lg\:hover\:dq-to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .lg\:hover\:dq-to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .lg\:hover\:dq-to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .lg\:hover\:dq-to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .lg\:hover\:dq-to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .lg\:hover\:dq-to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .lg\:hover\:dq-to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .lg\:hover\:dq-to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .lg\:hover\:dq-to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .lg\:hover\:dq-to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .lg\:hover\:dq-to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .lg\:hover\:dq-to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .lg\:hover\:dq-to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .lg\:hover\:dq-to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .lg\:hover\:dq-to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .lg\:hover\:dq-to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .lg\:hover\:dq-to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .lg\:hover\:dq-to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .lg\:hover\:dq-to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .lg\:hover\:dq-to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .lg\:hover\:dq-to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .lg\:hover\:dq-to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .lg\:hover\:dq-to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .lg\:hover\:dq-to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .lg\:hover\:dq-to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .lg\:hover\:dq-to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .lg\:hover\:dq-to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .lg\:hover\:dq-to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .lg\:hover\:dq-to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .lg\:hover\:dq-to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .lg\:hover\:dq-to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .lg\:hover\:dq-to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .lg\:hover\:dq-to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .lg\:hover\:dq-to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .lg\:hover\:dq-to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .lg\:hover\:dq-to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .lg\:hover\:dq-to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .lg\:hover\:dq-to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .lg\:hover\:dq-to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .lg\:hover\:dq-to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .lg\:hover\:dq-to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .lg\:hover\:dq-to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .lg\:hover\:dq-to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .lg\:hover\:dq-to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .lg\:hover\:dq-to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .lg\:hover\:dq-to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .lg\:hover\:dq-to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .lg\:hover\:dq-to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .lg\:hover\:dq-to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .lg\:hover\:dq-to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .lg\:hover\:dq-to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .lg\:hover\:dq-to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .lg\:hover\:dq-to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .lg\:hover\:dq-to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .lg\:hover\:dq-to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .lg\:hover\:dq-to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .lg\:hover\:dq-to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .lg\:focus\:dq-to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .lg\:focus\:dq-to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .lg\:focus\:dq-to-black:focus {
    --tw-gradient-to: #000;
  }

  .lg\:focus\:dq-to-white:focus {
    --tw-gradient-to: #fff;
  }

  .lg\:focus\:dq-to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .lg\:focus\:dq-to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .lg\:focus\:dq-to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .lg\:focus\:dq-to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .lg\:focus\:dq-to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .lg\:focus\:dq-to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .lg\:focus\:dq-to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .lg\:focus\:dq-to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .lg\:focus\:dq-to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .lg\:focus\:dq-to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .lg\:focus\:dq-to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }

  .lg\:focus\:dq-to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }

  .lg\:focus\:dq-to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }

  .lg\:focus\:dq-to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }

  .lg\:focus\:dq-to-red-400:focus {
    --tw-gradient-to: #f87171;
  }

  .lg\:focus\:dq-to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }

  .lg\:focus\:dq-to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }

  .lg\:focus\:dq-to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }

  .lg\:focus\:dq-to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }

  .lg\:focus\:dq-to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }

  .lg\:focus\:dq-to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .lg\:focus\:dq-to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .lg\:focus\:dq-to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .lg\:focus\:dq-to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .lg\:focus\:dq-to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .lg\:focus\:dq-to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .lg\:focus\:dq-to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .lg\:focus\:dq-to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .lg\:focus\:dq-to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .lg\:focus\:dq-to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .lg\:focus\:dq-to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .lg\:focus\:dq-to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .lg\:focus\:dq-to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .lg\:focus\:dq-to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .lg\:focus\:dq-to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .lg\:focus\:dq-to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .lg\:focus\:dq-to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .lg\:focus\:dq-to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .lg\:focus\:dq-to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .lg\:focus\:dq-to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .lg\:focus\:dq-to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .lg\:focus\:dq-to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .lg\:focus\:dq-to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .lg\:focus\:dq-to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .lg\:focus\:dq-to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .lg\:focus\:dq-to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .lg\:focus\:dq-to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .lg\:focus\:dq-to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .lg\:focus\:dq-to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .lg\:focus\:dq-to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .lg\:focus\:dq-to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .lg\:focus\:dq-to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .lg\:focus\:dq-to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .lg\:focus\:dq-to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .lg\:focus\:dq-to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .lg\:focus\:dq-to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .lg\:focus\:dq-to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .lg\:focus\:dq-to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .lg\:focus\:dq-to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .lg\:focus\:dq-to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .lg\:focus\:dq-to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .lg\:focus\:dq-to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .lg\:focus\:dq-to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .lg\:focus\:dq-to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .lg\:focus\:dq-to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .lg\:focus\:dq-to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .lg\:focus\:dq-to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .lg\:focus\:dq-to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .lg\:focus\:dq-to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .lg\:focus\:dq-to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .lg\:focus\:dq-to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .lg\:focus\:dq-to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .lg\:focus\:dq-to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .lg\:focus\:dq-to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .lg\:focus\:dq-to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .lg\:focus\:dq-to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .lg\:focus\:dq-to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .lg\:focus\:dq-to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .lg\:focus\:dq-to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .lg\:focus\:dq-to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .lg\:dq-decoration-slice {
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
  }

  .lg\:dq-decoration-clone {
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
  }

  .lg\:dq-bg-auto {
    background-size: auto;
  }

  .lg\:dq-bg-cover {
    background-size: cover;
  }

  .lg\:dq-bg-contain {
    background-size: contain;
  }

  .lg\:dq-bg-fixed {
    background-attachment: fixed;
  }

  .lg\:dq-bg-local {
    background-attachment: local;
  }

  .lg\:dq-bg-scroll {
    background-attachment: scroll;
  }

  .lg\:dq-bg-clip-border {
    background-clip: border-box;
  }

  .lg\:dq-bg-clip-padding {
    background-clip: padding-box;
  }

  .lg\:dq-bg-clip-content {
    background-clip: content-box;
  }

  .lg\:dq-bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .lg\:dq-bg-bottom {
    background-position: bottom;
  }

  .lg\:dq-bg-center {
    background-position: center;
  }

  .lg\:dq-bg-left {
    background-position: left;
  }

  .lg\:dq-bg-left-bottom {
    background-position: left bottom;
  }

  .lg\:dq-bg-left-top {
    background-position: left top;
  }

  .lg\:dq-bg-right {
    background-position: right;
  }

  .lg\:dq-bg-right-bottom {
    background-position: right bottom;
  }

  .lg\:dq-bg-right-top {
    background-position: right top;
  }

  .lg\:dq-bg-top {
    background-position: top;
  }

  .lg\:dq-bg-repeat {
    background-repeat: repeat;
  }

  .lg\:dq-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .lg\:dq-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .lg\:dq-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .lg\:dq-bg-repeat-round {
    background-repeat: round;
  }

  .lg\:dq-bg-repeat-space {
    background-repeat: space;
  }

  .lg\:dq-bg-origin-border {
    background-origin: border-box;
  }

  .lg\:dq-bg-origin-padding {
    background-origin: padding-box;
  }

  .lg\:dq-bg-origin-content {
    background-origin: content-box;
  }

  .lg\:dq-fill-current {
    fill: currentColor;
  }

  .lg\:dq-stroke-current {
    stroke: currentColor;
  }

  .lg\:dq-stroke-0 {
    stroke-width: 0;
  }

  .lg\:dq-stroke-1 {
    stroke-width: 1;
  }

  .lg\:dq-stroke-2 {
    stroke-width: 2;
  }

  .lg\:dq-object-contain {
    object-fit: contain;
  }

  .lg\:dq-object-cover {
    object-fit: cover;
  }

  .lg\:dq-object-fill {
    object-fit: fill;
  }

  .lg\:dq-object-none {
    object-fit: none;
  }

  .lg\:dq-object-scale-down {
    object-fit: scale-down;
  }

  .lg\:dq-object-bottom {
    object-position: bottom;
  }

  .lg\:dq-object-center {
    object-position: center;
  }

  .lg\:dq-object-left {
    object-position: left;
  }

  .lg\:dq-object-left-bottom {
    object-position: left bottom;
  }

  .lg\:dq-object-left-top {
    object-position: left top;
  }

  .lg\:dq-object-right {
    object-position: right;
  }

  .lg\:dq-object-right-bottom {
    object-position: right bottom;
  }

  .lg\:dq-object-right-top {
    object-position: right top;
  }

  .lg\:dq-object-top {
    object-position: top;
  }

  .lg\:dq-p-0 {
    padding: 0px;
  }

  .lg\:dq-p-1 {
    padding: 0.25rem;
  }

  .lg\:dq-p-2 {
    padding: 0.5rem;
  }

  .lg\:dq-p-3 {
    padding: 0.75rem;
  }

  .lg\:dq-p-4 {
    padding: 1rem;
  }

  .lg\:dq-p-5 {
    padding: 1.25rem;
  }

  .lg\:dq-p-6 {
    padding: 1.5rem;
  }

  .lg\:dq-p-7 {
    padding: 1.75rem;
  }

  .lg\:dq-p-8 {
    padding: 2rem;
  }

  .lg\:dq-p-9 {
    padding: 2.25rem;
  }

  .lg\:dq-p-10 {
    padding: 2.5rem;
  }

  .lg\:dq-p-11 {
    padding: 2.75rem;
  }

  .lg\:dq-p-12 {
    padding: 3rem;
  }

  .lg\:dq-p-14 {
    padding: 3.5rem;
  }

  .lg\:dq-p-16 {
    padding: 4rem;
  }

  .lg\:dq-p-20 {
    padding: 5rem;
  }

  .lg\:dq-p-24 {
    padding: 6rem;
  }

  .lg\:dq-p-28 {
    padding: 7rem;
  }

  .lg\:dq-p-32 {
    padding: 8rem;
  }

  .lg\:dq-p-36 {
    padding: 9rem;
  }

  .lg\:dq-p-40 {
    padding: 10rem;
  }

  .lg\:dq-p-44 {
    padding: 11rem;
  }

  .lg\:dq-p-48 {
    padding: 12rem;
  }

  .lg\:dq-p-52 {
    padding: 13rem;
  }

  .lg\:dq-p-56 {
    padding: 14rem;
  }

  .lg\:dq-p-60 {
    padding: 15rem;
  }

  .lg\:dq-p-64 {
    padding: 16rem;
  }

  .lg\:dq-p-72 {
    padding: 18rem;
  }

  .lg\:dq-p-80 {
    padding: 20rem;
  }

  .lg\:dq-p-96 {
    padding: 24rem;
  }

  .lg\:dq-p-px {
    padding: 1px;
  }

  .lg\:dq-p-0\.5 {
    padding: 0.125rem;
  }

  .lg\:dq-p-1\.5 {
    padding: 0.375rem;
  }

  .lg\:dq-p-2\.5 {
    padding: 0.625rem;
  }

  .lg\:dq-p-3\.5 {
    padding: 0.875rem;
  }

  .lg\:dq-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .lg\:dq-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .lg\:dq-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .lg\:dq-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .lg\:dq-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:dq-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:dq-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:dq-px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:dq-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:dq-px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .lg\:dq-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:dq-px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .lg\:dq-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:dq-px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg\:dq-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:dq-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:dq-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:dq-px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .lg\:dq-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:dq-px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .lg\:dq-px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .lg\:dq-px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .lg\:dq-px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .lg\:dq-px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .lg\:dq-px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .lg\:dq-px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .lg\:dq-px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .lg\:dq-px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .lg\:dq-px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .lg\:dq-px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .lg\:dq-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .lg\:dq-px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }

  .lg\:dq-px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }

  .lg\:dq-px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .lg\:dq-px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }

  .lg\:dq-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .lg\:dq-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .lg\:dq-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .lg\:dq-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .lg\:dq-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:dq-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:dq-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:dq-py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .lg\:dq-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:dq-py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .lg\:dq-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:dq-py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .lg\:dq-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:dq-py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .lg\:dq-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:dq-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:dq-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:dq-py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:dq-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:dq-py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .lg\:dq-py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:dq-py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .lg\:dq-py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .lg\:dq-py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .lg\:dq-py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .lg\:dq-py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .lg\:dq-py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .lg\:dq-py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .lg\:dq-py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .lg\:dq-py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .lg\:dq-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .lg\:dq-py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }

  .lg\:dq-py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  .lg\:dq-py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .lg\:dq-py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  .lg\:dq-pt-0 {
    padding-top: 0px;
  }

  .lg\:dq-pt-1 {
    padding-top: 0.25rem;
  }

  .lg\:dq-pt-2 {
    padding-top: 0.5rem;
  }

  .lg\:dq-pt-3 {
    padding-top: 0.75rem;
  }

  .lg\:dq-pt-4 {
    padding-top: 1rem;
  }

  .lg\:dq-pt-5 {
    padding-top: 1.25rem;
  }

  .lg\:dq-pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:dq-pt-7 {
    padding-top: 1.75rem;
  }

  .lg\:dq-pt-8 {
    padding-top: 2rem;
  }

  .lg\:dq-pt-9 {
    padding-top: 2.25rem;
  }

  .lg\:dq-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:dq-pt-11 {
    padding-top: 2.75rem;
  }

  .lg\:dq-pt-12 {
    padding-top: 3rem;
  }

  .lg\:dq-pt-14 {
    padding-top: 3.5rem;
  }

  .lg\:dq-pt-16 {
    padding-top: 4rem;
  }

  .lg\:dq-pt-20 {
    padding-top: 5rem;
  }

  .lg\:dq-pt-24 {
    padding-top: 6rem;
  }

  .lg\:dq-pt-28 {
    padding-top: 7rem;
  }

  .lg\:dq-pt-32 {
    padding-top: 8rem;
  }

  .lg\:dq-pt-36 {
    padding-top: 9rem;
  }

  .lg\:dq-pt-40 {
    padding-top: 10rem;
  }

  .lg\:dq-pt-44 {
    padding-top: 11rem;
  }

  .lg\:dq-pt-48 {
    padding-top: 12rem;
  }

  .lg\:dq-pt-52 {
    padding-top: 13rem;
  }

  .lg\:dq-pt-56 {
    padding-top: 14rem;
  }

  .lg\:dq-pt-60 {
    padding-top: 15rem;
  }

  .lg\:dq-pt-64 {
    padding-top: 16rem;
  }

  .lg\:dq-pt-72 {
    padding-top: 18rem;
  }

  .lg\:dq-pt-80 {
    padding-top: 20rem;
  }

  .lg\:dq-pt-96 {
    padding-top: 24rem;
  }

  .lg\:dq-pt-px {
    padding-top: 1px;
  }

  .lg\:dq-pt-0\.5 {
    padding-top: 0.125rem;
  }

  .lg\:dq-pt-1\.5 {
    padding-top: 0.375rem;
  }

  .lg\:dq-pt-2\.5 {
    padding-top: 0.625rem;
  }

  .lg\:dq-pt-3\.5 {
    padding-top: 0.875rem;
  }

  .lg\:dq-pr-0 {
    padding-right: 0px;
  }

  .lg\:dq-pr-1 {
    padding-right: 0.25rem;
  }

  .lg\:dq-pr-2 {
    padding-right: 0.5rem;
  }

  .lg\:dq-pr-3 {
    padding-right: 0.75rem;
  }

  .lg\:dq-pr-4 {
    padding-right: 1rem;
  }

  .lg\:dq-pr-5 {
    padding-right: 1.25rem;
  }

  .lg\:dq-pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:dq-pr-7 {
    padding-right: 1.75rem;
  }

  .lg\:dq-pr-8 {
    padding-right: 2rem;
  }

  .lg\:dq-pr-9 {
    padding-right: 2.25rem;
  }

  .lg\:dq-pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:dq-pr-11 {
    padding-right: 2.75rem;
  }

  .lg\:dq-pr-12 {
    padding-right: 3rem;
  }

  .lg\:dq-pr-14 {
    padding-right: 3.5rem;
  }

  .lg\:dq-pr-16 {
    padding-right: 4rem;
  }

  .lg\:dq-pr-20 {
    padding-right: 5rem;
  }

  .lg\:dq-pr-24 {
    padding-right: 6rem;
  }

  .lg\:dq-pr-28 {
    padding-right: 7rem;
  }

  .lg\:dq-pr-32 {
    padding-right: 8rem;
  }

  .lg\:dq-pr-36 {
    padding-right: 9rem;
  }

  .lg\:dq-pr-40 {
    padding-right: 10rem;
  }

  .lg\:dq-pr-44 {
    padding-right: 11rem;
  }

  .lg\:dq-pr-48 {
    padding-right: 12rem;
  }

  .lg\:dq-pr-52 {
    padding-right: 13rem;
  }

  .lg\:dq-pr-56 {
    padding-right: 14rem;
  }

  .lg\:dq-pr-60 {
    padding-right: 15rem;
  }

  .lg\:dq-pr-64 {
    padding-right: 16rem;
  }

  .lg\:dq-pr-72 {
    padding-right: 18rem;
  }

  .lg\:dq-pr-80 {
    padding-right: 20rem;
  }

  .lg\:dq-pr-96 {
    padding-right: 24rem;
  }

  .lg\:dq-pr-px {
    padding-right: 1px;
  }

  .lg\:dq-pr-0\.5 {
    padding-right: 0.125rem;
  }

  .lg\:dq-pr-1\.5 {
    padding-right: 0.375rem;
  }

  .lg\:dq-pr-2\.5 {
    padding-right: 0.625rem;
  }

  .lg\:dq-pr-3\.5 {
    padding-right: 0.875rem;
  }

  .lg\:dq-pb-0 {
    padding-bottom: 0px;
  }

  .lg\:dq-pb-1 {
    padding-bottom: 0.25rem;
  }

  .lg\:dq-pb-2 {
    padding-bottom: 0.5rem;
  }

  .lg\:dq-pb-3 {
    padding-bottom: 0.75rem;
  }

  .lg\:dq-pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:dq-pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:dq-pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:dq-pb-7 {
    padding-bottom: 1.75rem;
  }

  .lg\:dq-pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:dq-pb-9 {
    padding-bottom: 2.25rem;
  }

  .lg\:dq-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:dq-pb-11 {
    padding-bottom: 2.75rem;
  }

  .lg\:dq-pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:dq-pb-14 {
    padding-bottom: 3.5rem;
  }

  .lg\:dq-pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:dq-pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:dq-pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:dq-pb-28 {
    padding-bottom: 7rem;
  }

  .lg\:dq-pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:dq-pb-36 {
    padding-bottom: 9rem;
  }

  .lg\:dq-pb-40 {
    padding-bottom: 10rem;
  }

  .lg\:dq-pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:dq-pb-48 {
    padding-bottom: 12rem;
  }

  .lg\:dq-pb-52 {
    padding-bottom: 13rem;
  }

  .lg\:dq-pb-56 {
    padding-bottom: 14rem;
  }

  .lg\:dq-pb-60 {
    padding-bottom: 15rem;
  }

  .lg\:dq-pb-64 {
    padding-bottom: 16rem;
  }

  .lg\:dq-pb-72 {
    padding-bottom: 18rem;
  }

  .lg\:dq-pb-80 {
    padding-bottom: 20rem;
  }

  .lg\:dq-pb-96 {
    padding-bottom: 24rem;
  }

  .lg\:dq-pb-px {
    padding-bottom: 1px;
  }

  .lg\:dq-pb-0\.5 {
    padding-bottom: 0.125rem;
  }

  .lg\:dq-pb-1\.5 {
    padding-bottom: 0.375rem;
  }

  .lg\:dq-pb-2\.5 {
    padding-bottom: 0.625rem;
  }

  .lg\:dq-pb-3\.5 {
    padding-bottom: 0.875rem;
  }

  .lg\:dq-pl-0 {
    padding-left: 0px;
  }

  .lg\:dq-pl-1 {
    padding-left: 0.25rem;
  }

  .lg\:dq-pl-2 {
    padding-left: 0.5rem;
  }

  .lg\:dq-pl-3 {
    padding-left: 0.75rem;
  }

  .lg\:dq-pl-4 {
    padding-left: 1rem;
  }

  .lg\:dq-pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:dq-pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:dq-pl-7 {
    padding-left: 1.75rem;
  }

  .lg\:dq-pl-8 {
    padding-left: 2rem;
  }

  .lg\:dq-pl-9 {
    padding-left: 2.25rem;
  }

  .lg\:dq-pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:dq-pl-11 {
    padding-left: 2.75rem;
  }

  .lg\:dq-pl-12 {
    padding-left: 3rem;
  }

  .lg\:dq-pl-14 {
    padding-left: 3.5rem;
  }

  .lg\:dq-pl-16 {
    padding-left: 4rem;
  }

  .lg\:dq-pl-20 {
    padding-left: 5rem;
  }

  .lg\:dq-pl-24 {
    padding-left: 6rem;
  }

  .lg\:dq-pl-28 {
    padding-left: 7rem;
  }

  .lg\:dq-pl-32 {
    padding-left: 8rem;
  }

  .lg\:dq-pl-36 {
    padding-left: 9rem;
  }

  .lg\:dq-pl-40 {
    padding-left: 10rem;
  }

  .lg\:dq-pl-44 {
    padding-left: 11rem;
  }

  .lg\:dq-pl-48 {
    padding-left: 12rem;
  }

  .lg\:dq-pl-52 {
    padding-left: 13rem;
  }

  .lg\:dq-pl-56 {
    padding-left: 14rem;
  }

  .lg\:dq-pl-60 {
    padding-left: 15rem;
  }

  .lg\:dq-pl-64 {
    padding-left: 16rem;
  }

  .lg\:dq-pl-72 {
    padding-left: 18rem;
  }

  .lg\:dq-pl-80 {
    padding-left: 20rem;
  }

  .lg\:dq-pl-96 {
    padding-left: 24rem;
  }

  .lg\:dq-pl-px {
    padding-left: 1px;
  }

  .lg\:dq-pl-0\.5 {
    padding-left: 0.125rem;
  }

  .lg\:dq-pl-1\.5 {
    padding-left: 0.375rem;
  }

  .lg\:dq-pl-2\.5 {
    padding-left: 0.625rem;
  }

  .lg\:dq-pl-3\.5 {
    padding-left: 0.875rem;
  }

  .lg\:dq-text-left {
    text-align: left;
  }

  .lg\:dq-text-center {
    text-align: center;
  }

  .lg\:dq-text-right {
    text-align: right;
  }

  .lg\:dq-text-justify {
    text-align: justify;
  }

  .lg\:dq-align-baseline {
    vertical-align: baseline;
  }

  .lg\:dq-align-top {
    vertical-align: top;
  }

  .lg\:dq-align-middle {
    vertical-align: middle;
  }

  .lg\:dq-align-bottom {
    vertical-align: bottom;
  }

  .lg\:dq-align-text-top {
    vertical-align: text-top;
  }

  .lg\:dq-align-text-bottom {
    vertical-align: text-bottom;
  }

  .lg\:dq-font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .lg\:dq-font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .lg\:dq-font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .lg\:dq-text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .lg\:dq-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .lg\:dq-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:dq-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:dq-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:dq-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:dq-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:dq-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:dq-text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:dq-text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:dq-text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .lg\:dq-text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .lg\:dq-text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .lg\:dq-font-thin {
    font-weight: 100;
  }

  .lg\:dq-font-extralight {
    font-weight: 200;
  }

  .lg\:dq-font-light {
    font-weight: 300;
  }

  .lg\:dq-font-normal {
    font-weight: 400;
  }

  .lg\:dq-font-medium {
    font-weight: 500;
  }

  .lg\:dq-font-semibold {
    font-weight: 600;
  }

  .lg\:dq-font-bold {
    font-weight: 700;
  }

  .lg\:dq-font-extrabold {
    font-weight: 800;
  }

  .lg\:dq-font-black {
    font-weight: 900;
  }

  .lg\:dq-uppercase {
    text-transform: uppercase;
  }

  .lg\:dq-lowercase {
    text-transform: lowercase;
  }

  .lg\:dq-capitalize {
    text-transform: capitalize;
  }

  .lg\:dq-normal-case {
    text-transform: none;
  }

  .lg\:dq-italic {
    font-style: italic;
  }

  .lg\:dq-not-italic {
    font-style: normal;
  }

  .lg\:dq-ordinal, .lg\:dq-slashed-zero, .lg\:dq-lining-nums, .lg\:dq-oldstyle-nums, .lg\:dq-proportional-nums, .lg\:dq-tabular-nums, .lg\:dq-diagonal-fractions, .lg\:dq-stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:dq-normal-nums {
    font-variant-numeric: normal;
  }

  .lg\:dq-ordinal {
    --tw-ordinal: ordinal;
  }

  .lg\:dq-slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .lg\:dq-lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .lg\:dq-oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .lg\:dq-proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .lg\:dq-tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .lg\:dq-diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .lg\:dq-stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .lg\:dq-leading-3 {
    line-height: .75rem;
  }

  .lg\:dq-leading-4 {
    line-height: 1rem;
  }

  .lg\:dq-leading-5 {
    line-height: 1.25rem;
  }

  .lg\:dq-leading-6 {
    line-height: 1.5rem;
  }

  .lg\:dq-leading-7 {
    line-height: 1.75rem;
  }

  .lg\:dq-leading-8 {
    line-height: 2rem;
  }

  .lg\:dq-leading-9 {
    line-height: 2.25rem;
  }

  .lg\:dq-leading-10 {
    line-height: 2.5rem;
  }

  .lg\:dq-leading-none {
    line-height: 1;
  }

  .lg\:dq-leading-tight {
    line-height: 1.25;
  }

  .lg\:dq-leading-snug {
    line-height: 1.375;
  }

  .lg\:dq-leading-normal {
    line-height: 1.5;
  }

  .lg\:dq-leading-relaxed {
    line-height: 1.625;
  }

  .lg\:dq-leading-loose {
    line-height: 2;
  }

  .lg\:dq-tracking-tighter {
    letter-spacing: -0.05em;
  }

  .lg\:dq-tracking-tight {
    letter-spacing: -0.025em;
  }

  .lg\:dq-tracking-normal {
    letter-spacing: 0em;
  }

  .lg\:dq-tracking-wide {
    letter-spacing: 0.025em;
  }

  .lg\:dq-tracking-wider {
    letter-spacing: 0.05em;
  }

  .lg\:dq-tracking-widest {
    letter-spacing: 0.1em;
  }

  .lg\:dq-text-transparent {
    color: transparent;
  }

  .lg\:dq-text-current {
    color: currentColor;
  }

  .lg\:dq-text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg\:dq-text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:dq-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg\:dq-text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg\:dq-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg\:dq-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg\:dq-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg\:dq-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg\:dq-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg\:dq-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg\:dq-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg\:dq-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg\:dq-text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .lg\:dq-text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .lg\:dq-text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .lg\:dq-text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .lg\:dq-text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .lg\:dq-text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .lg\:dq-text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .lg\:dq-text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .lg\:dq-text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .lg\:dq-text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .lg\:dq-text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg\:dq-text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg\:dq-text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg\:dq-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg\:dq-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg\:dq-text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg\:dq-text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg\:dq-text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg\:dq-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg\:dq-text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg\:dq-text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg\:dq-text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg\:dq-text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg\:dq-text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg\:dq-text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg\:dq-text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg\:dq-text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg\:dq-text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg\:dq-text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg\:dq-text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg\:dq-text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg\:dq-text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg\:dq-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg\:dq-text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg\:dq-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg\:dq-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg\:dq-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg\:dq-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg\:dq-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg\:dq-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg\:dq-text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg\:dq-text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg\:dq-text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg\:dq-text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg\:dq-text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg\:dq-text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg\:dq-text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg\:dq-text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg\:dq-text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg\:dq-text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg\:dq-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg\:dq-text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg\:dq-text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg\:dq-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg\:dq-text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg\:dq-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg\:dq-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg\:dq-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg\:dq-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg\:dq-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg\:dq-text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg\:dq-text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg\:dq-text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg\:dq-text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg\:dq-text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg\:dq-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg\:dq-text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg\:dq-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg\:dq-text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg\:dq-text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-transparent {
    color: transparent;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-current {
    color: currentColor;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .dq-group:hover .lg\:group-hover\:dq-text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-transparent:focus-within {
    color: transparent;
  }

  .lg\:focus-within\:dq-text-current:focus-within {
    color: currentColor;
  }

  .lg\:focus-within\:dq-text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg\:focus-within\:dq-text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-transparent:hover {
    color: transparent;
  }

  .lg\:hover\:dq-text-current:hover {
    color: currentColor;
  }

  .lg\:hover\:dq-text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg\:hover\:dq-text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-transparent:focus {
    color: transparent;
  }

  .lg\:focus\:dq-text-current:focus {
    color: currentColor;
  }

  .lg\:focus\:dq-text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg\:focus\:dq-text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg\:dq-text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .lg\:dq-text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .lg\:dq-text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .lg\:dq-text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .lg\:dq-text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .lg\:dq-text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .lg\:dq-text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .lg\:dq-text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .lg\:dq-text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .lg\:dq-text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .lg\:dq-text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .lg\:dq-text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .lg\:dq-text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .lg\:dq-text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .lg\:dq-text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .dq-group:hover .lg\:group-hover\:dq-text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .lg\:focus-within\:dq-text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .lg\:focus-within\:dq-text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }

  .lg\:focus-within\:dq-text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }

  .lg\:focus-within\:dq-text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }

  .lg\:focus-within\:dq-text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }

  .lg\:focus-within\:dq-text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }

  .lg\:focus-within\:dq-text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }

  .lg\:focus-within\:dq-text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }

  .lg\:focus-within\:dq-text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }

  .lg\:focus-within\:dq-text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }

  .lg\:focus-within\:dq-text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }

  .lg\:focus-within\:dq-text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }

  .lg\:focus-within\:dq-text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }

  .lg\:focus-within\:dq-text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }

  .lg\:focus-within\:dq-text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .lg\:hover\:dq-text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .lg\:hover\:dq-text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }

  .lg\:hover\:dq-text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }

  .lg\:hover\:dq-text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }

  .lg\:hover\:dq-text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }

  .lg\:hover\:dq-text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }

  .lg\:hover\:dq-text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }

  .lg\:hover\:dq-text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }

  .lg\:hover\:dq-text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }

  .lg\:hover\:dq-text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }

  .lg\:hover\:dq-text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }

  .lg\:hover\:dq-text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }

  .lg\:hover\:dq-text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }

  .lg\:hover\:dq-text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }

  .lg\:hover\:dq-text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .lg\:focus\:dq-text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .lg\:focus\:dq-text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }

  .lg\:focus\:dq-text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }

  .lg\:focus\:dq-text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }

  .lg\:focus\:dq-text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }

  .lg\:focus\:dq-text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }

  .lg\:focus\:dq-text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }

  .lg\:focus\:dq-text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }

  .lg\:focus\:dq-text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }

  .lg\:focus\:dq-text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }

  .lg\:focus\:dq-text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }

  .lg\:focus\:dq-text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }

  .lg\:focus\:dq-text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }

  .lg\:focus\:dq-text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }

  .lg\:focus\:dq-text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .lg\:dq-underline {
    text-decoration: underline;
  }

  .lg\:dq-line-through {
    text-decoration: line-through;
  }

  .lg\:dq-no-underline {
    text-decoration: none;
  }

  .dq-group:hover .lg\:group-hover\:dq-underline {
    text-decoration: underline;
  }

  .dq-group:hover .lg\:group-hover\:dq-line-through {
    text-decoration: line-through;
  }

  .dq-group:hover .lg\:group-hover\:dq-no-underline {
    text-decoration: none;
  }

  .lg\:focus-within\:dq-underline:focus-within {
    text-decoration: underline;
  }

  .lg\:focus-within\:dq-line-through:focus-within {
    text-decoration: line-through;
  }

  .lg\:focus-within\:dq-no-underline:focus-within {
    text-decoration: none;
  }

  .lg\:hover\:dq-underline:hover {
    text-decoration: underline;
  }

  .lg\:hover\:dq-line-through:hover {
    text-decoration: line-through;
  }

  .lg\:hover\:dq-no-underline:hover {
    text-decoration: none;
  }

  .lg\:focus\:dq-underline:focus {
    text-decoration: underline;
  }

  .lg\:focus\:dq-line-through:focus {
    text-decoration: line-through;
  }

  .lg\:focus\:dq-no-underline:focus {
    text-decoration: none;
  }

  .lg\:dq-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg\:dq-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg\:dq-placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }

  .lg\:dq-placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }

  .lg\:dq-placeholder-transparent::placeholder {
    color: transparent;
  }

  .lg\:dq-placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }

  .lg\:dq-placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }

  .lg\:dq-placeholder-current::placeholder {
    color: currentColor;
  }

  .lg\:dq-placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .lg\:focus\:dq-placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }

  .lg\:focus\:dq-placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .lg\:focus\:dq-placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }

  .lg\:focus\:dq-placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }

  .lg\:focus\:dq-placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .lg\:focus\:dq-placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:dq-placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .lg\:dq-placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg\:dq-placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg\:dq-placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg\:dq-placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .lg\:dq-placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .lg\:dq-placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .lg\:dq-placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .lg\:dq-placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .lg\:dq-placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .lg\:dq-placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .lg\:dq-placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .lg\:dq-placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .lg\:dq-placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:dq-placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:dq-placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:dq-placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .lg\:dq-placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .lg\:dq-placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .lg\:dq-placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .lg\:dq-placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .lg\:dq-placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .lg\:dq-placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:dq-placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:dq-placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:dq-placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .lg\:dq-placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .lg\:dq-placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .lg\:dq-placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .lg\:dq-placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .lg\:dq-placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .lg\:dq-placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:dq-placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:dq-placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:dq-placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .lg\:dq-placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .lg\:dq-placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .lg\:dq-placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:dq-placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:dq-placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:dq-placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .lg\:dq-placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .lg\:dq-placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .lg\:dq-placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg\:dq-placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg\:dq-placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg\:focus\:dq-placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg\:focus\:dq-placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg\:focus\:dq-placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg\:focus\:dq-placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .lg\:focus\:dq-placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .lg\:focus\:dq-placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .lg\:focus\:dq-placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .lg\:focus\:dq-placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .lg\:focus\:dq-placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .lg\:focus\:dq-placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .lg\:focus\:dq-placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .lg\:focus\:dq-placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .lg\:focus\:dq-placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:focus\:dq-placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:focus\:dq-placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:focus\:dq-placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .lg\:focus\:dq-placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .lg\:focus\:dq-placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .lg\:focus\:dq-placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .lg\:focus\:dq-placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .lg\:focus\:dq-placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .lg\:focus\:dq-placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:focus\:dq-placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:focus\:dq-placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:focus\:dq-placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .lg\:focus\:dq-placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .lg\:focus\:dq-placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .lg\:focus\:dq-placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .lg\:focus\:dq-placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .lg\:focus\:dq-placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .lg\:focus\:dq-placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:focus\:dq-placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:focus\:dq-placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:focus\:dq-placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .lg\:focus\:dq-placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .lg\:focus\:dq-placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .lg\:focus\:dq-placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:focus\:dq-placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:focus\:dq-placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .lg\:focus\:dq-placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .lg\:focus\:dq-placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .lg\:focus\:dq-placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .lg\:focus\:dq-placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg\:focus\:dq-placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg\:focus\:dq-placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg\:dq-opacity-0 {
    opacity: 0;
  }

  .lg\:dq-opacity-5 {
    opacity: 0.05;
  }

  .lg\:dq-opacity-10 {
    opacity: 0.1;
  }

  .lg\:dq-opacity-20 {
    opacity: 0.2;
  }

  .lg\:dq-opacity-25 {
    opacity: 0.25;
  }

  .lg\:dq-opacity-30 {
    opacity: 0.3;
  }

  .lg\:dq-opacity-40 {
    opacity: 0.4;
  }

  .lg\:dq-opacity-50 {
    opacity: 0.5;
  }

  .lg\:dq-opacity-60 {
    opacity: 0.6;
  }

  .lg\:dq-opacity-70 {
    opacity: 0.7;
  }

  .lg\:dq-opacity-75 {
    opacity: 0.75;
  }

  .lg\:dq-opacity-80 {
    opacity: 0.8;
  }

  .lg\:dq-opacity-90 {
    opacity: 0.9;
  }

  .lg\:dq-opacity-95 {
    opacity: 0.95;
  }

  .lg\:dq-opacity-100 {
    opacity: 1;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-0 {
    opacity: 0;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-5 {
    opacity: 0.05;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-10 {
    opacity: 0.1;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-20 {
    opacity: 0.2;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-25 {
    opacity: 0.25;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-30 {
    opacity: 0.3;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-40 {
    opacity: 0.4;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-50 {
    opacity: 0.5;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-60 {
    opacity: 0.6;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-70 {
    opacity: 0.7;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-75 {
    opacity: 0.75;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-80 {
    opacity: 0.8;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-90 {
    opacity: 0.9;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-95 {
    opacity: 0.95;
  }

  .dq-group:hover .lg\:group-hover\:dq-opacity-100 {
    opacity: 1;
  }

  .lg\:focus-within\:dq-opacity-0:focus-within {
    opacity: 0;
  }

  .lg\:focus-within\:dq-opacity-5:focus-within {
    opacity: 0.05;
  }

  .lg\:focus-within\:dq-opacity-10:focus-within {
    opacity: 0.1;
  }

  .lg\:focus-within\:dq-opacity-20:focus-within {
    opacity: 0.2;
  }

  .lg\:focus-within\:dq-opacity-25:focus-within {
    opacity: 0.25;
  }

  .lg\:focus-within\:dq-opacity-30:focus-within {
    opacity: 0.3;
  }

  .lg\:focus-within\:dq-opacity-40:focus-within {
    opacity: 0.4;
  }

  .lg\:focus-within\:dq-opacity-50:focus-within {
    opacity: 0.5;
  }

  .lg\:focus-within\:dq-opacity-60:focus-within {
    opacity: 0.6;
  }

  .lg\:focus-within\:dq-opacity-70:focus-within {
    opacity: 0.7;
  }

  .lg\:focus-within\:dq-opacity-75:focus-within {
    opacity: 0.75;
  }

  .lg\:focus-within\:dq-opacity-80:focus-within {
    opacity: 0.8;
  }

  .lg\:focus-within\:dq-opacity-90:focus-within {
    opacity: 0.9;
  }

  .lg\:focus-within\:dq-opacity-95:focus-within {
    opacity: 0.95;
  }

  .lg\:focus-within\:dq-opacity-100:focus-within {
    opacity: 1;
  }

  .lg\:hover\:dq-opacity-0:hover {
    opacity: 0;
  }

  .lg\:hover\:dq-opacity-5:hover {
    opacity: 0.05;
  }

  .lg\:hover\:dq-opacity-10:hover {
    opacity: 0.1;
  }

  .lg\:hover\:dq-opacity-20:hover {
    opacity: 0.2;
  }

  .lg\:hover\:dq-opacity-25:hover {
    opacity: 0.25;
  }

  .lg\:hover\:dq-opacity-30:hover {
    opacity: 0.3;
  }

  .lg\:hover\:dq-opacity-40:hover {
    opacity: 0.4;
  }

  .lg\:hover\:dq-opacity-50:hover {
    opacity: 0.5;
  }

  .lg\:hover\:dq-opacity-60:hover {
    opacity: 0.6;
  }

  .lg\:hover\:dq-opacity-70:hover {
    opacity: 0.7;
  }

  .lg\:hover\:dq-opacity-75:hover {
    opacity: 0.75;
  }

  .lg\:hover\:dq-opacity-80:hover {
    opacity: 0.8;
  }

  .lg\:hover\:dq-opacity-90:hover {
    opacity: 0.9;
  }

  .lg\:hover\:dq-opacity-95:hover {
    opacity: 0.95;
  }

  .lg\:hover\:dq-opacity-100:hover {
    opacity: 1;
  }

  .lg\:focus\:dq-opacity-0:focus {
    opacity: 0;
  }

  .lg\:focus\:dq-opacity-5:focus {
    opacity: 0.05;
  }

  .lg\:focus\:dq-opacity-10:focus {
    opacity: 0.1;
  }

  .lg\:focus\:dq-opacity-20:focus {
    opacity: 0.2;
  }

  .lg\:focus\:dq-opacity-25:focus {
    opacity: 0.25;
  }

  .lg\:focus\:dq-opacity-30:focus {
    opacity: 0.3;
  }

  .lg\:focus\:dq-opacity-40:focus {
    opacity: 0.4;
  }

  .lg\:focus\:dq-opacity-50:focus {
    opacity: 0.5;
  }

  .lg\:focus\:dq-opacity-60:focus {
    opacity: 0.6;
  }

  .lg\:focus\:dq-opacity-70:focus {
    opacity: 0.7;
  }

  .lg\:focus\:dq-opacity-75:focus {
    opacity: 0.75;
  }

  .lg\:focus\:dq-opacity-80:focus {
    opacity: 0.8;
  }

  .lg\:focus\:dq-opacity-90:focus {
    opacity: 0.9;
  }

  .lg\:focus\:dq-opacity-95:focus {
    opacity: 0.95;
  }

  .lg\:focus\:dq-opacity-100:focus {
    opacity: 1;
  }

  .lg\:dq-bg-blend-normal {
    background-blend-mode: normal;
  }

  .lg\:dq-bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .lg\:dq-bg-blend-screen {
    background-blend-mode: screen;
  }

  .lg\:dq-bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .lg\:dq-bg-blend-darken {
    background-blend-mode: darken;
  }

  .lg\:dq-bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .lg\:dq-bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .lg\:dq-bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .lg\:dq-bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .lg\:dq-bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .lg\:dq-bg-blend-difference {
    background-blend-mode: difference;
  }

  .lg\:dq-bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .lg\:dq-bg-blend-hue {
    background-blend-mode: hue;
  }

  .lg\:dq-bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .lg\:dq-bg-blend-color {
    background-blend-mode: color;
  }

  .lg\:dq-bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .lg\:dq-mix-blend-normal {
    mix-blend-mode: normal;
  }

  .lg\:dq-mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .lg\:dq-mix-blend-screen {
    mix-blend-mode: screen;
  }

  .lg\:dq-mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .lg\:dq-mix-blend-darken {
    mix-blend-mode: darken;
  }

  .lg\:dq-mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .lg\:dq-mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .lg\:dq-mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .lg\:dq-mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .lg\:dq-mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .lg\:dq-mix-blend-difference {
    mix-blend-mode: difference;
  }

  .lg\:dq-mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .lg\:dq-mix-blend-hue {
    mix-blend-mode: hue;
  }

  .lg\:dq-mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .lg\:dq-mix-blend-color {
    mix-blend-mode: color;
  }

  .lg\:dq-mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .lg\:dq-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:dq-shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:dq-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:dq-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:dq-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:dq-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:dq-shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:dq-shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .lg\:group-hover\:dq-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .lg\:group-hover\:dq-shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .lg\:group-hover\:dq-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .lg\:group-hover\:dq-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .lg\:group-hover\:dq-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .lg\:group-hover\:dq-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .lg\:group-hover\:dq-shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .lg\:group-hover\:dq-shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:dq-shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:dq-shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:dq-shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:dq-shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:dq-shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:dq-shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:dq-shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:dq-shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:dq-shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:dq-shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:dq-shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:dq-shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:dq-shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:dq-shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:dq-shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:dq-shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:dq-shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:dq-shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:dq-shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:dq-shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:dq-shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:dq-shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:dq-shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:dq-shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:dq-outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .lg\:dq-outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .lg\:dq-outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .lg\:focus-within\:dq-outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .lg\:focus-within\:dq-outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .lg\:focus-within\:dq-outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .lg\:focus\:dq-outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .lg\:focus\:dq-outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .lg\:focus\:dq-outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .lg\:dq-ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:dq-ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:dq-ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:dq-ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:dq-ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:dq-ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:dq-ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:dq-ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:dq-ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:dq-ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:dq-ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:dq-ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:dq-ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:dq-ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:dq-ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:dq-ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:dq-ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:dq-ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:dq-ring-inset {
    --tw-ring-inset: inset;
  }

  .lg\:focus-within\:dq-ring-inset:focus-within {
    --tw-ring-inset: inset;
  }

  .lg\:focus\:dq-ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .lg\:dq-ring-transparent {
    --tw-ring-color: transparent;
  }

  .lg\:dq-ring-current {
    --tw-ring-color: currentColor;
  }

  .lg\:dq-ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .lg\:focus-within\:dq-ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .lg\:focus-within\:dq-ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:dq-ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .lg\:focus\:dq-ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .lg\:focus\:dq-ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .lg\:focus\:dq-ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .lg\:dq-ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .lg\:dq-ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }

  .lg\:dq-ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }

  .lg\:dq-ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }

  .lg\:dq-ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }

  .lg\:dq-ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }

  .lg\:dq-ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }

  .lg\:dq-ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }

  .lg\:dq-ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }

  .lg\:dq-ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }

  .lg\:dq-ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }

  .lg\:dq-ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }

  .lg\:dq-ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }

  .lg\:dq-ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }

  .lg\:dq-ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .lg\:focus-within\:dq-ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .lg\:focus-within\:dq-ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }

  .lg\:focus-within\:dq-ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }

  .lg\:focus-within\:dq-ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }

  .lg\:focus-within\:dq-ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }

  .lg\:focus-within\:dq-ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }

  .lg\:focus-within\:dq-ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }

  .lg\:focus-within\:dq-ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }

  .lg\:focus-within\:dq-ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }

  .lg\:focus-within\:dq-ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }

  .lg\:focus-within\:dq-ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }

  .lg\:focus-within\:dq-ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }

  .lg\:focus-within\:dq-ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }

  .lg\:focus-within\:dq-ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }

  .lg\:focus-within\:dq-ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .lg\:focus\:dq-ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .lg\:focus\:dq-ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }

  .lg\:focus\:dq-ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }

  .lg\:focus\:dq-ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }

  .lg\:focus\:dq-ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }

  .lg\:focus\:dq-ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }

  .lg\:focus\:dq-ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }

  .lg\:focus\:dq-ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }

  .lg\:focus\:dq-ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }

  .lg\:focus\:dq-ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }

  .lg\:focus\:dq-ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }

  .lg\:focus\:dq-ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }

  .lg\:focus\:dq-ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }

  .lg\:focus\:dq-ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }

  .lg\:focus\:dq-ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .lg\:dq-ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .lg\:dq-ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .lg\:dq-ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .lg\:dq-ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .lg\:dq-ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .lg\:focus-within\:dq-ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .lg\:focus-within\:dq-ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .lg\:focus-within\:dq-ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .lg\:focus-within\:dq-ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .lg\:focus-within\:dq-ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .lg\:focus\:dq-ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .lg\:focus\:dq-ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .lg\:focus\:dq-ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .lg\:focus\:dq-ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .lg\:focus\:dq-ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .lg\:dq-ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .lg\:dq-ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .lg\:dq-ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .lg\:dq-ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .lg\:dq-ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .lg\:dq-ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .lg\:dq-ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .lg\:dq-ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .lg\:dq-ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .lg\:dq-ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .lg\:dq-ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .lg\:dq-ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .lg\:dq-ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .lg\:dq-ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .lg\:dq-ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }

  .lg\:dq-ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }

  .lg\:dq-ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }

  .lg\:dq-ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }

  .lg\:dq-ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }

  .lg\:dq-ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }

  .lg\:dq-ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }

  .lg\:dq-ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }

  .lg\:dq-ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }

  .lg\:dq-ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }

  .lg\:dq-ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .lg\:dq-ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .lg\:dq-ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .lg\:dq-ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .lg\:dq-ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .lg\:dq-ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .lg\:dq-ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .lg\:dq-ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .lg\:dq-ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .lg\:dq-ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .lg\:dq-ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .lg\:dq-ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .lg\:dq-ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .lg\:dq-ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .lg\:dq-ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .lg\:dq-ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .lg\:dq-ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .lg\:dq-ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .lg\:dq-ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .lg\:dq-ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .lg\:dq-ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .lg\:dq-ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .lg\:dq-ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .lg\:dq-ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .lg\:dq-ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .lg\:dq-ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .lg\:dq-ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .lg\:dq-ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .lg\:dq-ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .lg\:dq-ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .lg\:dq-ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .lg\:dq-ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .lg\:dq-ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .lg\:dq-ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .lg\:dq-ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .lg\:dq-ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .lg\:dq-ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .lg\:dq-ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .lg\:dq-ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .lg\:dq-ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .lg\:dq-ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .lg\:dq-ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .lg\:dq-ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .lg\:dq-ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .lg\:dq-ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .lg\:dq-ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .lg\:dq-ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .lg\:dq-ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .lg\:dq-ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .lg\:dq-ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .lg\:dq-ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .lg\:dq-ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .lg\:dq-ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .lg\:dq-ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .lg\:dq-ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .lg\:dq-ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .lg\:dq-ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .lg\:dq-ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .lg\:dq-ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .lg\:dq-ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .lg\:focus-within\:dq-ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .lg\:focus-within\:dq-ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .lg\:focus-within\:dq-ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .lg\:focus-within\:dq-ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .lg\:focus-within\:dq-ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .lg\:focus-within\:dq-ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .lg\:focus-within\:dq-ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .lg\:focus-within\:dq-ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .lg\:focus-within\:dq-ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .lg\:focus-within\:dq-ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .lg\:focus-within\:dq-ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .lg\:focus-within\:dq-ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .lg\:focus-within\:dq-ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .lg\:focus-within\:dq-ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .lg\:focus-within\:dq-ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }

  .lg\:focus-within\:dq-ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }

  .lg\:focus-within\:dq-ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }

  .lg\:focus-within\:dq-ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }

  .lg\:focus-within\:dq-ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }

  .lg\:focus-within\:dq-ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }

  .lg\:focus-within\:dq-ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }

  .lg\:focus-within\:dq-ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }

  .lg\:focus-within\:dq-ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }

  .lg\:focus-within\:dq-ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }

  .lg\:focus-within\:dq-ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .lg\:focus-within\:dq-ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .lg\:focus-within\:dq-ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .lg\:focus-within\:dq-ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .lg\:focus-within\:dq-ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .lg\:focus-within\:dq-ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .lg\:focus-within\:dq-ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .lg\:focus-within\:dq-ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .lg\:focus-within\:dq-ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .lg\:focus-within\:dq-ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .lg\:focus-within\:dq-ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .lg\:focus-within\:dq-ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .lg\:focus-within\:dq-ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .lg\:focus-within\:dq-ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .lg\:focus-within\:dq-ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .lg\:focus-within\:dq-ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .lg\:focus-within\:dq-ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .lg\:focus-within\:dq-ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .lg\:focus-within\:dq-ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .lg\:focus-within\:dq-ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .lg\:focus-within\:dq-ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .lg\:focus-within\:dq-ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .lg\:focus-within\:dq-ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .lg\:focus-within\:dq-ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .lg\:focus-within\:dq-ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .lg\:focus-within\:dq-ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .lg\:focus-within\:dq-ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .lg\:focus-within\:dq-ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .lg\:focus-within\:dq-ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .lg\:focus-within\:dq-ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .lg\:focus-within\:dq-ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .lg\:focus-within\:dq-ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .lg\:focus-within\:dq-ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .lg\:focus-within\:dq-ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .lg\:focus-within\:dq-ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .lg\:focus-within\:dq-ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .lg\:focus-within\:dq-ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .lg\:focus-within\:dq-ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .lg\:focus-within\:dq-ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .lg\:focus-within\:dq-ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .lg\:focus-within\:dq-ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .lg\:focus-within\:dq-ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .lg\:focus-within\:dq-ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .lg\:focus-within\:dq-ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .lg\:focus-within\:dq-ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .lg\:focus-within\:dq-ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .lg\:focus-within\:dq-ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .lg\:focus-within\:dq-ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .lg\:focus-within\:dq-ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .lg\:focus-within\:dq-ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .lg\:focus-within\:dq-ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .lg\:focus-within\:dq-ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .lg\:focus-within\:dq-ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .lg\:focus-within\:dq-ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .lg\:focus-within\:dq-ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .lg\:focus-within\:dq-ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .lg\:focus-within\:dq-ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .lg\:focus-within\:dq-ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .lg\:focus-within\:dq-ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .lg\:focus-within\:dq-ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .lg\:focus\:dq-ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .lg\:focus\:dq-ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .lg\:focus\:dq-ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .lg\:focus\:dq-ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .lg\:focus\:dq-ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .lg\:focus\:dq-ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .lg\:focus\:dq-ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .lg\:focus\:dq-ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .lg\:focus\:dq-ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .lg\:focus\:dq-ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .lg\:focus\:dq-ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .lg\:focus\:dq-ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .lg\:focus\:dq-ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .lg\:focus\:dq-ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .lg\:focus\:dq-ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }

  .lg\:focus\:dq-ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }

  .lg\:focus\:dq-ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }

  .lg\:focus\:dq-ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }

  .lg\:focus\:dq-ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }

  .lg\:focus\:dq-ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }

  .lg\:focus\:dq-ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }

  .lg\:focus\:dq-ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }

  .lg\:focus\:dq-ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }

  .lg\:focus\:dq-ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }

  .lg\:focus\:dq-ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .lg\:focus\:dq-ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .lg\:focus\:dq-ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .lg\:focus\:dq-ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .lg\:focus\:dq-ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .lg\:focus\:dq-ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .lg\:focus\:dq-ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .lg\:focus\:dq-ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .lg\:focus\:dq-ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .lg\:focus\:dq-ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .lg\:focus\:dq-ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .lg\:focus\:dq-ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .lg\:focus\:dq-ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .lg\:focus\:dq-ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .lg\:focus\:dq-ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .lg\:focus\:dq-ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .lg\:focus\:dq-ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .lg\:focus\:dq-ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .lg\:focus\:dq-ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .lg\:focus\:dq-ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .lg\:focus\:dq-ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .lg\:focus\:dq-ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .lg\:focus\:dq-ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .lg\:focus\:dq-ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .lg\:focus\:dq-ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .lg\:focus\:dq-ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .lg\:focus\:dq-ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .lg\:focus\:dq-ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .lg\:focus\:dq-ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .lg\:focus\:dq-ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .lg\:focus\:dq-ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .lg\:focus\:dq-ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .lg\:focus\:dq-ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .lg\:focus\:dq-ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .lg\:focus\:dq-ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .lg\:focus\:dq-ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .lg\:focus\:dq-ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .lg\:focus\:dq-ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .lg\:focus\:dq-ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .lg\:focus\:dq-ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .lg\:focus\:dq-ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .lg\:focus\:dq-ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .lg\:focus\:dq-ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .lg\:focus\:dq-ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .lg\:focus\:dq-ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .lg\:focus\:dq-ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .lg\:focus\:dq-ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .lg\:focus\:dq-ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .lg\:focus\:dq-ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .lg\:focus\:dq-ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .lg\:focus\:dq-ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .lg\:focus\:dq-ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .lg\:focus\:dq-ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .lg\:focus\:dq-ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .lg\:focus\:dq-ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .lg\:focus\:dq-ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .lg\:focus\:dq-ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .lg\:focus\:dq-ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .lg\:focus\:dq-ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .lg\:focus\:dq-ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .lg\:dq-filter {
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:dq-filter-none {
    -webkit-filter: none;
            filter: none;
  }

  .lg\:dq-blur-0 {
    --tw-blur: blur(0);
  }

  .lg\:dq-blur-none {
    --tw-blur: blur(0);
  }

  .lg\:dq-blur-sm {
    --tw-blur: blur(4px);
  }

  .lg\:dq-blur {
    --tw-blur: blur(8px);
  }

  .lg\:dq-blur-md {
    --tw-blur: blur(12px);
  }

  .lg\:dq-blur-lg {
    --tw-blur: blur(16px);
  }

  .lg\:dq-blur-xl {
    --tw-blur: blur(24px);
  }

  .lg\:dq-blur-2xl {
    --tw-blur: blur(40px);
  }

  .lg\:dq-blur-3xl {
    --tw-blur: blur(64px);
  }

  .lg\:dq-brightness-0 {
    --tw-brightness: brightness(0);
  }

  .lg\:dq-brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .lg\:dq-brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .lg\:dq-brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .lg\:dq-brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .lg\:dq-brightness-100 {
    --tw-brightness: brightness(1);
  }

  .lg\:dq-brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .lg\:dq-brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .lg\:dq-brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .lg\:dq-brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .lg\:dq-brightness-200 {
    --tw-brightness: brightness(2);
  }

  .lg\:dq-contrast-0 {
    --tw-contrast: contrast(0);
  }

  .lg\:dq-contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .lg\:dq-contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .lg\:dq-contrast-100 {
    --tw-contrast: contrast(1);
  }

  .lg\:dq-contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .lg\:dq-contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .lg\:dq-contrast-200 {
    --tw-contrast: contrast(2);
  }

  .lg\:dq-drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .lg\:dq-drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .lg\:dq-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .lg\:dq-drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .lg\:dq-drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .lg\:dq-drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .lg\:dq-drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .lg\:dq-grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .lg\:dq-grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .lg\:dq-hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .lg\:dq-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .lg\:dq-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .lg\:dq-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .lg\:dq-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .lg\:dq-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .lg\:dq--hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .lg\:dq--hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .lg\:dq--hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .lg\:dq--hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .lg\:dq--hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .lg\:dq-invert-0 {
    --tw-invert: invert(0);
  }

  .lg\:dq-invert {
    --tw-invert: invert(100%);
  }

  .lg\:dq-saturate-0 {
    --tw-saturate: saturate(0);
  }

  .lg\:dq-saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .lg\:dq-saturate-100 {
    --tw-saturate: saturate(1);
  }

  .lg\:dq-saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .lg\:dq-saturate-200 {
    --tw-saturate: saturate(2);
  }

  .lg\:dq-sepia-0 {
    --tw-sepia: sepia(0);
  }

  .lg\:dq-sepia {
    --tw-sepia: sepia(100%);
  }

  .lg\:dq-backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:dq-backdrop-filter-none {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }

  .lg\:dq-backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }

  .lg\:dq-backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .lg\:dq-backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .lg\:dq-backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .lg\:dq-backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .lg\:dq-backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .lg\:dq-backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .lg\:dq-backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .lg\:dq-backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .lg\:dq-backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .lg\:dq-backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .lg\:dq-backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .lg\:dq-backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .lg\:dq-backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .lg\:dq-backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .lg\:dq-backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .lg\:dq-backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .lg\:dq-backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .lg\:dq-backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .lg\:dq-backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .lg\:dq-backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .lg\:dq-backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .lg\:dq-backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .lg\:dq-backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .lg\:dq-backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .lg\:dq-backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .lg\:dq-backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .lg\:dq-backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .lg\:dq-backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .lg\:dq-backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .lg\:dq-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .lg\:dq-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .lg\:dq-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .lg\:dq-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .lg\:dq-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .lg\:dq--backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .lg\:dq--backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .lg\:dq--backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .lg\:dq--backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .lg\:dq--backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .lg\:dq-backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .lg\:dq-backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .lg\:dq-backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .lg\:dq-backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }

  .lg\:dq-backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }

  .lg\:dq-backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }

  .lg\:dq-backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }

  .lg\:dq-backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }

  .lg\:dq-backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }

  .lg\:dq-backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }

  .lg\:dq-backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }

  .lg\:dq-backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }

  .lg\:dq-backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }

  .lg\:dq-backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }

  .lg\:dq-backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }

  .lg\:dq-backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }

  .lg\:dq-backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .lg\:dq-backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .lg\:dq-backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .lg\:dq-backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .lg\:dq-backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .lg\:dq-backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .lg\:dq-backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .lg\:dq-backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .lg\:dq-transition-none {
    transition-property: none;
  }

  .lg\:dq-transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .lg\:dq-transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .lg\:dq-transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .lg\:dq-transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .lg\:dq-transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .lg\:dq-transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .lg\:dq-delay-75 {
    transition-delay: 75ms;
  }

  .lg\:dq-delay-100 {
    transition-delay: 100ms;
  }

  .lg\:dq-delay-150 {
    transition-delay: 150ms;
  }

  .lg\:dq-delay-200 {
    transition-delay: 200ms;
  }

  .lg\:dq-delay-300 {
    transition-delay: 300ms;
  }

  .lg\:dq-delay-500 {
    transition-delay: 500ms;
  }

  .lg\:dq-delay-700 {
    transition-delay: 700ms;
  }

  .lg\:dq-delay-1000 {
    transition-delay: 1000ms;
  }

  .lg\:dq-duration-75 {
    transition-duration: 75ms;
  }

  .lg\:dq-duration-100 {
    transition-duration: 100ms;
  }

  .lg\:dq-duration-150 {
    transition-duration: 150ms;
  }

  .lg\:dq-duration-200 {
    transition-duration: 200ms;
  }

  .lg\:dq-duration-300 {
    transition-duration: 300ms;
  }

  .lg\:dq-duration-500 {
    transition-duration: 500ms;
  }

  .lg\:dq-duration-700 {
    transition-duration: 700ms;
  }

  .lg\:dq-duration-1000 {
    transition-duration: 1000ms;
  }

  .lg\:dq-ease-linear {
    transition-timing-function: linear;
  }

  .lg\:dq-ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .lg\:dq-ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .lg\:dq-ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 1280px) {
  .xl\:dq-container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .xl\:dq-container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .xl\:dq-container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .xl\:dq-container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .xl\:dq-container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .xl\:dq-container {
      max-width: 1536px;
    }
  }

  .xl\:dq-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .xl\:dq-not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .xl\:focus-within\:dq-sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .xl\:focus-within\:dq-not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .xl\:focus\:dq-sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .xl\:focus\:dq-not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .xl\:dq-pointer-events-none {
    pointer-events: none;
  }

  .xl\:dq-pointer-events-auto {
    pointer-events: auto;
  }

  .xl\:dq-visible {
    visibility: visible;
  }

  .xl\:dq-invisible {
    visibility: hidden;
  }

  .xl\:dq-static {
    position: static;
  }

  .xl\:dq-fixed {
    position: fixed;
  }

  .xl\:dq-absolute {
    position: absolute;
  }

  .xl\:dq-relative {
    position: relative;
  }

  .xl\:dq-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .xl\:dq-inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .xl\:dq-inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }

  .xl\:dq-inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }

  .xl\:dq-inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }

  .xl\:dq-inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }

  .xl\:dq-inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }

  .xl\:dq-inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }

  .xl\:dq-inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }

  .xl\:dq-inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }

  .xl\:dq-inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }

  .xl\:dq-inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }

  .xl\:dq-inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }

  .xl\:dq-inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }

  .xl\:dq-inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }

  .xl\:dq-inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }

  .xl\:dq-inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }

  .xl\:dq-inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }

  .xl\:dq-inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }

  .xl\:dq-inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }

  .xl\:dq-inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }

  .xl\:dq-inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }

  .xl\:dq-inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }

  .xl\:dq-inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }

  .xl\:dq-inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }

  .xl\:dq-inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }

  .xl\:dq-inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }

  .xl\:dq-inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }

  .xl\:dq-inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }

  .xl\:dq-inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }

  .xl\:dq-inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }

  .xl\:dq-inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .xl\:dq-inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }

  .xl\:dq-inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }

  .xl\:dq-inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }

  .xl\:dq-inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }

  .xl\:dq-inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }

  .xl\:dq--inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .xl\:dq--inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }

  .xl\:dq--inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }

  .xl\:dq--inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }

  .xl\:dq--inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }

  .xl\:dq--inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }

  .xl\:dq--inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }

  .xl\:dq--inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }

  .xl\:dq--inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }

  .xl\:dq--inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }

  .xl\:dq--inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }

  .xl\:dq--inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }

  .xl\:dq--inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }

  .xl\:dq--inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }

  .xl\:dq--inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }

  .xl\:dq--inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }

  .xl\:dq--inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }

  .xl\:dq--inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }

  .xl\:dq--inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }

  .xl\:dq--inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }

  .xl\:dq--inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }

  .xl\:dq--inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }

  .xl\:dq--inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }

  .xl\:dq--inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }

  .xl\:dq--inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }

  .xl\:dq--inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }

  .xl\:dq--inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }

  .xl\:dq--inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }

  .xl\:dq--inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }

  .xl\:dq--inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }

  .xl\:dq--inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }

  .xl\:dq--inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }

  .xl\:dq--inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }

  .xl\:dq--inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }

  .xl\:dq--inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }

  .xl\:dq-inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }

  .xl\:dq-inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }

  .xl\:dq-inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }

  .xl\:dq-inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }

  .xl\:dq-inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }

  .xl\:dq-inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }

  .xl\:dq-inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }

  .xl\:dq--inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }

  .xl\:dq--inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }

  .xl\:dq--inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }

  .xl\:dq--inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }

  .xl\:dq--inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }

  .xl\:dq--inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }

  .xl\:dq--inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }

  .xl\:dq-inset-x-0 {
    left: 0px;
    right: 0px;
  }

  .xl\:dq-inset-x-1 {
    left: 0.25rem;
    right: 0.25rem;
  }

  .xl\:dq-inset-x-2 {
    left: 0.5rem;
    right: 0.5rem;
  }

  .xl\:dq-inset-x-3 {
    left: 0.75rem;
    right: 0.75rem;
  }

  .xl\:dq-inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .xl\:dq-inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .xl\:dq-inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .xl\:dq-inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .xl\:dq-inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .xl\:dq-inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .xl\:dq-inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .xl\:dq-inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .xl\:dq-inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .xl\:dq-inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .xl\:dq-inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .xl\:dq-inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .xl\:dq-inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .xl\:dq-inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .xl\:dq-inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .xl\:dq-inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .xl\:dq-inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .xl\:dq-inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .xl\:dq-inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .xl\:dq-inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .xl\:dq-inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .xl\:dq-inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .xl\:dq-inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .xl\:dq-inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .xl\:dq-inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .xl\:dq-inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .xl\:dq-inset-x-auto {
    left: auto;
    right: auto;
  }

  .xl\:dq-inset-x-px {
    left: 1px;
    right: 1px;
  }

  .xl\:dq-inset-x-0\.5 {
    left: 0.125rem;
    right: 0.125rem;
  }

  .xl\:dq-inset-x-1\.5 {
    left: 0.375rem;
    right: 0.375rem;
  }

  .xl\:dq-inset-x-2\.5 {
    left: 0.625rem;
    right: 0.625rem;
  }

  .xl\:dq-inset-x-3\.5 {
    left: 0.875rem;
    right: 0.875rem;
  }

  .xl\:dq--inset-x-0 {
    left: 0px;
    right: 0px;
  }

  .xl\:dq--inset-x-1 {
    left: -0.25rem;
    right: -0.25rem;
  }

  .xl\:dq--inset-x-2 {
    left: -0.5rem;
    right: -0.5rem;
  }

  .xl\:dq--inset-x-3 {
    left: -0.75rem;
    right: -0.75rem;
  }

  .xl\:dq--inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .xl\:dq--inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .xl\:dq--inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .xl\:dq--inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .xl\:dq--inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .xl\:dq--inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .xl\:dq--inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .xl\:dq--inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .xl\:dq--inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .xl\:dq--inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .xl\:dq--inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .xl\:dq--inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .xl\:dq--inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .xl\:dq--inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .xl\:dq--inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .xl\:dq--inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .xl\:dq--inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .xl\:dq--inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .xl\:dq--inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .xl\:dq--inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .xl\:dq--inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .xl\:dq--inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .xl\:dq--inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .xl\:dq--inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .xl\:dq--inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .xl\:dq--inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .xl\:dq--inset-x-px {
    left: -1px;
    right: -1px;
  }

  .xl\:dq--inset-x-0\.5 {
    left: -0.125rem;
    right: -0.125rem;
  }

  .xl\:dq--inset-x-1\.5 {
    left: -0.375rem;
    right: -0.375rem;
  }

  .xl\:dq--inset-x-2\.5 {
    left: -0.625rem;
    right: -0.625rem;
  }

  .xl\:dq--inset-x-3\.5 {
    left: -0.875rem;
    right: -0.875rem;
  }

  .xl\:dq-inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .xl\:dq-inset-x-1\/3 {
    left: 33.333333%;
    right: 33.333333%;
  }

  .xl\:dq-inset-x-2\/3 {
    left: 66.666667%;
    right: 66.666667%;
  }

  .xl\:dq-inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .xl\:dq-inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .xl\:dq-inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .xl\:dq-inset-x-full {
    left: 100%;
    right: 100%;
  }

  .xl\:dq--inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .xl\:dq--inset-x-1\/3 {
    left: -33.333333%;
    right: -33.333333%;
  }

  .xl\:dq--inset-x-2\/3 {
    left: -66.666667%;
    right: -66.666667%;
  }

  .xl\:dq--inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .xl\:dq--inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .xl\:dq--inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .xl\:dq--inset-x-full {
    left: -100%;
    right: -100%;
  }

  .xl\:dq-inset-y-0 {
    top: 0px;
    bottom: 0px;
  }

  .xl\:dq-inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }

  .xl\:dq-inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }

  .xl\:dq-inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }

  .xl\:dq-inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .xl\:dq-inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .xl\:dq-inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .xl\:dq-inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .xl\:dq-inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .xl\:dq-inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .xl\:dq-inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .xl\:dq-inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .xl\:dq-inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .xl\:dq-inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .xl\:dq-inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .xl\:dq-inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .xl\:dq-inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .xl\:dq-inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .xl\:dq-inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .xl\:dq-inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .xl\:dq-inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .xl\:dq-inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .xl\:dq-inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .xl\:dq-inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .xl\:dq-inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .xl\:dq-inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .xl\:dq-inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .xl\:dq-inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .xl\:dq-inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .xl\:dq-inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .xl\:dq-inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .xl\:dq-inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .xl\:dq-inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }

  .xl\:dq-inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }

  .xl\:dq-inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }

  .xl\:dq-inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }

  .xl\:dq--inset-y-0 {
    top: 0px;
    bottom: 0px;
  }

  .xl\:dq--inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }

  .xl\:dq--inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }

  .xl\:dq--inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }

  .xl\:dq--inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .xl\:dq--inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .xl\:dq--inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .xl\:dq--inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .xl\:dq--inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .xl\:dq--inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .xl\:dq--inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .xl\:dq--inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .xl\:dq--inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .xl\:dq--inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .xl\:dq--inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .xl\:dq--inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .xl\:dq--inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .xl\:dq--inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .xl\:dq--inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .xl\:dq--inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .xl\:dq--inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .xl\:dq--inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .xl\:dq--inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .xl\:dq--inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .xl\:dq--inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .xl\:dq--inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .xl\:dq--inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .xl\:dq--inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .xl\:dq--inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .xl\:dq--inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .xl\:dq--inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .xl\:dq--inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }

  .xl\:dq--inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }

  .xl\:dq--inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }

  .xl\:dq--inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }

  .xl\:dq-inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .xl\:dq-inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }

  .xl\:dq-inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }

  .xl\:dq-inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .xl\:dq-inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .xl\:dq-inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .xl\:dq-inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .xl\:dq--inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .xl\:dq--inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }

  .xl\:dq--inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }

  .xl\:dq--inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .xl\:dq--inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .xl\:dq--inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .xl\:dq--inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .xl\:dq-top-0 {
    top: 0px;
  }

  .xl\:dq-top-1 {
    top: 0.25rem;
  }

  .xl\:dq-top-2 {
    top: 0.5rem;
  }

  .xl\:dq-top-3 {
    top: 0.75rem;
  }

  .xl\:dq-top-4 {
    top: 1rem;
  }

  .xl\:dq-top-5 {
    top: 1.25rem;
  }

  .xl\:dq-top-6 {
    top: 1.5rem;
  }

  .xl\:dq-top-7 {
    top: 1.75rem;
  }

  .xl\:dq-top-8 {
    top: 2rem;
  }

  .xl\:dq-top-9 {
    top: 2.25rem;
  }

  .xl\:dq-top-10 {
    top: 2.5rem;
  }

  .xl\:dq-top-11 {
    top: 2.75rem;
  }

  .xl\:dq-top-12 {
    top: 3rem;
  }

  .xl\:dq-top-14 {
    top: 3.5rem;
  }

  .xl\:dq-top-16 {
    top: 4rem;
  }

  .xl\:dq-top-20 {
    top: 5rem;
  }

  .xl\:dq-top-24 {
    top: 6rem;
  }

  .xl\:dq-top-28 {
    top: 7rem;
  }

  .xl\:dq-top-32 {
    top: 8rem;
  }

  .xl\:dq-top-36 {
    top: 9rem;
  }

  .xl\:dq-top-40 {
    top: 10rem;
  }

  .xl\:dq-top-44 {
    top: 11rem;
  }

  .xl\:dq-top-48 {
    top: 12rem;
  }

  .xl\:dq-top-52 {
    top: 13rem;
  }

  .xl\:dq-top-56 {
    top: 14rem;
  }

  .xl\:dq-top-60 {
    top: 15rem;
  }

  .xl\:dq-top-64 {
    top: 16rem;
  }

  .xl\:dq-top-72 {
    top: 18rem;
  }

  .xl\:dq-top-80 {
    top: 20rem;
  }

  .xl\:dq-top-96 {
    top: 24rem;
  }

  .xl\:dq-top-auto {
    top: auto;
  }

  .xl\:dq-top-px {
    top: 1px;
  }

  .xl\:dq-top-0\.5 {
    top: 0.125rem;
  }

  .xl\:dq-top-1\.5 {
    top: 0.375rem;
  }

  .xl\:dq-top-2\.5 {
    top: 0.625rem;
  }

  .xl\:dq-top-3\.5 {
    top: 0.875rem;
  }

  .xl\:dq--top-0 {
    top: 0px;
  }

  .xl\:dq--top-1 {
    top: -0.25rem;
  }

  .xl\:dq--top-2 {
    top: -0.5rem;
  }

  .xl\:dq--top-3 {
    top: -0.75rem;
  }

  .xl\:dq--top-4 {
    top: -1rem;
  }

  .xl\:dq--top-5 {
    top: -1.25rem;
  }

  .xl\:dq--top-6 {
    top: -1.5rem;
  }

  .xl\:dq--top-7 {
    top: -1.75rem;
  }

  .xl\:dq--top-8 {
    top: -2rem;
  }

  .xl\:dq--top-9 {
    top: -2.25rem;
  }

  .xl\:dq--top-10 {
    top: -2.5rem;
  }

  .xl\:dq--top-11 {
    top: -2.75rem;
  }

  .xl\:dq--top-12 {
    top: -3rem;
  }

  .xl\:dq--top-14 {
    top: -3.5rem;
  }

  .xl\:dq--top-16 {
    top: -4rem;
  }

  .xl\:dq--top-20 {
    top: -5rem;
  }

  .xl\:dq--top-24 {
    top: -6rem;
  }

  .xl\:dq--top-28 {
    top: -7rem;
  }

  .xl\:dq--top-32 {
    top: -8rem;
  }

  .xl\:dq--top-36 {
    top: -9rem;
  }

  .xl\:dq--top-40 {
    top: -10rem;
  }

  .xl\:dq--top-44 {
    top: -11rem;
  }

  .xl\:dq--top-48 {
    top: -12rem;
  }

  .xl\:dq--top-52 {
    top: -13rem;
  }

  .xl\:dq--top-56 {
    top: -14rem;
  }

  .xl\:dq--top-60 {
    top: -15rem;
  }

  .xl\:dq--top-64 {
    top: -16rem;
  }

  .xl\:dq--top-72 {
    top: -18rem;
  }

  .xl\:dq--top-80 {
    top: -20rem;
  }

  .xl\:dq--top-96 {
    top: -24rem;
  }

  .xl\:dq--top-px {
    top: -1px;
  }

  .xl\:dq--top-0\.5 {
    top: -0.125rem;
  }

  .xl\:dq--top-1\.5 {
    top: -0.375rem;
  }

  .xl\:dq--top-2\.5 {
    top: -0.625rem;
  }

  .xl\:dq--top-3\.5 {
    top: -0.875rem;
  }

  .xl\:dq-top-1\/2 {
    top: 50%;
  }

  .xl\:dq-top-1\/3 {
    top: 33.333333%;
  }

  .xl\:dq-top-2\/3 {
    top: 66.666667%;
  }

  .xl\:dq-top-1\/4 {
    top: 25%;
  }

  .xl\:dq-top-2\/4 {
    top: 50%;
  }

  .xl\:dq-top-3\/4 {
    top: 75%;
  }

  .xl\:dq-top-full {
    top: 100%;
  }

  .xl\:dq--top-1\/2 {
    top: -50%;
  }

  .xl\:dq--top-1\/3 {
    top: -33.333333%;
  }

  .xl\:dq--top-2\/3 {
    top: -66.666667%;
  }

  .xl\:dq--top-1\/4 {
    top: -25%;
  }

  .xl\:dq--top-2\/4 {
    top: -50%;
  }

  .xl\:dq--top-3\/4 {
    top: -75%;
  }

  .xl\:dq--top-full {
    top: -100%;
  }

  .xl\:dq-right-0 {
    right: 0px;
  }

  .xl\:dq-right-1 {
    right: 0.25rem;
  }

  .xl\:dq-right-2 {
    right: 0.5rem;
  }

  .xl\:dq-right-3 {
    right: 0.75rem;
  }

  .xl\:dq-right-4 {
    right: 1rem;
  }

  .xl\:dq-right-5 {
    right: 1.25rem;
  }

  .xl\:dq-right-6 {
    right: 1.5rem;
  }

  .xl\:dq-right-7 {
    right: 1.75rem;
  }

  .xl\:dq-right-8 {
    right: 2rem;
  }

  .xl\:dq-right-9 {
    right: 2.25rem;
  }

  .xl\:dq-right-10 {
    right: 2.5rem;
  }

  .xl\:dq-right-11 {
    right: 2.75rem;
  }

  .xl\:dq-right-12 {
    right: 3rem;
  }

  .xl\:dq-right-14 {
    right: 3.5rem;
  }

  .xl\:dq-right-16 {
    right: 4rem;
  }

  .xl\:dq-right-20 {
    right: 5rem;
  }

  .xl\:dq-right-24 {
    right: 6rem;
  }

  .xl\:dq-right-28 {
    right: 7rem;
  }

  .xl\:dq-right-32 {
    right: 8rem;
  }

  .xl\:dq-right-36 {
    right: 9rem;
  }

  .xl\:dq-right-40 {
    right: 10rem;
  }

  .xl\:dq-right-44 {
    right: 11rem;
  }

  .xl\:dq-right-48 {
    right: 12rem;
  }

  .xl\:dq-right-52 {
    right: 13rem;
  }

  .xl\:dq-right-56 {
    right: 14rem;
  }

  .xl\:dq-right-60 {
    right: 15rem;
  }

  .xl\:dq-right-64 {
    right: 16rem;
  }

  .xl\:dq-right-72 {
    right: 18rem;
  }

  .xl\:dq-right-80 {
    right: 20rem;
  }

  .xl\:dq-right-96 {
    right: 24rem;
  }

  .xl\:dq-right-auto {
    right: auto;
  }

  .xl\:dq-right-px {
    right: 1px;
  }

  .xl\:dq-right-0\.5 {
    right: 0.125rem;
  }

  .xl\:dq-right-1\.5 {
    right: 0.375rem;
  }

  .xl\:dq-right-2\.5 {
    right: 0.625rem;
  }

  .xl\:dq-right-3\.5 {
    right: 0.875rem;
  }

  .xl\:dq--right-0 {
    right: 0px;
  }

  .xl\:dq--right-1 {
    right: -0.25rem;
  }

  .xl\:dq--right-2 {
    right: -0.5rem;
  }

  .xl\:dq--right-3 {
    right: -0.75rem;
  }

  .xl\:dq--right-4 {
    right: -1rem;
  }

  .xl\:dq--right-5 {
    right: -1.25rem;
  }

  .xl\:dq--right-6 {
    right: -1.5rem;
  }

  .xl\:dq--right-7 {
    right: -1.75rem;
  }

  .xl\:dq--right-8 {
    right: -2rem;
  }

  .xl\:dq--right-9 {
    right: -2.25rem;
  }

  .xl\:dq--right-10 {
    right: -2.5rem;
  }

  .xl\:dq--right-11 {
    right: -2.75rem;
  }

  .xl\:dq--right-12 {
    right: -3rem;
  }

  .xl\:dq--right-14 {
    right: -3.5rem;
  }

  .xl\:dq--right-16 {
    right: -4rem;
  }

  .xl\:dq--right-20 {
    right: -5rem;
  }

  .xl\:dq--right-24 {
    right: -6rem;
  }

  .xl\:dq--right-28 {
    right: -7rem;
  }

  .xl\:dq--right-32 {
    right: -8rem;
  }

  .xl\:dq--right-36 {
    right: -9rem;
  }

  .xl\:dq--right-40 {
    right: -10rem;
  }

  .xl\:dq--right-44 {
    right: -11rem;
  }

  .xl\:dq--right-48 {
    right: -12rem;
  }

  .xl\:dq--right-52 {
    right: -13rem;
  }

  .xl\:dq--right-56 {
    right: -14rem;
  }

  .xl\:dq--right-60 {
    right: -15rem;
  }

  .xl\:dq--right-64 {
    right: -16rem;
  }

  .xl\:dq--right-72 {
    right: -18rem;
  }

  .xl\:dq--right-80 {
    right: -20rem;
  }

  .xl\:dq--right-96 {
    right: -24rem;
  }

  .xl\:dq--right-px {
    right: -1px;
  }

  .xl\:dq--right-0\.5 {
    right: -0.125rem;
  }

  .xl\:dq--right-1\.5 {
    right: -0.375rem;
  }

  .xl\:dq--right-2\.5 {
    right: -0.625rem;
  }

  .xl\:dq--right-3\.5 {
    right: -0.875rem;
  }

  .xl\:dq-right-1\/2 {
    right: 50%;
  }

  .xl\:dq-right-1\/3 {
    right: 33.333333%;
  }

  .xl\:dq-right-2\/3 {
    right: 66.666667%;
  }

  .xl\:dq-right-1\/4 {
    right: 25%;
  }

  .xl\:dq-right-2\/4 {
    right: 50%;
  }

  .xl\:dq-right-3\/4 {
    right: 75%;
  }

  .xl\:dq-right-full {
    right: 100%;
  }

  .xl\:dq--right-1\/2 {
    right: -50%;
  }

  .xl\:dq--right-1\/3 {
    right: -33.333333%;
  }

  .xl\:dq--right-2\/3 {
    right: -66.666667%;
  }

  .xl\:dq--right-1\/4 {
    right: -25%;
  }

  .xl\:dq--right-2\/4 {
    right: -50%;
  }

  .xl\:dq--right-3\/4 {
    right: -75%;
  }

  .xl\:dq--right-full {
    right: -100%;
  }

  .xl\:dq-bottom-0 {
    bottom: 0px;
  }

  .xl\:dq-bottom-1 {
    bottom: 0.25rem;
  }

  .xl\:dq-bottom-2 {
    bottom: 0.5rem;
  }

  .xl\:dq-bottom-3 {
    bottom: 0.75rem;
  }

  .xl\:dq-bottom-4 {
    bottom: 1rem;
  }

  .xl\:dq-bottom-5 {
    bottom: 1.25rem;
  }

  .xl\:dq-bottom-6 {
    bottom: 1.5rem;
  }

  .xl\:dq-bottom-7 {
    bottom: 1.75rem;
  }

  .xl\:dq-bottom-8 {
    bottom: 2rem;
  }

  .xl\:dq-bottom-9 {
    bottom: 2.25rem;
  }

  .xl\:dq-bottom-10 {
    bottom: 2.5rem;
  }

  .xl\:dq-bottom-11 {
    bottom: 2.75rem;
  }

  .xl\:dq-bottom-12 {
    bottom: 3rem;
  }

  .xl\:dq-bottom-14 {
    bottom: 3.5rem;
  }

  .xl\:dq-bottom-16 {
    bottom: 4rem;
  }

  .xl\:dq-bottom-20 {
    bottom: 5rem;
  }

  .xl\:dq-bottom-24 {
    bottom: 6rem;
  }

  .xl\:dq-bottom-28 {
    bottom: 7rem;
  }

  .xl\:dq-bottom-32 {
    bottom: 8rem;
  }

  .xl\:dq-bottom-36 {
    bottom: 9rem;
  }

  .xl\:dq-bottom-40 {
    bottom: 10rem;
  }

  .xl\:dq-bottom-44 {
    bottom: 11rem;
  }

  .xl\:dq-bottom-48 {
    bottom: 12rem;
  }

  .xl\:dq-bottom-52 {
    bottom: 13rem;
  }

  .xl\:dq-bottom-56 {
    bottom: 14rem;
  }

  .xl\:dq-bottom-60 {
    bottom: 15rem;
  }

  .xl\:dq-bottom-64 {
    bottom: 16rem;
  }

  .xl\:dq-bottom-72 {
    bottom: 18rem;
  }

  .xl\:dq-bottom-80 {
    bottom: 20rem;
  }

  .xl\:dq-bottom-96 {
    bottom: 24rem;
  }

  .xl\:dq-bottom-auto {
    bottom: auto;
  }

  .xl\:dq-bottom-px {
    bottom: 1px;
  }

  .xl\:dq-bottom-0\.5 {
    bottom: 0.125rem;
  }

  .xl\:dq-bottom-1\.5 {
    bottom: 0.375rem;
  }

  .xl\:dq-bottom-2\.5 {
    bottom: 0.625rem;
  }

  .xl\:dq-bottom-3\.5 {
    bottom: 0.875rem;
  }

  .xl\:dq--bottom-0 {
    bottom: 0px;
  }

  .xl\:dq--bottom-1 {
    bottom: -0.25rem;
  }

  .xl\:dq--bottom-2 {
    bottom: -0.5rem;
  }

  .xl\:dq--bottom-3 {
    bottom: -0.75rem;
  }

  .xl\:dq--bottom-4 {
    bottom: -1rem;
  }

  .xl\:dq--bottom-5 {
    bottom: -1.25rem;
  }

  .xl\:dq--bottom-6 {
    bottom: -1.5rem;
  }

  .xl\:dq--bottom-7 {
    bottom: -1.75rem;
  }

  .xl\:dq--bottom-8 {
    bottom: -2rem;
  }

  .xl\:dq--bottom-9 {
    bottom: -2.25rem;
  }

  .xl\:dq--bottom-10 {
    bottom: -2.5rem;
  }

  .xl\:dq--bottom-11 {
    bottom: -2.75rem;
  }

  .xl\:dq--bottom-12 {
    bottom: -3rem;
  }

  .xl\:dq--bottom-14 {
    bottom: -3.5rem;
  }

  .xl\:dq--bottom-16 {
    bottom: -4rem;
  }

  .xl\:dq--bottom-20 {
    bottom: -5rem;
  }

  .xl\:dq--bottom-24 {
    bottom: -6rem;
  }

  .xl\:dq--bottom-28 {
    bottom: -7rem;
  }

  .xl\:dq--bottom-32 {
    bottom: -8rem;
  }

  .xl\:dq--bottom-36 {
    bottom: -9rem;
  }

  .xl\:dq--bottom-40 {
    bottom: -10rem;
  }

  .xl\:dq--bottom-44 {
    bottom: -11rem;
  }

  .xl\:dq--bottom-48 {
    bottom: -12rem;
  }

  .xl\:dq--bottom-52 {
    bottom: -13rem;
  }

  .xl\:dq--bottom-56 {
    bottom: -14rem;
  }

  .xl\:dq--bottom-60 {
    bottom: -15rem;
  }

  .xl\:dq--bottom-64 {
    bottom: -16rem;
  }

  .xl\:dq--bottom-72 {
    bottom: -18rem;
  }

  .xl\:dq--bottom-80 {
    bottom: -20rem;
  }

  .xl\:dq--bottom-96 {
    bottom: -24rem;
  }

  .xl\:dq--bottom-px {
    bottom: -1px;
  }

  .xl\:dq--bottom-0\.5 {
    bottom: -0.125rem;
  }

  .xl\:dq--bottom-1\.5 {
    bottom: -0.375rem;
  }

  .xl\:dq--bottom-2\.5 {
    bottom: -0.625rem;
  }

  .xl\:dq--bottom-3\.5 {
    bottom: -0.875rem;
  }

  .xl\:dq-bottom-1\/2 {
    bottom: 50%;
  }

  .xl\:dq-bottom-1\/3 {
    bottom: 33.333333%;
  }

  .xl\:dq-bottom-2\/3 {
    bottom: 66.666667%;
  }

  .xl\:dq-bottom-1\/4 {
    bottom: 25%;
  }

  .xl\:dq-bottom-2\/4 {
    bottom: 50%;
  }

  .xl\:dq-bottom-3\/4 {
    bottom: 75%;
  }

  .xl\:dq-bottom-full {
    bottom: 100%;
  }

  .xl\:dq--bottom-1\/2 {
    bottom: -50%;
  }

  .xl\:dq--bottom-1\/3 {
    bottom: -33.333333%;
  }

  .xl\:dq--bottom-2\/3 {
    bottom: -66.666667%;
  }

  .xl\:dq--bottom-1\/4 {
    bottom: -25%;
  }

  .xl\:dq--bottom-2\/4 {
    bottom: -50%;
  }

  .xl\:dq--bottom-3\/4 {
    bottom: -75%;
  }

  .xl\:dq--bottom-full {
    bottom: -100%;
  }

  .xl\:dq-left-0 {
    left: 0px;
  }

  .xl\:dq-left-1 {
    left: 0.25rem;
  }

  .xl\:dq-left-2 {
    left: 0.5rem;
  }

  .xl\:dq-left-3 {
    left: 0.75rem;
  }

  .xl\:dq-left-4 {
    left: 1rem;
  }

  .xl\:dq-left-5 {
    left: 1.25rem;
  }

  .xl\:dq-left-6 {
    left: 1.5rem;
  }

  .xl\:dq-left-7 {
    left: 1.75rem;
  }

  .xl\:dq-left-8 {
    left: 2rem;
  }

  .xl\:dq-left-9 {
    left: 2.25rem;
  }

  .xl\:dq-left-10 {
    left: 2.5rem;
  }

  .xl\:dq-left-11 {
    left: 2.75rem;
  }

  .xl\:dq-left-12 {
    left: 3rem;
  }

  .xl\:dq-left-14 {
    left: 3.5rem;
  }

  .xl\:dq-left-16 {
    left: 4rem;
  }

  .xl\:dq-left-20 {
    left: 5rem;
  }

  .xl\:dq-left-24 {
    left: 6rem;
  }

  .xl\:dq-left-28 {
    left: 7rem;
  }

  .xl\:dq-left-32 {
    left: 8rem;
  }

  .xl\:dq-left-36 {
    left: 9rem;
  }

  .xl\:dq-left-40 {
    left: 10rem;
  }

  .xl\:dq-left-44 {
    left: 11rem;
  }

  .xl\:dq-left-48 {
    left: 12rem;
  }

  .xl\:dq-left-52 {
    left: 13rem;
  }

  .xl\:dq-left-56 {
    left: 14rem;
  }

  .xl\:dq-left-60 {
    left: 15rem;
  }

  .xl\:dq-left-64 {
    left: 16rem;
  }

  .xl\:dq-left-72 {
    left: 18rem;
  }

  .xl\:dq-left-80 {
    left: 20rem;
  }

  .xl\:dq-left-96 {
    left: 24rem;
  }

  .xl\:dq-left-auto {
    left: auto;
  }

  .xl\:dq-left-px {
    left: 1px;
  }

  .xl\:dq-left-0\.5 {
    left: 0.125rem;
  }

  .xl\:dq-left-1\.5 {
    left: 0.375rem;
  }

  .xl\:dq-left-2\.5 {
    left: 0.625rem;
  }

  .xl\:dq-left-3\.5 {
    left: 0.875rem;
  }

  .xl\:dq--left-0 {
    left: 0px;
  }

  .xl\:dq--left-1 {
    left: -0.25rem;
  }

  .xl\:dq--left-2 {
    left: -0.5rem;
  }

  .xl\:dq--left-3 {
    left: -0.75rem;
  }

  .xl\:dq--left-4 {
    left: -1rem;
  }

  .xl\:dq--left-5 {
    left: -1.25rem;
  }

  .xl\:dq--left-6 {
    left: -1.5rem;
  }

  .xl\:dq--left-7 {
    left: -1.75rem;
  }

  .xl\:dq--left-8 {
    left: -2rem;
  }

  .xl\:dq--left-9 {
    left: -2.25rem;
  }

  .xl\:dq--left-10 {
    left: -2.5rem;
  }

  .xl\:dq--left-11 {
    left: -2.75rem;
  }

  .xl\:dq--left-12 {
    left: -3rem;
  }

  .xl\:dq--left-14 {
    left: -3.5rem;
  }

  .xl\:dq--left-16 {
    left: -4rem;
  }

  .xl\:dq--left-20 {
    left: -5rem;
  }

  .xl\:dq--left-24 {
    left: -6rem;
  }

  .xl\:dq--left-28 {
    left: -7rem;
  }

  .xl\:dq--left-32 {
    left: -8rem;
  }

  .xl\:dq--left-36 {
    left: -9rem;
  }

  .xl\:dq--left-40 {
    left: -10rem;
  }

  .xl\:dq--left-44 {
    left: -11rem;
  }

  .xl\:dq--left-48 {
    left: -12rem;
  }

  .xl\:dq--left-52 {
    left: -13rem;
  }

  .xl\:dq--left-56 {
    left: -14rem;
  }

  .xl\:dq--left-60 {
    left: -15rem;
  }

  .xl\:dq--left-64 {
    left: -16rem;
  }

  .xl\:dq--left-72 {
    left: -18rem;
  }

  .xl\:dq--left-80 {
    left: -20rem;
  }

  .xl\:dq--left-96 {
    left: -24rem;
  }

  .xl\:dq--left-px {
    left: -1px;
  }

  .xl\:dq--left-0\.5 {
    left: -0.125rem;
  }

  .xl\:dq--left-1\.5 {
    left: -0.375rem;
  }

  .xl\:dq--left-2\.5 {
    left: -0.625rem;
  }

  .xl\:dq--left-3\.5 {
    left: -0.875rem;
  }

  .xl\:dq-left-1\/2 {
    left: 50%;
  }

  .xl\:dq-left-1\/3 {
    left: 33.333333%;
  }

  .xl\:dq-left-2\/3 {
    left: 66.666667%;
  }

  .xl\:dq-left-1\/4 {
    left: 25%;
  }

  .xl\:dq-left-2\/4 {
    left: 50%;
  }

  .xl\:dq-left-3\/4 {
    left: 75%;
  }

  .xl\:dq-left-full {
    left: 100%;
  }

  .xl\:dq--left-1\/2 {
    left: -50%;
  }

  .xl\:dq--left-1\/3 {
    left: -33.333333%;
  }

  .xl\:dq--left-2\/3 {
    left: -66.666667%;
  }

  .xl\:dq--left-1\/4 {
    left: -25%;
  }

  .xl\:dq--left-2\/4 {
    left: -50%;
  }

  .xl\:dq--left-3\/4 {
    left: -75%;
  }

  .xl\:dq--left-full {
    left: -100%;
  }

  .xl\:dq-isolate {
    isolation: isolate;
  }

  .xl\:dq-isolation-auto {
    isolation: auto;
  }

  .xl\:dq-z-0 {
    z-index: 0;
  }

  .xl\:dq-z-10 {
    z-index: 10;
  }

  .xl\:dq-z-20 {
    z-index: 20;
  }

  .xl\:dq-z-30 {
    z-index: 30;
  }

  .xl\:dq-z-40 {
    z-index: 40;
  }

  .xl\:dq-z-50 {
    z-index: 50;
  }

  .xl\:dq-z-auto {
    z-index: auto;
  }

  .xl\:focus-within\:dq-z-0:focus-within {
    z-index: 0;
  }

  .xl\:focus-within\:dq-z-10:focus-within {
    z-index: 10;
  }

  .xl\:focus-within\:dq-z-20:focus-within {
    z-index: 20;
  }

  .xl\:focus-within\:dq-z-30:focus-within {
    z-index: 30;
  }

  .xl\:focus-within\:dq-z-40:focus-within {
    z-index: 40;
  }

  .xl\:focus-within\:dq-z-50:focus-within {
    z-index: 50;
  }

  .xl\:focus-within\:dq-z-auto:focus-within {
    z-index: auto;
  }

  .xl\:focus\:dq-z-0:focus {
    z-index: 0;
  }

  .xl\:focus\:dq-z-10:focus {
    z-index: 10;
  }

  .xl\:focus\:dq-z-20:focus {
    z-index: 20;
  }

  .xl\:focus\:dq-z-30:focus {
    z-index: 30;
  }

  .xl\:focus\:dq-z-40:focus {
    z-index: 40;
  }

  .xl\:focus\:dq-z-50:focus {
    z-index: 50;
  }

  .xl\:focus\:dq-z-auto:focus {
    z-index: auto;
  }

  .xl\:dq-order-1 {
    order: 1;
  }

  .xl\:dq-order-2 {
    order: 2;
  }

  .xl\:dq-order-3 {
    order: 3;
  }

  .xl\:dq-order-4 {
    order: 4;
  }

  .xl\:dq-order-5 {
    order: 5;
  }

  .xl\:dq-order-6 {
    order: 6;
  }

  .xl\:dq-order-7 {
    order: 7;
  }

  .xl\:dq-order-8 {
    order: 8;
  }

  .xl\:dq-order-9 {
    order: 9;
  }

  .xl\:dq-order-10 {
    order: 10;
  }

  .xl\:dq-order-11 {
    order: 11;
  }

  .xl\:dq-order-12 {
    order: 12;
  }

  .xl\:dq-order-first {
    order: -9999;
  }

  .xl\:dq-order-last {
    order: 9999;
  }

  .xl\:dq-order-none {
    order: 0;
  }

  .xl\:dq-col-auto {
    grid-column: auto;
  }

  .xl\:dq-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:dq-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:dq-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:dq-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:dq-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:dq-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:dq-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:dq-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:dq-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xl\:dq-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xl\:dq-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xl\:dq-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xl\:dq-col-span-full {
    grid-column: 1 / -1;
  }

  .xl\:dq-col-start-1 {
    grid-column-start: 1;
  }

  .xl\:dq-col-start-2 {
    grid-column-start: 2;
  }

  .xl\:dq-col-start-3 {
    grid-column-start: 3;
  }

  .xl\:dq-col-start-4 {
    grid-column-start: 4;
  }

  .xl\:dq-col-start-5 {
    grid-column-start: 5;
  }

  .xl\:dq-col-start-6 {
    grid-column-start: 6;
  }

  .xl\:dq-col-start-7 {
    grid-column-start: 7;
  }

  .xl\:dq-col-start-8 {
    grid-column-start: 8;
  }

  .xl\:dq-col-start-9 {
    grid-column-start: 9;
  }

  .xl\:dq-col-start-10 {
    grid-column-start: 10;
  }

  .xl\:dq-col-start-11 {
    grid-column-start: 11;
  }

  .xl\:dq-col-start-12 {
    grid-column-start: 12;
  }

  .xl\:dq-col-start-13 {
    grid-column-start: 13;
  }

  .xl\:dq-col-start-auto {
    grid-column-start: auto;
  }

  .xl\:dq-col-end-1 {
    grid-column-end: 1;
  }

  .xl\:dq-col-end-2 {
    grid-column-end: 2;
  }

  .xl\:dq-col-end-3 {
    grid-column-end: 3;
  }

  .xl\:dq-col-end-4 {
    grid-column-end: 4;
  }

  .xl\:dq-col-end-5 {
    grid-column-end: 5;
  }

  .xl\:dq-col-end-6 {
    grid-column-end: 6;
  }

  .xl\:dq-col-end-7 {
    grid-column-end: 7;
  }

  .xl\:dq-col-end-8 {
    grid-column-end: 8;
  }

  .xl\:dq-col-end-9 {
    grid-column-end: 9;
  }

  .xl\:dq-col-end-10 {
    grid-column-end: 10;
  }

  .xl\:dq-col-end-11 {
    grid-column-end: 11;
  }

  .xl\:dq-col-end-12 {
    grid-column-end: 12;
  }

  .xl\:dq-col-end-13 {
    grid-column-end: 13;
  }

  .xl\:dq-col-end-auto {
    grid-column-end: auto;
  }

  .xl\:dq-row-auto {
    grid-row: auto;
  }

  .xl\:dq-row-span-1 {
    grid-row: span 1 / span 1;
  }

  .xl\:dq-row-span-2 {
    grid-row: span 2 / span 2;
  }

  .xl\:dq-row-span-3 {
    grid-row: span 3 / span 3;
  }

  .xl\:dq-row-span-4 {
    grid-row: span 4 / span 4;
  }

  .xl\:dq-row-span-5 {
    grid-row: span 5 / span 5;
  }

  .xl\:dq-row-span-6 {
    grid-row: span 6 / span 6;
  }

  .xl\:dq-row-span-full {
    grid-row: 1 / -1;
  }

  .xl\:dq-row-start-1 {
    grid-row-start: 1;
  }

  .xl\:dq-row-start-2 {
    grid-row-start: 2;
  }

  .xl\:dq-row-start-3 {
    grid-row-start: 3;
  }

  .xl\:dq-row-start-4 {
    grid-row-start: 4;
  }

  .xl\:dq-row-start-5 {
    grid-row-start: 5;
  }

  .xl\:dq-row-start-6 {
    grid-row-start: 6;
  }

  .xl\:dq-row-start-7 {
    grid-row-start: 7;
  }

  .xl\:dq-row-start-auto {
    grid-row-start: auto;
  }

  .xl\:dq-row-end-1 {
    grid-row-end: 1;
  }

  .xl\:dq-row-end-2 {
    grid-row-end: 2;
  }

  .xl\:dq-row-end-3 {
    grid-row-end: 3;
  }

  .xl\:dq-row-end-4 {
    grid-row-end: 4;
  }

  .xl\:dq-row-end-5 {
    grid-row-end: 5;
  }

  .xl\:dq-row-end-6 {
    grid-row-end: 6;
  }

  .xl\:dq-row-end-7 {
    grid-row-end: 7;
  }

  .xl\:dq-row-end-auto {
    grid-row-end: auto;
  }

  .xl\:dq-float-right {
    float: right;
  }

  .xl\:dq-float-left {
    float: left;
  }

  .xl\:dq-float-none {
    float: none;
  }

  .xl\:dq-clear-left {
    clear: left;
  }

  .xl\:dq-clear-right {
    clear: right;
  }

  .xl\:dq-clear-both {
    clear: both;
  }

  .xl\:dq-clear-none {
    clear: none;
  }

  .xl\:dq-m-0 {
    margin: 0px;
  }

  .xl\:dq-m-1 {
    margin: 0.25rem;
  }

  .xl\:dq-m-2 {
    margin: 0.5rem;
  }

  .xl\:dq-m-3 {
    margin: 0.75rem;
  }

  .xl\:dq-m-4 {
    margin: 1rem;
  }

  .xl\:dq-m-5 {
    margin: 1.25rem;
  }

  .xl\:dq-m-6 {
    margin: 1.5rem;
  }

  .xl\:dq-m-7 {
    margin: 1.75rem;
  }

  .xl\:dq-m-8 {
    margin: 2rem;
  }

  .xl\:dq-m-9 {
    margin: 2.25rem;
  }

  .xl\:dq-m-10 {
    margin: 2.5rem;
  }

  .xl\:dq-m-11 {
    margin: 2.75rem;
  }

  .xl\:dq-m-12 {
    margin: 3rem;
  }

  .xl\:dq-m-14 {
    margin: 3.5rem;
  }

  .xl\:dq-m-16 {
    margin: 4rem;
  }

  .xl\:dq-m-20 {
    margin: 5rem;
  }

  .xl\:dq-m-24 {
    margin: 6rem;
  }

  .xl\:dq-m-28 {
    margin: 7rem;
  }

  .xl\:dq-m-32 {
    margin: 8rem;
  }

  .xl\:dq-m-36 {
    margin: 9rem;
  }

  .xl\:dq-m-40 {
    margin: 10rem;
  }

  .xl\:dq-m-44 {
    margin: 11rem;
  }

  .xl\:dq-m-48 {
    margin: 12rem;
  }

  .xl\:dq-m-52 {
    margin: 13rem;
  }

  .xl\:dq-m-56 {
    margin: 14rem;
  }

  .xl\:dq-m-60 {
    margin: 15rem;
  }

  .xl\:dq-m-64 {
    margin: 16rem;
  }

  .xl\:dq-m-72 {
    margin: 18rem;
  }

  .xl\:dq-m-80 {
    margin: 20rem;
  }

  .xl\:dq-m-96 {
    margin: 24rem;
  }

  .xl\:dq-m-auto {
    margin: auto;
  }

  .xl\:dq-m-px {
    margin: 1px;
  }

  .xl\:dq-m-0\.5 {
    margin: 0.125rem;
  }

  .xl\:dq-m-1\.5 {
    margin: 0.375rem;
  }

  .xl\:dq-m-2\.5 {
    margin: 0.625rem;
  }

  .xl\:dq-m-3\.5 {
    margin: 0.875rem;
  }

  .xl\:dq--m-0 {
    margin: 0px;
  }

  .xl\:dq--m-1 {
    margin: -0.25rem;
  }

  .xl\:dq--m-2 {
    margin: -0.5rem;
  }

  .xl\:dq--m-3 {
    margin: -0.75rem;
  }

  .xl\:dq--m-4 {
    margin: -1rem;
  }

  .xl\:dq--m-5 {
    margin: -1.25rem;
  }

  .xl\:dq--m-6 {
    margin: -1.5rem;
  }

  .xl\:dq--m-7 {
    margin: -1.75rem;
  }

  .xl\:dq--m-8 {
    margin: -2rem;
  }

  .xl\:dq--m-9 {
    margin: -2.25rem;
  }

  .xl\:dq--m-10 {
    margin: -2.5rem;
  }

  .xl\:dq--m-11 {
    margin: -2.75rem;
  }

  .xl\:dq--m-12 {
    margin: -3rem;
  }

  .xl\:dq--m-14 {
    margin: -3.5rem;
  }

  .xl\:dq--m-16 {
    margin: -4rem;
  }

  .xl\:dq--m-20 {
    margin: -5rem;
  }

  .xl\:dq--m-24 {
    margin: -6rem;
  }

  .xl\:dq--m-28 {
    margin: -7rem;
  }

  .xl\:dq--m-32 {
    margin: -8rem;
  }

  .xl\:dq--m-36 {
    margin: -9rem;
  }

  .xl\:dq--m-40 {
    margin: -10rem;
  }

  .xl\:dq--m-44 {
    margin: -11rem;
  }

  .xl\:dq--m-48 {
    margin: -12rem;
  }

  .xl\:dq--m-52 {
    margin: -13rem;
  }

  .xl\:dq--m-56 {
    margin: -14rem;
  }

  .xl\:dq--m-60 {
    margin: -15rem;
  }

  .xl\:dq--m-64 {
    margin: -16rem;
  }

  .xl\:dq--m-72 {
    margin: -18rem;
  }

  .xl\:dq--m-80 {
    margin: -20rem;
  }

  .xl\:dq--m-96 {
    margin: -24rem;
  }

  .xl\:dq--m-px {
    margin: -1px;
  }

  .xl\:dq--m-0\.5 {
    margin: -0.125rem;
  }

  .xl\:dq--m-1\.5 {
    margin: -0.375rem;
  }

  .xl\:dq--m-2\.5 {
    margin: -0.625rem;
  }

  .xl\:dq--m-3\.5 {
    margin: -0.875rem;
  }

  .xl\:dq-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .xl\:dq-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .xl\:dq-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .xl\:dq-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .xl\:dq-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xl\:dq-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xl\:dq-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:dq-mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .xl\:dq-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl\:dq-mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .xl\:dq-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xl\:dq-mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .xl\:dq-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xl\:dq-mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .xl\:dq-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .xl\:dq-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .xl\:dq-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .xl\:dq-mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .xl\:dq-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .xl\:dq-mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .xl\:dq-mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .xl\:dq-mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .xl\:dq-mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .xl\:dq-mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .xl\:dq-mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .xl\:dq-mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .xl\:dq-mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .xl\:dq-mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .xl\:dq-mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .xl\:dq-mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .xl\:dq-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xl\:dq-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .xl\:dq-mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }

  .xl\:dq-mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }

  .xl\:dq-mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .xl\:dq-mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }

  .xl\:dq--mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .xl\:dq--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .xl\:dq--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .xl\:dq--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .xl\:dq--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xl\:dq--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .xl\:dq--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .xl\:dq--mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .xl\:dq--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .xl\:dq--mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .xl\:dq--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .xl\:dq--mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .xl\:dq--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xl\:dq--mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .xl\:dq--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .xl\:dq--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .xl\:dq--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .xl\:dq--mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .xl\:dq--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .xl\:dq--mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .xl\:dq--mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .xl\:dq--mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .xl\:dq--mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .xl\:dq--mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .xl\:dq--mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .xl\:dq--mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .xl\:dq--mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .xl\:dq--mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .xl\:dq--mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .xl\:dq--mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .xl\:dq--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .xl\:dq--mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }

  .xl\:dq--mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }

  .xl\:dq--mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }

  .xl\:dq--mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }

  .xl\:dq-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .xl\:dq-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .xl\:dq-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .xl\:dq-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .xl\:dq-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xl\:dq-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xl\:dq-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xl\:dq-my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .xl\:dq-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xl\:dq-my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .xl\:dq-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xl\:dq-my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .xl\:dq-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .xl\:dq-my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .xl\:dq-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .xl\:dq-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .xl\:dq-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .xl\:dq-my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .xl\:dq-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .xl\:dq-my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .xl\:dq-my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .xl\:dq-my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .xl\:dq-my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .xl\:dq-my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .xl\:dq-my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .xl\:dq-my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .xl\:dq-my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .xl\:dq-my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .xl\:dq-my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .xl\:dq-my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .xl\:dq-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .xl\:dq-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .xl\:dq-my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }

  .xl\:dq-my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }

  .xl\:dq-my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .xl\:dq-my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }

  .xl\:dq--my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .xl\:dq--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .xl\:dq--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .xl\:dq--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .xl\:dq--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .xl\:dq--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .xl\:dq--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .xl\:dq--my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .xl\:dq--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .xl\:dq--my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .xl\:dq--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .xl\:dq--my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .xl\:dq--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .xl\:dq--my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .xl\:dq--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .xl\:dq--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .xl\:dq--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .xl\:dq--my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .xl\:dq--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .xl\:dq--my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .xl\:dq--my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .xl\:dq--my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .xl\:dq--my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .xl\:dq--my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .xl\:dq--my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .xl\:dq--my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .xl\:dq--my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .xl\:dq--my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .xl\:dq--my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .xl\:dq--my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .xl\:dq--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .xl\:dq--my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }

  .xl\:dq--my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }

  .xl\:dq--my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }

  .xl\:dq--my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }

  .xl\:dq-mt-0 {
    margin-top: 0px;
  }

  .xl\:dq-mt-1 {
    margin-top: 0.25rem;
  }

  .xl\:dq-mt-2 {
    margin-top: 0.5rem;
  }

  .xl\:dq-mt-3 {
    margin-top: 0.75rem;
  }

  .xl\:dq-mt-4 {
    margin-top: 1rem;
  }

  .xl\:dq-mt-5 {
    margin-top: 1.25rem;
  }

  .xl\:dq-mt-6 {
    margin-top: 1.5rem;
  }

  .xl\:dq-mt-7 {
    margin-top: 1.75rem;
  }

  .xl\:dq-mt-8 {
    margin-top: 2rem;
  }

  .xl\:dq-mt-9 {
    margin-top: 2.25rem;
  }

  .xl\:dq-mt-10 {
    margin-top: 2.5rem;
  }

  .xl\:dq-mt-11 {
    margin-top: 2.75rem;
  }

  .xl\:dq-mt-12 {
    margin-top: 3rem;
  }

  .xl\:dq-mt-14 {
    margin-top: 3.5rem;
  }

  .xl\:dq-mt-16 {
    margin-top: 4rem;
  }

  .xl\:dq-mt-20 {
    margin-top: 5rem;
  }

  .xl\:dq-mt-24 {
    margin-top: 6rem;
  }

  .xl\:dq-mt-28 {
    margin-top: 7rem;
  }

  .xl\:dq-mt-32 {
    margin-top: 8rem;
  }

  .xl\:dq-mt-36 {
    margin-top: 9rem;
  }

  .xl\:dq-mt-40 {
    margin-top: 10rem;
  }

  .xl\:dq-mt-44 {
    margin-top: 11rem;
  }

  .xl\:dq-mt-48 {
    margin-top: 12rem;
  }

  .xl\:dq-mt-52 {
    margin-top: 13rem;
  }

  .xl\:dq-mt-56 {
    margin-top: 14rem;
  }

  .xl\:dq-mt-60 {
    margin-top: 15rem;
  }

  .xl\:dq-mt-64 {
    margin-top: 16rem;
  }

  .xl\:dq-mt-72 {
    margin-top: 18rem;
  }

  .xl\:dq-mt-80 {
    margin-top: 20rem;
  }

  .xl\:dq-mt-96 {
    margin-top: 24rem;
  }

  .xl\:dq-mt-auto {
    margin-top: auto;
  }

  .xl\:dq-mt-px {
    margin-top: 1px;
  }

  .xl\:dq-mt-0\.5 {
    margin-top: 0.125rem;
  }

  .xl\:dq-mt-1\.5 {
    margin-top: 0.375rem;
  }

  .xl\:dq-mt-2\.5 {
    margin-top: 0.625rem;
  }

  .xl\:dq-mt-3\.5 {
    margin-top: 0.875rem;
  }

  .xl\:dq--mt-0 {
    margin-top: 0px;
  }

  .xl\:dq--mt-1 {
    margin-top: -0.25rem;
  }

  .xl\:dq--mt-2 {
    margin-top: -0.5rem;
  }

  .xl\:dq--mt-3 {
    margin-top: -0.75rem;
  }

  .xl\:dq--mt-4 {
    margin-top: -1rem;
  }

  .xl\:dq--mt-5 {
    margin-top: -1.25rem;
  }

  .xl\:dq--mt-6 {
    margin-top: -1.5rem;
  }

  .xl\:dq--mt-7 {
    margin-top: -1.75rem;
  }

  .xl\:dq--mt-8 {
    margin-top: -2rem;
  }

  .xl\:dq--mt-9 {
    margin-top: -2.25rem;
  }

  .xl\:dq--mt-10 {
    margin-top: -2.5rem;
  }

  .xl\:dq--mt-11 {
    margin-top: -2.75rem;
  }

  .xl\:dq--mt-12 {
    margin-top: -3rem;
  }

  .xl\:dq--mt-14 {
    margin-top: -3.5rem;
  }

  .xl\:dq--mt-16 {
    margin-top: -4rem;
  }

  .xl\:dq--mt-20 {
    margin-top: -5rem;
  }

  .xl\:dq--mt-24 {
    margin-top: -6rem;
  }

  .xl\:dq--mt-28 {
    margin-top: -7rem;
  }

  .xl\:dq--mt-32 {
    margin-top: -8rem;
  }

  .xl\:dq--mt-36 {
    margin-top: -9rem;
  }

  .xl\:dq--mt-40 {
    margin-top: -10rem;
  }

  .xl\:dq--mt-44 {
    margin-top: -11rem;
  }

  .xl\:dq--mt-48 {
    margin-top: -12rem;
  }

  .xl\:dq--mt-52 {
    margin-top: -13rem;
  }

  .xl\:dq--mt-56 {
    margin-top: -14rem;
  }

  .xl\:dq--mt-60 {
    margin-top: -15rem;
  }

  .xl\:dq--mt-64 {
    margin-top: -16rem;
  }

  .xl\:dq--mt-72 {
    margin-top: -18rem;
  }

  .xl\:dq--mt-80 {
    margin-top: -20rem;
  }

  .xl\:dq--mt-96 {
    margin-top: -24rem;
  }

  .xl\:dq--mt-px {
    margin-top: -1px;
  }

  .xl\:dq--mt-0\.5 {
    margin-top: -0.125rem;
  }

  .xl\:dq--mt-1\.5 {
    margin-top: -0.375rem;
  }

  .xl\:dq--mt-2\.5 {
    margin-top: -0.625rem;
  }

  .xl\:dq--mt-3\.5 {
    margin-top: -0.875rem;
  }

  .xl\:dq-mr-0 {
    margin-right: 0px;
  }

  .xl\:dq-mr-1 {
    margin-right: 0.25rem;
  }

  .xl\:dq-mr-2 {
    margin-right: 0.5rem;
  }

  .xl\:dq-mr-3 {
    margin-right: 0.75rem;
  }

  .xl\:dq-mr-4 {
    margin-right: 1rem;
  }

  .xl\:dq-mr-5 {
    margin-right: 1.25rem;
  }

  .xl\:dq-mr-6 {
    margin-right: 1.5rem;
  }

  .xl\:dq-mr-7 {
    margin-right: 1.75rem;
  }

  .xl\:dq-mr-8 {
    margin-right: 2rem;
  }

  .xl\:dq-mr-9 {
    margin-right: 2.25rem;
  }

  .xl\:dq-mr-10 {
    margin-right: 2.5rem;
  }

  .xl\:dq-mr-11 {
    margin-right: 2.75rem;
  }

  .xl\:dq-mr-12 {
    margin-right: 3rem;
  }

  .xl\:dq-mr-14 {
    margin-right: 3.5rem;
  }

  .xl\:dq-mr-16 {
    margin-right: 4rem;
  }

  .xl\:dq-mr-20 {
    margin-right: 5rem;
  }

  .xl\:dq-mr-24 {
    margin-right: 6rem;
  }

  .xl\:dq-mr-28 {
    margin-right: 7rem;
  }

  .xl\:dq-mr-32 {
    margin-right: 8rem;
  }

  .xl\:dq-mr-36 {
    margin-right: 9rem;
  }

  .xl\:dq-mr-40 {
    margin-right: 10rem;
  }

  .xl\:dq-mr-44 {
    margin-right: 11rem;
  }

  .xl\:dq-mr-48 {
    margin-right: 12rem;
  }

  .xl\:dq-mr-52 {
    margin-right: 13rem;
  }

  .xl\:dq-mr-56 {
    margin-right: 14rem;
  }

  .xl\:dq-mr-60 {
    margin-right: 15rem;
  }

  .xl\:dq-mr-64 {
    margin-right: 16rem;
  }

  .xl\:dq-mr-72 {
    margin-right: 18rem;
  }

  .xl\:dq-mr-80 {
    margin-right: 20rem;
  }

  .xl\:dq-mr-96 {
    margin-right: 24rem;
  }

  .xl\:dq-mr-auto {
    margin-right: auto;
  }

  .xl\:dq-mr-px {
    margin-right: 1px;
  }

  .xl\:dq-mr-0\.5 {
    margin-right: 0.125rem;
  }

  .xl\:dq-mr-1\.5 {
    margin-right: 0.375rem;
  }

  .xl\:dq-mr-2\.5 {
    margin-right: 0.625rem;
  }

  .xl\:dq-mr-3\.5 {
    margin-right: 0.875rem;
  }

  .xl\:dq--mr-0 {
    margin-right: 0px;
  }

  .xl\:dq--mr-1 {
    margin-right: -0.25rem;
  }

  .xl\:dq--mr-2 {
    margin-right: -0.5rem;
  }

  .xl\:dq--mr-3 {
    margin-right: -0.75rem;
  }

  .xl\:dq--mr-4 {
    margin-right: -1rem;
  }

  .xl\:dq--mr-5 {
    margin-right: -1.25rem;
  }

  .xl\:dq--mr-6 {
    margin-right: -1.5rem;
  }

  .xl\:dq--mr-7 {
    margin-right: -1.75rem;
  }

  .xl\:dq--mr-8 {
    margin-right: -2rem;
  }

  .xl\:dq--mr-9 {
    margin-right: -2.25rem;
  }

  .xl\:dq--mr-10 {
    margin-right: -2.5rem;
  }

  .xl\:dq--mr-11 {
    margin-right: -2.75rem;
  }

  .xl\:dq--mr-12 {
    margin-right: -3rem;
  }

  .xl\:dq--mr-14 {
    margin-right: -3.5rem;
  }

  .xl\:dq--mr-16 {
    margin-right: -4rem;
  }

  .xl\:dq--mr-20 {
    margin-right: -5rem;
  }

  .xl\:dq--mr-24 {
    margin-right: -6rem;
  }

  .xl\:dq--mr-28 {
    margin-right: -7rem;
  }

  .xl\:dq--mr-32 {
    margin-right: -8rem;
  }

  .xl\:dq--mr-36 {
    margin-right: -9rem;
  }

  .xl\:dq--mr-40 {
    margin-right: -10rem;
  }

  .xl\:dq--mr-44 {
    margin-right: -11rem;
  }

  .xl\:dq--mr-48 {
    margin-right: -12rem;
  }

  .xl\:dq--mr-52 {
    margin-right: -13rem;
  }

  .xl\:dq--mr-56 {
    margin-right: -14rem;
  }

  .xl\:dq--mr-60 {
    margin-right: -15rem;
  }

  .xl\:dq--mr-64 {
    margin-right: -16rem;
  }

  .xl\:dq--mr-72 {
    margin-right: -18rem;
  }

  .xl\:dq--mr-80 {
    margin-right: -20rem;
  }

  .xl\:dq--mr-96 {
    margin-right: -24rem;
  }

  .xl\:dq--mr-px {
    margin-right: -1px;
  }

  .xl\:dq--mr-0\.5 {
    margin-right: -0.125rem;
  }

  .xl\:dq--mr-1\.5 {
    margin-right: -0.375rem;
  }

  .xl\:dq--mr-2\.5 {
    margin-right: -0.625rem;
  }

  .xl\:dq--mr-3\.5 {
    margin-right: -0.875rem;
  }

  .xl\:dq-mb-0 {
    margin-bottom: 0px;
  }

  .xl\:dq-mb-1 {
    margin-bottom: 0.25rem;
  }

  .xl\:dq-mb-2 {
    margin-bottom: 0.5rem;
  }

  .xl\:dq-mb-3 {
    margin-bottom: 0.75rem;
  }

  .xl\:dq-mb-4 {
    margin-bottom: 1rem;
  }

  .xl\:dq-mb-5 {
    margin-bottom: 1.25rem;
  }

  .xl\:dq-mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:dq-mb-7 {
    margin-bottom: 1.75rem;
  }

  .xl\:dq-mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:dq-mb-9 {
    margin-bottom: 2.25rem;
  }

  .xl\:dq-mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:dq-mb-11 {
    margin-bottom: 2.75rem;
  }

  .xl\:dq-mb-12 {
    margin-bottom: 3rem;
  }

  .xl\:dq-mb-14 {
    margin-bottom: 3.5rem;
  }

  .xl\:dq-mb-16 {
    margin-bottom: 4rem;
  }

  .xl\:dq-mb-20 {
    margin-bottom: 5rem;
  }

  .xl\:dq-mb-24 {
    margin-bottom: 6rem;
  }

  .xl\:dq-mb-28 {
    margin-bottom: 7rem;
  }

  .xl\:dq-mb-32 {
    margin-bottom: 8rem;
  }

  .xl\:dq-mb-36 {
    margin-bottom: 9rem;
  }

  .xl\:dq-mb-40 {
    margin-bottom: 10rem;
  }

  .xl\:dq-mb-44 {
    margin-bottom: 11rem;
  }

  .xl\:dq-mb-48 {
    margin-bottom: 12rem;
  }

  .xl\:dq-mb-52 {
    margin-bottom: 13rem;
  }

  .xl\:dq-mb-56 {
    margin-bottom: 14rem;
  }

  .xl\:dq-mb-60 {
    margin-bottom: 15rem;
  }

  .xl\:dq-mb-64 {
    margin-bottom: 16rem;
  }

  .xl\:dq-mb-72 {
    margin-bottom: 18rem;
  }

  .xl\:dq-mb-80 {
    margin-bottom: 20rem;
  }

  .xl\:dq-mb-96 {
    margin-bottom: 24rem;
  }

  .xl\:dq-mb-auto {
    margin-bottom: auto;
  }

  .xl\:dq-mb-px {
    margin-bottom: 1px;
  }

  .xl\:dq-mb-0\.5 {
    margin-bottom: 0.125rem;
  }

  .xl\:dq-mb-1\.5 {
    margin-bottom: 0.375rem;
  }

  .xl\:dq-mb-2\.5 {
    margin-bottom: 0.625rem;
  }

  .xl\:dq-mb-3\.5 {
    margin-bottom: 0.875rem;
  }

  .xl\:dq--mb-0 {
    margin-bottom: 0px;
  }

  .xl\:dq--mb-1 {
    margin-bottom: -0.25rem;
  }

  .xl\:dq--mb-2 {
    margin-bottom: -0.5rem;
  }

  .xl\:dq--mb-3 {
    margin-bottom: -0.75rem;
  }

  .xl\:dq--mb-4 {
    margin-bottom: -1rem;
  }

  .xl\:dq--mb-5 {
    margin-bottom: -1.25rem;
  }

  .xl\:dq--mb-6 {
    margin-bottom: -1.5rem;
  }

  .xl\:dq--mb-7 {
    margin-bottom: -1.75rem;
  }

  .xl\:dq--mb-8 {
    margin-bottom: -2rem;
  }

  .xl\:dq--mb-9 {
    margin-bottom: -2.25rem;
  }

  .xl\:dq--mb-10 {
    margin-bottom: -2.5rem;
  }

  .xl\:dq--mb-11 {
    margin-bottom: -2.75rem;
  }

  .xl\:dq--mb-12 {
    margin-bottom: -3rem;
  }

  .xl\:dq--mb-14 {
    margin-bottom: -3.5rem;
  }

  .xl\:dq--mb-16 {
    margin-bottom: -4rem;
  }

  .xl\:dq--mb-20 {
    margin-bottom: -5rem;
  }

  .xl\:dq--mb-24 {
    margin-bottom: -6rem;
  }

  .xl\:dq--mb-28 {
    margin-bottom: -7rem;
  }

  .xl\:dq--mb-32 {
    margin-bottom: -8rem;
  }

  .xl\:dq--mb-36 {
    margin-bottom: -9rem;
  }

  .xl\:dq--mb-40 {
    margin-bottom: -10rem;
  }

  .xl\:dq--mb-44 {
    margin-bottom: -11rem;
  }

  .xl\:dq--mb-48 {
    margin-bottom: -12rem;
  }

  .xl\:dq--mb-52 {
    margin-bottom: -13rem;
  }

  .xl\:dq--mb-56 {
    margin-bottom: -14rem;
  }

  .xl\:dq--mb-60 {
    margin-bottom: -15rem;
  }

  .xl\:dq--mb-64 {
    margin-bottom: -16rem;
  }

  .xl\:dq--mb-72 {
    margin-bottom: -18rem;
  }

  .xl\:dq--mb-80 {
    margin-bottom: -20rem;
  }

  .xl\:dq--mb-96 {
    margin-bottom: -24rem;
  }

  .xl\:dq--mb-px {
    margin-bottom: -1px;
  }

  .xl\:dq--mb-0\.5 {
    margin-bottom: -0.125rem;
  }

  .xl\:dq--mb-1\.5 {
    margin-bottom: -0.375rem;
  }

  .xl\:dq--mb-2\.5 {
    margin-bottom: -0.625rem;
  }

  .xl\:dq--mb-3\.5 {
    margin-bottom: -0.875rem;
  }

  .xl\:dq-ml-0 {
    margin-left: 0px;
  }

  .xl\:dq-ml-1 {
    margin-left: 0.25rem;
  }

  .xl\:dq-ml-2 {
    margin-left: 0.5rem;
  }

  .xl\:dq-ml-3 {
    margin-left: 0.75rem;
  }

  .xl\:dq-ml-4 {
    margin-left: 1rem;
  }

  .xl\:dq-ml-5 {
    margin-left: 1.25rem;
  }

  .xl\:dq-ml-6 {
    margin-left: 1.5rem;
  }

  .xl\:dq-ml-7 {
    margin-left: 1.75rem;
  }

  .xl\:dq-ml-8 {
    margin-left: 2rem;
  }

  .xl\:dq-ml-9 {
    margin-left: 2.25rem;
  }

  .xl\:dq-ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:dq-ml-11 {
    margin-left: 2.75rem;
  }

  .xl\:dq-ml-12 {
    margin-left: 3rem;
  }

  .xl\:dq-ml-14 {
    margin-left: 3.5rem;
  }

  .xl\:dq-ml-16 {
    margin-left: 4rem;
  }

  .xl\:dq-ml-20 {
    margin-left: 5rem;
  }

  .xl\:dq-ml-24 {
    margin-left: 6rem;
  }

  .xl\:dq-ml-28 {
    margin-left: 7rem;
  }

  .xl\:dq-ml-32 {
    margin-left: 8rem;
  }

  .xl\:dq-ml-36 {
    margin-left: 9rem;
  }

  .xl\:dq-ml-40 {
    margin-left: 10rem;
  }

  .xl\:dq-ml-44 {
    margin-left: 11rem;
  }

  .xl\:dq-ml-48 {
    margin-left: 12rem;
  }

  .xl\:dq-ml-52 {
    margin-left: 13rem;
  }

  .xl\:dq-ml-56 {
    margin-left: 14rem;
  }

  .xl\:dq-ml-60 {
    margin-left: 15rem;
  }

  .xl\:dq-ml-64 {
    margin-left: 16rem;
  }

  .xl\:dq-ml-72 {
    margin-left: 18rem;
  }

  .xl\:dq-ml-80 {
    margin-left: 20rem;
  }

  .xl\:dq-ml-96 {
    margin-left: 24rem;
  }

  .xl\:dq-ml-auto {
    margin-left: auto;
  }

  .xl\:dq-ml-px {
    margin-left: 1px;
  }

  .xl\:dq-ml-0\.5 {
    margin-left: 0.125rem;
  }

  .xl\:dq-ml-1\.5 {
    margin-left: 0.375rem;
  }

  .xl\:dq-ml-2\.5 {
    margin-left: 0.625rem;
  }

  .xl\:dq-ml-3\.5 {
    margin-left: 0.875rem;
  }

  .xl\:dq--ml-0 {
    margin-left: 0px;
  }

  .xl\:dq--ml-1 {
    margin-left: -0.25rem;
  }

  .xl\:dq--ml-2 {
    margin-left: -0.5rem;
  }

  .xl\:dq--ml-3 {
    margin-left: -0.75rem;
  }

  .xl\:dq--ml-4 {
    margin-left: -1rem;
  }

  .xl\:dq--ml-5 {
    margin-left: -1.25rem;
  }

  .xl\:dq--ml-6 {
    margin-left: -1.5rem;
  }

  .xl\:dq--ml-7 {
    margin-left: -1.75rem;
  }

  .xl\:dq--ml-8 {
    margin-left: -2rem;
  }

  .xl\:dq--ml-9 {
    margin-left: -2.25rem;
  }

  .xl\:dq--ml-10 {
    margin-left: -2.5rem;
  }

  .xl\:dq--ml-11 {
    margin-left: -2.75rem;
  }

  .xl\:dq--ml-12 {
    margin-left: -3rem;
  }

  .xl\:dq--ml-14 {
    margin-left: -3.5rem;
  }

  .xl\:dq--ml-16 {
    margin-left: -4rem;
  }

  .xl\:dq--ml-20 {
    margin-left: -5rem;
  }

  .xl\:dq--ml-24 {
    margin-left: -6rem;
  }

  .xl\:dq--ml-28 {
    margin-left: -7rem;
  }

  .xl\:dq--ml-32 {
    margin-left: -8rem;
  }

  .xl\:dq--ml-36 {
    margin-left: -9rem;
  }

  .xl\:dq--ml-40 {
    margin-left: -10rem;
  }

  .xl\:dq--ml-44 {
    margin-left: -11rem;
  }

  .xl\:dq--ml-48 {
    margin-left: -12rem;
  }

  .xl\:dq--ml-52 {
    margin-left: -13rem;
  }

  .xl\:dq--ml-56 {
    margin-left: -14rem;
  }

  .xl\:dq--ml-60 {
    margin-left: -15rem;
  }

  .xl\:dq--ml-64 {
    margin-left: -16rem;
  }

  .xl\:dq--ml-72 {
    margin-left: -18rem;
  }

  .xl\:dq--ml-80 {
    margin-left: -20rem;
  }

  .xl\:dq--ml-96 {
    margin-left: -24rem;
  }

  .xl\:dq--ml-px {
    margin-left: -1px;
  }

  .xl\:dq--ml-0\.5 {
    margin-left: -0.125rem;
  }

  .xl\:dq--ml-1\.5 {
    margin-left: -0.375rem;
  }

  .xl\:dq--ml-2\.5 {
    margin-left: -0.625rem;
  }

  .xl\:dq--ml-3\.5 {
    margin-left: -0.875rem;
  }

  .xl\:dq-box-border {
    box-sizing: border-box;
  }

  .xl\:dq-box-content {
    box-sizing: content-box;
  }

  .xl\:dq-block {
    display: block;
  }

  .xl\:dq-inline-block {
    display: inline-block;
  }

  .xl\:dq-inline {
    display: inline;
  }

  .xl\:dq-flex {
    display: flex;
  }

  .xl\:dq-inline-flex {
    display: inline-flex;
  }

  .xl\:dq-table {
    display: table;
  }

  .xl\:dq-inline-table {
    display: inline-table;
  }

  .xl\:dq-table-caption {
    display: table-caption;
  }

  .xl\:dq-table-cell {
    display: table-cell;
  }

  .xl\:dq-table-column {
    display: table-column;
  }

  .xl\:dq-table-column-group {
    display: table-column-group;
  }

  .xl\:dq-table-footer-group {
    display: table-footer-group;
  }

  .xl\:dq-table-header-group {
    display: table-header-group;
  }

  .xl\:dq-table-row-group {
    display: table-row-group;
  }

  .xl\:dq-table-row {
    display: table-row;
  }

  .xl\:dq-flow-root {
    display: flow-root;
  }

  .xl\:dq-grid {
    display: grid;
  }

  .xl\:dq-inline-grid {
    display: inline-grid;
  }

  .xl\:dq-contents {
    display: contents;
  }

  .xl\:dq-list-item {
    display: list-item;
  }

  .xl\:dq-hidden {
    display: none;
  }

  .xl\:dq-h-0 {
    height: 0px;
  }

  .xl\:dq-h-1 {
    height: 0.25rem;
  }

  .xl\:dq-h-2 {
    height: 0.5rem;
  }

  .xl\:dq-h-3 {
    height: 0.75rem;
  }

  .xl\:dq-h-4 {
    height: 1rem;
  }

  .xl\:dq-h-5 {
    height: 1.25rem;
  }

  .xl\:dq-h-6 {
    height: 1.5rem;
  }

  .xl\:dq-h-7 {
    height: 1.75rem;
  }

  .xl\:dq-h-8 {
    height: 2rem;
  }

  .xl\:dq-h-9 {
    height: 2.25rem;
  }

  .xl\:dq-h-10 {
    height: 2.5rem;
  }

  .xl\:dq-h-11 {
    height: 2.75rem;
  }

  .xl\:dq-h-12 {
    height: 3rem;
  }

  .xl\:dq-h-14 {
    height: 3.5rem;
  }

  .xl\:dq-h-16 {
    height: 4rem;
  }

  .xl\:dq-h-20 {
    height: 5rem;
  }

  .xl\:dq-h-24 {
    height: 6rem;
  }

  .xl\:dq-h-28 {
    height: 7rem;
  }

  .xl\:dq-h-32 {
    height: 8rem;
  }

  .xl\:dq-h-36 {
    height: 9rem;
  }

  .xl\:dq-h-40 {
    height: 10rem;
  }

  .xl\:dq-h-44 {
    height: 11rem;
  }

  .xl\:dq-h-48 {
    height: 12rem;
  }

  .xl\:dq-h-52 {
    height: 13rem;
  }

  .xl\:dq-h-56 {
    height: 14rem;
  }

  .xl\:dq-h-60 {
    height: 15rem;
  }

  .xl\:dq-h-64 {
    height: 16rem;
  }

  .xl\:dq-h-72 {
    height: 18rem;
  }

  .xl\:dq-h-80 {
    height: 20rem;
  }

  .xl\:dq-h-96 {
    height: 24rem;
  }

  .xl\:dq-h-auto {
    height: auto;
  }

  .xl\:dq-h-px {
    height: 1px;
  }

  .xl\:dq-h-0\.5 {
    height: 0.125rem;
  }

  .xl\:dq-h-1\.5 {
    height: 0.375rem;
  }

  .xl\:dq-h-2\.5 {
    height: 0.625rem;
  }

  .xl\:dq-h-3\.5 {
    height: 0.875rem;
  }

  .xl\:dq-h-1\/2 {
    height: 50%;
  }

  .xl\:dq-h-1\/3 {
    height: 33.333333%;
  }

  .xl\:dq-h-2\/3 {
    height: 66.666667%;
  }

  .xl\:dq-h-1\/4 {
    height: 25%;
  }

  .xl\:dq-h-2\/4 {
    height: 50%;
  }

  .xl\:dq-h-3\/4 {
    height: 75%;
  }

  .xl\:dq-h-1\/5 {
    height: 20%;
  }

  .xl\:dq-h-2\/5 {
    height: 40%;
  }

  .xl\:dq-h-3\/5 {
    height: 60%;
  }

  .xl\:dq-h-4\/5 {
    height: 80%;
  }

  .xl\:dq-h-1\/6 {
    height: 16.666667%;
  }

  .xl\:dq-h-2\/6 {
    height: 33.333333%;
  }

  .xl\:dq-h-3\/6 {
    height: 50%;
  }

  .xl\:dq-h-4\/6 {
    height: 66.666667%;
  }

  .xl\:dq-h-5\/6 {
    height: 83.333333%;
  }

  .xl\:dq-h-full {
    height: 100%;
  }

  .xl\:dq-h-screen {
    height: 100vh;
  }

  .xl\:dq-max-h-0 {
    max-height: 0px;
  }

  .xl\:dq-max-h-1 {
    max-height: 0.25rem;
  }

  .xl\:dq-max-h-2 {
    max-height: 0.5rem;
  }

  .xl\:dq-max-h-3 {
    max-height: 0.75rem;
  }

  .xl\:dq-max-h-4 {
    max-height: 1rem;
  }

  .xl\:dq-max-h-5 {
    max-height: 1.25rem;
  }

  .xl\:dq-max-h-6 {
    max-height: 1.5rem;
  }

  .xl\:dq-max-h-7 {
    max-height: 1.75rem;
  }

  .xl\:dq-max-h-8 {
    max-height: 2rem;
  }

  .xl\:dq-max-h-9 {
    max-height: 2.25rem;
  }

  .xl\:dq-max-h-10 {
    max-height: 2.5rem;
  }

  .xl\:dq-max-h-11 {
    max-height: 2.75rem;
  }

  .xl\:dq-max-h-12 {
    max-height: 3rem;
  }

  .xl\:dq-max-h-14 {
    max-height: 3.5rem;
  }

  .xl\:dq-max-h-16 {
    max-height: 4rem;
  }

  .xl\:dq-max-h-20 {
    max-height: 5rem;
  }

  .xl\:dq-max-h-24 {
    max-height: 6rem;
  }

  .xl\:dq-max-h-28 {
    max-height: 7rem;
  }

  .xl\:dq-max-h-32 {
    max-height: 8rem;
  }

  .xl\:dq-max-h-36 {
    max-height: 9rem;
  }

  .xl\:dq-max-h-40 {
    max-height: 10rem;
  }

  .xl\:dq-max-h-44 {
    max-height: 11rem;
  }

  .xl\:dq-max-h-48 {
    max-height: 12rem;
  }

  .xl\:dq-max-h-52 {
    max-height: 13rem;
  }

  .xl\:dq-max-h-56 {
    max-height: 14rem;
  }

  .xl\:dq-max-h-60 {
    max-height: 15rem;
  }

  .xl\:dq-max-h-64 {
    max-height: 16rem;
  }

  .xl\:dq-max-h-72 {
    max-height: 18rem;
  }

  .xl\:dq-max-h-80 {
    max-height: 20rem;
  }

  .xl\:dq-max-h-96 {
    max-height: 24rem;
  }

  .xl\:dq-max-h-px {
    max-height: 1px;
  }

  .xl\:dq-max-h-0\.5 {
    max-height: 0.125rem;
  }

  .xl\:dq-max-h-1\.5 {
    max-height: 0.375rem;
  }

  .xl\:dq-max-h-2\.5 {
    max-height: 0.625rem;
  }

  .xl\:dq-max-h-3\.5 {
    max-height: 0.875rem;
  }

  .xl\:dq-max-h-full {
    max-height: 100%;
  }

  .xl\:dq-max-h-screen {
    max-height: 100vh;
  }

  .xl\:dq-min-h-0 {
    min-height: 0px;
  }

  .xl\:dq-min-h-full {
    min-height: 100%;
  }

  .xl\:dq-min-h-screen {
    min-height: 100vh;
  }

  .xl\:dq-w-0 {
    width: 0px;
  }

  .xl\:dq-w-1 {
    width: 0.25rem;
  }

  .xl\:dq-w-2 {
    width: 0.5rem;
  }

  .xl\:dq-w-3 {
    width: 0.75rem;
  }

  .xl\:dq-w-4 {
    width: 1rem;
  }

  .xl\:dq-w-5 {
    width: 1.25rem;
  }

  .xl\:dq-w-6 {
    width: 1.5rem;
  }

  .xl\:dq-w-7 {
    width: 1.75rem;
  }

  .xl\:dq-w-8 {
    width: 2rem;
  }

  .xl\:dq-w-9 {
    width: 2.25rem;
  }

  .xl\:dq-w-10 {
    width: 2.5rem;
  }

  .xl\:dq-w-11 {
    width: 2.75rem;
  }

  .xl\:dq-w-12 {
    width: 3rem;
  }

  .xl\:dq-w-14 {
    width: 3.5rem;
  }

  .xl\:dq-w-16 {
    width: 4rem;
  }

  .xl\:dq-w-20 {
    width: 5rem;
  }

  .xl\:dq-w-24 {
    width: 6rem;
  }

  .xl\:dq-w-28 {
    width: 7rem;
  }

  .xl\:dq-w-32 {
    width: 8rem;
  }

  .xl\:dq-w-36 {
    width: 9rem;
  }

  .xl\:dq-w-40 {
    width: 10rem;
  }

  .xl\:dq-w-44 {
    width: 11rem;
  }

  .xl\:dq-w-48 {
    width: 12rem;
  }

  .xl\:dq-w-52 {
    width: 13rem;
  }

  .xl\:dq-w-56 {
    width: 14rem;
  }

  .xl\:dq-w-60 {
    width: 15rem;
  }

  .xl\:dq-w-64 {
    width: 16rem;
  }

  .xl\:dq-w-72 {
    width: 18rem;
  }

  .xl\:dq-w-80 {
    width: 20rem;
  }

  .xl\:dq-w-96 {
    width: 24rem;
  }

  .xl\:dq-w-auto {
    width: auto;
  }

  .xl\:dq-w-px {
    width: 1px;
  }

  .xl\:dq-w-0\.5 {
    width: 0.125rem;
  }

  .xl\:dq-w-1\.5 {
    width: 0.375rem;
  }

  .xl\:dq-w-2\.5 {
    width: 0.625rem;
  }

  .xl\:dq-w-3\.5 {
    width: 0.875rem;
  }

  .xl\:dq-w-1\/2 {
    width: 50%;
  }

  .xl\:dq-w-1\/3 {
    width: 33.333333%;
  }

  .xl\:dq-w-2\/3 {
    width: 66.666667%;
  }

  .xl\:dq-w-1\/4 {
    width: 25%;
  }

  .xl\:dq-w-2\/4 {
    width: 50%;
  }

  .xl\:dq-w-3\/4 {
    width: 75%;
  }

  .xl\:dq-w-1\/5 {
    width: 20%;
  }

  .xl\:dq-w-2\/5 {
    width: 40%;
  }

  .xl\:dq-w-3\/5 {
    width: 60%;
  }

  .xl\:dq-w-4\/5 {
    width: 80%;
  }

  .xl\:dq-w-1\/6 {
    width: 16.666667%;
  }

  .xl\:dq-w-2\/6 {
    width: 33.333333%;
  }

  .xl\:dq-w-3\/6 {
    width: 50%;
  }

  .xl\:dq-w-4\/6 {
    width: 66.666667%;
  }

  .xl\:dq-w-5\/6 {
    width: 83.333333%;
  }

  .xl\:dq-w-1\/12 {
    width: 8.333333%;
  }

  .xl\:dq-w-2\/12 {
    width: 16.666667%;
  }

  .xl\:dq-w-3\/12 {
    width: 25%;
  }

  .xl\:dq-w-4\/12 {
    width: 33.333333%;
  }

  .xl\:dq-w-5\/12 {
    width: 41.666667%;
  }

  .xl\:dq-w-6\/12 {
    width: 50%;
  }

  .xl\:dq-w-7\/12 {
    width: 58.333333%;
  }

  .xl\:dq-w-8\/12 {
    width: 66.666667%;
  }

  .xl\:dq-w-9\/12 {
    width: 75%;
  }

  .xl\:dq-w-10\/12 {
    width: 83.333333%;
  }

  .xl\:dq-w-11\/12 {
    width: 91.666667%;
  }

  .xl\:dq-w-full {
    width: 100%;
  }

  .xl\:dq-w-screen {
    width: 100vw;
  }

  .xl\:dq-w-min {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }

  .xl\:dq-w-max {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .xl\:dq-min-w-0 {
    min-width: 0px;
  }

  .xl\:dq-min-w-full {
    min-width: 100%;
  }

  .xl\:dq-min-w-min {
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
  }

  .xl\:dq-min-w-max {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
  }

  .xl\:dq-max-w-0 {
    max-width: 0rem;
  }

  .xl\:dq-max-w-none {
    max-width: none;
  }

  .xl\:dq-max-w-xs {
    max-width: 20rem;
  }

  .xl\:dq-max-w-sm {
    max-width: 24rem;
  }

  .xl\:dq-max-w-md {
    max-width: 28rem;
  }

  .xl\:dq-max-w-lg {
    max-width: 32rem;
  }

  .xl\:dq-max-w-xl {
    max-width: 36rem;
  }

  .xl\:dq-max-w-2xl {
    max-width: 42rem;
  }

  .xl\:dq-max-w-3xl {
    max-width: 48rem;
  }

  .xl\:dq-max-w-4xl {
    max-width: 56rem;
  }

  .xl\:dq-max-w-5xl {
    max-width: 64rem;
  }

  .xl\:dq-max-w-6xl {
    max-width: 72rem;
  }

  .xl\:dq-max-w-7xl {
    max-width: 80rem;
  }

  .xl\:dq-max-w-full {
    max-width: 100%;
  }

  .xl\:dq-max-w-min {
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
  }

  .xl\:dq-max-w-max {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }

  .xl\:dq-max-w-prose {
    max-width: 65ch;
  }

  .xl\:dq-max-w-screen-sm {
    max-width: 640px;
  }

  .xl\:dq-max-w-screen-md {
    max-width: 768px;
  }

  .xl\:dq-max-w-screen-lg {
    max-width: 1024px;
  }

  .xl\:dq-max-w-screen-xl {
    max-width: 1280px;
  }

  .xl\:dq-max-w-screen-2xl {
    max-width: 1536px;
  }

  .xl\:dq-flex-1 {
    flex: 1 1 0%;
  }

  .xl\:dq-flex-auto {
    flex: 1 1 auto;
  }

  .xl\:dq-flex-initial {
    flex: 0 1 auto;
  }

  .xl\:dq-flex-none {
    flex: none;
  }

  .xl\:dq-flex-shrink-0 {
    flex-shrink: 0;
  }

  .xl\:dq-flex-shrink {
    flex-shrink: 1;
  }

  .xl\:dq-flex-grow-0 {
    flex-grow: 0;
  }

  .xl\:dq-flex-grow {
    flex-grow: 1;
  }

  .xl\:dq-table-auto {
    table-layout: auto;
  }

  .xl\:dq-table-fixed {
    table-layout: fixed;
  }

  .xl\:dq-border-collapse {
    border-collapse: collapse;
  }

  .xl\:dq-border-separate {
    border-collapse: separate;
  }

  .xl\:dq-origin-center {
    transform-origin: center;
  }

  .xl\:dq-origin-top {
    transform-origin: top;
  }

  .xl\:dq-origin-top-right {
    transform-origin: top right;
  }

  .xl\:dq-origin-right {
    transform-origin: right;
  }

  .xl\:dq-origin-bottom-right {
    transform-origin: bottom right;
  }

  .xl\:dq-origin-bottom {
    transform-origin: bottom;
  }

  .xl\:dq-origin-bottom-left {
    transform-origin: bottom left;
  }

  .xl\:dq-origin-left {
    transform-origin: left;
  }

  .xl\:dq-origin-top-left {
    transform-origin: top left;
  }

  .xl\:dq-transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:dq-transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:dq-transform-none {
    transform: none;
  }

  .xl\:dq-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .xl\:dq-translate-x-1 {
    --tw-translate-x: 0.25rem;
  }

  .xl\:dq-translate-x-2 {
    --tw-translate-x: 0.5rem;
  }

  .xl\:dq-translate-x-3 {
    --tw-translate-x: 0.75rem;
  }

  .xl\:dq-translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .xl\:dq-translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .xl\:dq-translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .xl\:dq-translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .xl\:dq-translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .xl\:dq-translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .xl\:dq-translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .xl\:dq-translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .xl\:dq-translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .xl\:dq-translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .xl\:dq-translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .xl\:dq-translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .xl\:dq-translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .xl\:dq-translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .xl\:dq-translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .xl\:dq-translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .xl\:dq-translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .xl\:dq-translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .xl\:dq-translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .xl\:dq-translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .xl\:dq-translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .xl\:dq-translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .xl\:dq-translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .xl\:dq-translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .xl\:dq-translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .xl\:dq-translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .xl\:dq-translate-x-px {
    --tw-translate-x: 1px;
  }

  .xl\:dq-translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }

  .xl\:dq-translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }

  .xl\:dq-translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }

  .xl\:dq-translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }

  .xl\:dq--translate-x-0 {
    --tw-translate-x: 0px;
  }

  .xl\:dq--translate-x-1 {
    --tw-translate-x: -0.25rem;
  }

  .xl\:dq--translate-x-2 {
    --tw-translate-x: -0.5rem;
  }

  .xl\:dq--translate-x-3 {
    --tw-translate-x: -0.75rem;
  }

  .xl\:dq--translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .xl\:dq--translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .xl\:dq--translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .xl\:dq--translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .xl\:dq--translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .xl\:dq--translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .xl\:dq--translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .xl\:dq--translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .xl\:dq--translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .xl\:dq--translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .xl\:dq--translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .xl\:dq--translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .xl\:dq--translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .xl\:dq--translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .xl\:dq--translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .xl\:dq--translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .xl\:dq--translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .xl\:dq--translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .xl\:dq--translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .xl\:dq--translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .xl\:dq--translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .xl\:dq--translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .xl\:dq--translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .xl\:dq--translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .xl\:dq--translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .xl\:dq--translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .xl\:dq--translate-x-px {
    --tw-translate-x: -1px;
  }

  .xl\:dq--translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }

  .xl\:dq--translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }

  .xl\:dq--translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }

  .xl\:dq--translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }

  .xl\:dq-translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .xl\:dq-translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }

  .xl\:dq-translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }

  .xl\:dq-translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .xl\:dq-translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .xl\:dq-translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .xl\:dq-translate-x-full {
    --tw-translate-x: 100%;
  }

  .xl\:dq--translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .xl\:dq--translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }

  .xl\:dq--translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }

  .xl\:dq--translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .xl\:dq--translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .xl\:dq--translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .xl\:dq--translate-x-full {
    --tw-translate-x: -100%;
  }

  .xl\:dq-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .xl\:dq-translate-y-1 {
    --tw-translate-y: 0.25rem;
  }

  .xl\:dq-translate-y-2 {
    --tw-translate-y: 0.5rem;
  }

  .xl\:dq-translate-y-3 {
    --tw-translate-y: 0.75rem;
  }

  .xl\:dq-translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .xl\:dq-translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .xl\:dq-translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .xl\:dq-translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .xl\:dq-translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .xl\:dq-translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .xl\:dq-translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .xl\:dq-translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .xl\:dq-translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .xl\:dq-translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .xl\:dq-translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .xl\:dq-translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .xl\:dq-translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .xl\:dq-translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .xl\:dq-translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .xl\:dq-translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .xl\:dq-translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .xl\:dq-translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .xl\:dq-translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .xl\:dq-translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .xl\:dq-translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .xl\:dq-translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .xl\:dq-translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .xl\:dq-translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .xl\:dq-translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .xl\:dq-translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .xl\:dq-translate-y-px {
    --tw-translate-y: 1px;
  }

  .xl\:dq-translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }

  .xl\:dq-translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }

  .xl\:dq-translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }

  .xl\:dq-translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }

  .xl\:dq--translate-y-0 {
    --tw-translate-y: 0px;
  }

  .xl\:dq--translate-y-1 {
    --tw-translate-y: -0.25rem;
  }

  .xl\:dq--translate-y-2 {
    --tw-translate-y: -0.5rem;
  }

  .xl\:dq--translate-y-3 {
    --tw-translate-y: -0.75rem;
  }

  .xl\:dq--translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .xl\:dq--translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .xl\:dq--translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .xl\:dq--translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .xl\:dq--translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .xl\:dq--translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .xl\:dq--translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .xl\:dq--translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .xl\:dq--translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .xl\:dq--translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .xl\:dq--translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .xl\:dq--translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .xl\:dq--translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .xl\:dq--translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .xl\:dq--translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .xl\:dq--translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .xl\:dq--translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .xl\:dq--translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .xl\:dq--translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .xl\:dq--translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .xl\:dq--translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .xl\:dq--translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .xl\:dq--translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .xl\:dq--translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .xl\:dq--translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .xl\:dq--translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .xl\:dq--translate-y-px {
    --tw-translate-y: -1px;
  }

  .xl\:dq--translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }

  .xl\:dq--translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }

  .xl\:dq--translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }

  .xl\:dq--translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }

  .xl\:dq-translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .xl\:dq-translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }

  .xl\:dq-translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }

  .xl\:dq-translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .xl\:dq-translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .xl\:dq-translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .xl\:dq-translate-y-full {
    --tw-translate-y: 100%;
  }

  .xl\:dq--translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .xl\:dq--translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }

  .xl\:dq--translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }

  .xl\:dq--translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .xl\:dq--translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .xl\:dq--translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .xl\:dq--translate-y-full {
    --tw-translate-y: -100%;
  }

  .xl\:hover\:dq-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .xl\:hover\:dq-translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }

  .xl\:hover\:dq-translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }

  .xl\:hover\:dq-translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }

  .xl\:hover\:dq-translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .xl\:hover\:dq-translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .xl\:hover\:dq-translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .xl\:hover\:dq-translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .xl\:hover\:dq-translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .xl\:hover\:dq-translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .xl\:hover\:dq-translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .xl\:hover\:dq-translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .xl\:hover\:dq-translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .xl\:hover\:dq-translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .xl\:hover\:dq-translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .xl\:hover\:dq-translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .xl\:hover\:dq-translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .xl\:hover\:dq-translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .xl\:hover\:dq-translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .xl\:hover\:dq-translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .xl\:hover\:dq-translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .xl\:hover\:dq-translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .xl\:hover\:dq-translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .xl\:hover\:dq-translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .xl\:hover\:dq-translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .xl\:hover\:dq-translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .xl\:hover\:dq-translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .xl\:hover\:dq-translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .xl\:hover\:dq-translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .xl\:hover\:dq-translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .xl\:hover\:dq-translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .xl\:hover\:dq-translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }

  .xl\:hover\:dq-translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }

  .xl\:hover\:dq-translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }

  .xl\:hover\:dq-translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }

  .xl\:hover\:dq--translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .xl\:hover\:dq--translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }

  .xl\:hover\:dq--translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }

  .xl\:hover\:dq--translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }

  .xl\:hover\:dq--translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .xl\:hover\:dq--translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .xl\:hover\:dq--translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .xl\:hover\:dq--translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .xl\:hover\:dq--translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .xl\:hover\:dq--translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .xl\:hover\:dq--translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .xl\:hover\:dq--translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .xl\:hover\:dq--translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .xl\:hover\:dq--translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .xl\:hover\:dq--translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .xl\:hover\:dq--translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .xl\:hover\:dq--translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .xl\:hover\:dq--translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .xl\:hover\:dq--translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .xl\:hover\:dq--translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .xl\:hover\:dq--translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .xl\:hover\:dq--translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .xl\:hover\:dq--translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .xl\:hover\:dq--translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .xl\:hover\:dq--translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .xl\:hover\:dq--translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .xl\:hover\:dq--translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .xl\:hover\:dq--translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .xl\:hover\:dq--translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .xl\:hover\:dq--translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .xl\:hover\:dq--translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .xl\:hover\:dq--translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }

  .xl\:hover\:dq--translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }

  .xl\:hover\:dq--translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }

  .xl\:hover\:dq--translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }

  .xl\:hover\:dq-translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .xl\:hover\:dq-translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }

  .xl\:hover\:dq-translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }

  .xl\:hover\:dq-translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .xl\:hover\:dq-translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .xl\:hover\:dq-translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .xl\:hover\:dq-translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .xl\:hover\:dq--translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .xl\:hover\:dq--translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }

  .xl\:hover\:dq--translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }

  .xl\:hover\:dq--translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .xl\:hover\:dq--translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .xl\:hover\:dq--translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .xl\:hover\:dq--translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .xl\:hover\:dq-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .xl\:hover\:dq-translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }

  .xl\:hover\:dq-translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }

  .xl\:hover\:dq-translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }

  .xl\:hover\:dq-translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .xl\:hover\:dq-translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .xl\:hover\:dq-translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .xl\:hover\:dq-translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .xl\:hover\:dq-translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .xl\:hover\:dq-translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .xl\:hover\:dq-translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .xl\:hover\:dq-translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .xl\:hover\:dq-translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .xl\:hover\:dq-translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .xl\:hover\:dq-translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .xl\:hover\:dq-translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .xl\:hover\:dq-translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .xl\:hover\:dq-translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .xl\:hover\:dq-translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .xl\:hover\:dq-translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .xl\:hover\:dq-translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .xl\:hover\:dq-translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .xl\:hover\:dq-translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .xl\:hover\:dq-translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .xl\:hover\:dq-translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .xl\:hover\:dq-translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .xl\:hover\:dq-translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .xl\:hover\:dq-translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .xl\:hover\:dq-translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .xl\:hover\:dq-translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .xl\:hover\:dq-translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .xl\:hover\:dq-translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }

  .xl\:hover\:dq-translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }

  .xl\:hover\:dq-translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }

  .xl\:hover\:dq-translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }

  .xl\:hover\:dq--translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .xl\:hover\:dq--translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }

  .xl\:hover\:dq--translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }

  .xl\:hover\:dq--translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }

  .xl\:hover\:dq--translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .xl\:hover\:dq--translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .xl\:hover\:dq--translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .xl\:hover\:dq--translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .xl\:hover\:dq--translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .xl\:hover\:dq--translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .xl\:hover\:dq--translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .xl\:hover\:dq--translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .xl\:hover\:dq--translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .xl\:hover\:dq--translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .xl\:hover\:dq--translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .xl\:hover\:dq--translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .xl\:hover\:dq--translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .xl\:hover\:dq--translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .xl\:hover\:dq--translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .xl\:hover\:dq--translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .xl\:hover\:dq--translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .xl\:hover\:dq--translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .xl\:hover\:dq--translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .xl\:hover\:dq--translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .xl\:hover\:dq--translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .xl\:hover\:dq--translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .xl\:hover\:dq--translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .xl\:hover\:dq--translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .xl\:hover\:dq--translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .xl\:hover\:dq--translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .xl\:hover\:dq--translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .xl\:hover\:dq--translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }

  .xl\:hover\:dq--translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }

  .xl\:hover\:dq--translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }

  .xl\:hover\:dq--translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }

  .xl\:hover\:dq-translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .xl\:hover\:dq-translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }

  .xl\:hover\:dq-translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }

  .xl\:hover\:dq-translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .xl\:hover\:dq-translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .xl\:hover\:dq-translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .xl\:hover\:dq-translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .xl\:hover\:dq--translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .xl\:hover\:dq--translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }

  .xl\:hover\:dq--translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }

  .xl\:hover\:dq--translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .xl\:hover\:dq--translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .xl\:hover\:dq--translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .xl\:hover\:dq--translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .xl\:focus\:dq-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .xl\:focus\:dq-translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }

  .xl\:focus\:dq-translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }

  .xl\:focus\:dq-translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }

  .xl\:focus\:dq-translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .xl\:focus\:dq-translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .xl\:focus\:dq-translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .xl\:focus\:dq-translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .xl\:focus\:dq-translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .xl\:focus\:dq-translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .xl\:focus\:dq-translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .xl\:focus\:dq-translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .xl\:focus\:dq-translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .xl\:focus\:dq-translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .xl\:focus\:dq-translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .xl\:focus\:dq-translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .xl\:focus\:dq-translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .xl\:focus\:dq-translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .xl\:focus\:dq-translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .xl\:focus\:dq-translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .xl\:focus\:dq-translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .xl\:focus\:dq-translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .xl\:focus\:dq-translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .xl\:focus\:dq-translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .xl\:focus\:dq-translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .xl\:focus\:dq-translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .xl\:focus\:dq-translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .xl\:focus\:dq-translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .xl\:focus\:dq-translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .xl\:focus\:dq-translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .xl\:focus\:dq-translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .xl\:focus\:dq-translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }

  .xl\:focus\:dq-translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }

  .xl\:focus\:dq-translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }

  .xl\:focus\:dq-translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }

  .xl\:focus\:dq--translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .xl\:focus\:dq--translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }

  .xl\:focus\:dq--translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }

  .xl\:focus\:dq--translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }

  .xl\:focus\:dq--translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .xl\:focus\:dq--translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .xl\:focus\:dq--translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .xl\:focus\:dq--translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .xl\:focus\:dq--translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .xl\:focus\:dq--translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .xl\:focus\:dq--translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .xl\:focus\:dq--translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .xl\:focus\:dq--translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .xl\:focus\:dq--translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .xl\:focus\:dq--translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .xl\:focus\:dq--translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .xl\:focus\:dq--translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .xl\:focus\:dq--translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .xl\:focus\:dq--translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .xl\:focus\:dq--translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .xl\:focus\:dq--translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .xl\:focus\:dq--translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .xl\:focus\:dq--translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .xl\:focus\:dq--translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .xl\:focus\:dq--translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .xl\:focus\:dq--translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .xl\:focus\:dq--translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .xl\:focus\:dq--translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .xl\:focus\:dq--translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .xl\:focus\:dq--translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .xl\:focus\:dq--translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .xl\:focus\:dq--translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }

  .xl\:focus\:dq--translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }

  .xl\:focus\:dq--translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }

  .xl\:focus\:dq--translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }

  .xl\:focus\:dq-translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .xl\:focus\:dq-translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }

  .xl\:focus\:dq-translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }

  .xl\:focus\:dq-translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .xl\:focus\:dq-translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .xl\:focus\:dq-translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .xl\:focus\:dq-translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .xl\:focus\:dq--translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .xl\:focus\:dq--translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }

  .xl\:focus\:dq--translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }

  .xl\:focus\:dq--translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .xl\:focus\:dq--translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .xl\:focus\:dq--translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .xl\:focus\:dq--translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .xl\:focus\:dq-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .xl\:focus\:dq-translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }

  .xl\:focus\:dq-translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }

  .xl\:focus\:dq-translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }

  .xl\:focus\:dq-translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .xl\:focus\:dq-translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .xl\:focus\:dq-translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .xl\:focus\:dq-translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .xl\:focus\:dq-translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .xl\:focus\:dq-translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .xl\:focus\:dq-translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .xl\:focus\:dq-translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .xl\:focus\:dq-translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .xl\:focus\:dq-translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .xl\:focus\:dq-translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .xl\:focus\:dq-translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .xl\:focus\:dq-translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .xl\:focus\:dq-translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .xl\:focus\:dq-translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .xl\:focus\:dq-translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .xl\:focus\:dq-translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .xl\:focus\:dq-translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .xl\:focus\:dq-translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .xl\:focus\:dq-translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .xl\:focus\:dq-translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .xl\:focus\:dq-translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .xl\:focus\:dq-translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .xl\:focus\:dq-translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .xl\:focus\:dq-translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .xl\:focus\:dq-translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .xl\:focus\:dq-translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .xl\:focus\:dq-translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }

  .xl\:focus\:dq-translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }

  .xl\:focus\:dq-translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }

  .xl\:focus\:dq-translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }

  .xl\:focus\:dq--translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .xl\:focus\:dq--translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }

  .xl\:focus\:dq--translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }

  .xl\:focus\:dq--translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }

  .xl\:focus\:dq--translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .xl\:focus\:dq--translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .xl\:focus\:dq--translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .xl\:focus\:dq--translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .xl\:focus\:dq--translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .xl\:focus\:dq--translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .xl\:focus\:dq--translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .xl\:focus\:dq--translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .xl\:focus\:dq--translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .xl\:focus\:dq--translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .xl\:focus\:dq--translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .xl\:focus\:dq--translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .xl\:focus\:dq--translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .xl\:focus\:dq--translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .xl\:focus\:dq--translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .xl\:focus\:dq--translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .xl\:focus\:dq--translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .xl\:focus\:dq--translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .xl\:focus\:dq--translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .xl\:focus\:dq--translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .xl\:focus\:dq--translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .xl\:focus\:dq--translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .xl\:focus\:dq--translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .xl\:focus\:dq--translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .xl\:focus\:dq--translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .xl\:focus\:dq--translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .xl\:focus\:dq--translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .xl\:focus\:dq--translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }

  .xl\:focus\:dq--translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }

  .xl\:focus\:dq--translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }

  .xl\:focus\:dq--translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }

  .xl\:focus\:dq-translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .xl\:focus\:dq-translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }

  .xl\:focus\:dq-translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }

  .xl\:focus\:dq-translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .xl\:focus\:dq-translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .xl\:focus\:dq-translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .xl\:focus\:dq-translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .xl\:focus\:dq--translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .xl\:focus\:dq--translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }

  .xl\:focus\:dq--translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }

  .xl\:focus\:dq--translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .xl\:focus\:dq--translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .xl\:focus\:dq--translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .xl\:focus\:dq--translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .xl\:dq-rotate-0 {
    --tw-rotate: 0deg;
  }

  .xl\:dq-rotate-1 {
    --tw-rotate: 1deg;
  }

  .xl\:dq-rotate-2 {
    --tw-rotate: 2deg;
  }

  .xl\:dq-rotate-3 {
    --tw-rotate: 3deg;
  }

  .xl\:dq-rotate-6 {
    --tw-rotate: 6deg;
  }

  .xl\:dq-rotate-12 {
    --tw-rotate: 12deg;
  }

  .xl\:dq-rotate-45 {
    --tw-rotate: 45deg;
  }

  .xl\:dq-rotate-90 {
    --tw-rotate: 90deg;
  }

  .xl\:dq-rotate-180 {
    --tw-rotate: 180deg;
  }

  .xl\:dq--rotate-180 {
    --tw-rotate: -180deg;
  }

  .xl\:dq--rotate-90 {
    --tw-rotate: -90deg;
  }

  .xl\:dq--rotate-45 {
    --tw-rotate: -45deg;
  }

  .xl\:dq--rotate-12 {
    --tw-rotate: -12deg;
  }

  .xl\:dq--rotate-6 {
    --tw-rotate: -6deg;
  }

  .xl\:dq--rotate-3 {
    --tw-rotate: -3deg;
  }

  .xl\:dq--rotate-2 {
    --tw-rotate: -2deg;
  }

  .xl\:dq--rotate-1 {
    --tw-rotate: -1deg;
  }

  .xl\:hover\:dq-rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .xl\:hover\:dq-rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .xl\:hover\:dq-rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .xl\:hover\:dq-rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .xl\:hover\:dq-rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .xl\:hover\:dq-rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .xl\:hover\:dq-rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .xl\:hover\:dq-rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .xl\:hover\:dq-rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .xl\:hover\:dq--rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .xl\:hover\:dq--rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .xl\:hover\:dq--rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .xl\:hover\:dq--rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .xl\:hover\:dq--rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .xl\:hover\:dq--rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .xl\:hover\:dq--rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .xl\:hover\:dq--rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .xl\:focus\:dq-rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .xl\:focus\:dq-rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .xl\:focus\:dq-rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .xl\:focus\:dq-rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .xl\:focus\:dq-rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .xl\:focus\:dq-rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .xl\:focus\:dq-rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .xl\:focus\:dq-rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .xl\:focus\:dq-rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .xl\:focus\:dq--rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .xl\:focus\:dq--rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .xl\:focus\:dq--rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .xl\:focus\:dq--rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .xl\:focus\:dq--rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .xl\:focus\:dq--rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .xl\:focus\:dq--rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .xl\:focus\:dq--rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .xl\:dq-skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .xl\:dq-skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .xl\:dq-skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .xl\:dq-skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .xl\:dq-skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .xl\:dq-skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .xl\:dq--skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .xl\:dq--skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .xl\:dq--skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .xl\:dq--skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .xl\:dq--skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .xl\:dq-skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .xl\:dq-skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .xl\:dq-skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .xl\:dq-skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .xl\:dq-skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .xl\:dq-skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .xl\:dq--skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .xl\:dq--skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .xl\:dq--skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .xl\:dq--skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .xl\:dq--skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .xl\:hover\:dq-skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .xl\:hover\:dq-skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .xl\:hover\:dq-skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .xl\:hover\:dq-skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .xl\:hover\:dq-skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .xl\:hover\:dq-skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .xl\:hover\:dq--skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .xl\:hover\:dq--skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .xl\:hover\:dq--skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .xl\:hover\:dq--skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .xl\:hover\:dq--skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .xl\:hover\:dq-skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .xl\:hover\:dq-skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .xl\:hover\:dq-skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .xl\:hover\:dq-skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .xl\:hover\:dq-skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .xl\:hover\:dq-skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .xl\:hover\:dq--skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .xl\:hover\:dq--skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .xl\:hover\:dq--skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .xl\:hover\:dq--skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .xl\:hover\:dq--skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .xl\:focus\:dq-skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .xl\:focus\:dq-skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .xl\:focus\:dq-skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .xl\:focus\:dq-skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .xl\:focus\:dq-skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .xl\:focus\:dq-skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .xl\:focus\:dq--skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .xl\:focus\:dq--skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .xl\:focus\:dq--skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .xl\:focus\:dq--skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .xl\:focus\:dq--skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .xl\:focus\:dq-skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .xl\:focus\:dq-skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .xl\:focus\:dq-skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .xl\:focus\:dq-skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .xl\:focus\:dq-skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .xl\:focus\:dq-skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .xl\:focus\:dq--skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .xl\:focus\:dq--skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .xl\:focus\:dq--skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .xl\:focus\:dq--skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .xl\:focus\:dq--skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .xl\:dq-scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:dq-scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:dq-scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:dq-scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:dq-scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:dq-scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:dq-scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:dq-scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:dq-scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:dq-scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:hover\:dq-scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:hover\:dq-scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:hover\:dq-scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:hover\:dq-scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:hover\:dq-scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:hover\:dq-scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:hover\:dq-scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:hover\:dq-scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:hover\:dq-scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:hover\:dq-scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:focus\:dq-scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:focus\:dq-scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:focus\:dq-scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:focus\:dq-scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:focus\:dq-scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:focus\:dq-scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:focus\:dq-scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:focus\:dq-scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:focus\:dq-scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:focus\:dq-scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:dq-scale-x-0 {
    --tw-scale-x: 0;
  }

  .xl\:dq-scale-x-50 {
    --tw-scale-x: .5;
  }

  .xl\:dq-scale-x-75 {
    --tw-scale-x: .75;
  }

  .xl\:dq-scale-x-90 {
    --tw-scale-x: .9;
  }

  .xl\:dq-scale-x-95 {
    --tw-scale-x: .95;
  }

  .xl\:dq-scale-x-100 {
    --tw-scale-x: 1;
  }

  .xl\:dq-scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .xl\:dq-scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .xl\:dq-scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .xl\:dq-scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .xl\:dq-scale-y-0 {
    --tw-scale-y: 0;
  }

  .xl\:dq-scale-y-50 {
    --tw-scale-y: .5;
  }

  .xl\:dq-scale-y-75 {
    --tw-scale-y: .75;
  }

  .xl\:dq-scale-y-90 {
    --tw-scale-y: .9;
  }

  .xl\:dq-scale-y-95 {
    --tw-scale-y: .95;
  }

  .xl\:dq-scale-y-100 {
    --tw-scale-y: 1;
  }

  .xl\:dq-scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .xl\:dq-scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .xl\:dq-scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .xl\:dq-scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .xl\:hover\:dq-scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .xl\:hover\:dq-scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .xl\:hover\:dq-scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .xl\:hover\:dq-scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .xl\:hover\:dq-scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .xl\:hover\:dq-scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .xl\:hover\:dq-scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .xl\:hover\:dq-scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .xl\:hover\:dq-scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .xl\:hover\:dq-scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .xl\:hover\:dq-scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .xl\:hover\:dq-scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .xl\:hover\:dq-scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .xl\:hover\:dq-scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .xl\:hover\:dq-scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .xl\:hover\:dq-scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .xl\:hover\:dq-scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .xl\:hover\:dq-scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .xl\:hover\:dq-scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .xl\:hover\:dq-scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .xl\:focus\:dq-scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .xl\:focus\:dq-scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .xl\:focus\:dq-scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .xl\:focus\:dq-scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .xl\:focus\:dq-scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .xl\:focus\:dq-scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .xl\:focus\:dq-scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .xl\:focus\:dq-scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .xl\:focus\:dq-scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .xl\:focus\:dq-scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .xl\:focus\:dq-scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .xl\:focus\:dq-scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .xl\:focus\:dq-scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .xl\:focus\:dq-scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .xl\:focus\:dq-scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .xl\:focus\:dq-scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .xl\:focus\:dq-scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .xl\:focus\:dq-scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .xl\:focus\:dq-scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .xl\:focus\:dq-scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .xl\:dq-animate-none {
    animation: none;
  }

  .xl\:dq-animate-spin {
    animation: dq-spin 1s linear infinite;
  }

  .xl\:dq-animate-ping {
    animation: dq-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .xl\:dq-animate-pulse {
    animation: dq-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .xl\:dq-animate-bounce {
    animation: dq-bounce 1s infinite;
  }

  .xl\:dq-cursor-auto {
    cursor: auto;
  }

  .xl\:dq-cursor-default {
    cursor: default;
  }

  .xl\:dq-cursor-pointer {
    cursor: pointer;
  }

  .xl\:dq-cursor-wait {
    cursor: wait;
  }

  .xl\:dq-cursor-text {
    cursor: text;
  }

  .xl\:dq-cursor-move {
    cursor: move;
  }

  .xl\:dq-cursor-help {
    cursor: help;
  }

  .xl\:dq-cursor-not-allowed {
    cursor: not-allowed;
  }

  .xl\:dq-select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .xl\:dq-select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .xl\:dq-select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .xl\:dq-select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }

  .xl\:dq-resize-none {
    resize: none;
  }

  .xl\:dq-resize-y {
    resize: vertical;
  }

  .xl\:dq-resize-x {
    resize: horizontal;
  }

  .xl\:dq-resize {
    resize: both;
  }

  .xl\:dq-list-inside {
    list-style-position: inside;
  }

  .xl\:dq-list-outside {
    list-style-position: outside;
  }

  .xl\:dq-list-none {
    list-style-type: none;
  }

  .xl\:dq-list-disc {
    list-style-type: disc;
  }

  .xl\:dq-list-decimal {
    list-style-type: decimal;
  }

  .xl\:dq-appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  .xl\:dq-auto-cols-auto {
    grid-auto-columns: auto;
  }

  .xl\:dq-auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .xl\:dq-auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .xl\:dq-auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .xl\:dq-grid-flow-row {
    grid-auto-flow: row;
  }

  .xl\:dq-grid-flow-col {
    grid-auto-flow: column;
  }

  .xl\:dq-grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .xl\:dq-grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .xl\:dq-auto-rows-auto {
    grid-auto-rows: auto;
  }

  .xl\:dq-auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .xl\:dq-auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .xl\:dq-auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .xl\:dq-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:dq-grid-cols-none {
    grid-template-columns: none;
  }

  .xl\:dq-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .xl\:dq-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xl\:dq-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xl\:dq-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xl\:dq-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xl\:dq-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xl\:dq-grid-rows-none {
    grid-template-rows: none;
  }

  .xl\:dq-flex-row {
    flex-direction: row;
  }

  .xl\:dq-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .xl\:dq-flex-col {
    flex-direction: column;
  }

  .xl\:dq-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .xl\:dq-flex-wrap {
    flex-wrap: wrap;
  }

  .xl\:dq-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .xl\:dq-flex-nowrap {
    flex-wrap: nowrap;
  }

  .xl\:dq-place-content-center {
    place-content: center;
  }

  .xl\:dq-place-content-start {
    place-content: start;
  }

  .xl\:dq-place-content-end {
    place-content: end;
  }

  .xl\:dq-place-content-between {
    place-content: space-between;
  }

  .xl\:dq-place-content-around {
    place-content: space-around;
  }

  .xl\:dq-place-content-evenly {
    place-content: space-evenly;
  }

  .xl\:dq-place-content-stretch {
    place-content: stretch;
  }

  .xl\:dq-place-items-start {
    place-items: start;
  }

  .xl\:dq-place-items-end {
    place-items: end;
  }

  .xl\:dq-place-items-center {
    place-items: center;
  }

  .xl\:dq-place-items-stretch {
    place-items: stretch;
  }

  .xl\:dq-content-center {
    align-content: center;
  }

  .xl\:dq-content-start {
    align-content: flex-start;
  }

  .xl\:dq-content-end {
    align-content: flex-end;
  }

  .xl\:dq-content-between {
    align-content: space-between;
  }

  .xl\:dq-content-around {
    align-content: space-around;
  }

  .xl\:dq-content-evenly {
    align-content: space-evenly;
  }

  .xl\:dq-items-start {
    align-items: flex-start;
  }

  .xl\:dq-items-end {
    align-items: flex-end;
  }

  .xl\:dq-items-center {
    align-items: center;
  }

  .xl\:dq-items-baseline {
    align-items: baseline;
  }

  .xl\:dq-items-stretch {
    align-items: stretch;
  }

  .xl\:dq-justify-start {
    justify-content: flex-start;
  }

  .xl\:dq-justify-end {
    justify-content: flex-end;
  }

  .xl\:dq-justify-center {
    justify-content: center;
  }

  .xl\:dq-justify-between {
    justify-content: space-between;
  }

  .xl\:dq-justify-around {
    justify-content: space-around;
  }

  .xl\:dq-justify-evenly {
    justify-content: space-evenly;
  }

  .xl\:dq-justify-items-start {
    justify-items: start;
  }

  .xl\:dq-justify-items-end {
    justify-items: end;
  }

  .xl\:dq-justify-items-center {
    justify-items: center;
  }

  .xl\:dq-justify-items-stretch {
    justify-items: stretch;
  }

  .xl\:dq-gap-0 {
    gap: 0px;
  }

  .xl\:dq-gap-1 {
    gap: 0.25rem;
  }

  .xl\:dq-gap-2 {
    gap: 0.5rem;
  }

  .xl\:dq-gap-3 {
    gap: 0.75rem;
  }

  .xl\:dq-gap-4 {
    gap: 1rem;
  }

  .xl\:dq-gap-5 {
    gap: 1.25rem;
  }

  .xl\:dq-gap-6 {
    gap: 1.5rem;
  }

  .xl\:dq-gap-7 {
    gap: 1.75rem;
  }

  .xl\:dq-gap-8 {
    gap: 2rem;
  }

  .xl\:dq-gap-9 {
    gap: 2.25rem;
  }

  .xl\:dq-gap-10 {
    gap: 2.5rem;
  }

  .xl\:dq-gap-11 {
    gap: 2.75rem;
  }

  .xl\:dq-gap-12 {
    gap: 3rem;
  }

  .xl\:dq-gap-14 {
    gap: 3.5rem;
  }

  .xl\:dq-gap-16 {
    gap: 4rem;
  }

  .xl\:dq-gap-20 {
    gap: 5rem;
  }

  .xl\:dq-gap-24 {
    gap: 6rem;
  }

  .xl\:dq-gap-28 {
    gap: 7rem;
  }

  .xl\:dq-gap-32 {
    gap: 8rem;
  }

  .xl\:dq-gap-36 {
    gap: 9rem;
  }

  .xl\:dq-gap-40 {
    gap: 10rem;
  }

  .xl\:dq-gap-44 {
    gap: 11rem;
  }

  .xl\:dq-gap-48 {
    gap: 12rem;
  }

  .xl\:dq-gap-52 {
    gap: 13rem;
  }

  .xl\:dq-gap-56 {
    gap: 14rem;
  }

  .xl\:dq-gap-60 {
    gap: 15rem;
  }

  .xl\:dq-gap-64 {
    gap: 16rem;
  }

  .xl\:dq-gap-72 {
    gap: 18rem;
  }

  .xl\:dq-gap-80 {
    gap: 20rem;
  }

  .xl\:dq-gap-96 {
    gap: 24rem;
  }

  .xl\:dq-gap-px {
    gap: 1px;
  }

  .xl\:dq-gap-0\.5 {
    gap: 0.125rem;
  }

  .xl\:dq-gap-1\.5 {
    gap: 0.375rem;
  }

  .xl\:dq-gap-2\.5 {
    gap: 0.625rem;
  }

  .xl\:dq-gap-3\.5 {
    gap: 0.875rem;
  }

  .xl\:dq-gap-x-0 {
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .xl\:dq-gap-x-1 {
    -webkit-column-gap: 0.25rem;
            column-gap: 0.25rem;
  }

  .xl\:dq-gap-x-2 {
    -webkit-column-gap: 0.5rem;
            column-gap: 0.5rem;
  }

  .xl\:dq-gap-x-3 {
    -webkit-column-gap: 0.75rem;
            column-gap: 0.75rem;
  }

  .xl\:dq-gap-x-4 {
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
  }

  .xl\:dq-gap-x-5 {
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
  }

  .xl\:dq-gap-x-6 {
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
  }

  .xl\:dq-gap-x-7 {
    -webkit-column-gap: 1.75rem;
            column-gap: 1.75rem;
  }

  .xl\:dq-gap-x-8 {
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }

  .xl\:dq-gap-x-9 {
    -webkit-column-gap: 2.25rem;
            column-gap: 2.25rem;
  }

  .xl\:dq-gap-x-10 {
    -webkit-column-gap: 2.5rem;
            column-gap: 2.5rem;
  }

  .xl\:dq-gap-x-11 {
    -webkit-column-gap: 2.75rem;
            column-gap: 2.75rem;
  }

  .xl\:dq-gap-x-12 {
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }

  .xl\:dq-gap-x-14 {
    -webkit-column-gap: 3.5rem;
            column-gap: 3.5rem;
  }

  .xl\:dq-gap-x-16 {
    -webkit-column-gap: 4rem;
            column-gap: 4rem;
  }

  .xl\:dq-gap-x-20 {
    -webkit-column-gap: 5rem;
            column-gap: 5rem;
  }

  .xl\:dq-gap-x-24 {
    -webkit-column-gap: 6rem;
            column-gap: 6rem;
  }

  .xl\:dq-gap-x-28 {
    -webkit-column-gap: 7rem;
            column-gap: 7rem;
  }

  .xl\:dq-gap-x-32 {
    -webkit-column-gap: 8rem;
            column-gap: 8rem;
  }

  .xl\:dq-gap-x-36 {
    -webkit-column-gap: 9rem;
            column-gap: 9rem;
  }

  .xl\:dq-gap-x-40 {
    -webkit-column-gap: 10rem;
            column-gap: 10rem;
  }

  .xl\:dq-gap-x-44 {
    -webkit-column-gap: 11rem;
            column-gap: 11rem;
  }

  .xl\:dq-gap-x-48 {
    -webkit-column-gap: 12rem;
            column-gap: 12rem;
  }

  .xl\:dq-gap-x-52 {
    -webkit-column-gap: 13rem;
            column-gap: 13rem;
  }

  .xl\:dq-gap-x-56 {
    -webkit-column-gap: 14rem;
            column-gap: 14rem;
  }

  .xl\:dq-gap-x-60 {
    -webkit-column-gap: 15rem;
            column-gap: 15rem;
  }

  .xl\:dq-gap-x-64 {
    -webkit-column-gap: 16rem;
            column-gap: 16rem;
  }

  .xl\:dq-gap-x-72 {
    -webkit-column-gap: 18rem;
            column-gap: 18rem;
  }

  .xl\:dq-gap-x-80 {
    -webkit-column-gap: 20rem;
            column-gap: 20rem;
  }

  .xl\:dq-gap-x-96 {
    -webkit-column-gap: 24rem;
            column-gap: 24rem;
  }

  .xl\:dq-gap-x-px {
    -webkit-column-gap: 1px;
            column-gap: 1px;
  }

  .xl\:dq-gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
            column-gap: 0.125rem;
  }

  .xl\:dq-gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
            column-gap: 0.375rem;
  }

  .xl\:dq-gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
            column-gap: 0.625rem;
  }

  .xl\:dq-gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
            column-gap: 0.875rem;
  }

  .xl\:dq-gap-y-0 {
    row-gap: 0px;
  }

  .xl\:dq-gap-y-1 {
    row-gap: 0.25rem;
  }

  .xl\:dq-gap-y-2 {
    row-gap: 0.5rem;
  }

  .xl\:dq-gap-y-3 {
    row-gap: 0.75rem;
  }

  .xl\:dq-gap-y-4 {
    row-gap: 1rem;
  }

  .xl\:dq-gap-y-5 {
    row-gap: 1.25rem;
  }

  .xl\:dq-gap-y-6 {
    row-gap: 1.5rem;
  }

  .xl\:dq-gap-y-7 {
    row-gap: 1.75rem;
  }

  .xl\:dq-gap-y-8 {
    row-gap: 2rem;
  }

  .xl\:dq-gap-y-9 {
    row-gap: 2.25rem;
  }

  .xl\:dq-gap-y-10 {
    row-gap: 2.5rem;
  }

  .xl\:dq-gap-y-11 {
    row-gap: 2.75rem;
  }

  .xl\:dq-gap-y-12 {
    row-gap: 3rem;
  }

  .xl\:dq-gap-y-14 {
    row-gap: 3.5rem;
  }

  .xl\:dq-gap-y-16 {
    row-gap: 4rem;
  }

  .xl\:dq-gap-y-20 {
    row-gap: 5rem;
  }

  .xl\:dq-gap-y-24 {
    row-gap: 6rem;
  }

  .xl\:dq-gap-y-28 {
    row-gap: 7rem;
  }

  .xl\:dq-gap-y-32 {
    row-gap: 8rem;
  }

  .xl\:dq-gap-y-36 {
    row-gap: 9rem;
  }

  .xl\:dq-gap-y-40 {
    row-gap: 10rem;
  }

  .xl\:dq-gap-y-44 {
    row-gap: 11rem;
  }

  .xl\:dq-gap-y-48 {
    row-gap: 12rem;
  }

  .xl\:dq-gap-y-52 {
    row-gap: 13rem;
  }

  .xl\:dq-gap-y-56 {
    row-gap: 14rem;
  }

  .xl\:dq-gap-y-60 {
    row-gap: 15rem;
  }

  .xl\:dq-gap-y-64 {
    row-gap: 16rem;
  }

  .xl\:dq-gap-y-72 {
    row-gap: 18rem;
  }

  .xl\:dq-gap-y-80 {
    row-gap: 20rem;
  }

  .xl\:dq-gap-y-96 {
    row-gap: 24rem;
  }

  .xl\:dq-gap-y-px {
    row-gap: 1px;
  }

  .xl\:dq-gap-y-0\.5 {
    row-gap: 0.125rem;
  }

  .xl\:dq-gap-y-1\.5 {
    row-gap: 0.375rem;
  }

  .xl\:dq-gap-y-2\.5 {
    row-gap: 0.625rem;
  }

  .xl\:dq-gap-y-3\.5 {
    row-gap: 0.875rem;
  }

  .xl\:dq-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq--space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:dq-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }

  .xl\:dq--space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }

  .xl\:dq-space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .xl\:dq-space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .xl\:dq-divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:dq-divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:dq-divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:dq-divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:dq-divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:dq-divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .xl\:dq-divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .xl\:dq-divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .xl\:dq-divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .xl\:dq-divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .xl\:dq-divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .xl\:dq-divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .xl\:dq-divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .xl\:dq-divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .xl\:dq-divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .xl\:dq-divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .xl\:dq-divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .xl\:dq-divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }

  .xl\:dq-divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .xl\:dq-divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .xl\:dq-divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .xl\:dq-divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }

  .xl\:dq-divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }

  .xl\:dq-divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }

  .xl\:dq-divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }

  .xl\:dq-divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }

  .xl\:dq-divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }

  .xl\:dq-divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }

  .xl\:dq-divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }

  .xl\:dq-divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }

  .xl\:dq-divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }

  .xl\:dq-divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }

  .xl\:dq-divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }

  .xl\:dq-divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }

  .xl\:dq-divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .xl\:dq-place-self-auto {
    place-self: auto;
  }

  .xl\:dq-place-self-start {
    place-self: start;
  }

  .xl\:dq-place-self-end {
    place-self: end;
  }

  .xl\:dq-place-self-center {
    place-self: center;
  }

  .xl\:dq-place-self-stretch {
    place-self: stretch;
  }

  .xl\:dq-self-auto {
    align-self: auto;
  }

  .xl\:dq-self-start {
    align-self: flex-start;
  }

  .xl\:dq-self-end {
    align-self: flex-end;
  }

  .xl\:dq-self-center {
    align-self: center;
  }

  .xl\:dq-self-stretch {
    align-self: stretch;
  }

  .xl\:dq-self-baseline {
    align-self: baseline;
  }

  .xl\:dq-justify-self-auto {
    justify-self: auto;
  }

  .xl\:dq-justify-self-start {
    justify-self: start;
  }

  .xl\:dq-justify-self-end {
    justify-self: end;
  }

  .xl\:dq-justify-self-center {
    justify-self: center;
  }

  .xl\:dq-justify-self-stretch {
    justify-self: stretch;
  }

  .xl\:dq-overflow-auto {
    overflow: auto;
  }

  .xl\:dq-overflow-hidden {
    overflow: hidden;
  }

  .xl\:dq-overflow-visible {
    overflow: visible;
  }

  .xl\:dq-overflow-scroll {
    overflow: scroll;
  }

  .xl\:dq-overflow-x-auto {
    overflow-x: auto;
  }

  .xl\:dq-overflow-y-auto {
    overflow-y: auto;
  }

  .xl\:dq-overflow-x-hidden {
    overflow-x: hidden;
  }

  .xl\:dq-overflow-y-hidden {
    overflow-y: hidden;
  }

  .xl\:dq-overflow-x-visible {
    overflow-x: visible;
  }

  .xl\:dq-overflow-y-visible {
    overflow-y: visible;
  }

  .xl\:dq-overflow-x-scroll {
    overflow-x: scroll;
  }

  .xl\:dq-overflow-y-scroll {
    overflow-y: scroll;
  }

  .xl\:dq-overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
  }

  .xl\:dq-overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }

  .xl\:dq-overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
  }

  .xl\:dq-overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .xl\:dq-overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .xl\:dq-overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .xl\:dq-overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .xl\:dq-overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .xl\:dq-overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .xl\:dq-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .xl\:dq-overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .xl\:dq-overflow-clip {
    text-overflow: clip;
  }

  .xl\:dq-whitespace-normal {
    white-space: normal;
  }

  .xl\:dq-whitespace-nowrap {
    white-space: nowrap;
  }

  .xl\:dq-whitespace-pre {
    white-space: pre;
  }

  .xl\:dq-whitespace-pre-line {
    white-space: pre-line;
  }

  .xl\:dq-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .xl\:dq-break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .xl\:dq-break-words {
    overflow-wrap: break-word;
  }

  .xl\:dq-break-all {
    word-break: break-all;
  }

  .xl\:dq-rounded-none {
    border-radius: 0px;
  }

  .xl\:dq-rounded-sm {
    border-radius: 0.125rem;
  }

  .xl\:dq-rounded {
    border-radius: 0.25rem;
  }

  .xl\:dq-rounded-md {
    border-radius: 0.375rem;
  }

  .xl\:dq-rounded-lg {
    border-radius: 0.5rem;
  }

  .xl\:dq-rounded-xl {
    border-radius: 0.75rem;
  }

  .xl\:dq-rounded-2xl {
    border-radius: 1rem;
  }

  .xl\:dq-rounded-3xl {
    border-radius: 1.5rem;
  }

  .xl\:dq-rounded-full {
    border-radius: 9999px;
  }

  .xl\:dq-rounded-t-none {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .xl\:dq-rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .xl\:dq-rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .xl\:dq-rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .xl\:dq-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .xl\:dq-rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .xl\:dq-rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .xl\:dq-rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .xl\:dq-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .xl\:dq-rounded-r-none {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .xl\:dq-rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .xl\:dq-rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .xl\:dq-rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .xl\:dq-rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .xl\:dq-rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .xl\:dq-rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .xl\:dq-rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:dq-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .xl\:dq-rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .xl\:dq-rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:dq-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:dq-rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:dq-rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:dq-rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:dq-rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:dq-rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:dq-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:dq-rounded-l-none {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .xl\:dq-rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:dq-rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:dq-rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:dq-rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:dq-rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:dq-rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:dq-rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:dq-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:dq-rounded-tl-none {
    border-top-left-radius: 0px;
  }

  .xl\:dq-rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .xl\:dq-rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .xl\:dq-rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .xl\:dq-rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .xl\:dq-rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .xl\:dq-rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .xl\:dq-rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .xl\:dq-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .xl\:dq-rounded-tr-none {
    border-top-right-radius: 0px;
  }

  .xl\:dq-rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .xl\:dq-rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .xl\:dq-rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .xl\:dq-rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .xl\:dq-rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .xl\:dq-rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .xl\:dq-rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .xl\:dq-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .xl\:dq-rounded-br-none {
    border-bottom-right-radius: 0px;
  }

  .xl\:dq-rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .xl\:dq-rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .xl\:dq-rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .xl\:dq-rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .xl\:dq-rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .xl\:dq-rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .xl\:dq-rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:dq-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .xl\:dq-rounded-bl-none {
    border-bottom-left-radius: 0px;
  }

  .xl\:dq-rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:dq-rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:dq-rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:dq-rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:dq-rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:dq-rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .xl\:dq-rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:dq-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .xl\:dq-border-0 {
    border-width: 0px;
  }

  .xl\:dq-border-2 {
    border-width: 2px;
  }

  .xl\:dq-border-4 {
    border-width: 4px;
  }

  .xl\:dq-border-8 {
    border-width: 8px;
  }

  .xl\:dq-border {
    border-width: 1px;
  }

  .xl\:dq-border-t-0 {
    border-top-width: 0px;
  }

  .xl\:dq-border-t-2 {
    border-top-width: 2px;
  }

  .xl\:dq-border-t-4 {
    border-top-width: 4px;
  }

  .xl\:dq-border-t-8 {
    border-top-width: 8px;
  }

  .xl\:dq-border-t {
    border-top-width: 1px;
  }

  .xl\:dq-border-r-0 {
    border-right-width: 0px;
  }

  .xl\:dq-border-r-2 {
    border-right-width: 2px;
  }

  .xl\:dq-border-r-4 {
    border-right-width: 4px;
  }

  .xl\:dq-border-r-8 {
    border-right-width: 8px;
  }

  .xl\:dq-border-r {
    border-right-width: 1px;
  }

  .xl\:dq-border-b-0 {
    border-bottom-width: 0px;
  }

  .xl\:dq-border-b-2 {
    border-bottom-width: 2px;
  }

  .xl\:dq-border-b-4 {
    border-bottom-width: 4px;
  }

  .xl\:dq-border-b-8 {
    border-bottom-width: 8px;
  }

  .xl\:dq-border-b {
    border-bottom-width: 1px;
  }

  .xl\:dq-border-l-0 {
    border-left-width: 0px;
  }

  .xl\:dq-border-l-2 {
    border-left-width: 2px;
  }

  .xl\:dq-border-l-4 {
    border-left-width: 4px;
  }

  .xl\:dq-border-l-8 {
    border-left-width: 8px;
  }

  .xl\:dq-border-l {
    border-left-width: 1px;
  }

  .xl\:dq-border-solid {
    border-style: solid;
  }

  .xl\:dq-border-dashed {
    border-style: dashed;
  }

  .xl\:dq-border-dotted {
    border-style: dotted;
  }

  .xl\:dq-border-double {
    border-style: double;
  }

  .xl\:dq-border-none {
    border-style: none;
  }

  .xl\:dq-border-transparent {
    border-color: transparent;
  }

  .xl\:dq-border-current {
    border-color: currentColor;
  }

  .xl\:dq-border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xl\:dq-border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:dq-border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xl\:dq-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xl\:dq-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xl\:dq-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xl\:dq-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xl\:dq-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xl\:dq-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xl\:dq-border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xl\:dq-border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xl\:dq-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xl\:dq-border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .xl\:dq-border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .xl\:dq-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .xl\:dq-border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .xl\:dq-border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .xl\:dq-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .xl\:dq-border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .xl\:dq-border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .xl\:dq-border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .xl\:dq-border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .xl\:dq-border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xl\:dq-border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xl\:dq-border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xl\:dq-border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xl\:dq-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xl\:dq-border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xl\:dq-border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xl\:dq-border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xl\:dq-border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xl\:dq-border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xl\:dq-border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xl\:dq-border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xl\:dq-border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xl\:dq-border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xl\:dq-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xl\:dq-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xl\:dq-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xl\:dq-border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xl\:dq-border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xl\:dq-border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xl\:dq-border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xl\:dq-border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xl\:dq-border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xl\:dq-border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xl\:dq-border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xl\:dq-border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xl\:dq-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xl\:dq-border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xl\:dq-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xl\:dq-border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xl\:dq-border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xl\:dq-border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xl\:dq-border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xl\:dq-border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xl\:dq-border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xl\:dq-border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xl\:dq-border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xl\:dq-border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xl\:dq-border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xl\:dq-border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xl\:dq-border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xl\:dq-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xl\:dq-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xl\:dq-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xl\:dq-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xl\:dq-border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xl\:dq-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xl\:dq-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xl\:dq-border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xl\:dq-border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xl\:dq-border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xl\:dq-border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xl\:dq-border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xl\:dq-border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xl\:dq-border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xl\:dq-border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xl\:dq-border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xl\:dq-border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xl\:dq-border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xl\:dq-border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-transparent {
    border-color: transparent;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-current {
    border-color: currentColor;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-transparent:focus-within {
    border-color: transparent;
  }

  .xl\:focus-within\:dq-border-current:focus-within {
    border-color: currentColor;
  }

  .xl\:focus-within\:dq-border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xl\:focus-within\:dq-border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-transparent:hover {
    border-color: transparent;
  }

  .xl\:hover\:dq-border-current:hover {
    border-color: currentColor;
  }

  .xl\:hover\:dq-border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xl\:hover\:dq-border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-transparent:focus {
    border-color: transparent;
  }

  .xl\:focus\:dq-border-current:focus {
    border-color: currentColor;
  }

  .xl\:focus\:dq-border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xl\:focus\:dq-border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xl\:dq-border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .xl\:dq-border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .xl\:dq-border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .xl\:dq-border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .xl\:dq-border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .xl\:dq-border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .xl\:dq-border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .xl\:dq-border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .xl\:dq-border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .xl\:dq-border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .xl\:dq-border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .xl\:dq-border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .xl\:dq-border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .xl\:dq-border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .xl\:dq-border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .dq-group:hover .xl\:group-hover\:dq-border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .xl\:focus-within\:dq-border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .xl\:focus-within\:dq-border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }

  .xl\:focus-within\:dq-border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }

  .xl\:focus-within\:dq-border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }

  .xl\:focus-within\:dq-border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }

  .xl\:focus-within\:dq-border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }

  .xl\:focus-within\:dq-border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }

  .xl\:focus-within\:dq-border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }

  .xl\:focus-within\:dq-border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }

  .xl\:focus-within\:dq-border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }

  .xl\:focus-within\:dq-border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }

  .xl\:focus-within\:dq-border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }

  .xl\:focus-within\:dq-border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }

  .xl\:focus-within\:dq-border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }

  .xl\:focus-within\:dq-border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .xl\:hover\:dq-border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .xl\:hover\:dq-border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }

  .xl\:hover\:dq-border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }

  .xl\:hover\:dq-border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }

  .xl\:hover\:dq-border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }

  .xl\:hover\:dq-border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }

  .xl\:hover\:dq-border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }

  .xl\:hover\:dq-border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }

  .xl\:hover\:dq-border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }

  .xl\:hover\:dq-border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }

  .xl\:hover\:dq-border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }

  .xl\:hover\:dq-border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }

  .xl\:hover\:dq-border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }

  .xl\:hover\:dq-border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }

  .xl\:hover\:dq-border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .xl\:focus\:dq-border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .xl\:focus\:dq-border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }

  .xl\:focus\:dq-border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }

  .xl\:focus\:dq-border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }

  .xl\:focus\:dq-border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }

  .xl\:focus\:dq-border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }

  .xl\:focus\:dq-border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }

  .xl\:focus\:dq-border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }

  .xl\:focus\:dq-border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }

  .xl\:focus\:dq-border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }

  .xl\:focus\:dq-border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }

  .xl\:focus\:dq-border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }

  .xl\:focus\:dq-border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }

  .xl\:focus\:dq-border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }

  .xl\:focus\:dq-border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .xl\:dq-bg-transparent {
    background-color: transparent;
  }

  .xl\:dq-bg-current {
    background-color: currentColor;
  }

  .xl\:dq-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-transparent {
    background-color: transparent;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-current {
    background-color: currentColor;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-transparent:focus-within {
    background-color: transparent;
  }

  .xl\:focus-within\:dq-bg-current:focus-within {
    background-color: currentColor;
  }

  .xl\:focus-within\:dq-bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:dq-bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-transparent:hover {
    background-color: transparent;
  }

  .xl\:hover\:dq-bg-current:hover {
    background-color: currentColor;
  }

  .xl\:hover\:dq-bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xl\:hover\:dq-bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-transparent:focus {
    background-color: transparent;
  }

  .xl\:focus\:dq-bg-current:focus {
    background-color: currentColor;
  }

  .xl\:focus\:dq-bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xl\:focus\:dq-bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xl\:dq-bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .xl\:dq-bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .xl\:dq-bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .xl\:dq-bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .xl\:dq-bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .xl\:dq-bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .xl\:dq-bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .xl\:dq-bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .xl\:dq-bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .xl\:dq-bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .xl\:dq-bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .xl\:dq-bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .xl\:dq-bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .xl\:dq-bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .xl\:dq-bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .dq-group:hover .xl\:group-hover\:dq-bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .xl\:focus-within\:dq-bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .xl\:focus-within\:dq-bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }

  .xl\:focus-within\:dq-bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }

  .xl\:focus-within\:dq-bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }

  .xl\:focus-within\:dq-bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }

  .xl\:focus-within\:dq-bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }

  .xl\:focus-within\:dq-bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }

  .xl\:focus-within\:dq-bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }

  .xl\:focus-within\:dq-bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }

  .xl\:focus-within\:dq-bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }

  .xl\:focus-within\:dq-bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }

  .xl\:focus-within\:dq-bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }

  .xl\:focus-within\:dq-bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }

  .xl\:focus-within\:dq-bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }

  .xl\:focus-within\:dq-bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .xl\:hover\:dq-bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .xl\:hover\:dq-bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }

  .xl\:hover\:dq-bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }

  .xl\:hover\:dq-bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }

  .xl\:hover\:dq-bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }

  .xl\:hover\:dq-bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }

  .xl\:hover\:dq-bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }

  .xl\:hover\:dq-bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }

  .xl\:hover\:dq-bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }

  .xl\:hover\:dq-bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }

  .xl\:hover\:dq-bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }

  .xl\:hover\:dq-bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }

  .xl\:hover\:dq-bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }

  .xl\:hover\:dq-bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }

  .xl\:hover\:dq-bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .xl\:focus\:dq-bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .xl\:focus\:dq-bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }

  .xl\:focus\:dq-bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }

  .xl\:focus\:dq-bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }

  .xl\:focus\:dq-bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }

  .xl\:focus\:dq-bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }

  .xl\:focus\:dq-bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }

  .xl\:focus\:dq-bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }

  .xl\:focus\:dq-bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }

  .xl\:focus\:dq-bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }

  .xl\:focus\:dq-bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }

  .xl\:focus\:dq-bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }

  .xl\:focus\:dq-bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }

  .xl\:focus\:dq-bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }

  .xl\:focus\:dq-bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .xl\:dq-bg-none {
    background-image: none;
  }

  .xl\:dq-bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .xl\:dq-bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .xl\:dq-bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .xl\:dq-bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .xl\:dq-bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .xl\:dq-bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .xl\:dq-bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .xl\:dq-bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .xl\:dq-from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:dq-from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:dq-from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:dq-from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:dq-from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .xl\:dq-from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .xl\:dq-from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .xl\:dq-from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .xl\:dq-from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .xl\:dq-from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .xl\:dq-from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .xl\:dq-from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .xl\:dq-from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .xl\:dq-from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .xl\:dq-from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .xl\:dq-from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .xl\:dq-from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .xl\:dq-from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .xl\:dq-from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .xl\:dq-from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .xl\:dq-from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .xl\:dq-from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .xl\:dq-from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .xl\:dq-from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .xl\:dq-from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .xl\:dq-from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .xl\:dq-from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .xl\:dq-from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .xl\:dq-from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .xl\:dq-from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .xl\:dq-from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .xl\:dq-from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .xl\:dq-from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .xl\:dq-from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .xl\:dq-from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .xl\:dq-from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .xl\:dq-from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .xl\:dq-from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .xl\:dq-from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .xl\:dq-from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .xl\:dq-from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .xl\:dq-from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .xl\:dq-from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .xl\:dq-from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .xl\:dq-from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .xl\:dq-from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .xl\:dq-from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .xl\:dq-from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .xl\:dq-from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .xl\:dq-from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .xl\:dq-from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .xl\:dq-from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .xl\:dq-from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .xl\:dq-from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .xl\:dq-from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .xl\:dq-from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .xl\:dq-from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .xl\:dq-from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .xl\:dq-from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .xl\:dq-from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .xl\:dq-from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .xl\:dq-from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .xl\:dq-from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .xl\:dq-from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .xl\:dq-from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .xl\:dq-from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .xl\:dq-from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .xl\:dq-from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .xl\:dq-from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .xl\:dq-from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .xl\:dq-from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .xl\:dq-from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .xl\:dq-from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .xl\:dq-from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .xl\:dq-from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .xl\:dq-from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .xl\:dq-from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .xl\:dq-from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .xl\:dq-from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .xl\:dq-from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .xl\:dq-from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .xl\:dq-from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .xl\:dq-from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .xl\:dq-from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .xl\:hover\:dq-from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:dq-from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:dq-from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:dq-from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:dq-from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .xl\:hover\:dq-from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .xl\:hover\:dq-from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .xl\:hover\:dq-from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .xl\:hover\:dq-from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .xl\:hover\:dq-from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .xl\:hover\:dq-from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .xl\:hover\:dq-from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .xl\:hover\:dq-from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .xl\:hover\:dq-from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .xl\:hover\:dq-from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .xl\:hover\:dq-from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .xl\:hover\:dq-from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .xl\:hover\:dq-from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .xl\:hover\:dq-from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .xl\:hover\:dq-from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .xl\:hover\:dq-from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .xl\:hover\:dq-from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .xl\:hover\:dq-from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .xl\:hover\:dq-from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .xl\:hover\:dq-from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .xl\:hover\:dq-from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .xl\:hover\:dq-from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .xl\:hover\:dq-from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .xl\:hover\:dq-from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .xl\:hover\:dq-from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .xl\:hover\:dq-from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .xl\:hover\:dq-from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .xl\:hover\:dq-from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .xl\:hover\:dq-from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .xl\:hover\:dq-from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .xl\:hover\:dq-from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .xl\:hover\:dq-from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .xl\:hover\:dq-from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .xl\:hover\:dq-from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .xl\:hover\:dq-from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .xl\:hover\:dq-from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .xl\:hover\:dq-from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .xl\:hover\:dq-from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .xl\:hover\:dq-from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .xl\:hover\:dq-from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .xl\:hover\:dq-from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .xl\:hover\:dq-from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .xl\:hover\:dq-from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .xl\:hover\:dq-from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .xl\:hover\:dq-from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .xl\:hover\:dq-from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .xl\:hover\:dq-from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .xl\:hover\:dq-from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .xl\:hover\:dq-from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .xl\:hover\:dq-from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .xl\:hover\:dq-from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .xl\:hover\:dq-from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .xl\:hover\:dq-from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .xl\:hover\:dq-from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .xl\:hover\:dq-from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .xl\:hover\:dq-from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .xl\:hover\:dq-from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .xl\:hover\:dq-from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .xl\:hover\:dq-from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .xl\:hover\:dq-from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .xl\:hover\:dq-from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .xl\:hover\:dq-from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .xl\:hover\:dq-from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .xl\:hover\:dq-from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .xl\:hover\:dq-from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .xl\:hover\:dq-from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .xl\:hover\:dq-from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .xl\:hover\:dq-from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .xl\:hover\:dq-from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .xl\:hover\:dq-from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .xl\:hover\:dq-from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .xl\:hover\:dq-from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .xl\:hover\:dq-from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .xl\:hover\:dq-from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .xl\:hover\:dq-from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .xl\:hover\:dq-from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .xl\:hover\:dq-from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .xl\:hover\:dq-from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .xl\:hover\:dq-from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .xl\:focus\:dq-from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:dq-from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:dq-from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:dq-from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:dq-from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .xl\:focus\:dq-from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .xl\:focus\:dq-from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .xl\:focus\:dq-from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .xl\:focus\:dq-from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .xl\:focus\:dq-from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .xl\:focus\:dq-from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .xl\:focus\:dq-from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .xl\:focus\:dq-from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .xl\:focus\:dq-from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .xl\:focus\:dq-from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .xl\:focus\:dq-from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .xl\:focus\:dq-from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .xl\:focus\:dq-from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .xl\:focus\:dq-from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .xl\:focus\:dq-from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .xl\:focus\:dq-from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .xl\:focus\:dq-from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .xl\:focus\:dq-from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .xl\:focus\:dq-from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .xl\:focus\:dq-from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .xl\:focus\:dq-from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .xl\:focus\:dq-from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .xl\:focus\:dq-from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .xl\:focus\:dq-from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .xl\:focus\:dq-from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .xl\:focus\:dq-from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .xl\:focus\:dq-from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .xl\:focus\:dq-from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .xl\:focus\:dq-from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .xl\:focus\:dq-from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .xl\:focus\:dq-from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .xl\:focus\:dq-from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .xl\:focus\:dq-from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .xl\:focus\:dq-from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .xl\:focus\:dq-from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .xl\:focus\:dq-from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .xl\:focus\:dq-from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .xl\:focus\:dq-from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .xl\:focus\:dq-from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .xl\:focus\:dq-from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .xl\:focus\:dq-from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .xl\:focus\:dq-from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .xl\:focus\:dq-from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .xl\:focus\:dq-from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .xl\:focus\:dq-from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .xl\:focus\:dq-from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .xl\:focus\:dq-from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .xl\:focus\:dq-from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .xl\:focus\:dq-from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .xl\:focus\:dq-from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .xl\:focus\:dq-from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .xl\:focus\:dq-from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .xl\:focus\:dq-from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .xl\:focus\:dq-from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .xl\:focus\:dq-from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .xl\:focus\:dq-from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .xl\:focus\:dq-from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .xl\:focus\:dq-from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .xl\:focus\:dq-from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .xl\:focus\:dq-from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .xl\:focus\:dq-from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .xl\:focus\:dq-from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .xl\:focus\:dq-from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .xl\:focus\:dq-from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .xl\:focus\:dq-from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .xl\:focus\:dq-from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .xl\:focus\:dq-from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .xl\:focus\:dq-from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .xl\:focus\:dq-from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .xl\:focus\:dq-from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .xl\:focus\:dq-from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .xl\:focus\:dq-from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .xl\:focus\:dq-from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .xl\:focus\:dq-from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .xl\:focus\:dq-from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .xl\:focus\:dq-from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .xl\:focus\:dq-from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .xl\:focus\:dq-from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .xl\:focus\:dq-from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .xl\:dq-via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:dq-via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:dq-via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:dq-via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:dq-via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .xl\:dq-via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .xl\:dq-via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .xl\:dq-via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .xl\:dq-via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .xl\:dq-via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .xl\:dq-via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .xl\:dq-via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .xl\:dq-via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .xl\:dq-via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .xl\:dq-via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .xl\:dq-via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .xl\:dq-via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .xl\:dq-via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .xl\:dq-via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .xl\:dq-via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .xl\:dq-via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .xl\:dq-via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .xl\:dq-via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .xl\:dq-via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .xl\:dq-via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .xl\:dq-via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .xl\:dq-via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .xl\:dq-via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .xl\:dq-via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .xl\:dq-via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .xl\:dq-via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .xl\:dq-via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .xl\:dq-via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .xl\:dq-via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .xl\:dq-via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .xl\:dq-via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .xl\:dq-via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .xl\:dq-via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .xl\:dq-via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .xl\:dq-via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .xl\:dq-via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .xl\:dq-via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .xl\:dq-via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .xl\:dq-via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .xl\:dq-via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .xl\:dq-via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .xl\:dq-via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .xl\:dq-via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .xl\:dq-via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .xl\:dq-via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .xl\:dq-via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .xl\:dq-via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .xl\:dq-via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .xl\:dq-via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .xl\:dq-via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .xl\:dq-via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .xl\:dq-via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .xl\:dq-via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .xl\:dq-via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .xl\:dq-via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .xl\:dq-via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .xl\:dq-via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .xl\:dq-via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .xl\:dq-via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .xl\:dq-via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .xl\:dq-via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .xl\:dq-via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .xl\:dq-via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .xl\:dq-via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .xl\:dq-via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .xl\:dq-via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .xl\:dq-via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .xl\:dq-via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .xl\:dq-via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .xl\:dq-via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .xl\:dq-via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .xl\:dq-via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .xl\:dq-via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .xl\:dq-via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .xl\:dq-via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .xl\:dq-via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .xl\:dq-via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .xl\:dq-via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .xl\:dq-via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .xl\:hover\:dq-via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:dq-via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:dq-via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:dq-via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:dq-via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .xl\:hover\:dq-via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .xl\:hover\:dq-via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .xl\:hover\:dq-via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .xl\:hover\:dq-via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .xl\:hover\:dq-via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .xl\:hover\:dq-via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .xl\:hover\:dq-via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .xl\:hover\:dq-via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .xl\:hover\:dq-via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .xl\:hover\:dq-via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .xl\:hover\:dq-via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .xl\:hover\:dq-via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .xl\:hover\:dq-via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .xl\:hover\:dq-via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .xl\:hover\:dq-via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .xl\:hover\:dq-via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .xl\:hover\:dq-via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .xl\:hover\:dq-via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .xl\:hover\:dq-via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .xl\:hover\:dq-via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .xl\:hover\:dq-via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .xl\:hover\:dq-via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .xl\:hover\:dq-via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .xl\:hover\:dq-via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .xl\:hover\:dq-via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .xl\:hover\:dq-via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .xl\:hover\:dq-via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .xl\:hover\:dq-via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .xl\:hover\:dq-via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .xl\:hover\:dq-via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .xl\:hover\:dq-via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .xl\:hover\:dq-via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .xl\:hover\:dq-via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .xl\:hover\:dq-via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .xl\:hover\:dq-via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .xl\:hover\:dq-via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .xl\:hover\:dq-via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .xl\:hover\:dq-via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .xl\:hover\:dq-via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .xl\:hover\:dq-via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .xl\:hover\:dq-via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .xl\:hover\:dq-via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .xl\:hover\:dq-via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .xl\:hover\:dq-via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .xl\:hover\:dq-via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .xl\:hover\:dq-via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .xl\:hover\:dq-via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .xl\:hover\:dq-via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .xl\:hover\:dq-via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .xl\:hover\:dq-via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .xl\:hover\:dq-via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .xl\:hover\:dq-via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .xl\:hover\:dq-via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .xl\:hover\:dq-via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .xl\:hover\:dq-via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .xl\:hover\:dq-via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .xl\:hover\:dq-via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .xl\:hover\:dq-via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .xl\:hover\:dq-via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .xl\:hover\:dq-via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .xl\:hover\:dq-via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .xl\:hover\:dq-via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .xl\:hover\:dq-via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .xl\:hover\:dq-via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .xl\:hover\:dq-via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .xl\:hover\:dq-via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .xl\:hover\:dq-via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .xl\:hover\:dq-via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .xl\:hover\:dq-via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .xl\:hover\:dq-via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .xl\:hover\:dq-via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .xl\:hover\:dq-via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .xl\:hover\:dq-via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .xl\:hover\:dq-via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .xl\:hover\:dq-via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .xl\:hover\:dq-via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .xl\:hover\:dq-via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .xl\:hover\:dq-via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .xl\:hover\:dq-via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .xl\:focus\:dq-via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:dq-via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:dq-via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:dq-via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:dq-via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .xl\:focus\:dq-via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .xl\:focus\:dq-via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .xl\:focus\:dq-via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .xl\:focus\:dq-via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .xl\:focus\:dq-via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .xl\:focus\:dq-via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .xl\:focus\:dq-via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .xl\:focus\:dq-via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .xl\:focus\:dq-via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .xl\:focus\:dq-via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .xl\:focus\:dq-via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .xl\:focus\:dq-via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .xl\:focus\:dq-via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .xl\:focus\:dq-via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .xl\:focus\:dq-via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .xl\:focus\:dq-via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .xl\:focus\:dq-via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .xl\:focus\:dq-via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .xl\:focus\:dq-via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .xl\:focus\:dq-via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .xl\:focus\:dq-via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .xl\:focus\:dq-via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .xl\:focus\:dq-via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .xl\:focus\:dq-via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .xl\:focus\:dq-via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .xl\:focus\:dq-via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .xl\:focus\:dq-via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .xl\:focus\:dq-via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .xl\:focus\:dq-via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .xl\:focus\:dq-via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .xl\:focus\:dq-via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .xl\:focus\:dq-via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .xl\:focus\:dq-via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .xl\:focus\:dq-via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .xl\:focus\:dq-via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .xl\:focus\:dq-via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .xl\:focus\:dq-via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .xl\:focus\:dq-via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .xl\:focus\:dq-via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .xl\:focus\:dq-via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .xl\:focus\:dq-via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .xl\:focus\:dq-via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .xl\:focus\:dq-via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .xl\:focus\:dq-via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .xl\:focus\:dq-via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .xl\:focus\:dq-via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .xl\:focus\:dq-via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .xl\:focus\:dq-via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .xl\:focus\:dq-via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .xl\:focus\:dq-via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .xl\:focus\:dq-via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .xl\:focus\:dq-via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .xl\:focus\:dq-via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .xl\:focus\:dq-via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .xl\:focus\:dq-via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .xl\:focus\:dq-via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .xl\:focus\:dq-via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .xl\:focus\:dq-via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .xl\:focus\:dq-via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .xl\:focus\:dq-via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .xl\:focus\:dq-via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .xl\:focus\:dq-via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .xl\:focus\:dq-via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .xl\:focus\:dq-via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .xl\:focus\:dq-via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .xl\:focus\:dq-via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .xl\:focus\:dq-via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .xl\:focus\:dq-via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .xl\:focus\:dq-via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .xl\:focus\:dq-via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .xl\:focus\:dq-via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .xl\:focus\:dq-via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .xl\:focus\:dq-via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .xl\:focus\:dq-via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .xl\:focus\:dq-via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .xl\:focus\:dq-via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .xl\:focus\:dq-via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .xl\:focus\:dq-via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .xl\:focus\:dq-via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .xl\:dq-to-transparent {
    --tw-gradient-to: transparent;
  }

  .xl\:dq-to-current {
    --tw-gradient-to: currentColor;
  }

  .xl\:dq-to-black {
    --tw-gradient-to: #000;
  }

  .xl\:dq-to-white {
    --tw-gradient-to: #fff;
  }

  .xl\:dq-to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .xl\:dq-to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .xl\:dq-to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .xl\:dq-to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .xl\:dq-to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .xl\:dq-to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .xl\:dq-to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .xl\:dq-to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .xl\:dq-to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .xl\:dq-to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .xl\:dq-to-red-50 {
    --tw-gradient-to: #fef2f2;
  }

  .xl\:dq-to-red-100 {
    --tw-gradient-to: #fee2e2;
  }

  .xl\:dq-to-red-200 {
    --tw-gradient-to: #fecaca;
  }

  .xl\:dq-to-red-300 {
    --tw-gradient-to: #fca5a5;
  }

  .xl\:dq-to-red-400 {
    --tw-gradient-to: #f87171;
  }

  .xl\:dq-to-red-500 {
    --tw-gradient-to: #ef4444;
  }

  .xl\:dq-to-red-600 {
    --tw-gradient-to: #dc2626;
  }

  .xl\:dq-to-red-700 {
    --tw-gradient-to: #b91c1c;
  }

  .xl\:dq-to-red-800 {
    --tw-gradient-to: #991b1b;
  }

  .xl\:dq-to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }

  .xl\:dq-to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .xl\:dq-to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .xl\:dq-to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .xl\:dq-to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .xl\:dq-to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .xl\:dq-to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .xl\:dq-to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .xl\:dq-to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .xl\:dq-to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .xl\:dq-to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .xl\:dq-to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .xl\:dq-to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .xl\:dq-to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .xl\:dq-to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .xl\:dq-to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .xl\:dq-to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .xl\:dq-to-green-600 {
    --tw-gradient-to: #059669;
  }

  .xl\:dq-to-green-700 {
    --tw-gradient-to: #047857;
  }

  .xl\:dq-to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .xl\:dq-to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .xl\:dq-to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .xl\:dq-to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .xl\:dq-to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .xl\:dq-to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .xl\:dq-to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .xl\:dq-to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .xl\:dq-to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .xl\:dq-to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .xl\:dq-to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .xl\:dq-to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .xl\:dq-to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .xl\:dq-to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .xl\:dq-to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .xl\:dq-to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .xl\:dq-to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .xl\:dq-to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .xl\:dq-to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .xl\:dq-to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .xl\:dq-to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .xl\:dq-to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .xl\:dq-to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .xl\:dq-to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .xl\:dq-to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .xl\:dq-to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .xl\:dq-to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .xl\:dq-to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .xl\:dq-to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .xl\:dq-to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .xl\:dq-to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .xl\:dq-to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .xl\:dq-to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .xl\:dq-to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .xl\:dq-to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .xl\:dq-to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .xl\:dq-to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .xl\:dq-to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .xl\:dq-to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .xl\:dq-to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .xl\:dq-to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .xl\:dq-to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .xl\:hover\:dq-to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .xl\:hover\:dq-to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .xl\:hover\:dq-to-black:hover {
    --tw-gradient-to: #000;
  }

  .xl\:hover\:dq-to-white:hover {
    --tw-gradient-to: #fff;
  }

  .xl\:hover\:dq-to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .xl\:hover\:dq-to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .xl\:hover\:dq-to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .xl\:hover\:dq-to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .xl\:hover\:dq-to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .xl\:hover\:dq-to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .xl\:hover\:dq-to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .xl\:hover\:dq-to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .xl\:hover\:dq-to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .xl\:hover\:dq-to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .xl\:hover\:dq-to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }

  .xl\:hover\:dq-to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }

  .xl\:hover\:dq-to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }

  .xl\:hover\:dq-to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }

  .xl\:hover\:dq-to-red-400:hover {
    --tw-gradient-to: #f87171;
  }

  .xl\:hover\:dq-to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }

  .xl\:hover\:dq-to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }

  .xl\:hover\:dq-to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }

  .xl\:hover\:dq-to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }

  .xl\:hover\:dq-to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }

  .xl\:hover\:dq-to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .xl\:hover\:dq-to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .xl\:hover\:dq-to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .xl\:hover\:dq-to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .xl\:hover\:dq-to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .xl\:hover\:dq-to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .xl\:hover\:dq-to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .xl\:hover\:dq-to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .xl\:hover\:dq-to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .xl\:hover\:dq-to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .xl\:hover\:dq-to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .xl\:hover\:dq-to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .xl\:hover\:dq-to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .xl\:hover\:dq-to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .xl\:hover\:dq-to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .xl\:hover\:dq-to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .xl\:hover\:dq-to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .xl\:hover\:dq-to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .xl\:hover\:dq-to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .xl\:hover\:dq-to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .xl\:hover\:dq-to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .xl\:hover\:dq-to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .xl\:hover\:dq-to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .xl\:hover\:dq-to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .xl\:hover\:dq-to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .xl\:hover\:dq-to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .xl\:hover\:dq-to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .xl\:hover\:dq-to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .xl\:hover\:dq-to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .xl\:hover\:dq-to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .xl\:hover\:dq-to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .xl\:hover\:dq-to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .xl\:hover\:dq-to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .xl\:hover\:dq-to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .xl\:hover\:dq-to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .xl\:hover\:dq-to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .xl\:hover\:dq-to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .xl\:hover\:dq-to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .xl\:hover\:dq-to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .xl\:hover\:dq-to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .xl\:hover\:dq-to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .xl\:hover\:dq-to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .xl\:hover\:dq-to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .xl\:hover\:dq-to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .xl\:hover\:dq-to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .xl\:hover\:dq-to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .xl\:hover\:dq-to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .xl\:hover\:dq-to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .xl\:hover\:dq-to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .xl\:hover\:dq-to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .xl\:hover\:dq-to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .xl\:hover\:dq-to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .xl\:hover\:dq-to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .xl\:hover\:dq-to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .xl\:hover\:dq-to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .xl\:hover\:dq-to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .xl\:hover\:dq-to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .xl\:hover\:dq-to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .xl\:hover\:dq-to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .xl\:hover\:dq-to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .xl\:focus\:dq-to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .xl\:focus\:dq-to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .xl\:focus\:dq-to-black:focus {
    --tw-gradient-to: #000;
  }

  .xl\:focus\:dq-to-white:focus {
    --tw-gradient-to: #fff;
  }

  .xl\:focus\:dq-to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .xl\:focus\:dq-to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .xl\:focus\:dq-to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .xl\:focus\:dq-to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .xl\:focus\:dq-to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .xl\:focus\:dq-to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .xl\:focus\:dq-to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .xl\:focus\:dq-to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .xl\:focus\:dq-to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .xl\:focus\:dq-to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .xl\:focus\:dq-to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }

  .xl\:focus\:dq-to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }

  .xl\:focus\:dq-to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }

  .xl\:focus\:dq-to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }

  .xl\:focus\:dq-to-red-400:focus {
    --tw-gradient-to: #f87171;
  }

  .xl\:focus\:dq-to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }

  .xl\:focus\:dq-to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }

  .xl\:focus\:dq-to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }

  .xl\:focus\:dq-to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }

  .xl\:focus\:dq-to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }

  .xl\:focus\:dq-to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .xl\:focus\:dq-to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .xl\:focus\:dq-to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .xl\:focus\:dq-to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .xl\:focus\:dq-to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .xl\:focus\:dq-to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .xl\:focus\:dq-to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .xl\:focus\:dq-to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .xl\:focus\:dq-to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .xl\:focus\:dq-to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .xl\:focus\:dq-to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .xl\:focus\:dq-to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .xl\:focus\:dq-to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .xl\:focus\:dq-to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .xl\:focus\:dq-to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .xl\:focus\:dq-to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .xl\:focus\:dq-to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .xl\:focus\:dq-to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .xl\:focus\:dq-to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .xl\:focus\:dq-to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .xl\:focus\:dq-to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .xl\:focus\:dq-to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .xl\:focus\:dq-to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .xl\:focus\:dq-to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .xl\:focus\:dq-to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .xl\:focus\:dq-to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .xl\:focus\:dq-to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .xl\:focus\:dq-to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .xl\:focus\:dq-to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .xl\:focus\:dq-to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .xl\:focus\:dq-to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .xl\:focus\:dq-to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .xl\:focus\:dq-to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .xl\:focus\:dq-to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .xl\:focus\:dq-to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .xl\:focus\:dq-to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .xl\:focus\:dq-to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .xl\:focus\:dq-to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .xl\:focus\:dq-to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .xl\:focus\:dq-to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .xl\:focus\:dq-to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .xl\:focus\:dq-to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .xl\:focus\:dq-to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .xl\:focus\:dq-to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .xl\:focus\:dq-to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .xl\:focus\:dq-to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .xl\:focus\:dq-to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .xl\:focus\:dq-to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .xl\:focus\:dq-to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .xl\:focus\:dq-to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .xl\:focus\:dq-to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .xl\:focus\:dq-to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .xl\:focus\:dq-to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .xl\:focus\:dq-to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .xl\:focus\:dq-to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .xl\:focus\:dq-to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .xl\:focus\:dq-to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .xl\:focus\:dq-to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .xl\:focus\:dq-to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .xl\:focus\:dq-to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .xl\:dq-decoration-slice {
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
  }

  .xl\:dq-decoration-clone {
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
  }

  .xl\:dq-bg-auto {
    background-size: auto;
  }

  .xl\:dq-bg-cover {
    background-size: cover;
  }

  .xl\:dq-bg-contain {
    background-size: contain;
  }

  .xl\:dq-bg-fixed {
    background-attachment: fixed;
  }

  .xl\:dq-bg-local {
    background-attachment: local;
  }

  .xl\:dq-bg-scroll {
    background-attachment: scroll;
  }

  .xl\:dq-bg-clip-border {
    background-clip: border-box;
  }

  .xl\:dq-bg-clip-padding {
    background-clip: padding-box;
  }

  .xl\:dq-bg-clip-content {
    background-clip: content-box;
  }

  .xl\:dq-bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .xl\:dq-bg-bottom {
    background-position: bottom;
  }

  .xl\:dq-bg-center {
    background-position: center;
  }

  .xl\:dq-bg-left {
    background-position: left;
  }

  .xl\:dq-bg-left-bottom {
    background-position: left bottom;
  }

  .xl\:dq-bg-left-top {
    background-position: left top;
  }

  .xl\:dq-bg-right {
    background-position: right;
  }

  .xl\:dq-bg-right-bottom {
    background-position: right bottom;
  }

  .xl\:dq-bg-right-top {
    background-position: right top;
  }

  .xl\:dq-bg-top {
    background-position: top;
  }

  .xl\:dq-bg-repeat {
    background-repeat: repeat;
  }

  .xl\:dq-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .xl\:dq-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .xl\:dq-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .xl\:dq-bg-repeat-round {
    background-repeat: round;
  }

  .xl\:dq-bg-repeat-space {
    background-repeat: space;
  }

  .xl\:dq-bg-origin-border {
    background-origin: border-box;
  }

  .xl\:dq-bg-origin-padding {
    background-origin: padding-box;
  }

  .xl\:dq-bg-origin-content {
    background-origin: content-box;
  }

  .xl\:dq-fill-current {
    fill: currentColor;
  }

  .xl\:dq-stroke-current {
    stroke: currentColor;
  }

  .xl\:dq-stroke-0 {
    stroke-width: 0;
  }

  .xl\:dq-stroke-1 {
    stroke-width: 1;
  }

  .xl\:dq-stroke-2 {
    stroke-width: 2;
  }

  .xl\:dq-object-contain {
    object-fit: contain;
  }

  .xl\:dq-object-cover {
    object-fit: cover;
  }

  .xl\:dq-object-fill {
    object-fit: fill;
  }

  .xl\:dq-object-none {
    object-fit: none;
  }

  .xl\:dq-object-scale-down {
    object-fit: scale-down;
  }

  .xl\:dq-object-bottom {
    object-position: bottom;
  }

  .xl\:dq-object-center {
    object-position: center;
  }

  .xl\:dq-object-left {
    object-position: left;
  }

  .xl\:dq-object-left-bottom {
    object-position: left bottom;
  }

  .xl\:dq-object-left-top {
    object-position: left top;
  }

  .xl\:dq-object-right {
    object-position: right;
  }

  .xl\:dq-object-right-bottom {
    object-position: right bottom;
  }

  .xl\:dq-object-right-top {
    object-position: right top;
  }

  .xl\:dq-object-top {
    object-position: top;
  }

  .xl\:dq-p-0 {
    padding: 0px;
  }

  .xl\:dq-p-1 {
    padding: 0.25rem;
  }

  .xl\:dq-p-2 {
    padding: 0.5rem;
  }

  .xl\:dq-p-3 {
    padding: 0.75rem;
  }

  .xl\:dq-p-4 {
    padding: 1rem;
  }

  .xl\:dq-p-5 {
    padding: 1.25rem;
  }

  .xl\:dq-p-6 {
    padding: 1.5rem;
  }

  .xl\:dq-p-7 {
    padding: 1.75rem;
  }

  .xl\:dq-p-8 {
    padding: 2rem;
  }

  .xl\:dq-p-9 {
    padding: 2.25rem;
  }

  .xl\:dq-p-10 {
    padding: 2.5rem;
  }

  .xl\:dq-p-11 {
    padding: 2.75rem;
  }

  .xl\:dq-p-12 {
    padding: 3rem;
  }

  .xl\:dq-p-14 {
    padding: 3.5rem;
  }

  .xl\:dq-p-16 {
    padding: 4rem;
  }

  .xl\:dq-p-20 {
    padding: 5rem;
  }

  .xl\:dq-p-24 {
    padding: 6rem;
  }

  .xl\:dq-p-28 {
    padding: 7rem;
  }

  .xl\:dq-p-32 {
    padding: 8rem;
  }

  .xl\:dq-p-36 {
    padding: 9rem;
  }

  .xl\:dq-p-40 {
    padding: 10rem;
  }

  .xl\:dq-p-44 {
    padding: 11rem;
  }

  .xl\:dq-p-48 {
    padding: 12rem;
  }

  .xl\:dq-p-52 {
    padding: 13rem;
  }

  .xl\:dq-p-56 {
    padding: 14rem;
  }

  .xl\:dq-p-60 {
    padding: 15rem;
  }

  .xl\:dq-p-64 {
    padding: 16rem;
  }

  .xl\:dq-p-72 {
    padding: 18rem;
  }

  .xl\:dq-p-80 {
    padding: 20rem;
  }

  .xl\:dq-p-96 {
    padding: 24rem;
  }

  .xl\:dq-p-px {
    padding: 1px;
  }

  .xl\:dq-p-0\.5 {
    padding: 0.125rem;
  }

  .xl\:dq-p-1\.5 {
    padding: 0.375rem;
  }

  .xl\:dq-p-2\.5 {
    padding: 0.625rem;
  }

  .xl\:dq-p-3\.5 {
    padding: 0.875rem;
  }

  .xl\:dq-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .xl\:dq-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .xl\:dq-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .xl\:dq-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .xl\:dq-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:dq-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:dq-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:dq-px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .xl\:dq-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:dq-px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .xl\:dq-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:dq-px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .xl\:dq-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:dq-px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .xl\:dq-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:dq-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:dq-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:dq-px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .xl\:dq-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xl\:dq-px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .xl\:dq-px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .xl\:dq-px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .xl\:dq-px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .xl\:dq-px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .xl\:dq-px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .xl\:dq-px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .xl\:dq-px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .xl\:dq-px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .xl\:dq-px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .xl\:dq-px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .xl\:dq-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .xl\:dq-px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }

  .xl\:dq-px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }

  .xl\:dq-px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .xl\:dq-px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }

  .xl\:dq-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .xl\:dq-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .xl\:dq-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .xl\:dq-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .xl\:dq-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:dq-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xl\:dq-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:dq-py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .xl\:dq-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:dq-py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .xl\:dq-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:dq-py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .xl\:dq-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:dq-py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .xl\:dq-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xl\:dq-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xl\:dq-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:dq-py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .xl\:dq-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xl\:dq-py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .xl\:dq-py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .xl\:dq-py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .xl\:dq-py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .xl\:dq-py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .xl\:dq-py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .xl\:dq-py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .xl\:dq-py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .xl\:dq-py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .xl\:dq-py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .xl\:dq-py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .xl\:dq-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .xl\:dq-py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }

  .xl\:dq-py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  .xl\:dq-py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .xl\:dq-py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  .xl\:dq-pt-0 {
    padding-top: 0px;
  }

  .xl\:dq-pt-1 {
    padding-top: 0.25rem;
  }

  .xl\:dq-pt-2 {
    padding-top: 0.5rem;
  }

  .xl\:dq-pt-3 {
    padding-top: 0.75rem;
  }

  .xl\:dq-pt-4 {
    padding-top: 1rem;
  }

  .xl\:dq-pt-5 {
    padding-top: 1.25rem;
  }

  .xl\:dq-pt-6 {
    padding-top: 1.5rem;
  }

  .xl\:dq-pt-7 {
    padding-top: 1.75rem;
  }

  .xl\:dq-pt-8 {
    padding-top: 2rem;
  }

  .xl\:dq-pt-9 {
    padding-top: 2.25rem;
  }

  .xl\:dq-pt-10 {
    padding-top: 2.5rem;
  }

  .xl\:dq-pt-11 {
    padding-top: 2.75rem;
  }

  .xl\:dq-pt-12 {
    padding-top: 3rem;
  }

  .xl\:dq-pt-14 {
    padding-top: 3.5rem;
  }

  .xl\:dq-pt-16 {
    padding-top: 4rem;
  }

  .xl\:dq-pt-20 {
    padding-top: 5rem;
  }

  .xl\:dq-pt-24 {
    padding-top: 6rem;
  }

  .xl\:dq-pt-28 {
    padding-top: 7rem;
  }

  .xl\:dq-pt-32 {
    padding-top: 8rem;
  }

  .xl\:dq-pt-36 {
    padding-top: 9rem;
  }

  .xl\:dq-pt-40 {
    padding-top: 10rem;
  }

  .xl\:dq-pt-44 {
    padding-top: 11rem;
  }

  .xl\:dq-pt-48 {
    padding-top: 12rem;
  }

  .xl\:dq-pt-52 {
    padding-top: 13rem;
  }

  .xl\:dq-pt-56 {
    padding-top: 14rem;
  }

  .xl\:dq-pt-60 {
    padding-top: 15rem;
  }

  .xl\:dq-pt-64 {
    padding-top: 16rem;
  }

  .xl\:dq-pt-72 {
    padding-top: 18rem;
  }

  .xl\:dq-pt-80 {
    padding-top: 20rem;
  }

  .xl\:dq-pt-96 {
    padding-top: 24rem;
  }

  .xl\:dq-pt-px {
    padding-top: 1px;
  }

  .xl\:dq-pt-0\.5 {
    padding-top: 0.125rem;
  }

  .xl\:dq-pt-1\.5 {
    padding-top: 0.375rem;
  }

  .xl\:dq-pt-2\.5 {
    padding-top: 0.625rem;
  }

  .xl\:dq-pt-3\.5 {
    padding-top: 0.875rem;
  }

  .xl\:dq-pr-0 {
    padding-right: 0px;
  }

  .xl\:dq-pr-1 {
    padding-right: 0.25rem;
  }

  .xl\:dq-pr-2 {
    padding-right: 0.5rem;
  }

  .xl\:dq-pr-3 {
    padding-right: 0.75rem;
  }

  .xl\:dq-pr-4 {
    padding-right: 1rem;
  }

  .xl\:dq-pr-5 {
    padding-right: 1.25rem;
  }

  .xl\:dq-pr-6 {
    padding-right: 1.5rem;
  }

  .xl\:dq-pr-7 {
    padding-right: 1.75rem;
  }

  .xl\:dq-pr-8 {
    padding-right: 2rem;
  }

  .xl\:dq-pr-9 {
    padding-right: 2.25rem;
  }

  .xl\:dq-pr-10 {
    padding-right: 2.5rem;
  }

  .xl\:dq-pr-11 {
    padding-right: 2.75rem;
  }

  .xl\:dq-pr-12 {
    padding-right: 3rem;
  }

  .xl\:dq-pr-14 {
    padding-right: 3.5rem;
  }

  .xl\:dq-pr-16 {
    padding-right: 4rem;
  }

  .xl\:dq-pr-20 {
    padding-right: 5rem;
  }

  .xl\:dq-pr-24 {
    padding-right: 6rem;
  }

  .xl\:dq-pr-28 {
    padding-right: 7rem;
  }

  .xl\:dq-pr-32 {
    padding-right: 8rem;
  }

  .xl\:dq-pr-36 {
    padding-right: 9rem;
  }

  .xl\:dq-pr-40 {
    padding-right: 10rem;
  }

  .xl\:dq-pr-44 {
    padding-right: 11rem;
  }

  .xl\:dq-pr-48 {
    padding-right: 12rem;
  }

  .xl\:dq-pr-52 {
    padding-right: 13rem;
  }

  .xl\:dq-pr-56 {
    padding-right: 14rem;
  }

  .xl\:dq-pr-60 {
    padding-right: 15rem;
  }

  .xl\:dq-pr-64 {
    padding-right: 16rem;
  }

  .xl\:dq-pr-72 {
    padding-right: 18rem;
  }

  .xl\:dq-pr-80 {
    padding-right: 20rem;
  }

  .xl\:dq-pr-96 {
    padding-right: 24rem;
  }

  .xl\:dq-pr-px {
    padding-right: 1px;
  }

  .xl\:dq-pr-0\.5 {
    padding-right: 0.125rem;
  }

  .xl\:dq-pr-1\.5 {
    padding-right: 0.375rem;
  }

  .xl\:dq-pr-2\.5 {
    padding-right: 0.625rem;
  }

  .xl\:dq-pr-3\.5 {
    padding-right: 0.875rem;
  }

  .xl\:dq-pb-0 {
    padding-bottom: 0px;
  }

  .xl\:dq-pb-1 {
    padding-bottom: 0.25rem;
  }

  .xl\:dq-pb-2 {
    padding-bottom: 0.5rem;
  }

  .xl\:dq-pb-3 {
    padding-bottom: 0.75rem;
  }

  .xl\:dq-pb-4 {
    padding-bottom: 1rem;
  }

  .xl\:dq-pb-5 {
    padding-bottom: 1.25rem;
  }

  .xl\:dq-pb-6 {
    padding-bottom: 1.5rem;
  }

  .xl\:dq-pb-7 {
    padding-bottom: 1.75rem;
  }

  .xl\:dq-pb-8 {
    padding-bottom: 2rem;
  }

  .xl\:dq-pb-9 {
    padding-bottom: 2.25rem;
  }

  .xl\:dq-pb-10 {
    padding-bottom: 2.5rem;
  }

  .xl\:dq-pb-11 {
    padding-bottom: 2.75rem;
  }

  .xl\:dq-pb-12 {
    padding-bottom: 3rem;
  }

  .xl\:dq-pb-14 {
    padding-bottom: 3.5rem;
  }

  .xl\:dq-pb-16 {
    padding-bottom: 4rem;
  }

  .xl\:dq-pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:dq-pb-24 {
    padding-bottom: 6rem;
  }

  .xl\:dq-pb-28 {
    padding-bottom: 7rem;
  }

  .xl\:dq-pb-32 {
    padding-bottom: 8rem;
  }

  .xl\:dq-pb-36 {
    padding-bottom: 9rem;
  }

  .xl\:dq-pb-40 {
    padding-bottom: 10rem;
  }

  .xl\:dq-pb-44 {
    padding-bottom: 11rem;
  }

  .xl\:dq-pb-48 {
    padding-bottom: 12rem;
  }

  .xl\:dq-pb-52 {
    padding-bottom: 13rem;
  }

  .xl\:dq-pb-56 {
    padding-bottom: 14rem;
  }

  .xl\:dq-pb-60 {
    padding-bottom: 15rem;
  }

  .xl\:dq-pb-64 {
    padding-bottom: 16rem;
  }

  .xl\:dq-pb-72 {
    padding-bottom: 18rem;
  }

  .xl\:dq-pb-80 {
    padding-bottom: 20rem;
  }

  .xl\:dq-pb-96 {
    padding-bottom: 24rem;
  }

  .xl\:dq-pb-px {
    padding-bottom: 1px;
  }

  .xl\:dq-pb-0\.5 {
    padding-bottom: 0.125rem;
  }

  .xl\:dq-pb-1\.5 {
    padding-bottom: 0.375rem;
  }

  .xl\:dq-pb-2\.5 {
    padding-bottom: 0.625rem;
  }

  .xl\:dq-pb-3\.5 {
    padding-bottom: 0.875rem;
  }

  .xl\:dq-pl-0 {
    padding-left: 0px;
  }

  .xl\:dq-pl-1 {
    padding-left: 0.25rem;
  }

  .xl\:dq-pl-2 {
    padding-left: 0.5rem;
  }

  .xl\:dq-pl-3 {
    padding-left: 0.75rem;
  }

  .xl\:dq-pl-4 {
    padding-left: 1rem;
  }

  .xl\:dq-pl-5 {
    padding-left: 1.25rem;
  }

  .xl\:dq-pl-6 {
    padding-left: 1.5rem;
  }

  .xl\:dq-pl-7 {
    padding-left: 1.75rem;
  }

  .xl\:dq-pl-8 {
    padding-left: 2rem;
  }

  .xl\:dq-pl-9 {
    padding-left: 2.25rem;
  }

  .xl\:dq-pl-10 {
    padding-left: 2.5rem;
  }

  .xl\:dq-pl-11 {
    padding-left: 2.75rem;
  }

  .xl\:dq-pl-12 {
    padding-left: 3rem;
  }

  .xl\:dq-pl-14 {
    padding-left: 3.5rem;
  }

  .xl\:dq-pl-16 {
    padding-left: 4rem;
  }

  .xl\:dq-pl-20 {
    padding-left: 5rem;
  }

  .xl\:dq-pl-24 {
    padding-left: 6rem;
  }

  .xl\:dq-pl-28 {
    padding-left: 7rem;
  }

  .xl\:dq-pl-32 {
    padding-left: 8rem;
  }

  .xl\:dq-pl-36 {
    padding-left: 9rem;
  }

  .xl\:dq-pl-40 {
    padding-left: 10rem;
  }

  .xl\:dq-pl-44 {
    padding-left: 11rem;
  }

  .xl\:dq-pl-48 {
    padding-left: 12rem;
  }

  .xl\:dq-pl-52 {
    padding-left: 13rem;
  }

  .xl\:dq-pl-56 {
    padding-left: 14rem;
  }

  .xl\:dq-pl-60 {
    padding-left: 15rem;
  }

  .xl\:dq-pl-64 {
    padding-left: 16rem;
  }

  .xl\:dq-pl-72 {
    padding-left: 18rem;
  }

  .xl\:dq-pl-80 {
    padding-left: 20rem;
  }

  .xl\:dq-pl-96 {
    padding-left: 24rem;
  }

  .xl\:dq-pl-px {
    padding-left: 1px;
  }

  .xl\:dq-pl-0\.5 {
    padding-left: 0.125rem;
  }

  .xl\:dq-pl-1\.5 {
    padding-left: 0.375rem;
  }

  .xl\:dq-pl-2\.5 {
    padding-left: 0.625rem;
  }

  .xl\:dq-pl-3\.5 {
    padding-left: 0.875rem;
  }

  .xl\:dq-text-left {
    text-align: left;
  }

  .xl\:dq-text-center {
    text-align: center;
  }

  .xl\:dq-text-right {
    text-align: right;
  }

  .xl\:dq-text-justify {
    text-align: justify;
  }

  .xl\:dq-align-baseline {
    vertical-align: baseline;
  }

  .xl\:dq-align-top {
    vertical-align: top;
  }

  .xl\:dq-align-middle {
    vertical-align: middle;
  }

  .xl\:dq-align-bottom {
    vertical-align: bottom;
  }

  .xl\:dq-align-text-top {
    vertical-align: text-top;
  }

  .xl\:dq-align-text-bottom {
    vertical-align: text-bottom;
  }

  .xl\:dq-font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .xl\:dq-font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .xl\:dq-font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .xl\:dq-text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .xl\:dq-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .xl\:dq-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xl\:dq-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xl\:dq-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xl\:dq-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:dq-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:dq-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .xl\:dq-text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .xl\:dq-text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .xl\:dq-text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .xl\:dq-text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .xl\:dq-text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .xl\:dq-font-thin {
    font-weight: 100;
  }

  .xl\:dq-font-extralight {
    font-weight: 200;
  }

  .xl\:dq-font-light {
    font-weight: 300;
  }

  .xl\:dq-font-normal {
    font-weight: 400;
  }

  .xl\:dq-font-medium {
    font-weight: 500;
  }

  .xl\:dq-font-semibold {
    font-weight: 600;
  }

  .xl\:dq-font-bold {
    font-weight: 700;
  }

  .xl\:dq-font-extrabold {
    font-weight: 800;
  }

  .xl\:dq-font-black {
    font-weight: 900;
  }

  .xl\:dq-uppercase {
    text-transform: uppercase;
  }

  .xl\:dq-lowercase {
    text-transform: lowercase;
  }

  .xl\:dq-capitalize {
    text-transform: capitalize;
  }

  .xl\:dq-normal-case {
    text-transform: none;
  }

  .xl\:dq-italic {
    font-style: italic;
  }

  .xl\:dq-not-italic {
    font-style: normal;
  }

  .xl\:dq-ordinal, .xl\:dq-slashed-zero, .xl\:dq-lining-nums, .xl\:dq-oldstyle-nums, .xl\:dq-proportional-nums, .xl\:dq-tabular-nums, .xl\:dq-diagonal-fractions, .xl\:dq-stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:dq-normal-nums {
    font-variant-numeric: normal;
  }

  .xl\:dq-ordinal {
    --tw-ordinal: ordinal;
  }

  .xl\:dq-slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .xl\:dq-lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .xl\:dq-oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .xl\:dq-proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .xl\:dq-tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .xl\:dq-diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .xl\:dq-stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .xl\:dq-leading-3 {
    line-height: .75rem;
  }

  .xl\:dq-leading-4 {
    line-height: 1rem;
  }

  .xl\:dq-leading-5 {
    line-height: 1.25rem;
  }

  .xl\:dq-leading-6 {
    line-height: 1.5rem;
  }

  .xl\:dq-leading-7 {
    line-height: 1.75rem;
  }

  .xl\:dq-leading-8 {
    line-height: 2rem;
  }

  .xl\:dq-leading-9 {
    line-height: 2.25rem;
  }

  .xl\:dq-leading-10 {
    line-height: 2.5rem;
  }

  .xl\:dq-leading-none {
    line-height: 1;
  }

  .xl\:dq-leading-tight {
    line-height: 1.25;
  }

  .xl\:dq-leading-snug {
    line-height: 1.375;
  }

  .xl\:dq-leading-normal {
    line-height: 1.5;
  }

  .xl\:dq-leading-relaxed {
    line-height: 1.625;
  }

  .xl\:dq-leading-loose {
    line-height: 2;
  }

  .xl\:dq-tracking-tighter {
    letter-spacing: -0.05em;
  }

  .xl\:dq-tracking-tight {
    letter-spacing: -0.025em;
  }

  .xl\:dq-tracking-normal {
    letter-spacing: 0em;
  }

  .xl\:dq-tracking-wide {
    letter-spacing: 0.025em;
  }

  .xl\:dq-tracking-wider {
    letter-spacing: 0.05em;
  }

  .xl\:dq-tracking-widest {
    letter-spacing: 0.1em;
  }

  .xl\:dq-text-transparent {
    color: transparent;
  }

  .xl\:dq-text-current {
    color: currentColor;
  }

  .xl\:dq-text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xl\:dq-text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:dq-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xl\:dq-text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xl\:dq-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xl\:dq-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xl\:dq-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xl\:dq-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xl\:dq-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xl\:dq-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xl\:dq-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xl\:dq-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xl\:dq-text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .xl\:dq-text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .xl\:dq-text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .xl\:dq-text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .xl\:dq-text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .xl\:dq-text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .xl\:dq-text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .xl\:dq-text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .xl\:dq-text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .xl\:dq-text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .xl\:dq-text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xl\:dq-text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xl\:dq-text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xl\:dq-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xl\:dq-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xl\:dq-text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xl\:dq-text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xl\:dq-text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xl\:dq-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xl\:dq-text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xl\:dq-text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xl\:dq-text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xl\:dq-text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xl\:dq-text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xl\:dq-text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xl\:dq-text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xl\:dq-text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xl\:dq-text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xl\:dq-text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xl\:dq-text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xl\:dq-text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xl\:dq-text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xl\:dq-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xl\:dq-text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xl\:dq-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xl\:dq-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xl\:dq-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xl\:dq-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xl\:dq-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xl\:dq-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xl\:dq-text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xl\:dq-text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xl\:dq-text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xl\:dq-text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xl\:dq-text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xl\:dq-text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xl\:dq-text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xl\:dq-text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xl\:dq-text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xl\:dq-text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xl\:dq-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xl\:dq-text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xl\:dq-text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xl\:dq-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xl\:dq-text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xl\:dq-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xl\:dq-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xl\:dq-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xl\:dq-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xl\:dq-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xl\:dq-text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xl\:dq-text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xl\:dq-text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xl\:dq-text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xl\:dq-text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xl\:dq-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xl\:dq-text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xl\:dq-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xl\:dq-text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xl\:dq-text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-transparent {
    color: transparent;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-current {
    color: currentColor;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .dq-group:hover .xl\:group-hover\:dq-text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-transparent:focus-within {
    color: transparent;
  }

  .xl\:focus-within\:dq-text-current:focus-within {
    color: currentColor;
  }

  .xl\:focus-within\:dq-text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xl\:focus-within\:dq-text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-transparent:hover {
    color: transparent;
  }

  .xl\:hover\:dq-text-current:hover {
    color: currentColor;
  }

  .xl\:hover\:dq-text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xl\:hover\:dq-text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-transparent:focus {
    color: transparent;
  }

  .xl\:focus\:dq-text-current:focus {
    color: currentColor;
  }

  .xl\:focus\:dq-text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xl\:focus\:dq-text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xl\:dq-text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .xl\:dq-text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .xl\:dq-text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .xl\:dq-text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .xl\:dq-text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .xl\:dq-text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .xl\:dq-text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .xl\:dq-text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .xl\:dq-text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .xl\:dq-text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .xl\:dq-text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .xl\:dq-text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .xl\:dq-text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .xl\:dq-text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .xl\:dq-text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .dq-group:hover .xl\:group-hover\:dq-text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .xl\:focus-within\:dq-text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .xl\:focus-within\:dq-text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }

  .xl\:focus-within\:dq-text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }

  .xl\:focus-within\:dq-text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }

  .xl\:focus-within\:dq-text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }

  .xl\:focus-within\:dq-text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }

  .xl\:focus-within\:dq-text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }

  .xl\:focus-within\:dq-text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }

  .xl\:focus-within\:dq-text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }

  .xl\:focus-within\:dq-text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }

  .xl\:focus-within\:dq-text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }

  .xl\:focus-within\:dq-text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }

  .xl\:focus-within\:dq-text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }

  .xl\:focus-within\:dq-text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }

  .xl\:focus-within\:dq-text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .xl\:hover\:dq-text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .xl\:hover\:dq-text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }

  .xl\:hover\:dq-text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }

  .xl\:hover\:dq-text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }

  .xl\:hover\:dq-text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }

  .xl\:hover\:dq-text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }

  .xl\:hover\:dq-text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }

  .xl\:hover\:dq-text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }

  .xl\:hover\:dq-text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }

  .xl\:hover\:dq-text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }

  .xl\:hover\:dq-text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }

  .xl\:hover\:dq-text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }

  .xl\:hover\:dq-text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }

  .xl\:hover\:dq-text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }

  .xl\:hover\:dq-text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .xl\:focus\:dq-text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .xl\:focus\:dq-text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }

  .xl\:focus\:dq-text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }

  .xl\:focus\:dq-text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }

  .xl\:focus\:dq-text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }

  .xl\:focus\:dq-text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }

  .xl\:focus\:dq-text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }

  .xl\:focus\:dq-text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }

  .xl\:focus\:dq-text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }

  .xl\:focus\:dq-text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }

  .xl\:focus\:dq-text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }

  .xl\:focus\:dq-text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }

  .xl\:focus\:dq-text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }

  .xl\:focus\:dq-text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }

  .xl\:focus\:dq-text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .xl\:dq-underline {
    text-decoration: underline;
  }

  .xl\:dq-line-through {
    text-decoration: line-through;
  }

  .xl\:dq-no-underline {
    text-decoration: none;
  }

  .dq-group:hover .xl\:group-hover\:dq-underline {
    text-decoration: underline;
  }

  .dq-group:hover .xl\:group-hover\:dq-line-through {
    text-decoration: line-through;
  }

  .dq-group:hover .xl\:group-hover\:dq-no-underline {
    text-decoration: none;
  }

  .xl\:focus-within\:dq-underline:focus-within {
    text-decoration: underline;
  }

  .xl\:focus-within\:dq-line-through:focus-within {
    text-decoration: line-through;
  }

  .xl\:focus-within\:dq-no-underline:focus-within {
    text-decoration: none;
  }

  .xl\:hover\:dq-underline:hover {
    text-decoration: underline;
  }

  .xl\:hover\:dq-line-through:hover {
    text-decoration: line-through;
  }

  .xl\:hover\:dq-no-underline:hover {
    text-decoration: none;
  }

  .xl\:focus\:dq-underline:focus {
    text-decoration: underline;
  }

  .xl\:focus\:dq-line-through:focus {
    text-decoration: line-through;
  }

  .xl\:focus\:dq-no-underline:focus {
    text-decoration: none;
  }

  .xl\:dq-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xl\:dq-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xl\:dq-placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }

  .xl\:dq-placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }

  .xl\:dq-placeholder-transparent::placeholder {
    color: transparent;
  }

  .xl\:dq-placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }

  .xl\:dq-placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }

  .xl\:dq-placeholder-current::placeholder {
    color: currentColor;
  }

  .xl\:dq-placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .xl\:focus\:dq-placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }

  .xl\:focus\:dq-placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .xl\:focus\:dq-placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }

  .xl\:focus\:dq-placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }

  .xl\:focus\:dq-placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .xl\:focus\:dq-placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:dq-placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .xl\:dq-placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xl\:dq-placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xl\:dq-placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xl\:dq-placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .xl\:dq-placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .xl\:dq-placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .xl\:dq-placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .xl\:dq-placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .xl\:dq-placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .xl\:dq-placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .xl\:dq-placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .xl\:dq-placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .xl\:dq-placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:dq-placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:dq-placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:dq-placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .xl\:dq-placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .xl\:dq-placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .xl\:dq-placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .xl\:dq-placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .xl\:dq-placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .xl\:dq-placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:dq-placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:dq-placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:dq-placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .xl\:dq-placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .xl\:dq-placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .xl\:dq-placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .xl\:dq-placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .xl\:dq-placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .xl\:dq-placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:dq-placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:dq-placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:dq-placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .xl\:dq-placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .xl\:dq-placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .xl\:dq-placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:dq-placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:dq-placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:dq-placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .xl\:dq-placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .xl\:dq-placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .xl\:dq-placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xl\:dq-placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xl\:dq-placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xl\:focus\:dq-placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xl\:focus\:dq-placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xl\:focus\:dq-placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xl\:focus\:dq-placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .xl\:focus\:dq-placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .xl\:focus\:dq-placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .xl\:focus\:dq-placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .xl\:focus\:dq-placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .xl\:focus\:dq-placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .xl\:focus\:dq-placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .xl\:focus\:dq-placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .xl\:focus\:dq-placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .xl\:focus\:dq-placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:focus\:dq-placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:focus\:dq-placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:focus\:dq-placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .xl\:focus\:dq-placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .xl\:focus\:dq-placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .xl\:focus\:dq-placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .xl\:focus\:dq-placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .xl\:focus\:dq-placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .xl\:focus\:dq-placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:focus\:dq-placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:focus\:dq-placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:focus\:dq-placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .xl\:focus\:dq-placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .xl\:focus\:dq-placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .xl\:focus\:dq-placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .xl\:focus\:dq-placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .xl\:focus\:dq-placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .xl\:focus\:dq-placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:focus\:dq-placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:focus\:dq-placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:focus\:dq-placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .xl\:focus\:dq-placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .xl\:focus\:dq-placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .xl\:focus\:dq-placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:focus\:dq-placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:focus\:dq-placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .xl\:focus\:dq-placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .xl\:focus\:dq-placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .xl\:focus\:dq-placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .xl\:focus\:dq-placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xl\:focus\:dq-placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xl\:focus\:dq-placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xl\:dq-opacity-0 {
    opacity: 0;
  }

  .xl\:dq-opacity-5 {
    opacity: 0.05;
  }

  .xl\:dq-opacity-10 {
    opacity: 0.1;
  }

  .xl\:dq-opacity-20 {
    opacity: 0.2;
  }

  .xl\:dq-opacity-25 {
    opacity: 0.25;
  }

  .xl\:dq-opacity-30 {
    opacity: 0.3;
  }

  .xl\:dq-opacity-40 {
    opacity: 0.4;
  }

  .xl\:dq-opacity-50 {
    opacity: 0.5;
  }

  .xl\:dq-opacity-60 {
    opacity: 0.6;
  }

  .xl\:dq-opacity-70 {
    opacity: 0.7;
  }

  .xl\:dq-opacity-75 {
    opacity: 0.75;
  }

  .xl\:dq-opacity-80 {
    opacity: 0.8;
  }

  .xl\:dq-opacity-90 {
    opacity: 0.9;
  }

  .xl\:dq-opacity-95 {
    opacity: 0.95;
  }

  .xl\:dq-opacity-100 {
    opacity: 1;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-0 {
    opacity: 0;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-5 {
    opacity: 0.05;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-10 {
    opacity: 0.1;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-20 {
    opacity: 0.2;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-25 {
    opacity: 0.25;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-30 {
    opacity: 0.3;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-40 {
    opacity: 0.4;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-50 {
    opacity: 0.5;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-60 {
    opacity: 0.6;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-70 {
    opacity: 0.7;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-75 {
    opacity: 0.75;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-80 {
    opacity: 0.8;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-90 {
    opacity: 0.9;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-95 {
    opacity: 0.95;
  }

  .dq-group:hover .xl\:group-hover\:dq-opacity-100 {
    opacity: 1;
  }

  .xl\:focus-within\:dq-opacity-0:focus-within {
    opacity: 0;
  }

  .xl\:focus-within\:dq-opacity-5:focus-within {
    opacity: 0.05;
  }

  .xl\:focus-within\:dq-opacity-10:focus-within {
    opacity: 0.1;
  }

  .xl\:focus-within\:dq-opacity-20:focus-within {
    opacity: 0.2;
  }

  .xl\:focus-within\:dq-opacity-25:focus-within {
    opacity: 0.25;
  }

  .xl\:focus-within\:dq-opacity-30:focus-within {
    opacity: 0.3;
  }

  .xl\:focus-within\:dq-opacity-40:focus-within {
    opacity: 0.4;
  }

  .xl\:focus-within\:dq-opacity-50:focus-within {
    opacity: 0.5;
  }

  .xl\:focus-within\:dq-opacity-60:focus-within {
    opacity: 0.6;
  }

  .xl\:focus-within\:dq-opacity-70:focus-within {
    opacity: 0.7;
  }

  .xl\:focus-within\:dq-opacity-75:focus-within {
    opacity: 0.75;
  }

  .xl\:focus-within\:dq-opacity-80:focus-within {
    opacity: 0.8;
  }

  .xl\:focus-within\:dq-opacity-90:focus-within {
    opacity: 0.9;
  }

  .xl\:focus-within\:dq-opacity-95:focus-within {
    opacity: 0.95;
  }

  .xl\:focus-within\:dq-opacity-100:focus-within {
    opacity: 1;
  }

  .xl\:hover\:dq-opacity-0:hover {
    opacity: 0;
  }

  .xl\:hover\:dq-opacity-5:hover {
    opacity: 0.05;
  }

  .xl\:hover\:dq-opacity-10:hover {
    opacity: 0.1;
  }

  .xl\:hover\:dq-opacity-20:hover {
    opacity: 0.2;
  }

  .xl\:hover\:dq-opacity-25:hover {
    opacity: 0.25;
  }

  .xl\:hover\:dq-opacity-30:hover {
    opacity: 0.3;
  }

  .xl\:hover\:dq-opacity-40:hover {
    opacity: 0.4;
  }

  .xl\:hover\:dq-opacity-50:hover {
    opacity: 0.5;
  }

  .xl\:hover\:dq-opacity-60:hover {
    opacity: 0.6;
  }

  .xl\:hover\:dq-opacity-70:hover {
    opacity: 0.7;
  }

  .xl\:hover\:dq-opacity-75:hover {
    opacity: 0.75;
  }

  .xl\:hover\:dq-opacity-80:hover {
    opacity: 0.8;
  }

  .xl\:hover\:dq-opacity-90:hover {
    opacity: 0.9;
  }

  .xl\:hover\:dq-opacity-95:hover {
    opacity: 0.95;
  }

  .xl\:hover\:dq-opacity-100:hover {
    opacity: 1;
  }

  .xl\:focus\:dq-opacity-0:focus {
    opacity: 0;
  }

  .xl\:focus\:dq-opacity-5:focus {
    opacity: 0.05;
  }

  .xl\:focus\:dq-opacity-10:focus {
    opacity: 0.1;
  }

  .xl\:focus\:dq-opacity-20:focus {
    opacity: 0.2;
  }

  .xl\:focus\:dq-opacity-25:focus {
    opacity: 0.25;
  }

  .xl\:focus\:dq-opacity-30:focus {
    opacity: 0.3;
  }

  .xl\:focus\:dq-opacity-40:focus {
    opacity: 0.4;
  }

  .xl\:focus\:dq-opacity-50:focus {
    opacity: 0.5;
  }

  .xl\:focus\:dq-opacity-60:focus {
    opacity: 0.6;
  }

  .xl\:focus\:dq-opacity-70:focus {
    opacity: 0.7;
  }

  .xl\:focus\:dq-opacity-75:focus {
    opacity: 0.75;
  }

  .xl\:focus\:dq-opacity-80:focus {
    opacity: 0.8;
  }

  .xl\:focus\:dq-opacity-90:focus {
    opacity: 0.9;
  }

  .xl\:focus\:dq-opacity-95:focus {
    opacity: 0.95;
  }

  .xl\:focus\:dq-opacity-100:focus {
    opacity: 1;
  }

  .xl\:dq-bg-blend-normal {
    background-blend-mode: normal;
  }

  .xl\:dq-bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .xl\:dq-bg-blend-screen {
    background-blend-mode: screen;
  }

  .xl\:dq-bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .xl\:dq-bg-blend-darken {
    background-blend-mode: darken;
  }

  .xl\:dq-bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .xl\:dq-bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .xl\:dq-bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .xl\:dq-bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .xl\:dq-bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .xl\:dq-bg-blend-difference {
    background-blend-mode: difference;
  }

  .xl\:dq-bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .xl\:dq-bg-blend-hue {
    background-blend-mode: hue;
  }

  .xl\:dq-bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .xl\:dq-bg-blend-color {
    background-blend-mode: color;
  }

  .xl\:dq-bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .xl\:dq-mix-blend-normal {
    mix-blend-mode: normal;
  }

  .xl\:dq-mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .xl\:dq-mix-blend-screen {
    mix-blend-mode: screen;
  }

  .xl\:dq-mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .xl\:dq-mix-blend-darken {
    mix-blend-mode: darken;
  }

  .xl\:dq-mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .xl\:dq-mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .xl\:dq-mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .xl\:dq-mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .xl\:dq-mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .xl\:dq-mix-blend-difference {
    mix-blend-mode: difference;
  }

  .xl\:dq-mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .xl\:dq-mix-blend-hue {
    mix-blend-mode: hue;
  }

  .xl\:dq-mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .xl\:dq-mix-blend-color {
    mix-blend-mode: color;
  }

  .xl\:dq-mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .xl\:dq-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:dq-shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:dq-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:dq-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:dq-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:dq-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:dq-shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:dq-shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .xl\:group-hover\:dq-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .xl\:group-hover\:dq-shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .xl\:group-hover\:dq-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .xl\:group-hover\:dq-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .xl\:group-hover\:dq-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .xl\:group-hover\:dq-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .xl\:group-hover\:dq-shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .xl\:group-hover\:dq-shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:dq-shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:dq-shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:dq-shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:dq-shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:dq-shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:dq-shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:dq-shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:dq-shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:dq-shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:dq-shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:dq-shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:dq-shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:dq-shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:dq-shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:dq-shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:dq-shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:dq-shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:dq-shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:dq-shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:dq-shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:dq-shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:dq-shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:dq-shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:dq-shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:dq-outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .xl\:dq-outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .xl\:dq-outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .xl\:focus-within\:dq-outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .xl\:focus-within\:dq-outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .xl\:focus-within\:dq-outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .xl\:focus\:dq-outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .xl\:focus\:dq-outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .xl\:focus\:dq-outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .xl\:dq-ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:dq-ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:dq-ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:dq-ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:dq-ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:dq-ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:dq-ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:dq-ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:dq-ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:dq-ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:dq-ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:dq-ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:dq-ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:dq-ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:dq-ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:dq-ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:dq-ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:dq-ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:dq-ring-inset {
    --tw-ring-inset: inset;
  }

  .xl\:focus-within\:dq-ring-inset:focus-within {
    --tw-ring-inset: inset;
  }

  .xl\:focus\:dq-ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .xl\:dq-ring-transparent {
    --tw-ring-color: transparent;
  }

  .xl\:dq-ring-current {
    --tw-ring-color: currentColor;
  }

  .xl\:dq-ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .xl\:focus-within\:dq-ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .xl\:focus-within\:dq-ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:dq-ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .xl\:focus\:dq-ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .xl\:focus\:dq-ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .xl\:focus\:dq-ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .xl\:dq-ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .xl\:dq-ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }

  .xl\:dq-ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }

  .xl\:dq-ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }

  .xl\:dq-ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }

  .xl\:dq-ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }

  .xl\:dq-ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }

  .xl\:dq-ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }

  .xl\:dq-ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }

  .xl\:dq-ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }

  .xl\:dq-ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }

  .xl\:dq-ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }

  .xl\:dq-ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }

  .xl\:dq-ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }

  .xl\:dq-ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .xl\:focus-within\:dq-ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .xl\:focus-within\:dq-ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }

  .xl\:focus-within\:dq-ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }

  .xl\:focus-within\:dq-ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }

  .xl\:focus-within\:dq-ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }

  .xl\:focus-within\:dq-ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }

  .xl\:focus-within\:dq-ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }

  .xl\:focus-within\:dq-ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }

  .xl\:focus-within\:dq-ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }

  .xl\:focus-within\:dq-ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }

  .xl\:focus-within\:dq-ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }

  .xl\:focus-within\:dq-ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }

  .xl\:focus-within\:dq-ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }

  .xl\:focus-within\:dq-ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }

  .xl\:focus-within\:dq-ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .xl\:focus\:dq-ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .xl\:focus\:dq-ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }

  .xl\:focus\:dq-ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }

  .xl\:focus\:dq-ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }

  .xl\:focus\:dq-ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }

  .xl\:focus\:dq-ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }

  .xl\:focus\:dq-ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }

  .xl\:focus\:dq-ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }

  .xl\:focus\:dq-ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }

  .xl\:focus\:dq-ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }

  .xl\:focus\:dq-ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }

  .xl\:focus\:dq-ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }

  .xl\:focus\:dq-ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }

  .xl\:focus\:dq-ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }

  .xl\:focus\:dq-ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .xl\:dq-ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .xl\:dq-ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .xl\:dq-ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .xl\:dq-ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .xl\:dq-ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .xl\:focus-within\:dq-ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .xl\:focus-within\:dq-ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .xl\:focus-within\:dq-ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .xl\:focus-within\:dq-ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .xl\:focus-within\:dq-ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .xl\:focus\:dq-ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .xl\:focus\:dq-ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .xl\:focus\:dq-ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .xl\:focus\:dq-ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .xl\:focus\:dq-ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .xl\:dq-ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .xl\:dq-ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .xl\:dq-ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .xl\:dq-ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .xl\:dq-ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .xl\:dq-ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .xl\:dq-ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .xl\:dq-ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .xl\:dq-ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .xl\:dq-ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .xl\:dq-ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .xl\:dq-ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .xl\:dq-ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .xl\:dq-ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .xl\:dq-ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }

  .xl\:dq-ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }

  .xl\:dq-ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }

  .xl\:dq-ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }

  .xl\:dq-ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }

  .xl\:dq-ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }

  .xl\:dq-ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }

  .xl\:dq-ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }

  .xl\:dq-ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }

  .xl\:dq-ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }

  .xl\:dq-ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .xl\:dq-ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .xl\:dq-ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .xl\:dq-ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .xl\:dq-ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .xl\:dq-ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .xl\:dq-ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .xl\:dq-ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .xl\:dq-ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .xl\:dq-ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .xl\:dq-ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .xl\:dq-ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .xl\:dq-ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .xl\:dq-ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .xl\:dq-ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .xl\:dq-ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .xl\:dq-ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .xl\:dq-ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .xl\:dq-ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .xl\:dq-ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .xl\:dq-ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .xl\:dq-ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .xl\:dq-ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .xl\:dq-ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .xl\:dq-ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .xl\:dq-ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .xl\:dq-ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .xl\:dq-ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .xl\:dq-ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .xl\:dq-ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .xl\:dq-ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .xl\:dq-ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .xl\:dq-ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .xl\:dq-ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .xl\:dq-ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .xl\:dq-ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .xl\:dq-ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .xl\:dq-ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .xl\:dq-ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .xl\:dq-ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .xl\:dq-ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .xl\:dq-ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .xl\:dq-ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .xl\:dq-ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .xl\:dq-ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .xl\:dq-ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .xl\:dq-ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .xl\:dq-ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .xl\:dq-ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .xl\:dq-ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .xl\:dq-ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .xl\:dq-ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .xl\:dq-ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .xl\:dq-ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .xl\:dq-ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .xl\:dq-ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .xl\:dq-ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .xl\:dq-ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .xl\:dq-ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .xl\:dq-ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .xl\:focus-within\:dq-ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .xl\:focus-within\:dq-ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .xl\:focus-within\:dq-ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .xl\:focus-within\:dq-ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .xl\:focus-within\:dq-ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .xl\:focus-within\:dq-ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .xl\:focus-within\:dq-ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .xl\:focus-within\:dq-ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .xl\:focus-within\:dq-ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .xl\:focus-within\:dq-ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .xl\:focus-within\:dq-ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .xl\:focus-within\:dq-ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .xl\:focus-within\:dq-ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .xl\:focus-within\:dq-ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .xl\:focus-within\:dq-ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }

  .xl\:focus-within\:dq-ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }

  .xl\:focus-within\:dq-ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }

  .xl\:focus-within\:dq-ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }

  .xl\:focus-within\:dq-ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }

  .xl\:focus-within\:dq-ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }

  .xl\:focus-within\:dq-ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }

  .xl\:focus-within\:dq-ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }

  .xl\:focus-within\:dq-ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }

  .xl\:focus-within\:dq-ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }

  .xl\:focus-within\:dq-ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .xl\:focus-within\:dq-ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .xl\:focus-within\:dq-ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .xl\:focus-within\:dq-ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .xl\:focus-within\:dq-ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .xl\:focus-within\:dq-ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .xl\:focus-within\:dq-ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .xl\:focus-within\:dq-ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .xl\:focus-within\:dq-ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .xl\:focus-within\:dq-ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .xl\:focus-within\:dq-ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .xl\:focus-within\:dq-ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .xl\:focus-within\:dq-ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .xl\:focus-within\:dq-ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .xl\:focus-within\:dq-ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .xl\:focus-within\:dq-ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .xl\:focus-within\:dq-ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .xl\:focus-within\:dq-ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .xl\:focus-within\:dq-ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .xl\:focus-within\:dq-ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .xl\:focus-within\:dq-ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .xl\:focus-within\:dq-ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .xl\:focus-within\:dq-ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .xl\:focus-within\:dq-ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .xl\:focus-within\:dq-ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .xl\:focus-within\:dq-ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .xl\:focus-within\:dq-ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .xl\:focus-within\:dq-ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .xl\:focus-within\:dq-ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .xl\:focus-within\:dq-ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .xl\:focus-within\:dq-ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .xl\:focus-within\:dq-ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .xl\:focus-within\:dq-ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .xl\:focus-within\:dq-ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .xl\:focus-within\:dq-ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .xl\:focus-within\:dq-ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .xl\:focus-within\:dq-ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .xl\:focus-within\:dq-ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .xl\:focus-within\:dq-ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .xl\:focus-within\:dq-ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .xl\:focus-within\:dq-ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .xl\:focus-within\:dq-ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .xl\:focus-within\:dq-ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .xl\:focus-within\:dq-ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .xl\:focus-within\:dq-ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .xl\:focus-within\:dq-ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .xl\:focus-within\:dq-ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .xl\:focus-within\:dq-ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .xl\:focus-within\:dq-ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .xl\:focus-within\:dq-ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .xl\:focus-within\:dq-ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .xl\:focus-within\:dq-ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .xl\:focus-within\:dq-ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .xl\:focus-within\:dq-ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .xl\:focus-within\:dq-ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .xl\:focus-within\:dq-ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .xl\:focus-within\:dq-ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .xl\:focus-within\:dq-ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .xl\:focus-within\:dq-ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .xl\:focus-within\:dq-ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .xl\:focus\:dq-ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .xl\:focus\:dq-ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .xl\:focus\:dq-ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .xl\:focus\:dq-ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .xl\:focus\:dq-ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .xl\:focus\:dq-ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .xl\:focus\:dq-ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .xl\:focus\:dq-ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .xl\:focus\:dq-ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .xl\:focus\:dq-ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .xl\:focus\:dq-ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .xl\:focus\:dq-ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .xl\:focus\:dq-ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .xl\:focus\:dq-ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .xl\:focus\:dq-ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }

  .xl\:focus\:dq-ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }

  .xl\:focus\:dq-ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }

  .xl\:focus\:dq-ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }

  .xl\:focus\:dq-ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }

  .xl\:focus\:dq-ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }

  .xl\:focus\:dq-ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }

  .xl\:focus\:dq-ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }

  .xl\:focus\:dq-ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }

  .xl\:focus\:dq-ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }

  .xl\:focus\:dq-ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .xl\:focus\:dq-ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .xl\:focus\:dq-ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .xl\:focus\:dq-ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .xl\:focus\:dq-ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .xl\:focus\:dq-ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .xl\:focus\:dq-ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .xl\:focus\:dq-ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .xl\:focus\:dq-ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .xl\:focus\:dq-ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .xl\:focus\:dq-ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .xl\:focus\:dq-ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .xl\:focus\:dq-ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .xl\:focus\:dq-ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .xl\:focus\:dq-ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .xl\:focus\:dq-ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .xl\:focus\:dq-ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .xl\:focus\:dq-ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .xl\:focus\:dq-ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .xl\:focus\:dq-ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .xl\:focus\:dq-ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .xl\:focus\:dq-ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .xl\:focus\:dq-ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .xl\:focus\:dq-ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .xl\:focus\:dq-ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .xl\:focus\:dq-ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .xl\:focus\:dq-ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .xl\:focus\:dq-ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .xl\:focus\:dq-ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .xl\:focus\:dq-ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .xl\:focus\:dq-ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .xl\:focus\:dq-ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .xl\:focus\:dq-ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .xl\:focus\:dq-ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .xl\:focus\:dq-ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .xl\:focus\:dq-ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .xl\:focus\:dq-ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .xl\:focus\:dq-ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .xl\:focus\:dq-ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .xl\:focus\:dq-ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .xl\:focus\:dq-ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .xl\:focus\:dq-ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .xl\:focus\:dq-ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .xl\:focus\:dq-ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .xl\:focus\:dq-ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .xl\:focus\:dq-ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .xl\:focus\:dq-ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .xl\:focus\:dq-ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .xl\:focus\:dq-ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .xl\:focus\:dq-ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .xl\:focus\:dq-ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .xl\:focus\:dq-ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .xl\:focus\:dq-ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .xl\:focus\:dq-ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .xl\:focus\:dq-ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .xl\:focus\:dq-ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .xl\:focus\:dq-ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .xl\:focus\:dq-ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .xl\:focus\:dq-ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .xl\:focus\:dq-ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .xl\:dq-filter {
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:dq-filter-none {
    -webkit-filter: none;
            filter: none;
  }

  .xl\:dq-blur-0 {
    --tw-blur: blur(0);
  }

  .xl\:dq-blur-none {
    --tw-blur: blur(0);
  }

  .xl\:dq-blur-sm {
    --tw-blur: blur(4px);
  }

  .xl\:dq-blur {
    --tw-blur: blur(8px);
  }

  .xl\:dq-blur-md {
    --tw-blur: blur(12px);
  }

  .xl\:dq-blur-lg {
    --tw-blur: blur(16px);
  }

  .xl\:dq-blur-xl {
    --tw-blur: blur(24px);
  }

  .xl\:dq-blur-2xl {
    --tw-blur: blur(40px);
  }

  .xl\:dq-blur-3xl {
    --tw-blur: blur(64px);
  }

  .xl\:dq-brightness-0 {
    --tw-brightness: brightness(0);
  }

  .xl\:dq-brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .xl\:dq-brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .xl\:dq-brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .xl\:dq-brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .xl\:dq-brightness-100 {
    --tw-brightness: brightness(1);
  }

  .xl\:dq-brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .xl\:dq-brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .xl\:dq-brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .xl\:dq-brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .xl\:dq-brightness-200 {
    --tw-brightness: brightness(2);
  }

  .xl\:dq-contrast-0 {
    --tw-contrast: contrast(0);
  }

  .xl\:dq-contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .xl\:dq-contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .xl\:dq-contrast-100 {
    --tw-contrast: contrast(1);
  }

  .xl\:dq-contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .xl\:dq-contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .xl\:dq-contrast-200 {
    --tw-contrast: contrast(2);
  }

  .xl\:dq-drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .xl\:dq-drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .xl\:dq-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .xl\:dq-drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .xl\:dq-drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .xl\:dq-drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .xl\:dq-drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .xl\:dq-grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .xl\:dq-grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .xl\:dq-hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .xl\:dq-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .xl\:dq-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .xl\:dq-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .xl\:dq-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .xl\:dq-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .xl\:dq--hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .xl\:dq--hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .xl\:dq--hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .xl\:dq--hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .xl\:dq--hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .xl\:dq-invert-0 {
    --tw-invert: invert(0);
  }

  .xl\:dq-invert {
    --tw-invert: invert(100%);
  }

  .xl\:dq-saturate-0 {
    --tw-saturate: saturate(0);
  }

  .xl\:dq-saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .xl\:dq-saturate-100 {
    --tw-saturate: saturate(1);
  }

  .xl\:dq-saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .xl\:dq-saturate-200 {
    --tw-saturate: saturate(2);
  }

  .xl\:dq-sepia-0 {
    --tw-sepia: sepia(0);
  }

  .xl\:dq-sepia {
    --tw-sepia: sepia(100%);
  }

  .xl\:dq-backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:dq-backdrop-filter-none {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }

  .xl\:dq-backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }

  .xl\:dq-backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .xl\:dq-backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .xl\:dq-backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .xl\:dq-backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .xl\:dq-backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .xl\:dq-backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .xl\:dq-backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .xl\:dq-backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .xl\:dq-backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .xl\:dq-backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .xl\:dq-backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .xl\:dq-backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .xl\:dq-backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .xl\:dq-backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .xl\:dq-backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .xl\:dq-backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .xl\:dq-backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .xl\:dq-backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .xl\:dq-backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .xl\:dq-backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .xl\:dq-backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .xl\:dq-backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .xl\:dq-backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .xl\:dq-backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .xl\:dq-backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .xl\:dq-backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .xl\:dq-backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .xl\:dq-backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .xl\:dq-backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .xl\:dq-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .xl\:dq-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .xl\:dq-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .xl\:dq-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .xl\:dq-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .xl\:dq--backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .xl\:dq--backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .xl\:dq--backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .xl\:dq--backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .xl\:dq--backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .xl\:dq-backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .xl\:dq-backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .xl\:dq-backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .xl\:dq-backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }

  .xl\:dq-backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }

  .xl\:dq-backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }

  .xl\:dq-backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }

  .xl\:dq-backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }

  .xl\:dq-backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }

  .xl\:dq-backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }

  .xl\:dq-backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }

  .xl\:dq-backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }

  .xl\:dq-backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }

  .xl\:dq-backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }

  .xl\:dq-backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }

  .xl\:dq-backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }

  .xl\:dq-backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .xl\:dq-backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .xl\:dq-backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .xl\:dq-backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .xl\:dq-backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .xl\:dq-backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .xl\:dq-backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .xl\:dq-backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .xl\:dq-transition-none {
    transition-property: none;
  }

  .xl\:dq-transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .xl\:dq-transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .xl\:dq-transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .xl\:dq-transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .xl\:dq-transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .xl\:dq-transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .xl\:dq-delay-75 {
    transition-delay: 75ms;
  }

  .xl\:dq-delay-100 {
    transition-delay: 100ms;
  }

  .xl\:dq-delay-150 {
    transition-delay: 150ms;
  }

  .xl\:dq-delay-200 {
    transition-delay: 200ms;
  }

  .xl\:dq-delay-300 {
    transition-delay: 300ms;
  }

  .xl\:dq-delay-500 {
    transition-delay: 500ms;
  }

  .xl\:dq-delay-700 {
    transition-delay: 700ms;
  }

  .xl\:dq-delay-1000 {
    transition-delay: 1000ms;
  }

  .xl\:dq-duration-75 {
    transition-duration: 75ms;
  }

  .xl\:dq-duration-100 {
    transition-duration: 100ms;
  }

  .xl\:dq-duration-150 {
    transition-duration: 150ms;
  }

  .xl\:dq-duration-200 {
    transition-duration: 200ms;
  }

  .xl\:dq-duration-300 {
    transition-duration: 300ms;
  }

  .xl\:dq-duration-500 {
    transition-duration: 500ms;
  }

  .xl\:dq-duration-700 {
    transition-duration: 700ms;
  }

  .xl\:dq-duration-1000 {
    transition-duration: 1000ms;
  }

  .xl\:dq-ease-linear {
    transition-timing-function: linear;
  }

  .xl\:dq-ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .xl\:dq-ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .xl\:dq-ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 1536px) {
  .\32xl\:dq-container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .\32xl\:dq-container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .\32xl\:dq-container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .\32xl\:dq-container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .\32xl\:dq-container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .\32xl\:dq-container {
      max-width: 1536px;
    }
  }

  .\32xl\:dq-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .\32xl\:dq-not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .\32xl\:focus-within\:dq-sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .\32xl\:focus-within\:dq-not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .\32xl\:focus\:dq-sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .\32xl\:focus\:dq-not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .\32xl\:dq-pointer-events-none {
    pointer-events: none;
  }

  .\32xl\:dq-pointer-events-auto {
    pointer-events: auto;
  }

  .\32xl\:dq-visible {
    visibility: visible;
  }

  .\32xl\:dq-invisible {
    visibility: hidden;
  }

  .\32xl\:dq-static {
    position: static;
  }

  .\32xl\:dq-fixed {
    position: fixed;
  }

  .\32xl\:dq-absolute {
    position: absolute;
  }

  .\32xl\:dq-relative {
    position: relative;
  }

  .\32xl\:dq-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .\32xl\:dq-inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .\32xl\:dq-inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }

  .\32xl\:dq-inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }

  .\32xl\:dq-inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }

  .\32xl\:dq-inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }

  .\32xl\:dq-inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }

  .\32xl\:dq-inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }

  .\32xl\:dq-inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }

  .\32xl\:dq-inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }

  .\32xl\:dq-inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }

  .\32xl\:dq-inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }

  .\32xl\:dq-inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }

  .\32xl\:dq-inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }

  .\32xl\:dq-inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }

  .\32xl\:dq-inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }

  .\32xl\:dq-inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }

  .\32xl\:dq-inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }

  .\32xl\:dq-inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }

  .\32xl\:dq-inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }

  .\32xl\:dq-inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }

  .\32xl\:dq-inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }

  .\32xl\:dq-inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }

  .\32xl\:dq-inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }

  .\32xl\:dq-inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }

  .\32xl\:dq-inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }

  .\32xl\:dq-inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }

  .\32xl\:dq-inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }

  .\32xl\:dq-inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }

  .\32xl\:dq-inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }

  .\32xl\:dq-inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }

  .\32xl\:dq-inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .\32xl\:dq-inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }

  .\32xl\:dq-inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }

  .\32xl\:dq-inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }

  .\32xl\:dq-inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }

  .\32xl\:dq-inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }

  .\32xl\:dq--inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .\32xl\:dq--inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }

  .\32xl\:dq--inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }

  .\32xl\:dq--inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }

  .\32xl\:dq--inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }

  .\32xl\:dq--inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }

  .\32xl\:dq--inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }

  .\32xl\:dq--inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }

  .\32xl\:dq--inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }

  .\32xl\:dq--inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }

  .\32xl\:dq--inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }

  .\32xl\:dq--inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }

  .\32xl\:dq--inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }

  .\32xl\:dq--inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }

  .\32xl\:dq--inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }

  .\32xl\:dq--inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }

  .\32xl\:dq--inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }

  .\32xl\:dq--inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }

  .\32xl\:dq--inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }

  .\32xl\:dq--inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }

  .\32xl\:dq--inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }

  .\32xl\:dq--inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }

  .\32xl\:dq--inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }

  .\32xl\:dq--inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }

  .\32xl\:dq--inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }

  .\32xl\:dq--inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }

  .\32xl\:dq--inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }

  .\32xl\:dq--inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }

  .\32xl\:dq--inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }

  .\32xl\:dq--inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }

  .\32xl\:dq--inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }

  .\32xl\:dq--inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }

  .\32xl\:dq--inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }

  .\32xl\:dq--inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }

  .\32xl\:dq--inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }

  .\32xl\:dq-inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }

  .\32xl\:dq-inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }

  .\32xl\:dq-inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }

  .\32xl\:dq-inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }

  .\32xl\:dq-inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }

  .\32xl\:dq-inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }

  .\32xl\:dq-inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }

  .\32xl\:dq--inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }

  .\32xl\:dq--inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }

  .\32xl\:dq--inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }

  .\32xl\:dq--inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }

  .\32xl\:dq--inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }

  .\32xl\:dq--inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }

  .\32xl\:dq--inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }

  .\32xl\:dq-inset-x-0 {
    left: 0px;
    right: 0px;
  }

  .\32xl\:dq-inset-x-1 {
    left: 0.25rem;
    right: 0.25rem;
  }

  .\32xl\:dq-inset-x-2 {
    left: 0.5rem;
    right: 0.5rem;
  }

  .\32xl\:dq-inset-x-3 {
    left: 0.75rem;
    right: 0.75rem;
  }

  .\32xl\:dq-inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .\32xl\:dq-inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .\32xl\:dq-inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .\32xl\:dq-inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .\32xl\:dq-inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .\32xl\:dq-inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .\32xl\:dq-inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .\32xl\:dq-inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .\32xl\:dq-inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .\32xl\:dq-inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .\32xl\:dq-inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .\32xl\:dq-inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .\32xl\:dq-inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .\32xl\:dq-inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .\32xl\:dq-inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .\32xl\:dq-inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .\32xl\:dq-inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .\32xl\:dq-inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .\32xl\:dq-inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .\32xl\:dq-inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .\32xl\:dq-inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .\32xl\:dq-inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .\32xl\:dq-inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .\32xl\:dq-inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .\32xl\:dq-inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .\32xl\:dq-inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .\32xl\:dq-inset-x-auto {
    left: auto;
    right: auto;
  }

  .\32xl\:dq-inset-x-px {
    left: 1px;
    right: 1px;
  }

  .\32xl\:dq-inset-x-0\.5 {
    left: 0.125rem;
    right: 0.125rem;
  }

  .\32xl\:dq-inset-x-1\.5 {
    left: 0.375rem;
    right: 0.375rem;
  }

  .\32xl\:dq-inset-x-2\.5 {
    left: 0.625rem;
    right: 0.625rem;
  }

  .\32xl\:dq-inset-x-3\.5 {
    left: 0.875rem;
    right: 0.875rem;
  }

  .\32xl\:dq--inset-x-0 {
    left: 0px;
    right: 0px;
  }

  .\32xl\:dq--inset-x-1 {
    left: -0.25rem;
    right: -0.25rem;
  }

  .\32xl\:dq--inset-x-2 {
    left: -0.5rem;
    right: -0.5rem;
  }

  .\32xl\:dq--inset-x-3 {
    left: -0.75rem;
    right: -0.75rem;
  }

  .\32xl\:dq--inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .\32xl\:dq--inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .\32xl\:dq--inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .\32xl\:dq--inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .\32xl\:dq--inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .\32xl\:dq--inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .\32xl\:dq--inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .\32xl\:dq--inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .\32xl\:dq--inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .\32xl\:dq--inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .\32xl\:dq--inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .\32xl\:dq--inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .\32xl\:dq--inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .\32xl\:dq--inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .\32xl\:dq--inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .\32xl\:dq--inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .\32xl\:dq--inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .\32xl\:dq--inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .\32xl\:dq--inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .\32xl\:dq--inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .\32xl\:dq--inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .\32xl\:dq--inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .\32xl\:dq--inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .\32xl\:dq--inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .\32xl\:dq--inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .\32xl\:dq--inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .\32xl\:dq--inset-x-px {
    left: -1px;
    right: -1px;
  }

  .\32xl\:dq--inset-x-0\.5 {
    left: -0.125rem;
    right: -0.125rem;
  }

  .\32xl\:dq--inset-x-1\.5 {
    left: -0.375rem;
    right: -0.375rem;
  }

  .\32xl\:dq--inset-x-2\.5 {
    left: -0.625rem;
    right: -0.625rem;
  }

  .\32xl\:dq--inset-x-3\.5 {
    left: -0.875rem;
    right: -0.875rem;
  }

  .\32xl\:dq-inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .\32xl\:dq-inset-x-1\/3 {
    left: 33.333333%;
    right: 33.333333%;
  }

  .\32xl\:dq-inset-x-2\/3 {
    left: 66.666667%;
    right: 66.666667%;
  }

  .\32xl\:dq-inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .\32xl\:dq-inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .\32xl\:dq-inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .\32xl\:dq-inset-x-full {
    left: 100%;
    right: 100%;
  }

  .\32xl\:dq--inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .\32xl\:dq--inset-x-1\/3 {
    left: -33.333333%;
    right: -33.333333%;
  }

  .\32xl\:dq--inset-x-2\/3 {
    left: -66.666667%;
    right: -66.666667%;
  }

  .\32xl\:dq--inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .\32xl\:dq--inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .\32xl\:dq--inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .\32xl\:dq--inset-x-full {
    left: -100%;
    right: -100%;
  }

  .\32xl\:dq-inset-y-0 {
    top: 0px;
    bottom: 0px;
  }

  .\32xl\:dq-inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }

  .\32xl\:dq-inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }

  .\32xl\:dq-inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }

  .\32xl\:dq-inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .\32xl\:dq-inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .\32xl\:dq-inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .\32xl\:dq-inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .\32xl\:dq-inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .\32xl\:dq-inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .\32xl\:dq-inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .\32xl\:dq-inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .\32xl\:dq-inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .\32xl\:dq-inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .\32xl\:dq-inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .\32xl\:dq-inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .\32xl\:dq-inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .\32xl\:dq-inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .\32xl\:dq-inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .\32xl\:dq-inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .\32xl\:dq-inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .\32xl\:dq-inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .\32xl\:dq-inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .\32xl\:dq-inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .\32xl\:dq-inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .\32xl\:dq-inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .\32xl\:dq-inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .\32xl\:dq-inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .\32xl\:dq-inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .\32xl\:dq-inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .\32xl\:dq-inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .\32xl\:dq-inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .\32xl\:dq-inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }

  .\32xl\:dq-inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }

  .\32xl\:dq-inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }

  .\32xl\:dq-inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }

  .\32xl\:dq--inset-y-0 {
    top: 0px;
    bottom: 0px;
  }

  .\32xl\:dq--inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }

  .\32xl\:dq--inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }

  .\32xl\:dq--inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }

  .\32xl\:dq--inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .\32xl\:dq--inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .\32xl\:dq--inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .\32xl\:dq--inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .\32xl\:dq--inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .\32xl\:dq--inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .\32xl\:dq--inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .\32xl\:dq--inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .\32xl\:dq--inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .\32xl\:dq--inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .\32xl\:dq--inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .\32xl\:dq--inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .\32xl\:dq--inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .\32xl\:dq--inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .\32xl\:dq--inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .\32xl\:dq--inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .\32xl\:dq--inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .\32xl\:dq--inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .\32xl\:dq--inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .\32xl\:dq--inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .\32xl\:dq--inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .\32xl\:dq--inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .\32xl\:dq--inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .\32xl\:dq--inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .\32xl\:dq--inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .\32xl\:dq--inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .\32xl\:dq--inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .\32xl\:dq--inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }

  .\32xl\:dq--inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }

  .\32xl\:dq--inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }

  .\32xl\:dq--inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }

  .\32xl\:dq-inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .\32xl\:dq-inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }

  .\32xl\:dq-inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }

  .\32xl\:dq-inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .\32xl\:dq-inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .\32xl\:dq-inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .\32xl\:dq-inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .\32xl\:dq--inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .\32xl\:dq--inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }

  .\32xl\:dq--inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }

  .\32xl\:dq--inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .\32xl\:dq--inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .\32xl\:dq--inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .\32xl\:dq--inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .\32xl\:dq-top-0 {
    top: 0px;
  }

  .\32xl\:dq-top-1 {
    top: 0.25rem;
  }

  .\32xl\:dq-top-2 {
    top: 0.5rem;
  }

  .\32xl\:dq-top-3 {
    top: 0.75rem;
  }

  .\32xl\:dq-top-4 {
    top: 1rem;
  }

  .\32xl\:dq-top-5 {
    top: 1.25rem;
  }

  .\32xl\:dq-top-6 {
    top: 1.5rem;
  }

  .\32xl\:dq-top-7 {
    top: 1.75rem;
  }

  .\32xl\:dq-top-8 {
    top: 2rem;
  }

  .\32xl\:dq-top-9 {
    top: 2.25rem;
  }

  .\32xl\:dq-top-10 {
    top: 2.5rem;
  }

  .\32xl\:dq-top-11 {
    top: 2.75rem;
  }

  .\32xl\:dq-top-12 {
    top: 3rem;
  }

  .\32xl\:dq-top-14 {
    top: 3.5rem;
  }

  .\32xl\:dq-top-16 {
    top: 4rem;
  }

  .\32xl\:dq-top-20 {
    top: 5rem;
  }

  .\32xl\:dq-top-24 {
    top: 6rem;
  }

  .\32xl\:dq-top-28 {
    top: 7rem;
  }

  .\32xl\:dq-top-32 {
    top: 8rem;
  }

  .\32xl\:dq-top-36 {
    top: 9rem;
  }

  .\32xl\:dq-top-40 {
    top: 10rem;
  }

  .\32xl\:dq-top-44 {
    top: 11rem;
  }

  .\32xl\:dq-top-48 {
    top: 12rem;
  }

  .\32xl\:dq-top-52 {
    top: 13rem;
  }

  .\32xl\:dq-top-56 {
    top: 14rem;
  }

  .\32xl\:dq-top-60 {
    top: 15rem;
  }

  .\32xl\:dq-top-64 {
    top: 16rem;
  }

  .\32xl\:dq-top-72 {
    top: 18rem;
  }

  .\32xl\:dq-top-80 {
    top: 20rem;
  }

  .\32xl\:dq-top-96 {
    top: 24rem;
  }

  .\32xl\:dq-top-auto {
    top: auto;
  }

  .\32xl\:dq-top-px {
    top: 1px;
  }

  .\32xl\:dq-top-0\.5 {
    top: 0.125rem;
  }

  .\32xl\:dq-top-1\.5 {
    top: 0.375rem;
  }

  .\32xl\:dq-top-2\.5 {
    top: 0.625rem;
  }

  .\32xl\:dq-top-3\.5 {
    top: 0.875rem;
  }

  .\32xl\:dq--top-0 {
    top: 0px;
  }

  .\32xl\:dq--top-1 {
    top: -0.25rem;
  }

  .\32xl\:dq--top-2 {
    top: -0.5rem;
  }

  .\32xl\:dq--top-3 {
    top: -0.75rem;
  }

  .\32xl\:dq--top-4 {
    top: -1rem;
  }

  .\32xl\:dq--top-5 {
    top: -1.25rem;
  }

  .\32xl\:dq--top-6 {
    top: -1.5rem;
  }

  .\32xl\:dq--top-7 {
    top: -1.75rem;
  }

  .\32xl\:dq--top-8 {
    top: -2rem;
  }

  .\32xl\:dq--top-9 {
    top: -2.25rem;
  }

  .\32xl\:dq--top-10 {
    top: -2.5rem;
  }

  .\32xl\:dq--top-11 {
    top: -2.75rem;
  }

  .\32xl\:dq--top-12 {
    top: -3rem;
  }

  .\32xl\:dq--top-14 {
    top: -3.5rem;
  }

  .\32xl\:dq--top-16 {
    top: -4rem;
  }

  .\32xl\:dq--top-20 {
    top: -5rem;
  }

  .\32xl\:dq--top-24 {
    top: -6rem;
  }

  .\32xl\:dq--top-28 {
    top: -7rem;
  }

  .\32xl\:dq--top-32 {
    top: -8rem;
  }

  .\32xl\:dq--top-36 {
    top: -9rem;
  }

  .\32xl\:dq--top-40 {
    top: -10rem;
  }

  .\32xl\:dq--top-44 {
    top: -11rem;
  }

  .\32xl\:dq--top-48 {
    top: -12rem;
  }

  .\32xl\:dq--top-52 {
    top: -13rem;
  }

  .\32xl\:dq--top-56 {
    top: -14rem;
  }

  .\32xl\:dq--top-60 {
    top: -15rem;
  }

  .\32xl\:dq--top-64 {
    top: -16rem;
  }

  .\32xl\:dq--top-72 {
    top: -18rem;
  }

  .\32xl\:dq--top-80 {
    top: -20rem;
  }

  .\32xl\:dq--top-96 {
    top: -24rem;
  }

  .\32xl\:dq--top-px {
    top: -1px;
  }

  .\32xl\:dq--top-0\.5 {
    top: -0.125rem;
  }

  .\32xl\:dq--top-1\.5 {
    top: -0.375rem;
  }

  .\32xl\:dq--top-2\.5 {
    top: -0.625rem;
  }

  .\32xl\:dq--top-3\.5 {
    top: -0.875rem;
  }

  .\32xl\:dq-top-1\/2 {
    top: 50%;
  }

  .\32xl\:dq-top-1\/3 {
    top: 33.333333%;
  }

  .\32xl\:dq-top-2\/3 {
    top: 66.666667%;
  }

  .\32xl\:dq-top-1\/4 {
    top: 25%;
  }

  .\32xl\:dq-top-2\/4 {
    top: 50%;
  }

  .\32xl\:dq-top-3\/4 {
    top: 75%;
  }

  .\32xl\:dq-top-full {
    top: 100%;
  }

  .\32xl\:dq--top-1\/2 {
    top: -50%;
  }

  .\32xl\:dq--top-1\/3 {
    top: -33.333333%;
  }

  .\32xl\:dq--top-2\/3 {
    top: -66.666667%;
  }

  .\32xl\:dq--top-1\/4 {
    top: -25%;
  }

  .\32xl\:dq--top-2\/4 {
    top: -50%;
  }

  .\32xl\:dq--top-3\/4 {
    top: -75%;
  }

  .\32xl\:dq--top-full {
    top: -100%;
  }

  .\32xl\:dq-right-0 {
    right: 0px;
  }

  .\32xl\:dq-right-1 {
    right: 0.25rem;
  }

  .\32xl\:dq-right-2 {
    right: 0.5rem;
  }

  .\32xl\:dq-right-3 {
    right: 0.75rem;
  }

  .\32xl\:dq-right-4 {
    right: 1rem;
  }

  .\32xl\:dq-right-5 {
    right: 1.25rem;
  }

  .\32xl\:dq-right-6 {
    right: 1.5rem;
  }

  .\32xl\:dq-right-7 {
    right: 1.75rem;
  }

  .\32xl\:dq-right-8 {
    right: 2rem;
  }

  .\32xl\:dq-right-9 {
    right: 2.25rem;
  }

  .\32xl\:dq-right-10 {
    right: 2.5rem;
  }

  .\32xl\:dq-right-11 {
    right: 2.75rem;
  }

  .\32xl\:dq-right-12 {
    right: 3rem;
  }

  .\32xl\:dq-right-14 {
    right: 3.5rem;
  }

  .\32xl\:dq-right-16 {
    right: 4rem;
  }

  .\32xl\:dq-right-20 {
    right: 5rem;
  }

  .\32xl\:dq-right-24 {
    right: 6rem;
  }

  .\32xl\:dq-right-28 {
    right: 7rem;
  }

  .\32xl\:dq-right-32 {
    right: 8rem;
  }

  .\32xl\:dq-right-36 {
    right: 9rem;
  }

  .\32xl\:dq-right-40 {
    right: 10rem;
  }

  .\32xl\:dq-right-44 {
    right: 11rem;
  }

  .\32xl\:dq-right-48 {
    right: 12rem;
  }

  .\32xl\:dq-right-52 {
    right: 13rem;
  }

  .\32xl\:dq-right-56 {
    right: 14rem;
  }

  .\32xl\:dq-right-60 {
    right: 15rem;
  }

  .\32xl\:dq-right-64 {
    right: 16rem;
  }

  .\32xl\:dq-right-72 {
    right: 18rem;
  }

  .\32xl\:dq-right-80 {
    right: 20rem;
  }

  .\32xl\:dq-right-96 {
    right: 24rem;
  }

  .\32xl\:dq-right-auto {
    right: auto;
  }

  .\32xl\:dq-right-px {
    right: 1px;
  }

  .\32xl\:dq-right-0\.5 {
    right: 0.125rem;
  }

  .\32xl\:dq-right-1\.5 {
    right: 0.375rem;
  }

  .\32xl\:dq-right-2\.5 {
    right: 0.625rem;
  }

  .\32xl\:dq-right-3\.5 {
    right: 0.875rem;
  }

  .\32xl\:dq--right-0 {
    right: 0px;
  }

  .\32xl\:dq--right-1 {
    right: -0.25rem;
  }

  .\32xl\:dq--right-2 {
    right: -0.5rem;
  }

  .\32xl\:dq--right-3 {
    right: -0.75rem;
  }

  .\32xl\:dq--right-4 {
    right: -1rem;
  }

  .\32xl\:dq--right-5 {
    right: -1.25rem;
  }

  .\32xl\:dq--right-6 {
    right: -1.5rem;
  }

  .\32xl\:dq--right-7 {
    right: -1.75rem;
  }

  .\32xl\:dq--right-8 {
    right: -2rem;
  }

  .\32xl\:dq--right-9 {
    right: -2.25rem;
  }

  .\32xl\:dq--right-10 {
    right: -2.5rem;
  }

  .\32xl\:dq--right-11 {
    right: -2.75rem;
  }

  .\32xl\:dq--right-12 {
    right: -3rem;
  }

  .\32xl\:dq--right-14 {
    right: -3.5rem;
  }

  .\32xl\:dq--right-16 {
    right: -4rem;
  }

  .\32xl\:dq--right-20 {
    right: -5rem;
  }

  .\32xl\:dq--right-24 {
    right: -6rem;
  }

  .\32xl\:dq--right-28 {
    right: -7rem;
  }

  .\32xl\:dq--right-32 {
    right: -8rem;
  }

  .\32xl\:dq--right-36 {
    right: -9rem;
  }

  .\32xl\:dq--right-40 {
    right: -10rem;
  }

  .\32xl\:dq--right-44 {
    right: -11rem;
  }

  .\32xl\:dq--right-48 {
    right: -12rem;
  }

  .\32xl\:dq--right-52 {
    right: -13rem;
  }

  .\32xl\:dq--right-56 {
    right: -14rem;
  }

  .\32xl\:dq--right-60 {
    right: -15rem;
  }

  .\32xl\:dq--right-64 {
    right: -16rem;
  }

  .\32xl\:dq--right-72 {
    right: -18rem;
  }

  .\32xl\:dq--right-80 {
    right: -20rem;
  }

  .\32xl\:dq--right-96 {
    right: -24rem;
  }

  .\32xl\:dq--right-px {
    right: -1px;
  }

  .\32xl\:dq--right-0\.5 {
    right: -0.125rem;
  }

  .\32xl\:dq--right-1\.5 {
    right: -0.375rem;
  }

  .\32xl\:dq--right-2\.5 {
    right: -0.625rem;
  }

  .\32xl\:dq--right-3\.5 {
    right: -0.875rem;
  }

  .\32xl\:dq-right-1\/2 {
    right: 50%;
  }

  .\32xl\:dq-right-1\/3 {
    right: 33.333333%;
  }

  .\32xl\:dq-right-2\/3 {
    right: 66.666667%;
  }

  .\32xl\:dq-right-1\/4 {
    right: 25%;
  }

  .\32xl\:dq-right-2\/4 {
    right: 50%;
  }

  .\32xl\:dq-right-3\/4 {
    right: 75%;
  }

  .\32xl\:dq-right-full {
    right: 100%;
  }

  .\32xl\:dq--right-1\/2 {
    right: -50%;
  }

  .\32xl\:dq--right-1\/3 {
    right: -33.333333%;
  }

  .\32xl\:dq--right-2\/3 {
    right: -66.666667%;
  }

  .\32xl\:dq--right-1\/4 {
    right: -25%;
  }

  .\32xl\:dq--right-2\/4 {
    right: -50%;
  }

  .\32xl\:dq--right-3\/4 {
    right: -75%;
  }

  .\32xl\:dq--right-full {
    right: -100%;
  }

  .\32xl\:dq-bottom-0 {
    bottom: 0px;
  }

  .\32xl\:dq-bottom-1 {
    bottom: 0.25rem;
  }

  .\32xl\:dq-bottom-2 {
    bottom: 0.5rem;
  }

  .\32xl\:dq-bottom-3 {
    bottom: 0.75rem;
  }

  .\32xl\:dq-bottom-4 {
    bottom: 1rem;
  }

  .\32xl\:dq-bottom-5 {
    bottom: 1.25rem;
  }

  .\32xl\:dq-bottom-6 {
    bottom: 1.5rem;
  }

  .\32xl\:dq-bottom-7 {
    bottom: 1.75rem;
  }

  .\32xl\:dq-bottom-8 {
    bottom: 2rem;
  }

  .\32xl\:dq-bottom-9 {
    bottom: 2.25rem;
  }

  .\32xl\:dq-bottom-10 {
    bottom: 2.5rem;
  }

  .\32xl\:dq-bottom-11 {
    bottom: 2.75rem;
  }

  .\32xl\:dq-bottom-12 {
    bottom: 3rem;
  }

  .\32xl\:dq-bottom-14 {
    bottom: 3.5rem;
  }

  .\32xl\:dq-bottom-16 {
    bottom: 4rem;
  }

  .\32xl\:dq-bottom-20 {
    bottom: 5rem;
  }

  .\32xl\:dq-bottom-24 {
    bottom: 6rem;
  }

  .\32xl\:dq-bottom-28 {
    bottom: 7rem;
  }

  .\32xl\:dq-bottom-32 {
    bottom: 8rem;
  }

  .\32xl\:dq-bottom-36 {
    bottom: 9rem;
  }

  .\32xl\:dq-bottom-40 {
    bottom: 10rem;
  }

  .\32xl\:dq-bottom-44 {
    bottom: 11rem;
  }

  .\32xl\:dq-bottom-48 {
    bottom: 12rem;
  }

  .\32xl\:dq-bottom-52 {
    bottom: 13rem;
  }

  .\32xl\:dq-bottom-56 {
    bottom: 14rem;
  }

  .\32xl\:dq-bottom-60 {
    bottom: 15rem;
  }

  .\32xl\:dq-bottom-64 {
    bottom: 16rem;
  }

  .\32xl\:dq-bottom-72 {
    bottom: 18rem;
  }

  .\32xl\:dq-bottom-80 {
    bottom: 20rem;
  }

  .\32xl\:dq-bottom-96 {
    bottom: 24rem;
  }

  .\32xl\:dq-bottom-auto {
    bottom: auto;
  }

  .\32xl\:dq-bottom-px {
    bottom: 1px;
  }

  .\32xl\:dq-bottom-0\.5 {
    bottom: 0.125rem;
  }

  .\32xl\:dq-bottom-1\.5 {
    bottom: 0.375rem;
  }

  .\32xl\:dq-bottom-2\.5 {
    bottom: 0.625rem;
  }

  .\32xl\:dq-bottom-3\.5 {
    bottom: 0.875rem;
  }

  .\32xl\:dq--bottom-0 {
    bottom: 0px;
  }

  .\32xl\:dq--bottom-1 {
    bottom: -0.25rem;
  }

  .\32xl\:dq--bottom-2 {
    bottom: -0.5rem;
  }

  .\32xl\:dq--bottom-3 {
    bottom: -0.75rem;
  }

  .\32xl\:dq--bottom-4 {
    bottom: -1rem;
  }

  .\32xl\:dq--bottom-5 {
    bottom: -1.25rem;
  }

  .\32xl\:dq--bottom-6 {
    bottom: -1.5rem;
  }

  .\32xl\:dq--bottom-7 {
    bottom: -1.75rem;
  }

  .\32xl\:dq--bottom-8 {
    bottom: -2rem;
  }

  .\32xl\:dq--bottom-9 {
    bottom: -2.25rem;
  }

  .\32xl\:dq--bottom-10 {
    bottom: -2.5rem;
  }

  .\32xl\:dq--bottom-11 {
    bottom: -2.75rem;
  }

  .\32xl\:dq--bottom-12 {
    bottom: -3rem;
  }

  .\32xl\:dq--bottom-14 {
    bottom: -3.5rem;
  }

  .\32xl\:dq--bottom-16 {
    bottom: -4rem;
  }

  .\32xl\:dq--bottom-20 {
    bottom: -5rem;
  }

  .\32xl\:dq--bottom-24 {
    bottom: -6rem;
  }

  .\32xl\:dq--bottom-28 {
    bottom: -7rem;
  }

  .\32xl\:dq--bottom-32 {
    bottom: -8rem;
  }

  .\32xl\:dq--bottom-36 {
    bottom: -9rem;
  }

  .\32xl\:dq--bottom-40 {
    bottom: -10rem;
  }

  .\32xl\:dq--bottom-44 {
    bottom: -11rem;
  }

  .\32xl\:dq--bottom-48 {
    bottom: -12rem;
  }

  .\32xl\:dq--bottom-52 {
    bottom: -13rem;
  }

  .\32xl\:dq--bottom-56 {
    bottom: -14rem;
  }

  .\32xl\:dq--bottom-60 {
    bottom: -15rem;
  }

  .\32xl\:dq--bottom-64 {
    bottom: -16rem;
  }

  .\32xl\:dq--bottom-72 {
    bottom: -18rem;
  }

  .\32xl\:dq--bottom-80 {
    bottom: -20rem;
  }

  .\32xl\:dq--bottom-96 {
    bottom: -24rem;
  }

  .\32xl\:dq--bottom-px {
    bottom: -1px;
  }

  .\32xl\:dq--bottom-0\.5 {
    bottom: -0.125rem;
  }

  .\32xl\:dq--bottom-1\.5 {
    bottom: -0.375rem;
  }

  .\32xl\:dq--bottom-2\.5 {
    bottom: -0.625rem;
  }

  .\32xl\:dq--bottom-3\.5 {
    bottom: -0.875rem;
  }

  .\32xl\:dq-bottom-1\/2 {
    bottom: 50%;
  }

  .\32xl\:dq-bottom-1\/3 {
    bottom: 33.333333%;
  }

  .\32xl\:dq-bottom-2\/3 {
    bottom: 66.666667%;
  }

  .\32xl\:dq-bottom-1\/4 {
    bottom: 25%;
  }

  .\32xl\:dq-bottom-2\/4 {
    bottom: 50%;
  }

  .\32xl\:dq-bottom-3\/4 {
    bottom: 75%;
  }

  .\32xl\:dq-bottom-full {
    bottom: 100%;
  }

  .\32xl\:dq--bottom-1\/2 {
    bottom: -50%;
  }

  .\32xl\:dq--bottom-1\/3 {
    bottom: -33.333333%;
  }

  .\32xl\:dq--bottom-2\/3 {
    bottom: -66.666667%;
  }

  .\32xl\:dq--bottom-1\/4 {
    bottom: -25%;
  }

  .\32xl\:dq--bottom-2\/4 {
    bottom: -50%;
  }

  .\32xl\:dq--bottom-3\/4 {
    bottom: -75%;
  }

  .\32xl\:dq--bottom-full {
    bottom: -100%;
  }

  .\32xl\:dq-left-0 {
    left: 0px;
  }

  .\32xl\:dq-left-1 {
    left: 0.25rem;
  }

  .\32xl\:dq-left-2 {
    left: 0.5rem;
  }

  .\32xl\:dq-left-3 {
    left: 0.75rem;
  }

  .\32xl\:dq-left-4 {
    left: 1rem;
  }

  .\32xl\:dq-left-5 {
    left: 1.25rem;
  }

  .\32xl\:dq-left-6 {
    left: 1.5rem;
  }

  .\32xl\:dq-left-7 {
    left: 1.75rem;
  }

  .\32xl\:dq-left-8 {
    left: 2rem;
  }

  .\32xl\:dq-left-9 {
    left: 2.25rem;
  }

  .\32xl\:dq-left-10 {
    left: 2.5rem;
  }

  .\32xl\:dq-left-11 {
    left: 2.75rem;
  }

  .\32xl\:dq-left-12 {
    left: 3rem;
  }

  .\32xl\:dq-left-14 {
    left: 3.5rem;
  }

  .\32xl\:dq-left-16 {
    left: 4rem;
  }

  .\32xl\:dq-left-20 {
    left: 5rem;
  }

  .\32xl\:dq-left-24 {
    left: 6rem;
  }

  .\32xl\:dq-left-28 {
    left: 7rem;
  }

  .\32xl\:dq-left-32 {
    left: 8rem;
  }

  .\32xl\:dq-left-36 {
    left: 9rem;
  }

  .\32xl\:dq-left-40 {
    left: 10rem;
  }

  .\32xl\:dq-left-44 {
    left: 11rem;
  }

  .\32xl\:dq-left-48 {
    left: 12rem;
  }

  .\32xl\:dq-left-52 {
    left: 13rem;
  }

  .\32xl\:dq-left-56 {
    left: 14rem;
  }

  .\32xl\:dq-left-60 {
    left: 15rem;
  }

  .\32xl\:dq-left-64 {
    left: 16rem;
  }

  .\32xl\:dq-left-72 {
    left: 18rem;
  }

  .\32xl\:dq-left-80 {
    left: 20rem;
  }

  .\32xl\:dq-left-96 {
    left: 24rem;
  }

  .\32xl\:dq-left-auto {
    left: auto;
  }

  .\32xl\:dq-left-px {
    left: 1px;
  }

  .\32xl\:dq-left-0\.5 {
    left: 0.125rem;
  }

  .\32xl\:dq-left-1\.5 {
    left: 0.375rem;
  }

  .\32xl\:dq-left-2\.5 {
    left: 0.625rem;
  }

  .\32xl\:dq-left-3\.5 {
    left: 0.875rem;
  }

  .\32xl\:dq--left-0 {
    left: 0px;
  }

  .\32xl\:dq--left-1 {
    left: -0.25rem;
  }

  .\32xl\:dq--left-2 {
    left: -0.5rem;
  }

  .\32xl\:dq--left-3 {
    left: -0.75rem;
  }

  .\32xl\:dq--left-4 {
    left: -1rem;
  }

  .\32xl\:dq--left-5 {
    left: -1.25rem;
  }

  .\32xl\:dq--left-6 {
    left: -1.5rem;
  }

  .\32xl\:dq--left-7 {
    left: -1.75rem;
  }

  .\32xl\:dq--left-8 {
    left: -2rem;
  }

  .\32xl\:dq--left-9 {
    left: -2.25rem;
  }

  .\32xl\:dq--left-10 {
    left: -2.5rem;
  }

  .\32xl\:dq--left-11 {
    left: -2.75rem;
  }

  .\32xl\:dq--left-12 {
    left: -3rem;
  }

  .\32xl\:dq--left-14 {
    left: -3.5rem;
  }

  .\32xl\:dq--left-16 {
    left: -4rem;
  }

  .\32xl\:dq--left-20 {
    left: -5rem;
  }

  .\32xl\:dq--left-24 {
    left: -6rem;
  }

  .\32xl\:dq--left-28 {
    left: -7rem;
  }

  .\32xl\:dq--left-32 {
    left: -8rem;
  }

  .\32xl\:dq--left-36 {
    left: -9rem;
  }

  .\32xl\:dq--left-40 {
    left: -10rem;
  }

  .\32xl\:dq--left-44 {
    left: -11rem;
  }

  .\32xl\:dq--left-48 {
    left: -12rem;
  }

  .\32xl\:dq--left-52 {
    left: -13rem;
  }

  .\32xl\:dq--left-56 {
    left: -14rem;
  }

  .\32xl\:dq--left-60 {
    left: -15rem;
  }

  .\32xl\:dq--left-64 {
    left: -16rem;
  }

  .\32xl\:dq--left-72 {
    left: -18rem;
  }

  .\32xl\:dq--left-80 {
    left: -20rem;
  }

  .\32xl\:dq--left-96 {
    left: -24rem;
  }

  .\32xl\:dq--left-px {
    left: -1px;
  }

  .\32xl\:dq--left-0\.5 {
    left: -0.125rem;
  }

  .\32xl\:dq--left-1\.5 {
    left: -0.375rem;
  }

  .\32xl\:dq--left-2\.5 {
    left: -0.625rem;
  }

  .\32xl\:dq--left-3\.5 {
    left: -0.875rem;
  }

  .\32xl\:dq-left-1\/2 {
    left: 50%;
  }

  .\32xl\:dq-left-1\/3 {
    left: 33.333333%;
  }

  .\32xl\:dq-left-2\/3 {
    left: 66.666667%;
  }

  .\32xl\:dq-left-1\/4 {
    left: 25%;
  }

  .\32xl\:dq-left-2\/4 {
    left: 50%;
  }

  .\32xl\:dq-left-3\/4 {
    left: 75%;
  }

  .\32xl\:dq-left-full {
    left: 100%;
  }

  .\32xl\:dq--left-1\/2 {
    left: -50%;
  }

  .\32xl\:dq--left-1\/3 {
    left: -33.333333%;
  }

  .\32xl\:dq--left-2\/3 {
    left: -66.666667%;
  }

  .\32xl\:dq--left-1\/4 {
    left: -25%;
  }

  .\32xl\:dq--left-2\/4 {
    left: -50%;
  }

  .\32xl\:dq--left-3\/4 {
    left: -75%;
  }

  .\32xl\:dq--left-full {
    left: -100%;
  }

  .\32xl\:dq-isolate {
    isolation: isolate;
  }

  .\32xl\:dq-isolation-auto {
    isolation: auto;
  }

  .\32xl\:dq-z-0 {
    z-index: 0;
  }

  .\32xl\:dq-z-10 {
    z-index: 10;
  }

  .\32xl\:dq-z-20 {
    z-index: 20;
  }

  .\32xl\:dq-z-30 {
    z-index: 30;
  }

  .\32xl\:dq-z-40 {
    z-index: 40;
  }

  .\32xl\:dq-z-50 {
    z-index: 50;
  }

  .\32xl\:dq-z-auto {
    z-index: auto;
  }

  .\32xl\:focus-within\:dq-z-0:focus-within {
    z-index: 0;
  }

  .\32xl\:focus-within\:dq-z-10:focus-within {
    z-index: 10;
  }

  .\32xl\:focus-within\:dq-z-20:focus-within {
    z-index: 20;
  }

  .\32xl\:focus-within\:dq-z-30:focus-within {
    z-index: 30;
  }

  .\32xl\:focus-within\:dq-z-40:focus-within {
    z-index: 40;
  }

  .\32xl\:focus-within\:dq-z-50:focus-within {
    z-index: 50;
  }

  .\32xl\:focus-within\:dq-z-auto:focus-within {
    z-index: auto;
  }

  .\32xl\:focus\:dq-z-0:focus {
    z-index: 0;
  }

  .\32xl\:focus\:dq-z-10:focus {
    z-index: 10;
  }

  .\32xl\:focus\:dq-z-20:focus {
    z-index: 20;
  }

  .\32xl\:focus\:dq-z-30:focus {
    z-index: 30;
  }

  .\32xl\:focus\:dq-z-40:focus {
    z-index: 40;
  }

  .\32xl\:focus\:dq-z-50:focus {
    z-index: 50;
  }

  .\32xl\:focus\:dq-z-auto:focus {
    z-index: auto;
  }

  .\32xl\:dq-order-1 {
    order: 1;
  }

  .\32xl\:dq-order-2 {
    order: 2;
  }

  .\32xl\:dq-order-3 {
    order: 3;
  }

  .\32xl\:dq-order-4 {
    order: 4;
  }

  .\32xl\:dq-order-5 {
    order: 5;
  }

  .\32xl\:dq-order-6 {
    order: 6;
  }

  .\32xl\:dq-order-7 {
    order: 7;
  }

  .\32xl\:dq-order-8 {
    order: 8;
  }

  .\32xl\:dq-order-9 {
    order: 9;
  }

  .\32xl\:dq-order-10 {
    order: 10;
  }

  .\32xl\:dq-order-11 {
    order: 11;
  }

  .\32xl\:dq-order-12 {
    order: 12;
  }

  .\32xl\:dq-order-first {
    order: -9999;
  }

  .\32xl\:dq-order-last {
    order: 9999;
  }

  .\32xl\:dq-order-none {
    order: 0;
  }

  .\32xl\:dq-col-auto {
    grid-column: auto;
  }

  .\32xl\:dq-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .\32xl\:dq-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .\32xl\:dq-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .\32xl\:dq-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .\32xl\:dq-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .\32xl\:dq-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .\32xl\:dq-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\32xl\:dq-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .\32xl\:dq-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .\32xl\:dq-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .\32xl\:dq-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .\32xl\:dq-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .\32xl\:dq-col-span-full {
    grid-column: 1 / -1;
  }

  .\32xl\:dq-col-start-1 {
    grid-column-start: 1;
  }

  .\32xl\:dq-col-start-2 {
    grid-column-start: 2;
  }

  .\32xl\:dq-col-start-3 {
    grid-column-start: 3;
  }

  .\32xl\:dq-col-start-4 {
    grid-column-start: 4;
  }

  .\32xl\:dq-col-start-5 {
    grid-column-start: 5;
  }

  .\32xl\:dq-col-start-6 {
    grid-column-start: 6;
  }

  .\32xl\:dq-col-start-7 {
    grid-column-start: 7;
  }

  .\32xl\:dq-col-start-8 {
    grid-column-start: 8;
  }

  .\32xl\:dq-col-start-9 {
    grid-column-start: 9;
  }

  .\32xl\:dq-col-start-10 {
    grid-column-start: 10;
  }

  .\32xl\:dq-col-start-11 {
    grid-column-start: 11;
  }

  .\32xl\:dq-col-start-12 {
    grid-column-start: 12;
  }

  .\32xl\:dq-col-start-13 {
    grid-column-start: 13;
  }

  .\32xl\:dq-col-start-auto {
    grid-column-start: auto;
  }

  .\32xl\:dq-col-end-1 {
    grid-column-end: 1;
  }

  .\32xl\:dq-col-end-2 {
    grid-column-end: 2;
  }

  .\32xl\:dq-col-end-3 {
    grid-column-end: 3;
  }

  .\32xl\:dq-col-end-4 {
    grid-column-end: 4;
  }

  .\32xl\:dq-col-end-5 {
    grid-column-end: 5;
  }

  .\32xl\:dq-col-end-6 {
    grid-column-end: 6;
  }

  .\32xl\:dq-col-end-7 {
    grid-column-end: 7;
  }

  .\32xl\:dq-col-end-8 {
    grid-column-end: 8;
  }

  .\32xl\:dq-col-end-9 {
    grid-column-end: 9;
  }

  .\32xl\:dq-col-end-10 {
    grid-column-end: 10;
  }

  .\32xl\:dq-col-end-11 {
    grid-column-end: 11;
  }

  .\32xl\:dq-col-end-12 {
    grid-column-end: 12;
  }

  .\32xl\:dq-col-end-13 {
    grid-column-end: 13;
  }

  .\32xl\:dq-col-end-auto {
    grid-column-end: auto;
  }

  .\32xl\:dq-row-auto {
    grid-row: auto;
  }

  .\32xl\:dq-row-span-1 {
    grid-row: span 1 / span 1;
  }

  .\32xl\:dq-row-span-2 {
    grid-row: span 2 / span 2;
  }

  .\32xl\:dq-row-span-3 {
    grid-row: span 3 / span 3;
  }

  .\32xl\:dq-row-span-4 {
    grid-row: span 4 / span 4;
  }

  .\32xl\:dq-row-span-5 {
    grid-row: span 5 / span 5;
  }

  .\32xl\:dq-row-span-6 {
    grid-row: span 6 / span 6;
  }

  .\32xl\:dq-row-span-full {
    grid-row: 1 / -1;
  }

  .\32xl\:dq-row-start-1 {
    grid-row-start: 1;
  }

  .\32xl\:dq-row-start-2 {
    grid-row-start: 2;
  }

  .\32xl\:dq-row-start-3 {
    grid-row-start: 3;
  }

  .\32xl\:dq-row-start-4 {
    grid-row-start: 4;
  }

  .\32xl\:dq-row-start-5 {
    grid-row-start: 5;
  }

  .\32xl\:dq-row-start-6 {
    grid-row-start: 6;
  }

  .\32xl\:dq-row-start-7 {
    grid-row-start: 7;
  }

  .\32xl\:dq-row-start-auto {
    grid-row-start: auto;
  }

  .\32xl\:dq-row-end-1 {
    grid-row-end: 1;
  }

  .\32xl\:dq-row-end-2 {
    grid-row-end: 2;
  }

  .\32xl\:dq-row-end-3 {
    grid-row-end: 3;
  }

  .\32xl\:dq-row-end-4 {
    grid-row-end: 4;
  }

  .\32xl\:dq-row-end-5 {
    grid-row-end: 5;
  }

  .\32xl\:dq-row-end-6 {
    grid-row-end: 6;
  }

  .\32xl\:dq-row-end-7 {
    grid-row-end: 7;
  }

  .\32xl\:dq-row-end-auto {
    grid-row-end: auto;
  }

  .\32xl\:dq-float-right {
    float: right;
  }

  .\32xl\:dq-float-left {
    float: left;
  }

  .\32xl\:dq-float-none {
    float: none;
  }

  .\32xl\:dq-clear-left {
    clear: left;
  }

  .\32xl\:dq-clear-right {
    clear: right;
  }

  .\32xl\:dq-clear-both {
    clear: both;
  }

  .\32xl\:dq-clear-none {
    clear: none;
  }

  .\32xl\:dq-m-0 {
    margin: 0px;
  }

  .\32xl\:dq-m-1 {
    margin: 0.25rem;
  }

  .\32xl\:dq-m-2 {
    margin: 0.5rem;
  }

  .\32xl\:dq-m-3 {
    margin: 0.75rem;
  }

  .\32xl\:dq-m-4 {
    margin: 1rem;
  }

  .\32xl\:dq-m-5 {
    margin: 1.25rem;
  }

  .\32xl\:dq-m-6 {
    margin: 1.5rem;
  }

  .\32xl\:dq-m-7 {
    margin: 1.75rem;
  }

  .\32xl\:dq-m-8 {
    margin: 2rem;
  }

  .\32xl\:dq-m-9 {
    margin: 2.25rem;
  }

  .\32xl\:dq-m-10 {
    margin: 2.5rem;
  }

  .\32xl\:dq-m-11 {
    margin: 2.75rem;
  }

  .\32xl\:dq-m-12 {
    margin: 3rem;
  }

  .\32xl\:dq-m-14 {
    margin: 3.5rem;
  }

  .\32xl\:dq-m-16 {
    margin: 4rem;
  }

  .\32xl\:dq-m-20 {
    margin: 5rem;
  }

  .\32xl\:dq-m-24 {
    margin: 6rem;
  }

  .\32xl\:dq-m-28 {
    margin: 7rem;
  }

  .\32xl\:dq-m-32 {
    margin: 8rem;
  }

  .\32xl\:dq-m-36 {
    margin: 9rem;
  }

  .\32xl\:dq-m-40 {
    margin: 10rem;
  }

  .\32xl\:dq-m-44 {
    margin: 11rem;
  }

  .\32xl\:dq-m-48 {
    margin: 12rem;
  }

  .\32xl\:dq-m-52 {
    margin: 13rem;
  }

  .\32xl\:dq-m-56 {
    margin: 14rem;
  }

  .\32xl\:dq-m-60 {
    margin: 15rem;
  }

  .\32xl\:dq-m-64 {
    margin: 16rem;
  }

  .\32xl\:dq-m-72 {
    margin: 18rem;
  }

  .\32xl\:dq-m-80 {
    margin: 20rem;
  }

  .\32xl\:dq-m-96 {
    margin: 24rem;
  }

  .\32xl\:dq-m-auto {
    margin: auto;
  }

  .\32xl\:dq-m-px {
    margin: 1px;
  }

  .\32xl\:dq-m-0\.5 {
    margin: 0.125rem;
  }

  .\32xl\:dq-m-1\.5 {
    margin: 0.375rem;
  }

  .\32xl\:dq-m-2\.5 {
    margin: 0.625rem;
  }

  .\32xl\:dq-m-3\.5 {
    margin: 0.875rem;
  }

  .\32xl\:dq--m-0 {
    margin: 0px;
  }

  .\32xl\:dq--m-1 {
    margin: -0.25rem;
  }

  .\32xl\:dq--m-2 {
    margin: -0.5rem;
  }

  .\32xl\:dq--m-3 {
    margin: -0.75rem;
  }

  .\32xl\:dq--m-4 {
    margin: -1rem;
  }

  .\32xl\:dq--m-5 {
    margin: -1.25rem;
  }

  .\32xl\:dq--m-6 {
    margin: -1.5rem;
  }

  .\32xl\:dq--m-7 {
    margin: -1.75rem;
  }

  .\32xl\:dq--m-8 {
    margin: -2rem;
  }

  .\32xl\:dq--m-9 {
    margin: -2.25rem;
  }

  .\32xl\:dq--m-10 {
    margin: -2.5rem;
  }

  .\32xl\:dq--m-11 {
    margin: -2.75rem;
  }

  .\32xl\:dq--m-12 {
    margin: -3rem;
  }

  .\32xl\:dq--m-14 {
    margin: -3.5rem;
  }

  .\32xl\:dq--m-16 {
    margin: -4rem;
  }

  .\32xl\:dq--m-20 {
    margin: -5rem;
  }

  .\32xl\:dq--m-24 {
    margin: -6rem;
  }

  .\32xl\:dq--m-28 {
    margin: -7rem;
  }

  .\32xl\:dq--m-32 {
    margin: -8rem;
  }

  .\32xl\:dq--m-36 {
    margin: -9rem;
  }

  .\32xl\:dq--m-40 {
    margin: -10rem;
  }

  .\32xl\:dq--m-44 {
    margin: -11rem;
  }

  .\32xl\:dq--m-48 {
    margin: -12rem;
  }

  .\32xl\:dq--m-52 {
    margin: -13rem;
  }

  .\32xl\:dq--m-56 {
    margin: -14rem;
  }

  .\32xl\:dq--m-60 {
    margin: -15rem;
  }

  .\32xl\:dq--m-64 {
    margin: -16rem;
  }

  .\32xl\:dq--m-72 {
    margin: -18rem;
  }

  .\32xl\:dq--m-80 {
    margin: -20rem;
  }

  .\32xl\:dq--m-96 {
    margin: -24rem;
  }

  .\32xl\:dq--m-px {
    margin: -1px;
  }

  .\32xl\:dq--m-0\.5 {
    margin: -0.125rem;
  }

  .\32xl\:dq--m-1\.5 {
    margin: -0.375rem;
  }

  .\32xl\:dq--m-2\.5 {
    margin: -0.625rem;
  }

  .\32xl\:dq--m-3\.5 {
    margin: -0.875rem;
  }

  .\32xl\:dq-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .\32xl\:dq-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .\32xl\:dq-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .\32xl\:dq-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .\32xl\:dq-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .\32xl\:dq-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .\32xl\:dq-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .\32xl\:dq-mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .\32xl\:dq-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .\32xl\:dq-mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .\32xl\:dq-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .\32xl\:dq-mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .\32xl\:dq-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .\32xl\:dq-mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .\32xl\:dq-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .\32xl\:dq-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .\32xl\:dq-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .\32xl\:dq-mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .\32xl\:dq-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .\32xl\:dq-mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .\32xl\:dq-mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .\32xl\:dq-mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .\32xl\:dq-mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .\32xl\:dq-mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .\32xl\:dq-mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .\32xl\:dq-mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .\32xl\:dq-mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .\32xl\:dq-mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .\32xl\:dq-mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .\32xl\:dq-mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .\32xl\:dq-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .\32xl\:dq-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .\32xl\:dq-mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }

  .\32xl\:dq-mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }

  .\32xl\:dq-mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .\32xl\:dq-mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }

  .\32xl\:dq--mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .\32xl\:dq--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .\32xl\:dq--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .\32xl\:dq--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .\32xl\:dq--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .\32xl\:dq--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .\32xl\:dq--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .\32xl\:dq--mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .\32xl\:dq--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .\32xl\:dq--mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .\32xl\:dq--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .\32xl\:dq--mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .\32xl\:dq--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .\32xl\:dq--mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .\32xl\:dq--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .\32xl\:dq--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .\32xl\:dq--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .\32xl\:dq--mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .\32xl\:dq--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .\32xl\:dq--mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .\32xl\:dq--mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .\32xl\:dq--mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .\32xl\:dq--mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .\32xl\:dq--mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .\32xl\:dq--mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .\32xl\:dq--mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .\32xl\:dq--mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .\32xl\:dq--mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .\32xl\:dq--mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .\32xl\:dq--mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .\32xl\:dq--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .\32xl\:dq--mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }

  .\32xl\:dq--mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }

  .\32xl\:dq--mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }

  .\32xl\:dq--mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }

  .\32xl\:dq-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .\32xl\:dq-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .\32xl\:dq-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .\32xl\:dq-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .\32xl\:dq-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .\32xl\:dq-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .\32xl\:dq-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .\32xl\:dq-my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .\32xl\:dq-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .\32xl\:dq-my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .\32xl\:dq-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .\32xl\:dq-my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .\32xl\:dq-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .\32xl\:dq-my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .\32xl\:dq-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .\32xl\:dq-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .\32xl\:dq-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .\32xl\:dq-my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .\32xl\:dq-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .\32xl\:dq-my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .\32xl\:dq-my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .\32xl\:dq-my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .\32xl\:dq-my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .\32xl\:dq-my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .\32xl\:dq-my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .\32xl\:dq-my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .\32xl\:dq-my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .\32xl\:dq-my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .\32xl\:dq-my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .\32xl\:dq-my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .\32xl\:dq-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .\32xl\:dq-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .\32xl\:dq-my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }

  .\32xl\:dq-my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }

  .\32xl\:dq-my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .\32xl\:dq-my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }

  .\32xl\:dq--my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .\32xl\:dq--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .\32xl\:dq--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .\32xl\:dq--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .\32xl\:dq--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .\32xl\:dq--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .\32xl\:dq--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .\32xl\:dq--my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .\32xl\:dq--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .\32xl\:dq--my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .\32xl\:dq--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .\32xl\:dq--my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .\32xl\:dq--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .\32xl\:dq--my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .\32xl\:dq--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .\32xl\:dq--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .\32xl\:dq--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .\32xl\:dq--my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .\32xl\:dq--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .\32xl\:dq--my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .\32xl\:dq--my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .\32xl\:dq--my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .\32xl\:dq--my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .\32xl\:dq--my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .\32xl\:dq--my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .\32xl\:dq--my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .\32xl\:dq--my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .\32xl\:dq--my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .\32xl\:dq--my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .\32xl\:dq--my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .\32xl\:dq--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .\32xl\:dq--my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }

  .\32xl\:dq--my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }

  .\32xl\:dq--my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }

  .\32xl\:dq--my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }

  .\32xl\:dq-mt-0 {
    margin-top: 0px;
  }

  .\32xl\:dq-mt-1 {
    margin-top: 0.25rem;
  }

  .\32xl\:dq-mt-2 {
    margin-top: 0.5rem;
  }

  .\32xl\:dq-mt-3 {
    margin-top: 0.75rem;
  }

  .\32xl\:dq-mt-4 {
    margin-top: 1rem;
  }

  .\32xl\:dq-mt-5 {
    margin-top: 1.25rem;
  }

  .\32xl\:dq-mt-6 {
    margin-top: 1.5rem;
  }

  .\32xl\:dq-mt-7 {
    margin-top: 1.75rem;
  }

  .\32xl\:dq-mt-8 {
    margin-top: 2rem;
  }

  .\32xl\:dq-mt-9 {
    margin-top: 2.25rem;
  }

  .\32xl\:dq-mt-10 {
    margin-top: 2.5rem;
  }

  .\32xl\:dq-mt-11 {
    margin-top: 2.75rem;
  }

  .\32xl\:dq-mt-12 {
    margin-top: 3rem;
  }

  .\32xl\:dq-mt-14 {
    margin-top: 3.5rem;
  }

  .\32xl\:dq-mt-16 {
    margin-top: 4rem;
  }

  .\32xl\:dq-mt-20 {
    margin-top: 5rem;
  }

  .\32xl\:dq-mt-24 {
    margin-top: 6rem;
  }

  .\32xl\:dq-mt-28 {
    margin-top: 7rem;
  }

  .\32xl\:dq-mt-32 {
    margin-top: 8rem;
  }

  .\32xl\:dq-mt-36 {
    margin-top: 9rem;
  }

  .\32xl\:dq-mt-40 {
    margin-top: 10rem;
  }

  .\32xl\:dq-mt-44 {
    margin-top: 11rem;
  }

  .\32xl\:dq-mt-48 {
    margin-top: 12rem;
  }

  .\32xl\:dq-mt-52 {
    margin-top: 13rem;
  }

  .\32xl\:dq-mt-56 {
    margin-top: 14rem;
  }

  .\32xl\:dq-mt-60 {
    margin-top: 15rem;
  }

  .\32xl\:dq-mt-64 {
    margin-top: 16rem;
  }

  .\32xl\:dq-mt-72 {
    margin-top: 18rem;
  }

  .\32xl\:dq-mt-80 {
    margin-top: 20rem;
  }

  .\32xl\:dq-mt-96 {
    margin-top: 24rem;
  }

  .\32xl\:dq-mt-auto {
    margin-top: auto;
  }

  .\32xl\:dq-mt-px {
    margin-top: 1px;
  }

  .\32xl\:dq-mt-0\.5 {
    margin-top: 0.125rem;
  }

  .\32xl\:dq-mt-1\.5 {
    margin-top: 0.375rem;
  }

  .\32xl\:dq-mt-2\.5 {
    margin-top: 0.625rem;
  }

  .\32xl\:dq-mt-3\.5 {
    margin-top: 0.875rem;
  }

  .\32xl\:dq--mt-0 {
    margin-top: 0px;
  }

  .\32xl\:dq--mt-1 {
    margin-top: -0.25rem;
  }

  .\32xl\:dq--mt-2 {
    margin-top: -0.5rem;
  }

  .\32xl\:dq--mt-3 {
    margin-top: -0.75rem;
  }

  .\32xl\:dq--mt-4 {
    margin-top: -1rem;
  }

  .\32xl\:dq--mt-5 {
    margin-top: -1.25rem;
  }

  .\32xl\:dq--mt-6 {
    margin-top: -1.5rem;
  }

  .\32xl\:dq--mt-7 {
    margin-top: -1.75rem;
  }

  .\32xl\:dq--mt-8 {
    margin-top: -2rem;
  }

  .\32xl\:dq--mt-9 {
    margin-top: -2.25rem;
  }

  .\32xl\:dq--mt-10 {
    margin-top: -2.5rem;
  }

  .\32xl\:dq--mt-11 {
    margin-top: -2.75rem;
  }

  .\32xl\:dq--mt-12 {
    margin-top: -3rem;
  }

  .\32xl\:dq--mt-14 {
    margin-top: -3.5rem;
  }

  .\32xl\:dq--mt-16 {
    margin-top: -4rem;
  }

  .\32xl\:dq--mt-20 {
    margin-top: -5rem;
  }

  .\32xl\:dq--mt-24 {
    margin-top: -6rem;
  }

  .\32xl\:dq--mt-28 {
    margin-top: -7rem;
  }

  .\32xl\:dq--mt-32 {
    margin-top: -8rem;
  }

  .\32xl\:dq--mt-36 {
    margin-top: -9rem;
  }

  .\32xl\:dq--mt-40 {
    margin-top: -10rem;
  }

  .\32xl\:dq--mt-44 {
    margin-top: -11rem;
  }

  .\32xl\:dq--mt-48 {
    margin-top: -12rem;
  }

  .\32xl\:dq--mt-52 {
    margin-top: -13rem;
  }

  .\32xl\:dq--mt-56 {
    margin-top: -14rem;
  }

  .\32xl\:dq--mt-60 {
    margin-top: -15rem;
  }

  .\32xl\:dq--mt-64 {
    margin-top: -16rem;
  }

  .\32xl\:dq--mt-72 {
    margin-top: -18rem;
  }

  .\32xl\:dq--mt-80 {
    margin-top: -20rem;
  }

  .\32xl\:dq--mt-96 {
    margin-top: -24rem;
  }

  .\32xl\:dq--mt-px {
    margin-top: -1px;
  }

  .\32xl\:dq--mt-0\.5 {
    margin-top: -0.125rem;
  }

  .\32xl\:dq--mt-1\.5 {
    margin-top: -0.375rem;
  }

  .\32xl\:dq--mt-2\.5 {
    margin-top: -0.625rem;
  }

  .\32xl\:dq--mt-3\.5 {
    margin-top: -0.875rem;
  }

  .\32xl\:dq-mr-0 {
    margin-right: 0px;
  }

  .\32xl\:dq-mr-1 {
    margin-right: 0.25rem;
  }

  .\32xl\:dq-mr-2 {
    margin-right: 0.5rem;
  }

  .\32xl\:dq-mr-3 {
    margin-right: 0.75rem;
  }

  .\32xl\:dq-mr-4 {
    margin-right: 1rem;
  }

  .\32xl\:dq-mr-5 {
    margin-right: 1.25rem;
  }

  .\32xl\:dq-mr-6 {
    margin-right: 1.5rem;
  }

  .\32xl\:dq-mr-7 {
    margin-right: 1.75rem;
  }

  .\32xl\:dq-mr-8 {
    margin-right: 2rem;
  }

  .\32xl\:dq-mr-9 {
    margin-right: 2.25rem;
  }

  .\32xl\:dq-mr-10 {
    margin-right: 2.5rem;
  }

  .\32xl\:dq-mr-11 {
    margin-right: 2.75rem;
  }

  .\32xl\:dq-mr-12 {
    margin-right: 3rem;
  }

  .\32xl\:dq-mr-14 {
    margin-right: 3.5rem;
  }

  .\32xl\:dq-mr-16 {
    margin-right: 4rem;
  }

  .\32xl\:dq-mr-20 {
    margin-right: 5rem;
  }

  .\32xl\:dq-mr-24 {
    margin-right: 6rem;
  }

  .\32xl\:dq-mr-28 {
    margin-right: 7rem;
  }

  .\32xl\:dq-mr-32 {
    margin-right: 8rem;
  }

  .\32xl\:dq-mr-36 {
    margin-right: 9rem;
  }

  .\32xl\:dq-mr-40 {
    margin-right: 10rem;
  }

  .\32xl\:dq-mr-44 {
    margin-right: 11rem;
  }

  .\32xl\:dq-mr-48 {
    margin-right: 12rem;
  }

  .\32xl\:dq-mr-52 {
    margin-right: 13rem;
  }

  .\32xl\:dq-mr-56 {
    margin-right: 14rem;
  }

  .\32xl\:dq-mr-60 {
    margin-right: 15rem;
  }

  .\32xl\:dq-mr-64 {
    margin-right: 16rem;
  }

  .\32xl\:dq-mr-72 {
    margin-right: 18rem;
  }

  .\32xl\:dq-mr-80 {
    margin-right: 20rem;
  }

  .\32xl\:dq-mr-96 {
    margin-right: 24rem;
  }

  .\32xl\:dq-mr-auto {
    margin-right: auto;
  }

  .\32xl\:dq-mr-px {
    margin-right: 1px;
  }

  .\32xl\:dq-mr-0\.5 {
    margin-right: 0.125rem;
  }

  .\32xl\:dq-mr-1\.5 {
    margin-right: 0.375rem;
  }

  .\32xl\:dq-mr-2\.5 {
    margin-right: 0.625rem;
  }

  .\32xl\:dq-mr-3\.5 {
    margin-right: 0.875rem;
  }

  .\32xl\:dq--mr-0 {
    margin-right: 0px;
  }

  .\32xl\:dq--mr-1 {
    margin-right: -0.25rem;
  }

  .\32xl\:dq--mr-2 {
    margin-right: -0.5rem;
  }

  .\32xl\:dq--mr-3 {
    margin-right: -0.75rem;
  }

  .\32xl\:dq--mr-4 {
    margin-right: -1rem;
  }

  .\32xl\:dq--mr-5 {
    margin-right: -1.25rem;
  }

  .\32xl\:dq--mr-6 {
    margin-right: -1.5rem;
  }

  .\32xl\:dq--mr-7 {
    margin-right: -1.75rem;
  }

  .\32xl\:dq--mr-8 {
    margin-right: -2rem;
  }

  .\32xl\:dq--mr-9 {
    margin-right: -2.25rem;
  }

  .\32xl\:dq--mr-10 {
    margin-right: -2.5rem;
  }

  .\32xl\:dq--mr-11 {
    margin-right: -2.75rem;
  }

  .\32xl\:dq--mr-12 {
    margin-right: -3rem;
  }

  .\32xl\:dq--mr-14 {
    margin-right: -3.5rem;
  }

  .\32xl\:dq--mr-16 {
    margin-right: -4rem;
  }

  .\32xl\:dq--mr-20 {
    margin-right: -5rem;
  }

  .\32xl\:dq--mr-24 {
    margin-right: -6rem;
  }

  .\32xl\:dq--mr-28 {
    margin-right: -7rem;
  }

  .\32xl\:dq--mr-32 {
    margin-right: -8rem;
  }

  .\32xl\:dq--mr-36 {
    margin-right: -9rem;
  }

  .\32xl\:dq--mr-40 {
    margin-right: -10rem;
  }

  .\32xl\:dq--mr-44 {
    margin-right: -11rem;
  }

  .\32xl\:dq--mr-48 {
    margin-right: -12rem;
  }

  .\32xl\:dq--mr-52 {
    margin-right: -13rem;
  }

  .\32xl\:dq--mr-56 {
    margin-right: -14rem;
  }

  .\32xl\:dq--mr-60 {
    margin-right: -15rem;
  }

  .\32xl\:dq--mr-64 {
    margin-right: -16rem;
  }

  .\32xl\:dq--mr-72 {
    margin-right: -18rem;
  }

  .\32xl\:dq--mr-80 {
    margin-right: -20rem;
  }

  .\32xl\:dq--mr-96 {
    margin-right: -24rem;
  }

  .\32xl\:dq--mr-px {
    margin-right: -1px;
  }

  .\32xl\:dq--mr-0\.5 {
    margin-right: -0.125rem;
  }

  .\32xl\:dq--mr-1\.5 {
    margin-right: -0.375rem;
  }

  .\32xl\:dq--mr-2\.5 {
    margin-right: -0.625rem;
  }

  .\32xl\:dq--mr-3\.5 {
    margin-right: -0.875rem;
  }

  .\32xl\:dq-mb-0 {
    margin-bottom: 0px;
  }

  .\32xl\:dq-mb-1 {
    margin-bottom: 0.25rem;
  }

  .\32xl\:dq-mb-2 {
    margin-bottom: 0.5rem;
  }

  .\32xl\:dq-mb-3 {
    margin-bottom: 0.75rem;
  }

  .\32xl\:dq-mb-4 {
    margin-bottom: 1rem;
  }

  .\32xl\:dq-mb-5 {
    margin-bottom: 1.25rem;
  }

  .\32xl\:dq-mb-6 {
    margin-bottom: 1.5rem;
  }

  .\32xl\:dq-mb-7 {
    margin-bottom: 1.75rem;
  }

  .\32xl\:dq-mb-8 {
    margin-bottom: 2rem;
  }

  .\32xl\:dq-mb-9 {
    margin-bottom: 2.25rem;
  }

  .\32xl\:dq-mb-10 {
    margin-bottom: 2.5rem;
  }

  .\32xl\:dq-mb-11 {
    margin-bottom: 2.75rem;
  }

  .\32xl\:dq-mb-12 {
    margin-bottom: 3rem;
  }

  .\32xl\:dq-mb-14 {
    margin-bottom: 3.5rem;
  }

  .\32xl\:dq-mb-16 {
    margin-bottom: 4rem;
  }

  .\32xl\:dq-mb-20 {
    margin-bottom: 5rem;
  }

  .\32xl\:dq-mb-24 {
    margin-bottom: 6rem;
  }

  .\32xl\:dq-mb-28 {
    margin-bottom: 7rem;
  }

  .\32xl\:dq-mb-32 {
    margin-bottom: 8rem;
  }

  .\32xl\:dq-mb-36 {
    margin-bottom: 9rem;
  }

  .\32xl\:dq-mb-40 {
    margin-bottom: 10rem;
  }

  .\32xl\:dq-mb-44 {
    margin-bottom: 11rem;
  }

  .\32xl\:dq-mb-48 {
    margin-bottom: 12rem;
  }

  .\32xl\:dq-mb-52 {
    margin-bottom: 13rem;
  }

  .\32xl\:dq-mb-56 {
    margin-bottom: 14rem;
  }

  .\32xl\:dq-mb-60 {
    margin-bottom: 15rem;
  }

  .\32xl\:dq-mb-64 {
    margin-bottom: 16rem;
  }

  .\32xl\:dq-mb-72 {
    margin-bottom: 18rem;
  }

  .\32xl\:dq-mb-80 {
    margin-bottom: 20rem;
  }

  .\32xl\:dq-mb-96 {
    margin-bottom: 24rem;
  }

  .\32xl\:dq-mb-auto {
    margin-bottom: auto;
  }

  .\32xl\:dq-mb-px {
    margin-bottom: 1px;
  }

  .\32xl\:dq-mb-0\.5 {
    margin-bottom: 0.125rem;
  }

  .\32xl\:dq-mb-1\.5 {
    margin-bottom: 0.375rem;
  }

  .\32xl\:dq-mb-2\.5 {
    margin-bottom: 0.625rem;
  }

  .\32xl\:dq-mb-3\.5 {
    margin-bottom: 0.875rem;
  }

  .\32xl\:dq--mb-0 {
    margin-bottom: 0px;
  }

  .\32xl\:dq--mb-1 {
    margin-bottom: -0.25rem;
  }

  .\32xl\:dq--mb-2 {
    margin-bottom: -0.5rem;
  }

  .\32xl\:dq--mb-3 {
    margin-bottom: -0.75rem;
  }

  .\32xl\:dq--mb-4 {
    margin-bottom: -1rem;
  }

  .\32xl\:dq--mb-5 {
    margin-bottom: -1.25rem;
  }

  .\32xl\:dq--mb-6 {
    margin-bottom: -1.5rem;
  }

  .\32xl\:dq--mb-7 {
    margin-bottom: -1.75rem;
  }

  .\32xl\:dq--mb-8 {
    margin-bottom: -2rem;
  }

  .\32xl\:dq--mb-9 {
    margin-bottom: -2.25rem;
  }

  .\32xl\:dq--mb-10 {
    margin-bottom: -2.5rem;
  }

  .\32xl\:dq--mb-11 {
    margin-bottom: -2.75rem;
  }

  .\32xl\:dq--mb-12 {
    margin-bottom: -3rem;
  }

  .\32xl\:dq--mb-14 {
    margin-bottom: -3.5rem;
  }

  .\32xl\:dq--mb-16 {
    margin-bottom: -4rem;
  }

  .\32xl\:dq--mb-20 {
    margin-bottom: -5rem;
  }

  .\32xl\:dq--mb-24 {
    margin-bottom: -6rem;
  }

  .\32xl\:dq--mb-28 {
    margin-bottom: -7rem;
  }

  .\32xl\:dq--mb-32 {
    margin-bottom: -8rem;
  }

  .\32xl\:dq--mb-36 {
    margin-bottom: -9rem;
  }

  .\32xl\:dq--mb-40 {
    margin-bottom: -10rem;
  }

  .\32xl\:dq--mb-44 {
    margin-bottom: -11rem;
  }

  .\32xl\:dq--mb-48 {
    margin-bottom: -12rem;
  }

  .\32xl\:dq--mb-52 {
    margin-bottom: -13rem;
  }

  .\32xl\:dq--mb-56 {
    margin-bottom: -14rem;
  }

  .\32xl\:dq--mb-60 {
    margin-bottom: -15rem;
  }

  .\32xl\:dq--mb-64 {
    margin-bottom: -16rem;
  }

  .\32xl\:dq--mb-72 {
    margin-bottom: -18rem;
  }

  .\32xl\:dq--mb-80 {
    margin-bottom: -20rem;
  }

  .\32xl\:dq--mb-96 {
    margin-bottom: -24rem;
  }

  .\32xl\:dq--mb-px {
    margin-bottom: -1px;
  }

  .\32xl\:dq--mb-0\.5 {
    margin-bottom: -0.125rem;
  }

  .\32xl\:dq--mb-1\.5 {
    margin-bottom: -0.375rem;
  }

  .\32xl\:dq--mb-2\.5 {
    margin-bottom: -0.625rem;
  }

  .\32xl\:dq--mb-3\.5 {
    margin-bottom: -0.875rem;
  }

  .\32xl\:dq-ml-0 {
    margin-left: 0px;
  }

  .\32xl\:dq-ml-1 {
    margin-left: 0.25rem;
  }

  .\32xl\:dq-ml-2 {
    margin-left: 0.5rem;
  }

  .\32xl\:dq-ml-3 {
    margin-left: 0.75rem;
  }

  .\32xl\:dq-ml-4 {
    margin-left: 1rem;
  }

  .\32xl\:dq-ml-5 {
    margin-left: 1.25rem;
  }

  .\32xl\:dq-ml-6 {
    margin-left: 1.5rem;
  }

  .\32xl\:dq-ml-7 {
    margin-left: 1.75rem;
  }

  .\32xl\:dq-ml-8 {
    margin-left: 2rem;
  }

  .\32xl\:dq-ml-9 {
    margin-left: 2.25rem;
  }

  .\32xl\:dq-ml-10 {
    margin-left: 2.5rem;
  }

  .\32xl\:dq-ml-11 {
    margin-left: 2.75rem;
  }

  .\32xl\:dq-ml-12 {
    margin-left: 3rem;
  }

  .\32xl\:dq-ml-14 {
    margin-left: 3.5rem;
  }

  .\32xl\:dq-ml-16 {
    margin-left: 4rem;
  }

  .\32xl\:dq-ml-20 {
    margin-left: 5rem;
  }

  .\32xl\:dq-ml-24 {
    margin-left: 6rem;
  }

  .\32xl\:dq-ml-28 {
    margin-left: 7rem;
  }

  .\32xl\:dq-ml-32 {
    margin-left: 8rem;
  }

  .\32xl\:dq-ml-36 {
    margin-left: 9rem;
  }

  .\32xl\:dq-ml-40 {
    margin-left: 10rem;
  }

  .\32xl\:dq-ml-44 {
    margin-left: 11rem;
  }

  .\32xl\:dq-ml-48 {
    margin-left: 12rem;
  }

  .\32xl\:dq-ml-52 {
    margin-left: 13rem;
  }

  .\32xl\:dq-ml-56 {
    margin-left: 14rem;
  }

  .\32xl\:dq-ml-60 {
    margin-left: 15rem;
  }

  .\32xl\:dq-ml-64 {
    margin-left: 16rem;
  }

  .\32xl\:dq-ml-72 {
    margin-left: 18rem;
  }

  .\32xl\:dq-ml-80 {
    margin-left: 20rem;
  }

  .\32xl\:dq-ml-96 {
    margin-left: 24rem;
  }

  .\32xl\:dq-ml-auto {
    margin-left: auto;
  }

  .\32xl\:dq-ml-px {
    margin-left: 1px;
  }

  .\32xl\:dq-ml-0\.5 {
    margin-left: 0.125rem;
  }

  .\32xl\:dq-ml-1\.5 {
    margin-left: 0.375rem;
  }

  .\32xl\:dq-ml-2\.5 {
    margin-left: 0.625rem;
  }

  .\32xl\:dq-ml-3\.5 {
    margin-left: 0.875rem;
  }

  .\32xl\:dq--ml-0 {
    margin-left: 0px;
  }

  .\32xl\:dq--ml-1 {
    margin-left: -0.25rem;
  }

  .\32xl\:dq--ml-2 {
    margin-left: -0.5rem;
  }

  .\32xl\:dq--ml-3 {
    margin-left: -0.75rem;
  }

  .\32xl\:dq--ml-4 {
    margin-left: -1rem;
  }

  .\32xl\:dq--ml-5 {
    margin-left: -1.25rem;
  }

  .\32xl\:dq--ml-6 {
    margin-left: -1.5rem;
  }

  .\32xl\:dq--ml-7 {
    margin-left: -1.75rem;
  }

  .\32xl\:dq--ml-8 {
    margin-left: -2rem;
  }

  .\32xl\:dq--ml-9 {
    margin-left: -2.25rem;
  }

  .\32xl\:dq--ml-10 {
    margin-left: -2.5rem;
  }

  .\32xl\:dq--ml-11 {
    margin-left: -2.75rem;
  }

  .\32xl\:dq--ml-12 {
    margin-left: -3rem;
  }

  .\32xl\:dq--ml-14 {
    margin-left: -3.5rem;
  }

  .\32xl\:dq--ml-16 {
    margin-left: -4rem;
  }

  .\32xl\:dq--ml-20 {
    margin-left: -5rem;
  }

  .\32xl\:dq--ml-24 {
    margin-left: -6rem;
  }

  .\32xl\:dq--ml-28 {
    margin-left: -7rem;
  }

  .\32xl\:dq--ml-32 {
    margin-left: -8rem;
  }

  .\32xl\:dq--ml-36 {
    margin-left: -9rem;
  }

  .\32xl\:dq--ml-40 {
    margin-left: -10rem;
  }

  .\32xl\:dq--ml-44 {
    margin-left: -11rem;
  }

  .\32xl\:dq--ml-48 {
    margin-left: -12rem;
  }

  .\32xl\:dq--ml-52 {
    margin-left: -13rem;
  }

  .\32xl\:dq--ml-56 {
    margin-left: -14rem;
  }

  .\32xl\:dq--ml-60 {
    margin-left: -15rem;
  }

  .\32xl\:dq--ml-64 {
    margin-left: -16rem;
  }

  .\32xl\:dq--ml-72 {
    margin-left: -18rem;
  }

  .\32xl\:dq--ml-80 {
    margin-left: -20rem;
  }

  .\32xl\:dq--ml-96 {
    margin-left: -24rem;
  }

  .\32xl\:dq--ml-px {
    margin-left: -1px;
  }

  .\32xl\:dq--ml-0\.5 {
    margin-left: -0.125rem;
  }

  .\32xl\:dq--ml-1\.5 {
    margin-left: -0.375rem;
  }

  .\32xl\:dq--ml-2\.5 {
    margin-left: -0.625rem;
  }

  .\32xl\:dq--ml-3\.5 {
    margin-left: -0.875rem;
  }

  .\32xl\:dq-box-border {
    box-sizing: border-box;
  }

  .\32xl\:dq-box-content {
    box-sizing: content-box;
  }

  .\32xl\:dq-block {
    display: block;
  }

  .\32xl\:dq-inline-block {
    display: inline-block;
  }

  .\32xl\:dq-inline {
    display: inline;
  }

  .\32xl\:dq-flex {
    display: flex;
  }

  .\32xl\:dq-inline-flex {
    display: inline-flex;
  }

  .\32xl\:dq-table {
    display: table;
  }

  .\32xl\:dq-inline-table {
    display: inline-table;
  }

  .\32xl\:dq-table-caption {
    display: table-caption;
  }

  .\32xl\:dq-table-cell {
    display: table-cell;
  }

  .\32xl\:dq-table-column {
    display: table-column;
  }

  .\32xl\:dq-table-column-group {
    display: table-column-group;
  }

  .\32xl\:dq-table-footer-group {
    display: table-footer-group;
  }

  .\32xl\:dq-table-header-group {
    display: table-header-group;
  }

  .\32xl\:dq-table-row-group {
    display: table-row-group;
  }

  .\32xl\:dq-table-row {
    display: table-row;
  }

  .\32xl\:dq-flow-root {
    display: flow-root;
  }

  .\32xl\:dq-grid {
    display: grid;
  }

  .\32xl\:dq-inline-grid {
    display: inline-grid;
  }

  .\32xl\:dq-contents {
    display: contents;
  }

  .\32xl\:dq-list-item {
    display: list-item;
  }

  .\32xl\:dq-hidden {
    display: none;
  }

  .\32xl\:dq-h-0 {
    height: 0px;
  }

  .\32xl\:dq-h-1 {
    height: 0.25rem;
  }

  .\32xl\:dq-h-2 {
    height: 0.5rem;
  }

  .\32xl\:dq-h-3 {
    height: 0.75rem;
  }

  .\32xl\:dq-h-4 {
    height: 1rem;
  }

  .\32xl\:dq-h-5 {
    height: 1.25rem;
  }

  .\32xl\:dq-h-6 {
    height: 1.5rem;
  }

  .\32xl\:dq-h-7 {
    height: 1.75rem;
  }

  .\32xl\:dq-h-8 {
    height: 2rem;
  }

  .\32xl\:dq-h-9 {
    height: 2.25rem;
  }

  .\32xl\:dq-h-10 {
    height: 2.5rem;
  }

  .\32xl\:dq-h-11 {
    height: 2.75rem;
  }

  .\32xl\:dq-h-12 {
    height: 3rem;
  }

  .\32xl\:dq-h-14 {
    height: 3.5rem;
  }

  .\32xl\:dq-h-16 {
    height: 4rem;
  }

  .\32xl\:dq-h-20 {
    height: 5rem;
  }

  .\32xl\:dq-h-24 {
    height: 6rem;
  }

  .\32xl\:dq-h-28 {
    height: 7rem;
  }

  .\32xl\:dq-h-32 {
    height: 8rem;
  }

  .\32xl\:dq-h-36 {
    height: 9rem;
  }

  .\32xl\:dq-h-40 {
    height: 10rem;
  }

  .\32xl\:dq-h-44 {
    height: 11rem;
  }

  .\32xl\:dq-h-48 {
    height: 12rem;
  }

  .\32xl\:dq-h-52 {
    height: 13rem;
  }

  .\32xl\:dq-h-56 {
    height: 14rem;
  }

  .\32xl\:dq-h-60 {
    height: 15rem;
  }

  .\32xl\:dq-h-64 {
    height: 16rem;
  }

  .\32xl\:dq-h-72 {
    height: 18rem;
  }

  .\32xl\:dq-h-80 {
    height: 20rem;
  }

  .\32xl\:dq-h-96 {
    height: 24rem;
  }

  .\32xl\:dq-h-auto {
    height: auto;
  }

  .\32xl\:dq-h-px {
    height: 1px;
  }

  .\32xl\:dq-h-0\.5 {
    height: 0.125rem;
  }

  .\32xl\:dq-h-1\.5 {
    height: 0.375rem;
  }

  .\32xl\:dq-h-2\.5 {
    height: 0.625rem;
  }

  .\32xl\:dq-h-3\.5 {
    height: 0.875rem;
  }

  .\32xl\:dq-h-1\/2 {
    height: 50%;
  }

  .\32xl\:dq-h-1\/3 {
    height: 33.333333%;
  }

  .\32xl\:dq-h-2\/3 {
    height: 66.666667%;
  }

  .\32xl\:dq-h-1\/4 {
    height: 25%;
  }

  .\32xl\:dq-h-2\/4 {
    height: 50%;
  }

  .\32xl\:dq-h-3\/4 {
    height: 75%;
  }

  .\32xl\:dq-h-1\/5 {
    height: 20%;
  }

  .\32xl\:dq-h-2\/5 {
    height: 40%;
  }

  .\32xl\:dq-h-3\/5 {
    height: 60%;
  }

  .\32xl\:dq-h-4\/5 {
    height: 80%;
  }

  .\32xl\:dq-h-1\/6 {
    height: 16.666667%;
  }

  .\32xl\:dq-h-2\/6 {
    height: 33.333333%;
  }

  .\32xl\:dq-h-3\/6 {
    height: 50%;
  }

  .\32xl\:dq-h-4\/6 {
    height: 66.666667%;
  }

  .\32xl\:dq-h-5\/6 {
    height: 83.333333%;
  }

  .\32xl\:dq-h-full {
    height: 100%;
  }

  .\32xl\:dq-h-screen {
    height: 100vh;
  }

  .\32xl\:dq-max-h-0 {
    max-height: 0px;
  }

  .\32xl\:dq-max-h-1 {
    max-height: 0.25rem;
  }

  .\32xl\:dq-max-h-2 {
    max-height: 0.5rem;
  }

  .\32xl\:dq-max-h-3 {
    max-height: 0.75rem;
  }

  .\32xl\:dq-max-h-4 {
    max-height: 1rem;
  }

  .\32xl\:dq-max-h-5 {
    max-height: 1.25rem;
  }

  .\32xl\:dq-max-h-6 {
    max-height: 1.5rem;
  }

  .\32xl\:dq-max-h-7 {
    max-height: 1.75rem;
  }

  .\32xl\:dq-max-h-8 {
    max-height: 2rem;
  }

  .\32xl\:dq-max-h-9 {
    max-height: 2.25rem;
  }

  .\32xl\:dq-max-h-10 {
    max-height: 2.5rem;
  }

  .\32xl\:dq-max-h-11 {
    max-height: 2.75rem;
  }

  .\32xl\:dq-max-h-12 {
    max-height: 3rem;
  }

  .\32xl\:dq-max-h-14 {
    max-height: 3.5rem;
  }

  .\32xl\:dq-max-h-16 {
    max-height: 4rem;
  }

  .\32xl\:dq-max-h-20 {
    max-height: 5rem;
  }

  .\32xl\:dq-max-h-24 {
    max-height: 6rem;
  }

  .\32xl\:dq-max-h-28 {
    max-height: 7rem;
  }

  .\32xl\:dq-max-h-32 {
    max-height: 8rem;
  }

  .\32xl\:dq-max-h-36 {
    max-height: 9rem;
  }

  .\32xl\:dq-max-h-40 {
    max-height: 10rem;
  }

  .\32xl\:dq-max-h-44 {
    max-height: 11rem;
  }

  .\32xl\:dq-max-h-48 {
    max-height: 12rem;
  }

  .\32xl\:dq-max-h-52 {
    max-height: 13rem;
  }

  .\32xl\:dq-max-h-56 {
    max-height: 14rem;
  }

  .\32xl\:dq-max-h-60 {
    max-height: 15rem;
  }

  .\32xl\:dq-max-h-64 {
    max-height: 16rem;
  }

  .\32xl\:dq-max-h-72 {
    max-height: 18rem;
  }

  .\32xl\:dq-max-h-80 {
    max-height: 20rem;
  }

  .\32xl\:dq-max-h-96 {
    max-height: 24rem;
  }

  .\32xl\:dq-max-h-px {
    max-height: 1px;
  }

  .\32xl\:dq-max-h-0\.5 {
    max-height: 0.125rem;
  }

  .\32xl\:dq-max-h-1\.5 {
    max-height: 0.375rem;
  }

  .\32xl\:dq-max-h-2\.5 {
    max-height: 0.625rem;
  }

  .\32xl\:dq-max-h-3\.5 {
    max-height: 0.875rem;
  }

  .\32xl\:dq-max-h-full {
    max-height: 100%;
  }

  .\32xl\:dq-max-h-screen {
    max-height: 100vh;
  }

  .\32xl\:dq-min-h-0 {
    min-height: 0px;
  }

  .\32xl\:dq-min-h-full {
    min-height: 100%;
  }

  .\32xl\:dq-min-h-screen {
    min-height: 100vh;
  }

  .\32xl\:dq-w-0 {
    width: 0px;
  }

  .\32xl\:dq-w-1 {
    width: 0.25rem;
  }

  .\32xl\:dq-w-2 {
    width: 0.5rem;
  }

  .\32xl\:dq-w-3 {
    width: 0.75rem;
  }

  .\32xl\:dq-w-4 {
    width: 1rem;
  }

  .\32xl\:dq-w-5 {
    width: 1.25rem;
  }

  .\32xl\:dq-w-6 {
    width: 1.5rem;
  }

  .\32xl\:dq-w-7 {
    width: 1.75rem;
  }

  .\32xl\:dq-w-8 {
    width: 2rem;
  }

  .\32xl\:dq-w-9 {
    width: 2.25rem;
  }

  .\32xl\:dq-w-10 {
    width: 2.5rem;
  }

  .\32xl\:dq-w-11 {
    width: 2.75rem;
  }

  .\32xl\:dq-w-12 {
    width: 3rem;
  }

  .\32xl\:dq-w-14 {
    width: 3.5rem;
  }

  .\32xl\:dq-w-16 {
    width: 4rem;
  }

  .\32xl\:dq-w-20 {
    width: 5rem;
  }

  .\32xl\:dq-w-24 {
    width: 6rem;
  }

  .\32xl\:dq-w-28 {
    width: 7rem;
  }

  .\32xl\:dq-w-32 {
    width: 8rem;
  }

  .\32xl\:dq-w-36 {
    width: 9rem;
  }

  .\32xl\:dq-w-40 {
    width: 10rem;
  }

  .\32xl\:dq-w-44 {
    width: 11rem;
  }

  .\32xl\:dq-w-48 {
    width: 12rem;
  }

  .\32xl\:dq-w-52 {
    width: 13rem;
  }

  .\32xl\:dq-w-56 {
    width: 14rem;
  }

  .\32xl\:dq-w-60 {
    width: 15rem;
  }

  .\32xl\:dq-w-64 {
    width: 16rem;
  }

  .\32xl\:dq-w-72 {
    width: 18rem;
  }

  .\32xl\:dq-w-80 {
    width: 20rem;
  }

  .\32xl\:dq-w-96 {
    width: 24rem;
  }

  .\32xl\:dq-w-auto {
    width: auto;
  }

  .\32xl\:dq-w-px {
    width: 1px;
  }

  .\32xl\:dq-w-0\.5 {
    width: 0.125rem;
  }

  .\32xl\:dq-w-1\.5 {
    width: 0.375rem;
  }

  .\32xl\:dq-w-2\.5 {
    width: 0.625rem;
  }

  .\32xl\:dq-w-3\.5 {
    width: 0.875rem;
  }

  .\32xl\:dq-w-1\/2 {
    width: 50%;
  }

  .\32xl\:dq-w-1\/3 {
    width: 33.333333%;
  }

  .\32xl\:dq-w-2\/3 {
    width: 66.666667%;
  }

  .\32xl\:dq-w-1\/4 {
    width: 25%;
  }

  .\32xl\:dq-w-2\/4 {
    width: 50%;
  }

  .\32xl\:dq-w-3\/4 {
    width: 75%;
  }

  .\32xl\:dq-w-1\/5 {
    width: 20%;
  }

  .\32xl\:dq-w-2\/5 {
    width: 40%;
  }

  .\32xl\:dq-w-3\/5 {
    width: 60%;
  }

  .\32xl\:dq-w-4\/5 {
    width: 80%;
  }

  .\32xl\:dq-w-1\/6 {
    width: 16.666667%;
  }

  .\32xl\:dq-w-2\/6 {
    width: 33.333333%;
  }

  .\32xl\:dq-w-3\/6 {
    width: 50%;
  }

  .\32xl\:dq-w-4\/6 {
    width: 66.666667%;
  }

  .\32xl\:dq-w-5\/6 {
    width: 83.333333%;
  }

  .\32xl\:dq-w-1\/12 {
    width: 8.333333%;
  }

  .\32xl\:dq-w-2\/12 {
    width: 16.666667%;
  }

  .\32xl\:dq-w-3\/12 {
    width: 25%;
  }

  .\32xl\:dq-w-4\/12 {
    width: 33.333333%;
  }

  .\32xl\:dq-w-5\/12 {
    width: 41.666667%;
  }

  .\32xl\:dq-w-6\/12 {
    width: 50%;
  }

  .\32xl\:dq-w-7\/12 {
    width: 58.333333%;
  }

  .\32xl\:dq-w-8\/12 {
    width: 66.666667%;
  }

  .\32xl\:dq-w-9\/12 {
    width: 75%;
  }

  .\32xl\:dq-w-10\/12 {
    width: 83.333333%;
  }

  .\32xl\:dq-w-11\/12 {
    width: 91.666667%;
  }

  .\32xl\:dq-w-full {
    width: 100%;
  }

  .\32xl\:dq-w-screen {
    width: 100vw;
  }

  .\32xl\:dq-w-min {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }

  .\32xl\:dq-w-max {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .\32xl\:dq-min-w-0 {
    min-width: 0px;
  }

  .\32xl\:dq-min-w-full {
    min-width: 100%;
  }

  .\32xl\:dq-min-w-min {
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
  }

  .\32xl\:dq-min-w-max {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
  }

  .\32xl\:dq-max-w-0 {
    max-width: 0rem;
  }

  .\32xl\:dq-max-w-none {
    max-width: none;
  }

  .\32xl\:dq-max-w-xs {
    max-width: 20rem;
  }

  .\32xl\:dq-max-w-sm {
    max-width: 24rem;
  }

  .\32xl\:dq-max-w-md {
    max-width: 28rem;
  }

  .\32xl\:dq-max-w-lg {
    max-width: 32rem;
  }

  .\32xl\:dq-max-w-xl {
    max-width: 36rem;
  }

  .\32xl\:dq-max-w-2xl {
    max-width: 42rem;
  }

  .\32xl\:dq-max-w-3xl {
    max-width: 48rem;
  }

  .\32xl\:dq-max-w-4xl {
    max-width: 56rem;
  }

  .\32xl\:dq-max-w-5xl {
    max-width: 64rem;
  }

  .\32xl\:dq-max-w-6xl {
    max-width: 72rem;
  }

  .\32xl\:dq-max-w-7xl {
    max-width: 80rem;
  }

  .\32xl\:dq-max-w-full {
    max-width: 100%;
  }

  .\32xl\:dq-max-w-min {
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
  }

  .\32xl\:dq-max-w-max {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }

  .\32xl\:dq-max-w-prose {
    max-width: 65ch;
  }

  .\32xl\:dq-max-w-screen-sm {
    max-width: 640px;
  }

  .\32xl\:dq-max-w-screen-md {
    max-width: 768px;
  }

  .\32xl\:dq-max-w-screen-lg {
    max-width: 1024px;
  }

  .\32xl\:dq-max-w-screen-xl {
    max-width: 1280px;
  }

  .\32xl\:dq-max-w-screen-2xl {
    max-width: 1536px;
  }

  .\32xl\:dq-flex-1 {
    flex: 1 1 0%;
  }

  .\32xl\:dq-flex-auto {
    flex: 1 1 auto;
  }

  .\32xl\:dq-flex-initial {
    flex: 0 1 auto;
  }

  .\32xl\:dq-flex-none {
    flex: none;
  }

  .\32xl\:dq-flex-shrink-0 {
    flex-shrink: 0;
  }

  .\32xl\:dq-flex-shrink {
    flex-shrink: 1;
  }

  .\32xl\:dq-flex-grow-0 {
    flex-grow: 0;
  }

  .\32xl\:dq-flex-grow {
    flex-grow: 1;
  }

  .\32xl\:dq-table-auto {
    table-layout: auto;
  }

  .\32xl\:dq-table-fixed {
    table-layout: fixed;
  }

  .\32xl\:dq-border-collapse {
    border-collapse: collapse;
  }

  .\32xl\:dq-border-separate {
    border-collapse: separate;
  }

  .\32xl\:dq-origin-center {
    transform-origin: center;
  }

  .\32xl\:dq-origin-top {
    transform-origin: top;
  }

  .\32xl\:dq-origin-top-right {
    transform-origin: top right;
  }

  .\32xl\:dq-origin-right {
    transform-origin: right;
  }

  .\32xl\:dq-origin-bottom-right {
    transform-origin: bottom right;
  }

  .\32xl\:dq-origin-bottom {
    transform-origin: bottom;
  }

  .\32xl\:dq-origin-bottom-left {
    transform-origin: bottom left;
  }

  .\32xl\:dq-origin-left {
    transform-origin: left;
  }

  .\32xl\:dq-origin-top-left {
    transform-origin: top left;
  }

  .\32xl\:dq-transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .\32xl\:dq-transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .\32xl\:dq-transform-none {
    transform: none;
  }

  .\32xl\:dq-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .\32xl\:dq-translate-x-1 {
    --tw-translate-x: 0.25rem;
  }

  .\32xl\:dq-translate-x-2 {
    --tw-translate-x: 0.5rem;
  }

  .\32xl\:dq-translate-x-3 {
    --tw-translate-x: 0.75rem;
  }

  .\32xl\:dq-translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .\32xl\:dq-translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .\32xl\:dq-translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .\32xl\:dq-translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .\32xl\:dq-translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .\32xl\:dq-translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .\32xl\:dq-translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .\32xl\:dq-translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .\32xl\:dq-translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .\32xl\:dq-translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .\32xl\:dq-translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .\32xl\:dq-translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .\32xl\:dq-translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .\32xl\:dq-translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .\32xl\:dq-translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .\32xl\:dq-translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .\32xl\:dq-translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .\32xl\:dq-translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .\32xl\:dq-translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .\32xl\:dq-translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .\32xl\:dq-translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .\32xl\:dq-translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .\32xl\:dq-translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .\32xl\:dq-translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .\32xl\:dq-translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .\32xl\:dq-translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .\32xl\:dq-translate-x-px {
    --tw-translate-x: 1px;
  }

  .\32xl\:dq-translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }

  .\32xl\:dq-translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }

  .\32xl\:dq-translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }

  .\32xl\:dq-translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }

  .\32xl\:dq--translate-x-0 {
    --tw-translate-x: 0px;
  }

  .\32xl\:dq--translate-x-1 {
    --tw-translate-x: -0.25rem;
  }

  .\32xl\:dq--translate-x-2 {
    --tw-translate-x: -0.5rem;
  }

  .\32xl\:dq--translate-x-3 {
    --tw-translate-x: -0.75rem;
  }

  .\32xl\:dq--translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .\32xl\:dq--translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .\32xl\:dq--translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .\32xl\:dq--translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .\32xl\:dq--translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .\32xl\:dq--translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .\32xl\:dq--translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .\32xl\:dq--translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .\32xl\:dq--translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .\32xl\:dq--translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .\32xl\:dq--translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .\32xl\:dq--translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .\32xl\:dq--translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .\32xl\:dq--translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .\32xl\:dq--translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .\32xl\:dq--translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .\32xl\:dq--translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .\32xl\:dq--translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .\32xl\:dq--translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .\32xl\:dq--translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .\32xl\:dq--translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .\32xl\:dq--translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .\32xl\:dq--translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .\32xl\:dq--translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .\32xl\:dq--translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .\32xl\:dq--translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .\32xl\:dq--translate-x-px {
    --tw-translate-x: -1px;
  }

  .\32xl\:dq--translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }

  .\32xl\:dq--translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }

  .\32xl\:dq--translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }

  .\32xl\:dq--translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }

  .\32xl\:dq-translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .\32xl\:dq-translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }

  .\32xl\:dq-translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }

  .\32xl\:dq-translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .\32xl\:dq-translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .\32xl\:dq-translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .\32xl\:dq-translate-x-full {
    --tw-translate-x: 100%;
  }

  .\32xl\:dq--translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .\32xl\:dq--translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }

  .\32xl\:dq--translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }

  .\32xl\:dq--translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .\32xl\:dq--translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .\32xl\:dq--translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .\32xl\:dq--translate-x-full {
    --tw-translate-x: -100%;
  }

  .\32xl\:dq-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .\32xl\:dq-translate-y-1 {
    --tw-translate-y: 0.25rem;
  }

  .\32xl\:dq-translate-y-2 {
    --tw-translate-y: 0.5rem;
  }

  .\32xl\:dq-translate-y-3 {
    --tw-translate-y: 0.75rem;
  }

  .\32xl\:dq-translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .\32xl\:dq-translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .\32xl\:dq-translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .\32xl\:dq-translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .\32xl\:dq-translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .\32xl\:dq-translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .\32xl\:dq-translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .\32xl\:dq-translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .\32xl\:dq-translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .\32xl\:dq-translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .\32xl\:dq-translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .\32xl\:dq-translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .\32xl\:dq-translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .\32xl\:dq-translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .\32xl\:dq-translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .\32xl\:dq-translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .\32xl\:dq-translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .\32xl\:dq-translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .\32xl\:dq-translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .\32xl\:dq-translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .\32xl\:dq-translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .\32xl\:dq-translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .\32xl\:dq-translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .\32xl\:dq-translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .\32xl\:dq-translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .\32xl\:dq-translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .\32xl\:dq-translate-y-px {
    --tw-translate-y: 1px;
  }

  .\32xl\:dq-translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }

  .\32xl\:dq-translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }

  .\32xl\:dq-translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }

  .\32xl\:dq-translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }

  .\32xl\:dq--translate-y-0 {
    --tw-translate-y: 0px;
  }

  .\32xl\:dq--translate-y-1 {
    --tw-translate-y: -0.25rem;
  }

  .\32xl\:dq--translate-y-2 {
    --tw-translate-y: -0.5rem;
  }

  .\32xl\:dq--translate-y-3 {
    --tw-translate-y: -0.75rem;
  }

  .\32xl\:dq--translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .\32xl\:dq--translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .\32xl\:dq--translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .\32xl\:dq--translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .\32xl\:dq--translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .\32xl\:dq--translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .\32xl\:dq--translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .\32xl\:dq--translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .\32xl\:dq--translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .\32xl\:dq--translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .\32xl\:dq--translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .\32xl\:dq--translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .\32xl\:dq--translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .\32xl\:dq--translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .\32xl\:dq--translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .\32xl\:dq--translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .\32xl\:dq--translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .\32xl\:dq--translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .\32xl\:dq--translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .\32xl\:dq--translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .\32xl\:dq--translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .\32xl\:dq--translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .\32xl\:dq--translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .\32xl\:dq--translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .\32xl\:dq--translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .\32xl\:dq--translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .\32xl\:dq--translate-y-px {
    --tw-translate-y: -1px;
  }

  .\32xl\:dq--translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }

  .\32xl\:dq--translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }

  .\32xl\:dq--translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }

  .\32xl\:dq--translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }

  .\32xl\:dq-translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .\32xl\:dq-translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }

  .\32xl\:dq-translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }

  .\32xl\:dq-translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .\32xl\:dq-translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .\32xl\:dq-translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .\32xl\:dq-translate-y-full {
    --tw-translate-y: 100%;
  }

  .\32xl\:dq--translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .\32xl\:dq--translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }

  .\32xl\:dq--translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }

  .\32xl\:dq--translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .\32xl\:dq--translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .\32xl\:dq--translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .\32xl\:dq--translate-y-full {
    --tw-translate-y: -100%;
  }

  .\32xl\:hover\:dq-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .\32xl\:hover\:dq-translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }

  .\32xl\:hover\:dq-translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }

  .\32xl\:hover\:dq-translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }

  .\32xl\:hover\:dq-translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .\32xl\:hover\:dq-translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .\32xl\:hover\:dq-translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .\32xl\:hover\:dq-translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .\32xl\:hover\:dq-translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .\32xl\:hover\:dq-translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .\32xl\:hover\:dq-translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .\32xl\:hover\:dq-translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .\32xl\:hover\:dq-translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .\32xl\:hover\:dq-translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .\32xl\:hover\:dq-translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .\32xl\:hover\:dq-translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .\32xl\:hover\:dq-translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .\32xl\:hover\:dq-translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .\32xl\:hover\:dq-translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .\32xl\:hover\:dq-translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .\32xl\:hover\:dq-translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .\32xl\:hover\:dq-translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .\32xl\:hover\:dq-translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .\32xl\:hover\:dq-translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .\32xl\:hover\:dq-translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .\32xl\:hover\:dq-translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .\32xl\:hover\:dq-translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .\32xl\:hover\:dq-translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .\32xl\:hover\:dq-translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .\32xl\:hover\:dq-translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .\32xl\:hover\:dq-translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .\32xl\:hover\:dq-translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }

  .\32xl\:hover\:dq-translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }

  .\32xl\:hover\:dq-translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }

  .\32xl\:hover\:dq-translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }

  .\32xl\:hover\:dq--translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .\32xl\:hover\:dq--translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }

  .\32xl\:hover\:dq--translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }

  .\32xl\:hover\:dq--translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }

  .\32xl\:hover\:dq--translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .\32xl\:hover\:dq--translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .\32xl\:hover\:dq--translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .\32xl\:hover\:dq--translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .\32xl\:hover\:dq--translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .\32xl\:hover\:dq--translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .\32xl\:hover\:dq--translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .\32xl\:hover\:dq--translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .\32xl\:hover\:dq--translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .\32xl\:hover\:dq--translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .\32xl\:hover\:dq--translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .\32xl\:hover\:dq--translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .\32xl\:hover\:dq--translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .\32xl\:hover\:dq--translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .\32xl\:hover\:dq--translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .\32xl\:hover\:dq--translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .\32xl\:hover\:dq--translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .\32xl\:hover\:dq--translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .\32xl\:hover\:dq--translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .\32xl\:hover\:dq--translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .\32xl\:hover\:dq--translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .\32xl\:hover\:dq--translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .\32xl\:hover\:dq--translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .\32xl\:hover\:dq--translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .\32xl\:hover\:dq--translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .\32xl\:hover\:dq--translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .\32xl\:hover\:dq--translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .\32xl\:hover\:dq--translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }

  .\32xl\:hover\:dq--translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }

  .\32xl\:hover\:dq--translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }

  .\32xl\:hover\:dq--translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }

  .\32xl\:hover\:dq-translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .\32xl\:hover\:dq-translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }

  .\32xl\:hover\:dq-translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }

  .\32xl\:hover\:dq-translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .\32xl\:hover\:dq-translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .\32xl\:hover\:dq-translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .\32xl\:hover\:dq-translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .\32xl\:hover\:dq--translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .\32xl\:hover\:dq--translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }

  .\32xl\:hover\:dq--translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }

  .\32xl\:hover\:dq--translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .\32xl\:hover\:dq--translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .\32xl\:hover\:dq--translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .\32xl\:hover\:dq--translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .\32xl\:hover\:dq-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .\32xl\:hover\:dq-translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }

  .\32xl\:hover\:dq-translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }

  .\32xl\:hover\:dq-translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }

  .\32xl\:hover\:dq-translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .\32xl\:hover\:dq-translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .\32xl\:hover\:dq-translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .\32xl\:hover\:dq-translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .\32xl\:hover\:dq-translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .\32xl\:hover\:dq-translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .\32xl\:hover\:dq-translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .\32xl\:hover\:dq-translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .\32xl\:hover\:dq-translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .\32xl\:hover\:dq-translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .\32xl\:hover\:dq-translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .\32xl\:hover\:dq-translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .\32xl\:hover\:dq-translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .\32xl\:hover\:dq-translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .\32xl\:hover\:dq-translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .\32xl\:hover\:dq-translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .\32xl\:hover\:dq-translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .\32xl\:hover\:dq-translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .\32xl\:hover\:dq-translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .\32xl\:hover\:dq-translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .\32xl\:hover\:dq-translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .\32xl\:hover\:dq-translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .\32xl\:hover\:dq-translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .\32xl\:hover\:dq-translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .\32xl\:hover\:dq-translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .\32xl\:hover\:dq-translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .\32xl\:hover\:dq-translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .\32xl\:hover\:dq-translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }

  .\32xl\:hover\:dq-translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }

  .\32xl\:hover\:dq-translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }

  .\32xl\:hover\:dq-translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }

  .\32xl\:hover\:dq--translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .\32xl\:hover\:dq--translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }

  .\32xl\:hover\:dq--translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }

  .\32xl\:hover\:dq--translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }

  .\32xl\:hover\:dq--translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .\32xl\:hover\:dq--translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .\32xl\:hover\:dq--translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .\32xl\:hover\:dq--translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .\32xl\:hover\:dq--translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .\32xl\:hover\:dq--translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .\32xl\:hover\:dq--translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .\32xl\:hover\:dq--translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .\32xl\:hover\:dq--translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .\32xl\:hover\:dq--translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .\32xl\:hover\:dq--translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .\32xl\:hover\:dq--translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .\32xl\:hover\:dq--translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .\32xl\:hover\:dq--translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .\32xl\:hover\:dq--translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .\32xl\:hover\:dq--translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .\32xl\:hover\:dq--translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .\32xl\:hover\:dq--translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .\32xl\:hover\:dq--translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .\32xl\:hover\:dq--translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .\32xl\:hover\:dq--translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .\32xl\:hover\:dq--translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .\32xl\:hover\:dq--translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .\32xl\:hover\:dq--translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .\32xl\:hover\:dq--translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .\32xl\:hover\:dq--translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .\32xl\:hover\:dq--translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .\32xl\:hover\:dq--translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }

  .\32xl\:hover\:dq--translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }

  .\32xl\:hover\:dq--translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }

  .\32xl\:hover\:dq--translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }

  .\32xl\:hover\:dq-translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .\32xl\:hover\:dq-translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }

  .\32xl\:hover\:dq-translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }

  .\32xl\:hover\:dq-translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .\32xl\:hover\:dq-translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .\32xl\:hover\:dq-translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .\32xl\:hover\:dq-translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .\32xl\:hover\:dq--translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .\32xl\:hover\:dq--translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }

  .\32xl\:hover\:dq--translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }

  .\32xl\:hover\:dq--translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .\32xl\:hover\:dq--translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .\32xl\:hover\:dq--translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .\32xl\:hover\:dq--translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .\32xl\:focus\:dq-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .\32xl\:focus\:dq-translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }

  .\32xl\:focus\:dq-translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }

  .\32xl\:focus\:dq-translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }

  .\32xl\:focus\:dq-translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .\32xl\:focus\:dq-translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .\32xl\:focus\:dq-translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .\32xl\:focus\:dq-translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .\32xl\:focus\:dq-translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .\32xl\:focus\:dq-translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .\32xl\:focus\:dq-translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .\32xl\:focus\:dq-translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .\32xl\:focus\:dq-translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .\32xl\:focus\:dq-translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .\32xl\:focus\:dq-translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .\32xl\:focus\:dq-translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .\32xl\:focus\:dq-translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .\32xl\:focus\:dq-translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .\32xl\:focus\:dq-translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .\32xl\:focus\:dq-translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .\32xl\:focus\:dq-translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .\32xl\:focus\:dq-translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .\32xl\:focus\:dq-translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .\32xl\:focus\:dq-translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .\32xl\:focus\:dq-translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .\32xl\:focus\:dq-translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .\32xl\:focus\:dq-translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .\32xl\:focus\:dq-translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .\32xl\:focus\:dq-translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .\32xl\:focus\:dq-translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .\32xl\:focus\:dq-translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .\32xl\:focus\:dq-translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }

  .\32xl\:focus\:dq-translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }

  .\32xl\:focus\:dq-translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }

  .\32xl\:focus\:dq-translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }

  .\32xl\:focus\:dq--translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .\32xl\:focus\:dq--translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }

  .\32xl\:focus\:dq--translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }

  .\32xl\:focus\:dq--translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }

  .\32xl\:focus\:dq--translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .\32xl\:focus\:dq--translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .\32xl\:focus\:dq--translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .\32xl\:focus\:dq--translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .\32xl\:focus\:dq--translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .\32xl\:focus\:dq--translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .\32xl\:focus\:dq--translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .\32xl\:focus\:dq--translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .\32xl\:focus\:dq--translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .\32xl\:focus\:dq--translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .\32xl\:focus\:dq--translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .\32xl\:focus\:dq--translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .\32xl\:focus\:dq--translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .\32xl\:focus\:dq--translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .\32xl\:focus\:dq--translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .\32xl\:focus\:dq--translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .\32xl\:focus\:dq--translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .\32xl\:focus\:dq--translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .\32xl\:focus\:dq--translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .\32xl\:focus\:dq--translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .\32xl\:focus\:dq--translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .\32xl\:focus\:dq--translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .\32xl\:focus\:dq--translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .\32xl\:focus\:dq--translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .\32xl\:focus\:dq--translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .\32xl\:focus\:dq--translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .\32xl\:focus\:dq--translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .\32xl\:focus\:dq--translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }

  .\32xl\:focus\:dq--translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }

  .\32xl\:focus\:dq--translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }

  .\32xl\:focus\:dq--translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }

  .\32xl\:focus\:dq-translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .\32xl\:focus\:dq-translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }

  .\32xl\:focus\:dq-translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }

  .\32xl\:focus\:dq-translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .\32xl\:focus\:dq-translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .\32xl\:focus\:dq-translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .\32xl\:focus\:dq-translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .\32xl\:focus\:dq--translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .\32xl\:focus\:dq--translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }

  .\32xl\:focus\:dq--translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }

  .\32xl\:focus\:dq--translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .\32xl\:focus\:dq--translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .\32xl\:focus\:dq--translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .\32xl\:focus\:dq--translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .\32xl\:focus\:dq-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .\32xl\:focus\:dq-translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }

  .\32xl\:focus\:dq-translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }

  .\32xl\:focus\:dq-translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }

  .\32xl\:focus\:dq-translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .\32xl\:focus\:dq-translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .\32xl\:focus\:dq-translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .\32xl\:focus\:dq-translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .\32xl\:focus\:dq-translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .\32xl\:focus\:dq-translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .\32xl\:focus\:dq-translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .\32xl\:focus\:dq-translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .\32xl\:focus\:dq-translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .\32xl\:focus\:dq-translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .\32xl\:focus\:dq-translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .\32xl\:focus\:dq-translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .\32xl\:focus\:dq-translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .\32xl\:focus\:dq-translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .\32xl\:focus\:dq-translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .\32xl\:focus\:dq-translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .\32xl\:focus\:dq-translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .\32xl\:focus\:dq-translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .\32xl\:focus\:dq-translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .\32xl\:focus\:dq-translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .\32xl\:focus\:dq-translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .\32xl\:focus\:dq-translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .\32xl\:focus\:dq-translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .\32xl\:focus\:dq-translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .\32xl\:focus\:dq-translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .\32xl\:focus\:dq-translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .\32xl\:focus\:dq-translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .\32xl\:focus\:dq-translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }

  .\32xl\:focus\:dq-translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }

  .\32xl\:focus\:dq-translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }

  .\32xl\:focus\:dq-translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }

  .\32xl\:focus\:dq--translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .\32xl\:focus\:dq--translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }

  .\32xl\:focus\:dq--translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }

  .\32xl\:focus\:dq--translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }

  .\32xl\:focus\:dq--translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .\32xl\:focus\:dq--translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .\32xl\:focus\:dq--translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .\32xl\:focus\:dq--translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .\32xl\:focus\:dq--translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .\32xl\:focus\:dq--translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .\32xl\:focus\:dq--translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .\32xl\:focus\:dq--translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .\32xl\:focus\:dq--translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .\32xl\:focus\:dq--translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .\32xl\:focus\:dq--translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .\32xl\:focus\:dq--translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .\32xl\:focus\:dq--translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .\32xl\:focus\:dq--translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .\32xl\:focus\:dq--translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .\32xl\:focus\:dq--translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .\32xl\:focus\:dq--translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .\32xl\:focus\:dq--translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .\32xl\:focus\:dq--translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .\32xl\:focus\:dq--translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .\32xl\:focus\:dq--translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .\32xl\:focus\:dq--translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .\32xl\:focus\:dq--translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .\32xl\:focus\:dq--translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .\32xl\:focus\:dq--translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .\32xl\:focus\:dq--translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .\32xl\:focus\:dq--translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .\32xl\:focus\:dq--translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }

  .\32xl\:focus\:dq--translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }

  .\32xl\:focus\:dq--translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }

  .\32xl\:focus\:dq--translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }

  .\32xl\:focus\:dq-translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .\32xl\:focus\:dq-translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }

  .\32xl\:focus\:dq-translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }

  .\32xl\:focus\:dq-translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .\32xl\:focus\:dq-translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .\32xl\:focus\:dq-translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .\32xl\:focus\:dq-translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .\32xl\:focus\:dq--translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .\32xl\:focus\:dq--translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }

  .\32xl\:focus\:dq--translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }

  .\32xl\:focus\:dq--translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .\32xl\:focus\:dq--translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .\32xl\:focus\:dq--translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .\32xl\:focus\:dq--translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .\32xl\:dq-rotate-0 {
    --tw-rotate: 0deg;
  }

  .\32xl\:dq-rotate-1 {
    --tw-rotate: 1deg;
  }

  .\32xl\:dq-rotate-2 {
    --tw-rotate: 2deg;
  }

  .\32xl\:dq-rotate-3 {
    --tw-rotate: 3deg;
  }

  .\32xl\:dq-rotate-6 {
    --tw-rotate: 6deg;
  }

  .\32xl\:dq-rotate-12 {
    --tw-rotate: 12deg;
  }

  .\32xl\:dq-rotate-45 {
    --tw-rotate: 45deg;
  }

  .\32xl\:dq-rotate-90 {
    --tw-rotate: 90deg;
  }

  .\32xl\:dq-rotate-180 {
    --tw-rotate: 180deg;
  }

  .\32xl\:dq--rotate-180 {
    --tw-rotate: -180deg;
  }

  .\32xl\:dq--rotate-90 {
    --tw-rotate: -90deg;
  }

  .\32xl\:dq--rotate-45 {
    --tw-rotate: -45deg;
  }

  .\32xl\:dq--rotate-12 {
    --tw-rotate: -12deg;
  }

  .\32xl\:dq--rotate-6 {
    --tw-rotate: -6deg;
  }

  .\32xl\:dq--rotate-3 {
    --tw-rotate: -3deg;
  }

  .\32xl\:dq--rotate-2 {
    --tw-rotate: -2deg;
  }

  .\32xl\:dq--rotate-1 {
    --tw-rotate: -1deg;
  }

  .\32xl\:hover\:dq-rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .\32xl\:hover\:dq-rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .\32xl\:hover\:dq-rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .\32xl\:hover\:dq-rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .\32xl\:hover\:dq-rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .\32xl\:hover\:dq-rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .\32xl\:hover\:dq-rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .\32xl\:hover\:dq-rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .\32xl\:hover\:dq-rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .\32xl\:hover\:dq--rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .\32xl\:hover\:dq--rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .\32xl\:hover\:dq--rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .\32xl\:hover\:dq--rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .\32xl\:hover\:dq--rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .\32xl\:hover\:dq--rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .\32xl\:hover\:dq--rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .\32xl\:hover\:dq--rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .\32xl\:focus\:dq-rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .\32xl\:focus\:dq-rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .\32xl\:focus\:dq-rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .\32xl\:focus\:dq-rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .\32xl\:focus\:dq-rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .\32xl\:focus\:dq-rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .\32xl\:focus\:dq-rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .\32xl\:focus\:dq-rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .\32xl\:focus\:dq-rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .\32xl\:focus\:dq--rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .\32xl\:focus\:dq--rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .\32xl\:focus\:dq--rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .\32xl\:focus\:dq--rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .\32xl\:focus\:dq--rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .\32xl\:focus\:dq--rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .\32xl\:focus\:dq--rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .\32xl\:focus\:dq--rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .\32xl\:dq-skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .\32xl\:dq-skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .\32xl\:dq-skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .\32xl\:dq-skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .\32xl\:dq-skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .\32xl\:dq-skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .\32xl\:dq--skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .\32xl\:dq--skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .\32xl\:dq--skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .\32xl\:dq--skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .\32xl\:dq--skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .\32xl\:dq-skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .\32xl\:dq-skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .\32xl\:dq-skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .\32xl\:dq-skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .\32xl\:dq-skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .\32xl\:dq-skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .\32xl\:dq--skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .\32xl\:dq--skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .\32xl\:dq--skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .\32xl\:dq--skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .\32xl\:dq--skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .\32xl\:hover\:dq-skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .\32xl\:hover\:dq-skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .\32xl\:hover\:dq-skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .\32xl\:hover\:dq-skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .\32xl\:hover\:dq-skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .\32xl\:hover\:dq-skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .\32xl\:hover\:dq--skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .\32xl\:hover\:dq--skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .\32xl\:hover\:dq--skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .\32xl\:hover\:dq--skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .\32xl\:hover\:dq--skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .\32xl\:hover\:dq-skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .\32xl\:hover\:dq-skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .\32xl\:hover\:dq-skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .\32xl\:hover\:dq-skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .\32xl\:hover\:dq-skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .\32xl\:hover\:dq-skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .\32xl\:hover\:dq--skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .\32xl\:hover\:dq--skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .\32xl\:hover\:dq--skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .\32xl\:hover\:dq--skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .\32xl\:hover\:dq--skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .\32xl\:focus\:dq-skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .\32xl\:focus\:dq-skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .\32xl\:focus\:dq-skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .\32xl\:focus\:dq-skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .\32xl\:focus\:dq-skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .\32xl\:focus\:dq-skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .\32xl\:focus\:dq--skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .\32xl\:focus\:dq--skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .\32xl\:focus\:dq--skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .\32xl\:focus\:dq--skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .\32xl\:focus\:dq--skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .\32xl\:focus\:dq-skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .\32xl\:focus\:dq-skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .\32xl\:focus\:dq-skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .\32xl\:focus\:dq-skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .\32xl\:focus\:dq-skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .\32xl\:focus\:dq-skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .\32xl\:focus\:dq--skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .\32xl\:focus\:dq--skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .\32xl\:focus\:dq--skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .\32xl\:focus\:dq--skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .\32xl\:focus\:dq--skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .\32xl\:dq-scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .\32xl\:dq-scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .\32xl\:dq-scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .\32xl\:dq-scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .\32xl\:dq-scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .\32xl\:dq-scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .\32xl\:dq-scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .\32xl\:dq-scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .\32xl\:dq-scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .\32xl\:dq-scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .\32xl\:hover\:dq-scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .\32xl\:hover\:dq-scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .\32xl\:hover\:dq-scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .\32xl\:hover\:dq-scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .\32xl\:hover\:dq-scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .\32xl\:hover\:dq-scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .\32xl\:hover\:dq-scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .\32xl\:hover\:dq-scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .\32xl\:hover\:dq-scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .\32xl\:hover\:dq-scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .\32xl\:focus\:dq-scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .\32xl\:focus\:dq-scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .\32xl\:focus\:dq-scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .\32xl\:focus\:dq-scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .\32xl\:focus\:dq-scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .\32xl\:focus\:dq-scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .\32xl\:focus\:dq-scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .\32xl\:focus\:dq-scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .\32xl\:focus\:dq-scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .\32xl\:focus\:dq-scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .\32xl\:dq-scale-x-0 {
    --tw-scale-x: 0;
  }

  .\32xl\:dq-scale-x-50 {
    --tw-scale-x: .5;
  }

  .\32xl\:dq-scale-x-75 {
    --tw-scale-x: .75;
  }

  .\32xl\:dq-scale-x-90 {
    --tw-scale-x: .9;
  }

  .\32xl\:dq-scale-x-95 {
    --tw-scale-x: .95;
  }

  .\32xl\:dq-scale-x-100 {
    --tw-scale-x: 1;
  }

  .\32xl\:dq-scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .\32xl\:dq-scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .\32xl\:dq-scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .\32xl\:dq-scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .\32xl\:dq-scale-y-0 {
    --tw-scale-y: 0;
  }

  .\32xl\:dq-scale-y-50 {
    --tw-scale-y: .5;
  }

  .\32xl\:dq-scale-y-75 {
    --tw-scale-y: .75;
  }

  .\32xl\:dq-scale-y-90 {
    --tw-scale-y: .9;
  }

  .\32xl\:dq-scale-y-95 {
    --tw-scale-y: .95;
  }

  .\32xl\:dq-scale-y-100 {
    --tw-scale-y: 1;
  }

  .\32xl\:dq-scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .\32xl\:dq-scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .\32xl\:dq-scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .\32xl\:dq-scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .\32xl\:hover\:dq-scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .\32xl\:hover\:dq-scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .\32xl\:hover\:dq-scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .\32xl\:hover\:dq-scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .\32xl\:hover\:dq-scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .\32xl\:hover\:dq-scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .\32xl\:hover\:dq-scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .\32xl\:hover\:dq-scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .\32xl\:hover\:dq-scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .\32xl\:hover\:dq-scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .\32xl\:hover\:dq-scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .\32xl\:hover\:dq-scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .\32xl\:hover\:dq-scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .\32xl\:hover\:dq-scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .\32xl\:hover\:dq-scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .\32xl\:hover\:dq-scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .\32xl\:hover\:dq-scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .\32xl\:hover\:dq-scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .\32xl\:hover\:dq-scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .\32xl\:hover\:dq-scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .\32xl\:focus\:dq-scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .\32xl\:focus\:dq-scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .\32xl\:focus\:dq-scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .\32xl\:focus\:dq-scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .\32xl\:focus\:dq-scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .\32xl\:focus\:dq-scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .\32xl\:focus\:dq-scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .\32xl\:focus\:dq-scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .\32xl\:focus\:dq-scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .\32xl\:focus\:dq-scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .\32xl\:focus\:dq-scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .\32xl\:focus\:dq-scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .\32xl\:focus\:dq-scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .\32xl\:focus\:dq-scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .\32xl\:focus\:dq-scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .\32xl\:focus\:dq-scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .\32xl\:focus\:dq-scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .\32xl\:focus\:dq-scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .\32xl\:focus\:dq-scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .\32xl\:focus\:dq-scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .\32xl\:dq-animate-none {
    animation: none;
  }

  .\32xl\:dq-animate-spin {
    animation: dq-spin 1s linear infinite;
  }

  .\32xl\:dq-animate-ping {
    animation: dq-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .\32xl\:dq-animate-pulse {
    animation: dq-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .\32xl\:dq-animate-bounce {
    animation: dq-bounce 1s infinite;
  }

  .\32xl\:dq-cursor-auto {
    cursor: auto;
  }

  .\32xl\:dq-cursor-default {
    cursor: default;
  }

  .\32xl\:dq-cursor-pointer {
    cursor: pointer;
  }

  .\32xl\:dq-cursor-wait {
    cursor: wait;
  }

  .\32xl\:dq-cursor-text {
    cursor: text;
  }

  .\32xl\:dq-cursor-move {
    cursor: move;
  }

  .\32xl\:dq-cursor-help {
    cursor: help;
  }

  .\32xl\:dq-cursor-not-allowed {
    cursor: not-allowed;
  }

  .\32xl\:dq-select-none {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .\32xl\:dq-select-text {
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .\32xl\:dq-select-all {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
  }

  .\32xl\:dq-select-auto {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }

  .\32xl\:dq-resize-none {
    resize: none;
  }

  .\32xl\:dq-resize-y {
    resize: vertical;
  }

  .\32xl\:dq-resize-x {
    resize: horizontal;
  }

  .\32xl\:dq-resize {
    resize: both;
  }

  .\32xl\:dq-list-inside {
    list-style-position: inside;
  }

  .\32xl\:dq-list-outside {
    list-style-position: outside;
  }

  .\32xl\:dq-list-none {
    list-style-type: none;
  }

  .\32xl\:dq-list-disc {
    list-style-type: disc;
  }

  .\32xl\:dq-list-decimal {
    list-style-type: decimal;
  }

  .\32xl\:dq-appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  .\32xl\:dq-auto-cols-auto {
    grid-auto-columns: auto;
  }

  .\32xl\:dq-auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
  }

  .\32xl\:dq-auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
  }

  .\32xl\:dq-auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .\32xl\:dq-grid-flow-row {
    grid-auto-flow: row;
  }

  .\32xl\:dq-grid-flow-col {
    grid-auto-flow: column;
  }

  .\32xl\:dq-grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .\32xl\:dq-grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .\32xl\:dq-auto-rows-auto {
    grid-auto-rows: auto;
  }

  .\32xl\:dq-auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .\32xl\:dq-auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
  }

  .\32xl\:dq-auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .\32xl\:dq-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-cols-none {
    grid-template-columns: none;
  }

  .\32xl\:dq-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .\32xl\:dq-grid-rows-none {
    grid-template-rows: none;
  }

  .\32xl\:dq-flex-row {
    flex-direction: row;
  }

  .\32xl\:dq-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .\32xl\:dq-flex-col {
    flex-direction: column;
  }

  .\32xl\:dq-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .\32xl\:dq-flex-wrap {
    flex-wrap: wrap;
  }

  .\32xl\:dq-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .\32xl\:dq-flex-nowrap {
    flex-wrap: nowrap;
  }

  .\32xl\:dq-place-content-center {
    place-content: center;
  }

  .\32xl\:dq-place-content-start {
    place-content: start;
  }

  .\32xl\:dq-place-content-end {
    place-content: end;
  }

  .\32xl\:dq-place-content-between {
    place-content: space-between;
  }

  .\32xl\:dq-place-content-around {
    place-content: space-around;
  }

  .\32xl\:dq-place-content-evenly {
    place-content: space-evenly;
  }

  .\32xl\:dq-place-content-stretch {
    place-content: stretch;
  }

  .\32xl\:dq-place-items-start {
    place-items: start;
  }

  .\32xl\:dq-place-items-end {
    place-items: end;
  }

  .\32xl\:dq-place-items-center {
    place-items: center;
  }

  .\32xl\:dq-place-items-stretch {
    place-items: stretch;
  }

  .\32xl\:dq-content-center {
    align-content: center;
  }

  .\32xl\:dq-content-start {
    align-content: flex-start;
  }

  .\32xl\:dq-content-end {
    align-content: flex-end;
  }

  .\32xl\:dq-content-between {
    align-content: space-between;
  }

  .\32xl\:dq-content-around {
    align-content: space-around;
  }

  .\32xl\:dq-content-evenly {
    align-content: space-evenly;
  }

  .\32xl\:dq-items-start {
    align-items: flex-start;
  }

  .\32xl\:dq-items-end {
    align-items: flex-end;
  }

  .\32xl\:dq-items-center {
    align-items: center;
  }

  .\32xl\:dq-items-baseline {
    align-items: baseline;
  }

  .\32xl\:dq-items-stretch {
    align-items: stretch;
  }

  .\32xl\:dq-justify-start {
    justify-content: flex-start;
  }

  .\32xl\:dq-justify-end {
    justify-content: flex-end;
  }

  .\32xl\:dq-justify-center {
    justify-content: center;
  }

  .\32xl\:dq-justify-between {
    justify-content: space-between;
  }

  .\32xl\:dq-justify-around {
    justify-content: space-around;
  }

  .\32xl\:dq-justify-evenly {
    justify-content: space-evenly;
  }

  .\32xl\:dq-justify-items-start {
    justify-items: start;
  }

  .\32xl\:dq-justify-items-end {
    justify-items: end;
  }

  .\32xl\:dq-justify-items-center {
    justify-items: center;
  }

  .\32xl\:dq-justify-items-stretch {
    justify-items: stretch;
  }

  .\32xl\:dq-gap-0 {
    gap: 0px;
  }

  .\32xl\:dq-gap-1 {
    gap: 0.25rem;
  }

  .\32xl\:dq-gap-2 {
    gap: 0.5rem;
  }

  .\32xl\:dq-gap-3 {
    gap: 0.75rem;
  }

  .\32xl\:dq-gap-4 {
    gap: 1rem;
  }

  .\32xl\:dq-gap-5 {
    gap: 1.25rem;
  }

  .\32xl\:dq-gap-6 {
    gap: 1.5rem;
  }

  .\32xl\:dq-gap-7 {
    gap: 1.75rem;
  }

  .\32xl\:dq-gap-8 {
    gap: 2rem;
  }

  .\32xl\:dq-gap-9 {
    gap: 2.25rem;
  }

  .\32xl\:dq-gap-10 {
    gap: 2.5rem;
  }

  .\32xl\:dq-gap-11 {
    gap: 2.75rem;
  }

  .\32xl\:dq-gap-12 {
    gap: 3rem;
  }

  .\32xl\:dq-gap-14 {
    gap: 3.5rem;
  }

  .\32xl\:dq-gap-16 {
    gap: 4rem;
  }

  .\32xl\:dq-gap-20 {
    gap: 5rem;
  }

  .\32xl\:dq-gap-24 {
    gap: 6rem;
  }

  .\32xl\:dq-gap-28 {
    gap: 7rem;
  }

  .\32xl\:dq-gap-32 {
    gap: 8rem;
  }

  .\32xl\:dq-gap-36 {
    gap: 9rem;
  }

  .\32xl\:dq-gap-40 {
    gap: 10rem;
  }

  .\32xl\:dq-gap-44 {
    gap: 11rem;
  }

  .\32xl\:dq-gap-48 {
    gap: 12rem;
  }

  .\32xl\:dq-gap-52 {
    gap: 13rem;
  }

  .\32xl\:dq-gap-56 {
    gap: 14rem;
  }

  .\32xl\:dq-gap-60 {
    gap: 15rem;
  }

  .\32xl\:dq-gap-64 {
    gap: 16rem;
  }

  .\32xl\:dq-gap-72 {
    gap: 18rem;
  }

  .\32xl\:dq-gap-80 {
    gap: 20rem;
  }

  .\32xl\:dq-gap-96 {
    gap: 24rem;
  }

  .\32xl\:dq-gap-px {
    gap: 1px;
  }

  .\32xl\:dq-gap-0\.5 {
    gap: 0.125rem;
  }

  .\32xl\:dq-gap-1\.5 {
    gap: 0.375rem;
  }

  .\32xl\:dq-gap-2\.5 {
    gap: 0.625rem;
  }

  .\32xl\:dq-gap-3\.5 {
    gap: 0.875rem;
  }

  .\32xl\:dq-gap-x-0 {
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }

  .\32xl\:dq-gap-x-1 {
    -webkit-column-gap: 0.25rem;
            column-gap: 0.25rem;
  }

  .\32xl\:dq-gap-x-2 {
    -webkit-column-gap: 0.5rem;
            column-gap: 0.5rem;
  }

  .\32xl\:dq-gap-x-3 {
    -webkit-column-gap: 0.75rem;
            column-gap: 0.75rem;
  }

  .\32xl\:dq-gap-x-4 {
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
  }

  .\32xl\:dq-gap-x-5 {
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
  }

  .\32xl\:dq-gap-x-6 {
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
  }

  .\32xl\:dq-gap-x-7 {
    -webkit-column-gap: 1.75rem;
            column-gap: 1.75rem;
  }

  .\32xl\:dq-gap-x-8 {
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }

  .\32xl\:dq-gap-x-9 {
    -webkit-column-gap: 2.25rem;
            column-gap: 2.25rem;
  }

  .\32xl\:dq-gap-x-10 {
    -webkit-column-gap: 2.5rem;
            column-gap: 2.5rem;
  }

  .\32xl\:dq-gap-x-11 {
    -webkit-column-gap: 2.75rem;
            column-gap: 2.75rem;
  }

  .\32xl\:dq-gap-x-12 {
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }

  .\32xl\:dq-gap-x-14 {
    -webkit-column-gap: 3.5rem;
            column-gap: 3.5rem;
  }

  .\32xl\:dq-gap-x-16 {
    -webkit-column-gap: 4rem;
            column-gap: 4rem;
  }

  .\32xl\:dq-gap-x-20 {
    -webkit-column-gap: 5rem;
            column-gap: 5rem;
  }

  .\32xl\:dq-gap-x-24 {
    -webkit-column-gap: 6rem;
            column-gap: 6rem;
  }

  .\32xl\:dq-gap-x-28 {
    -webkit-column-gap: 7rem;
            column-gap: 7rem;
  }

  .\32xl\:dq-gap-x-32 {
    -webkit-column-gap: 8rem;
            column-gap: 8rem;
  }

  .\32xl\:dq-gap-x-36 {
    -webkit-column-gap: 9rem;
            column-gap: 9rem;
  }

  .\32xl\:dq-gap-x-40 {
    -webkit-column-gap: 10rem;
            column-gap: 10rem;
  }

  .\32xl\:dq-gap-x-44 {
    -webkit-column-gap: 11rem;
            column-gap: 11rem;
  }

  .\32xl\:dq-gap-x-48 {
    -webkit-column-gap: 12rem;
            column-gap: 12rem;
  }

  .\32xl\:dq-gap-x-52 {
    -webkit-column-gap: 13rem;
            column-gap: 13rem;
  }

  .\32xl\:dq-gap-x-56 {
    -webkit-column-gap: 14rem;
            column-gap: 14rem;
  }

  .\32xl\:dq-gap-x-60 {
    -webkit-column-gap: 15rem;
            column-gap: 15rem;
  }

  .\32xl\:dq-gap-x-64 {
    -webkit-column-gap: 16rem;
            column-gap: 16rem;
  }

  .\32xl\:dq-gap-x-72 {
    -webkit-column-gap: 18rem;
            column-gap: 18rem;
  }

  .\32xl\:dq-gap-x-80 {
    -webkit-column-gap: 20rem;
            column-gap: 20rem;
  }

  .\32xl\:dq-gap-x-96 {
    -webkit-column-gap: 24rem;
            column-gap: 24rem;
  }

  .\32xl\:dq-gap-x-px {
    -webkit-column-gap: 1px;
            column-gap: 1px;
  }

  .\32xl\:dq-gap-x-0\.5 {
    -webkit-column-gap: 0.125rem;
            column-gap: 0.125rem;
  }

  .\32xl\:dq-gap-x-1\.5 {
    -webkit-column-gap: 0.375rem;
            column-gap: 0.375rem;
  }

  .\32xl\:dq-gap-x-2\.5 {
    -webkit-column-gap: 0.625rem;
            column-gap: 0.625rem;
  }

  .\32xl\:dq-gap-x-3\.5 {
    -webkit-column-gap: 0.875rem;
            column-gap: 0.875rem;
  }

  .\32xl\:dq-gap-y-0 {
    row-gap: 0px;
  }

  .\32xl\:dq-gap-y-1 {
    row-gap: 0.25rem;
  }

  .\32xl\:dq-gap-y-2 {
    row-gap: 0.5rem;
  }

  .\32xl\:dq-gap-y-3 {
    row-gap: 0.75rem;
  }

  .\32xl\:dq-gap-y-4 {
    row-gap: 1rem;
  }

  .\32xl\:dq-gap-y-5 {
    row-gap: 1.25rem;
  }

  .\32xl\:dq-gap-y-6 {
    row-gap: 1.5rem;
  }

  .\32xl\:dq-gap-y-7 {
    row-gap: 1.75rem;
  }

  .\32xl\:dq-gap-y-8 {
    row-gap: 2rem;
  }

  .\32xl\:dq-gap-y-9 {
    row-gap: 2.25rem;
  }

  .\32xl\:dq-gap-y-10 {
    row-gap: 2.5rem;
  }

  .\32xl\:dq-gap-y-11 {
    row-gap: 2.75rem;
  }

  .\32xl\:dq-gap-y-12 {
    row-gap: 3rem;
  }

  .\32xl\:dq-gap-y-14 {
    row-gap: 3.5rem;
  }

  .\32xl\:dq-gap-y-16 {
    row-gap: 4rem;
  }

  .\32xl\:dq-gap-y-20 {
    row-gap: 5rem;
  }

  .\32xl\:dq-gap-y-24 {
    row-gap: 6rem;
  }

  .\32xl\:dq-gap-y-28 {
    row-gap: 7rem;
  }

  .\32xl\:dq-gap-y-32 {
    row-gap: 8rem;
  }

  .\32xl\:dq-gap-y-36 {
    row-gap: 9rem;
  }

  .\32xl\:dq-gap-y-40 {
    row-gap: 10rem;
  }

  .\32xl\:dq-gap-y-44 {
    row-gap: 11rem;
  }

  .\32xl\:dq-gap-y-48 {
    row-gap: 12rem;
  }

  .\32xl\:dq-gap-y-52 {
    row-gap: 13rem;
  }

  .\32xl\:dq-gap-y-56 {
    row-gap: 14rem;
  }

  .\32xl\:dq-gap-y-60 {
    row-gap: 15rem;
  }

  .\32xl\:dq-gap-y-64 {
    row-gap: 16rem;
  }

  .\32xl\:dq-gap-y-72 {
    row-gap: 18rem;
  }

  .\32xl\:dq-gap-y-80 {
    row-gap: 20rem;
  }

  .\32xl\:dq-gap-y-96 {
    row-gap: 24rem;
  }

  .\32xl\:dq-gap-y-px {
    row-gap: 1px;
  }

  .\32xl\:dq-gap-y-0\.5 {
    row-gap: 0.125rem;
  }

  .\32xl\:dq-gap-y-1\.5 {
    row-gap: 0.375rem;
  }

  .\32xl\:dq-gap-y-2\.5 {
    row-gap: 0.625rem;
  }

  .\32xl\:dq-gap-y-3\.5 {
    row-gap: 0.875rem;
  }

  .\32xl\:dq-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq--space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .\32xl\:dq-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq--space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }

  .\32xl\:dq-space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .\32xl\:dq-space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .\32xl\:dq-divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .\32xl\:dq-divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .\32xl\:dq-divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .\32xl\:dq-divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .\32xl\:dq-divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .\32xl\:dq-divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .\32xl\:dq-divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .\32xl\:dq-divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .\32xl\:dq-divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .\32xl\:dq-divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .\32xl\:dq-divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .\32xl\:dq-divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .\32xl\:dq-divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .\32xl\:dq-divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .\32xl\:dq-divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .\32xl\:dq-divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .\32xl\:dq-divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .\32xl\:dq-divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }

  .\32xl\:dq-divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .\32xl\:dq-divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .\32xl\:dq-divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .\32xl\:dq-divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }

  .\32xl\:dq-divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }

  .\32xl\:dq-divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }

  .\32xl\:dq-divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }

  .\32xl\:dq-divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }

  .\32xl\:dq-divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }

  .\32xl\:dq-divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }

  .\32xl\:dq-divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }

  .\32xl\:dq-divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }

  .\32xl\:dq-divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }

  .\32xl\:dq-divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }

  .\32xl\:dq-divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }

  .\32xl\:dq-divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }

  .\32xl\:dq-divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .\32xl\:dq-place-self-auto {
    place-self: auto;
  }

  .\32xl\:dq-place-self-start {
    place-self: start;
  }

  .\32xl\:dq-place-self-end {
    place-self: end;
  }

  .\32xl\:dq-place-self-center {
    place-self: center;
  }

  .\32xl\:dq-place-self-stretch {
    place-self: stretch;
  }

  .\32xl\:dq-self-auto {
    align-self: auto;
  }

  .\32xl\:dq-self-start {
    align-self: flex-start;
  }

  .\32xl\:dq-self-end {
    align-self: flex-end;
  }

  .\32xl\:dq-self-center {
    align-self: center;
  }

  .\32xl\:dq-self-stretch {
    align-self: stretch;
  }

  .\32xl\:dq-self-baseline {
    align-self: baseline;
  }

  .\32xl\:dq-justify-self-auto {
    justify-self: auto;
  }

  .\32xl\:dq-justify-self-start {
    justify-self: start;
  }

  .\32xl\:dq-justify-self-end {
    justify-self: end;
  }

  .\32xl\:dq-justify-self-center {
    justify-self: center;
  }

  .\32xl\:dq-justify-self-stretch {
    justify-self: stretch;
  }

  .\32xl\:dq-overflow-auto {
    overflow: auto;
  }

  .\32xl\:dq-overflow-hidden {
    overflow: hidden;
  }

  .\32xl\:dq-overflow-visible {
    overflow: visible;
  }

  .\32xl\:dq-overflow-scroll {
    overflow: scroll;
  }

  .\32xl\:dq-overflow-x-auto {
    overflow-x: auto;
  }

  .\32xl\:dq-overflow-y-auto {
    overflow-y: auto;
  }

  .\32xl\:dq-overflow-x-hidden {
    overflow-x: hidden;
  }

  .\32xl\:dq-overflow-y-hidden {
    overflow-y: hidden;
  }

  .\32xl\:dq-overflow-x-visible {
    overflow-x: visible;
  }

  .\32xl\:dq-overflow-y-visible {
    overflow-y: visible;
  }

  .\32xl\:dq-overflow-x-scroll {
    overflow-x: scroll;
  }

  .\32xl\:dq-overflow-y-scroll {
    overflow-y: scroll;
  }

  .\32xl\:dq-overscroll-auto {
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
  }

  .\32xl\:dq-overscroll-contain {
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }

  .\32xl\:dq-overscroll-none {
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
  }

  .\32xl\:dq-overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .\32xl\:dq-overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .\32xl\:dq-overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .\32xl\:dq-overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .\32xl\:dq-overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .\32xl\:dq-overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .\32xl\:dq-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .\32xl\:dq-overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .\32xl\:dq-overflow-clip {
    text-overflow: clip;
  }

  .\32xl\:dq-whitespace-normal {
    white-space: normal;
  }

  .\32xl\:dq-whitespace-nowrap {
    white-space: nowrap;
  }

  .\32xl\:dq-whitespace-pre {
    white-space: pre;
  }

  .\32xl\:dq-whitespace-pre-line {
    white-space: pre-line;
  }

  .\32xl\:dq-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .\32xl\:dq-break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .\32xl\:dq-break-words {
    overflow-wrap: break-word;
  }

  .\32xl\:dq-break-all {
    word-break: break-all;
  }

  .\32xl\:dq-rounded-none {
    border-radius: 0px;
  }

  .\32xl\:dq-rounded-sm {
    border-radius: 0.125rem;
  }

  .\32xl\:dq-rounded {
    border-radius: 0.25rem;
  }

  .\32xl\:dq-rounded-md {
    border-radius: 0.375rem;
  }

  .\32xl\:dq-rounded-lg {
    border-radius: 0.5rem;
  }

  .\32xl\:dq-rounded-xl {
    border-radius: 0.75rem;
  }

  .\32xl\:dq-rounded-2xl {
    border-radius: 1rem;
  }

  .\32xl\:dq-rounded-3xl {
    border-radius: 1.5rem;
  }

  .\32xl\:dq-rounded-full {
    border-radius: 9999px;
  }

  .\32xl\:dq-rounded-t-none {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .\32xl\:dq-rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .\32xl\:dq-rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .\32xl\:dq-rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .\32xl\:dq-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .\32xl\:dq-rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .\32xl\:dq-rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .\32xl\:dq-rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .\32xl\:dq-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .\32xl\:dq-rounded-r-none {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .\32xl\:dq-rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .\32xl\:dq-rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .\32xl\:dq-rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .\32xl\:dq-rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .\32xl\:dq-rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .\32xl\:dq-rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .\32xl\:dq-rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .\32xl\:dq-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .\32xl\:dq-rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .\32xl\:dq-rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .\32xl\:dq-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .\32xl\:dq-rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .\32xl\:dq-rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .\32xl\:dq-rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .\32xl\:dq-rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .\32xl\:dq-rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .\32xl\:dq-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .\32xl\:dq-rounded-l-none {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .\32xl\:dq-rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .\32xl\:dq-rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .\32xl\:dq-rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .\32xl\:dq-rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .\32xl\:dq-rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .\32xl\:dq-rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .\32xl\:dq-rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .\32xl\:dq-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .\32xl\:dq-rounded-tl-none {
    border-top-left-radius: 0px;
  }

  .\32xl\:dq-rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .\32xl\:dq-rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .\32xl\:dq-rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .\32xl\:dq-rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .\32xl\:dq-rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .\32xl\:dq-rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .\32xl\:dq-rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .\32xl\:dq-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .\32xl\:dq-rounded-tr-none {
    border-top-right-radius: 0px;
  }

  .\32xl\:dq-rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .\32xl\:dq-rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .\32xl\:dq-rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .\32xl\:dq-rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .\32xl\:dq-rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .\32xl\:dq-rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .\32xl\:dq-rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .\32xl\:dq-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .\32xl\:dq-rounded-br-none {
    border-bottom-right-radius: 0px;
  }

  .\32xl\:dq-rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .\32xl\:dq-rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .\32xl\:dq-rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .\32xl\:dq-rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .\32xl\:dq-rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .\32xl\:dq-rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .\32xl\:dq-rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .\32xl\:dq-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .\32xl\:dq-rounded-bl-none {
    border-bottom-left-radius: 0px;
  }

  .\32xl\:dq-rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .\32xl\:dq-rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .\32xl\:dq-rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .\32xl\:dq-rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .\32xl\:dq-rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .\32xl\:dq-rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .\32xl\:dq-rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .\32xl\:dq-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .\32xl\:dq-border-0 {
    border-width: 0px;
  }

  .\32xl\:dq-border-2 {
    border-width: 2px;
  }

  .\32xl\:dq-border-4 {
    border-width: 4px;
  }

  .\32xl\:dq-border-8 {
    border-width: 8px;
  }

  .\32xl\:dq-border {
    border-width: 1px;
  }

  .\32xl\:dq-border-t-0 {
    border-top-width: 0px;
  }

  .\32xl\:dq-border-t-2 {
    border-top-width: 2px;
  }

  .\32xl\:dq-border-t-4 {
    border-top-width: 4px;
  }

  .\32xl\:dq-border-t-8 {
    border-top-width: 8px;
  }

  .\32xl\:dq-border-t {
    border-top-width: 1px;
  }

  .\32xl\:dq-border-r-0 {
    border-right-width: 0px;
  }

  .\32xl\:dq-border-r-2 {
    border-right-width: 2px;
  }

  .\32xl\:dq-border-r-4 {
    border-right-width: 4px;
  }

  .\32xl\:dq-border-r-8 {
    border-right-width: 8px;
  }

  .\32xl\:dq-border-r {
    border-right-width: 1px;
  }

  .\32xl\:dq-border-b-0 {
    border-bottom-width: 0px;
  }

  .\32xl\:dq-border-b-2 {
    border-bottom-width: 2px;
  }

  .\32xl\:dq-border-b-4 {
    border-bottom-width: 4px;
  }

  .\32xl\:dq-border-b-8 {
    border-bottom-width: 8px;
  }

  .\32xl\:dq-border-b {
    border-bottom-width: 1px;
  }

  .\32xl\:dq-border-l-0 {
    border-left-width: 0px;
  }

  .\32xl\:dq-border-l-2 {
    border-left-width: 2px;
  }

  .\32xl\:dq-border-l-4 {
    border-left-width: 4px;
  }

  .\32xl\:dq-border-l-8 {
    border-left-width: 8px;
  }

  .\32xl\:dq-border-l {
    border-left-width: 1px;
  }

  .\32xl\:dq-border-solid {
    border-style: solid;
  }

  .\32xl\:dq-border-dashed {
    border-style: dashed;
  }

  .\32xl\:dq-border-dotted {
    border-style: dotted;
  }

  .\32xl\:dq-border-double {
    border-style: double;
  }

  .\32xl\:dq-border-none {
    border-style: none;
  }

  .\32xl\:dq-border-transparent {
    border-color: transparent;
  }

  .\32xl\:dq-border-current {
    border-color: currentColor;
  }

  .\32xl\:dq-border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-transparent {
    border-color: transparent;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-current {
    border-color: currentColor;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-transparent:focus-within {
    border-color: transparent;
  }

  .\32xl\:focus-within\:dq-border-current:focus-within {
    border-color: currentColor;
  }

  .\32xl\:focus-within\:dq-border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .\32xl\:focus-within\:dq-border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-transparent:hover {
    border-color: transparent;
  }

  .\32xl\:hover\:dq-border-current:hover {
    border-color: currentColor;
  }

  .\32xl\:hover\:dq-border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .\32xl\:hover\:dq-border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-transparent:focus {
    border-color: transparent;
  }

  .\32xl\:focus\:dq-border-current:focus {
    border-color: currentColor;
  }

  .\32xl\:focus\:dq-border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .\32xl\:focus\:dq-border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .\32xl\:dq-border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .\32xl\:dq-border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .\32xl\:dq-border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .\32xl\:dq-border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .\32xl\:dq-border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .\32xl\:dq-border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .\32xl\:dq-border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .\32xl\:dq-border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .\32xl\:dq-border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .\32xl\:dq-border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .\32xl\:dq-border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .\32xl\:dq-border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .\32xl\:dq-border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .\32xl\:dq-border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .\32xl\:dq-border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .\32xl\:focus-within\:dq-border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .\32xl\:focus-within\:dq-border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }

  .\32xl\:focus-within\:dq-border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }

  .\32xl\:focus-within\:dq-border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }

  .\32xl\:focus-within\:dq-border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }

  .\32xl\:focus-within\:dq-border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }

  .\32xl\:focus-within\:dq-border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }

  .\32xl\:focus-within\:dq-border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }

  .\32xl\:focus-within\:dq-border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }

  .\32xl\:focus-within\:dq-border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }

  .\32xl\:focus-within\:dq-border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }

  .\32xl\:focus-within\:dq-border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }

  .\32xl\:focus-within\:dq-border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }

  .\32xl\:focus-within\:dq-border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }

  .\32xl\:focus-within\:dq-border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .\32xl\:hover\:dq-border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .\32xl\:hover\:dq-border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }

  .\32xl\:hover\:dq-border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }

  .\32xl\:hover\:dq-border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }

  .\32xl\:hover\:dq-border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }

  .\32xl\:hover\:dq-border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }

  .\32xl\:hover\:dq-border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }

  .\32xl\:hover\:dq-border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }

  .\32xl\:hover\:dq-border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }

  .\32xl\:hover\:dq-border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }

  .\32xl\:hover\:dq-border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }

  .\32xl\:hover\:dq-border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }

  .\32xl\:hover\:dq-border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }

  .\32xl\:hover\:dq-border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }

  .\32xl\:hover\:dq-border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .\32xl\:focus\:dq-border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .\32xl\:focus\:dq-border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }

  .\32xl\:focus\:dq-border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }

  .\32xl\:focus\:dq-border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }

  .\32xl\:focus\:dq-border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }

  .\32xl\:focus\:dq-border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }

  .\32xl\:focus\:dq-border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }

  .\32xl\:focus\:dq-border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }

  .\32xl\:focus\:dq-border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }

  .\32xl\:focus\:dq-border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }

  .\32xl\:focus\:dq-border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }

  .\32xl\:focus\:dq-border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }

  .\32xl\:focus\:dq-border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }

  .\32xl\:focus\:dq-border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }

  .\32xl\:focus\:dq-border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .\32xl\:dq-bg-transparent {
    background-color: transparent;
  }

  .\32xl\:dq-bg-current {
    background-color: currentColor;
  }

  .\32xl\:dq-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-transparent {
    background-color: transparent;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-current {
    background-color: currentColor;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-transparent:focus-within {
    background-color: transparent;
  }

  .\32xl\:focus-within\:dq-bg-current:focus-within {
    background-color: currentColor;
  }

  .\32xl\:focus-within\:dq-bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .\32xl\:focus-within\:dq-bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-transparent:hover {
    background-color: transparent;
  }

  .\32xl\:hover\:dq-bg-current:hover {
    background-color: currentColor;
  }

  .\32xl\:hover\:dq-bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .\32xl\:hover\:dq-bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-transparent:focus {
    background-color: transparent;
  }

  .\32xl\:focus\:dq-bg-current:focus {
    background-color: currentColor;
  }

  .\32xl\:focus\:dq-bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .\32xl\:focus\:dq-bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .\32xl\:dq-bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .\32xl\:dq-bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .\32xl\:dq-bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .\32xl\:dq-bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .\32xl\:dq-bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .\32xl\:dq-bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .\32xl\:dq-bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .\32xl\:dq-bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .\32xl\:dq-bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .\32xl\:dq-bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .\32xl\:dq-bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .\32xl\:dq-bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .\32xl\:dq-bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .\32xl\:dq-bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .\32xl\:dq-bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .\32xl\:focus-within\:dq-bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .\32xl\:focus-within\:dq-bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }

  .\32xl\:focus-within\:dq-bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }

  .\32xl\:focus-within\:dq-bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }

  .\32xl\:focus-within\:dq-bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }

  .\32xl\:focus-within\:dq-bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }

  .\32xl\:focus-within\:dq-bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }

  .\32xl\:focus-within\:dq-bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }

  .\32xl\:focus-within\:dq-bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }

  .\32xl\:focus-within\:dq-bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }

  .\32xl\:focus-within\:dq-bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }

  .\32xl\:focus-within\:dq-bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }

  .\32xl\:focus-within\:dq-bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }

  .\32xl\:focus-within\:dq-bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }

  .\32xl\:focus-within\:dq-bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .\32xl\:hover\:dq-bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .\32xl\:hover\:dq-bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }

  .\32xl\:hover\:dq-bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }

  .\32xl\:hover\:dq-bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }

  .\32xl\:hover\:dq-bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }

  .\32xl\:hover\:dq-bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }

  .\32xl\:hover\:dq-bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }

  .\32xl\:hover\:dq-bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }

  .\32xl\:hover\:dq-bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }

  .\32xl\:hover\:dq-bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }

  .\32xl\:hover\:dq-bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }

  .\32xl\:hover\:dq-bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }

  .\32xl\:hover\:dq-bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }

  .\32xl\:hover\:dq-bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }

  .\32xl\:hover\:dq-bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .\32xl\:focus\:dq-bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .\32xl\:focus\:dq-bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }

  .\32xl\:focus\:dq-bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }

  .\32xl\:focus\:dq-bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }

  .\32xl\:focus\:dq-bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }

  .\32xl\:focus\:dq-bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }

  .\32xl\:focus\:dq-bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }

  .\32xl\:focus\:dq-bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }

  .\32xl\:focus\:dq-bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }

  .\32xl\:focus\:dq-bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }

  .\32xl\:focus\:dq-bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }

  .\32xl\:focus\:dq-bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }

  .\32xl\:focus\:dq-bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }

  .\32xl\:focus\:dq-bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }

  .\32xl\:focus\:dq-bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .\32xl\:dq-bg-none {
    background-image: none;
  }

  .\32xl\:dq-bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .\32xl\:dq-bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .\32xl\:dq-bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .\32xl\:dq-bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .\32xl\:dq-bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .\32xl\:dq-bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .\32xl\:dq-bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .\32xl\:dq-bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .\32xl\:dq-from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:dq-from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:dq-from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:dq-from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:dq-from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .\32xl\:dq-from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .\32xl\:dq-from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .\32xl\:dq-from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .\32xl\:dq-from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .\32xl\:dq-from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .\32xl\:dq-from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .\32xl\:dq-from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .\32xl\:dq-from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .\32xl\:dq-from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .\32xl\:dq-from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .\32xl\:dq-from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .\32xl\:dq-from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .\32xl\:dq-from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .\32xl\:dq-from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .\32xl\:dq-from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .\32xl\:dq-from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .\32xl\:dq-from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .\32xl\:dq-from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .\32xl\:dq-from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .\32xl\:dq-from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .\32xl\:dq-from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .\32xl\:dq-from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .\32xl\:dq-from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .\32xl\:dq-from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .\32xl\:dq-from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .\32xl\:dq-from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .\32xl\:dq-from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .\32xl\:dq-from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .\32xl\:dq-from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .\32xl\:dq-from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .\32xl\:dq-from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .\32xl\:dq-from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .\32xl\:dq-from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .\32xl\:dq-from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .\32xl\:dq-from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .\32xl\:dq-from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .\32xl\:dq-from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .\32xl\:dq-from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .\32xl\:dq-from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .\32xl\:dq-from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .\32xl\:dq-from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .\32xl\:dq-from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .\32xl\:dq-from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .\32xl\:dq-from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .\32xl\:dq-from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .\32xl\:dq-from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .\32xl\:dq-from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .\32xl\:dq-from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .\32xl\:dq-from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .\32xl\:dq-from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .\32xl\:dq-from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .\32xl\:dq-from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .\32xl\:dq-from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .\32xl\:dq-from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .\32xl\:dq-from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .\32xl\:dq-from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .\32xl\:dq-from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .\32xl\:dq-from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .\32xl\:dq-from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .\32xl\:dq-from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .\32xl\:dq-from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .\32xl\:dq-from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .\32xl\:dq-from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .\32xl\:dq-from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .\32xl\:dq-from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .\32xl\:dq-from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .\32xl\:dq-from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .\32xl\:dq-from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .\32xl\:dq-from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .\32xl\:dq-from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .\32xl\:dq-from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .\32xl\:dq-from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .\32xl\:dq-from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .\32xl\:dq-from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .\32xl\:dq-from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .\32xl\:dq-from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .\32xl\:dq-from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .\32xl\:dq-from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .\32xl\:dq-from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .\32xl\:hover\:dq-from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:hover\:dq-from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:hover\:dq-from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:hover\:dq-from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:hover\:dq-from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .\32xl\:hover\:dq-from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .\32xl\:hover\:dq-from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .\32xl\:hover\:dq-from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .\32xl\:hover\:dq-from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .\32xl\:hover\:dq-from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .\32xl\:hover\:dq-from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .\32xl\:hover\:dq-from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .\32xl\:hover\:dq-from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .\32xl\:hover\:dq-from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .\32xl\:hover\:dq-from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .\32xl\:hover\:dq-from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .\32xl\:hover\:dq-from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .\32xl\:hover\:dq-from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .\32xl\:hover\:dq-from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .\32xl\:hover\:dq-from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .\32xl\:hover\:dq-from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .\32xl\:hover\:dq-from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .\32xl\:hover\:dq-from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .\32xl\:hover\:dq-from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .\32xl\:hover\:dq-from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .\32xl\:hover\:dq-from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .\32xl\:hover\:dq-from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .\32xl\:hover\:dq-from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .\32xl\:hover\:dq-from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .\32xl\:hover\:dq-from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .\32xl\:hover\:dq-from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .\32xl\:hover\:dq-from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .\32xl\:hover\:dq-from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .\32xl\:hover\:dq-from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .\32xl\:hover\:dq-from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .\32xl\:hover\:dq-from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .\32xl\:hover\:dq-from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .\32xl\:hover\:dq-from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .\32xl\:hover\:dq-from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .\32xl\:hover\:dq-from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .\32xl\:hover\:dq-from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .\32xl\:hover\:dq-from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .\32xl\:hover\:dq-from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .\32xl\:hover\:dq-from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .\32xl\:hover\:dq-from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .\32xl\:hover\:dq-from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .\32xl\:hover\:dq-from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .\32xl\:hover\:dq-from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .\32xl\:hover\:dq-from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .\32xl\:hover\:dq-from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .\32xl\:hover\:dq-from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .\32xl\:hover\:dq-from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .\32xl\:hover\:dq-from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .\32xl\:hover\:dq-from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .\32xl\:hover\:dq-from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .\32xl\:hover\:dq-from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .\32xl\:hover\:dq-from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .\32xl\:hover\:dq-from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .\32xl\:hover\:dq-from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .\32xl\:hover\:dq-from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .\32xl\:hover\:dq-from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .\32xl\:hover\:dq-from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .\32xl\:hover\:dq-from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .\32xl\:hover\:dq-from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .\32xl\:hover\:dq-from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .\32xl\:hover\:dq-from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .\32xl\:hover\:dq-from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .\32xl\:hover\:dq-from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .\32xl\:hover\:dq-from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .\32xl\:hover\:dq-from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .\32xl\:hover\:dq-from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .\32xl\:hover\:dq-from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .\32xl\:hover\:dq-from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .\32xl\:hover\:dq-from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .\32xl\:hover\:dq-from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .\32xl\:hover\:dq-from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .\32xl\:hover\:dq-from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .\32xl\:hover\:dq-from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .\32xl\:hover\:dq-from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .\32xl\:hover\:dq-from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .\32xl\:hover\:dq-from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .\32xl\:hover\:dq-from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .\32xl\:hover\:dq-from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .\32xl\:hover\:dq-from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .\32xl\:focus\:dq-from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:focus\:dq-from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:focus\:dq-from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:focus\:dq-from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:focus\:dq-from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .\32xl\:focus\:dq-from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .\32xl\:focus\:dq-from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .\32xl\:focus\:dq-from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .\32xl\:focus\:dq-from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .\32xl\:focus\:dq-from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .\32xl\:focus\:dq-from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .\32xl\:focus\:dq-from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .\32xl\:focus\:dq-from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .\32xl\:focus\:dq-from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .\32xl\:focus\:dq-from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .\32xl\:focus\:dq-from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .\32xl\:focus\:dq-from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .\32xl\:focus\:dq-from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .\32xl\:focus\:dq-from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .\32xl\:focus\:dq-from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .\32xl\:focus\:dq-from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .\32xl\:focus\:dq-from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .\32xl\:focus\:dq-from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .\32xl\:focus\:dq-from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .\32xl\:focus\:dq-from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .\32xl\:focus\:dq-from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .\32xl\:focus\:dq-from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .\32xl\:focus\:dq-from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .\32xl\:focus\:dq-from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .\32xl\:focus\:dq-from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .\32xl\:focus\:dq-from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .\32xl\:focus\:dq-from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .\32xl\:focus\:dq-from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .\32xl\:focus\:dq-from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .\32xl\:focus\:dq-from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .\32xl\:focus\:dq-from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .\32xl\:focus\:dq-from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .\32xl\:focus\:dq-from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .\32xl\:focus\:dq-from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .\32xl\:focus\:dq-from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .\32xl\:focus\:dq-from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .\32xl\:focus\:dq-from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .\32xl\:focus\:dq-from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .\32xl\:focus\:dq-from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .\32xl\:focus\:dq-from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .\32xl\:focus\:dq-from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .\32xl\:focus\:dq-from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .\32xl\:focus\:dq-from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .\32xl\:focus\:dq-from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .\32xl\:focus\:dq-from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .\32xl\:focus\:dq-from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .\32xl\:focus\:dq-from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .\32xl\:focus\:dq-from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .\32xl\:focus\:dq-from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .\32xl\:focus\:dq-from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .\32xl\:focus\:dq-from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .\32xl\:focus\:dq-from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .\32xl\:focus\:dq-from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .\32xl\:focus\:dq-from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .\32xl\:focus\:dq-from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .\32xl\:focus\:dq-from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .\32xl\:focus\:dq-from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .\32xl\:focus\:dq-from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .\32xl\:focus\:dq-from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .\32xl\:focus\:dq-from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .\32xl\:focus\:dq-from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .\32xl\:focus\:dq-from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .\32xl\:focus\:dq-from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .\32xl\:focus\:dq-from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .\32xl\:focus\:dq-from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .\32xl\:focus\:dq-from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .\32xl\:focus\:dq-from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .\32xl\:focus\:dq-from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .\32xl\:focus\:dq-from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .\32xl\:focus\:dq-from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .\32xl\:focus\:dq-from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .\32xl\:focus\:dq-from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .\32xl\:focus\:dq-from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .\32xl\:focus\:dq-from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .\32xl\:focus\:dq-from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .\32xl\:focus\:dq-from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .\32xl\:focus\:dq-from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .\32xl\:focus\:dq-from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .\32xl\:focus\:dq-from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .\32xl\:dq-via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:dq-via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:dq-via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:dq-via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:dq-via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .\32xl\:dq-via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .\32xl\:dq-via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .\32xl\:dq-via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .\32xl\:dq-via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .\32xl\:dq-via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .\32xl\:dq-via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .\32xl\:dq-via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .\32xl\:dq-via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .\32xl\:dq-via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .\32xl\:dq-via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .\32xl\:dq-via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .\32xl\:dq-via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .\32xl\:dq-via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .\32xl\:dq-via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .\32xl\:dq-via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .\32xl\:dq-via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .\32xl\:dq-via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .\32xl\:dq-via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .\32xl\:dq-via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .\32xl\:dq-via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .\32xl\:dq-via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .\32xl\:dq-via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .\32xl\:dq-via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .\32xl\:dq-via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .\32xl\:dq-via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .\32xl\:dq-via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .\32xl\:dq-via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .\32xl\:dq-via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .\32xl\:dq-via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .\32xl\:dq-via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .\32xl\:dq-via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .\32xl\:dq-via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .\32xl\:dq-via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .\32xl\:dq-via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .\32xl\:dq-via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .\32xl\:dq-via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .\32xl\:dq-via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .\32xl\:dq-via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .\32xl\:dq-via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .\32xl\:dq-via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .\32xl\:dq-via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .\32xl\:dq-via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .\32xl\:dq-via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .\32xl\:dq-via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .\32xl\:dq-via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .\32xl\:dq-via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .\32xl\:dq-via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .\32xl\:dq-via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .\32xl\:dq-via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .\32xl\:dq-via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .\32xl\:dq-via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .\32xl\:dq-via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .\32xl\:dq-via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .\32xl\:dq-via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .\32xl\:dq-via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .\32xl\:dq-via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .\32xl\:dq-via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .\32xl\:dq-via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .\32xl\:dq-via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .\32xl\:dq-via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .\32xl\:dq-via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .\32xl\:dq-via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .\32xl\:dq-via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .\32xl\:dq-via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .\32xl\:dq-via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .\32xl\:dq-via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .\32xl\:dq-via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .\32xl\:dq-via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .\32xl\:dq-via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .\32xl\:dq-via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .\32xl\:dq-via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .\32xl\:dq-via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .\32xl\:dq-via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .\32xl\:dq-via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .\32xl\:dq-via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .\32xl\:dq-via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .\32xl\:dq-via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .\32xl\:dq-via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .\32xl\:dq-via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .\32xl\:hover\:dq-via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:hover\:dq-via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:hover\:dq-via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:hover\:dq-via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:hover\:dq-via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .\32xl\:hover\:dq-via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .\32xl\:hover\:dq-via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .\32xl\:hover\:dq-via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .\32xl\:hover\:dq-via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .\32xl\:hover\:dq-via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .\32xl\:hover\:dq-via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .\32xl\:hover\:dq-via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .\32xl\:hover\:dq-via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .\32xl\:hover\:dq-via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .\32xl\:hover\:dq-via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .\32xl\:hover\:dq-via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .\32xl\:hover\:dq-via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .\32xl\:hover\:dq-via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .\32xl\:hover\:dq-via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .\32xl\:hover\:dq-via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .\32xl\:hover\:dq-via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .\32xl\:hover\:dq-via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .\32xl\:hover\:dq-via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .\32xl\:hover\:dq-via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .\32xl\:hover\:dq-via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .\32xl\:hover\:dq-via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .\32xl\:hover\:dq-via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .\32xl\:hover\:dq-via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .\32xl\:hover\:dq-via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .\32xl\:hover\:dq-via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .\32xl\:hover\:dq-via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .\32xl\:hover\:dq-via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .\32xl\:hover\:dq-via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .\32xl\:hover\:dq-via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .\32xl\:hover\:dq-via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .\32xl\:hover\:dq-via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .\32xl\:hover\:dq-via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .\32xl\:hover\:dq-via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .\32xl\:hover\:dq-via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .\32xl\:hover\:dq-via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .\32xl\:hover\:dq-via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .\32xl\:hover\:dq-via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .\32xl\:hover\:dq-via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .\32xl\:hover\:dq-via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .\32xl\:hover\:dq-via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .\32xl\:hover\:dq-via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .\32xl\:hover\:dq-via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .\32xl\:hover\:dq-via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .\32xl\:hover\:dq-via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .\32xl\:hover\:dq-via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .\32xl\:hover\:dq-via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .\32xl\:hover\:dq-via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .\32xl\:hover\:dq-via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .\32xl\:hover\:dq-via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .\32xl\:hover\:dq-via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .\32xl\:hover\:dq-via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .\32xl\:hover\:dq-via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .\32xl\:hover\:dq-via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .\32xl\:hover\:dq-via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .\32xl\:hover\:dq-via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .\32xl\:hover\:dq-via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .\32xl\:hover\:dq-via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .\32xl\:hover\:dq-via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .\32xl\:hover\:dq-via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .\32xl\:hover\:dq-via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .\32xl\:hover\:dq-via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .\32xl\:hover\:dq-via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .\32xl\:hover\:dq-via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .\32xl\:hover\:dq-via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .\32xl\:hover\:dq-via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .\32xl\:hover\:dq-via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .\32xl\:hover\:dq-via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .\32xl\:hover\:dq-via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .\32xl\:hover\:dq-via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .\32xl\:hover\:dq-via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .\32xl\:hover\:dq-via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .\32xl\:hover\:dq-via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .\32xl\:hover\:dq-via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .\32xl\:hover\:dq-via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .\32xl\:hover\:dq-via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .\32xl\:hover\:dq-via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .\32xl\:hover\:dq-via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .\32xl\:hover\:dq-via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .\32xl\:hover\:dq-via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .\32xl\:focus\:dq-via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:focus\:dq-via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:focus\:dq-via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .\32xl\:focus\:dq-via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .\32xl\:focus\:dq-via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }

  .\32xl\:focus\:dq-via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }

  .\32xl\:focus\:dq-via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }

  .\32xl\:focus\:dq-via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }

  .\32xl\:focus\:dq-via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }

  .\32xl\:focus\:dq-via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }

  .\32xl\:focus\:dq-via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }

  .\32xl\:focus\:dq-via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }

  .\32xl\:focus\:dq-via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }

  .\32xl\:focus\:dq-via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }

  .\32xl\:focus\:dq-via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }

  .\32xl\:focus\:dq-via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }

  .\32xl\:focus\:dq-via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }

  .\32xl\:focus\:dq-via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }

  .\32xl\:focus\:dq-via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }

  .\32xl\:focus\:dq-via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }

  .\32xl\:focus\:dq-via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }

  .\32xl\:focus\:dq-via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }

  .\32xl\:focus\:dq-via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }

  .\32xl\:focus\:dq-via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }

  .\32xl\:focus\:dq-via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }

  .\32xl\:focus\:dq-via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }

  .\32xl\:focus\:dq-via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }

  .\32xl\:focus\:dq-via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }

  .\32xl\:focus\:dq-via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }

  .\32xl\:focus\:dq-via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }

  .\32xl\:focus\:dq-via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }

  .\32xl\:focus\:dq-via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }

  .\32xl\:focus\:dq-via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }

  .\32xl\:focus\:dq-via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }

  .\32xl\:focus\:dq-via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }

  .\32xl\:focus\:dq-via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }

  .\32xl\:focus\:dq-via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }

  .\32xl\:focus\:dq-via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }

  .\32xl\:focus\:dq-via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }

  .\32xl\:focus\:dq-via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }

  .\32xl\:focus\:dq-via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }

  .\32xl\:focus\:dq-via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }

  .\32xl\:focus\:dq-via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }

  .\32xl\:focus\:dq-via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }

  .\32xl\:focus\:dq-via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }

  .\32xl\:focus\:dq-via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }

  .\32xl\:focus\:dq-via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }

  .\32xl\:focus\:dq-via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }

  .\32xl\:focus\:dq-via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }

  .\32xl\:focus\:dq-via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }

  .\32xl\:focus\:dq-via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }

  .\32xl\:focus\:dq-via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }

  .\32xl\:focus\:dq-via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }

  .\32xl\:focus\:dq-via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }

  .\32xl\:focus\:dq-via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }

  .\32xl\:focus\:dq-via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }

  .\32xl\:focus\:dq-via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }

  .\32xl\:focus\:dq-via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }

  .\32xl\:focus\:dq-via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }

  .\32xl\:focus\:dq-via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }

  .\32xl\:focus\:dq-via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }

  .\32xl\:focus\:dq-via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }

  .\32xl\:focus\:dq-via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }

  .\32xl\:focus\:dq-via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }

  .\32xl\:focus\:dq-via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }

  .\32xl\:focus\:dq-via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }

  .\32xl\:focus\:dq-via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }

  .\32xl\:focus\:dq-via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }

  .\32xl\:focus\:dq-via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }

  .\32xl\:focus\:dq-via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }

  .\32xl\:focus\:dq-via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }

  .\32xl\:focus\:dq-via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }

  .\32xl\:focus\:dq-via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }

  .\32xl\:focus\:dq-via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }

  .\32xl\:focus\:dq-via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }

  .\32xl\:focus\:dq-via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }

  .\32xl\:focus\:dq-via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }

  .\32xl\:focus\:dq-via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }

  .\32xl\:focus\:dq-via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }

  .\32xl\:focus\:dq-via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }

  .\32xl\:focus\:dq-via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }

  .\32xl\:focus\:dq-via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }

  .\32xl\:focus\:dq-via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }

  .\32xl\:focus\:dq-via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }

  .\32xl\:dq-to-transparent {
    --tw-gradient-to: transparent;
  }

  .\32xl\:dq-to-current {
    --tw-gradient-to: currentColor;
  }

  .\32xl\:dq-to-black {
    --tw-gradient-to: #000;
  }

  .\32xl\:dq-to-white {
    --tw-gradient-to: #fff;
  }

  .\32xl\:dq-to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .\32xl\:dq-to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .\32xl\:dq-to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .\32xl\:dq-to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .\32xl\:dq-to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .\32xl\:dq-to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .\32xl\:dq-to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .\32xl\:dq-to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .\32xl\:dq-to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .\32xl\:dq-to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .\32xl\:dq-to-red-50 {
    --tw-gradient-to: #fef2f2;
  }

  .\32xl\:dq-to-red-100 {
    --tw-gradient-to: #fee2e2;
  }

  .\32xl\:dq-to-red-200 {
    --tw-gradient-to: #fecaca;
  }

  .\32xl\:dq-to-red-300 {
    --tw-gradient-to: #fca5a5;
  }

  .\32xl\:dq-to-red-400 {
    --tw-gradient-to: #f87171;
  }

  .\32xl\:dq-to-red-500 {
    --tw-gradient-to: #ef4444;
  }

  .\32xl\:dq-to-red-600 {
    --tw-gradient-to: #dc2626;
  }

  .\32xl\:dq-to-red-700 {
    --tw-gradient-to: #b91c1c;
  }

  .\32xl\:dq-to-red-800 {
    --tw-gradient-to: #991b1b;
  }

  .\32xl\:dq-to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }

  .\32xl\:dq-to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .\32xl\:dq-to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .\32xl\:dq-to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .\32xl\:dq-to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .\32xl\:dq-to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .\32xl\:dq-to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .\32xl\:dq-to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .\32xl\:dq-to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .\32xl\:dq-to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .\32xl\:dq-to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .\32xl\:dq-to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .\32xl\:dq-to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .\32xl\:dq-to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .\32xl\:dq-to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .\32xl\:dq-to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .\32xl\:dq-to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .\32xl\:dq-to-green-600 {
    --tw-gradient-to: #059669;
  }

  .\32xl\:dq-to-green-700 {
    --tw-gradient-to: #047857;
  }

  .\32xl\:dq-to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .\32xl\:dq-to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .\32xl\:dq-to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .\32xl\:dq-to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .\32xl\:dq-to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .\32xl\:dq-to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .\32xl\:dq-to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .\32xl\:dq-to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .\32xl\:dq-to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .\32xl\:dq-to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .\32xl\:dq-to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .\32xl\:dq-to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .\32xl\:dq-to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .\32xl\:dq-to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .\32xl\:dq-to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .\32xl\:dq-to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .\32xl\:dq-to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .\32xl\:dq-to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .\32xl\:dq-to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .\32xl\:dq-to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .\32xl\:dq-to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .\32xl\:dq-to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .\32xl\:dq-to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .\32xl\:dq-to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .\32xl\:dq-to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .\32xl\:dq-to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .\32xl\:dq-to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .\32xl\:dq-to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .\32xl\:dq-to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .\32xl\:dq-to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .\32xl\:dq-to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .\32xl\:dq-to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .\32xl\:dq-to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .\32xl\:dq-to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .\32xl\:dq-to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .\32xl\:dq-to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .\32xl\:dq-to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .\32xl\:dq-to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .\32xl\:dq-to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .\32xl\:dq-to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .\32xl\:dq-to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .\32xl\:dq-to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .\32xl\:hover\:dq-to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .\32xl\:hover\:dq-to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .\32xl\:hover\:dq-to-black:hover {
    --tw-gradient-to: #000;
  }

  .\32xl\:hover\:dq-to-white:hover {
    --tw-gradient-to: #fff;
  }

  .\32xl\:hover\:dq-to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .\32xl\:hover\:dq-to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .\32xl\:hover\:dq-to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .\32xl\:hover\:dq-to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .\32xl\:hover\:dq-to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .\32xl\:hover\:dq-to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .\32xl\:hover\:dq-to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .\32xl\:hover\:dq-to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .\32xl\:hover\:dq-to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .\32xl\:hover\:dq-to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .\32xl\:hover\:dq-to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }

  .\32xl\:hover\:dq-to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }

  .\32xl\:hover\:dq-to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }

  .\32xl\:hover\:dq-to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }

  .\32xl\:hover\:dq-to-red-400:hover {
    --tw-gradient-to: #f87171;
  }

  .\32xl\:hover\:dq-to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }

  .\32xl\:hover\:dq-to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }

  .\32xl\:hover\:dq-to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }

  .\32xl\:hover\:dq-to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }

  .\32xl\:hover\:dq-to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }

  .\32xl\:hover\:dq-to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .\32xl\:hover\:dq-to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .\32xl\:hover\:dq-to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .\32xl\:hover\:dq-to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .\32xl\:hover\:dq-to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .\32xl\:hover\:dq-to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .\32xl\:hover\:dq-to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .\32xl\:hover\:dq-to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .\32xl\:hover\:dq-to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .\32xl\:hover\:dq-to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .\32xl\:hover\:dq-to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .\32xl\:hover\:dq-to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .\32xl\:hover\:dq-to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .\32xl\:hover\:dq-to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .\32xl\:hover\:dq-to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .\32xl\:hover\:dq-to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .\32xl\:hover\:dq-to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .\32xl\:hover\:dq-to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .\32xl\:hover\:dq-to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .\32xl\:hover\:dq-to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .\32xl\:hover\:dq-to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .\32xl\:hover\:dq-to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .\32xl\:hover\:dq-to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .\32xl\:hover\:dq-to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .\32xl\:hover\:dq-to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .\32xl\:hover\:dq-to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .\32xl\:hover\:dq-to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .\32xl\:hover\:dq-to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .\32xl\:hover\:dq-to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .\32xl\:hover\:dq-to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .\32xl\:hover\:dq-to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .\32xl\:hover\:dq-to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .\32xl\:hover\:dq-to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .\32xl\:hover\:dq-to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .\32xl\:hover\:dq-to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .\32xl\:hover\:dq-to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .\32xl\:hover\:dq-to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .\32xl\:hover\:dq-to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .\32xl\:hover\:dq-to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .\32xl\:hover\:dq-to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .\32xl\:hover\:dq-to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .\32xl\:hover\:dq-to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .\32xl\:hover\:dq-to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .\32xl\:hover\:dq-to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .\32xl\:hover\:dq-to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .\32xl\:hover\:dq-to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .\32xl\:hover\:dq-to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .\32xl\:hover\:dq-to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .\32xl\:hover\:dq-to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .\32xl\:hover\:dq-to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .\32xl\:hover\:dq-to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .\32xl\:hover\:dq-to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .\32xl\:hover\:dq-to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .\32xl\:hover\:dq-to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .\32xl\:hover\:dq-to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .\32xl\:hover\:dq-to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .\32xl\:hover\:dq-to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .\32xl\:hover\:dq-to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .\32xl\:hover\:dq-to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .\32xl\:hover\:dq-to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .\32xl\:focus\:dq-to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .\32xl\:focus\:dq-to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .\32xl\:focus\:dq-to-black:focus {
    --tw-gradient-to: #000;
  }

  .\32xl\:focus\:dq-to-white:focus {
    --tw-gradient-to: #fff;
  }

  .\32xl\:focus\:dq-to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .\32xl\:focus\:dq-to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .\32xl\:focus\:dq-to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .\32xl\:focus\:dq-to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .\32xl\:focus\:dq-to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .\32xl\:focus\:dq-to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .\32xl\:focus\:dq-to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .\32xl\:focus\:dq-to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .\32xl\:focus\:dq-to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .\32xl\:focus\:dq-to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .\32xl\:focus\:dq-to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }

  .\32xl\:focus\:dq-to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }

  .\32xl\:focus\:dq-to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }

  .\32xl\:focus\:dq-to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }

  .\32xl\:focus\:dq-to-red-400:focus {
    --tw-gradient-to: #f87171;
  }

  .\32xl\:focus\:dq-to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }

  .\32xl\:focus\:dq-to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }

  .\32xl\:focus\:dq-to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }

  .\32xl\:focus\:dq-to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }

  .\32xl\:focus\:dq-to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }

  .\32xl\:focus\:dq-to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .\32xl\:focus\:dq-to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .\32xl\:focus\:dq-to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .\32xl\:focus\:dq-to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .\32xl\:focus\:dq-to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .\32xl\:focus\:dq-to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .\32xl\:focus\:dq-to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .\32xl\:focus\:dq-to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .\32xl\:focus\:dq-to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .\32xl\:focus\:dq-to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .\32xl\:focus\:dq-to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .\32xl\:focus\:dq-to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .\32xl\:focus\:dq-to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .\32xl\:focus\:dq-to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .\32xl\:focus\:dq-to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .\32xl\:focus\:dq-to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .\32xl\:focus\:dq-to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .\32xl\:focus\:dq-to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .\32xl\:focus\:dq-to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .\32xl\:focus\:dq-to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .\32xl\:focus\:dq-to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .\32xl\:focus\:dq-to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .\32xl\:focus\:dq-to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .\32xl\:focus\:dq-to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .\32xl\:focus\:dq-to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .\32xl\:focus\:dq-to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .\32xl\:focus\:dq-to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .\32xl\:focus\:dq-to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .\32xl\:focus\:dq-to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .\32xl\:focus\:dq-to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .\32xl\:focus\:dq-to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .\32xl\:focus\:dq-to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .\32xl\:focus\:dq-to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .\32xl\:focus\:dq-to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .\32xl\:focus\:dq-to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .\32xl\:focus\:dq-to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .\32xl\:focus\:dq-to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .\32xl\:focus\:dq-to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .\32xl\:focus\:dq-to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .\32xl\:focus\:dq-to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .\32xl\:focus\:dq-to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .\32xl\:focus\:dq-to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .\32xl\:focus\:dq-to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .\32xl\:focus\:dq-to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .\32xl\:focus\:dq-to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .\32xl\:focus\:dq-to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .\32xl\:focus\:dq-to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .\32xl\:focus\:dq-to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .\32xl\:focus\:dq-to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .\32xl\:focus\:dq-to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .\32xl\:focus\:dq-to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .\32xl\:focus\:dq-to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .\32xl\:focus\:dq-to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .\32xl\:focus\:dq-to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .\32xl\:focus\:dq-to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .\32xl\:focus\:dq-to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .\32xl\:focus\:dq-to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .\32xl\:focus\:dq-to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .\32xl\:focus\:dq-to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .\32xl\:focus\:dq-to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .\32xl\:dq-decoration-slice {
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
  }

  .\32xl\:dq-decoration-clone {
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
  }

  .\32xl\:dq-bg-auto {
    background-size: auto;
  }

  .\32xl\:dq-bg-cover {
    background-size: cover;
  }

  .\32xl\:dq-bg-contain {
    background-size: contain;
  }

  .\32xl\:dq-bg-fixed {
    background-attachment: fixed;
  }

  .\32xl\:dq-bg-local {
    background-attachment: local;
  }

  .\32xl\:dq-bg-scroll {
    background-attachment: scroll;
  }

  .\32xl\:dq-bg-clip-border {
    background-clip: border-box;
  }

  .\32xl\:dq-bg-clip-padding {
    background-clip: padding-box;
  }

  .\32xl\:dq-bg-clip-content {
    background-clip: content-box;
  }

  .\32xl\:dq-bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .\32xl\:dq-bg-bottom {
    background-position: bottom;
  }

  .\32xl\:dq-bg-center {
    background-position: center;
  }

  .\32xl\:dq-bg-left {
    background-position: left;
  }

  .\32xl\:dq-bg-left-bottom {
    background-position: left bottom;
  }

  .\32xl\:dq-bg-left-top {
    background-position: left top;
  }

  .\32xl\:dq-bg-right {
    background-position: right;
  }

  .\32xl\:dq-bg-right-bottom {
    background-position: right bottom;
  }

  .\32xl\:dq-bg-right-top {
    background-position: right top;
  }

  .\32xl\:dq-bg-top {
    background-position: top;
  }

  .\32xl\:dq-bg-repeat {
    background-repeat: repeat;
  }

  .\32xl\:dq-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .\32xl\:dq-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .\32xl\:dq-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .\32xl\:dq-bg-repeat-round {
    background-repeat: round;
  }

  .\32xl\:dq-bg-repeat-space {
    background-repeat: space;
  }

  .\32xl\:dq-bg-origin-border {
    background-origin: border-box;
  }

  .\32xl\:dq-bg-origin-padding {
    background-origin: padding-box;
  }

  .\32xl\:dq-bg-origin-content {
    background-origin: content-box;
  }

  .\32xl\:dq-fill-current {
    fill: currentColor;
  }

  .\32xl\:dq-stroke-current {
    stroke: currentColor;
  }

  .\32xl\:dq-stroke-0 {
    stroke-width: 0;
  }

  .\32xl\:dq-stroke-1 {
    stroke-width: 1;
  }

  .\32xl\:dq-stroke-2 {
    stroke-width: 2;
  }

  .\32xl\:dq-object-contain {
    object-fit: contain;
  }

  .\32xl\:dq-object-cover {
    object-fit: cover;
  }

  .\32xl\:dq-object-fill {
    object-fit: fill;
  }

  .\32xl\:dq-object-none {
    object-fit: none;
  }

  .\32xl\:dq-object-scale-down {
    object-fit: scale-down;
  }

  .\32xl\:dq-object-bottom {
    object-position: bottom;
  }

  .\32xl\:dq-object-center {
    object-position: center;
  }

  .\32xl\:dq-object-left {
    object-position: left;
  }

  .\32xl\:dq-object-left-bottom {
    object-position: left bottom;
  }

  .\32xl\:dq-object-left-top {
    object-position: left top;
  }

  .\32xl\:dq-object-right {
    object-position: right;
  }

  .\32xl\:dq-object-right-bottom {
    object-position: right bottom;
  }

  .\32xl\:dq-object-right-top {
    object-position: right top;
  }

  .\32xl\:dq-object-top {
    object-position: top;
  }

  .\32xl\:dq-p-0 {
    padding: 0px;
  }

  .\32xl\:dq-p-1 {
    padding: 0.25rem;
  }

  .\32xl\:dq-p-2 {
    padding: 0.5rem;
  }

  .\32xl\:dq-p-3 {
    padding: 0.75rem;
  }

  .\32xl\:dq-p-4 {
    padding: 1rem;
  }

  .\32xl\:dq-p-5 {
    padding: 1.25rem;
  }

  .\32xl\:dq-p-6 {
    padding: 1.5rem;
  }

  .\32xl\:dq-p-7 {
    padding: 1.75rem;
  }

  .\32xl\:dq-p-8 {
    padding: 2rem;
  }

  .\32xl\:dq-p-9 {
    padding: 2.25rem;
  }

  .\32xl\:dq-p-10 {
    padding: 2.5rem;
  }

  .\32xl\:dq-p-11 {
    padding: 2.75rem;
  }

  .\32xl\:dq-p-12 {
    padding: 3rem;
  }

  .\32xl\:dq-p-14 {
    padding: 3.5rem;
  }

  .\32xl\:dq-p-16 {
    padding: 4rem;
  }

  .\32xl\:dq-p-20 {
    padding: 5rem;
  }

  .\32xl\:dq-p-24 {
    padding: 6rem;
  }

  .\32xl\:dq-p-28 {
    padding: 7rem;
  }

  .\32xl\:dq-p-32 {
    padding: 8rem;
  }

  .\32xl\:dq-p-36 {
    padding: 9rem;
  }

  .\32xl\:dq-p-40 {
    padding: 10rem;
  }

  .\32xl\:dq-p-44 {
    padding: 11rem;
  }

  .\32xl\:dq-p-48 {
    padding: 12rem;
  }

  .\32xl\:dq-p-52 {
    padding: 13rem;
  }

  .\32xl\:dq-p-56 {
    padding: 14rem;
  }

  .\32xl\:dq-p-60 {
    padding: 15rem;
  }

  .\32xl\:dq-p-64 {
    padding: 16rem;
  }

  .\32xl\:dq-p-72 {
    padding: 18rem;
  }

  .\32xl\:dq-p-80 {
    padding: 20rem;
  }

  .\32xl\:dq-p-96 {
    padding: 24rem;
  }

  .\32xl\:dq-p-px {
    padding: 1px;
  }

  .\32xl\:dq-p-0\.5 {
    padding: 0.125rem;
  }

  .\32xl\:dq-p-1\.5 {
    padding: 0.375rem;
  }

  .\32xl\:dq-p-2\.5 {
    padding: 0.625rem;
  }

  .\32xl\:dq-p-3\.5 {
    padding: 0.875rem;
  }

  .\32xl\:dq-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .\32xl\:dq-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .\32xl\:dq-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .\32xl\:dq-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .\32xl\:dq-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .\32xl\:dq-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .\32xl\:dq-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .\32xl\:dq-px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .\32xl\:dq-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .\32xl\:dq-px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .\32xl\:dq-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .\32xl\:dq-px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .\32xl\:dq-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .\32xl\:dq-px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .\32xl\:dq-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .\32xl\:dq-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .\32xl\:dq-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .\32xl\:dq-px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .\32xl\:dq-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .\32xl\:dq-px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .\32xl\:dq-px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .\32xl\:dq-px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .\32xl\:dq-px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .\32xl\:dq-px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .\32xl\:dq-px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .\32xl\:dq-px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .\32xl\:dq-px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .\32xl\:dq-px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .\32xl\:dq-px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .\32xl\:dq-px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .\32xl\:dq-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .\32xl\:dq-px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }

  .\32xl\:dq-px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }

  .\32xl\:dq-px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .\32xl\:dq-px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }

  .\32xl\:dq-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .\32xl\:dq-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .\32xl\:dq-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .\32xl\:dq-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .\32xl\:dq-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .\32xl\:dq-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .\32xl\:dq-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .\32xl\:dq-py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .\32xl\:dq-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .\32xl\:dq-py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .\32xl\:dq-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .\32xl\:dq-py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .\32xl\:dq-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .\32xl\:dq-py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .\32xl\:dq-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .\32xl\:dq-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .\32xl\:dq-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .\32xl\:dq-py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .\32xl\:dq-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .\32xl\:dq-py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .\32xl\:dq-py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .\32xl\:dq-py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .\32xl\:dq-py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .\32xl\:dq-py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .\32xl\:dq-py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .\32xl\:dq-py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .\32xl\:dq-py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .\32xl\:dq-py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .\32xl\:dq-py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .\32xl\:dq-py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .\32xl\:dq-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .\32xl\:dq-py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }

  .\32xl\:dq-py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  .\32xl\:dq-py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .\32xl\:dq-py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  .\32xl\:dq-pt-0 {
    padding-top: 0px;
  }

  .\32xl\:dq-pt-1 {
    padding-top: 0.25rem;
  }

  .\32xl\:dq-pt-2 {
    padding-top: 0.5rem;
  }

  .\32xl\:dq-pt-3 {
    padding-top: 0.75rem;
  }

  .\32xl\:dq-pt-4 {
    padding-top: 1rem;
  }

  .\32xl\:dq-pt-5 {
    padding-top: 1.25rem;
  }

  .\32xl\:dq-pt-6 {
    padding-top: 1.5rem;
  }

  .\32xl\:dq-pt-7 {
    padding-top: 1.75rem;
  }

  .\32xl\:dq-pt-8 {
    padding-top: 2rem;
  }

  .\32xl\:dq-pt-9 {
    padding-top: 2.25rem;
  }

  .\32xl\:dq-pt-10 {
    padding-top: 2.5rem;
  }

  .\32xl\:dq-pt-11 {
    padding-top: 2.75rem;
  }

  .\32xl\:dq-pt-12 {
    padding-top: 3rem;
  }

  .\32xl\:dq-pt-14 {
    padding-top: 3.5rem;
  }

  .\32xl\:dq-pt-16 {
    padding-top: 4rem;
  }

  .\32xl\:dq-pt-20 {
    padding-top: 5rem;
  }

  .\32xl\:dq-pt-24 {
    padding-top: 6rem;
  }

  .\32xl\:dq-pt-28 {
    padding-top: 7rem;
  }

  .\32xl\:dq-pt-32 {
    padding-top: 8rem;
  }

  .\32xl\:dq-pt-36 {
    padding-top: 9rem;
  }

  .\32xl\:dq-pt-40 {
    padding-top: 10rem;
  }

  .\32xl\:dq-pt-44 {
    padding-top: 11rem;
  }

  .\32xl\:dq-pt-48 {
    padding-top: 12rem;
  }

  .\32xl\:dq-pt-52 {
    padding-top: 13rem;
  }

  .\32xl\:dq-pt-56 {
    padding-top: 14rem;
  }

  .\32xl\:dq-pt-60 {
    padding-top: 15rem;
  }

  .\32xl\:dq-pt-64 {
    padding-top: 16rem;
  }

  .\32xl\:dq-pt-72 {
    padding-top: 18rem;
  }

  .\32xl\:dq-pt-80 {
    padding-top: 20rem;
  }

  .\32xl\:dq-pt-96 {
    padding-top: 24rem;
  }

  .\32xl\:dq-pt-px {
    padding-top: 1px;
  }

  .\32xl\:dq-pt-0\.5 {
    padding-top: 0.125rem;
  }

  .\32xl\:dq-pt-1\.5 {
    padding-top: 0.375rem;
  }

  .\32xl\:dq-pt-2\.5 {
    padding-top: 0.625rem;
  }

  .\32xl\:dq-pt-3\.5 {
    padding-top: 0.875rem;
  }

  .\32xl\:dq-pr-0 {
    padding-right: 0px;
  }

  .\32xl\:dq-pr-1 {
    padding-right: 0.25rem;
  }

  .\32xl\:dq-pr-2 {
    padding-right: 0.5rem;
  }

  .\32xl\:dq-pr-3 {
    padding-right: 0.75rem;
  }

  .\32xl\:dq-pr-4 {
    padding-right: 1rem;
  }

  .\32xl\:dq-pr-5 {
    padding-right: 1.25rem;
  }

  .\32xl\:dq-pr-6 {
    padding-right: 1.5rem;
  }

  .\32xl\:dq-pr-7 {
    padding-right: 1.75rem;
  }

  .\32xl\:dq-pr-8 {
    padding-right: 2rem;
  }

  .\32xl\:dq-pr-9 {
    padding-right: 2.25rem;
  }

  .\32xl\:dq-pr-10 {
    padding-right: 2.5rem;
  }

  .\32xl\:dq-pr-11 {
    padding-right: 2.75rem;
  }

  .\32xl\:dq-pr-12 {
    padding-right: 3rem;
  }

  .\32xl\:dq-pr-14 {
    padding-right: 3.5rem;
  }

  .\32xl\:dq-pr-16 {
    padding-right: 4rem;
  }

  .\32xl\:dq-pr-20 {
    padding-right: 5rem;
  }

  .\32xl\:dq-pr-24 {
    padding-right: 6rem;
  }

  .\32xl\:dq-pr-28 {
    padding-right: 7rem;
  }

  .\32xl\:dq-pr-32 {
    padding-right: 8rem;
  }

  .\32xl\:dq-pr-36 {
    padding-right: 9rem;
  }

  .\32xl\:dq-pr-40 {
    padding-right: 10rem;
  }

  .\32xl\:dq-pr-44 {
    padding-right: 11rem;
  }

  .\32xl\:dq-pr-48 {
    padding-right: 12rem;
  }

  .\32xl\:dq-pr-52 {
    padding-right: 13rem;
  }

  .\32xl\:dq-pr-56 {
    padding-right: 14rem;
  }

  .\32xl\:dq-pr-60 {
    padding-right: 15rem;
  }

  .\32xl\:dq-pr-64 {
    padding-right: 16rem;
  }

  .\32xl\:dq-pr-72 {
    padding-right: 18rem;
  }

  .\32xl\:dq-pr-80 {
    padding-right: 20rem;
  }

  .\32xl\:dq-pr-96 {
    padding-right: 24rem;
  }

  .\32xl\:dq-pr-px {
    padding-right: 1px;
  }

  .\32xl\:dq-pr-0\.5 {
    padding-right: 0.125rem;
  }

  .\32xl\:dq-pr-1\.5 {
    padding-right: 0.375rem;
  }

  .\32xl\:dq-pr-2\.5 {
    padding-right: 0.625rem;
  }

  .\32xl\:dq-pr-3\.5 {
    padding-right: 0.875rem;
  }

  .\32xl\:dq-pb-0 {
    padding-bottom: 0px;
  }

  .\32xl\:dq-pb-1 {
    padding-bottom: 0.25rem;
  }

  .\32xl\:dq-pb-2 {
    padding-bottom: 0.5rem;
  }

  .\32xl\:dq-pb-3 {
    padding-bottom: 0.75rem;
  }

  .\32xl\:dq-pb-4 {
    padding-bottom: 1rem;
  }

  .\32xl\:dq-pb-5 {
    padding-bottom: 1.25rem;
  }

  .\32xl\:dq-pb-6 {
    padding-bottom: 1.5rem;
  }

  .\32xl\:dq-pb-7 {
    padding-bottom: 1.75rem;
  }

  .\32xl\:dq-pb-8 {
    padding-bottom: 2rem;
  }

  .\32xl\:dq-pb-9 {
    padding-bottom: 2.25rem;
  }

  .\32xl\:dq-pb-10 {
    padding-bottom: 2.5rem;
  }

  .\32xl\:dq-pb-11 {
    padding-bottom: 2.75rem;
  }

  .\32xl\:dq-pb-12 {
    padding-bottom: 3rem;
  }

  .\32xl\:dq-pb-14 {
    padding-bottom: 3.5rem;
  }

  .\32xl\:dq-pb-16 {
    padding-bottom: 4rem;
  }

  .\32xl\:dq-pb-20 {
    padding-bottom: 5rem;
  }

  .\32xl\:dq-pb-24 {
    padding-bottom: 6rem;
  }

  .\32xl\:dq-pb-28 {
    padding-bottom: 7rem;
  }

  .\32xl\:dq-pb-32 {
    padding-bottom: 8rem;
  }

  .\32xl\:dq-pb-36 {
    padding-bottom: 9rem;
  }

  .\32xl\:dq-pb-40 {
    padding-bottom: 10rem;
  }

  .\32xl\:dq-pb-44 {
    padding-bottom: 11rem;
  }

  .\32xl\:dq-pb-48 {
    padding-bottom: 12rem;
  }

  .\32xl\:dq-pb-52 {
    padding-bottom: 13rem;
  }

  .\32xl\:dq-pb-56 {
    padding-bottom: 14rem;
  }

  .\32xl\:dq-pb-60 {
    padding-bottom: 15rem;
  }

  .\32xl\:dq-pb-64 {
    padding-bottom: 16rem;
  }

  .\32xl\:dq-pb-72 {
    padding-bottom: 18rem;
  }

  .\32xl\:dq-pb-80 {
    padding-bottom: 20rem;
  }

  .\32xl\:dq-pb-96 {
    padding-bottom: 24rem;
  }

  .\32xl\:dq-pb-px {
    padding-bottom: 1px;
  }

  .\32xl\:dq-pb-0\.5 {
    padding-bottom: 0.125rem;
  }

  .\32xl\:dq-pb-1\.5 {
    padding-bottom: 0.375rem;
  }

  .\32xl\:dq-pb-2\.5 {
    padding-bottom: 0.625rem;
  }

  .\32xl\:dq-pb-3\.5 {
    padding-bottom: 0.875rem;
  }

  .\32xl\:dq-pl-0 {
    padding-left: 0px;
  }

  .\32xl\:dq-pl-1 {
    padding-left: 0.25rem;
  }

  .\32xl\:dq-pl-2 {
    padding-left: 0.5rem;
  }

  .\32xl\:dq-pl-3 {
    padding-left: 0.75rem;
  }

  .\32xl\:dq-pl-4 {
    padding-left: 1rem;
  }

  .\32xl\:dq-pl-5 {
    padding-left: 1.25rem;
  }

  .\32xl\:dq-pl-6 {
    padding-left: 1.5rem;
  }

  .\32xl\:dq-pl-7 {
    padding-left: 1.75rem;
  }

  .\32xl\:dq-pl-8 {
    padding-left: 2rem;
  }

  .\32xl\:dq-pl-9 {
    padding-left: 2.25rem;
  }

  .\32xl\:dq-pl-10 {
    padding-left: 2.5rem;
  }

  .\32xl\:dq-pl-11 {
    padding-left: 2.75rem;
  }

  .\32xl\:dq-pl-12 {
    padding-left: 3rem;
  }

  .\32xl\:dq-pl-14 {
    padding-left: 3.5rem;
  }

  .\32xl\:dq-pl-16 {
    padding-left: 4rem;
  }

  .\32xl\:dq-pl-20 {
    padding-left: 5rem;
  }

  .\32xl\:dq-pl-24 {
    padding-left: 6rem;
  }

  .\32xl\:dq-pl-28 {
    padding-left: 7rem;
  }

  .\32xl\:dq-pl-32 {
    padding-left: 8rem;
  }

  .\32xl\:dq-pl-36 {
    padding-left: 9rem;
  }

  .\32xl\:dq-pl-40 {
    padding-left: 10rem;
  }

  .\32xl\:dq-pl-44 {
    padding-left: 11rem;
  }

  .\32xl\:dq-pl-48 {
    padding-left: 12rem;
  }

  .\32xl\:dq-pl-52 {
    padding-left: 13rem;
  }

  .\32xl\:dq-pl-56 {
    padding-left: 14rem;
  }

  .\32xl\:dq-pl-60 {
    padding-left: 15rem;
  }

  .\32xl\:dq-pl-64 {
    padding-left: 16rem;
  }

  .\32xl\:dq-pl-72 {
    padding-left: 18rem;
  }

  .\32xl\:dq-pl-80 {
    padding-left: 20rem;
  }

  .\32xl\:dq-pl-96 {
    padding-left: 24rem;
  }

  .\32xl\:dq-pl-px {
    padding-left: 1px;
  }

  .\32xl\:dq-pl-0\.5 {
    padding-left: 0.125rem;
  }

  .\32xl\:dq-pl-1\.5 {
    padding-left: 0.375rem;
  }

  .\32xl\:dq-pl-2\.5 {
    padding-left: 0.625rem;
  }

  .\32xl\:dq-pl-3\.5 {
    padding-left: 0.875rem;
  }

  .\32xl\:dq-text-left {
    text-align: left;
  }

  .\32xl\:dq-text-center {
    text-align: center;
  }

  .\32xl\:dq-text-right {
    text-align: right;
  }

  .\32xl\:dq-text-justify {
    text-align: justify;
  }

  .\32xl\:dq-align-baseline {
    vertical-align: baseline;
  }

  .\32xl\:dq-align-top {
    vertical-align: top;
  }

  .\32xl\:dq-align-middle {
    vertical-align: middle;
  }

  .\32xl\:dq-align-bottom {
    vertical-align: bottom;
  }

  .\32xl\:dq-align-text-top {
    vertical-align: text-top;
  }

  .\32xl\:dq-align-text-bottom {
    vertical-align: text-bottom;
  }

  .\32xl\:dq-font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .\32xl\:dq-font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .\32xl\:dq-font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .\32xl\:dq-text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .\32xl\:dq-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .\32xl\:dq-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .\32xl\:dq-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .\32xl\:dq-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .\32xl\:dq-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .\32xl\:dq-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .\32xl\:dq-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .\32xl\:dq-text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .\32xl\:dq-text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .\32xl\:dq-text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .\32xl\:dq-text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .\32xl\:dq-text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .\32xl\:dq-font-thin {
    font-weight: 100;
  }

  .\32xl\:dq-font-extralight {
    font-weight: 200;
  }

  .\32xl\:dq-font-light {
    font-weight: 300;
  }

  .\32xl\:dq-font-normal {
    font-weight: 400;
  }

  .\32xl\:dq-font-medium {
    font-weight: 500;
  }

  .\32xl\:dq-font-semibold {
    font-weight: 600;
  }

  .\32xl\:dq-font-bold {
    font-weight: 700;
  }

  .\32xl\:dq-font-extrabold {
    font-weight: 800;
  }

  .\32xl\:dq-font-black {
    font-weight: 900;
  }

  .\32xl\:dq-uppercase {
    text-transform: uppercase;
  }

  .\32xl\:dq-lowercase {
    text-transform: lowercase;
  }

  .\32xl\:dq-capitalize {
    text-transform: capitalize;
  }

  .\32xl\:dq-normal-case {
    text-transform: none;
  }

  .\32xl\:dq-italic {
    font-style: italic;
  }

  .\32xl\:dq-not-italic {
    font-style: normal;
  }

  .\32xl\:dq-ordinal, .\32xl\:dq-slashed-zero, .\32xl\:dq-lining-nums, .\32xl\:dq-oldstyle-nums, .\32xl\:dq-proportional-nums, .\32xl\:dq-tabular-nums, .\32xl\:dq-diagonal-fractions, .\32xl\:dq-stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .\32xl\:dq-normal-nums {
    font-variant-numeric: normal;
  }

  .\32xl\:dq-ordinal {
    --tw-ordinal: ordinal;
  }

  .\32xl\:dq-slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .\32xl\:dq-lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .\32xl\:dq-oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .\32xl\:dq-proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .\32xl\:dq-tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .\32xl\:dq-diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .\32xl\:dq-stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .\32xl\:dq-leading-3 {
    line-height: .75rem;
  }

  .\32xl\:dq-leading-4 {
    line-height: 1rem;
  }

  .\32xl\:dq-leading-5 {
    line-height: 1.25rem;
  }

  .\32xl\:dq-leading-6 {
    line-height: 1.5rem;
  }

  .\32xl\:dq-leading-7 {
    line-height: 1.75rem;
  }

  .\32xl\:dq-leading-8 {
    line-height: 2rem;
  }

  .\32xl\:dq-leading-9 {
    line-height: 2.25rem;
  }

  .\32xl\:dq-leading-10 {
    line-height: 2.5rem;
  }

  .\32xl\:dq-leading-none {
    line-height: 1;
  }

  .\32xl\:dq-leading-tight {
    line-height: 1.25;
  }

  .\32xl\:dq-leading-snug {
    line-height: 1.375;
  }

  .\32xl\:dq-leading-normal {
    line-height: 1.5;
  }

  .\32xl\:dq-leading-relaxed {
    line-height: 1.625;
  }

  .\32xl\:dq-leading-loose {
    line-height: 2;
  }

  .\32xl\:dq-tracking-tighter {
    letter-spacing: -0.05em;
  }

  .\32xl\:dq-tracking-tight {
    letter-spacing: -0.025em;
  }

  .\32xl\:dq-tracking-normal {
    letter-spacing: 0em;
  }

  .\32xl\:dq-tracking-wide {
    letter-spacing: 0.025em;
  }

  .\32xl\:dq-tracking-wider {
    letter-spacing: 0.05em;
  }

  .\32xl\:dq-tracking-widest {
    letter-spacing: 0.1em;
  }

  .\32xl\:dq-text-transparent {
    color: transparent;
  }

  .\32xl\:dq-text-current {
    color: currentColor;
  }

  .\32xl\:dq-text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-transparent {
    color: transparent;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-current {
    color: currentColor;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-transparent:focus-within {
    color: transparent;
  }

  .\32xl\:focus-within\:dq-text-current:focus-within {
    color: currentColor;
  }

  .\32xl\:focus-within\:dq-text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .\32xl\:focus-within\:dq-text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-transparent:hover {
    color: transparent;
  }

  .\32xl\:hover\:dq-text-current:hover {
    color: currentColor;
  }

  .\32xl\:hover\:dq-text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .\32xl\:hover\:dq-text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-transparent:focus {
    color: transparent;
  }

  .\32xl\:focus\:dq-text-current:focus {
    color: currentColor;
  }

  .\32xl\:focus\:dq-text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .\32xl\:focus\:dq-text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .\32xl\:dq-text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .\32xl\:dq-text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .\32xl\:dq-text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .\32xl\:dq-text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .\32xl\:dq-text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .\32xl\:dq-text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .\32xl\:dq-text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .\32xl\:dq-text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .\32xl\:dq-text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .\32xl\:dq-text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .\32xl\:dq-text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .\32xl\:dq-text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .\32xl\:dq-text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .\32xl\:dq-text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .\32xl\:dq-text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .\32xl\:focus-within\:dq-text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .\32xl\:focus-within\:dq-text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }

  .\32xl\:focus-within\:dq-text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }

  .\32xl\:focus-within\:dq-text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }

  .\32xl\:focus-within\:dq-text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }

  .\32xl\:focus-within\:dq-text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }

  .\32xl\:focus-within\:dq-text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }

  .\32xl\:focus-within\:dq-text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }

  .\32xl\:focus-within\:dq-text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }

  .\32xl\:focus-within\:dq-text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }

  .\32xl\:focus-within\:dq-text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }

  .\32xl\:focus-within\:dq-text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }

  .\32xl\:focus-within\:dq-text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }

  .\32xl\:focus-within\:dq-text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }

  .\32xl\:focus-within\:dq-text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .\32xl\:hover\:dq-text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .\32xl\:hover\:dq-text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }

  .\32xl\:hover\:dq-text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }

  .\32xl\:hover\:dq-text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }

  .\32xl\:hover\:dq-text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }

  .\32xl\:hover\:dq-text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }

  .\32xl\:hover\:dq-text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }

  .\32xl\:hover\:dq-text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }

  .\32xl\:hover\:dq-text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }

  .\32xl\:hover\:dq-text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }

  .\32xl\:hover\:dq-text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }

  .\32xl\:hover\:dq-text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }

  .\32xl\:hover\:dq-text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }

  .\32xl\:hover\:dq-text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }

  .\32xl\:hover\:dq-text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .\32xl\:focus\:dq-text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .\32xl\:focus\:dq-text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }

  .\32xl\:focus\:dq-text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }

  .\32xl\:focus\:dq-text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }

  .\32xl\:focus\:dq-text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }

  .\32xl\:focus\:dq-text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }

  .\32xl\:focus\:dq-text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }

  .\32xl\:focus\:dq-text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }

  .\32xl\:focus\:dq-text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }

  .\32xl\:focus\:dq-text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }

  .\32xl\:focus\:dq-text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }

  .\32xl\:focus\:dq-text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }

  .\32xl\:focus\:dq-text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }

  .\32xl\:focus\:dq-text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }

  .\32xl\:focus\:dq-text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .\32xl\:dq-underline {
    text-decoration: underline;
  }

  .\32xl\:dq-line-through {
    text-decoration: line-through;
  }

  .\32xl\:dq-no-underline {
    text-decoration: none;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-underline {
    text-decoration: underline;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-line-through {
    text-decoration: line-through;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-no-underline {
    text-decoration: none;
  }

  .\32xl\:focus-within\:dq-underline:focus-within {
    text-decoration: underline;
  }

  .\32xl\:focus-within\:dq-line-through:focus-within {
    text-decoration: line-through;
  }

  .\32xl\:focus-within\:dq-no-underline:focus-within {
    text-decoration: none;
  }

  .\32xl\:hover\:dq-underline:hover {
    text-decoration: underline;
  }

  .\32xl\:hover\:dq-line-through:hover {
    text-decoration: line-through;
  }

  .\32xl\:hover\:dq-no-underline:hover {
    text-decoration: none;
  }

  .\32xl\:focus\:dq-underline:focus {
    text-decoration: underline;
  }

  .\32xl\:focus\:dq-line-through:focus {
    text-decoration: line-through;
  }

  .\32xl\:focus\:dq-no-underline:focus {
    text-decoration: none;
  }

  .\32xl\:dq-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .\32xl\:dq-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .\32xl\:dq-placeholder-transparent::-webkit-input-placeholder {
    color: transparent;
  }

  .\32xl\:dq-placeholder-transparent:-ms-input-placeholder {
    color: transparent;
  }

  .\32xl\:dq-placeholder-transparent::placeholder {
    color: transparent;
  }

  .\32xl\:dq-placeholder-current::-webkit-input-placeholder {
    color: currentColor;
  }

  .\32xl\:dq-placeholder-current:-ms-input-placeholder {
    color: currentColor;
  }

  .\32xl\:dq-placeholder-current::placeholder {
    color: currentColor;
  }

  .\32xl\:dq-placeholder-black::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-black:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-white:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-200::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-300::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-300:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-400:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-500::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-600::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-600:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-700::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-800::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-800:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-900::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-900:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .\32xl\:focus\:dq-placeholder-transparent:focus:-ms-input-placeholder {
    color: transparent;
  }

  .\32xl\:focus\:dq-placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .\32xl\:focus\:dq-placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor;
  }

  .\32xl\:focus\:dq-placeholder-current:focus:-ms-input-placeholder {
    color: currentColor;
  }

  .\32xl\:focus\:dq-placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .\32xl\:focus\:dq-placeholder-black:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-black:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-white:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-white:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-200:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-200:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-300:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-300:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-400:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-400:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-500:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-500:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-600:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-600:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-700:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-700:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-800:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-800:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-900:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-900:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .\32xl\:focus\:dq-placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .\32xl\:dq-placeholder-opacity-0::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .\32xl\:dq-placeholder-opacity-0:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .\32xl\:dq-placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .\32xl\:dq-placeholder-opacity-5::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .\32xl\:dq-placeholder-opacity-5:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .\32xl\:dq-placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .\32xl\:dq-placeholder-opacity-10::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .\32xl\:dq-placeholder-opacity-10:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .\32xl\:dq-placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .\32xl\:dq-placeholder-opacity-20::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .\32xl\:dq-placeholder-opacity-20:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .\32xl\:dq-placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .\32xl\:dq-placeholder-opacity-25::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .\32xl\:dq-placeholder-opacity-25:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .\32xl\:dq-placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .\32xl\:dq-placeholder-opacity-30::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .\32xl\:dq-placeholder-opacity-30:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .\32xl\:dq-placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .\32xl\:dq-placeholder-opacity-40::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .\32xl\:dq-placeholder-opacity-40:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .\32xl\:dq-placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .\32xl\:dq-placeholder-opacity-50::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .\32xl\:dq-placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .\32xl\:dq-placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .\32xl\:dq-placeholder-opacity-60::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .\32xl\:dq-placeholder-opacity-60:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .\32xl\:dq-placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .\32xl\:dq-placeholder-opacity-70::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .\32xl\:dq-placeholder-opacity-70:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .\32xl\:dq-placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .\32xl\:dq-placeholder-opacity-75::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .\32xl\:dq-placeholder-opacity-75:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .\32xl\:dq-placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .\32xl\:dq-placeholder-opacity-80::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .\32xl\:dq-placeholder-opacity-80:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .\32xl\:dq-placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .\32xl\:dq-placeholder-opacity-90::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .\32xl\:dq-placeholder-opacity-90:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .\32xl\:dq-placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .\32xl\:dq-placeholder-opacity-95::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .\32xl\:dq-placeholder-opacity-95:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .\32xl\:dq-placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .\32xl\:dq-placeholder-opacity-100::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .\32xl\:dq-placeholder-opacity-100:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .\32xl\:dq-placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .\32xl\:focus\:dq-placeholder-opacity-0:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .\32xl\:focus\:dq-placeholder-opacity-0:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0;
  }

  .\32xl\:focus\:dq-placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .\32xl\:focus\:dq-placeholder-opacity-5:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .\32xl\:focus\:dq-placeholder-opacity-5:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .\32xl\:focus\:dq-placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }

  .\32xl\:focus\:dq-placeholder-opacity-10:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .\32xl\:focus\:dq-placeholder-opacity-10:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .\32xl\:focus\:dq-placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }

  .\32xl\:focus\:dq-placeholder-opacity-20:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .\32xl\:focus\:dq-placeholder-opacity-20:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .\32xl\:focus\:dq-placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }

  .\32xl\:focus\:dq-placeholder-opacity-25:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .\32xl\:focus\:dq-placeholder-opacity-25:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .\32xl\:focus\:dq-placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .\32xl\:focus\:dq-placeholder-opacity-30:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .\32xl\:focus\:dq-placeholder-opacity-30:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .\32xl\:focus\:dq-placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }

  .\32xl\:focus\:dq-placeholder-opacity-40:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .\32xl\:focus\:dq-placeholder-opacity-40:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .\32xl\:focus\:dq-placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }

  .\32xl\:focus\:dq-placeholder-opacity-50:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .\32xl\:focus\:dq-placeholder-opacity-50:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .\32xl\:focus\:dq-placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .\32xl\:focus\:dq-placeholder-opacity-60:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .\32xl\:focus\:dq-placeholder-opacity-60:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .\32xl\:focus\:dq-placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }

  .\32xl\:focus\:dq-placeholder-opacity-70:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .\32xl\:focus\:dq-placeholder-opacity-70:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .\32xl\:focus\:dq-placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }

  .\32xl\:focus\:dq-placeholder-opacity-75:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .\32xl\:focus\:dq-placeholder-opacity-75:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .\32xl\:focus\:dq-placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .\32xl\:focus\:dq-placeholder-opacity-80:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .\32xl\:focus\:dq-placeholder-opacity-80:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .\32xl\:focus\:dq-placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }

  .\32xl\:focus\:dq-placeholder-opacity-90:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .\32xl\:focus\:dq-placeholder-opacity-90:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .\32xl\:focus\:dq-placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }

  .\32xl\:focus\:dq-placeholder-opacity-95:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .\32xl\:focus\:dq-placeholder-opacity-95:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .\32xl\:focus\:dq-placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }

  .\32xl\:focus\:dq-placeholder-opacity-100:focus::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .\32xl\:focus\:dq-placeholder-opacity-100:focus:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
  }

  .\32xl\:focus\:dq-placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .\32xl\:dq-opacity-0 {
    opacity: 0;
  }

  .\32xl\:dq-opacity-5 {
    opacity: 0.05;
  }

  .\32xl\:dq-opacity-10 {
    opacity: 0.1;
  }

  .\32xl\:dq-opacity-20 {
    opacity: 0.2;
  }

  .\32xl\:dq-opacity-25 {
    opacity: 0.25;
  }

  .\32xl\:dq-opacity-30 {
    opacity: 0.3;
  }

  .\32xl\:dq-opacity-40 {
    opacity: 0.4;
  }

  .\32xl\:dq-opacity-50 {
    opacity: 0.5;
  }

  .\32xl\:dq-opacity-60 {
    opacity: 0.6;
  }

  .\32xl\:dq-opacity-70 {
    opacity: 0.7;
  }

  .\32xl\:dq-opacity-75 {
    opacity: 0.75;
  }

  .\32xl\:dq-opacity-80 {
    opacity: 0.8;
  }

  .\32xl\:dq-opacity-90 {
    opacity: 0.9;
  }

  .\32xl\:dq-opacity-95 {
    opacity: 0.95;
  }

  .\32xl\:dq-opacity-100 {
    opacity: 1;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-0 {
    opacity: 0;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-5 {
    opacity: 0.05;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-10 {
    opacity: 0.1;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-20 {
    opacity: 0.2;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-25 {
    opacity: 0.25;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-30 {
    opacity: 0.3;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-40 {
    opacity: 0.4;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-50 {
    opacity: 0.5;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-60 {
    opacity: 0.6;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-70 {
    opacity: 0.7;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-75 {
    opacity: 0.75;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-80 {
    opacity: 0.8;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-90 {
    opacity: 0.9;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-95 {
    opacity: 0.95;
  }

  .dq-group:hover .\32xl\:group-hover\:dq-opacity-100 {
    opacity: 1;
  }

  .\32xl\:focus-within\:dq-opacity-0:focus-within {
    opacity: 0;
  }

  .\32xl\:focus-within\:dq-opacity-5:focus-within {
    opacity: 0.05;
  }

  .\32xl\:focus-within\:dq-opacity-10:focus-within {
    opacity: 0.1;
  }

  .\32xl\:focus-within\:dq-opacity-20:focus-within {
    opacity: 0.2;
  }

  .\32xl\:focus-within\:dq-opacity-25:focus-within {
    opacity: 0.25;
  }

  .\32xl\:focus-within\:dq-opacity-30:focus-within {
    opacity: 0.3;
  }

  .\32xl\:focus-within\:dq-opacity-40:focus-within {
    opacity: 0.4;
  }

  .\32xl\:focus-within\:dq-opacity-50:focus-within {
    opacity: 0.5;
  }

  .\32xl\:focus-within\:dq-opacity-60:focus-within {
    opacity: 0.6;
  }

  .\32xl\:focus-within\:dq-opacity-70:focus-within {
    opacity: 0.7;
  }

  .\32xl\:focus-within\:dq-opacity-75:focus-within {
    opacity: 0.75;
  }

  .\32xl\:focus-within\:dq-opacity-80:focus-within {
    opacity: 0.8;
  }

  .\32xl\:focus-within\:dq-opacity-90:focus-within {
    opacity: 0.9;
  }

  .\32xl\:focus-within\:dq-opacity-95:focus-within {
    opacity: 0.95;
  }

  .\32xl\:focus-within\:dq-opacity-100:focus-within {
    opacity: 1;
  }

  .\32xl\:hover\:dq-opacity-0:hover {
    opacity: 0;
  }

  .\32xl\:hover\:dq-opacity-5:hover {
    opacity: 0.05;
  }

  .\32xl\:hover\:dq-opacity-10:hover {
    opacity: 0.1;
  }

  .\32xl\:hover\:dq-opacity-20:hover {
    opacity: 0.2;
  }

  .\32xl\:hover\:dq-opacity-25:hover {
    opacity: 0.25;
  }

  .\32xl\:hover\:dq-opacity-30:hover {
    opacity: 0.3;
  }

  .\32xl\:hover\:dq-opacity-40:hover {
    opacity: 0.4;
  }

  .\32xl\:hover\:dq-opacity-50:hover {
    opacity: 0.5;
  }

  .\32xl\:hover\:dq-opacity-60:hover {
    opacity: 0.6;
  }

  .\32xl\:hover\:dq-opacity-70:hover {
    opacity: 0.7;
  }

  .\32xl\:hover\:dq-opacity-75:hover {
    opacity: 0.75;
  }

  .\32xl\:hover\:dq-opacity-80:hover {
    opacity: 0.8;
  }

  .\32xl\:hover\:dq-opacity-90:hover {
    opacity: 0.9;
  }

  .\32xl\:hover\:dq-opacity-95:hover {
    opacity: 0.95;
  }

  .\32xl\:hover\:dq-opacity-100:hover {
    opacity: 1;
  }

  .\32xl\:focus\:dq-opacity-0:focus {
    opacity: 0;
  }

  .\32xl\:focus\:dq-opacity-5:focus {
    opacity: 0.05;
  }

  .\32xl\:focus\:dq-opacity-10:focus {
    opacity: 0.1;
  }

  .\32xl\:focus\:dq-opacity-20:focus {
    opacity: 0.2;
  }

  .\32xl\:focus\:dq-opacity-25:focus {
    opacity: 0.25;
  }

  .\32xl\:focus\:dq-opacity-30:focus {
    opacity: 0.3;
  }

  .\32xl\:focus\:dq-opacity-40:focus {
    opacity: 0.4;
  }

  .\32xl\:focus\:dq-opacity-50:focus {
    opacity: 0.5;
  }

  .\32xl\:focus\:dq-opacity-60:focus {
    opacity: 0.6;
  }

  .\32xl\:focus\:dq-opacity-70:focus {
    opacity: 0.7;
  }

  .\32xl\:focus\:dq-opacity-75:focus {
    opacity: 0.75;
  }

  .\32xl\:focus\:dq-opacity-80:focus {
    opacity: 0.8;
  }

  .\32xl\:focus\:dq-opacity-90:focus {
    opacity: 0.9;
  }

  .\32xl\:focus\:dq-opacity-95:focus {
    opacity: 0.95;
  }

  .\32xl\:focus\:dq-opacity-100:focus {
    opacity: 1;
  }

  .\32xl\:dq-bg-blend-normal {
    background-blend-mode: normal;
  }

  .\32xl\:dq-bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .\32xl\:dq-bg-blend-screen {
    background-blend-mode: screen;
  }

  .\32xl\:dq-bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .\32xl\:dq-bg-blend-darken {
    background-blend-mode: darken;
  }

  .\32xl\:dq-bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .\32xl\:dq-bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .\32xl\:dq-bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .\32xl\:dq-bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .\32xl\:dq-bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .\32xl\:dq-bg-blend-difference {
    background-blend-mode: difference;
  }

  .\32xl\:dq-bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .\32xl\:dq-bg-blend-hue {
    background-blend-mode: hue;
  }

  .\32xl\:dq-bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .\32xl\:dq-bg-blend-color {
    background-blend-mode: color;
  }

  .\32xl\:dq-bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .\32xl\:dq-mix-blend-normal {
    mix-blend-mode: normal;
  }

  .\32xl\:dq-mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .\32xl\:dq-mix-blend-screen {
    mix-blend-mode: screen;
  }

  .\32xl\:dq-mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .\32xl\:dq-mix-blend-darken {
    mix-blend-mode: darken;
  }

  .\32xl\:dq-mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .\32xl\:dq-mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .\32xl\:dq-mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .\32xl\:dq-mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .\32xl\:dq-mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .\32xl\:dq-mix-blend-difference {
    mix-blend-mode: difference;
  }

  .\32xl\:dq-mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .\32xl\:dq-mix-blend-hue {
    mix-blend-mode: hue;
  }

  .\32xl\:dq-mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .\32xl\:dq-mix-blend-color {
    mix-blend-mode: color;
  }

  .\32xl\:dq-mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .\32xl\:dq-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:dq-shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:dq-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:dq-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:dq-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:dq-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:dq-shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:dq-shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .\32xl\:group-hover\:dq-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .\32xl\:group-hover\:dq-shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .\32xl\:group-hover\:dq-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .\32xl\:group-hover\:dq-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .\32xl\:group-hover\:dq-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .\32xl\:group-hover\:dq-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .\32xl\:group-hover\:dq-shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dq-group:hover .\32xl\:group-hover\:dq-shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus-within\:dq-shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus-within\:dq-shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus-within\:dq-shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus-within\:dq-shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus-within\:dq-shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus-within\:dq-shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus-within\:dq-shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus-within\:dq-shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:hover\:dq-shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:hover\:dq-shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:hover\:dq-shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:hover\:dq-shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:hover\:dq-shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:hover\:dq-shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:hover\:dq-shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:hover\:dq-shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus\:dq-shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus\:dq-shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus\:dq-shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus\:dq-shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus\:dq-shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus\:dq-shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus\:dq-shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:focus\:dq-shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .\32xl\:dq-outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .\32xl\:dq-outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .\32xl\:dq-outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .\32xl\:focus-within\:dq-outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .\32xl\:focus-within\:dq-outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .\32xl\:focus-within\:dq-outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .\32xl\:focus\:dq-outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .\32xl\:focus\:dq-outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .\32xl\:focus\:dq-outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .\32xl\:dq-ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:dq-ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:dq-ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:dq-ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:dq-ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:dq-ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:dq-ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:dq-ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:dq-ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:dq-ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:dq-ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:dq-ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:dq-ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:dq-ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:dq-ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:dq-ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:dq-ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:dq-ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:dq-ring-inset {
    --tw-ring-inset: inset;
  }

  .\32xl\:focus-within\:dq-ring-inset:focus-within {
    --tw-ring-inset: inset;
  }

  .\32xl\:focus\:dq-ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .\32xl\:dq-ring-transparent {
    --tw-ring-color: transparent;
  }

  .\32xl\:dq-ring-current {
    --tw-ring-color: currentColor;
  }

  .\32xl\:dq-ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .\32xl\:focus-within\:dq-ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .\32xl\:focus-within\:dq-ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:dq-ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .\32xl\:focus\:dq-ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .\32xl\:focus\:dq-ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:dq-ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .\32xl\:dq-ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .\32xl\:dq-ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }

  .\32xl\:dq-ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }

  .\32xl\:dq-ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }

  .\32xl\:dq-ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }

  .\32xl\:dq-ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }

  .\32xl\:dq-ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }

  .\32xl\:dq-ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }

  .\32xl\:dq-ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }

  .\32xl\:dq-ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }

  .\32xl\:dq-ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }

  .\32xl\:dq-ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }

  .\32xl\:dq-ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }

  .\32xl\:dq-ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }

  .\32xl\:dq-ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .\32xl\:focus-within\:dq-ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .\32xl\:focus-within\:dq-ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }

  .\32xl\:focus-within\:dq-ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }

  .\32xl\:focus-within\:dq-ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }

  .\32xl\:focus-within\:dq-ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }

  .\32xl\:focus-within\:dq-ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }

  .\32xl\:focus-within\:dq-ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }

  .\32xl\:focus-within\:dq-ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }

  .\32xl\:focus-within\:dq-ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }

  .\32xl\:focus-within\:dq-ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }

  .\32xl\:focus-within\:dq-ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }

  .\32xl\:focus-within\:dq-ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }

  .\32xl\:focus-within\:dq-ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }

  .\32xl\:focus-within\:dq-ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }

  .\32xl\:focus-within\:dq-ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .\32xl\:focus\:dq-ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .\32xl\:focus\:dq-ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }

  .\32xl\:focus\:dq-ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }

  .\32xl\:focus\:dq-ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }

  .\32xl\:focus\:dq-ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }

  .\32xl\:focus\:dq-ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }

  .\32xl\:focus\:dq-ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }

  .\32xl\:focus\:dq-ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }

  .\32xl\:focus\:dq-ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }

  .\32xl\:focus\:dq-ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }

  .\32xl\:focus\:dq-ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }

  .\32xl\:focus\:dq-ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }

  .\32xl\:focus\:dq-ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }

  .\32xl\:focus\:dq-ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }

  .\32xl\:focus\:dq-ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .\32xl\:dq-ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .\32xl\:dq-ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .\32xl\:dq-ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .\32xl\:dq-ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .\32xl\:dq-ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .\32xl\:focus-within\:dq-ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .\32xl\:focus-within\:dq-ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .\32xl\:focus-within\:dq-ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .\32xl\:focus-within\:dq-ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .\32xl\:focus-within\:dq-ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .\32xl\:focus\:dq-ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .\32xl\:focus\:dq-ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .\32xl\:focus\:dq-ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .\32xl\:focus\:dq-ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .\32xl\:focus\:dq-ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .\32xl\:dq-ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .\32xl\:dq-ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .\32xl\:dq-ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .\32xl\:dq-ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .\32xl\:dq-ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .\32xl\:dq-ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .\32xl\:dq-ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .\32xl\:dq-ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .\32xl\:dq-ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .\32xl\:dq-ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .\32xl\:dq-ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .\32xl\:dq-ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .\32xl\:dq-ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .\32xl\:dq-ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .\32xl\:dq-ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }

  .\32xl\:dq-ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }

  .\32xl\:dq-ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }

  .\32xl\:dq-ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }

  .\32xl\:dq-ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }

  .\32xl\:dq-ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }

  .\32xl\:dq-ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }

  .\32xl\:dq-ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }

  .\32xl\:dq-ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }

  .\32xl\:dq-ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }

  .\32xl\:dq-ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .\32xl\:dq-ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .\32xl\:dq-ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .\32xl\:dq-ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .\32xl\:dq-ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .\32xl\:dq-ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .\32xl\:dq-ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .\32xl\:dq-ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .\32xl\:dq-ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .\32xl\:dq-ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .\32xl\:dq-ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .\32xl\:dq-ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .\32xl\:dq-ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .\32xl\:dq-ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .\32xl\:dq-ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .\32xl\:dq-ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .\32xl\:dq-ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .\32xl\:dq-ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .\32xl\:dq-ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .\32xl\:dq-ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .\32xl\:dq-ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .\32xl\:dq-ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .\32xl\:dq-ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .\32xl\:dq-ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .\32xl\:dq-ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .\32xl\:dq-ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .\32xl\:dq-ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .\32xl\:dq-ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .\32xl\:dq-ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .\32xl\:dq-ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .\32xl\:dq-ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .\32xl\:dq-ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .\32xl\:dq-ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .\32xl\:dq-ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .\32xl\:dq-ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .\32xl\:dq-ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .\32xl\:dq-ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .\32xl\:dq-ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .\32xl\:dq-ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .\32xl\:dq-ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .\32xl\:dq-ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .\32xl\:dq-ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .\32xl\:dq-ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .\32xl\:dq-ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .\32xl\:dq-ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .\32xl\:dq-ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .\32xl\:dq-ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .\32xl\:dq-ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .\32xl\:dq-ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .\32xl\:dq-ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .\32xl\:dq-ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .\32xl\:dq-ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .\32xl\:dq-ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .\32xl\:dq-ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .\32xl\:dq-ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .\32xl\:dq-ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .\32xl\:dq-ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .\32xl\:dq-ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .\32xl\:dq-ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .\32xl\:dq-ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .\32xl\:focus-within\:dq-ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .\32xl\:focus-within\:dq-ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .\32xl\:focus-within\:dq-ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .\32xl\:focus-within\:dq-ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .\32xl\:focus-within\:dq-ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .\32xl\:focus-within\:dq-ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .\32xl\:focus-within\:dq-ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .\32xl\:focus-within\:dq-ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .\32xl\:focus-within\:dq-ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .\32xl\:focus-within\:dq-ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .\32xl\:focus-within\:dq-ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .\32xl\:focus-within\:dq-ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .\32xl\:focus-within\:dq-ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .\32xl\:focus-within\:dq-ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .\32xl\:focus-within\:dq-ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }

  .\32xl\:focus-within\:dq-ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }

  .\32xl\:focus-within\:dq-ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }

  .\32xl\:focus-within\:dq-ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }

  .\32xl\:focus-within\:dq-ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }

  .\32xl\:focus-within\:dq-ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }

  .\32xl\:focus-within\:dq-ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }

  .\32xl\:focus-within\:dq-ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }

  .\32xl\:focus-within\:dq-ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }

  .\32xl\:focus-within\:dq-ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }

  .\32xl\:focus-within\:dq-ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .\32xl\:focus-within\:dq-ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .\32xl\:focus-within\:dq-ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .\32xl\:focus-within\:dq-ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .\32xl\:focus-within\:dq-ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .\32xl\:focus-within\:dq-ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .\32xl\:focus-within\:dq-ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .\32xl\:focus-within\:dq-ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .\32xl\:focus-within\:dq-ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .\32xl\:focus-within\:dq-ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .\32xl\:focus-within\:dq-ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .\32xl\:focus-within\:dq-ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .\32xl\:focus-within\:dq-ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .\32xl\:focus-within\:dq-ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .\32xl\:focus-within\:dq-ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .\32xl\:focus-within\:dq-ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .\32xl\:focus-within\:dq-ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .\32xl\:focus-within\:dq-ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .\32xl\:focus-within\:dq-ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .\32xl\:focus-within\:dq-ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .\32xl\:focus-within\:dq-ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .\32xl\:focus-within\:dq-ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .\32xl\:focus-within\:dq-ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .\32xl\:focus-within\:dq-ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .\32xl\:focus-within\:dq-ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .\32xl\:focus-within\:dq-ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .\32xl\:focus-within\:dq-ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .\32xl\:focus-within\:dq-ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .\32xl\:focus-within\:dq-ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .\32xl\:focus-within\:dq-ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .\32xl\:focus-within\:dq-ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .\32xl\:focus-within\:dq-ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .\32xl\:focus-within\:dq-ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .\32xl\:focus-within\:dq-ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .\32xl\:focus-within\:dq-ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .\32xl\:focus-within\:dq-ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .\32xl\:focus-within\:dq-ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .\32xl\:focus-within\:dq-ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .\32xl\:focus-within\:dq-ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .\32xl\:focus-within\:dq-ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .\32xl\:focus-within\:dq-ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .\32xl\:focus-within\:dq-ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .\32xl\:focus-within\:dq-ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .\32xl\:focus-within\:dq-ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .\32xl\:focus-within\:dq-ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .\32xl\:focus-within\:dq-ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .\32xl\:focus-within\:dq-ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .\32xl\:focus-within\:dq-ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .\32xl\:focus-within\:dq-ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .\32xl\:focus-within\:dq-ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .\32xl\:focus-within\:dq-ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .\32xl\:focus-within\:dq-ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .\32xl\:focus-within\:dq-ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .\32xl\:focus-within\:dq-ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .\32xl\:focus-within\:dq-ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .\32xl\:focus-within\:dq-ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .\32xl\:focus-within\:dq-ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .\32xl\:focus-within\:dq-ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .\32xl\:focus-within\:dq-ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .\32xl\:focus-within\:dq-ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .\32xl\:focus\:dq-ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .\32xl\:focus\:dq-ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .\32xl\:focus\:dq-ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .\32xl\:focus\:dq-ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .\32xl\:focus\:dq-ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .\32xl\:focus\:dq-ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .\32xl\:focus\:dq-ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .\32xl\:focus\:dq-ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .\32xl\:focus\:dq-ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .\32xl\:focus\:dq-ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .\32xl\:focus\:dq-ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .\32xl\:focus\:dq-ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .\32xl\:focus\:dq-ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .\32xl\:focus\:dq-ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .\32xl\:focus\:dq-ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }

  .\32xl\:focus\:dq-ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }

  .\32xl\:focus\:dq-ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }

  .\32xl\:focus\:dq-ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }

  .\32xl\:focus\:dq-ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }

  .\32xl\:focus\:dq-ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }

  .\32xl\:focus\:dq-ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }

  .\32xl\:focus\:dq-ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }

  .\32xl\:focus\:dq-ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }

  .\32xl\:focus\:dq-ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }

  .\32xl\:focus\:dq-ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .\32xl\:focus\:dq-ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .\32xl\:focus\:dq-ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .\32xl\:focus\:dq-ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .\32xl\:focus\:dq-ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .\32xl\:focus\:dq-ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .\32xl\:focus\:dq-ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .\32xl\:focus\:dq-ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .\32xl\:focus\:dq-ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .\32xl\:focus\:dq-ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .\32xl\:focus\:dq-ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .\32xl\:focus\:dq-ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .\32xl\:focus\:dq-ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .\32xl\:focus\:dq-ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .\32xl\:focus\:dq-ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .\32xl\:focus\:dq-ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .\32xl\:focus\:dq-ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .\32xl\:focus\:dq-ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .\32xl\:focus\:dq-ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .\32xl\:focus\:dq-ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .\32xl\:focus\:dq-ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .\32xl\:focus\:dq-ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .\32xl\:focus\:dq-ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .\32xl\:focus\:dq-ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .\32xl\:focus\:dq-ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .\32xl\:focus\:dq-ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .\32xl\:focus\:dq-ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .\32xl\:focus\:dq-ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .\32xl\:focus\:dq-ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .\32xl\:focus\:dq-ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .\32xl\:focus\:dq-ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .\32xl\:focus\:dq-ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .\32xl\:focus\:dq-ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .\32xl\:focus\:dq-ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .\32xl\:focus\:dq-ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .\32xl\:focus\:dq-ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .\32xl\:focus\:dq-ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .\32xl\:focus\:dq-ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .\32xl\:focus\:dq-ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .\32xl\:focus\:dq-ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .\32xl\:focus\:dq-ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .\32xl\:focus\:dq-ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .\32xl\:focus\:dq-ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .\32xl\:focus\:dq-ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .\32xl\:focus\:dq-ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .\32xl\:focus\:dq-ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .\32xl\:focus\:dq-ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .\32xl\:focus\:dq-ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .\32xl\:focus\:dq-ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .\32xl\:focus\:dq-ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .\32xl\:focus\:dq-ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .\32xl\:focus\:dq-ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .\32xl\:focus\:dq-ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .\32xl\:focus\:dq-ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .\32xl\:focus\:dq-ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .\32xl\:focus\:dq-ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .\32xl\:focus\:dq-ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .\32xl\:focus\:dq-ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .\32xl\:focus\:dq-ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .\32xl\:focus\:dq-ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .\32xl\:dq-filter {
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .\32xl\:dq-filter-none {
    -webkit-filter: none;
            filter: none;
  }

  .\32xl\:dq-blur-0 {
    --tw-blur: blur(0);
  }

  .\32xl\:dq-blur-none {
    --tw-blur: blur(0);
  }

  .\32xl\:dq-blur-sm {
    --tw-blur: blur(4px);
  }

  .\32xl\:dq-blur {
    --tw-blur: blur(8px);
  }

  .\32xl\:dq-blur-md {
    --tw-blur: blur(12px);
  }

  .\32xl\:dq-blur-lg {
    --tw-blur: blur(16px);
  }

  .\32xl\:dq-blur-xl {
    --tw-blur: blur(24px);
  }

  .\32xl\:dq-blur-2xl {
    --tw-blur: blur(40px);
  }

  .\32xl\:dq-blur-3xl {
    --tw-blur: blur(64px);
  }

  .\32xl\:dq-brightness-0 {
    --tw-brightness: brightness(0);
  }

  .\32xl\:dq-brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .\32xl\:dq-brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .\32xl\:dq-brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .\32xl\:dq-brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .\32xl\:dq-brightness-100 {
    --tw-brightness: brightness(1);
  }

  .\32xl\:dq-brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .\32xl\:dq-brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .\32xl\:dq-brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .\32xl\:dq-brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .\32xl\:dq-brightness-200 {
    --tw-brightness: brightness(2);
  }

  .\32xl\:dq-contrast-0 {
    --tw-contrast: contrast(0);
  }

  .\32xl\:dq-contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .\32xl\:dq-contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .\32xl\:dq-contrast-100 {
    --tw-contrast: contrast(1);
  }

  .\32xl\:dq-contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .\32xl\:dq-contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .\32xl\:dq-contrast-200 {
    --tw-contrast: contrast(2);
  }

  .\32xl\:dq-drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .\32xl\:dq-drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .\32xl\:dq-drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .\32xl\:dq-drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .\32xl\:dq-drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .\32xl\:dq-drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .\32xl\:dq-drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .\32xl\:dq-grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .\32xl\:dq-grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .\32xl\:dq-hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .\32xl\:dq-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .\32xl\:dq-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .\32xl\:dq-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .\32xl\:dq-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .\32xl\:dq-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .\32xl\:dq--hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .\32xl\:dq--hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .\32xl\:dq--hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .\32xl\:dq--hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .\32xl\:dq--hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .\32xl\:dq-invert-0 {
    --tw-invert: invert(0);
  }

  .\32xl\:dq-invert {
    --tw-invert: invert(100%);
  }

  .\32xl\:dq-saturate-0 {
    --tw-saturate: saturate(0);
  }

  .\32xl\:dq-saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .\32xl\:dq-saturate-100 {
    --tw-saturate: saturate(1);
  }

  .\32xl\:dq-saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .\32xl\:dq-saturate-200 {
    --tw-saturate: saturate(2);
  }

  .\32xl\:dq-sepia-0 {
    --tw-sepia: sepia(0);
  }

  .\32xl\:dq-sepia {
    --tw-sepia: sepia(100%);
  }

  .\32xl\:dq-backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .\32xl\:dq-backdrop-filter-none {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }

  .\32xl\:dq-backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }

  .\32xl\:dq-backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .\32xl\:dq-backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .\32xl\:dq-backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .\32xl\:dq-backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .\32xl\:dq-backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .\32xl\:dq-backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .\32xl\:dq-backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .\32xl\:dq-backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .\32xl\:dq-backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .\32xl\:dq-backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .\32xl\:dq-backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .\32xl\:dq-backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .\32xl\:dq-backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .\32xl\:dq-backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .\32xl\:dq-backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .\32xl\:dq-backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .\32xl\:dq-backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .\32xl\:dq-backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .\32xl\:dq-backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .\32xl\:dq-backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .\32xl\:dq-backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .\32xl\:dq-backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .\32xl\:dq-backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .\32xl\:dq-backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .\32xl\:dq-backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .\32xl\:dq-backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .\32xl\:dq-backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .\32xl\:dq-backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .\32xl\:dq-backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .\32xl\:dq-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .\32xl\:dq-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .\32xl\:dq-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .\32xl\:dq-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .\32xl\:dq-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .\32xl\:dq--backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .\32xl\:dq--backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .\32xl\:dq--backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .\32xl\:dq--backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .\32xl\:dq--backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .\32xl\:dq-backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .\32xl\:dq-backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .\32xl\:dq-backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .\32xl\:dq-backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }

  .\32xl\:dq-backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }

  .\32xl\:dq-backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }

  .\32xl\:dq-backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }

  .\32xl\:dq-backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }

  .\32xl\:dq-backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }

  .\32xl\:dq-backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }

  .\32xl\:dq-backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }

  .\32xl\:dq-backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }

  .\32xl\:dq-backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }

  .\32xl\:dq-backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }

  .\32xl\:dq-backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }

  .\32xl\:dq-backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }

  .\32xl\:dq-backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .\32xl\:dq-backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .\32xl\:dq-backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .\32xl\:dq-backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .\32xl\:dq-backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .\32xl\:dq-backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .\32xl\:dq-backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .\32xl\:dq-backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .\32xl\:dq-transition-none {
    transition-property: none;
  }

  .\32xl\:dq-transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .\32xl\:dq-transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .\32xl\:dq-transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .\32xl\:dq-transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .\32xl\:dq-transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .\32xl\:dq-transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .\32xl\:dq-delay-75 {
    transition-delay: 75ms;
  }

  .\32xl\:dq-delay-100 {
    transition-delay: 100ms;
  }

  .\32xl\:dq-delay-150 {
    transition-delay: 150ms;
  }

  .\32xl\:dq-delay-200 {
    transition-delay: 200ms;
  }

  .\32xl\:dq-delay-300 {
    transition-delay: 300ms;
  }

  .\32xl\:dq-delay-500 {
    transition-delay: 500ms;
  }

  .\32xl\:dq-delay-700 {
    transition-delay: 700ms;
  }

  .\32xl\:dq-delay-1000 {
    transition-delay: 1000ms;
  }

  .\32xl\:dq-duration-75 {
    transition-duration: 75ms;
  }

  .\32xl\:dq-duration-100 {
    transition-duration: 100ms;
  }

  .\32xl\:dq-duration-150 {
    transition-duration: 150ms;
  }

  .\32xl\:dq-duration-200 {
    transition-duration: 200ms;
  }

  .\32xl\:dq-duration-300 {
    transition-duration: 300ms;
  }

  .\32xl\:dq-duration-500 {
    transition-duration: 500ms;
  }

  .\32xl\:dq-duration-700 {
    transition-duration: 700ms;
  }

  .\32xl\:dq-duration-1000 {
    transition-duration: 1000ms;
  }

  .\32xl\:dq-ease-linear {
    transition-timing-function: linear;
  }

  .\32xl\:dq-ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .\32xl\:dq-ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .\32xl\:dq-ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

html,
body,
#root,
.App,
.editor {
  height: 100%;
  min-height: 100%;
}

.editor-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.editor-body {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.editor-section {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editor-text {
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow: auto;
  flex: 1 1 200px;
}

.editor-code {
  display: flex;
  flex: 1 1 200px;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}

.editor-code-code,
.markdown-editor,
.CodeMirror {
  display: flex;
  flex-direction: column;
  flex: 1 1 300px;
}

.markdown-editor .CodeMirror {
  padding: 8px;
}

.lesson-edit-form,
.lesson-second-column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding-top: 16px;
}

.screen-menu {
  width: 400px;
  position: absolute !important;
  right: 0;
  top: 64px;
}

.screen-menu ul,
.screen-menu li {
  padding: 0;
}

.screen-menu-item {
  padding: 16px;
  width: 100%;
  height: 100%;
}

.editor-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.MuiFormGroup-root.editor-footer-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-left: 8px;
}

.MuiFormControl-root.screen-title-input {
  width: 30%;
  margin-right: 8px;
}

.editor-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
}

.editor-code-output {
  background-color: #272822;
}

.answer-check-disabled {
  background-color: #3c3e38;
}

.output-text,
.check-messages {
  font-family: monospace;
  padding: 16px;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: white;
}

.output-text .MuiTypography-body2 {
  font-family: monospace;
}

.output-text-stderr {
  color: #f92626;
}

.variable-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: monospace;
}

.check-messages {
  display: flex;
  flex-direction: column;
}

.output-variables .MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.output-variables .MuiPaper-root.MuiAccordion-root {
  background-color: #272822;
  color: white;
  font-family: monospace;
}

.output-text.MuiAccordionDetails-root {
  padding: 0 0 16px 24px;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logged-in-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.editor-table-container {
  background-color: #272822;
  color: white;
  font-family: monospace;
  height: 100%;
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  min-width: 300px;
}

.data-table th {
  font-weight: bold;
  color: white;
  font-family: monospace;
}

.data-table td {
  color: white;
  font-family: monospace;
}

.data-table tbody tr:nth-child(odd) {
  background-color: #3c3e38;
}

.package-dependency {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

pre,
code {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  background-color: #f9f1f3;
}

pre {
  display: block;
  padding: 1em;
  margin: 1em;
}

code {
  color: #ab003c;
  font-size: 0.9em;
}

.code-preview {
  padding: 8px 28px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  background-color: #3c3e38;
  color: white;
  font-family: monospace;
}

@media screen and (min-width: 960px) {
  .lesson-edit-form,
  .lesson-second-column {
    width: 50%;
  }

  .editor-code-section,
  .editor-text-section {
    width: 50%;
  }
}

@media screen and (min-width: 1280px) {
  .lesson-edit-form,
  .lesson-second-column {
    width: 600px;
  }
}

.editor-collapse-controls {
  display: flex;
  align-items: center;
}

.CodeMirror {
  height: auto;
}

.codeRunError p {
  margin: 0;
}

