@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDataGrid-columnSeparator {
  visibility: hidden;
}

/* Third party libraries */
.rdw-editor-wrapper {
  @apply dq-px-3 dq-py-2;
  @apply dq-rounded;
  @apply dq-border dq-border-solid;
  border-color: rgb(196, 196, 196);
}
