html,
body,
#root,
.App,
.editor {
  height: 100%;
  min-height: 100%;
}

.editor-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.editor-body {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.editor-section {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editor-text {
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow: auto;
  flex: 1 1 200px;
}

.editor-code {
  display: flex;
  flex: 1 1 200px;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}

.editor-code-code,
.markdown-editor,
.CodeMirror {
  display: flex;
  flex-direction: column;
  flex: 1 1 300px;
}

.markdown-editor .CodeMirror {
  padding: 8px;
}

.lesson-edit-form,
.lesson-second-column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding-top: 16px;
}

.screen-menu {
  width: 400px;
  position: absolute !important;
  right: 0;
  top: 64px;
}

.screen-menu ul,
.screen-menu li {
  padding: 0;
}

.screen-menu-item {
  padding: 16px;
  width: 100%;
  height: 100%;
}

.editor-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.MuiFormGroup-root.editor-footer-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-left: 8px;
}

.MuiFormControl-root.screen-title-input {
  width: 30%;
  margin-right: 8px;
}

.editor-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
}

.editor-code-output {
  background-color: #272822;
}

.answer-check-disabled {
  background-color: #3c3e38;
}

.output-text,
.check-messages {
  font-family: monospace;
  padding: 16px;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: white;
}

.output-text .MuiTypography-body2 {
  font-family: monospace;
}

.output-text-stderr {
  color: #f92626;
}

.variable-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: monospace;
}

.check-messages {
  display: flex;
  flex-direction: column;
}

.output-variables .MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.output-variables .MuiPaper-root.MuiAccordion-root {
  background-color: #272822;
  color: white;
  font-family: monospace;
}

.output-text.MuiAccordionDetails-root {
  padding: 0 0 16px 24px;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logged-in-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.editor-table-container {
  background-color: #272822;
  color: white;
  font-family: monospace;
  height: 100%;
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  min-width: 300px;
}

.data-table th {
  font-weight: bold;
  color: white;
  font-family: monospace;
}

.data-table td {
  color: white;
  font-family: monospace;
}

.data-table tbody tr:nth-child(odd) {
  background-color: #3c3e38;
}

.package-dependency {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

pre,
code {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  background-color: #f9f1f3;
}

pre {
  display: block;
  padding: 1em;
  margin: 1em;
}

code {
  color: #ab003c;
  font-size: 0.9em;
}

.code-preview {
  padding: 8px 28px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  background-color: #3c3e38;
  color: white;
  font-family: monospace;
}

@media screen and (min-width: 960px) {
  .lesson-edit-form,
  .lesson-second-column {
    width: 50%;
  }
  .editor-code-section,
  .editor-text-section {
    width: 50%;
  }
}

@media screen and (min-width: 1280px) {
  .lesson-edit-form,
  .lesson-second-column {
    width: 600px;
  }
}

.editor-collapse-controls {
  display: flex;
  align-items: center;
}

.CodeMirror {
  height: auto;
}

.codeRunError p {
  margin: 0;
}
